import { useTranslation } from 'react-i18next'

import { useMoney } from '../../../hooks'

import { Price } from '@/graphql/purchasing/generated/purchasing_graphql'

export interface BestBuyItemProps {
  unitPrice: number
  sortedPrices: Price[] | undefined
  index: number
}

// return the best buy or difference in price from the best buy
export const BestBuyItem = ({ unitPrice, sortedPrices, index }: BestBuyItemProps) => {
  const { formatMoney } = useMoney()
  const { t } = useTranslation()
  // The first item in sortedPrices is the best buy, if other items have the same price, also show 'best buy'
  if (index === 0 || unitPrice - (sortedPrices?.[0]?.unitPrice ?? 0) === 0) {
    return (
      <div className="mx-2 flex min-w-[5.5rem] max-w-[8rem] justify-center rounded-md bg-success px-0 py-2 text-white">
        {t('general.bestBuy', 'Best Buy')}
      </div>
    )
  }
  return (
    <div className="mx-2 flex min-w-[5.5rem] max-w-[8rem] justify-center rounded-md bg-error px-0 py-2 text-white">
      {formatMoney(unitPrice - (sortedPrices?.[0]?.unitPrice ?? 0))}
    </div>
  )
}
