import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import { useGetAllStockLocations } from './hooks/useGetAllStockLocations'
import TableView from './table-view'
import TileView from './tile-view'

import StocktakesEmptyStateImg from '@/assets/images/empty-stocktakes.svg'
import { Spinner } from '@/modules/requisitions/components'
import { Breadcrumb, QueryResult, TitleBar } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import SearchAndFilter from '@/modules/shared/components/search-input/SearchAndFilter'
import { useWindowSize } from '@/modules/shared/hooks'
import { HomeIcon, InventoryIcon } from '@/modules/shared/icons'
import { QueryParameter } from '@/modules/shared/types'

export default function AllStockLocations() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()

  const {
    data,
    setVariablesLoading,
    refetchLoading,
    loading,
    stockLocations,
    error,
    onFetchMoreStockLocations,
    onFilter,
  } = useGetAllStockLocations()

  return (
    <>
      <Helmet>
        <title>{t('stockLocations.pageTitle', 'View All Stock Locations')}</title>
      </Helmet>

      <main className="mx-auto space-y-5 px-4">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="#">{t('general.inventory', 'Inventory')}</Breadcrumb.Item>
          <Breadcrumb.Item href="#">{t('general.stockLocations', 'Stock Locations')}</Breadcrumb.Item>
          <Breadcrumb.Item>{t('general.viewAll', 'View All')}</Breadcrumb.Item>
        </Breadcrumb>
        <TitleBar
          icon={<InventoryIcon className="h-10 w-10 " />}
          title={t('general.stockLocations', 'Stock Locations')}
          subtitle={t('stockLocations.pageTitle', 'View All Stock Locations')}
        />
        <SearchAndFilter
          testId="stock-locations-search-input"
          placeholder={t('general.searchStockLocations', 'Search Stock Locations')}
          ariaLabel={t('general.searchStockLocations', 'Search Stock Locations')}
          onFilter={onFilter}
          queryParamFilterType={QueryParameter.Status}
        />
        <section>
          <QueryResult loading={loading || refetchLoading || setVariablesLoading} error={error}>
            {data?.legalHolder && (
              <>
                <InfiniteScroll
                  dataLength={stockLocations.length}
                  next={onFetchMoreStockLocations}
                  hasMore={!!data?.legalHolder?.stockLocations?.pageInfo.hasNextPage}
                  loader={<Spinner className="mt-5 h-14 md:w-16" />}
                >
                  {isLargeScreen ? (
                    <TableView stockLocations={stockLocations} />
                  ) : (
                    <TileView stockLocations={stockLocations} />
                  )}
                </InfiniteScroll>
                {!setVariablesLoading && stockLocations.length === 0 && (
                  <EmptyState
                    img={StocktakesEmptyStateImg}
                    message={t('stockLocations.noLocations', "We couldn't find any Stock Locations to display.")}
                  />
                )}
              </>
            )}
          </QueryResult>
        </section>
      </main>
    </>
  )
}
