import { PropsWithChildren } from 'react'
import { Link, generatePath } from 'react-router-dom'

interface PPNewDetailLinkProps extends PropsWithChildren {
  id?: string
  secondaryId?: string // for use on links that require two ids
  type?:
    | 'purchase-order'
    | 'requisition'
    | 'invoice'
    | 'credit-note'
    | 'catalog'
    | 'catalog-product'
    | 'supplier'
    | 'supplier-directory'
    | 'supplier-directory-products'
    | 'user'
    | 'buy-list'
    | 'stocktake'
    | 'receiving-document'
  testId?: string
  openNewTab?: boolean
  className?: string
  advanced?: boolean
}

export default function PPNewDetailLink({
  id,
  secondaryId,
  type,
  openNewTab = false,
  children,
  className,
  testId,
}: PPNewDetailLinkProps) {
  const openNewTabProps = openNewTab ? { target: '_blank', rel: 'noreferrer' } : {}
  let linkTo = ''
  let defaultTestId = ''
  let defaultClassName = 'text-primary'

  switch (type) {
    case 'purchase-order':
      linkTo = '/purchase-orders/:id/summary'
      defaultTestId = 'view-po-link'
      break
    case 'requisition':
      linkTo = '/requisitions/:id/summary'
      defaultTestId = 'view-requisition-link'
      break
    case 'invoice':
      linkTo = '/invoices/:id/summary'
      defaultTestId = 'view-invoice-link'
      break
    case 'credit-note':
      linkTo = '/invoices/credit-notes/:id/summary'
      defaultTestId = 'view-credit-note-link'
      break
    case 'catalog':
      linkTo = '/catalogs/:id/products'
      defaultTestId = 'view-catalog-link'
      break
    case 'catalog-product':
      linkTo = '/catalogs/self-managed/:id/products/:secondaryId/product-details'
      defaultTestId = 'view-catalog-product'
      break
    case 'supplier':
      linkTo = '/my-suppliers/:id/supplier-details'
      defaultTestId = 'view-supplier-link'
      break
    case 'supplier-directory':
      linkTo = '/supplier-directory/:id/supplier-details'
      defaultTestId = 'view-supplier-directory-link'
      break
    case 'supplier-directory-products':
      linkTo = '/supplier-directory/:id/products'
      defaultTestId = 'view-supplier-directory-products-link'
      break
    case 'user':
      linkTo = '/users/:id/details'
      defaultTestId = 'view-user-link'
      break
    case 'buy-list':
      linkTo = '/buy-lists/:id/products'
      defaultTestId = 'view-buy-list-link'
      break
    case 'stocktake':
      linkTo = '/stocktakes/stock-location/:secondaryId/stocktake/:id/stock-count'
      defaultTestId = 'view-stocktake-link'
      break
    case 'receiving-document':
      linkTo = '/purchase-orders/:secondaryId/receiving-notes/:id'
      defaultTestId = 'view-receiving-document-link'
      break
    default:
      return <>{children}</>
  }

  return (
    <Link
      data-testid={testId || defaultTestId}
      to={generatePath(linkTo, { id, secondaryId })}
      className={className || defaultClassName}
      {...openNewTabProps}
    >
      {children}
    </Link>
  )
}
