import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { PageInfo } from '@/graphql/purchasing/generated/purchasing_graphql'
import { OwnerUnionPricedCatalogue } from '@/modules/catalogs/types'
import { Fallback, MoreOptionsMenu, PPNewDetailLink } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { OptionIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface AllCatalogsTableViewProps {
  catalogs: OwnerUnionPricedCatalogue[]
  paginationLoading: boolean
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'endCursor'>
  onNextPage: () => void
  onPreviousPage: () => void
}

function AllCatalogsTableView({
  catalogs,
  pageInfo: { hasNextPage, hasPreviousPage, endCursor },
  onNextPage,
  onPreviousPage,
  paginationLoading,
}: AllCatalogsTableViewProps) {
  const { t } = useTranslation()

  const renderCatalog = ({ id, title, owner }: OwnerUnionPricedCatalogue) => {
    return owner.isASupplier ? (
      id
    ) : (
      <PPNewDetailLink type="catalog" id={String(id)}>
        {title}
      </PPNewDetailLink>
    )
  }
  const renderSupplier = ({ supplier }: OwnerUnionPricedCatalogue) => {
    return (
      <Fallback as="p" className="break-words" condition={!!supplier?.name}>
        {supplier?.name}
      </Fallback>
    )
  }
  const renderOwner = ({ owner }: OwnerUnionPricedCatalogue) => {
    return (
      <Fallback as="p" className="break-words" condition={!!owner?.name}>
        {owner?.name}
      </Fallback>
    )
  }

  const renderSelfManaged = ({ owner }: OwnerUnionPricedCatalogue) => {
    return owner.isASupplier ? t('general.no', 'No') : t('general.yes', 'Yes')
  }

  const renderLastUpdated = ({ updatedAt }: OwnerUnionPricedCatalogue) => {
    return (
      <>
        <p className="break-words text-sm leading-5">{formatDate(Dates.Short, updatedAt)}</p>
        <p className="text-xs text-gray-500">{formatDate(Dates.Time, updatedAt)}</p>
      </>
    )
  }
  const renderMoreOptions = ({ id, owner }: OwnerUnionPricedCatalogue) => {
    return (
      <MoreOptionsMenu showHelp pricedCatalogId={id} showOpenInPPlus={owner.isAPurchaser}>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </MoreOptionsMenu>
    )
  }

  return (
    <Table
      dataTestId="catalogs-table"
      dataSource={catalogs}
      keyExtractor={(record) => String(record.id)}
      pagination={{
        hasNextPage,
        hasPreviousPage,
        onNextPage: onNextPage,
        onPreviousPage: onPreviousPage,
        loading: paginationLoading,
        endCursor: endCursor,
      }}
      columns={[
        {
          title: t('general.catalog', 'Catalog'),
          key: 'catalog',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 14.375,
          maxWidth: 17.5,
          render: renderCatalog,
        },
        {
          title: t('general.supplier', 'Supplier'),
          key: 'supplier',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 14.375,
          maxWidth: 17.5,
          render: renderSupplier,
        },
        {
          title: t('general.owner', 'Owner'),
          key: 'owner',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 14.375,
          grow: true,
          render: renderOwner,
        },
        {
          title: t('general.selfManaged', 'Self Managed'),
          key: 'selfManaged',
          headerCellStyles: 'px-2 py-3 text-center',
          cellStyles: 'px-2 py-3 text-center',
          minWidth: 7.5,
          maxWidth: 7.5,
          render: renderSelfManaged,
        },
        {
          title: t('general.lastUpdated', 'Last Updated'),
          key: 'lastUpdated',
          headerCellStyles: 'px-0 py-3 text-center',
          cellStyles: 'px-0 py-3 text-center',
          minWidth: 7.5,
          maxWidth: 7.5,
          render: renderLastUpdated,
        },
        {
          title: t('general.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: renderMoreOptions,
        },
      ]}
    />
  )
}

export default memo(AllCatalogsTableView)
