import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import {
  GetAllStocktakesDocument,
  PageInfo,
  RansackDirection,
  RansackMatcher,
  StockTake,
} from '@/graphql/inventory/generated/inventory_graphql'
import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { INVENTORY_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus } from '@/modules/shared/utils'

export const useGetAllStocktakes = () => {
  const { currentOrganisation } = useCurrentOrganisation()
  const [loadingType, setLoadingType] = useState<string | null>(null)
  const paginationResultsPerPage = 20
  const [fetchedStocktakes, setFetchedStocktakes] = useState<StockTake[]>([])

  const { data, networkStatus, error, refetch } = useQuery(GetAllStocktakesDocument, {
    variables: {
      organisationId: Number(currentOrganisation?.id),
      first: paginationResultsPerPage,
      after: null,
      filter: {},
      sort: [
        {
          property: 'periodEndingOn',
          direction: RansackDirection.Desc,
        },
      ],
    },
    context: { uri: INVENTORY_GRAPHQL_API },
    notifyOnNetworkStatusChange: true,
  })

  const { setVariablesLoading, refetchLoading, loading } = checkNetworkStatus(networkStatus)

  const StocktakeConnection = data?.legalHolder?.stockTakes

  const onFilter = ({ searchValue, filterValue, periodYear, periodMonth, stockLocations, countStatus }: OnFilter) => {
    const filter: RansackMatcher[] = []
    if (searchValue) {
      filter.push({
        property: 'stockLocation_name_cont',
        value: searchValue.trim(),
      })
    }
    if (filterValue !== 'all') {
      filter.push({
        property: 'closed_at_null',
        value: filterValue === 'in-progress',
      })
    }
    if (periodYear && !periodMonth) {
      const startDate = dayjs(`${periodYear}-01-01`)
      const endDate = dayjs(`${periodYear}-12-31`)

      filter.push({
        property: 'period_ending_on_gt',
        value: startDate.format('YYYY-MM-DD'),
      })
      filter.push({
        property: 'period_ending_on_lt',
        value: endDate.format('YYYY-MM-DD'),
      })
    }

    if (periodYear && periodMonth) {
      const startDate = dayjs(`${periodYear}-${periodMonth}-01`)
      const endDate = startDate.add(1, 'month')

      filter.push({
        property: 'period_ending_on_gt',
        value: startDate.format('YYYY-MM-DD'),
      })
      filter.push({
        property: 'period_ending_on_lteq',
        value: endDate.format('YYYY-MM-DD'),
      })
    }

    if (stockLocations) {
      filter.push({
        property: 'stockLocation_name_in',
        value: stockLocations,
      })
    }

    if (countStatus) {
      filter.push({
        property: 'stockTakeStatus_not_in',
        value: countStatus,
      })
    }

    refetch({
      filter: {
        q: [...filter],
      },
      first: paginationResultsPerPage, // Reset the pagination to the first page
      after: null, // Reset the pagination to the first page
    })
  }

  const onPreviousPage = () => {
    setLoadingType('onPreviousPage')
    const { startCursor } = StocktakeConnection?.pageInfo || {}
    refetch({
      before: startCursor,
      after: null,
      last: paginationResultsPerPage,
      first: null,
    })
  }

  const onNextPage = () => {
    setLoadingType('onNextPage')
    const { endCursor } = StocktakeConnection?.pageInfo || {}
    refetch({
      after: endCursor,
      before: null,
      first: paginationResultsPerPage,
      last: null,
    })
  }

  // disable loadingType when data is returned
  useEffect(() => {
    if (data?.legalHolder?.stockTakes?.nodes) {
      setFetchedStocktakes((data.legalHolder.stockTakes.nodes as StockTake[]) || [])
    }
    if (loadingType) {
      setLoadingType(null)
    }
  }, [data?.legalHolder?.stockTakes?.nodes])

  return {
    loadingType,
    error,
    setVariablesLoading,
    refetchLoading,
    loading,
    onFilter,
    onPreviousPage,
    onNextPage,
    pageInfo: data?.legalHolder?.stockTakes?.pageInfo as PageInfo,
    stocktakes: fetchedStocktakes as StockTake[],
  }
}
