import { useTranslation } from 'react-i18next'

import InvoiceReceivedStatus from './InvoiceReceivedStatus'

import { InvoiceReceivedStatuses } from '@/modules/invoices/types'
import { Tooltip } from '@/modules/shared/components'

interface InvoiceLineReceivedStatusProps {
  receivedStatus: InvoiceReceivedStatuses
  totalInvoicedQuantity: number
  totalReceivedQuantity: number
  purchaseOrderId: number
}

export default function InvoiceLineReceivedStatus({
  receivedStatus,
  totalInvoicedQuantity,
  totalReceivedQuantity,
  purchaseOrderId,
}: InvoiceLineReceivedStatusProps) {
  const { t } = useTranslation()

  const getInvoiceLineReceivedTooltip = () => {
    switch (receivedStatus) {
      case InvoiceReceivedStatuses.NotReceived:
      case InvoiceReceivedStatuses.PartlyReceived:
        if (!purchaseOrderId) {
          return t(
            'invoice.invoice.lineReceivedTooltip.noPurchaseOrder',
            'The Invoice Line is not linked to a Purchase Order Line.'
          )
        } else {
          return t(
            'invoice.invoice.lineReceivedTooltip.notReceived',
            'The Total Received quantity is {{ totalReceivedQuantity }} and this is not equal to or greater than the Total Invoiced quantity of {{ totalInvoicedQuantity }}.',
            {
              totalReceivedQuantity,
              totalInvoicedQuantity,
            }
          )
        }
      case InvoiceReceivedStatuses.FullyReceived:
        return t(
          'invoice.invoice.lineReceivedTooltip.partlyReceived',
          'The Total Received quantity of {{ totalReceivedQuantity }} is equal to or greater than the Total Invoiced quantity of {{ totalInvoicedQuantity }}.',
          {
            totalReceivedQuantity,
            totalInvoicedQuantity,
          }
        )
      default:
        return 'Invoice received'
    }
  }

  return (
    <Tooltip content={getInvoiceLineReceivedTooltip()}>
      <InvoiceReceivedStatus status={String(receivedStatus)} />
    </Tooltip>
  )
}
