import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import OptionDetails from '../../layouts/shop-header/OptionDetails'

import { User } from '@/graphql/access/generated/access_graphql'
import { Purchaser } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useSession } from '@/modules/access/hooks'
import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import { Button, ConfirmDialog } from '@/modules/shared/components'
import { OptionIcon, SaveIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

export default function ShopHeader() {
  const { t } = useTranslation()
  const { currentUser } = useSession()
  const { currentOrganisation } = useCurrentOrganisation()

  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <header className="flex items-center justify-between border-b bg-white px-5 py-3">
      <Button
        className="flex h-11 items-center gap-2 rounded-md bg-gray-100 px-4 text-sm"
        onClick={() => setIsOpen(true)}
      >
        {t('general.save&Close', 'Save & Close')}
        <SaveIcon className="h-7 w-7" />
      </Button>
      {currentUser && currentOrganisation && (
        <OptionDetails currentUser={currentUser as User} currentOrganisation={currentOrganisation as Purchaser}>
          <OptionIcon className="h-8 w-8" aria-hidden="true" />
        </OptionDetails>
      )}
      <ConfirmDialog
        title={t('general.save&Close', 'Save & Close')}
        description={t(
          'access.shop.confirm.saveDescription',
          'By choosing Save and Close you will be returned to the dashboard and the Requisition will be saved in your drafts list. You will be able to resume shopping the Requisition again anytime in the future.'
        )}
        primaryButtonLabel={t('general.save&Close', 'Save & Close')}
        secondaryButtonLabel={t('general.continueShopping', 'Continue Shopping')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onPrimaryBtnClick={() => navigate('/dashboard')}
        theme={Themes.Primary}
      />
    </header>
  )
}
