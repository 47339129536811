import { graphql } from 'msw'

import { GetCreditNoteDetailsQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

export const GetCreditNoteDetails = graphql.query<GetCreditNoteDetailsQuery>('GetCreditNoteDetails', (_, res, ctx) => {
  return res(
    ctx.data({
      currentPurchaser: {
        id: 44926,
        __typename: 'Purchaser',
        creditNote: {
          id: 813,
          creditNoteNumber: '12314',
          workflowState: 'reconciled',
          creditNoteDate: '2016-12-28',
          reference: 'PO 12345 Restaurant pens',
          totalValue: 42.35,
          totalTaxValue: 3.85,
          deliveryChargeExTax: 0,
          deliveryChargeTax: 0,
          invoice: {
            id: 12321321,
            invoiceNumber: 'INV1234',
            __typename: 'Invoice',
          },
          __typename: 'CreditNote',
          department: {
            id: 44926,
            name: 'Test Account',
            __typename: 'Purchaser',
          },
          account: {
            id: 96392,
            code: '0060008',
            accountName: '0060008 - Operating Account - 8',
            __typename: 'Account',
          },
          supplier: {
            id: 15065,
            name: 'Zeus Wholesale',
            __typename: 'Supplier',
          },
        },
      },
    })
  )
})
