import { graphql } from 'msw'

import { GetAllSuppliersQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetAllSuppliers: GetAllSuppliersQuery = {
  suppliers: {
    edges: [
      {
        node: {
          id: 37746,
          name: '01 Computer System Pte Ltd',
          businessNumber: '',
          email: 'internal@marketboomer.com',
          telephone: '+65 63092711',
          creditApplicationUrl: 'https://purchaseplus.com',
          supplierRelationship: {
            id: 38712,
            isEnabled: true,
          },
          __typename: 'Supplier',
        },
        __typename: 'SupplierEdge',
      },
      {
        node: {
          id: 83440,
          name: '0/3 Baby Collection',
          businessNumber: null,
          email: 'null@marketboomer.com',
          telephone: '+852 26121390',
          creditApplicationUrl: 'https://purchaseplus.com',
          supplierRelationship: {
            id: 56235,
            isEnabled: false,
          },
          __typename: 'Supplier',
        },
        __typename: 'SupplierEdge',
      },
      {
        node: {
          id: 121239,
          name: '100 Proof',
          businessNumber: '123903235972',
          email: 'duncan.baldwin@100-proof.com.au',
          telephone: null,
          creditApplicationUrl: null,
          supplierRelationship: null,
          __typename: 'Supplier',
        },
        __typename: 'SupplierEdge',
      },
      {
        node: {
          id: 108165,
          name: '103 Studio Job',
          businessNumber: null,
          email: '103studiojob@gmail.com',
          telephone: '+60 603 21818181',
          creditApplicationUrl: null,
          supplierRelationship: null,
          __typename: 'Supplier',
        },
        __typename: 'SupplierEdge',
      },
      {
        node: {
          id: 196866,
          name: '108-1009 Groop Co Ltd',
          businessNumber: null,
          email: 'internal@marketboomer.com',
          telephone: '021 254 709',
          creditApplicationUrl: null,
          supplierRelationship: null,
          __typename: 'Supplier',
        },
        __typename: 'SupplierEdge',
      },
      {
        node: {
          id: 176630,
          name: '108 Hardware Com By Bangkok',
          businessNumber: null,
          email: 'internal@marketboomer.com',
          telephone: '+662 893 2217',
          creditApplicationUrl: null,
          supplierRelationship: null,
          __typename: 'Supplier',
        },
        __typename: 'SupplierEdge',
      },
      {
        node: {
          id: 184252,
          name: '108 Technofarm Co Ltd',
          businessNumber: null,
          email: 'internal@marketboomer.com',
          telephone: '+66 35-767-341',
          creditApplicationUrl: null,
          supplierRelationship: null,
          __typename: 'Supplier',
        },
        __typename: 'SupplierEdge',
      },
    ],
    pageInfo: {
      endCursor: 'MjU',
      hasNextPage: false,
      __typename: 'PageInfo',
    },
    __typename: 'SupplierConnection',
  },
}

export const GetAllSuppliers = graphql.query<GetAllSuppliersQuery>('GetAllSuppliers', (_, res, ctx) => {
  return res(ctx.data(baseGetAllSuppliers))
})

export default baseGetAllSuppliers
