import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Tooltip } from '@/modules/shared/components'
import { StatusMapping } from '@/modules/shared/types'

interface UserStatusProps {
  isDeleted: boolean
}

function UserStatus(props: UserStatusProps) {
  const { isDeleted } = props
  const { t } = useTranslation()

  const statuses: StatusMapping<'enabled' | 'disabled'> = {
    enabled: {
      content: t('general.enabled', 'Enabled'),
      tooltip: t('systemSettings.userStatus.currentTooltipContent', 'This user can access their PurchasePlus account.'),
      customStyles: 'bg-gray-200',
    },
    disabled: {
      content: t('general.disabled', 'Disabled'),
      tooltip: t(
        'systemSettings.userStatus.deletedTooltipContent',
        'This user has been deleted and no longer has access to this Organisation. '
      ),
      customStyles: 'bg-error text-white',
    },
  }

  const { content, tooltip, customStyles } = statuses[isDeleted ? 'disabled' : 'enabled']

  return (
    <Tooltip content={tooltip} showArrow={false}>
      <span className={classNames('rounded-full px-3 py-1.5 text-xs', customStyles)}>{content}</span>
    </Tooltip>
  )
}

export default UserStatus
