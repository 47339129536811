import { useTranslation } from 'react-i18next'

import Fallback from '../../../fallback'

import { useMoney } from '@/modules/shared/hooks'
import { ProductListType, ProductType } from '@/modules/shared/types'

export interface ProductTaxProps {
  type: ProductListType
  product: ProductType
}

export default function ProductPrice({ type, product }: ProductTaxProps) {
  const { availableQuotes, unitPrice } = product || {}
  const { formatMoney } = useMoney()
  const { t } = useTranslation()

  switch (type) {
    case ProductListType.AddCatalogProduct:
      return <Fallback condition={!!product?.product?.catalogued}>{t('general.added', 'Added')}</Fallback>
    default:
      return <Fallback condition={!!availableQuotes?.length}>{formatMoney(unitPrice)}</Fallback>
  }
}
