import { useMutation } from '@apollo/client'
import { generatePath, useNavigate } from 'react-router-dom'

import { CreatePricedCatalogDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useCreatePricedCatalog() {
  const navigate = useNavigate()

  const mutation = useMutation(CreatePricedCatalogDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    onCompleted(data) {
      navigate(
        generatePath(`/catalogs/:id/products`, {
          id: String(data?.createPricedCatalogue?.pricedCatalogue?.id),
        })
      )
    },
  })

  return mutation
}
