import { t } from 'i18next'
import { useState } from 'react'
import { Link, generatePath } from 'react-router-dom'

import PurchaseOrderInvoicedStatus from '../../all-purchase-order/PurchaseOrderInvoicedStatus'
import PurchaseOrderReceivedStatus from '../../all-purchase-order/PurchaseOrderReceivedStatus'
import PurchaseOrderStatus from '../../all-purchase-order/PurchaseOrderStatus'
import UpdateModal from './UpdateModal'

import {
  DeliveryAddress,
  Invoice,
  PurchaseOrder,
  ReceivingDocument,
  Supplier,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { ReceivingNoteRoute } from '@/modules/purchase-orders/utils/receivingNoteRoute'
import { statusText } from '@/modules/purchase-orders/utils/statusText'
import { ReceivingNoteState } from '@/modules/receiving-notes/types'
import PurchaseOrderModal from '@/modules/requisitions/pages/confirmation/requisition-purchase-orders/PurchaseOrderModal'
import SupplierDetailsModal from '@/modules/requisitions/pages/view-cart/summary/SupplierDetailsModal'
import { EditRequisitionModalEnum } from '@/modules/requisitions/types'
import { formatAddress } from '@/modules/requisitions/utils/formatAddress'
import { Fallback, PPNewDetailLink } from '@/modules/shared/components'
import { TableRowDouble, TableRowSingle } from '@/modules/shared/components/page-header-details-table'
import { DetailsContext } from '@/modules/shared/contexts/DetailsContext'
import { Dates } from '@/modules/shared/types'
import { extractEdges, formatDate } from '@/modules/shared/utils'

interface DetailsProps {
  purchaseOrder: PurchaseOrder
}

function Details({ purchaseOrder }: DetailsProps) {
  const {
    purchaseOrderNumber,
    requisition,
    supplier,
    sentDate,
    sendToSupplier,
    expectedDeliveryDate,
    department,
    account,
    deliveryAddress,
    invoicedStatus,
    status,
    receivedStatus,
  } = purchaseOrder
  const [showSupplierModal, setShowSupplierModal] = useState<number | null>(null)
  const [modalType, setModalType] = useState<string | null>(null)
  const [showStatusModal, setShowStatusModal] = useState<number | null>(null)

  return (
    <section
      className="flex items-center justify-between rounded-md  bg-white p-4 text-sm shadow"
      data-testid="po-details"
    >
      <DetailsContext.Provider
        value={{
          showUpdateButton: Boolean(!purchaseOrder.sentDate),
          setModalType: setModalType,
        }}
      >
        <div className="w-full divide-y divide-gray-200 overflow-auto rounded-md border border-gray-300">
          <TableRowDouble
            leftTitle={t('general.poNumber', 'PO Number')}
            leftTooltip={t(
              'purchaseOrders.purchaseOrder.details.poNumberTooltip',
              'The unique number to identify this Purchase Order.'
            )}
            leftTestId="poNumber"
            leftValue={purchaseOrderNumber}
            rightTitle={t('general.requisition', 'Requisition')}
            rightTooltip={t(
              'purchaseOrders.purchaseOrder.details.requisitionTooltip',
              'The Approved Purchase Requisition that this Purchase Order was created from. Purchase Orders can only be created from an Approved Requisition.'
            )}
            rightTestId="requisition"
            rightValue={
              <PPNewDetailLink type="requisition" id={String(requisition?.id)}>
                {requisition?.requisitionNumber}
              </PPNewDetailLink>
            }
          />
          <TableRowSingle
            titleText={t('general.reference', 'Reference')}
            tooltipText={t(
              'purchaseOrders.purchaseOrder.details.referenceTooltip',
              'The reference or description given to this Purchase Order. This was taken from the Requisition reference and will be visible to the Supplier.'
            )}
            testId="reference"
            value={requisition?.reference}
          />
          <TableRowSingle
            titleText={t('general.status', 'Status')}
            tooltipText={t(
              'purchaseOrders.purchaseOrder.details.statusTooltip',
              'The current status of this Purchase Order. Click the status to see an explanation of each status type.'
            )}
            testId="status"
            value={
              status && (
                <span className="cursor-pointer" onClick={() => setShowStatusModal(purchaseOrder.id)}>
                  <PurchaseOrderStatus status={statusText(status, sentDate)} />
                </span>
              )
            }
          />
          <TableRowSingle
            titleText={t('general.receivedStatus', 'Received Status')}
            tooltipText={t(
              'purchaseOrders.purchaseOrder.details.receivedStatusTooltip',
              'The Received status indicates if the Purchase Order Items are Not Received, Partly Received or Fully Received.'
            )}
            testId="receivedStatus"
            value={receivedStatus && <PurchaseOrderReceivedStatus receivedStatus={receivedStatus} />}
          />
          <TableRowSingle
            titleText={t('general.invoicedStatus', 'Invoiced Status')}
            tooltipText={t(
              'purchaseOrders.purchaseOrder.details.invoicedStatusTooltip',
              'The Invoiced Status indicates if the Purchase Order is Not Invoiced, Partly Invoiced or Fully Invoiced.'
            )}
            testId="invoicedStatus"
            value={invoicedStatus && <PurchaseOrderInvoicedStatus purchaseOrder={purchaseOrder} />}
          />
          <TableRowSingle
            titleText={t('general.supplier', 'Supplier')}
            tooltipText={t(
              'purchaseOrders.purchaseOrder.details.supplierTooltip',
              'The Supplier whom the Purchase Order is for.'
            )}
            testId="supplier"
            value={
              <p
                className="cursor-pointer text-primary"
                data-testid="supplier"
                onClick={() => setShowSupplierModal(Number(supplier?.id))}
              >
                {supplier?.name}
              </p>
            }
          />
          <TableRowSingle
            titleText={t('general.supplierPhone', 'Supplier Phone')}
            tooltipText={t(
              'purchaseOrders.purchaseOrder.details.supplierPhoneTooltip',
              'The contact phone number of the Supplier.'
            )}
            testId="supplierPhone"
            value={supplier?.telephone}
          />
          <TableRowDouble
            leftTitle={t('general.department', 'Department')}
            leftTooltip={t(
              'purchaseOrders.purchaseOrder.details.departmentTooltip',
              'The Department that this Purchase Order expense will be recorded against.'
            )}
            leftTestId="department"
            leftValue={department?.name}
            rightTitle={t('general.accountCode', 'Account Code')}
            rightTooltip={t(
              'purchaseOrders.purchaseOrder.details.accountTooltip',
              'The account code that this Purchase Order expense will be recorded against.'
            )}
            rightTestId="account"
            rightValue={account?.code}
            leftUpdate={EditRequisitionModalEnum.DeptAndAcc}
            rightUpdate={EditRequisitionModalEnum.DeptAndAcc}
          />
          <TableRowDouble
            leftTitle={t('general.sentDate', 'Sent Date')}
            leftTooltip={t(
              'purchaseOrders.purchaseOrder.details.sentDateTooltip',
              'If the Purchase Order is in a Sent state, this is the date that the Purchase Order was marked as Sent.'
            )}
            leftTestId="sentDate"
            leftValue={sentDate ? formatDate(Dates.Medium, sentDate) : ''}
            rightTitle={t('general.sendToSupplier?', 'Send To Supplier?')}
            rightTooltip={t(
              'purchaseOrders.purchaseOrder.details.sendToSupplierTooltip',
              'If the Purchase Order is in a Sent state, this indicates if the Purchase Order was electronically sent to the Supplier.'
            )}
            rightTestId="sendToSupplier"
            rightValue={sendToSupplier ? t('general.yes', 'Yes') : t('general.no', 'No')}
            rightUpdate={EditRequisitionModalEnum.SendToSupplier}
          />
          <TableRowSingle
            titleText={t('general.deliveryDate', 'Delivery Date')}
            tooltipText={t(
              'purchaseOrders.purchaseOrder.details.deliveryDateTooltip',
              'The delivery date recorded on the Purchase Order. This is the date that the Supplier will be expected to make delivery.'
            )}
            testId="deliveryDate"
            value={formatDate(Dates.Medium, expectedDeliveryDate)}
            update={EditRequisitionModalEnum.ExpectedDeliveryDate}
          />
          <TableRowSingle
            titleText={t('general.deliveryAddress', 'Delivery Address')}
            tooltipText={t(
              'purchaseOrders.purchaseOrder.details.deliveryAddressTooltip',
              'The delivery address is the location that the Supplier will be expected to deliver to. It will appear to the Supplier on the Purchase Order.'
            )}
            testId="deliveryAddress"
            value={formatAddress(deliveryAddress as DeliveryAddress)}
            update={EditRequisitionModalEnum.DeliveryAddress}
          />
          <TableRowSingle
            titleText={t('general.matchedInvoices', 'Matched Invoices')}
            tooltipText={t(
              'purchaseOrders.purchaseOrder.details.matchedInvoicesTooltip',
              'As one or more Invoices are matched to the Purchase Order, the Invoices are listed here.'
            )}
            testId="matchedInvoices"
            value={
              <Fallback condition={!!purchaseOrder?.invoices?.edges?.length}>
                {extractEdges<Invoice>(purchaseOrder?.invoices).map((invoice) => (
                  <Link
                    key={invoice.id}
                    className="cursor-pointer text-primary"
                    to={generatePath('/invoices/:invoiceId', {
                      invoiceId: String(invoice?.id),
                    })}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {invoice.invoiceNumber}
                  </Link>
                ))}
              </Fallback>
            }
          />
          <TableRowSingle
            titleText={t('general.receivingNotes', 'Receiving Notes')}
            tooltipText={t(
              'purchaseOrders.purchaseOrder.details.receivingNotesTooltip',
              'As goods are received, the Receiving Notes created against this Purchase Order are listed here.'
            )}
            testId="receivingNotes"
            value={
              <Fallback condition={!!purchaseOrder?.receivingDocuments?.edges?.length}>
                {extractEdges<ReceivingDocument>(purchaseOrder?.receivingDocuments).map((gr) => (
                  <Link
                    key={gr.id}
                    className="cursor-pointer text-primary"
                    to={generatePath(
                      `/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/${ReceivingNoteRoute(
                        gr.workflowState as ReceivingNoteState
                      )}`,
                      {
                        receivingNoteId: String(gr.id),
                        purchaseOrderId: String(purchaseOrder.id),
                      }
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {gr.receivingDocumentNumber}
                  </Link>
                ))}
              </Fallback>
            }
          />
        </div>
      </DetailsContext.Provider>
      {Boolean(modalType) && (
        <UpdateModal showModal={modalType} setShowModal={setModalType} purchaseOrder={purchaseOrder} />
      )}
      <SupplierDetailsModal
        showModal={showSupplierModal === supplier?.id}
        setShowModal={setShowSupplierModal}
        supplier={supplier as Supplier}
      />
      <PurchaseOrderModal
        showModal={showStatusModal === purchaseOrder.id}
        setShowModal={setShowStatusModal}
        purchaseOrder={purchaseOrder}
      />
    </section>
  )
}

export default Details
