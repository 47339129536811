import { Outlet } from 'react-router-dom'

import { useSession } from '@/modules/access/hooks'
import { Header } from '@/modules/requisitions/layouts'
import { AccessDenied } from '@/modules/shared/pages'
import { Home } from '@/modules/shared/pages'

function AdminRoute() {
  const { currentUser, loading } = useSession()
  if (currentUser) {
    if (!currentUser.isAdmin) return <AccessDenied showHeader={false} />
    return <Outlet />
  }

  return loading ? <Header /> : <Home />
}

export default AdminRoute
