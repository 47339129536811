import i18n from 'i18next'

import { PurchaseOrderStatuses } from '@/modules/purchase-orders/types'

/**
 * returns a translation from the purchaseOrderState
 * possible states and translations:
 * @param purchaseOrderState
 * @returns
 */
export function formatStateForLocale(purchaseOrderState: string): string {
  switch (purchaseOrderState) {
    case PurchaseOrderStatuses.NotSent:
      return i18n.t('general.notSent', 'Not Sent')
    case PurchaseOrderStatuses.Sent:
      return i18n.t('general.sent', 'Sent')
    case PurchaseOrderStatuses.Received:
      return i18n.t('general.received', 'Received')
    case PurchaseOrderStatuses.Invoiced:
      return i18n.t('general.invoiced', 'Invoiced')
    case PurchaseOrderStatuses.Closed:
      return i18n.t('general.closed', 'Closed')
    case PurchaseOrderStatuses.Cancelled:
      return i18n.t('general.cancelled', 'Cancelled')
    default:
      return '--'
  }
}
