import dayjs from 'dayjs'
import { Dispatch, SetStateAction } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useSession } from '@/modules/access/hooks'
import { useCurrentOrganisation, useCurrentPurchaser } from '@/modules/purchasing/hooks'
import { useCreateRequisition, useGetDeliveryAddress } from '@/modules/requisitions/hooks'
import { OriginType, RequisitionFormInputs } from '@/modules/requisitions/types'
import { Button } from '@/modules/shared/components'
import { Modal } from '@/modules/shared/components'
import FormInput from '@/modules/shared/components/create-form/FormInput'
import { SOURCE_APPLICATION } from '@/modules/shared/constants'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface QuickCreateModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  moreRequisitionOptionsPath: string
}

export default function QuickCreateModal({
  showModal,
  setShowModal,
  moreRequisitionOptionsPath,
}: QuickCreateModalProps) {
  const { t } = useTranslation()
  const { defaultDeliveryAddress, loading: deliveryAddressLoading } = useGetDeliveryAddress()
  const { currentUser } = useSession()
  const { currentPurchaser } = useCurrentPurchaser()
  const { currentOrganisation } = useCurrentOrganisation()

  const formMethods = useForm<RequisitionFormInputs>()

  const [createRequisition, { loading }] = useCreateRequisition()

  const onCloseModal = () => {
    setShowModal(false)
  }

  const onSubmit: SubmitHandler<RequisitionFormInputs> = (data) => {
    let { reference } = data

    createRequisition({
      variables: {
        input: {
          reference,
          requestorId: currentUser?.id,
          originId: currentPurchaser?.id!,
          originType: OriginType.Purchaser,
          purchaserOrganisationId: currentPurchaser?.id as number,
          deliveryAddressId: defaultDeliveryAddress?.id,
          sendToSupplier: true,
          expectedDeliveryDate: formatDate(Dates.ShortYear, dayjs().add(1, 'days')),
          accountId: currentOrganisation?.defaultAccount?.id,
          departmentId: currentOrganisation?.id,
          sourceApplication: SOURCE_APPLICATION,
          advanced: false,
        },
      },
    })
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        as="form"
        onSubmit={formMethods.handleSubmit(onSubmit)}
        className="flex w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]"
      >
        <Modal.Title title={t('general.startShopping', 'Start Shopping')} onCloseModal={onCloseModal} />

        <Modal.Body>
          <FormProvider {...formMethods}>
            <FormInput
              className="mt-0"
              title={t('createRequisition.reference.label', 'Reference')}
              description={t(
                'createRequisition.reference.explain',
                'Please include a brief description of the Purchase Requisition. This reference will appear on all Purchase Orders that are created when this Requisition is submitted and fully approved. The reference will be visible to Suppliers and anyone at your organisation.'
              )}
              register="reference"
              placeholder={t('createRequisition.reference.placeholder', 'E.g. Beverage Order')}
            />
          </FormProvider>
        </Modal.Body>
        <Modal.Footer className="mt-4 flex items-center justify-between gap-2">
          <Link
            to={moreRequisitionOptionsPath}
            data-testid="quick-create-more-options-link"
            className="text-sm text-primary"
          >
            {t('general.moreRequisitionOptions', 'More Requisition Options')}
          </Link>
          <div className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
            <Button
              type="button"
              className="h-11 w-full rounded-md bg-gray-200 px-8 text-sm md:w-fit"
              onClick={onCloseModal}
            >
              {t('shopPage.cart.summary.table.cancelModalBtn', 'Cancel')}
            </Button>

            <Button
              data-testid="quick-create-submit-button"
              type="submit"
              className="h-11 w-full rounded-md bg-primary px-5 text-sm text-white md:w-fit"
              loading={loading || deliveryAddressLoading}
            >
              {t('general.startShopping', 'Start Shopping')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
