import { graphql } from 'msw'

import { GetPoSendEventsQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetPOSendEvents: GetPoSendEventsQuery = {
  currentPurchaser: {
    id: 44926,
    purchaseOrder: {
      id: 16566710,
      purchaseOrderNumber: 'PO02775',
      deliveries: {
        nodes: [
          {
            id: 11713367,
            state: 'sent',
            sender: {
              id: 18544,
              fullName: 'Sunny H Sun',
            },
            createdAt: '2022-11-02T14:06:48+11:00',
            endpoint: 'https://gw31.goodmanfielder.com.au:443/RESTAdapter/MarketBoomer_Orders',
            __typename: 'Delivery',
          },
          {
            id: 11715232,
            state: 'resent',
            sender: {
              id: 23784,
              fullName: 'Chris Dat Truong',
            },
            createdAt: '2023-02-17T13:20:15+11:00',
            endpoint: null,
            __typename: 'Delivery',
          },
        ],
        __typename: 'DeliveryConnection',
      },
      __typename: 'PurchaseOrder',
    },
    __typename: 'Purchaser',
  },
}

export const GetPOSendEvents = graphql.query<GetPoSendEventsQuery>('GetPOSendEvents', (_, res, ctx) => {
  return res(ctx.data(baseGetPOSendEvents))
})

export default baseGetPOSendEvents
