import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddToBuyListModal from '../AddToBuyListModal'
import PreferredSupplier from '../PreferredSupplier'
import ProductPrice from '../tile-view/product-card-tile-view/ProductPrice'
import ProductPrimaryButton from '../tile-view/product-card-tile-view/ProductPrimaryButton'
import ProductTax from '../tile-view/product-card-tile-view/ProductTax'

import NoImage from '@/assets/images/no-image.svg'
import { RequisitionLine, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { MoreOptionsMenu } from '@/modules/shared/components'
import ProductModal from '@/modules/shared/components/product-modal/ProductModal'
import Table from '@/modules/shared/components/table/Table'
import { ProductImage, ProductItem, SupplierQuotesButton } from '@/modules/shared/components/table/table-column-items'
import { OptionIcon } from '@/modules/shared/icons'
import { ProductListType, ProductType } from '@/modules/shared/types'

interface ListViewProps {
  products: ProductType[]
  type: ProductListType
  lines?: RequisitionLine[]
}

function ProductsListView({ products, type, lines }: ListViewProps) {
  const { t } = useTranslation()

  const [showProductDetailsModal, setShowProductDetailsModal] = useState(false)
  const [showAddToBuyListModal, setShowAddToBuyListModal] = useState(false)
  const [activeLineId, setActiveLineId] = useState<number | null>(null)

  const onOpenProductDetailsModal = (activeId: number) => {
    setActiveLineId(activeId)
    setShowProductDetailsModal(true)
  }

  const onOpenAddToBuyListModal = (activeId: number) => {
    setActiveLineId(activeId)
    setShowAddToBuyListModal(true)
  }

  const renderSupplier = ({ supplier, preferredSupplier }: ProductType) => (
    <p className="flex items-center gap-x-1.5 truncate text-sm leading-5">
      <PreferredSupplier preferredSupplier={!!preferredSupplier} supplierName={supplier?.name} />
    </p>
  )

  const renderUnitPrice = (record: ProductType) => (
    <span>
      <p className="text-base font-bold leading-tight">
        <ProductPrice type={type} product={record} />
      </p>
      <p className="text-xs text-gray-500">
        <ProductTax type={type} product={record} />
      </p>
    </span>
  )

  const renderAddItem = (record: ProductType) => {
    return (
      <div className="flex items-center justify-center">
        <ProductPrimaryButton product={record} type={type} lines={lines} />
      </div>
    )
  }

  const renderMoreOptions = (record: ProductType) => {
    const { cataloguedProductId } = record
    return (
      <>
        <MoreOptionsMenu
          showDetails={() => onOpenProductDetailsModal(Number(cataloguedProductId))}
          showAddToBuyList={() => onOpenAddToBuyListModal(Number(cataloguedProductId))}
        >
          <OptionIcon className="h-10 w-10 text-gray-500" />
        </MoreOptionsMenu>
        <AddToBuyListModal
          requisitionLine={record}
          showModal={showAddToBuyListModal && activeLineId === record.cataloguedProductId}
          setShowModal={setShowAddToBuyListModal}
        />
        <ProductModal
          showModal={showProductDetailsModal && activeLineId === record.cataloguedProductId}
          setShowModal={setShowProductDetailsModal}
          line={record}
          supplier={record?.supplier as Supplier}
        />
      </>
    )
  }

  return (
    <>
      <Table
        dataSource={products}
        keyExtractor={(record) => String(record.cataloguedProductId)}
        columnsHidden={type === ProductListType.Shop ? [] : ['supplier', 'more']}
        columns={[
          {
            title: t('general.image', 'Image'),
            key: 'image',
            headerCellStyles: 'px-4 py-3 text-center',
            cellStyles: 'px-4 py-3 text-center',
            minWidth: 5.625,
            maxWidth: 5.625,
            render: (record) => (
              <ProductImage
                image={record?.image || NoImage}
                clickToOpenModal
                line={record}
                supplier={record?.supplier as Supplier}
              />
            ),
          },
          {
            title: t('general.item', 'Item'),
            key: 'item',
            headerCellStyles: 'pl-0 pr-4 py-3',
            cellStyles: 'pl-0 pr-4 py-3',
            minWidth: 17.5,
            maxWidth: 18.75,
            grow: type !== ProductListType.Shop,
            render: (record: ProductType) =>
              record?.product && (
                <ProductItem product={record?.product} clickToOpenModal supplier={record?.supplier} line={record} />
              ),
          },
          {
            title: t('general.supplier', 'Supplier'),
            key: 'supplier',
            headerCellStyles: 'pl-0 pr-4 py-3',
            cellStyles: 'pl-0 pr-4 py-3',
            minWidth: 15,
            grow: true,
            render: renderSupplier,
          },
          {
            title: t('general.quotes', 'Quotes'),
            key: 'quotes',
            headerCellStyles: 'py-3 text-center',
            cellStyles: 'text-center py-3',
            minWidth: 4.375,
            maxWidth: 4.375,
            render: ({ availableQuotes, product, supplier, image, quantity }: ProductType) => (
              <SupplierQuotesButton
                product={product}
                prices={availableQuotes}
                currentSupplierId={supplier?.id}
                image={image}
                basicShop
                quantity={quantity}
              />
            ),
          },
          {
            title: t('general.unitPrice', 'Unit Price'),
            key: 'unitPrice',
            headerCellStyles: 'pr-4 py-3 text-right',
            cellStyles: 'min-w-[6.875rem] max-w-[9.375rem] pr-4 py-3 text-right',
            minWidth: 6.875,
            maxWidth: 9.375,
            render: renderUnitPrice,
          },
          {
            title: t('general.addItem', 'Add Item'),
            key: 'addItem',
            headerCellStyles: 'py-3 text-center',
            cellStyles: 'py-3 text-center',
            minWidth: 11.25,
            maxWidth: 11.25,
            render: renderAddItem,
          },
          {
            title: t('general.more', 'More'),
            key: 'more',
            headerCellStyles: ' py-3 text-center',
            cellStyles: 'py-3 text-center',
            minWidth: 4.375,
            maxWidth: 4.375,
            render: renderMoreOptions,
          },
        ]}
      />
    </>
  )
}

export default memo(ProductsListView)
