import { graphql } from 'msw'

import { CreateReceivingDocumentMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const CreateReceivingDocument = graphql.mutation<CreateReceivingDocumentMutation>(
  'CreateReceivingDocument',
  (_, res, ctx) => {
    return res(
      ctx.data({
        createReceivingDocument: {
          receivingDocument: {
            id: 6659890,
            purchaseOrder: {
              id: 16566710,
              purchaseOrderNumber: 'PO04331',
              __typename: 'PurchaseOrder',
            },
            workflowState: 'open',
            __typename: 'ReceivingDocument',
          },
          __typename: 'CreateReceivingDocumentMutationPayload',
        },
      })
    )
  }
)
