import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import AttachmentItem from './AttachmentItem'

import {
  GetRequisitionAttachmentsDocument,
  RansackDirection,
  RequisitionAttachment,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { ControlBarIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

export default function RequisitionAttachments() {
  const { t } = useTranslation()
  const { requisitionId } = useParams<{ requisitionId: string }>()

  const { data, loading, error } = useQuery(GetRequisitionAttachmentsDocument, {
    variables: {
      requisitionId: Number(requisitionId),
      sort: [
        {
          direction: RansackDirection.Desc,
          property: 'createdAt',
        },
      ],
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const attachemnts = extractEdges<RequisitionAttachment>(data?.currentPurchaser?.requisition?.requisitionAttachments)

  return (
    <QueryResult loading={loading} error={error}>
      <div className=" flex items-center">
        <ControlBarIcon className=" h-10 w-10" />
        <p className="ml-3 text-sm">
          {data?.currentPurchaser?.requisition?.requisitionNumber} {t('general.attachments', 'Attachments')}
        </p>
      </div>
      <div className="my-4 space-y-4 rounded-md bg-white p-5 shadow-sm">
        <span>
          <strong>{t('general.attachments', 'Attachments')}</strong>
          <p className="text-sm text-gray-500">
            {t(
              'requisitionInformation.attachments.description',
              'Files can be attached here as a place to store documents that relate to this Requisition.'
            )}
          </p>
        </span>
        <div className="rounded-md border">
          {attachemnts.map((attachment) => (
            <AttachmentItem key={attachment.id} attachment={attachment} />
          ))}
          {attachemnts.length === 0 && (
            <div className="flex min-h-[3.75rem] items-center justify-center bg-gray-100 px-4">
              <span className="text-sm">
                {t('requisition.noAttachmentsInRequisition', 'There are no files attached to this Requisition.')}
              </span>
            </div>
          )}
        </div>
      </div>
    </QueryResult>
  )
}
