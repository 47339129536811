export default function ReorderIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.3 3.61999C17.1773 3.49905 17.0122 3.43086 16.84 3.42999C16.6702 3.42999 16.5075 3.49741 16.3874 3.61744C16.2674 3.73746 16.2 3.90024 16.2 4.06999V5.81999C15.596 4.89615 14.8015 4.11199 13.87 3.51999C12.5334 2.6744 10.9681 2.26267 9.38846 2.34119C7.80883 2.4197 6.29208 2.98463 5.04596 3.95859C3.79984 4.93255 2.88527 6.26793 2.42751 7.78183C1.96976 9.29574 1.99121 10.9142 2.48891 12.4153C2.98661 13.9166 3.93624 15.2272 5.20772 16.1679C6.47921 17.1085 8.0104 17.633 9.59155 17.6697C11.1727 17.7063 12.7265 17.2532 14.0402 16.3726C15.354 15.4918 16.3633 14.2266 16.93 12.75C17.0002 12.5683 16.9955 12.3662 16.9167 12.188C16.838 12.0098 16.6916 11.8702 16.51 11.8C16.3283 11.7297 16.1261 11.7345 15.948 11.8132C15.7698 11.892 15.6302 12.0383 15.56 12.22C15.157 13.2743 14.4734 14.1983 13.5831 14.8922C12.6929 15.5861 11.6299 16.0233 10.5091 16.1567C9.3883 16.29 8.25239 16.1143 7.22421 15.6487C6.19604 15.183 5.31476 14.4451 4.6757 13.5147C4.03663 12.5844 3.66411 11.497 3.59842 10.3702C3.53274 9.2434 3.77639 8.1201 4.30303 7.1218C4.82967 6.12349 5.61925 5.28817 6.58635 4.70621C7.55345 4.12425 8.6613 3.8178 9.78996 3.81999C10.9551 3.81377 12.0975 4.1436 13.08 4.76999C13.9956 5.34233 14.7441 6.14602 15.25 7.09999H13.16C12.9893 7.10261 12.8265 7.17225 12.7068 7.29387C12.587 7.41548 12.52 7.57931 12.52 7.74999C12.5225 7.91892 12.5908 8.08021 12.7103 8.19968C12.8297 8.31915 12.991 8.3874 13.16 8.38999H16.84C17.0092 8.38865 17.171 8.32032 17.29 8.19999C17.3515 8.1425 17.4001 8.07272 17.4328 7.99519C17.4655 7.91767 17.4816 7.83412 17.48 7.74999V4.06999C17.4811 3.98681 17.4659 3.90422 17.435 3.82699C17.404 3.74975 17.3582 3.67939 17.3 3.61999Z"
        fill="currentColor"
      />
    </svg>
  )
}
