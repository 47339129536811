import TileViewCardItem from './TileViewCardItem'

import { ReceivingDocumentLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'

interface TileViewProps {
  receivingNoteLineItems: ReceivingDocumentLineItem[]
  isReadOnly: boolean
}

export default function TileView({ receivingNoteLineItems, isReadOnly }: TileViewProps) {
  return (
    <div className="space-y-5">
      {receivingNoteLineItems.map((receivingNoteLineItem, index) => (
        <TileViewCardItem
          key={index}
          lineIndex={index}
          receivingDocumentLineItem={receivingNoteLineItem}
          isReadOnly={isReadOnly}
        />
      ))}
    </div>
  )
}
