import { NetworkStatus, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import ReceivingNoteSummary from '../summary'

import { GetPoReceivingNoteDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ReceivingNoteState } from '@/modules/receiving-notes/types'
import { Button, ConfirmDialog } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { ChevronLeftIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

export default function ReceivingNote() {
  const { receivingNoteId, purchaseOrderId } = useParams<{ receivingNoteId: string; purchaseOrderId: string }>()
  const { t } = useTranslation()
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const navigate = useNavigate()

  const {
    data: GRData,
    networkStatus,
    previousData: GRPreviousData,
  } = useQuery(GetPoReceivingNoteDocument, {
    variables: {
      receivingDocumentId: Number(receivingNoteId),
      purchaseOrderId: Number(purchaseOrderId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
  })

  const loading = networkStatus === NetworkStatus.loading

  const data = GRData || GRPreviousData

  useEffect(() => {
    if (data) {
      data.currentPurchaser?.purchaseOrder?.receivingDocument?.workflowState !== ReceivingNoteState.Open &&
        navigate(
          generatePath('/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId', {
            purchaseOrderId: String(purchaseOrderId),
            receivingNoteId: String(receivingNoteId),
          })
        )
    }
  }, [data])

  return (
    <>
      <Helmet>
        <title>{`${data?.currentPurchaser?.purchaseOrder?.receivingDocument?.receivingDocumentNumber || ''} ${t(
          'general.receiveGoods',
          'Receive Goods'
        )}`}</title>
      </Helmet>
      <header className="px-4 py-3">
        {!loading && (
          <Button
            data-testid="cancel-receiving-note"
            className="flex h-11 w-fit items-center justify-between gap-x-2 rounded-md bg-gray-200 px-4"
            onClick={() => setOpenConfirmDialog(true)}
          >
            <ChevronLeftIcon className="-ml-2 h-5 w-5" />
            <p className="text-sm">{t('general.saveAsDraft', 'Save as Draft')}</p>
          </Button>
        )}
      </header>
      <ReceivingNoteSummary />
      <ConfirmDialog
        theme={Themes.Primary}
        isOpen={openConfirmDialog}
        setIsOpen={setOpenConfirmDialog}
        title={t('general.saveAsDraft', 'Save as Draft')}
        description={t(
          'receivingNotes.saveAsDraftDescription',
          'By choosing Save as Draft your work here will be saved and you will be returned to the Receiving Notes area of the Purchase Order. The Receiving Quantities you I have recorded here will not count while the Receiving Note Is in a Draft state. You will be able to resume this draft again anytime again in the future.'
        )}
        primaryButtonLabel={t('general.saveAsDraft', 'Save as Draft')}
        secondaryButtonLabel={t('general.keepReceiving', 'Keep Receiving')}
        onPrimaryBtnClick={() =>
          navigate(
            generatePath('/purchase-orders/:purchaseOrderId/receiving-notes/', {
              purchaseOrderId: String(purchaseOrderId),
            })
          )
        }
      />
    </>
  )
}
