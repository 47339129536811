import { useQuery } from '@apollo/client'

import { CreditNote, GetAllCreditNotesDocument, PageInfo } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export default function useGetAllCreditNotes() {
  const paginationResultsPerPage = 20
  const { data, networkStatus, error, refetch } = useQuery(GetAllCreditNotesDocument, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: paginationResultsPerPage,
      after: null,
      filter: {},
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  return {
    creditNotes: extractEdges<CreditNote>(data?.currentPurchaser?.creditNotes),
    loadingStates: checkNetworkStatus(networkStatus),
    error,
    refetch,
    paginationResultsPerPage,
    pageInfo: data?.currentPurchaser?.creditNotes?.pageInfo || ({} as PageInfo),
  }
}
