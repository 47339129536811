import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { POAction } from '../../../../shared/components/table/table-column-items/POAction'
import { POInvoice } from '../../../../shared/components/table/table-column-items/POInvoice'
import PurchaseOrderInvoicedStatus from '../PurchaseOrderInvoicedStatus'
import PurchaseOrderReceivedStatus from '../PurchaseOrderReceivedStatus'

import { PurchaseOrder } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PurchaseOrderStatuses } from '@/modules/purchase-orders/types'
import { Fallback, MoreOptionsMenu, PPNewDetailLink } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { ShortDateItem, TotalTaxInclusive } from '@/modules/shared/components/table/table-column-items'
import { POSentStatus } from '@/modules/shared/components/table/table-column-items/POSentStatus'
import PurchaseOrderReceiptsButton from '@/modules/shared/components/table/table-column-items/PurchaseOrderReceiptsButton'
import { OptionIcon } from '@/modules/shared/icons'

interface TableViewProps {
  purchaseOrders: PurchaseOrder[]
}

export const isPOMatchable = (purchaseOrder: PurchaseOrder) => {
  return (
    purchaseOrder.status !== PurchaseOrderStatuses.Closed && purchaseOrder.status !== PurchaseOrderStatuses.Cancelled
  )
}

function TableView(props: TableViewProps) {
  const { purchaseOrders } = props
  const { t } = useTranslation()

  const renderPurchaseOrder = ({ purchaseOrderNumber, id }: PurchaseOrder) => {
    return (
      <PPNewDetailLink type="purchase-order" id={String(id)}>
        {purchaseOrderNumber}
      </PPNewDetailLink>
    )
  }

  const renderReference = ({ requisition }: PurchaseOrder) => {
    return <Fallback condition={!!requisition?.reference}>{requisition?.reference}</Fallback>
  }

  const renderRequisition = ({ requisition }: PurchaseOrder) => {
    return (
      <Fallback condition={!!requisition}>
        <PPNewDetailLink type="requisition" id={String(requisition?.id)}>
          {requisition?.requisitionNumber}
        </PPNewDetailLink>
      </Fallback>
    )
  }

  const renderSupplier = ({ supplier }: PurchaseOrder) => {
    return <p className="break-words">{supplier?.name}</p>
  }

  const renderAccountCode = ({ department, account }: PurchaseOrder) => {
    return (
      <Fallback as="div" className="break-words" condition={!!department || !!account}>
        <p>{department?.name}</p>
        <p className="text-xs">{account?.code}</p>
      </Fallback>
    )
  }

  const renderReceived = ({ receivedStatus }: PurchaseOrder) => {
    return <span>{receivedStatus && <PurchaseOrderReceivedStatus receivedStatus={receivedStatus} />}</span>
  }

  const renderInvoiced = (record: PurchaseOrder) => {
    return <span>{record.invoicedStatus && <PurchaseOrderInvoicedStatus purchaseOrder={record} />}</span>
  }

  const renderMoreOptions = ({ id }: PurchaseOrder) => {
    return (
      <MoreOptionsMenu showPrint showOpenInPPlus showHelp showPurchaseOrderLink purchaseOrderId={id}>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </MoreOptionsMenu>
    )
  }

  return (
    <Table
      dataTestId="purchase-orders-table-view"
      dataSource={purchaseOrders}
      keyExtractor={(record, index) => String(`${record.id}_${index}`)}
      columns={[
        {
          title: t('general.purchaseOrder', 'Purchase Order'),
          key: 'purchaseOrder',
          headerCellStyles: 'px-4 py-3 ',
          cellStyles: 'px-4 py-3',
          minWidth: 8.125,
          maxWidth: 8.125,
          render: renderPurchaseOrder,
        },
        {
          title: t('general.reference', 'Reference'),
          key: 'reference',
          headerCellStyles: 'px-2 py-3 ',
          cellStyles: 'px-2 py-3',
          minWidth: 11.25,
          maxWidth: 13.75,
          render: renderReference,
        },
        {
          title: t('general.requisition', 'Requisition'),
          key: 'requisition',
          headerCellStyles: 'pl-4 pr-0 py-3 ',
          cellStyles: 'pl-4 pr-0 py-3 ',
          minWidth: 6.875,
          maxWidth: 6.875,
          render: renderRequisition,
        },
        {
          title: t('general.supplier', 'Supplier'),
          key: 'supplier',
          headerCellStyles: 'px-2 py-3 ',
          cellStyles: 'px-2 py-3 ',
          minWidth: 12.5,
          maxWidth: 13.75,
          render: renderSupplier,
        },
        {
          title: t('general.accountCode', 'Account Code'),
          key: 'accountCode',
          headerCellStyles: 'px-4 py-3 ',
          cellStyles: 'px-4 py-3 ',
          minWidth: 15,
          grow: true,
          render: renderAccountCode,
        },
        {
          title: t('general.status', 'Status'),
          key: 'status',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 6.875,
          maxWidth: 6.875,
          render: (record) => <POSentStatus record={record} />,
        },
        {
          title: t('general.received', 'Received'),
          key: 'received',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 8.75,
          maxWidth: 8.75,
          render: renderReceived,
        },
        {
          title: t('general.invoiced', 'Invoiced'),
          key: 'invoiced',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 8.75,
          maxWidth: 8.75,
          render: renderInvoiced,
        },
        {
          title: t('general.deliveryDate', 'Delivery Date'),
          key: 'deliveryDate',
          headerCellStyles: 'px-1 py-3 text-center',
          cellStyles: 'px-1 py-3 text-center',
          minWidth: 7.5,
          maxWidth: 7.5,
          render: ({ expectedDeliveryDate }) => <ShortDateItem date={expectedDeliveryDate} />,
        },
        {
          title: t('general.receipts', 'Receipts'),
          key: 'receipts',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: (record) => (
            <div className="flex items-center justify-center">
              <PurchaseOrderReceiptsButton purchaseOrder={record} />
            </div>
          ),
        },
        {
          title: t('general.invoices', 'Invoices'),
          key: 'invoices',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: (record) => <POInvoice record={record} />,
        },
        {
          title: t('general.total', 'Total'),
          key: 'total',
          headerCellStyles: 'px-4 py-3 text-right',
          cellStyles: 'px-4 py-3 text-right',
          minWidth: 8,
          maxWidth: 9.375,
          render: ({ totalValue }) => <TotalTaxInclusive totalValue={totalValue} />,
        },
        {
          title: t('general.actions', 'Actions'),
          key: 'actions',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: (record) => <POAction record={record} />,
        },
        {
          title: t('general.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: renderMoreOptions,
        },
      ]}
    />
  )
}

export default memo(TableView)
