import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import InvoiceNumber from './InvoiceNumber'

import { Account, Invoice, Purchaser, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useGetInvoice, useUpdateInvoice } from '@/modules/invoices/hooks'
import { invoiceFilterText } from '@/modules/invoices/pages/all-invoices/translationUtils'
import { InvoiceStatuses } from '@/modules/invoices/types'
import { DeptAndAccount } from '@/modules/requisitions/pages/create/form-elements'
import SupplierDetailsModal from '@/modules/requisitions/pages/view-cart/summary/SupplierDetailsModal'
import {
  Fallback,
  PPNewDetailLink,
  QueryResult,
  SimpleTableItem,
  SimpleTableItemWithTooltip,
} from '@/modules/shared/components'
import FormDate from '@/modules/shared/components/create-form/FormDate'
import FormInput from '@/modules/shared/components/create-form/FormInput'
import { useAlert, useMoney } from '@/modules/shared/hooks'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'
import SaveFooter from '@/modules/suppliers/pages/supplier/supplier-details/form-elements/SaveFooter'

export type InvoiceFormInputs = {
  reference: string
  invoiceNumber: string
  invoiceDate: string
  departmentId: number
  accountId: number
}

export default function InvoiceSettings() {
  const { t } = useTranslation()
  const { invoiceId } = useParams<{ invoiceId: string }>()
  const { formatMoney } = useMoney()
  const [remount, setRemount] = useState(0)
  const [showSupplierDetailsModal, setShowSupplierDetailsModal] = useState<number | null>(0)
  const [updateInvoiceSetting, { loading: updateLoading }] = useUpdateInvoice()
  const { alertDialog } = useAlert()

  const {
    invoiceNumber,
    purchaseOrder,
    supplier,
    totalValueExTax,
    totalTaxValue,
    totalValue,
    department,
    account,
    createdAt,
    inception,
    invoiceDate,
    state,
    loading,
    error,
    invoice,
    getDefaultValues,
  } = useGetInvoice()
  const disabled = ![InvoiceStatuses.Open, InvoiceStatuses.Flagged].includes(state as InvoiceStatuses)

  const formMethods = useForm<InvoiceFormInputs>({
    defaultValues: getDefaultValues(invoice as Invoice),
  })

  const onSubmit: SubmitHandler<InvoiceFormInputs> = (data) => {
    const { reference, invoiceNumber, invoiceDate, departmentId, accountId } = data
    updateInvoiceSetting({
      variables: {
        input: {
          id: Number(invoiceId),
          reference,
          invoiceNumber,
          invoiceDate,
          departmentId,
          accountId,
        },
      },
      onCompleted(data) {
        if (!!data.updateInvoice.errors?.length) {
          alertDialog({
            type: 'error',
            title: t('createInvoice.error.title', 'Invoice Number Already In Use'),
            message: t(
              'createInvoice.error.description',
              'The Invoice Number you have entered is already in use for the Supplier you have selected. Please update the Invoice Number so that it is unique for the selected Supplier.'
            ),
            buttonText: t('general.okayGotIt', 'Okay, Got It'),
          })
        } else {
          formMethods.reset(getDefaultValues(data?.updateInvoice?.invoice as Invoice))
        }
      },
    })
  }

  const onDiscard = () => {
    formMethods.reset(getDefaultValues(invoice as Invoice))
    setRemount(remount + 1)
  }

  return (
    <QueryResult loading={loading} error={error}>
      <section
        className="flex flex-col-reverse justify-between gap-10 xl:flex-row"
        data-testid="invoice-editable-settings"
      >
        <section className="mb-20 xl:w-[52.5rem]">
          <p className="my-3 text-lg font-bold">{t('general.general', 'General')}</p>
          <hr />
          {state && disabled && (
            <p className="mt-8 rounded-md bg-gray-200 px-5 py-4 text-sm" data-testid="not-editable-message">
              {t(
                'invoices.invoice.settings.notEditableMessage',
                "Changes can't be made to the Settings of an Invoice in a {{state}} state.",
                {
                  state: invoiceFilterText(state),
                }
              )}
            </p>
          )}
          <FormProvider {...formMethods}>
            <form key={remount} onSubmit={formMethods.handleSubmit(onSubmit)}>
              <InvoiceNumber
                disabled={disabled}
                title={t('general.invoiceNumber', 'Invoice Number')}
                description={t(
                  'invoices.invoice.settings.form.invoiceNumber.desc',
                  'The document number used to identify this Invoice. The Invoice number does not need to be unique, but we recommend that it is kept unique. If the Invoice was created by PurchasePlus Paperless Invoicing, the Invoice Number will be found when the original PDF was scanned. You can make changes to the Invoice Number here if needed.'
                )}
              />
              <FormInput
                disabled={disabled}
                title={t('general.reference', 'Reference')}
                description={t(
                  'invoices.invoice.settings.form.reference.desc',
                  'A brief description of the Purchase Order. The Reference will be copied from the Purchase Requisition that this Purchase Order was created from. The Reference will be visible to Suppliers and anyone at your organisation. You can make changes to the Reference here if needed while the Purchase Order is in a Not Sent state.'
                )}
                register="reference"
                placeholder={t('createRequisition.reference.placeholder', 'E.g. Beverage Order')}
              />
              <FormDate
                register="invoiceDate"
                disabled={disabled}
                defaultDate={invoiceDate}
                title={t('general.invoiceDueDate', 'Invoice Due Date')}
                description={t(
                  'invoices.invoice.settings.form.dueDate.desc',
                  'This is the Due Date of the Invoice. If the Invoice was created by PurchasePlus Paperless Invoicing, the Invoice Due Date will have been found when the original PDF was scanned. You can make changes to the Due Date here if needed.'
                )}
              />
              <DeptAndAccount
                disabled={disabled}
                defaultDepartmentValue={department as Purchaser}
                defaultAccountValue={(account as Account) || undefined}
                title={t('invoices.invoice.settings.form.deptAndAccount.title', 'Department and Account Code')}
                description={t(
                  'invoices.invoice.settings.form.deptAndAccount.desc',
                  'If applicable, select a Department then an Account Code to record this Invoice against. Your organisation may use Department and Account Codes for recording expenses. If the Invoice is matched to a Purchase Order, the Department and Account Code on the Purchase Order will be set here. You can make changes to these fields here if needed.'
                )}
              />
              <div className="h-96" />
              {formMethods.formState.isDirty && formMethods.formState.isValid && (
                <SaveFooter onDiscardChanges={onDiscard} loading={updateLoading} />
              )}
            </form>
          </FormProvider>
        </section>
        <section className="xl:w-96">
          <section className="divide-y rounded-md bg-white shadow">
            <h2 className="px-5 py-4 font-bold">{t('general.invoiceDetails', 'Invoice Details')}</h2>
            <div className="flex flex-col gap-y-3 p-5 text-sm" data-testid="invoice-information">
              <SimpleTableItem title={t('general.invoiceNumber', 'Invoice Number')} value={invoiceNumber} />
              <SimpleTableItemWithTooltip
                tooltip={t(
                  'invoices.invoice.settings.matchedPOTooltip',
                  'The Purchase Order that has been matched to this Invoice, if any. An Invoice must have a Purchase Order matched to it before it can be reconciled.'
                )}
                title={t('general.matchedPurchaseOrder', 'Matched Purchase Order')}
                value={
                  <Fallback condition={!!purchaseOrder?.id}>
                    <PPNewDetailLink openNewTab type="purchase-order" id={String(purchaseOrder?.id)}>
                      {purchaseOrder?.purchaseOrderNumber}
                    </PPNewDetailLink>
                  </Fallback>
                }
              />
              <SimpleTableItemWithTooltip
                tooltip={t(
                  'invoices.invoice.settings.invoiceTypeTooltip',
                  'If the Invoice was manually created the Invoice type will be “Manual”. If the Invoice was created via Paperless Invoicing, the Invoice type will be “PDF”.'
                )}
                title={t('general.invoiceType', 'Invoice Type')}
                value={inception?.toUpperCase()}
              />
              <SimpleTableItemWithTooltip
                tooltip={t(
                  'invoices.invoice.settings.createdOnTooltip',
                  'The time and date the Invoice was created in PurchasePlus.'
                )}
                title={t('general.createdOn', 'Created On')}
                value={formatDate(Dates.Long, createdAt)}
              />
              <SimpleTableItem
                title={t('general.supplier', 'Supplier')}
                value={
                  <span className="cursor-pointer text-primary" onClick={() => setShowSupplierDetailsModal(1)}>
                    {supplier?.name}
                  </span>
                }
              />
              <SimpleTableItem
                title={t('general.totalExcludingTax', 'Total Excluding Tax')}
                value={formatMoney(totalValueExTax)}
              />
              <SimpleTableItem title={t('general.tax', 'Tax')} value={formatMoney(totalTaxValue)} />
              <SimpleTableItem
                title={t('general.totalIncludingTax', 'Total Including Tax')}
                value={formatMoney(totalValue)}
              />
              <SupplierDetailsModal
                supplier={supplier as Supplier}
                showModal={!!showSupplierDetailsModal}
                setShowModal={setShowSupplierDetailsModal}
              />
            </div>
          </section>
        </section>
      </section>
    </QueryResult>
  )
}
