import { useTranslation } from 'react-i18next'

import SideNavLink from '../SideNavLink'
import { SideBarItem } from './PurchaserNavigation'

import { Accordion } from '@/modules/shared/components'
import { CatalogIcon, PlusIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface CatalogsSideBarItemProps extends SideBarItem {
  catalogRoutesMatch: boolean
}

export const CatalogsSideBarItem = ({
  catalogRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
}: CatalogsSideBarItemProps) => {
  const { t } = useTranslation()
  return (
    <Accordion
      titlePrefixIcon={<CatalogIcon className="h-7 w-7" />}
      title={t('general.catalogs', 'Catalogs')}
      activeColor={catalogRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === 4}
      handleTriggerClick={() => changeOpenAccordionKey(4)}
      buttonClassName="h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="catalogs-navigation"
        sideNavLinkItems={[
          {
            key: t('general.newCatalog', 'New Catalog'),
            target: '/catalogs/create',
            icon: <PlusIcon className="h-6 w-6 text-gray-500" />,
          },
          {
            key: t('general.viewAll', 'View All'),
            target: '/catalogs',
          },
        ]}
      />
    </Accordion>
  )
}
