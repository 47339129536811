import { useTranslation } from 'react-i18next'

import { InvoiceStatuses } from '../types'

export default function useInvoiceLineItemModalText(state: InvoiceStatuses) {
  const { t } = useTranslation()

  switch (state) {
    case InvoiceStatuses.Parked:
      return {
        title: t('general.invoiceIsParked', 'Invoice is Parked'),
        description: t(
          'invoices.invoice.lineStatusModal.parkedBody',
          'Changes cannot be made to an Invoice while it is in a Parked state. You will need to Re-Open the Invoice to make changes.'
        ),
        secondaryButton: t('general.reopenInvoice', 'Re-Open Invoice'),
      }
    case InvoiceStatuses.Cancelled:
      return {
        title: t('general.invoiceIsCancelled', 'Invoice is Cancelled'),
        description: t(
          'invoices.invoice.lineStatusModal.cancelledBody',
          'Changes cannot be made to an Invoice while it is in a Cancelled state. You will need to Re-Open the Invoice to make changes.'
        ),
        secondaryButton: t('general.reopenInvoice', 'Re-Open Invoice'),
      }
    case InvoiceStatuses.Reconciled:
      return {
        title: t('invoices.invoice.lineStatusModal.reconciledTitle', 'Invoice is Already Reconciled'),
        description: t(
          'invoices.invoice.lineStatusModal.reconciledBody',
          'Changes cannot be made to an Invoice while it is in a Reconciled state. You will need to Re-Open the Invoice to make changes.'
        ),
        secondaryButton: t('general.reopenInvoice', 'Re-Open Invoice'),
      }
    case InvoiceStatuses.Processing:
      return {
        title: t('general.invoiceIsProcessing', 'Invoice is Processing'),
        description: t(
          'invoices.invoice.lineStatusModal.processingBody',
          'Changes cannot be made to an Invoice while it is in a Processing state. You will need to Halt the Invoice to revert it to a Reconciled state.'
        ),
        secondaryButton: '',
      }
    case InvoiceStatuses.Processed:
      return {
        title: t('general.invoiceIsProcessed', 'Invoice is Processed'),
        description: t(
          'invoices.invoice.lineStatusModal.processedBody',
          'Changes cannot be made to an Invoice when it is in a Processed state. The invoice has been finalised and it can no longer be modified.'
        ),
        secondaryButton: '',
      }
    default:
      return { title: '', description: '', secondaryButton: '' }
  }
}
