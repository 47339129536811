import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Comment, GetCreditNoteCommentsDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { QueryResult } from '@/modules/shared/components'
import CommentSection from '@/modules/shared/components/comment-section/CommentSection'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { ControlBarIcon } from '@/modules/shared/icons'
import { Document, DocumentType } from '@/modules/shared/types'
import { extractEdges } from '@/modules/shared/utils'

export default function CreditNoteComments() {
  const { t } = useTranslation()
  const { creditNoteId } = useParams<{ creditNoteId: string }>()
  const { data, loading, error, refetch } = useQuery(GetCreditNoteCommentsDocument, {
    variables: {
      creditNoteId: Number(creditNoteId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })
  const publicComments = extractEdges<Comment>(data?.currentPurchaser?.creditNote?.publicComments)
  const privateComments = extractEdges<Comment>(data?.currentPurchaser?.creditNote?.privateComments)
  return (
    <QueryResult loading={loading} error={error}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <ControlBarIcon className=" h-10 w-10" />
          <p className="ml-3 text-sm">
            {data?.currentPurchaser?.creditNote?.creditNoteNumber} {t('general.comments', 'Comments')}
          </p>
        </div>
      </div>
      <CommentSection
        publicComments={publicComments}
        privateComments={privateComments}
        docId={Number(creditNoteId)}
        type={DocumentType.CreditNote}
        refetch={refetch}
        translate={Document.CreditNote}
      />
    </QueryResult>
  )
}
