import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { SUPPORT_LINK } from '../../constants'
import Button from '../button'

interface CreateFooterProps {
  getHelpText: string
  createButtonText: string
  disabled?: boolean
  createLoading: boolean
}

export default function CreateFooter({ getHelpText, createButtonText, disabled, createLoading }: CreateFooterProps) {
  const { t } = useTranslation()

  return (
    <section className="mt-8 flex flex-col-reverse items-center justify-between gap-y-2 md:flex-row">
      <a className="text-sm text-primary" href={SUPPORT_LINK} target="_blank" rel="noreferrer">
        {getHelpText}
      </a>
      <div className="flex w-full flex-col-reverse gap-2 md:w-fit md:flex-row">
        <Link className="flex h-11 items-center justify-center rounded-md bg-gray-200 px-5 text-center text-sm" to="/">
          {t('general.cancel', 'Cancel')}
        </Link>
        <Button
          data-testid="create-button"
          type="submit"
          disabled={disabled}
          loading={createLoading}
          className="h-11 rounded-md bg-primary px-5 text-sm text-white"
        >
          {createButtonText}
        </Button>
      </div>
    </section>
  )
}
