import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Outlet, generatePath, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'

import PurchaseOrderStatus from '../../all-purchase-order/PurchaseOrderStatus'
import CancelPurchaseOrderModal from './CancelPurchaseOrderModal'
import Details from './Details'

import { GetPurchaseOrderDocument, PurchaseOrder } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCreateReceivingDocument } from '@/modules/purchase-orders/hooks'
import { PurchaseOrderStatuses } from '@/modules/purchase-orders/types'
import { statusText } from '@/modules/purchase-orders/utils/statusText'
import PurchaseOrderModal from '@/modules/requisitions/pages/confirmation/requisition-purchase-orders/PurchaseOrderModal'
import RelatedDocumentsModal from '@/modules/requisitions/pages/requisition/page-header/related-documents-modal'
import { Breadcrumb, Button, ConfirmDialog, QueryResult, ThemeWrapper, Tooltip } from '@/modules/shared/components'
import { MoreOptionsMenu } from '@/modules/shared/components'
import HeaderTabs, { TabItem } from '@/modules/shared/components/header-tabs'
import SuccessModal from '@/modules/shared/components/success-modal'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useMoney } from '@/modules/shared/hooks'
import {
  ArrowUpIcon,
  CheckIcon,
  HomeIcon,
  InfoIcon,
  OptionIcon,
  PurchaseOrderIcon,
  TreeDiagramIcon,
  TruckIcon,
} from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'
import { findActiveRoute } from '@/modules/shared/utils'

function POPageHeader() {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const navigate = useNavigate()
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>()
  const [showInfo, setShowInfo] = useState(false)
  const [isOpen, setIsOpen] = useState<number | null>(null)
  const [showRelatedDocModal, setShowRelatedDocModal] = useState(false)
  const [showConfirmCancel, setShowConfirmCancel] = useState(false)
  const [showCantCancel, setShowCantCancel] = useState(false)
  const [showPurchaseOrderCancelled, setShowPurchaseOrderCancelled] = useState(false)

  const location = useLocation()

  const { data, loading } = useQuery(GetPurchaseOrderDocument, {
    variables: {
      purchaseOrderId: Number(purchaseOrderId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const { onCreateReceivingDocument, createReceivingDocumentLoading, modalProps, InventoryModal } =
    useCreateReceivingDocument()

  const purchaseOrder = data?.currentPurchaser?.purchaseOrder
  const { id, purchaseOrderNumber, totalValue, sentDate, status, invoices, receivingDocuments } = purchaseOrder || {}
  const actionList: TabItem[] = [
    { target: 'summary', label: t('general.productSummary', 'Product Summary') },
    { target: 'settings', label: t('general.settings', 'Settings') },
    {
      target: 'comments',
      label: t('general.comments', 'Comments'),
      count: Number(data?.currentPurchaser?.purchaseOrder?.commentsCount),
    },
    { target: 'invoices', label: t('general.invoices', 'Invoices') },
    { target: 'receiving-notes', label: t('general.receivingNotes', 'Receiving Notes') },
    { target: 'advanced', label: t('general.advanced', 'Advanced') },
    { target: 'audit-log', label: t('general.auditLog', 'Audit Log'), hiddenOnHeaderTabs: true },
  ]
  const sent = Boolean(sentDate)

  const isRedirect = matchPath('/purchase-orders/:purchaseOrderId', location.pathname)

  const onCancelPurchaseOrder = () => {
    if (!invoices?.edges?.length && !receivingDocuments?.edges?.length) {
      setShowConfirmCancel(true)
    } else {
      setShowCantCancel(true)
    }
  }

  useEffect(() => {
    if (isRedirect) {
      navigate(generatePath('/purchase-orders/:purchaseOrderId/summary', { purchaseOrderId: String(purchaseOrderId) }))
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${purchaseOrder?.purchaseOrderNumber || ''} ${findActiveRoute(actionList, location.pathname)}`}</title>
      </Helmet>
      <QueryResult loading={loading}>
        <div className="mx-auto max-w-[95rem] space-y-5 px-4">
          <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2" data-testid="purchase-order-breadcrumb">
            <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
            <Breadcrumb.Item href="/purchase-orders">{t('general.purchaseOrders', 'Purchase Orders')}</Breadcrumb.Item>
            <Breadcrumb.Item href="#">{purchaseOrderNumber}</Breadcrumb.Item>
            <Breadcrumb.Item>{findActiveRoute(actionList, location.pathname)}</Breadcrumb.Item>
          </Breadcrumb>
          <section className="flex flex-col gap-y-2">
            <section className="flex w-full flex-col justify-between rounded-md bg-white shadow sm:flex-row">
              <div className="flex items-center gap-4 p-4">
                <ThemeWrapper theme={Themes.Primary} className="flex items-center justify-center rounded-md p-2">
                  <PurchaseOrderIcon className="h-10 w-10 text-primary" />
                </ThemeWrapper>
                <div className="flex justify-between">
                  <span>
                    <p className="text-xl font-bold" data-testid="cart-value">
                      {formatMoney(totalValue)}
                    </p>
                    <p className="text-sm">{purchaseOrderNumber}</p>
                  </span>
                </div>
              </div>
              <hr className="sm:hidden" />
              <div className="flex items-center justify-between p-4 sm:justify-center">
                <p className="text-sm text-gray-500 sm:hidden">{t('general.actions')}</p>
                <div className="flex items-center justify-center gap-x-2">
                  <Tooltip
                    content={
                      sent
                        ? t(
                            'purchaseOrders.allPurchaseOrders.table.tooltips.POSent',
                            'Purchase Order has been marked as Sent'
                          )
                        : t(
                            'purchaseOrders.allPurchaseOrders.table.tooltips.PONotSent',
                            'Click to send the Purchase Order to the Supplier'
                          )
                    }
                    showArrow={false}
                    delay={100}
                  >
                    <span>
                      <Button
                        className="flex items-center justify-center"
                        onClick={() => setIsOpen(Number(id))}
                        data-testid="purchase-order-send-2"
                      >
                        <ArrowUpIcon
                          className={classNames('h-11 w-11 cursor-pointer rounded-full p-1.5', {
                            'bg-success text-white': sent,
                            'bg-gray-200 text-gray-600': !sent,
                          })}
                        />
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip content={t('general.receiveGoods', 'Receive Goods')} showArrow={false}>
                    <Button
                      data-testid="receive-goods-button"
                      loading={createReceivingDocumentLoading}
                      className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                      onClick={() => onCreateReceivingDocument(purchaseOrderId)}
                    >
                      <TruckIcon className="h-8 w-8 text-gray-600" />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    content={t('purchaseOrders.purchaseOrder.details.info', 'Show or hide details')}
                    showArrow={false}
                  >
                    <Button
                      data-testid="show-details-button"
                      className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                      onClick={() => setShowInfo(!showInfo)}
                    >
                      <InfoIcon className="h-8 w-8 text-gray-600" />
                    </Button>
                  </Tooltip>
                  <Tooltip content={t('general.viewRelatedDocuments', 'View Related Documents')} showArrow={false}>
                    <Button
                      data-testid="related-docs-btn"
                      className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                      onClick={() => setShowRelatedDocModal(true)}
                    >
                      <TreeDiagramIcon className="h-8 w-8 text-gray-600" />
                    </Button>
                  </Tooltip>
                  <MoreOptionsMenu
                    showPrint
                    showAuditLog
                    showOpenInPPlus
                    showHelp
                    purchaseOrderId={id}
                    buttonTheme="dark"
                    showCancelPurchaseOrder={{
                      show:
                        statusText(status, sentDate) === PurchaseOrderStatuses.NotSent ||
                        statusText(status, sentDate) === PurchaseOrderStatuses.Sent,
                      onClick: onCancelPurchaseOrder,
                    }}
                  >
                    <OptionIcon className="h-8 w-8" />
                  </MoreOptionsMenu>
                </div>
              </div>
              <PurchaseOrderModal
                showModal={isOpen === id}
                setShowModal={setIsOpen}
                purchaseOrder={purchaseOrder as PurchaseOrder}
              />
              <RelatedDocumentsModal
                showModal={showRelatedDocModal}
                setShowModal={setShowRelatedDocModal}
                purchaseOrder={purchaseOrder as PurchaseOrder}
              />
            </section>
            {showInfo && <Details purchaseOrder={purchaseOrder as PurchaseOrder} />}
            <section className=" flex items-center justify-between rounded-md bg-white px-4 py-3 text-sm shadow">
              <p>{t('general.status', 'Status')}</p>
              <PurchaseOrderStatus status={statusText(status, sentDate)} />
            </section>
            <HeaderTabs actionList={actionList.filter((action) => !action.hiddenOnHeaderTabs)} />
          </section>
          <InventoryModal {...modalProps} purchaseOrderId={String(purchaseOrderId)} />
          <ConfirmDialog
            title={t('general.cancelNotAvailable', 'Cancel Not Available')}
            description={t(
              'purchaseOrder.cantCancel.description',
              "Goods on this Purchase Order have been Received or Invoiced and the Purchase Order can no longer be Cancelled. We can't allow the Purchase Order to be Cancelled because the record of the Received or Invoiced Goods relies on the Purchase Order for reporting accuracy."
            )}
            primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
            onPrimaryBtnClick={() => setShowCantCancel(false)}
            isOpen={showCantCancel}
            setIsOpen={setShowCantCancel}
            theme={Themes.Primary}
          />
          <CancelPurchaseOrderModal
            purchaseOrder={purchaseOrder as PurchaseOrder}
            setShowModal={setShowConfirmCancel}
            showModal={showConfirmCancel}
            onCompleteCancellation={() => setShowPurchaseOrderCancelled(true)}
          />
          <SuccessModal
            showModal={showPurchaseOrderCancelled}
            setShowModal={setShowPurchaseOrderCancelled}
            icon={<CheckIcon className="h-12 w-12 text-gray-600" />}
            title={t('purchaseOrder.cancelled.title', 'Purchase Order is Cancelled')}
            body={t(
              'purchaseOrder.cancelled.body',
              'You have successfully Cancelled the Purchase Order and changes can no longer be made to it now it is in a Cancelled state.'
            )}
          />
          <Outlet />
        </div>
      </QueryResult>
    </>
  )
}

export default POPageHeader
