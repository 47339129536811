import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { RequisitionLine, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Checkbox } from '@/modules/shared/components'
import ProductModal from '@/modules/shared/components/product-modal/ProductModal'
import { useMoney } from '@/modules/shared/hooks'

interface LinkInvoiceModalTableRowProps {
  line: RequisitionLine
  supplier: Supplier
  selectedLineId: number | null
  setSelectedLineId?: Dispatch<SetStateAction<number | null>>
  canBeUpdated: boolean
}

export default function LinkInvoiceModalTableRow({
  line,
  supplier,
  selectedLineId,
  setSelectedLineId,
  canBeUpdated,
}: LinkInvoiceModalTableRowProps) {
  const { id, product, quantity, unitPrice, taxPercentage } = line || {}
  const [showProductModal, setShowProductModal] = useState(false)
  const { formatMoney } = useMoney()
  const { t } = useTranslation()

  return (
    <tr className="rounded-md border">
      <td className="w-full py-3 pl-4">
        <span className="cursor-pointer text-primary" onClick={() => setShowProductModal(true)}>
          {product?.brand} {product?.itemDescription}
        </span>
        <span className="text-xxs text-gray-500 sm:text-xs">
          <p>
            {formatProductItem({
              itemMeasure: product?.itemMeasure,
              itemPackName: product?.itemPackName,
              itemSize: product?.itemSize,
              itemSellUnit: product?.concatenatedSellUnit,
            })}
          </p>
        </span>
        <ProductModal line={line} showModal={showProductModal} setShowModal={setShowProductModal} supplier={supplier} />
      </td>
      <td className="min-w-[5rem] text-center">{quantity}</td>
      <td className="min-w-[5rem] text-center">{formatMoney(unitPrice)}</td>
      <td className="min-w-[5rem] text-center">{`${taxPercentage}%`}</td>
      {canBeUpdated && (
        <td className="min-w-[5rem] text-center">
          <span
            className="flex justify-center"
            onClick={() => setSelectedLineId && setSelectedLineId(Number(id))}
            data-testid="select-line"
          >
            <Checkbox
              isSelected={selectedLineId === id}
              disabled={selectedLineId === id && !setSelectedLineId}
              aria-label={t('general.selectLine', 'Select Line')}
            />
          </span>
        </td>
      )}
    </tr>
  )
}
