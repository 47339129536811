import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import RequisitionStatus from '../RequisitionStatus'

import { PageInfo, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback, MoreOptionsMenu, PPNewDetailLink } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { AccountCodeItem, ShortDateItem, TotalTaxInclusive } from '@/modules/shared/components/table/table-column-items'
import { OptionIcon } from '@/modules/shared/icons'

interface TableViewProps {
  requisitions: Requisition[]
  paginationLoading: boolean
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'endCursor'>
  onNextPage: () => void
  onPreviousPage: () => void
}

function TableView(props: TableViewProps) {
  const {
    requisitions,
    pageInfo: { hasNextPage, hasPreviousPage, endCursor },
    onNextPage,
    onPreviousPage,
    paginationLoading,
  } = props
  const { t } = useTranslation()

  const renderRequisition = ({ id, requisitionNumber }: Requisition) => {
    return (
      <PPNewDetailLink type="requisition" id={String(id)}>
        {requisitionNumber}
      </PPNewDetailLink>
    )
  }
  const renderReference = ({ reference }: Requisition) => {
    return <Fallback condition={!!reference}>{reference}</Fallback>
  }
  const renderRequestor = ({ creator }: Requisition) => {
    return <Fallback condition={!!creator}>{creator?.fullName}</Fallback>
  }
  const renderType = ({ originName }: Requisition) => {
    return <Fallback condition={!!originName}>{originName}</Fallback>
  }
  const renderStatus = ({ state, id }: Requisition) => {
    return state && <RequisitionStatus state={state} requisitionId={id} />
  }

  const renderSendPO = ({ sendToSupplier }: Requisition) => {
    return sendToSupplier ? t('general.yes', 'Yes') : t('general.no', 'No')
  }
  const renderMoreOptions = ({ id }: Requisition) => {
    return (
      <MoreOptionsMenu showOpenInPPlus showPrint showHelp showViewRequisition requisitionId={id}>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </MoreOptionsMenu>
    )
  }

  return (
    <>
      <Table
        dataTestId="requisitions-table-view"
        dataSource={requisitions}
        keyExtractor={(record) => String(record.id)}
        pagination={{
          hasNextPage,
          hasPreviousPage,
          onNextPage: onNextPage,
          onPreviousPage: onPreviousPage,
          loading: paginationLoading,
          endCursor: endCursor,
        }}
        columns={[
          {
            title: t('general.requisition', 'Requisition'),
            key: 'requisition',
            headerCellStyles: 'px-4 py-3',
            cellStyles: 'px-4 py-3',
            minWidth: 8.125,
            maxWidth: 8.125,
            render: renderRequisition,
          },
          {
            title: t('general.reference', 'Reference'),
            key: 'reference',
            headerCellStyles: 'px-2 py-3',
            cellStyles: 'px-2 py-3',
            minWidth: 11.25,
            maxWidth: 13.75,
            render: renderReference,
          },
          {
            title: t('general.requestor', 'Requestor'),
            key: 'requestor',
            headerCellStyles: 'px-2 py-3',
            cellStyles: 'px-2 py-3',
            minWidth: 12.5,
            maxWidth: 12.5,
            render: renderRequestor,
          },
          {
            title: t('general.type', 'Type'),
            key: 'type',
            headerCellStyles: 'px-2 py-3',
            cellStyles: 'px-2 py-3',
            minWidth: 13.75,
            maxWidth: 15,
            render: renderType,
          },
          {
            title: t('general.accountCode', 'Account Code'),
            key: 'account',
            headerCellStyles: 'px-2 py-3',
            cellStyles: 'px-2 py-3',
            minWidth: 15,
            grow: true,
            render: ({ department, account }) => <AccountCodeItem department={department} account={account} />,
          },
          {
            title: t('general.status', 'Status'),
            key: 'status',
            headerCellStyles: 'py-3 text-center',
            cellStyles: 'py-3 text-center',
            minWidth: 10,
            maxWidth: 10,
            render: renderStatus,
          },
          {
            title: t('general.deliveryDate', 'Delivery Date'),
            key: 'deliveryDate',
            headerCellStyles: 'px-2 py-3 text-center',
            cellStyles: 'px-2 py-3 text-center',
            minWidth: 7.5,
            maxWidth: 7.5,
            render: ({ expectedDeliveryDate }) => <ShortDateItem date={expectedDeliveryDate} />,
          },
          {
            title: t('general.sendPo', 'Send PO'),
            key: 'sendPO',
            headerCellStyles: 'px-1 py-3 text-center',
            cellStyles: 'px-1 py-3 text-center',
            minWidth: 5,
            maxWidth: 5,
            render: renderSendPO,
          },
          {
            title: t('general.totalValue', 'Total Value'),
            key: 'totalValue',
            headerCellStyles: 'px-1 py-3 text-right',
            cellStyles: 'px-1 py-3 text-right',
            minWidth: 6.875,
            maxWidth: 9.375,
            render: ({ totalValue }) => <TotalTaxInclusive totalValue={totalValue} />,
          },
          {
            title: t('general.more', 'More'),
            key: 'more',
            headerCellStyles: 'px-1 py-3 text-center',
            cellStyles: 'px-1 py-3 text-center',
            minWidth: 4.375,
            maxWidth: 4.375,
            render: renderMoreOptions,
          },
        ]}
      />
    </>
  )
}

export default memo(TableView)
