import { useTranslation } from 'react-i18next'

import Fallback from '../../../fallback'

import { ProductListType, ProductType } from '@/modules/shared/types'

export interface ProductTaxProps {
  type: ProductListType
  product: ProductType
}

export default function ProductTax({ type, product }: ProductTaxProps) {
  const { availableQuotes, taxPercentage } = product || {}
  const { t } = useTranslation()

  switch (type) {
    case ProductListType.AddCatalogProduct:
      return (
        <Fallback
          condition={!!product?.product?.catalogued}
          fallbackValue={t('general.notInCatalog', 'Not in Catalog')}
        >
          {t('general.alreadyAdded', 'Already Added')}
        </Fallback>
      )
    default:
      return (
        <Fallback condition={!!availableQuotes?.length} fallbackValue={t('general.noSuppliers', 'No Suppliers')}>
          {taxPercentage
            ? t('shopPage.productList.productCard.taxPercentage', 'Plus {{ percent }}% Tax', {
                percent: taxPercentage,
              })
            : t('general.taxFree', 'Tax Free')}
        </Fallback>
      )
  }
}
