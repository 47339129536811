import { autoUpdate, useFloating } from '@floating-ui/react-dom'
import { Popover, Transition } from '@headlessui/react'
import { Fragment, PropsWithChildren, ReactNode } from 'react'

import { Portal } from '@/modules/shared/layouts'

interface MenuPopoverProps extends PropsWithChildren {
  content: ReactNode
}

function MenuPopover(props: MenuPopoverProps) {
  const { children, content } = props
  const {
    x,
    y,
    strategy,
    refs: { setReference, setFloating },
  } = useFloating({
    placement: 'bottom-end',
    whileElementsMounted: autoUpdate,
  })

  return (
    <Popover className="relative flex items-center justify-center">
      <Popover.Button className="items-center justify-between outline-none hover:opacity-75" ref={setReference}>
        {children}
      </Popover.Button>
      <Portal>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel
            className="z-10 rounded-md border bg-white shadow-lg"
            ref={setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            {content}
          </Popover.Panel>
        </Transition>
      </Portal>
    </Popover>
  )
}

export default MenuPopover
