import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import MyProductsTableView from './MyProductsTableView'

import ProductsEmptyStateImg from '@/assets/images/empty-po-lines.svg'
import { Breadcrumb, ConfirmDialog, MoreOptionsMenu, QueryResult, TitleBar } from '@/modules/shared/components'
import AdvancedSearch from '@/modules/shared/components/advanced-search/AdvancedSearch'
import EmptyState from '@/modules/shared/components/empty-state'
import SearchAndFilter, { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { ControlBarIcon, HomeIcon, OptionIcon } from '@/modules/shared/icons'
import SearchContainer from '@/modules/shared/search-container'
import { Themes } from '@/modules/shared/types'
import { useGetCurrentSupplierProducts } from '@/modules/supplier/hooks'

export default function MyProducts() {
  const { t } = useTranslation()
  const [showMatchModal, setShowMatchModal] = useState(false)
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
  const { availableProducts, error, paginationResultsPerPage, pageInfo, loadingStates, refetch } =
    useGetCurrentSupplierProducts()

  const onChangePage = (type: 'onPreviousPage' | 'onNextPage') => {
    const { startCursor, endCursor } = pageInfo || {}
    refetch({
      before: type === 'onPreviousPage' ? startCursor : null,
      after: type === 'onNextPage' ? endCursor : null,
      last: type === 'onPreviousPage' ? paginationResultsPerPage : null,
      first: type === 'onNextPage' ? paginationResultsPerPage : null,
    })
  }

  const onFilter = ({ searchValue, hasImage, catalogued, matched, master, categoryIds }: OnFilter) => {
    refetch({
      searchText: searchValue,
      hasImage,
      catalogued,
      matched,
      master,
      categoryIds: categoryIds as number[] | null,
    })
  }

  return (
    <>
      <Helmet>
        <title>{t('myProducts.pageTitle', 'My Products')}</title>
      </Helmet>
      <main className="mx-auto max-w-[95rem] space-y-5 px-4">
        <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="#">{t('breadcrumbs.products', 'Products')}</Breadcrumb.Item>
          <Breadcrumb.Item href="#">{t('breadcrumbs.myProducts', 'My Products')}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="space-y-2">
          <TitleBar
            icon={<ControlBarIcon className="h-10 w-10" />}
            title={t('myProducts.pageTitle', 'My Products')}
            subtitle={t('myProducts.viewAndManage', 'View and manage your Products')}
          >
            <div>
              <MoreOptionsMenu showHelp>
                <OptionIcon className="h-11 w-11 rounded-full bg-gray-200 p-1.5 text-gray-600 outline-none hover:brightness-95" />
              </MoreOptionsMenu>
            </div>
          </TitleBar>
        </section>
        <SearchContainer showAdvancedSearch={showAdvancedSearch} setShowAdvancedSearch={setShowAdvancedSearch}>
          <SearchAndFilter
            testId="products-search-input"
            placeholder={t('general.searchByNameOrBrand', 'Search by Product Name, Brand')}
            ariaLabel={t('general.searchByNameOrBrand', 'Search by Product Name, Brand')}
            onFilter={onFilter}
          />
        </SearchContainer>
        {showAdvancedSearch && (
          <AdvancedSearch
            showCategory
            showFilterByImageStatus
            showFilterByCataloguedStatus
            showFilterByMatchedStatus
            showFilterByMasterStatus
          />
        )}
        <section>
          <QueryResult loading={loadingStates.loading || loadingStates.refetchLoading} error={error}>
            <MyProductsTableView
              products={availableProducts.slice(0, paginationResultsPerPage)}
              pageInfo={pageInfo}
              paginationLoading={loadingStates.setVariablesLoading}
              onNextPage={() => onChangePage('onNextPage')}
              onPreviousPage={() => onChangePage('onPreviousPage')}
              setShowMatchModal={setShowMatchModal}
            />
            <ConfirmDialog
              theme={Themes.Primary}
              isOpen={showMatchModal}
              setIsOpen={setShowMatchModal}
              title={t('general.comingSoon', 'Coming Soon!')}
              description={t(
                'supplier.myProducts.matchMasterProduct',
                'Soon you will be able to match this product to a PurchasePlus Master Product, so that your Purchasers can find and compare it more easily. We are still working on this feature.'
              )}
              primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
              onPrimaryBtnClick={() => setShowMatchModal(false)}
            />
            {!loadingStates.setVariablesLoading && availableProducts.length === 0 && (
              <EmptyState
                img={ProductsEmptyStateImg}
                message={t('general.noProductsToDisplay', 'There are no products to display.')}
              />
            )}
          </QueryResult>
        </section>
      </main>
    </>
  )
}
