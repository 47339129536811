import 'i18next'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translation from './en-AU/translation.json'

// allows the tFunc to return 'undefined' if there is no translation for the key and no default value
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: undefined
  }
}

export default function i18nextConfig() {
  i18n.use(initReactI18next).init({
    returnNull: undefined,
    lng: 'en-AU',
    fallbackLng: 'en-AU',
    resources: {
      'en-AU': {
        translation,
      },
    },
  })

  return i18n
}
