import { useTranslation } from 'react-i18next'

import {
  DeliveryChargeConfiguration,
  Supplier,
  SupplierRelationship,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { DeliveryFees } from '@/modules/requisitions/components'
import { formatSupplierBillingAddress } from '@/modules/requisitions/utils'
import { formatSupplierPostalAddress } from '@/modules/requisitions/utils/formatSupplierPostalAddress'
import { TextWithFallback } from '@/modules/shared/components'

interface SupplierRightDetailsProps {
  supplierRelationShip: SupplierRelationship
}

export default function SupplierRightDetails({ supplierRelationShip }: SupplierRightDetailsProps) {
  const { supplier } = supplierRelationShip || {}
  const { t } = useTranslation()
  return (
    <section className="flex w-full flex-col gap-y-4 text-sm xl:w-96">
      <section className="rounded-md bg-white p-5 shadow" data-testid="supplier-details">
        <h2 className="mb-5 text-center text-lg font-bold">{supplier?.name}</h2>
        <div className="flex flex-col gap-y-4">
          <TextWithFallback
            title={t('general.billingAddress', 'Billing Address')}
            value={formatSupplierBillingAddress(supplier as Supplier)}
          />
          <TextWithFallback
            title={t('general.postalAddress', 'Postal Address')}
            value={formatSupplierPostalAddress(supplier as Supplier)}
          />
          <TextWithFallback title={t('general.contactPhone', 'Contact Phone')} value={supplier?.telephone} />
          <TextWithFallback title={t('general.contactEmail', 'Contact Email')} value={supplier?.email} />
          <TextWithFallback title={t('general.businessNumber', 'Business Number')} value={supplier?.businessNumber} />
        </div>
      </section>
      <section className="rounded-md bg-white p-5 shadow" data-testid="supplier-delivery">
        <p className="font-bold">{t('general.deliveryFees', 'Delivery Fees')}</p>
        <p>{t('mySuppliers.supplier.byTotalOrder', 'By total order value, excluding tax.')}</p>
        <DeliveryFees
          deliveryChargeConfiguration={supplier?.deliveryChargeConfiguration as DeliveryChargeConfiguration}
        />
        <p className="text-xs text-gray-500">
          {t(
            'mySuppliers.supplier.deliveryFeeInfo',
            'Delivery fees are set by the Supplier based on your trading agreement. Delivery fees will be automatically applied to a Purchase Order. Delivery fees shown here are a general guide and the Supplier reserves the right to charge additional delivery fees based on other location or product criteria such as weight or volume of certain products.'
          )}
        </p>
      </section>
    </section>
  )
}
