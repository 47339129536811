import { graphql } from 'msw'

import { CancelReceivingDocumentMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const CancelReceivingDocument = graphql.mutation<CancelReceivingDocumentMutation>(
  'CancelReceivingDocument',
  (_, res, ctx) => {
    return res(
      ctx.data({
        cancelReceivingDocument: {
          receivingDocument: {
            id: 6659890,
            purchaseOrder: {
              id: 16566710,
              purchaseOrderNumber: 'PO04331',
              __typename: 'PurchaseOrder',
            },
            workflowState: 'cancelled',
            __typename: 'ReceivingDocument',
          },
          __typename: 'CancelReceivingDocumentMutationPayload',
        },
      })
    )
  }
)
