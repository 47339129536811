import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { useGetAllCreditNotes } from '../../hooks'
import AllCreditNotesTableView from './table-view/AllCreditNotesTableView'

import EmptyInvoices from '@/assets/images/empty-invoices.svg'
import { ProductListEmptyState } from '@/modules/requisitions/components'
import { Breadcrumb, QueryResult, TitleBar } from '@/modules/shared/components'
import SearchAndFilter, { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { HomeIcon, InvoiceIcon } from '@/modules/shared/icons'
import { QueryParameter } from '@/modules/shared/types'

export default function AllCreditNotes() {
  const { t } = useTranslation()
  const { error, loadingStates, creditNotes, pageInfo, paginationResultsPerPage, refetch } = useGetAllCreditNotes()

  const onPreviousPage = () => {
    const { startCursor } = pageInfo
    refetch({
      before: startCursor,
      after: null,
      last: paginationResultsPerPage,
      first: null,
    })
  }

  const onNextPage = () => {
    const { endCursor } = pageInfo
    refetch({
      after: endCursor,
      before: null,
      first: paginationResultsPerPage,
      last: null,
    })
  }

  const onFilter = ({ searchValue }: OnFilter) => {
    refetch({
      filter: {
        q: [
          {
            property: 'creditNoteNumber_or_reference_or_invoice_invoiceNumber_cont',
            value: searchValue,
          },
        ],
      },
      first: paginationResultsPerPage,
      after: null,
      last: null,
    })
  }

  return (
    <>
      <Helmet>
        <title>{t('general.viewAllCreditNotes', 'View All Credit Notes')}</title>
      </Helmet>
      <main className="mx-auto space-y-5 px-4">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="/invoices/credit-notes">{t('general.creditNotes', 'Credit Notes')}</Breadcrumb.Item>
          <Breadcrumb.Item>{t('general.viewAll', 'View All')}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="space-y-2">
          <TitleBar
            icon={<InvoiceIcon className="h-10 w-10" />}
            title={t('general.allCreditNotes', 'All Credit Notes')}
            subtitle={t('general.viewAllCreditNotes', 'View All Credit Notes')}
          />
        </section>
        <SearchAndFilter
          testId="credit-notes-search-input"
          placeholder={t(
            'creditNotes.allCredits.searchPlaceholder',
            'Search by Credit Note Number, Invoice Number or Reference'
          )}
          ariaLabel={t(
            'creditNotes.allCredits.searchPlaceholder',
            'Search by Credit Note Number, Invoice Number or Reference'
          )}
          onFilter={onFilter}
          queryParamFilterType={QueryParameter.Search}
        />
        <QueryResult loading={loadingStates.loading || loadingStates.refetchLoading} error={error}>
          <AllCreditNotesTableView
            creditNotes={creditNotes}
            pageInfo={pageInfo}
            paginationLoading={loadingStates.setVariablesLoading}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
          />
          {!loadingStates.setVariablesLoading && creditNotes.length === 0 && (
            <ProductListEmptyState
              image={EmptyInvoices}
              message={t('creditNotes.allCreditNotes.noCreditNotes', "We couldn't find any Credit Notes to display.")}
            />
          )}
        </QueryResult>
      </main>
    </>
  )
}
