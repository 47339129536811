import { ComponentProps } from 'react'

import BreadcrumbItem from './breadcrumb-item'

function BreadcrumbComponent(props: ComponentProps<'nav'>) {
  const { children, ...restProps } = props
  return (
    <nav aria-label="Breadcrumb" {...restProps}>
      <ol className="flex items-center overflow-y-auto">{children}</ol>
    </nav>
  )
}

export const Breadcrumb = Object.assign(BreadcrumbComponent, {
  Item: BreadcrumbItem,
})
