import { graphql } from 'msw'

import { CreateCommentMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const CreateComment = graphql.mutation<CreateCommentMutation>('CreateComment', (req, res, ctx) => {
  return res(
    ctx.data({
      createComment: {
        comment: {
          id: 1985528,
          body: 'New Comment',
          __typename: 'Comment',
        },
        __typename: 'CreateCommentMutationPayload',
      },
    })
  )
})
