import { useHover } from 'react-aria'

import { Comment } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useSession } from '@/modules/access/hooks'
import { useDeleteComment } from '@/modules/requisitions/hooks'
import { Button } from '@/modules/shared/components'
import { useWindowSize } from '@/modules/shared/hooks'
import { TrashIcon, UserIcon } from '@/modules/shared/icons'
import { Breakpoints, Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface CommentProps {
  comment: Comment
  refetch: () => void
}

export default function SingleComment(props: CommentProps) {
  const { comment, refetch } = props
  const { currentUser } = useSession()
  const { hoverProps, isHovered } = useHover({})
  const [deleteComment, { loading }] = useDeleteComment()
  const { width } = useWindowSize()

  const onDeleteComment = (id: number) => {
    deleteComment({
      variables: {
        input: {
          id,
        },
      },
      onCompleted: () => {
        refetch()
      },
    })
  }

  return (
    <div className="mt-3 flex w-full gap-x-3" {...hoverProps} data-testid="single-comment">
      <UserIcon className="h-9 w-9 rounded-full bg-gray-100 p-1 text-gray-500" />
      <div className="flex w-full flex-col">
        <div className="flex h-9 justify-between">
          <div className="flex flex-col">
            <span className="text-xs font-bold">{comment.creator?.fullName}</span>
            <span className="text-xs text-gray-500">{formatDate(Dates.Long, comment.createdAt)}</span>
          </div>
          {currentUser?.id === comment.creator?.id && (isHovered || width < Breakpoints.LG) && (
            <Button disabled={loading}>
              <TrashIcon
                data-testid="delete-comment"
                className="h-9 w-9 cursor-pointer rounded-full bg-error/20 p-1 text-error"
                onClick={() => onDeleteComment(comment.id)}
              />
            </Button>
          )}
        </div>
        <div className="mt-2 w-fit rounded-md bg-gray-200 px-4 py-3 text-sm">{comment.body}</div>
      </div>
    </div>
  )
}
