import NumberInput from '../../number-input'

export interface JournalQuantityProps {
  quantity: number | string | null | undefined
  onChange: (quantity: number) => void
  isDisabled?: boolean
}

export default function JournalQuantity({ quantity, onChange, isDisabled }: JournalQuantityProps) {
  return (
    <NumberInput
      defaultValue={Number(quantity)}
      className="m-auto w-[4rem] rounded-md border border-gray-300 px-4 py-3 text-center text-sm"
      onChange={onChange}
      aria-label="quantity-input"
      isDisabled={isDisabled}
      data-testid="quantity-input"
    />
  )
}
