import { useLazyQuery } from '@apollo/client'

import { GetPurchaseOrderLinesLinkableDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useFetchLinkableLines(invoiceId: string, purchaseOrderId: number) {
  const [fetchLinkableLines, { data, loading, error }] = useLazyQuery(GetPurchaseOrderLinesLinkableDocument, {
    variables: {
      invoiceId: String(invoiceId),
      purchaseOrderId,
    },
    context: { uri: PURCHASING_GRAPHQL_API },
    fetchPolicy: 'cache-and-network',
  })

  return {
    fetchLinkableLines,
    linkableLines: data?.currentPurchaser?.purchaseOrder?.lineItemsNotInvoicedBy,
    loading,
    error,
  }
}
