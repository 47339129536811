import classNames from 'classnames'

import { PurchaseOrderStatuses } from '@/modules/purchase-orders/types'
import { formatStateForLocale } from '@/modules/purchase-orders/utils/formatStateForLocale'

interface RequisitionStatusProps {
  status: string
}

export default function PurchaseOrderStatus(props: RequisitionStatusProps) {
  const { status } = props

  return (
    <span
      className={classNames('rounded-full px-3 py-1.5 text-xs', {
        'bg-success text-white': status === PurchaseOrderStatuses.Sent || status === PurchaseOrderStatuses.Closed,
        'bg-gray-200': status === PurchaseOrderStatuses.NotSent,
        'bg-gray-600 text-white': status === PurchaseOrderStatuses.Cancelled,
      })}
    >
      {formatStateForLocale(status)}
    </span>
  )
}
