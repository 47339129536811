import { graphql } from 'msw'

import { CancelPurchaseOrderMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const CancelPurchaseOrder = graphql.mutation<CancelPurchaseOrderMutation>(
  'CancelPurchaseOrder',
  (req, res, ctx) => {
    return res(
      ctx.data({
        cancelPurchaseOrder: {
          purchaseOrder: {
            id: 16566710,
            status: 'Cancelled',
            __typename: 'PurchaseOrder',
          },
          __typename: 'CancelPurchaseOrderMutationPayload',
        },
      })
    )
  }
)
