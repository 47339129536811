import { t } from 'i18next'

import { Activity, Comment } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback } from '@/modules/shared/components'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface DeclineBodyProps {
  declineActivity: Activity
  declineComment: Comment
}

export default function DeclinedBody({ declineActivity, declineComment }: DeclineBodyProps) {
  return (
    <>
      <section>
        <p className="font-bold">{t('general.declinedBy', 'Declined By')}</p>
        <p className="text-gray-500">
          {t('requisition.statusModal.declinedByBody', 'The user who declined the Requisition.')}
        </p>
        <div className="mt-2 flex w-full flex-col justify-between gap-x-2 rounded-md border bg-gray-100 px-3 py-4 sm:flex-row">
          <Fallback condition={!!declineActivity}>
            <span>{declineActivity?.user?.fullName}</span>
            <span className="text-gray-500">{formatDate(Dates.Long, declineActivity?.createdAt)}</span>
          </Fallback>
        </div>
      </section>
      <section>
        <p className="font-bold">{t('general.declineComment', 'Decline Comment')}</p>
        <p className="text-gray-500">
          {t('requisition.statusModal.declineCommentBody', 'The comment left by the declining user, if any.')}
        </p>
        <div className="mt-2 min-h-[3.375rem] w-full rounded-md border bg-gray-100 px-3 py-4">
          <span>
            <Fallback condition={!!declineComment?.body}>{declineComment?.body}</Fallback>
          </span>
        </div>
      </section>
    </>
  )
}
