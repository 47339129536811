import { graphql } from 'msw'

import { GetCurrentPurchaserQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetCurrentPurchaserObject: GetCurrentPurchaserQuery = {
  currentPurchaser: {
    id: 44926,
    name: 'Test Account',
    isAPurchaser: true,
    isInventoryEnabled: true,
    ppnewAccess: true,
    currency: 'AUD',
    __typename: 'Purchaser',
  },
}

export const GetCurrentPurchaser = graphql.query<GetCurrentPurchaserQuery>('GetCurrentPurchaser', (_, res, ctx) => {
  return res(ctx.data(baseGetCurrentPurchaserObject))
})

export default baseGetCurrentPurchaserObject
