import classNames from 'classnames'
import { PropsWithChildren, ReactNode, useState } from 'react'
import Collapsible from 'react-collapsible'

import Button from '../button'

import { ChevronDownIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface AccordionProps extends PropsWithChildren {
  title: string
  buttonClassName?: string
  titleClassName?: string
  titlePrefixIcon?: ReactNode
  titleSuffixIcon?: ReactNode
  defaultOpen?: boolean
  activeColor?: Themes
  keepActiveColor?: boolean
  testId?: string
  handleTriggerClick?: () => void
}

function Accordion({
  title,
  activeColor,
  buttonClassName,
  titleClassName,
  titlePrefixIcon,
  titleSuffixIcon,
  defaultOpen = false,
  keepActiveColor = false,
  testId,
  children,
  handleTriggerClick,
}: AccordionProps) {
  const [buttonIsOpen, setButtonIsOpen] = useState<boolean>(defaultOpen)

  return (
    <Collapsible
      triggerTagName="div"
      easing="ease-in-out"
      transitionTime={200}
      transitionCloseTime={0}
      open={defaultOpen}
      onOpening={() => setButtonIsOpen(true)}
      onClosing={() => setButtonIsOpen(false)}
      handleTriggerClick={handleTriggerClick}
      trigger={
        <Button
          data-testid={testId || `accordion-${title.toLowerCase().replace(/\s/g, '')}-btn`}
          className={classNames(
            buttonClassName,
            'flex w-full items-center justify-between rounded-md hover:brightness-95',
            {
              'bg-primary text-white ': (buttonIsOpen || keepActiveColor) && activeColor === Themes.Primary,
              'bg-gray-200 ': !activeColor || (buttonIsOpen && !activeColor),
              'bg-gray-100': !keepActiveColor && !buttonIsOpen,
            }
          )}
        >
          <div className="flex items-center gap-2">
            {titlePrefixIcon}
            <span className={classNames(titleClassName, 'text-sm')}>{title}</span>
            {titleSuffixIcon}
          </div>
          <ChevronDownIcon
            className={classNames('h-5 w-5 transition-all duration-300', {
              '-rotate-180 transform': buttonIsOpen,
            })}
          />
        </Button>
      }
    >
      <div className="w-full">{children}</div>
    </Collapsible>
  )
}

export default Accordion
