import { graphql } from 'msw'

import { GetRequisitionStatusActivitiesQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetRequisitionStatusActivities: GetRequisitionStatusActivitiesQuery = {
  currentPurchaser: {
    id: 44926,
    requisition: {
      id: 5235941,
      state: 'awaiting_approval',
      requisitionNumber: 'PR00040',
      awaitingMyApproval: true,
      advanced: false,
      requestor: {
        id: 19897,
        fullName: 'Johan W. Baaij',
        createdAt: '2022-07-08T12:49:06+10:00',
        __typename: 'User',
      },
      approvalWorkflow: {
        id: 6231,
        title: 'Value Test',
        __typename: 'RequisitionApprovalWorkflow',
      },
      nextApprovalUsers: [
        { id: 19897, fullName: 'Johan W. Baaij', __typename: 'User' },
        {
          id: 18544,
          fullName: 'Sunny H Sun',
          __typename: 'User',
        },
      ],
      submitActivity: {
        id: 190232853,
        createdAt: '2022-01-19T09:21:09+11:00',
        user: {
          id: 21172,
          fullName: 'Maria Loesing',
          __typename: 'User',
        },
        __typename: 'Activity',
      },
      approveActivity: null,
      cancelActivity: null,
      declineActivity: null,
      declineComment: null,
      nextApprovingUsers: [
        {
          id: 21645,
          fullName: 'Drew Nixon',
          __typename: 'User',
        },
      ],
      purchaseOrders: {
        edges: [
          {
            node: {
              id: 16565053,
              purchaseOrderNumber: 'PO03270',
              status: 'Not Sent',
              __typename: 'PurchaseOrder',
            },
            __typename: 'PurchaseOrderEdge',
          },
          {
            node: {
              id: 16565985,
              purchaseOrderNumber: 'PO03626',
              status: 'Not Sent',
              __typename: 'PurchaseOrder',
            },
            __typename: 'PurchaseOrderEdge',
          },
          {
            node: {
              id: 16565986,
              purchaseOrderNumber: 'PO03627',
              status: 'Not Sent',
              __typename: 'PurchaseOrder',
            },
            __typename: 'PurchaseOrderEdge',
          },
        ],
        __typename: 'PurchaseOrderConnection',
      },
      __typename: 'Requisition',
    },
    __typename: 'Purchaser',
  },
}

export const GetRequisitionStatusActivities = graphql.query<GetRequisitionStatusActivitiesQuery>(
  'GetRequisitionStatusActivities',
  (req, res, ctx) => {
    return res(ctx.data(baseGetRequisitionStatusActivities))
  }
)

export default baseGetRequisitionStatusActivities
