import Fallback from '../../fallback'

import { useMoney } from '@/modules/shared/hooks'

export interface UnitPriceProps {
  unitPrice: number | null | undefined
  availableQuotes?: boolean
}

export default function UnitPrice({ unitPrice, availableQuotes = true }: UnitPriceProps) {
  const { formatMoney } = useMoney()

  return (
    <Fallback condition={!!availableQuotes}>
      <strong>{formatMoney(unitPrice)}</strong>
    </Fallback>
  )
}
