import { matchRoutes, useLocation } from 'react-router-dom'

import { myProductsRoutes } from '@/modules/shared/constants'

const useFindMatchSupplierRoutes = () => {
  const location = useLocation()
  const allSupplierRoutes = [myProductsRoutes]
  const matchedRouteIndex = allSupplierRoutes.findIndex((route) => matchRoutes(route, location))
  const routeMatches = allSupplierRoutes.map((_, index) => index === matchedRouteIndex)

  return {
    myProductsRoutes: routeMatches[0],
    accordionDefaultKey: matchedRouteIndex !== -1 ? matchedRouteIndex : null,
  }
}

export default useFindMatchSupplierRoutes
