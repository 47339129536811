import classNames from 'classnames'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { isChildSelected } from './utils/isChildSelected'

import { Purchaser } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, Fallback } from '@/modules/shared/components'
import { ChevronDownIcon } from '@/modules/shared/icons'

export interface NestedPurchaserRowProps {
  purchaser: Purchaser | null
  level: number
  forceExpandAll?: boolean
}

export const NestedPurchaserRow = ({ purchaser, level, forceExpandAll }: NestedPurchaserRowProps) => {
  const { t } = useTranslation()
  const { organisationId } = useParams<{ organisationId: string }>()
  const selectedOrgId = Number(organisationId)
  const childSelected = isChildSelected(purchaser, selectedOrgId, forceExpandAll)
  const [isExpanded, setIsExpanded] = useState(level === 0 || childSelected)
  const selectedOrg = selectedOrgId === purchaser?.id

  function toggleAccordion() {
    setIsExpanded(!isExpanded)
  }

  const marginLeft = level <= 6 ? `${level * 2}rem` : '12rem'

  return (
    <Fragment key={purchaser?.id}>
      <tr>
        <td className="px-0">
          <div
            className={classNames('mt-1 flex h-12 w-full items-center rounded-l border-b border-l border-t', {
              'border-primaryLight bg-primaryLighter': selectedOrg,
              'bg-gray-100': !selectedOrg,
            })}
            style={{ marginLeft }}
          >
            {!!purchaser?.departments?.length ? (
              <ChevronDownIcon
                className={classNames('ml-1 h-8 w-8 cursor-pointer rounded-full p-1 text-gray-500 transition', {
                  '-rotate-270 transform': isExpanded,
                  '-rotate-90 transform': !isExpanded,
                  'text-primary hover:bg-primaryLight': selectedOrg,
                  'hover:bg-gray-200': !selectedOrg,
                })}
                onClick={toggleAccordion}
                data-testid={`show-org-hierarchy-dropdown-${level}`}
              />
            ) : (
              <div className="ml-1 h-8 w-8 p-1" />
            )}
            <div
              className={classNames('ml-1 w-full break-words', {
                'font-bold': purchaser?.legalEntity,
                'text-primary': selectedOrg,
              })}
            >
              {purchaser?.name}
            </div>
          </div>
        </td>
        <td className="px-0">
          <div
            className={classNames(
              'mt-1 flex h-12 items-center justify-center border-b border-t bg-gray-100 py-2 text-sm',
              {
                'border-primaryLight bg-primaryLighter text-primary': selectedOrg,
                'bg-gray-100': !selectedOrg,
              }
            )}
          >
            {purchaser?.legalEntity ? t('general.yes', 'Yes') : t('general.no', 'No')}
          </div>
        </td>
        <td className="px-0">
          <div
            className={classNames(
              'mt-1 flex h-12 items-center justify-center border-b border-t bg-gray-100 py-2 text-sm',
              {
                'border-primaryLight bg-primaryLighter text-primary': selectedOrg,
                'bg-gray-100': !selectedOrg,
              }
            )}
          >
            <Fallback condition={!!purchaser?.departmentCode}>{purchaser?.departmentCode}</Fallback>
          </div>
        </td>
        <td className="px-0">
          <div
            className={classNames(
              'mt-1 flex h-12 items-center justify-center border-b border-t bg-gray-100 py-2 text-sm',
              {
                'border-primaryLight bg-primaryLighter text-primary': selectedOrg,
                'bg-gray-100': !selectedOrg,
              }
            )}
          >
            <Fallback condition={!!purchaser?.defaultAccount?.name}>{purchaser?.defaultAccount?.name}</Fallback>
          </div>
        </td>
        <td className="px-0">
          <div
            className={classNames(
              'mt-1 flex h-12 items-center justify-center border-b border-r-0 border-t bg-gray-100 px-0 py-2',
              { 'border-primaryLight bg-primaryLighter': selectedOrg, 'bg-gray-100': !selectedOrg }
            )}
          >
            <Button
              className={classNames(
                'flex min-w-[5rem] items-center justify-center rounded-md px-3 py-1.5 text-xs transition',
                {
                  'bg-primary text-white': selectedOrg,
                  'bg-gray-200 hover:brightness-95': !selectedOrg,
                }
              )}
            >
              {t('general.viewInfo', 'View Info')}
            </Button>
          </div>
        </td>
        <td className="px-0">
          <div
            className={classNames(
              'mt-1 flex h-12 items-center justify-center rounded-r border-b border-l-0 border-r border-t bg-gray-100 px-0 py-2',
              { 'border-primaryLight bg-primaryLighter': selectedOrg, 'bg-gray-100': !selectedOrg }
            )}
          >
            <Link
              to={`/admin/organisations/${purchaser?.id}/customize?open-hierarchy=1`}
              className={classNames(
                'flex min-w-[5rem] items-center justify-center rounded-md px-3 py-1.5 text-xs transition',
                {
                  'bg-primary text-white': selectedOrg,
                  'bg-gray-200 hover:brightness-95': !selectedOrg,
                }
              )}
              data-testid="select-org-button"
            >
              {selectedOrg ? t('general.selected', 'Selected') : t('general.select', 'Select')}
            </Link>
          </div>
        </td>
      </tr>

      {isExpanded &&
        purchaser?.departments.map((dept) => (
          <NestedPurchaserRow key={dept.id} purchaser={dept} level={level + 1} forceExpandAll={forceExpandAll} />
        ))}
    </Fragment>
  )
}
