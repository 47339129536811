import { Dispatch, SetStateAction } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreatePricedCataloguedProduct, useGetPricedCatalog } from '@/modules/catalogs/hooks'
import { CreateCataloguedProductInputs } from '@/modules/catalogs/types'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks'
import { Button, Modal, ProductSummary } from '@/modules/shared/components'
import NumberInput from '@/modules/shared/components/number-input'
import { ProductType } from '@/modules/shared/types'

export interface AddCatalogProductModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  line: ProductType
  catalogId: number
}

export default function AddCatalogProductModal({
  showModal,
  setShowModal,
  line,
  catalogId,
}: AddCatalogProductModalProps) {
  const { t } = useTranslation()

  const { product } = line
  const { currentPurchaser } = useCurrentPurchaser()
  const { pricedCatalog } = useGetPricedCatalog()
  const { title } = pricedCatalog || {}
  const [createPricedCatalogedProduct, { loading: createLoading }] = useCreatePricedCataloguedProduct({
    catalogId,
    line,
  })

  const formMethods = useForm<CreateCataloguedProductInputs>({
    defaultValues: { sellUnitTaxPercentage: 0 },
  })

  const errorUnitPrice =
    formMethods.formState.errors.sellUnitPrice && formMethods.formState.errors.sellUnitPrice.type === 'required'

  const onCloseModal = () => {
    setShowModal(false)
    if (formMethods.formState.errors) formMethods.reset()
  }

  const onSubmit: SubmitHandler<CreateCataloguedProductInputs> = (data) => {
    createPricedCatalogedProduct({
      variables: {
        input: { catalogueId: catalogId, productId: line?.product?.id, ...data },
      },
      onCompleted: () => {
        onCloseModal()
      },
    })
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel className="flex w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]">
        <Modal.Title
          title={t('catalogs.addProductModal.title', 'Add Product to {{ title }}', { title })}
          onCloseModal={onCloseModal}
        />

        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Modal.Body>
            <p className="font-bold">{t('catalogs.addProductModal.title', 'Add Product to {{ title }}', { title })}</p>
            <p className="mb-2 text-gray-500">
              {t(
                'catalogs.addProductModal.description',
                'You can add the product below to {{ title }}. Please add the Unit Price and Tax Percentage you wish to assign to this Product in this Catalog.',
                { title }
              )}
            </p>
            <ProductSummary product={product} showPriceAndTaxPercentage={false} />
            <div className="space-y-4 text-sm">
              <div>
                <p className="font-bold">{t('general.unitPrice', 'Unit Price')}</p>
                <p className="text-gray-500">
                  {t(
                    'catalogs.addProductModal.unitPriceDescription',
                    'Set the price for this product in this catalog. The price is exclusive of tax, and the tax percentage to be added to the price is managed in the field below.'
                  )}
                </p>
                <Controller
                  control={formMethods.control}
                  name="sellUnitPrice"
                  render={({ field }) => (
                    <NumberInput
                      className="mt-2 h-12 w-full rounded-md border text-sm shadow-sm"
                      leftUnit={currentPurchaser?.currency}
                      data-testid="price-input"
                      aria-label={t('general.unitPrice', 'Unit Price')}
                      hasError={errorUnitPrice}
                      errorMessage={t(
                        'catalogs.addProductModal.unitPriceRequiredMsg',
                        'You must provide a Unit Price.'
                      )}
                      formatOptions={{
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      }}
                      defaultValue={0}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </div>
              <div>
                <p className="font-bold">{t('general.taxPercentage', 'Tax Percentage')}</p>
                <p className="text-gray-500">
                  {t(
                    'catalogs.addProductModal.taxPercentageDescription',
                    'Set the tax percentage for this product in this catalog. The tax percentage is the amount that will be added to the tax exclusive unit price above.'
                  )}
                </p>
                <Controller
                  control={formMethods.control}
                  name="sellUnitTaxPercentage"
                  render={({ field }) => (
                    <NumberInput
                      className="mt-2 h-12 w-full rounded-md border text-sm shadow-sm"
                      leftUnit={t('general.taxWithPercentage', 'Tax %')}
                      data-testid="tax-input"
                      aria-label={t('general.taxPercentage', 'Tax Percentage')}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
            <Button
              className="h-11 w-full rounded-md bg-gray-200 px-5 text-sm md:w-fit"
              onClick={onCloseModal}
              type="button"
              data-testid="cancel-button"
            >
              {t('general.cancel', 'Cancel')}
            </Button>
            <Button
              className="h-11 w-full rounded-md bg-primary px-5 text-sm text-white md:w-fit"
              type="submit"
              loading={createLoading}
              data-testid="submit-button"
            >
              {t('general.addToCatalog', 'Add to Catalog')}
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Panel>
    </Modal>
  )
}
