import { Dispatch, SetStateAction } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import LinkInvoiceModalTable from './LinkInvoiceModalTable'
import LinkInvoiceModalTableRow from './LinkInvoiceModalTableRow'

import { PurchaseOrderLineItem, RequisitionLine, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { LinkInvoiceTableTypes } from '@/modules/invoices/types'

interface LinkInvoiceModalLinkableLinesProps {
  purchaseOrderLineItemId: number
  hasLines: boolean
  canBeUpdated: boolean
  purchaseOrderLineItem: PurchaseOrderLineItem
  supplier: Supplier
  selectedLineId: number | null
  setSelectedLineId: Dispatch<SetStateAction<number | null>>
  linkableLines: PurchaseOrderLineItem[]
  purchaseOrderId: number
}

export default function LinkInvoiceModalLinkableLines({
  linkableLines,
  purchaseOrderId,
  purchaseOrderLineItemId,
  hasLines,
  canBeUpdated,
  purchaseOrderLineItem,
  supplier,
  selectedLineId,
  setSelectedLineId,
}: LinkInvoiceModalLinkableLinesProps) {
  const { t } = useTranslation()
  return (
    <section>
      <p className="font-bold">
        {!!purchaseOrderLineItemId
          ? t('invoices.invoice.linkModal.linkedLine', 'Linked Purchase Order Line')
          : t('invoices.invoice.linkModal.availableLines', 'Available Purchase Order Lines')}
      </p>
      <p className="text-gray-500">
        {!!purchaseOrderLineItemId ? (
          t('invoices.invoice.linkModal.linkedLineDesc', 'Details of the linked Purchase Order line.')
        ) : (
          <Trans t={t} i18nKey="invoices.invoice.linkModal.availableLinesDesc">
            Select a Purchase Order line to link to this Invoice line. The lines below are those from the matched
            Purchase Order that are not yet linked to a line on this Invoice. Take care when linking lines as this
            action cannot be undone.
          </Trans>
        )}
      </p>
      {hasLines || !!purchaseOrderLineItemId ? (
        <LinkInvoiceModalTable type={LinkInvoiceTableTypes.PurchaseOrder} canBeUpdated={canBeUpdated}>
          {!!purchaseOrderLineItemId && (
            <LinkInvoiceModalTableRow
              line={purchaseOrderLineItem as unknown as RequisitionLine}
              supplier={supplier}
              selectedLineId={selectedLineId}
              setSelectedLineId={setSelectedLineId}
              canBeUpdated={canBeUpdated}
            />
          )}
          {linkableLines?.map((line) => (
            <LinkInvoiceModalTableRow
              line={line as unknown as RequisitionLine}
              supplier={supplier}
              selectedLineId={selectedLineId}
              setSelectedLineId={setSelectedLineId}
              key={line.id}
              canBeUpdated={canBeUpdated}
            />
          ))}
        </LinkInvoiceModalTable>
      ) : (
        <div className="mt-3 border bg-gray-100 px-3 py-5 text-center text-gray-500">
          {!purchaseOrderId
            ? t('invoices.invoice.linkModal.noPurchaseOrder', 'There is no Purchase Order matched to this invoice')
            : t('invoices.invoice.linkModal.noLinkableLines', 'There are no available Purchase Order lines')}
        </div>
      )}
    </section>
  )
}
