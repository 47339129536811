import { graphql } from 'msw'

import { UpdatePurchaseOrderMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const UpdatePurchaseOrder = graphql.mutation<UpdatePurchaseOrderMutation>(
  'UpdatePurchaseOrder',
  (_, res, ctx) => {
    return res(
      ctx.data({
        updatePurchaseOrder: {
          purchaseOrder: {
            id: 16566710,
            expectedDeliveryDate: '2023-01-28',
            account: {
              id: 96393,
              accountName: '0060009 - Operating Account - 9',
              code: '0060009',
              __typename: 'Account',
            },
            department: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Purchaser',
            },
            deliveryAddress: {
              id: 1961,
              locationName: '',
              line1: 'test line 1 ',
              line2: 'test line 2',
              city: 'Hornsby',
              stateProvince: 'NSW',
              country: '',
              __typename: 'DeliveryAddress',
            },
            sendToSupplier: false,
            __typename: 'PurchaseOrder',
          },
          __typename: 'UpdatePurchaseOrderMutationPayload',
        },
      })
    )
  }
)
