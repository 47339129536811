import { useTranslation } from 'react-i18next'

import SideNavLink from '../SideNavLink'
import { SideBarItem } from './PurchaserNavigation'

import { Accordion } from '@/modules/shared/components'
import { OrderIcon, PlusIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface PurchaseOrdersSideBarItemProps extends SideBarItem {
  purchaseOrdersRoutesMatch: boolean
  setShowCreatePurchaseOrderModal: (showCreatePurchaseOrderModal: boolean) => void
}

export const PurchaseOrdersSideBarItem = ({
  purchaseOrdersRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
  setShowCreatePurchaseOrderModal,
}: PurchaseOrdersSideBarItemProps) => {
  const { t } = useTranslation()
  return (
    <Accordion
      titlePrefixIcon={<OrderIcon className="h-7 w-7" />}
      title={t('general.purchaseOrders', 'Purchase Orders')}
      buttonClassName="h-11 px-3"
      activeColor={purchaseOrdersRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === 1}
      handleTriggerClick={() => changeOpenAccordionKey(1)}
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="purchaseorder-navigation"
        sideNavLinkItems={[
          {
            key: t('general.newPurchaseOrder', 'New Purchase Order'),
            icon: <PlusIcon className="h-6 w-6 text-gray-500" />,
            onClick: () => setShowCreatePurchaseOrderModal(true),
          },
          {
            key: t('general.viewAll', 'View All'),
            target: '/purchase-orders',
          },
          {
            key: t('general.receivingNotes', 'Receiving Notes'),
            target: '/purchase-orders/receiving-notes',
          },
        ]}
      />
    </Accordion>
  )
}
