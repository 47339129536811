import { useQuery } from '@apollo/client'

import { GetCurrentOrganisationDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useCurrentOrganisation() {
  const { data, loading, refetch } = useQuery(GetCurrentOrganisationDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })
  return {
    currentOrganisation: data?.currentOrganisationUnit,
    loading,
    refetch,
  }
}
