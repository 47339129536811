import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { useGetAllInvoices } from '../../hooks'
import InvoiceTableView from '../all-invoices/table-view/InvoiceTableView'

import EmptyInvoices from '@/assets/images/empty-invoices.svg'
import { ProductListEmptyState } from '@/modules/requisitions/components'
import { Breadcrumb, QueryResult, TitleBar, displayBadgeCount } from '@/modules/shared/components'
import AdvancedSearch from '@/modules/shared/components/advanced-search/AdvancedSearch'
import SearchAndFilter from '@/modules/shared/components/search-input/SearchAndFilter'
import { HomeIcon, InvoiceIcon } from '@/modules/shared/icons'
import SearchContainer from '@/modules/shared/search-container'
import { Document } from '@/modules/shared/types'
import { QueryParameter } from '@/modules/shared/types'

export default function InvoicesAwaitingMyApproval() {
  const { t } = useTranslation()
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false)
  const [preventAdvancedSearchAutoOpening, setPreventAdvancedSearchAutoOpening] = useState<boolean>(false)

  const { onFilter, onNextPage, onPreviousPage, error, loadingStates, invoices, pageInfo, paginationResultsPerPage } =
    useGetAllInvoices({ awaitingMyApproval: true })

  return (
    <>
      <Helmet>
        <title>{t('general.awaitingMyApproval', 'Awaiting My Approval')}</title>
      </Helmet>

      <main className="mx-auto space-y-5 px-4">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="/invoices">{t('general.invoices', 'Invoices')}</Breadcrumb.Item>
          <Breadcrumb.Item href="#">{t('general.awaitingMyApproval', 'Awaiting My Approval')}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="space-y-2">
          <TitleBar
            icon={<InvoiceIcon className="h-10 w-10" />}
            title={t('invoices.invoiceCount', '{{ number }} Invoices', {
              number: displayBadgeCount(invoices.length, 20),
            })}
            subtitle={t('general.awaitingMyApproval', 'Awaiting My Approval')}
          />
        </section>
        <SearchContainer
          showAdvancedSearch={showAdvancedSearch}
          setShowAdvancedSearch={setShowAdvancedSearch}
          openByDefault={!preventAdvancedSearchAutoOpening}
        >
          <SearchAndFilter
            testId="invoices-search-input"
            placeholder={t('invoices.allInvoices.searchPlaceholder', 'Search by Invoice Number or Reference')}
            ariaLabel={t('general.searchingForInvoices', 'Searching for Invoices')}
            onFilter={onFilter}
            queryParamFilterType={QueryParameter.Status}
          />
        </SearchContainer>
        {showAdvancedSearch && <AdvancedSearch type={Document.Invoice} showSupplier />}
        <section>
          <QueryResult loading={loadingStates.loading || loadingStates.refetchLoading} error={error}>
            <InvoiceTableView
              invoices={invoices.slice(0, paginationResultsPerPage)}
              onNextPage={onNextPage}
              onPreviousPage={onPreviousPage}
              pageInfo={pageInfo}
              paginationLoading={loadingStates.setVariablesLoading}
              setPreventAdvancedSearchAutoOpening={setPreventAdvancedSearchAutoOpening}
              awaitingMyApproval
            />
            {!loadingStates.setVariablesLoading && invoices.length === 0 && (
              <ProductListEmptyState
                image={EmptyInvoices}
                message={t('invoices.allInvoices.noInvoices', "We couldn't find any Invoices to display.")}
              />
            )}
          </QueryResult>
        </section>
        <div className="h-96" />
      </main>
    </>
  )
}
