import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DeliveryEvent from './DeliveryEvent'

import { PurchaseOrder, SendPurchaseOrderDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useGetPOSendEvents } from '@/modules/purchase-orders/hooks'
import PurchaseOrderStatus from '@/modules/purchase-orders/pages/all-purchase-order/PurchaseOrderStatus'
import { PurchaseOrderStatuses } from '@/modules/purchase-orders/types'
import { statusText } from '@/modules/purchase-orders/utils/statusText'
import { Spinner } from '@/modules/requisitions/components'
import { Button, Modal } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { CheckIcon } from '@/modules/shared/icons'

interface PurchaseOrderModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<number | null>>
  purchaseOrder: PurchaseOrder
}

function PurchaseOrderModal({ showModal, setShowModal, purchaseOrder }: PurchaseOrderModalProps) {
  const { t } = useTranslation()
  const { id, purchaseOrderNumber, sentDate, status } = purchaseOrder || {}
  const [sendToSupplier, setSendToSupplier] = useState(purchaseOrder?.sendToSupplier)
  const [loadingEvents, setLoadingEvents] = useState(false)
  const pollTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const state = statusText(status, sentDate)

  const { sendEvents, error, startPolling, stopPolling } = useGetPOSendEvents({ POId: id })

  const onCloseModal = () => {
    setShowModal(null)
  }

  useEffect(() => {
    if (state === PurchaseOrderStatuses.Sent && !sendEvents?.length) {
      setLoadingEvents(true)
      startPolling(500)
      // Set a timeout to stop polling after 10 seconds
      if (!pollTimeoutRef.current) {
        pollTimeoutRef.current = setTimeout(() => {
          setLoadingEvents(false)
          stopPolling()
          pollTimeoutRef.current = null
        }, 10000) // 10 seconds
      }
    } else if (state === PurchaseOrderStatuses.Sent && sendEvents?.length) {
      setLoadingEvents(false)
      stopPolling()
      // Clear the timeout if polling is manually stopped
      if (pollTimeoutRef.current) {
        clearTimeout(pollTimeoutRef.current)
        pollTimeoutRef.current = null
      }
    }
  }, [state, sendEvents, stopPolling, startPolling])

  const [SendPurchaseOrder, { loading: loadingPO }] = useMutation(SendPurchaseOrderDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const onSendOrder = () => {
    SendPurchaseOrder({
      variables: {
        updatePOInput: {
          id,
          sentDate: new Date().toISOString(),
          sending: true,
          sendToSupplier,
        },
        sendPOInput: {
          id,
        },
      },
    })
  }

  /**
   * Return translation strings depending on variable state.
   *
   * @param purchaseOrderState: enum PurchaseOrderStatuses
   * @param purchaseOrderNumber: string type purchaseOrderNumber
   *
   * @returns Object containing three string properties: title, status, and statusBody.
   */
  const handlePOModalText = (purchaseOrderState: PurchaseOrderStatuses, purchaseOrderNumber?: string | null) => {
    let POModalTitle, POModalStatus, POModalStatusBody
    switch (purchaseOrderState) {
      case PurchaseOrderStatuses.Sent:
        POModalTitle = t('confirmation.POModal.sentTitle', '{{ id }} is Sent', { id: purchaseOrderNumber })
        POModalStatus = t('general.sentStatus', 'Sent Status')
        POModalStatusBody = t(
          'confirmation.POModal.sentStatusBody',
          'A Sent Purchase Order has been marked as Sent and the items on the Purchase Order can now be Received and Invoiced. Once all items on the Purchase Order are Received and Invoiced, the Purchase Order can be Closed.'
        )
        break
      case PurchaseOrderStatuses.NotSent:
        POModalTitle = t('confirmation.POModal.notSentTitle', '{{ id }} is Not Sent', { id: purchaseOrderNumber })
        POModalStatus = t('general.notSentStatus', 'Not Sent Status')
        POModalStatusBody = t(
          'confirmation.POModal.notSentStatusBody',
          'A Not Sent Purchase Order has been created from an Approved Purchase Requisition, but it is yet to be Sent to the Supplier. Prior to being Sent, items on a Purchase Order cannot be Received or Invoiced. You can send this Purchase Order now using the button below.'
        )
        break
      case PurchaseOrderStatuses.Closed:
        POModalTitle = t('confirmation.POModal.closedTitle', '{{ id }} is Closed', { id: purchaseOrderNumber })
        POModalStatus = t('general.closedStatus', 'Closed Status')
        POModalStatusBody = t(
          'confirmation.POModal.closedStatusBody',
          'A Purchase Order can be closed once it has been Sent, and all items on the Purchase Order have been fully Received and fully Invoiced. Closed is the final state of a Purchase Order and it indicates that the Purchase Order items have been successfully received and invoiced.'
        )
        break
      case PurchaseOrderStatuses.Cancelled:
        POModalTitle = t('confirmation.POModal.cancelledTitle', '{{ id }} is Cancelled', { id: purchaseOrderNumber })
        POModalStatus = t('general.cancelledStatus', 'Cancelled Status')
        POModalStatusBody = t(
          'confirmation.POModal.cancelledStatusBody',
          'A Purchase Order can be closed once it has been Sent, and all items on the Purchase Order have been fully Received and fully Invoiced. Closed is the final state of a Purchase Order and it indicates that the Purchase Order items have been successfully received and invoiced.'
        )
        break
    }
    return { POModalTitle, POModalStatus, POModalStatusBody }
  }

  const { POModalTitle, POModalStatus, POModalStatusBody } = handlePOModalText(state, purchaseOrderNumber)

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal} dataTestId="sent-order-modal">
      <Modal.Panel
        as="div"
        className="flex max-h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]"
      >
        <Modal.Title title={POModalTitle || ''} onCloseModal={onCloseModal} />
        <Modal.Body className="flex flex-col gap-y-5">
          <section>
            <p className="font-bold">{POModalStatus}</p>
            <p className="text-gray-500">{POModalStatusBody}</p>
            <div className="mt-3 rounded-md border bg-gray-100 px-3 py-4">
              <PurchaseOrderStatus status={state} />
            </div>
          </section>
          {state === PurchaseOrderStatuses.NotSent && (
            <section>
              <p className="font-bold">{t('confirmation.POModal.sendPO', 'Send Purchase Order Electronically?')}</p>
              <p className="mb-4 text-gray-500">
                {t(
                  'confirmation.POModal.sendPOBody',
                  "If you decide to Send the Purchase Order, you can uncheck this option below to prevent the Supplier from receiving it electronically. The Purchase Order will be marked as Sent, but PurchasePlus will not electronically deliver it to the Supplier. There may be reasons why you don't need the Supplier to receive it, for example you may have phoned ahead and placed your order and you don't wish the Supplier to receive a duplicate order."
                )}
              </p>
              <div className="flex items-center gap-x-2">
                <CheckIcon
                  className={classNames('h-7 w-7 cursor-pointer rounded-full border-gray-300', {
                    'text-success': sendToSupplier,
                    'border text-white': !sendToSupplier,
                  })}
                  onClick={() => setSendToSupplier(!sendToSupplier)}
                />
                <span>{t('confirmation.POModal.checkBox', 'Send Purchase Order Electronically')}</span>
              </div>
            </section>
          )}
          {state === PurchaseOrderStatuses.Sent && (
            <section>
              <p className="font-bold">{t('general.electronicSendEvents', 'Electronic Send Events')}</p>
              <p className="mb-4 text-gray-500">
                {t(
                  'confirmation.POModal.sendEventsBody',
                  'When the Purchase Order was Sent, depending on the settings applied at the time, it may or may not have been electronically sent to the Supplier. If it was electronically sent, each send event will be listed below. There may be reasons why a Purchase Order was not electronically sent to the Supplier, for example the order may have been placed by phone and the user did not want the Supplier to receive a duplicate order.'
                )}
              </p>
              <div className="flex flex-col gap-y-2">
                {sendEvents.length ? (
                  sendEvents.map((delivery) => <DeliveryEvent delivery={delivery} key={delivery.id} />)
                ) : (
                  <>
                    {loadingEvents && !error ? (
                      <Spinner className="h-10 w-10" />
                    ) : (
                      <div className="border bg-gray-100 p-4 text-center text-gray-500">
                        {t('confirmation.POModal.noDeliveries', 'No Electronic Send Events Found')}
                      </div>
                    )}
                  </>
                )}
              </div>
            </section>
          )}
        </Modal.Body>
        <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
          <Button
            type="button"
            className="h-11 w-full rounded-md bg-gray-200 px-6 text-sm md:w-fit"
            onClick={onCloseModal}
            data-testid="send-order-close"
          >
            {state === PurchaseOrderStatuses.NotSent ? t('general.cancel', 'Cancel') : t('general.close', 'Close')}
          </Button>
          {state === PurchaseOrderStatuses.NotSent && (
            <Button
              type="button"
              className="h-11 w-full rounded-md bg-success px-6 text-sm text-white md:w-fit"
              onClick={onSendOrder}
              data-testid="send-order-send"
              loading={loadingPO}
            >
              {t('general.send', 'Send')}
            </Button>
          )}
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}

export default PurchaseOrderModal
