import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import PurchaseOrdersEmptyStateImg from '@/assets/images/empty-purchase-orders.svg'
import { GetInvoiceDocument, PurchaseOrder } from '@/graphql/purchasing/generated/purchasing_graphql'
import PurchaseOrdersTableView from '@/modules/requisitions/pages/requisition/purchase-orders/table-view'
import PurchaseOrdersTileView from '@/modules/requisitions/pages/requisition/purchase-orders/tile-view'
import { QueryResult } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { ControlBarIcon } from '@/modules/shared/icons'

export default function InvoicePurchaseOrder() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()
  const { invoiceId } = useParams<{ invoiceId: string }>()

  const { data, loading, error } = useQuery(GetInvoiceDocument, {
    variables: { invoiceId: Number(invoiceId) },
    context: { uri: PURCHASING_GRAPHQL_API },
  })
  const purchaseOrder = data?.currentPurchaser?.invoice?.purchaseOrder as PurchaseOrder
  const purchaseOrders = purchaseOrder ? [purchaseOrder] : []

  return (
    <QueryResult loading={loading} error={error}>
      <div data-testid="invoice-po-section" className="flex items-center">
        <ControlBarIcon className="h-10 w-10" />
        <p className="ml-3 text-sm">
          {data?.currentPurchaser?.invoice?.invoiceNumber} {t('general.purchaseOrder', 'Purchase Order')}
        </p>
      </div>
      {isLargeScreen ? (
        <PurchaseOrdersTableView purchaseOrders={purchaseOrders} />
      ) : (
        <div className="space-y-5">
          <PurchaseOrdersTileView purchaseOrders={purchaseOrders} />
        </div>
      )}
      {purchaseOrders.length === 0 && (
        <EmptyState
          img={PurchaseOrdersEmptyStateImg}
          message={t('invoices.invoice.settings.noPo', 'There is no Purchase Order matched to this Invoice.')}
        />
      )}
    </QueryResult>
  )
}
