import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

import AddDeptAndAccountModal from './AddDeptAndAccountModal'
import AddOrderInstructionModal from './AddOrderInstructionModal'
import LinesBySuppliersMoreOptionsMenu from './MoreOptionsMenu'

import { RequisitionLine, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useChangeRequisitionLineQuantity, useLinesBySuppliers } from '@/modules/requisitions/hooks'
import { ShoppingCartContext } from '@/modules/requisitions/types'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Button, Stepper } from '@/modules/shared/components'
import ProductModal from '@/modules/shared/components/product-modal/ProductModal'
import { SupplierQuotesButton } from '@/modules/shared/components/table/table-column-items'
import { useMoney } from '@/modules/shared/hooks'
import { CloseIcon, OptionIcon } from '@/modules/shared/icons'
import { Loading } from '@/modules/shared/icons-special'

interface LinesBySuppliersTileViewProps {
  line: RequisitionLine
  lineIndex: number
  supplier: Supplier
  refetch?: () => void
}

export default function LinesBySuppliersTileView({
  line,
  lineIndex,
  supplier,
  refetch,
}: LinesBySuppliersTileViewProps) {
  const {
    id: lineId,
    orderInstruction,
    department,
    account,
    product,
    unitPrice,
    quantity,
    taxPercentage,
    total,
    cataloguedProductId,
    availableQuotes,
    image,
  } = line
  const {
    itemMeasure,
    itemPackName,
    itemSize,
    concatenatedSellUnit,
    id: productId,
    brand,
    itemDescription,
  } = product || {}
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [showAddOrderInstructionModal, setShowAddOrderInstructionModal] = useState(false)
  const [showAddDeptAndAccountModal, setShowAddDeptAndAccountModal] = useState(false)
  const [showProductModal, setShowProductModal] = useState(false)
  const { readonly } = useOutletContext<ShoppingCartContext>()
  const hasDeptAndAccount = department && account

  const {
    onRemoveOrderInstruction,
    onRemoveRequisitionLine,
    onRemoveDeptAndAccount,
    loading,
    activeDeptAndAccountLoadingId,
    activeOrderInstructionLoadingId,
  } = useLinesBySuppliers(Number(lineId), Number(productId))

  const { onUpdateRequisitionLineQuantity, loading: loadingChangeRequisition } = useChangeRequisitionLineQuantity()

  return (
    <>
      <div className="flex flex-col rounded-md bg-white shadow-sm" data-testid="summary-items-res">
        <div className="flex justify-between px-5 py-4">
          <div className="flex flex-col">
            <span className="cursor-pointer text-primary" onClick={() => setShowProductModal(true)}>
              {brand} {itemDescription}
            </span>
            <span className="text-xs text-gray-500">
              <p>
                {formatProductItem({
                  itemMeasure: itemMeasure,
                  itemPackName: itemPackName,
                  itemSize: itemSize,
                  itemSellUnit: concatenatedSellUnit,
                })}
              </p>
            </span>
            <strong className="mt-3 text-base">{`${formatMoney(unitPrice)}`}</strong>
            <span className="text-xs">
              {t('shopPage.cart.summary.plusTax', 'Plus {{ taxPercentage }}% Tax', {
                taxPercentage: taxPercentage || 0,
              })}
            </span>
            <ProductModal
              line={line}
              showModal={showProductModal}
              setShowModal={setShowProductModal}
              supplier={supplier}
            />
          </div>
          {!readonly && (
            <LinesBySuppliersMoreOptionsMenu
              setActiveLineOrderInstructionIndex={setShowAddOrderInstructionModal}
              setActiveLineDeptAndAccountIndex={setShowAddDeptAndAccountModal}
              onRemoveRequisitionLine={onRemoveRequisitionLine}
              lineIndex={lineIndex}
            >
              <div
                className={classNames(`flex h-10 w-10 cursor-pointer items-center justify-center rounded-full`, {
                  'bg-primary/10 text-primary hover:bg-primary/20': orderInstruction || hasDeptAndAccount,
                  'bg-gray-200 text-gray-500 hover:bg-gray-300': !orderInstruction && !hasDeptAndAccount,
                })}
              >
                <OptionIcon className="h-6 w-6" />
              </div>
            </LinesBySuppliersMoreOptionsMenu>
          )}
        </div>
        <div>
          {(orderInstruction || hasDeptAndAccount) && (
            <>
              {orderInstruction && (
                <div className="px-5">
                  <div
                    data-testid="order-instruction-block-res"
                    className="flex cursor-pointer items-center justify-between rounded-md bg-primary/20 p-2 md:gap-x-3"
                    onClick={() => !readonly && setShowAddOrderInstructionModal(true)}
                  >
                    <div className="flex flex-col md:grow md:flex-row md:justify-between">
                      <p className="text-sm font-bold text-primary">
                        {t('general.orderInstruction', 'Order Instruction')}
                      </p>
                      <p className="text-sm text-primary">{orderInstruction}</p>
                    </div>
                    {!readonly && (
                      <div className="flex items-center gap-x-3">
                        <Button
                          data-testid="remove-order-instruction-btn-res"
                          onClick={(e) => {
                            e.stopPropagation()
                            onRemoveOrderInstruction()
                          }}
                        >
                          {loading && activeOrderInstructionLoadingId === lineId ? (
                            <Loading className="h-5 w-5 fill-primary text-primary/40" />
                          ) : (
                            <CloseIcon className="h-5 w-5 text-primary" />
                          )}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {hasDeptAndAccount && (
                <div className="px-5 pt-2" data-testid="dept-account-block-res">
                  <div
                    data-testid="order-instruction-block-res"
                    className="flex cursor-pointer items-center justify-between rounded-md bg-primary/20 p-2 md:gap-x-3"
                    onClick={() => !readonly && setShowAddDeptAndAccountModal(true)}
                  >
                    <div className="flex flex-col md:grow md:flex-row md:justify-between">
                      <p className="text-sm font-bold text-primary">
                        {t('shopPage.cart.summary.table.deptAndAccount', 'Department and Account Code')}
                      </p>
                      <p className="text-sm text-primary">
                        {department.name} {account.code}
                      </p>
                    </div>
                    {!readonly && (
                      <div className="flex items-center gap-x-3">
                        <Button
                          data-testid="remove-dept-account-btn-res"
                          onClick={(e) => {
                            e.stopPropagation()
                            onRemoveDeptAndAccount()
                          }}
                        >
                          {loading && activeDeptAndAccountLoadingId === lineId ? (
                            <Loading className="h-5 w-5 fill-primary text-primary/40" />
                          ) : (
                            <CloseIcon className="h-5 w-5 text-primary" />
                          )}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="mt-4 flex items-center justify-between border-t border-gray-200 px-5 py-4">
          {t('general.quotes', 'Quotes')}
          <SupplierQuotesButton
            product={product}
            prices={availableQuotes}
            currentSupplierId={supplier?.id}
            lineId={lineId}
            image={image}
            quantity={quantity}
            refetch={refetch}
          />
        </div>
        <div className="flex justify-between border-t border-gray-200">
          <span className="p-3 px-5">
            {!readonly ? (
              <Stepper
                loading={loadingChangeRequisition}
                value={quantity || 0}
                stepperAriaLabel={t('shopPage.stepperAriaLabel', 'Entering number of products')}
                onChange={(newQty: number) =>
                  onUpdateRequisitionLineQuantity({
                    qty: newQty,
                    lineId,
                    productId: Number(productId),
                    cataloguedProductId,
                  })
                }
              />
            ) : (
              <div className="flex items-center gap-x-2">
                <input value={String(quantity)} className="h-10 w-12 border bg-gray-100 text-center" readOnly />
                <p className="text-xs">{t('general.quantity', 'Quantity')}</p>
              </div>
            )}
          </span>
          <div className="flex flex-col p-3 px-5 text-right">
            <strong>{`${formatMoney(total)}`}</strong>
            <span className="text-xs">
              {t('shopPage.cart.summary.plusTax', 'Plus {{ taxPercentage }}% Tax', {
                taxPercentage: taxPercentage || 0,
              })}
            </span>
          </div>
        </div>
      </div>
      <AddOrderInstructionModal
        showModal={showAddOrderInstructionModal}
        setShowModal={setShowAddOrderInstructionModal}
        line={line as RequisitionLine}
      />
      <AddDeptAndAccountModal
        showModal={showAddDeptAndAccountModal}
        setShowModal={setShowAddDeptAndAccountModal}
        line={line as RequisitionLine}
      />
    </>
  )
}
