import { useQuery } from '@apollo/client'

import { GetCurrentLegalEntityDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useCurrentLegalEntity() {
  const { data, loading } = useQuery(GetCurrentLegalEntityDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })
  return {
    currentLegalEntity: data?.currentLegalEntity,
    loading,
  }
}
