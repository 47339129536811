import { useQuery } from '@apollo/client'

import {
  GetAllInvoicesDocument,
  Invoice,
  PageInfo,
  RansackDirection,
  RansackMatcher,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

interface UseGetAllInvoicesProps {
  awaitingMyApproval?: boolean
}

export default function useGetAllInvoices({ awaitingMyApproval }: UseGetAllInvoicesProps) {
  const paginationResultsPerPage = 20
  const { data, networkStatus, error, refetch, fetchMore } = useQuery(GetAllInvoicesDocument, {
    variables: {
      after: null,
      filter: null,
      sort: [
        {
          property: 'invoiceDate',
          direction: RansackDirection.Desc,
        },
      ],
    },
    context: { uri: PURCHASING_GRAPHQL_API },
    notifyOnNetworkStatusChange: true,
  })

  const onFetchMoreInvoices = () => {
    fetchMore({
      variables: {
        after: data?.currentPurchaser?.invoices?.pageInfo.endCursor,
        before: null,
        last: null,
        first: paginationResultsPerPage,
      },
    })
  }

  const onFilter = ({ searchValue, filterValue, fromDate, toDate, supplierIds }: OnFilter) => {
    let filter: RansackMatcher[] = []

    if (searchValue) {
      filter.push({
        property: 'invoice_number_or_reference_cont',
        value: searchValue.trim(),
      })
    }
    if (filterValue && !awaitingMyApproval) {
      filter.push({
        property: 'state_eq',
        value: filterValue === 'all' ? '' : filterValue,
      })
    }
    if (fromDate) {
      filter.push({
        property: 'invoiceDate_gt',
        value: fromDate,
      })
    }
    if (toDate) {
      filter.push({
        property: 'invoiceDate_lt',
        value: toDate,
      })
    }

    if (supplierIds) {
      filter.push({
        property: 'supplier_id_in',
        value: supplierIds,
      })
    }

    if (awaitingMyApproval) {
      filter.push({
        property: 'state_eq',
        value: 'flagged',
      })
    }

    refetch({
      filter: {
        q: [...filter],
      },
      first: 20,
      after: null,
      last: null,
    })
  }

  const onPreviousPage = () => {
    const { startCursor } = data?.currentPurchaser?.invoices?.pageInfo || {}
    refetch({
      before: startCursor,
      after: null,
      last: 20,
      first: null,
    })
  }

  const onNextPage = () => {
    const { endCursor } = data?.currentPurchaser?.invoices?.pageInfo || {}
    refetch({
      after: endCursor,
      before: null,
      first: 20,
      last: null,
    })
  }

  return {
    invoices: extractEdges<Invoice>(data?.currentPurchaser?.invoices),
    loadingStates: checkNetworkStatus(networkStatus),
    paginationResultsPerPage,
    error,
    onFetchMoreInvoices,
    onFilter,
    onNextPage,
    onPreviousPage,
    pageInfo: data?.currentPurchaser?.invoices?.pageInfo || ({} as PageInfo),
  }
}
