import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'

import OptionDetails from './OptionDetails'

import { User } from '@/graphql/access/generated/access_graphql'
import { Purchaser } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useSession } from '@/modules/access/hooks'
import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import { Button, ConfirmDialog } from '@/modules/shared/components'
import { ChevronLeftIcon, OptionIcon, SaveIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface ShopHeaderProps {
  isCart?: boolean
}

export default function ShopHeader({ isCart }: ShopHeaderProps) {
  const { t } = useTranslation()
  const { currentUser } = useSession()
  const { currentOrganisation } = useCurrentOrganisation()
  const navigate = useNavigate()
  const location = useLocation()
  const { requisitionId } = useParams<{ requisitionId: string }>()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <header className="flex items-center justify-between border-b bg-white px-5 py-3">
      <>
        {isCart ? (
          <Link
            className="flex h-11 items-center gap-2 rounded-md bg-gray-100 pl-2 pr-4 text-sm transition duration-200 hover:bg-gray-200"
            to={generatePath(`/requisitions/:requisitionId/shop/:queryParam`, {
              requisitionId: String(requisitionId),
              queryParam: location.search,
            })}
            data-testid="continue-shopping"
          >
            <ChevronLeftIcon className="h-6 w-6" />
            <span>{t('general.continueShopping', 'Continue Shopping')}</span>
          </Link>
        ) : (
          <Button
            className="flex h-11 items-center gap-2 rounded-md bg-gray-100 px-4 text-sm transition duration-200 hover:bg-gray-200"
            onClick={() => setIsOpen(true)}
            data-testid="save-and-close-button"
          >
            <span>{t('general.save&Close', 'Save & Close')}</span>
            <SaveIcon className="h-7 w-7" />
          </Button>
        )}
      </>
      {currentUser && currentOrganisation && (
        <OptionDetails currentUser={currentUser as User} currentOrganisation={currentOrganisation as Purchaser}>
          <OptionIcon className="h-8 w-8 text-gray-600" data-testid="options-button" />
        </OptionDetails>
      )}
      <ConfirmDialog
        title={t('general.save&Close', 'Save & Close')}
        description={t(
          'access.shop.confirm.saveDescription',
          'By choosing Save and Close you will be returned to the dashboard and the Requisition will be saved in your drafts list. You will be able to resume shopping the Requisition again anytime in the future.'
        )}
        primaryButtonLabel={t('general.saveAndClose', 'Save and Close')}
        secondaryButtonLabel={t('general.continueShopping', 'Continue Shopping')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onPrimaryBtnClick={() => navigate('/dashboard')}
        theme={Themes.Primary}
        testId="save-and-close-dialog"
      />
    </header>
  )
}
