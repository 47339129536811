import { graphql } from 'msw'

import { GetInvoiceReceivingNotesQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetInvoiceReceivingNotes: GetInvoiceReceivingNotesQuery = {
  currentPurchaser: {
    id: 44926,
    invoice: {
      id: 7524140,
      invoiceNumber: 'INGR02385',
      purchaseOrderId: 16566710,
      receivingDocuments: {
        edges: [
          {
            node: {
              id: 6660507,
              receivingDocumentNumber: 'GR03074',
              receivedDate: '2023-05-24T12:04:00+10:00',
              workflowState: 'received',
              destinationId: 2162,
              purchaseOrderId: 16567441,
              destination: {
                id: '2162',
                name: 'Cafe101',
                __typename: 'StockLocation',
              },
              transfer: {
                id: '1135924',
                transferNumber: 'TX01251',
                __typename: 'ReceivingTransfer',
              },
              receivingTotalValue: 39,
              __typename: 'ReceivingDocument',
              receiver: {
                id: 23790,
                fullName: 'Nathan Del Medico',
                __typename: 'User',
              },
            },
            __typename: 'ReceivingDocumentEdge',
          },
          {
            node: {
              id: 6660508,
              receivingDocumentNumber: 'GR03075',
              receivedDate: '2023-05-24T12:04:35+10:00',
              workflowState: 'open',
              destinationId: 2162,
              purchaseOrderId: 16567441,
              destination: {
                id: '1111',
                name: 'New Location',
                __typename: 'StockLocation',
              },
              transfer: {
                id: '1135925',
                transferNumber: 'TX01252',
                __typename: 'ReceivingTransfer',
              },
              receivingTotalValue: 27,
              __typename: 'ReceivingDocument',
              receiver: {
                id: 23790,
                fullName: 'Nathan Del Medico',
                __typename: 'User',
              },
            },
            __typename: 'ReceivingDocumentEdge',
          },
        ],
        __typename: 'ReceivingDocumentConnection',
      },
      __typename: 'Invoice',
    },
    __typename: 'Purchaser',
  },
}

export const GetInvoiceReceivingNotes = graphql.query<GetInvoiceReceivingNotesQuery>(
  'GetInvoiceReceivingNotes',
  (_, res, ctx) => {
    return res(ctx.data(baseGetInvoiceReceivingNotes))
  }
)

export default baseGetInvoiceReceivingNotes
