import { useTranslation } from 'react-i18next'

import { useGetBuyListDetails } from './hooks/useGetBuyListDetails'

import { QueryResult } from '@/modules/shared/components'
import { TableRowDouble, TableRowSingle } from '@/modules/shared/components/page-header-details-table'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

export interface BuyListDetailsPanelProps {
  buyListId: string
}

function BuyListDetailsPanel({ buyListId }: BuyListDetailsPanelProps) {
  const { t } = useTranslation()

  const { title, owner, createdAt, user, updatedAt, account, department, loading, error } =
    useGetBuyListDetails(buyListId)

  return (
    <section
      className="flex items-center justify-between rounded-md  bg-white p-4 text-sm shadow"
      data-testid="buyList-details-tab"
    >
      <QueryResult loading={loading} error={error} size="md">
        <div className="w-full divide-y divide-gray-200 overflow-auto rounded-md border border-gray-300">
          <TableRowSingle
            titleText={t('general.name', 'Name')}
            tooltipText={t('buyList.detailsTable.nameTooltip', 'The name of the Buy List')}
            testId="buyList-details-name"
            value={title}
          />
          <TableRowSingle
            titleText={t('general.owner', 'Owner')}
            tooltipText={t('buyList.detailsTable.ownerTooltip', 'The organisation to whom the buy list belongs')}
            testId="buyList-details-owner"
            value={owner?.name}
          />
          <TableRowDouble
            leftTitle={t('general.department', 'Department')}
            leftTooltip={t(
              'buyList.detailsTable.departmentTooltip',
              'When using the Buy List to create a Requisition, this is the Department that the Requisition will be recorded against.'
            )}
            leftTestId="buyList-details-department"
            leftValue={department?.name}
            rightTitle={t('general.accountCode', 'Account Code')}
            rightTooltip={t(
              'buyList.detailsTable.accountCodeTooltip',
              'When using the Buy List to create a Requisition, this is the Account Code that the Requisition will be recorded against.'
            )}
            rightTestId="buyList-details-accountCode"
            rightValue={account?.accountName}
          />
          <TableRowSingle
            titleText={t('general.createdOn', 'Created On')}
            tooltipText={t('buyList.detailsTable.createdOnTooltip', 'The date that the Buy List was created.')}
            testId="buyList-details-createdOn"
            value={formatDate(Dates.Long, createdAt)}
          />
          {/* TODO: The user always returns as null from the backend, this will be fixed on a backend ticket */}
          <TableRowSingle
            titleText={t('general.createdBy', 'Created By')}
            tooltipText={t('buyList.detailsTable.createdByTooltip', 'The user who created the Buy List.')}
            testId="buyList-details-createdBy"
            value={user?.fullName}
          />
          <TableRowSingle
            titleText={t('general.lastUpdated', 'Last Updated')}
            tooltipText={t(
              'buyList.detailsTable.lastUpdatedTooltip',
              'The time and date that the Buy List was last updated.'
            )}
            testId="buyList-details-lastUpdated"
            value={formatDate(Dates.Long, updatedAt)}
          />
        </div>
      </QueryResult>
    </section>
  )
}

export default BuyListDetailsPanel
