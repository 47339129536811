import { Fragment, memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PurchaseOrder } from '@/graphql/purchasing/generated/purchasing_graphql'
import PurchaseOrderInvoicedStatus from '@/modules/purchase-orders/pages/all-purchase-order/PurchaseOrderInvoicedStatus'
import PurchaseOrderReceivedStatus from '@/modules/purchase-orders/pages/all-purchase-order/PurchaseOrderReceivedStatus'
import PurchaseOrderStatus from '@/modules/purchase-orders/pages/all-purchase-order/PurchaseOrderStatus'
import { statusText } from '@/modules/purchase-orders/utils/statusText'
import PurchaseOrderModal from '@/modules/requisitions/pages/confirmation/requisition-purchase-orders/PurchaseOrderModal'
import { Fallback, MoreOptionsMenu, PPNewDetailLink, SimpleTileViewItem } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TileViewProps {
  purchaseOrders: PurchaseOrder[]
}

function TileView({ purchaseOrders }: TileViewProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [isOpen, setIsOpen] = useState<number | null>(null)
  return (
    <Fragment>
      {purchaseOrders.map((purchaseOrder) => {
        const {
          id,
          purchaseOrderNumber,
          requisition,
          totalValue,
          supplier,
          status,
          sentDate,
          expectedDeliveryDate,
          receivedStatus,
          invoicedStatus,
          department,
          account,
        } = purchaseOrder
        return (
          <Fragment key={id}>
            <div className="rounded-md border bg-white">
              <div className="flex justify-between p-4">
                <div>
                  <span className="break-all text-base font-bold">{requisition?.reference || purchaseOrderNumber}</span>
                  <p className="text-sm text-gray-500">{purchaseOrderNumber}</p>
                </div>
                <div>
                  <div className="mt-0 text-right">
                    <span className="text-base font-bold">{`${formatMoney(totalValue)}`}</span>
                    <p className="text-sm text-gray-500">
                      {t('general.total', 'Total')} ({t('general.includingTax', 'Including Tax')})
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <section className="space-y-3 p-4 text-sm" data-testid="purchase-order-details">
                <SimpleTileViewItem title={t('general.supplier', 'Supplier')} value={supplier?.name} />
                <SimpleTileViewItem
                  title={t('general.status', 'Status')}
                  value={
                    <span
                      className="cursor-pointer"
                      onClick={() => setIsOpen(id)}
                      data-testid="purchase-order-send-tile-view"
                    >
                      <PurchaseOrderStatus status={statusText(status, sentDate)} />
                    </span>
                  }
                />
                <SimpleTileViewItem
                  title={t('general.received', 'Received')}
                  value={receivedStatus && <PurchaseOrderReceivedStatus receivedStatus={receivedStatus} />}
                />
                <SimpleTileViewItem
                  title={t('general.invoiced', 'Invoiced')}
                  value={invoicedStatus && <PurchaseOrderInvoicedStatus purchaseOrder={purchaseOrder} />}
                />
                <SimpleTileViewItem
                  title={t('general.deliveryDate', 'Delivery Date')}
                  value={
                    <Fallback condition={!!expectedDeliveryDate}>
                      {formatDate(Dates.Short, expectedDeliveryDate)}
                    </Fallback>
                  }
                />
                <SimpleTileViewItem
                  title={t('general.requisition', 'Requisition')}
                  value={requisition?.requisitionNumber}
                />
                <SimpleTileViewItem
                  title={t('general.accountCode', 'Account Code')}
                  value={
                    !(department && account)
                      ? department?.name || account?.code
                      : `${department.name} - ${account.code}`
                  }
                />
              </section>
              <hr />
              <section className="flex items-center justify-between p-4">
                <PPNewDetailLink
                  type="purchase-order"
                  id={String(id)}
                  className="flex h-11 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
                >
                  {t('general.viewPurchaseOrder', 'View Purchase Order')}
                </PPNewDetailLink>

                <MoreOptionsMenu showPrint showOpenInPPlus showHelp showPurchaseOrderLink purchaseOrderId={id}>
                  <OptionIcon className="h-10 w-10 text-gray-500" />
                </MoreOptionsMenu>
              </section>
            </div>
            <PurchaseOrderModal
              showModal={isOpen === purchaseOrder.id}
              setShowModal={setIsOpen}
              purchaseOrder={purchaseOrder}
            />
          </Fragment>
        )
      })}
    </Fragment>
  )
}

export default memo(TileView)
