import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useGetRequisition } from '../../hooks'
import RequisitionPurchaseOrders from './requisition-purchase-orders'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ApprovalItem } from '@/modules/requisitions/components'
import { ConfirmationVariant } from '@/modules/requisitions/types'
import { PPNewDetailLink, QueryResult } from '@/modules/shared/components'
import { CheckIcon, ChevronLeftIcon, NewTabIcon } from '@/modules/shared/icons'

interface ConfirmationProps {
  variant: ConfirmationVariant
}

export default function Confirmation(props: ConfirmationProps) {
  const { variant } = props
  const { t } = useTranslation()

  const { loading, error, requisition } = useGetRequisition()

  /**
   * Takes the variant prop and returns the appropriate translation string.
   * `confirmation.requisition${variant}PageTitle`
   *
   * @returns String
   */
  const handleRequisitionPageTitle = (confirmationVariant: ConfirmationVariant, requisitionNumber?: string) => {
    switch (confirmationVariant) {
      case ConfirmationVariant.Approved:
        return t('confirmation.requisitionApprovedPageTitle', '{{ requisitionNumber }} Approved', {
          requisitionNumber: requisitionNumber,
        })
      case ConfirmationVariant.Declined:
        return t('confirmation.requisitionDeclinedPageTitle', '{{ requisitionNumber }} Declined', {
          requisitionNumber: requisitionNumber,
        })
      case ConfirmationVariant.AutoApproved:
        return t(
          'confirmation.requisitionAutoApprovedPageTitle',
          '{{ requisitionNumber }} Submitted and Fully Approved',
          {
            requisitionNumber: requisitionNumber,
          }
        )
      case ConfirmationVariant.PartiallyApproved:
        return t('confirmation.requisitionPartiallyApprovedPageTitle', '{{ requisitionNumber }} Partially Approved', {
          requisitionNumber: requisitionNumber,
        })
      default:
        return t('confirmation.requisitionAwaitingApprovalPageTitle', '{{ requisitionNumber }} Submitted', {
          requisitionNumber: requisitionNumber,
        })
    }
  }

  /**
   * Takes the variant prop and returns the appropriate translation string.
   * `confirmation.requisition${variant}Title`
   *
   * @returns String
   */
  const handleRequisitionTitle = (confirmationVariant: ConfirmationVariant, requisitionNumber?: string) => {
    switch (confirmationVariant) {
      case ConfirmationVariant.Approved:
        return t('general.finalApprovalGranted', 'Final Approval Granted')
      case ConfirmationVariant.Declined:
        return t('confirmation.requisitionDeclinedTitle', '{{ requisitionNumber }} Successfully Declined', {
          requisitionNumber: requisitionNumber,
        })
      case ConfirmationVariant.AutoApproved:
        return t('confirmation.requisitionAutoApprovedTitle', 'Submitted and Fully Approved')
      case ConfirmationVariant.PartiallyApproved:
        return t('general.partiallyApproved', 'Partially Approved')
      default:
        return t('general.successfullySubmitted', 'Successfully Submitted')
    }
  }

  /**
   * Takes the variant prop and returns the appropriate translation string.
   * `confirmation.requisition${variant}Body`
   *
   * @returns String
   */
  const handleRequisitionBody = (confirmationVariant: ConfirmationVariant, requisitionNumber?: string) => {
    switch (confirmationVariant) {
      case ConfirmationVariant.Approved:
        return t(
          'confirmation.requisitionApprovedBody',
          'You have successfully approved this Purchase Requisition and it is now fully approved. Purchase Orders have now been generated for each of the Suppliers included in the Requisition and these are listed below. You can send a Purchase Order if it is in a Not Sent state, you can visit the Approved Requisition, or use the buttons above to return to your Awaiting Approval list.'
        )
      case ConfirmationVariant.Declined:
        return t(
          'confirmation.requisitionDeclinedBody',
          'This Purchase Requisition has now been declined and the notification email has been sent to the requestor. The order can be modified and re-submitted, it can also be cancelled in order to prevent re-submission.'
        )
      case ConfirmationVariant.AutoApproved:
        return t(
          'confirmation.requisitionAutoApprovedBody',
          '{{ requisitionNumber }} has been successfully submitted and was automatically approved. Purchase Orders have now been generated for each of the Suppliers included in the Requisition and these are listed below. You can send a Purchase Order if it is in a Not Sent state, you can visit the Approved Requisition, or use the buttons above to return to your Awaiting Approval list.',
          { requisitionNumber: requisitionNumber }
        )
      case ConfirmationVariant.PartiallyApproved:
        return t(
          'confirmation.requisitionPartiallyApprovedBody',
          'You have successfully approved this Purchase Requisition however additional approvals are now required by other users at your organisation and these users have now been notified by email. You can view the details of this Requisition below, or use the buttons above to return to your Awaiting Approval list.'
        )
      default:
        return t(
          'confirmation.requisitionAwaitingApprovalBody',
          '{{ requisitionNumber }} is now successfully submitted. Approvals are now required and approving users have been notified by email. Purchase Orders will only be generated for Suppliers once all required approvals have been granted. You can view details of the Requisition below, or you can close and return home.',
          { requisitionNumber: requisitionNumber }
        )
    }
  }

  const showPO = variant === ConfirmationVariant.AutoApproved || variant === ConfirmationVariant.Approved
  const submitted = variant === ConfirmationVariant.AwaitingApproval || variant === ConfirmationVariant.AutoApproved

  return (
    <>
      <Helmet>
        <title>{handleRequisitionPageTitle(variant, requisition?.requisitionNumber)}</title>
      </Helmet>
      <header className="px-4 py-3">
        <Link
          data-testid="action-return"
          to={submitted ? '/' : '/requisitions/awaiting-my-approval'}
          className="flex h-11 w-fit items-center justify-between gap-x-2 rounded-md bg-gray-200 px-4 transition hover:brightness-95"
        >
          <ChevronLeftIcon className="-ml-2 h-5 w-5" />
          <p className="text-sm">
            {submitted ? t('general.returnHome', 'Return Home') : t('general.returnToList', 'Return to List')}
          </p>
        </Link>
      </header>
      <div className="mx-auto w-full px-4 py-9 lg:w-[43.625rem]" data-testid="confirmation-body">
        <div className="flex flex-col items-center justify-center gap-y-5">
          {variant !== ConfirmationVariant.Declined && <CheckIcon className="h-16 w-16 text-success" />}
          <h1 className="text-center text-2xl font-bold">
            {handleRequisitionTitle(variant, requisition?.requisitionNumber)}
          </h1>
          <p className="px-5 text-center text-sm text-gray-500">
            {handleRequisitionBody(variant, requisition?.requisitionNumber)}
          </p>
          {showPO && (
            <>
              <RequisitionPurchaseOrders requisition={requisition as Requisition} />
              <PPNewDetailLink
                openNewTab
                type="requisition"
                testId="view-requisition-link-new-tab"
                id={String(requisition?.id)}
                className="flex h-11 w-full items-center justify-center gap-x-2 rounded-md border bg-white p-4 text-center text-sm transition hover:brightness-95"
              >
                {t('general.viewRequisition', 'View Requisition')}
                <NewTabIcon className="h-5 w-5" />
              </PPNewDetailLink>
            </>
          )}
          <QueryResult loading={loading} error={error}>
            {requisition && !showPO && (
              <>
                <ApprovalItem className="w-full" requisition={requisition as Requisition} />
              </>
            )}
          </QueryResult>
        </div>
      </div>
    </>
  )
}
