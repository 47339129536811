import { graphql } from 'msw'

import { GetDepartmentsQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

export const GetDepartments = graphql.query<GetDepartmentsQuery>('GetDepartments', (_, res, ctx) => {
  return res(
    ctx.data({
      currentPurchaser: {
        id: 44926,
        departments: [
          {
            id: 44926,
            name: 'Test Account',
            legalEntity: true,
            defaultAccount: {
              name: 'Operating Account - 4',
            },
            departmentCode: 'testCenter1',
            parent: null,
            departments: [
              {
                id: 44926,
                name: 'Test Account',
                __typename: 'Purchaser',
              },
              {
                id: 100617,
                name: 'Accounts',
                __typename: 'Purchaser',
              },
            ],
            __typename: 'Purchaser',
          },
          {
            id: 100617,
            name: 'Accounts',
            legalEntity: false,
            defaultAccount: {
              name: 'Operating Account - 4',
            },
            departmentCode: 'testCenter1',
            parent: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Purchaser',
            },
            departments: [
              {
                id: 100617,
                name: 'Accounts',
                __typename: 'Purchaser',
              },
              {
                id: 100618,
                name: 'Accounts 2',
                __typename: 'Purchaser',
              },
            ],
            __typename: 'Purchaser',
          },
          {
            id: 100618,
            name: 'Accounts 2',
            legalEntity: false,
            defaultAccount: {
              name: 'Operating Account - 4',
            },
            departmentCode: 'testCenter1',
            parent: {
              id: 100617,
              name: 'Accounts',
              __typename: 'Purchaser',
            },
            departments: [],
            __typename: 'Purchaser',
          },
        ],
        __typename: 'Purchaser',
      },
    })
  )
})
