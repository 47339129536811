import { useQuery } from '@apollo/client'
import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { GetPurchaserDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus } from '@/modules/shared/utils'

export default function useGetPurchaser() {
  const { organisationId } = useParams<{ organisationId: string }>()
  const isFirstRender = useRef(true)

  const { data, networkStatus, error, refetch } = useQuery(GetPurchaserDocument, {
    variables: {
      purchaserId: Number(organisationId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  useEffect(() => {
    if (!isFirstRender.current) {
      // Refetch the data whenever the organisationId changes
      refetch()
    } else {
      isFirstRender.current = false
    }
  }, [organisationId, refetch])

  const loadingState = checkNetworkStatus(networkStatus)

  return {
    purchaser: data?.purchaser,
    error,
    loadingState,
    refetch,
  }
}
