export default function HeartIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <path d="M16.89,4.21a4.44,4.44,0,0,0-6.28,0L10,4.82l-.61-.61a4.45,4.45,0,0,0-6.28,0,4.38,4.38,0,0,0-1,1.44,4.42,4.42,0,0,0,0,3.4,4.49,4.49,0,0,0,1,1.44l6.4,6.4a.7.7,0,0,0,1,0l6.4-6.4a4.44,4.44,0,0,0,0-6.28Zm-3.14.08a3,3,0,0,1,2.16.89,3.05,3.05,0,0,1,0,4.33L10,15.43,4.09,9.51a3,3,0,0,1-.67-1,3.09,3.09,0,0,1,0-2.34,3.07,3.07,0,0,1,.66-1,3.19,3.19,0,0,1,1-.67,3.09,3.09,0,0,1,2.34,0,3.18,3.18,0,0,1,1,.66l1.1,1.1a.71.71,0,0,0,1,0l1.1-1.1A3,3,0,0,1,13.75,4.29Z" />
    </svg>
  )
}
