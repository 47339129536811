import { graphql } from 'msw'

import { GetCurrentOrganisationQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetCurrentOrganisation: GetCurrentOrganisationQuery = {
  currentOrganisationUnit: {
    id: 44926,
    name: 'Test Account',
    ppxAccess: true,
    legalEntity: true,
    createdAt: '2020-01-01T00:00:00.000Z',
    parent: null,
    featureFlags: {
      inventory: true,
      quickCreate: false,
      promoBanners: false,
      __typename: 'OrganisationFeatureFlags',
    },
    __typename: 'Purchaser',
    defaultAccount: {
      id: 96388,
      name: 'Operating Account - 4',
      accountName: '0060004 - Operating Account - 4',
      __typename: 'Account',
    },
  },
}

export const GetCurrentOrganisation = graphql.query<GetCurrentOrganisationQuery>(
  'GetCurrentOrganisation',
  (_, res, ctx) => {
    return res(ctx.data(baseGetCurrentOrganisation))
  }
)

export default baseGetCurrentOrganisation
