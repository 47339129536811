import { LegalEntity, PricedCatalogue } from '@/graphql/purchasing/generated/purchasing_graphql'

export type UpdatePriceFormInputs = {
  sellUnitPrice: number
  sellUnitTaxPercentage: number | null
}

export type UpdateProductFormInputs = {
  sellUnitPrice: number
  sellUnitTaxPercentage: number | null
  longDescription: string
}

export type CatalogFormInputs = {
  title: string
  originId: string
}

export enum CatalogueTypes {
  Product = 'Product',
  Priced = 'Priced',
}

export interface OwnerUnionPricedCatalogue extends PricedCatalogue {
  owner: LegalEntity & { name?: string; isASupplier?: boolean; isAPurchaser?: boolean }
}

export interface CreateCataloguedProductInputs {
  sellUnitPrice: number
  sellUnitTaxPercentage: number
}
