import { DeliveryAddress } from '@/graphql/purchasing/generated/purchasing_graphql'

export function formatAddress(deliveryAddress: DeliveryAddress) {
  let { line1, line2, city, stateProvince, postalCode, locationName } = deliveryAddress

  const arr = [locationName, line1, line2, city, stateProvince, postalCode] as string[]

  return arr
    .filter((e) => Boolean(e))
    .reduce((acc, curr) => `${acc} ${curr}`, '')
    .trim()
}
