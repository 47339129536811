import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PurchaseOrder } from '@/graphql/purchasing/generated/purchasing_graphql'
import PurchaseOrderModal from '@/modules/requisitions/pages/confirmation/requisition-purchase-orders/PurchaseOrderModal'
import { Button, Tooltip } from '@/modules/shared/components'
import { ArrowUpIcon } from '@/modules/shared/icons'

interface POActionProps {
  record: PurchaseOrder
}
export const POAction = ({ record }: POActionProps) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<number | null>(null)
  const { sentDate, id } = record
  const sent = !!sentDate
  return (
    <Tooltip
      content={
        sent
          ? t('purchaseOrders.allPurchaseOrders.table.tooltips.POSent', 'Purchase Order has been marked as Sent')
          : t(
              'purchaseOrders.allPurchaseOrders.table.tooltips.PONotSent',
              'Click to send the Purchase Order to the Supplier'
            )
      }
      showArrow={false}
      delay={100}
    >
      <span>
        <Button onClick={() => setIsOpen(id)} data-testid="purchase-order-send-2">
          <ArrowUpIcon
            className={classNames('h-9 w-9 cursor-pointer rounded-full p-1.5', {
              'bg-success text-white': sent,
              'bg-gray-200 text-gray-500': !sent,
            })}
          />
        </Button>
      </span>
      <PurchaseOrderModal showModal={!!isOpen} setShowModal={setIsOpen} purchaseOrder={record} />
    </Tooltip>
  )
}
