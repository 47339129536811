import { useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'

import TableView from './table-view'
import TileView from './tile-view'

import ProductEmptyStateImg from '@/assets/images/product-empty-state.svg'
import { Catalogue, GetAllBuyListsDocument, RansackDirection } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Spinner } from '@/modules/requisitions/components'
import { Breadcrumb, MoreOptionsMenu, QueryResult, TitleBar, Tooltip } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import SearchAndFilter, { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { BuyListIcon, HomeIcon, OptionIcon, PlusIcon, WizardIcon } from '@/modules/shared/icons'
import { QueryParameter } from '@/modules/shared/types'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export default function AllBuyLists() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()

  const { data, networkStatus, error, refetch, fetchMore } = useQuery(GetAllBuyListsDocument, {
    variables: {
      after: null,
      filter: {},
      sort: [
        {
          property: 'updatedAt',
          direction: RansackDirection.Desc,
        },
      ],
    },
    context: { uri: PURCHASING_GRAPHQL_API },
    notifyOnNetworkStatusChange: true,
  })

  const { setVariablesLoading, refetchLoading, loading } = checkNetworkStatus(networkStatus)

  const buyLists = extractEdges<Catalogue>(data?.currentPurchaser?.catalogues)

  const onFetchMoreBuyLists = () => {
    fetchMore({
      variables: {
        after: data?.currentPurchaser?.catalogues?.pageInfo.endCursor,
      },
    })
  }

  const onFilter = ({ searchValue }: OnFilter) => {
    refetch({
      filter: {
        q: [
          {
            property: 'title_cont',
            value: searchValue.trim(),
          },
        ],
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{t('general.vieAllbuyLists', 'View All Buy Lists')}</title>
      </Helmet>

      <main className="mx-auto space-y-5 px-4">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="#">{t('general.buyLists', 'Buy Lists')}</Breadcrumb.Item>
          <Breadcrumb.Item>{t('general.viewAll', 'View All')}</Breadcrumb.Item>
        </Breadcrumb>
        <TitleBar
          icon={<BuyListIcon className="h-10 w-10 " />}
          title={t('general.buyLists', 'Buy Lists')}
          subtitle={t('buyLists.viewAll', 'View All Buy Lists')}
        >
          <div className="flex items-center gap-x-2">
            <Tooltip content={t('buyLists.builder.tooltip', 'Open the Buy List Builder')}>
              <Link to="/buy-lists/builder">
                <WizardIcon className="h-11 w-11 cursor-pointer rounded-full bg-gray-200 p-2 text-gray-600" />
              </Link>
            </Tooltip>
            <Tooltip content={t('createBuyList.createTooltip', 'Create a New Buy List')}>
              <Link to="/buy-lists/create">
                <PlusIcon className="h-11 w-11 cursor-pointer rounded-full bg-gray-200 p-1 text-gray-600" />
              </Link>
            </Tooltip>
            <MoreOptionsMenu showHelp showOpenInPPlus allBuyLists>
              <OptionIcon className="h-11 w-11 rounded-full bg-gray-200 p-1.5 text-gray-600 outline-none hover:brightness-95" />
            </MoreOptionsMenu>
          </div>
        </TitleBar>
        <SearchAndFilter
          testId="buy-lists-search-input"
          placeholder={t('general.searchBuyLists', 'Search Buy Lists')}
          ariaLabel={t('general.searchBuyLists', 'Search Buy Lists')}
          onFilter={onFilter}
          queryParamFilterType={QueryParameter.Status}
        />
        <section>
          <QueryResult loading={loading || refetchLoading || setVariablesLoading} error={error}>
            {data?.currentPurchaser && (
              <>
                <InfiniteScroll
                  dataLength={buyLists.length}
                  next={onFetchMoreBuyLists}
                  hasMore={!!data?.currentPurchaser?.catalogues?.pageInfo.hasNextPage}
                  loader={<Spinner className="mt-5 h-14 md:w-16" />}
                >
                  {isLargeScreen ? <TableView buyLists={buyLists} /> : <TileView buyLists={buyLists} />}
                </InfiniteScroll>
                {!setVariablesLoading && buyLists.length === 0 && (
                  <EmptyState
                    img={ProductEmptyStateImg}
                    message={t('buyLists.noBuyLists', "We couldn't find any Buy Lists to display.")}
                  />
                )}
              </>
            )}
          </QueryResult>
        </section>
      </main>
    </>
  )
}
