import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback } from '@/modules/shared/components'

interface PRLinePurchaseCodeProps {
  requisitionLine: RequisitionLine
}

export default function PRLinePurchaseCode({ requisitionLine }: PRLinePurchaseCodeProps) {
  const { purchaserProductCode } = requisitionLine

  return <Fallback condition={!!purchaserProductCode}>{purchaserProductCode}</Fallback>
}
