import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import {
  useCreateAdvancedRequisitionLine,
  useDeleteAdvancedRequisitionLine,
  useUpdateAdvancedRequisitionLine,
} from '@/modules/requisitions/hooks'
import NumberInput from '@/modules/shared/components/number-input'

interface PRLineQuantityProps {
  requisitionLine: RequisitionLine
  isAddedToCart: (cataloguedProductId: number) => boolean
  availableQuotes?: boolean
}

export default function PRLineQuantity({ requisitionLine, isAddedToCart, availableQuotes }: PRLineQuantityProps) {
  const { quantity, cataloguedProductId } = requisitionLine

  const [newQty, setNewQty] = useState(Number(quantity))

  const { requisitionId } = useParams<{ requisitionId: string }>()

  const [updateAdvancedRequisitionLine, { loading: updateLoading }] = useUpdateAdvancedRequisitionLine()
  const [createAdvancedRequisitionLine, { loading: createLoading }] = useCreateAdvancedRequisitionLine()
  const [deleteAdvancedRequisitionLine, { loading: deleteLoading }] = useDeleteAdvancedRequisitionLine(
    Number(requisitionId),
    Number(cataloguedProductId)
  )

  useEffect(() => {
    if (quantity !== newQty) {
      setNewQty(Number(quantity))
    }
  }, [quantity])

  const onUpdateRequisitionLine = (line: RequisitionLine, newQuantity: number) => {
    const { id, productId, supplier, unitPrice, cataloguedProductId, taxPercentage } = line
    // TODO: refactor this update function

    if (id) {
      if (newQuantity === 0) {
        deleteAdvancedRequisitionLine({
          variables: {
            input: {
              id,
            },
          },
        })
      } else {
        updateAdvancedRequisitionLine({
          variables: {
            input: {
              id,
              quantity: newQuantity,
              cataloguedProductId: cataloguedProductId,
            },
          },
        })
      }
    } else {
      createAdvancedRequisitionLine({
        variables: {
          input: {
            cataloguedProductId: cataloguedProductId,
            productId: productId,
            supplierId: supplier?.id,
            unitPrice: unitPrice,
            requisitionId: Number(requisitionId),
            taxPercentage: taxPercentage,
            quantity: newQuantity,
          },
        },
      })
    }
  }

  const findClosestAncestorByTagName = (element: HTMLElement | null, tagName: string): HTMLElement | null => {
    while (element && element.tagName !== tagName) {
      element = element.parentElement
      // Break the loop if the element becomes null (when it reaches the top of the tree)
      if (!element) {
        break
      }
    }
    return element
  }

  /**
   * onKeyDown is a callback function that handles keyboard events for input elements inside a table.
   * If the user presses 'Enter' when they put a number in the quantity field, the input field on the next line in the
   * table is selected.
   */
  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback((e) => {
    // Check if the pressed key is 'Enter'.
    if (e.key === 'Enter') {
      // Find the closest ancestor element that is a table.
      const tableElement = findClosestAncestorByTagName(e.currentTarget, 'TABLE')

      // Get all input elements within the table that are not disabled.
      const inputs = Array.from(tableElement?.querySelectorAll('input') ?? []).filter(
        (e: HTMLInputElement) => !e.disabled
      )

      // Determine the index of the current input element within the array of inputs.
      const index = inputs.indexOf(e.currentTarget)

      // If the 'Shift' key is also pressed, focus on the previous input element. Otherwise, focus on the next input element.
      if (e.shiftKey) {
        inputs[index - 1]?.focus()
      } else {
        inputs[index + 1]?.focus()
      }

      // Prevent the default behavior of the 'Enter' key.
      e.preventDefault()
    }
  }, [])

  return (
    <NumberInput
      className={classNames('max-w-[3.75rem] rounded-md text-center', {
        'bg-gray-200': !isAddedToCart(Number(cataloguedProductId)),
      })}
      aria-label="quantity-input"
      data-testid="quantity-input"
      loading={updateLoading || createLoading || deleteLoading}
      value={newQty}
      minValue={0}
      maxValue={999}
      formatOptions={{
        maximumFractionDigits: 3,
      }}
      onKeyDown={onKeyDown}
      onChange={(value) => {
        setNewQty(value)
        onUpdateRequisitionLine(requisitionLine, value)
      }}
      isDisabled={!availableQuotes}
    />
  )
}
