import { graphql } from 'msw'

import { GetAdvancedPossibleRequisitionLinesQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetAdvancedPossibleRequisitionLines: GetAdvancedPossibleRequisitionLinesQuery = {
  currentPurchaser: {
    id: 44926,
    requisition: {
      id: 5235941,
      requisitionNumber: 'PR04777',
      reference: 'TEST001',
      totalValue: 0.1,
      totalTaxValue: 0,
      lines: [],
      commentsCount: 0,
      awaitingMyApproval: false,
      state: 'open',
      originName: '',
      expectedDeliveryDate: '',
      sendToSupplier: true,
      creator: null,
      approvalWorkflow: null,
      department: null,
      account: null,
      deliveryAddress: {
        id: 1961,
        locationName: '',
        line1: 'test line 1 ',
        line2: 'test line 2',
        city: 'Hornsby',
        stateProvince: 'NSW',
        country: 'AU',
        __typename: 'DeliveryAddress',
      },
      possibleRequisitionLines: {
        edges: [
          {
            node: {
              id: null,
              productId: 5075897,
              cataloguedProductId: 19860317,
              image: null,
              unitPrice: 0.01,
              taxPercentage: 0,
              unitTax: 0.001,
              quantity: null,
              total: 0.1,
              livePremium: 0,
              orderInstruction: null,
              supplierProductCode: '185139-EA',
              purchaserProductCode: null,
              preferredSupplier: false,
              availableQuotes: [
                {
                  unitPrice: 21.4,
                  taxPercentage: 0,
                  supplierName: 'In2Food (Operations) Pty Ltd - All States ',
                  supplierId: 4,
                  cataloguedProductId: 1,
                  preferredSupplier: false,
                  __typename: 'Price',
                },
                {
                  unitPrice: 0.3,
                  taxPercentage: 0,
                  supplierName: 'Simon George & Sons Pty Ltd - Cairns',
                  supplierId: 5426,
                  cataloguedProductId: 2,
                  preferredSupplier: false,
                  __typename: 'Price',
                },
              ],
              department: null,
              account: null,
              product: {
                id: 5075897,
                brand: 'Alchemy',
                itemDescription: 'Syrup Pump : To Suit 750ml & 1lt Bottles',
                concatenatedDescription: 'Syrup Pump : To Suit 750ml & 1lt Bottles 1 each',
                concatenatedSellUnit: 'each of 1',
                itemPackName: null,
                itemMeasure: 'each',
                itemSellPackName: 'each',
                itemSize: 1,
                category: {
                  id: 109491,
                  name: 'Serviceware',
                  parent: {
                    id: 109435,
                    name: 'Food Service Equipment and Supplies',
                    __typename: 'Category',
                  },
                  __typename: 'Category',
                },
              },
              supplier: {
                id: 734,
                name: 'Bidfood Australia Ltd - National',
                billingAddressLine1: '548-572 Clayton Road',
                billingAddressLine2: '',
                billingAddressStateProvince: 'Victoria',
                billingAddressCity: 'Clayton',
                billingAddressCountry: 'AU',
                billingAddressPostalCode: '3168',
                telephone: '+613 8551 0111',
                email: 'ecommerce@bidvest.com.au',
                deliveryChargeConfiguration: null,
                __typename: 'Supplier',
              },
              __typename: 'RequisitionLine',
            },
            __typename: 'PossibleRequisitionLinesEdge',
          },
          {
            node: {
              id: 26773944,
              productId: 25268,
              cataloguedProductId: 2482888,
              image: 'https://cdn.purchaseplus.com/images/products/72dfe81b-964b-4add-8d10-8f2283c04271.jpg',
              unitPrice: 0.5,
              taxPercentage: 0,
              unitTax: 0,
              quantity: 0,
              total: 2,
              livePremium: 0.1,
              orderInstruction: null,
              supplierProductCode: 'PUMPK',
              purchaserProductCode: null,
              preferredSupplier: false,
              availableQuotes: [
                {
                  unitPrice: 0.5,
                  taxPercentage: 0,
                  supplierName: 'Sydney Wholesale Co',
                  supplierId: 15065,
                  cataloguedProductId: 3,
                  preferredSupplier: false,
                  __typename: 'Price',
                },
                {
                  unitPrice: 0.4,
                  taxPercentage: 0,
                  supplierName: 'Local Supermarket',
                  supplierId: 15389,
                  cataloguedProductId: 4,
                  preferredSupplier: false,
                  __typename: 'Price',
                },
              ],
              __typename: 'RequisitionLine',
              department: null,
              account: null,
              product: {
                id: 25268,
                brand: null,
                itemDescription: 'Pumpkin Queensland Blue',
                concatenatedDescription: 'Pumpkin Queensland Blue 1 kg',
                concatenatedSellUnit: 'each of 1',
                itemPackName: null,
                itemMeasure: 'kg',
                itemSellPackName: 'each',
                itemSize: 1,
                __typename: 'Product',
                category: {
                  id: 109879,
                  name: 'Vegetables',
                  __typename: 'Category',
                  parent: {
                    id: 109460,
                    name: 'Fruit and Vegetables',
                    __typename: 'Category',
                  },
                },
              },
              supplier: {
                id: 15065,
                name: 'Sydney Wholesale Co',
                billingAddressLine1: '100 Miller Street',
                billingAddressLine2: '',
                billingAddressStateProvince: 'NSW',
                billingAddressCity: 'Sydney',
                billingAddressCountry: 'AU',
                billingAddressPostalCode: '2000',
                telephone: '02 8456 0500',
                email: 'testsupplier03@marketboomer.com',
                deliveryChargeConfiguration: {
                  minimumOrderValueExTax: 100,
                  deliveryChargeExTax: 30,
                  deliveryChargeIncTax: 33,
                  deliveryChargeTaxPercentage: 10,
                  __typename: 'DeliveryChargeConfiguration',
                },
                __typename: 'Supplier',
              },
            },
            __typename: 'PossibleRequisitionLinesEdge',
          },
        ],
        pageInfo: {
          startCursor: 'MQ',
          endCursor: 'MQ',
          hasNextPage: false,
          hasPreviousPage: false,
          __typename: 'PageInfo',
        },
        __typename: 'PossibleRequisitionLinesConnection',
      },
      __typename: 'Requisition',
    },
    __typename: 'Purchaser',
  },
}

export const GetAdvancedPossibleRequisitionLines = graphql.query<GetAdvancedPossibleRequisitionLinesQuery>(
  'GetAdvancedPossibleRequisitionLines',
  (_, res, ctx) => {
    return res(ctx.data(baseGetAdvancedPossibleRequisitionLines))
  }
)

export default baseGetAdvancedPossibleRequisitionLines
