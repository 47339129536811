import { useQuery } from '@apollo/client'

import { OwnerUnionPricedCatalogue } from '../types'

import {
  GetAllCatalogsDocument,
  PageInfo,
  RansackDirection,
  RansackMatcher,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export default function useGetAllCatalogs() {
  const paginationResultsPerPage = 20
  const { data, networkStatus, error, refetch, fetchMore } = useQuery(GetAllCatalogsDocument, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: paginationResultsPerPage,
      after: null,
      filter: {},
      sort: [
        {
          property: 'updatedAt',
          direction: RansackDirection.Desc,
        },
      ],
    },
    fetchPolicy: 'cache-and-network',
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const pageInfo = data?.currentPurchaser?.availableCatalogues?.pageInfo || ({} as PageInfo)

  const onFilter = ({ searchValue, filterValue }: OnFilter) => {
    let filter: RansackMatcher[] = []
    if (searchValue) {
      filter.push({
        property: 'title_or_supplier_name_cont',
        value: searchValue,
      })
    }

    if (filterValue) {
      filter.push({
        property: 'owner_isASupplier_eq',
        value: filterValue === 'all' ? '' : filterValue === 'yes' ? false : true,
      })
    }

    refetch({
      filter: {
        q: [...filter],
      },
      first: paginationResultsPerPage,
      after: null,
      last: null,
    })
  }
  const onPreviousPage = () => {
    const { startCursor } = pageInfo
    refetch({
      before: startCursor,
      after: null,
      last: paginationResultsPerPage,
      first: null,
    })
  }

  const onNextPage = () => {
    const { endCursor } = pageInfo
    refetch({
      after: endCursor,
      before: null,
      first: paginationResultsPerPage,
      last: null,
    })
  }

  const onFetchMoreCatalogs = () => {
    fetchMore({ variables: { after: pageInfo.endCursor } })
  }

  return {
    catalogs: extractEdges<OwnerUnionPricedCatalogue>(data?.currentPurchaser?.availableCatalogues),
    loadingStates: checkNetworkStatus(networkStatus),
    error,
    paginationResultsPerPage,
    pageInfo,
    onFilter,
    onPreviousPage,
    onNextPage,
    onFetchMoreCatalogs,
  }
}
