import { useTranslation } from 'react-i18next'

import SideNavLink from '../SideNavLink'
import { SideBarItem } from './PurchaserNavigation'

import { Accordion } from '@/modules/shared/components'
import { TruckIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface SuppliersSideBarItemProps extends SideBarItem {
  supplierRoutesMatch: boolean
}

export const SuppliersSideBarItem = ({
  supplierRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
}: SuppliersSideBarItemProps) => {
  const { t } = useTranslation()
  return (
    <Accordion
      titlePrefixIcon={<TruckIcon className="h-7 w-7" />}
      title={t('general.suppliers', 'Suppliers')}
      activeColor={supplierRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === 5}
      handleTriggerClick={() => changeOpenAccordionKey(5)}
      buttonClassName="h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="suppliers-navigation"
        sideNavLinkItems={[
          {
            key: t('general.mySuppliers', 'My Suppliers'),
            target: '/my-suppliers',
          },
          {
            key: t('general.supplierDirectory', 'Supplier Directory'),
            target: '/supplier-directory',
          },
        ]}
      />
    </Accordion>
  )
}
