import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Price, Product, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import SupplierQuotesModal from '@/modules/shared/components/supplier-quotes-modal/SupplierQuotesModal'
import { useMoney } from '@/modules/shared/hooks'

export interface PRLineBestBuyProps {
  livePremium: number | null | undefined
  cataloguedProductId: number | null | undefined
  isAddedToCart: (cataloguedProductId: number) => boolean
  product?: Product | null
  prices?: Price[] | null
  supplier?: Supplier | null
  lineId?: number | null
  image?: string | null
  refetch?: () => void
  quantity?: number | null
}

export default function BestBuyButton({
  isAddedToCart,
  product,
  prices,
  supplier,
  lineId,
  image,
  refetch,
  livePremium,
  cataloguedProductId,
  quantity,
}: PRLineBestBuyProps) {
  const { formatMoney } = useMoney()
  const { t } = useTranslation()
  const [showSupplierQuotesModal, setShowSupplierQuotesModal] = useState<boolean>(false)

  return (
    <>
      <div
        className={classNames('mx-auto flex h-9 items-center justify-center rounded-md p-2 ', {
          'w-9': !isAddedToCart(Number(cataloguedProductId)),
          'cursor-pointer hover:brightness-95': product && prices,
        })}
        data-testid="best-buy-button"
        onClick={() => product && prices && setShowSupplierQuotesModal(true)}
      >
        {isAddedToCart(Number(cataloguedProductId)) && livePremium !== 0 && (
          <div className="mx-2 flex min-w-[5.1rem] max-w-[5.1rem] justify-center rounded-md bg-error px-0 py-2 text-white">
            {formatMoney(livePremium)}
          </div>
        )}
        {isAddedToCart(Number(cataloguedProductId)) && livePremium === 0 && (
          <div className="mx-2 flex min-w-[5.1rem] max-w-[5.1rem] justify-center rounded-md bg-success px-0 py-2 text-white">
            {t('general.bestBuy', 'Best Buy')}
          </div>
        )}
        {!isAddedToCart(Number(cataloguedProductId)) && '--'}
      </div>
      {product && prices && (
        <SupplierQuotesModal
          showModal={showSupplierQuotesModal}
          setShowModal={setShowSupplierQuotesModal}
          product={product}
          prices={prices}
          currentSupplierId={supplier?.id}
          lineId={lineId || undefined}
          image={image}
          refetch={refetch}
          cataloguedProductId={cataloguedProductId}
          originalQuantity={quantity}
        />
      )}
    </>
  )
}
