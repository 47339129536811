import { graphql } from 'msw'

import { GetStocktakeQuery } from '@/graphql/inventory/generated/inventory_graphql'

const baseGetStocktake: GetStocktakeQuery = {
  legalHolder: {
    id: 12,
    stockLocation: {
      id: 2162,
      name: 'Cafe101',
      stockTake: {
        periodEndingOn: '2023-04-30',
        id: 53692,
        closedAt: null,
        blind: false,
        closable: true,
        stockTakeStatus: 'Count In Progress',
        stockCounts: {
          edges: [
            {
              node: {
                expectedQuantity: 120,
                id: 7314867,
                openingQuantity: 120,
                quantity: null,
                productId: 23941,
                variance: null,
                product: {
                  brand: '',
                  id: 23941,
                  itemDescription: 'Apple Granny Smith : Peeled & Cored',
                  itemMeasure: 'kg',
                  itemSellQuantity: 1,
                  itemPackName: 'box',
                  itemSize: 1,
                  itemId: 14814,
                  concatenatedSellUnit: 'each of 1',
                  itemSellPackName: 'each',
                  __typename: 'Product',
                },
                __typename: 'StockCount',
              },
              __typename: 'StockCountEdge',
            },
            {
              node: {
                expectedQuantity: 11,
                id: 7314883,
                openingQuantity: 11,
                quantity: 10,
                productId: 24882,
                variance: -1,
                product: {
                  brand: '',
                  id: 24882,
                  itemDescription: 'Onion Brown : Medium',
                  itemMeasure: 'kg',
                  itemSellQuantity: 1,
                  itemPackName: 'box',
                  itemSize: 1,
                  itemId: 434069,
                  concatenatedSellUnit: 'each of 1',
                  itemSellPackName: 'each',
                  __typename: 'Product',
                },
                __typename: 'StockCount',
              },
              __typename: 'StockCountEdge',
            },
            {
              node: {
                expectedQuantity: 205,
                id: 7314904,
                openingQuantity: 205,
                quantity: 205,
                productId: 11,
                variance: null,
                product: {
                  brand: '',
                  id: 11,
                  itemDescription: 'Sage',
                  itemMeasure: 'bunch',
                  itemSellQuantity: 1,
                  itemPackName: 'box',
                  itemSize: 1,
                  itemId: 539329,
                  concatenatedSellUnit: 'each of 1',
                  itemSellPackName: 'each',
                  __typename: 'Product',
                },
                __typename: 'StockCount',
              },
              __typename: 'StockCountEdge',
            },
          ],
          pageInfo: {
            endCursor: 'MjA',
            hasNextPage: false,
            hasPreviousPage: false,
            startCursor: 'MQ',
            __typename: 'PageInfo',
          },
          __typename: 'StockCountConnection',
        },
        __typename: 'StockTake',
      },
      __typename: 'StockLocation',
    },
    __typename: 'Holder',
  },
}

export const GetStocktake = graphql.query<GetStocktakeQuery>('GetStocktake', (_, res, ctx) => {
  return res(ctx.data(baseGetStocktake))
})

export default baseGetStocktake
