import { useQuery } from '@apollo/client'

import { GetBuyListDetailsDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export const useGetBuyListDetails = (buyListId: string) => {
  const { data, loading, error } = useQuery(GetBuyListDetailsDocument, {
    variables: {
      catalogueId: Number(buyListId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const buyList = data?.currentPurchaser?.catalogue

  const { title, owner, createdAt, user, updatedAt, chargingDefinitions } = buyList || {}
  const account = chargingDefinitions?.edges![0]?.node?.account
  const department = chargingDefinitions?.edges![0]?.node?.department

  return { title, owner, createdAt, user, updatedAt, account, department, loading, error }
}
