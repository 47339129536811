import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddAccountCodeModal from '../AddAccountCodeModal'
import RemoveProductModal from '../RemoveProductModal'

import NoImage from '@/assets/images/no-image.svg'
import { Account, CataloguedProduct, Price, Purchaser } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, Fallback, MoreOptionsMenu } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { ProductImage, ProductItem, SupplierQuotesButton } from '@/modules/shared/components/table/table-column-items'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon, PlusIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

interface BuyListProductsTableViewProps {
  cataloguedProducts: CataloguedProduct[]
}

export default function BuyListProductsTableView({ cataloguedProducts }: BuyListProductsTableViewProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [activeCataloguedProduct, setActiveCatalogueId] = useState<number | null>(null)
  const [showRemoveProductModal, setShowRemoveProductModal] = useState<number | null>(null)

  const renderCategory = ({ product }: CataloguedProduct) => (
    <>
      <p>{product?.category?.parent?.name}</p>
      <p className="text-xs">{product?.category?.name}</p>
    </>
  )

  const renderAccountCode = ({ department, account, id }: CataloguedProduct) => (
    <>
      {!!department || !!account ? (
        <>
          <p>{department?.name}</p>
          <p className="text-xs">{account?.accountName}</p>
        </>
      ) : (
        <Button
          data-testid="add-dept-account-btn"
          className="flex items-center space-x-1 rounded-md bg-gray-200 px-3 py-1"
          onClick={() => setActiveCatalogueId(id)}
        >
          <p className="text-xs">{t('general.addAccountCode', 'Add Account Code')}</p>
          <PlusIcon className="h-4 w-4" />
        </Button>
      )}
      <AddAccountCodeModal
        showModal={activeCataloguedProduct === id}
        setShowModal={setActiveCatalogueId}
        cataloguedProductId={id}
        department={department as Purchaser}
        account={account as Account}
      />
      <RemoveProductModal
        showModal={showRemoveProductModal === id}
        setShowModal={setShowRemoveProductModal}
        cataloguedProductId={id}
      />
    </>
  )

  const renderBestPrice = ({ product }: CataloguedProduct) => {
    const hasPrices = !!product?.prices?.edges?.length
    return (
      <>
        <Fallback as="p" condition={hasPrices} className="text-base font-bold">
          {formatMoney(product?.prices?.edges![0]?.node?.unitPrice)}
        </Fallback>
        <Fallback as="p" condition={hasPrices} className="text-xs text-gray-500">
          {t('general.plusTax', 'Plus {{ percent }}% Tax', {
            percent: product?.prices?.edges![0]?.node?.taxPercentage,
          })}
        </Fallback>
      </>
    )
  }

  const renderMoreOptions = ({ id, department, account }: CataloguedProduct) => (
    <MoreOptionsMenu
      showHelp
      showAccountConfig={{
        onClick: () => setActiveCatalogueId(id),
        hasAccount: !!department && !!account,
      }}
      showRemoveFromBuyList={() => setShowRemoveProductModal(id)}
    >
      <OptionIcon className="h-10 w-10 text-gray-500" />
    </MoreOptionsMenu>
  )

  return (
    <>
      <Table
        dataSource={cataloguedProducts}
        keyExtractor={(record) => String(record.id)}
        dataTestId="buy-list-product-table"
        columns={[
          {
            title: t('general.image', 'Image'),
            key: 'image',
            headerCellStyles: 'px-4 py-3 text-center',
            cellStyles: 'px-4 py-3 text-center',
            minWidth: 5.625,
            maxWidth: 5.625,
            render: () => <ProductImage image={NoImage} />,
          },
          {
            title: t('general.item', 'Item'),
            key: 'item',
            headerCellStyles: 'pl-0 pr-4 py-3',
            cellStyles: 'pl-0 pr-4 py-3',
            minWidth: 15,
            maxWidth: 15,
            render: ({ product }: CataloguedProduct) => product && <ProductItem product={product} />,
          },
          {
            title: t('general.category', 'Category'),
            key: 'category',
            headerCellStyles: 'pl-0 pr-4 py-3',
            cellStyles: 'pl-0 pr-4 py-3',
            minWidth: 15,
            render: renderCategory,
          },
          {
            title: t('general.accountCode', 'Account Code'),
            key: 'accountCode',
            headerCellStyles: 'py-3',
            cellStyles: 'py-3',
            minWidth: 15,
            grow: true,
            render: renderAccountCode,
          },
          {
            title: t('general.bestPrice', 'Best Price'),
            key: 'bestPrice',
            headerCellStyles: 'pl-0 pr-4 py-3 text-right',
            cellStyles: 'pl-0 pr-4 py-3 text-right',
            minWidth: 7.5,
            maxWidth: 8.75,
            render: renderBestPrice,
          },
          {
            title: t('general.quotes', 'Quotes'),
            key: 'quotes',
            headerCellStyles: 'py-3 text-center',
            cellStyles: 'text-center py-3',
            minWidth: 4.375,
            maxWidth: 4.375,
            render: (cataloguedProduct) => {
              // SupplierQuotesColumn requires a Product and Price[] not CataloguedProduct
              const { product } = cataloguedProduct || {}
              const prices = extractEdges<Price>(product?.prices)
              return <SupplierQuotesButton product={product} prices={prices} />
            },
          },
          {
            title: t('general.more', 'More'),
            key: 'more',
            headerCellStyles: ' px-4 py-3 text-center',
            cellStyles: 'px-4 py-3 text-center',
            minWidth: 4.375,
            maxWidth: 4.375,
            render: renderMoreOptions,
          },
        ]}
      />
    </>
  )
}
