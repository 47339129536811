import { ReactNode, createContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type AlertOptions = {
  title?: string
  message?: string
  buttonText?: string
  errorCode?: number
  type?: 'success' | 'error'
  onButtonClick?: Function
}

interface AlertContextInterface {
  title?: string
  message?: string
  buttonText?: string
  errorCode?: number
  show: boolean
  alertType: AlertOptions['type']
  onButtonClick?: Function
  alert: (options: AlertOptions) => void
  clear: () => void
}

const AlertContext = createContext<AlertContextInterface | null>(null)
AlertContext.displayName = 'AlertContext'

function AlertProvider(props: { children: ReactNode }) {
  const { children } = props
  const { t } = useTranslation()
  const [title, setTitle] = useState<string | undefined>('')
  const [message, setMessage] = useState<string | undefined>('')
  const [buttonText, setButtonText] = useState<string | undefined>('')
  const [errorCode, setErrorCode] = useState<number | undefined>()
  const [onClick, setOnClick] = useState<Function | undefined>()
  const [alertType, setAlertType] = useState<AlertOptions['type']>('success')
  const [showAlert, setShowAlert] = useState(false)

  return (
    <AlertContext.Provider
      value={{
        title,
        message,
        buttonText,
        errorCode,
        onButtonClick: onClick,
        show: showAlert,
        alertType,
        alert(options) {
          const {
            title = t('general.somethingWentWrong', 'Something Went Wrong'),
            message = t(
              'alert.error.generalErrorMessage',
              'An error has occurred. Please try again soon or contact our friendly support team for assistance'
            ),
            buttonText = t('general.close', 'Close'),
            type = 'success',
            onButtonClick,
            errorCode,
          } = options
          setOnClick(undefined)
          if (onButtonClick) {
            setOnClick(() => () => onButtonClick())
          }
          setTitle(title)
          setMessage(message)
          setButtonText(buttonText)
          setErrorCode(errorCode)
          setAlertType(type)
          setShowAlert(true)
        },
        clear() {
          setShowAlert(false)
        },
      }}
    >
      {children}
    </AlertContext.Provider>
  )
}

export { AlertProvider }

export default AlertContext
