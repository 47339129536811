import { useRef } from 'react'
import { AriaCheckboxProps, useCheckbox } from 'react-aria'
import { useToggleState } from 'react-stately'

import { CheckboxIcon } from '@/modules/shared/icons-special'

function Checkbox(props: AriaCheckboxProps & { dataTestId?: string; disabled?: boolean; reverse?: boolean }) {
  const state = useToggleState(props)
  const ref = useRef(null)
  const { inputProps } = useCheckbox(props, state, ref)

  return (
    <label
      className="flex cursor-pointer items-center gap-2 text-sm"
      onClick={(e) => props.disabled && e.preventDefault()}
    >
      {/* put the label in front of the checkbox if reversed */}
      {props.reverse && props.children}
      <input className="hidden" {...inputProps} ref={ref} data-testid={props.dataTestId} />
      <CheckboxIcon isSelected={state.isSelected} isIndeterminate={props.isIndeterminate} disabled={props.disabled} />
      {!props.reverse && props.children}
    </label>
  )
}

export default Checkbox
