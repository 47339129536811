import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { useGetAllCatalogs } from '../../hooks'
import AllCatalogsTableView from './table-view/AllCatalogsTableView'
import AllCatalogsTileView from './tile-view/AllCatalogsTileView'

import CatalogsEmptyState from '@/assets/images/empty-catalogs.svg'
import { Spinner } from '@/modules/requisitions/components'
import { Breadcrumb, FilterTabs, MoreOptionsMenu, QueryResult, TitleBar, Tooltip } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import SearchAndFilter from '@/modules/shared/components/search-input/SearchAndFilter'
import { useWindowSize } from '@/modules/shared/hooks'
import { CatalogIcon, HomeIcon, OptionIcon, PlusIcon } from '@/modules/shared/icons'
import { FilterTabItem, QueryParameter } from '@/modules/shared/types'

export default function AllCatalogs() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()
  const [filterURLParam] = useQueryParam(QueryParameter.SelfManaged, withDefault(StringParam, 'all'))
  const {
    catalogs,
    loadingStates,
    pageInfo,
    error,
    paginationResultsPerPage,
    onFetchMoreCatalogs,
    onFilter,
    onNextPage,
    onPreviousPage,
  } = useGetAllCatalogs()

  const filterTabs: FilterTabItem[] = [
    {
      key: t('general.viewAll', 'View All'),
      // Not an actual state
      filterValue: 'all',
    },
    {
      key: t('general.supplierManaged', 'Supplier Managed'),
      // Not an actual state
      filterValue: 'no',
    },
    {
      // Not an actual state
      key: t('general.selfManaged', 'Self Managed'),
      filterValue: 'yes',
    },
  ]

  return (
    <>
      <Helmet>
        <title>{t('general.viewAllCatalogs', 'View All Catalogs')}</title>
      </Helmet>
      <main className="mx-auto space-y-5 px-4">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="#">{t('general.catalogs', 'Catalogs')}</Breadcrumb.Item>
          <Breadcrumb.Item>{filterTabs.find((e) => e.filterValue === filterURLParam)?.key}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="space-y-2">
          <TitleBar
            icon={<CatalogIcon className="h-10 w-10 " />}
            title={t('general.catalogs', 'Catalogs')}
            subtitle={t('general.viewAllCatalogs', 'View All Catalogs')}
          >
            <div className="flex items-center gap-x-2">
              <Tooltip content={t('createCatalog.createTooltip', 'Create a Catalog')}>
                <Link to="/catalogs/create">
                  <PlusIcon className="h-11 w-11 cursor-pointer rounded-full bg-gray-200 p-1 text-gray-600" />
                </Link>
              </Tooltip>
              <MoreOptionsMenu showHelp showOpenInPPlus allSelfManagedCatalogs>
                <OptionIcon className="h-11 w-11 rounded-full bg-gray-200 p-1.5 text-gray-600 outline-none hover:brightness-95" />
              </MoreOptionsMenu>
            </div>
          </TitleBar>
          <FilterTabs tabs={filterTabs} filterBy={QueryParameter.SelfManaged} />
        </section>
        <SearchAndFilter
          testId="catalogs-search-input"
          placeholder={t('catalogs.searchCatalogsPlaceholder', 'Search by Supplier or Catalog Name')}
          ariaLabel={t('catalogs.searchCatalogsPlaceholder', 'Search by Supplier or Catalog Name')}
          onFilter={onFilter}
          queryParamFilterType={QueryParameter.SelfManaged}
        />
        <QueryResult loading={loadingStates.loading || loadingStates.refetchLoading} error={error}>
          {isLargeScreen ? (
            <AllCatalogsTableView
              catalogs={catalogs.slice(0, paginationResultsPerPage)}
              pageInfo={pageInfo}
              paginationLoading={loadingStates.setVariablesLoading}
              onNextPage={onNextPage}
              onPreviousPage={onPreviousPage}
            />
          ) : (
            <>
              {loadingStates.setVariablesLoading ? (
                <Spinner className="mt-12 h-28 md:h-32" />
              ) : (
                <InfiniteScroll
                  dataLength={catalogs.length}
                  next={onFetchMoreCatalogs}
                  hasMore={pageInfo.hasNextPage}
                  loader={<Spinner className="mt-12 h-28 md:h-32" />}
                >
                  <AllCatalogsTileView catalogs={catalogs} />
                </InfiniteScroll>
              )}
            </>
          )}
          {!loadingStates.setVariablesLoading && catalogs.length === 0 && (
            <EmptyState
              img={CatalogsEmptyState}
              message={t('catalogs.emptyState', 'There are no Catalogs to display.')}
            />
          )}
        </QueryResult>
      </main>
    </>
  )
}
