import { graphql } from 'msw'

import { GetPricedCatalogueQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetPricedCatalogueObject: GetPricedCatalogueQuery = {
  currentPurchaser: {
    id: 44926,
    pricedCatalogue: {
      id: 182605,
      title: 'Self Managed - Bev1',
      createdAt: '2022-09-01T14:24:03+10:00',
      updatedAt: '2022-09-01T14:25:05+10:00',
      pricedCataloguedProducts: {
        edges: [
          {
            node: {
              id: 22673148,
              product: {
                id: 5848728,
                category: {
                  id: 109607,
                  name: 'Pinot Noir',
                  parent: {
                    id: 109444,
                    name: 'Wine Red',
                    __typename: 'Category',
                  },
                  __typename: 'Category',
                },
                brand: null,
                itemDescription: 'Pinot Noir',
                concatenatedDescription: 'Pinot Noir 750 ml',
                concatenatedSellUnit: 'carton of 6',
                itemPackName: null,
                itemMeasure: 'ml',
                itemSellPackName: 'carton',
                itemSize: 750,

                pricings: {
                  edges: [
                    {
                      node: {
                        id: 22673148,
                        sellUnitPrice: 100,
                        updatedAt: '2022-05-10T10:07:27+10:00',
                        sellUnitTaxPercentage: 10,
                        __typename: 'CataloguedProduct',
                      },
                      __typename: 'CataloguedProductEdge',
                    },
                    {
                      node: {
                        id: 22673355,
                        sellUnitPrice: null,
                        updatedAt: '2022-08-15T13:05:37+10:00',
                        sellUnitTaxPercentage: null,
                        __typename: 'CataloguedProduct',
                      },
                      __typename: 'CataloguedProductEdge',
                    },
                    {
                      node: {
                        id: 22673358,
                        sellUnitPrice: null,
                        updatedAt: '2022-08-15T13:42:16+10:00',
                        sellUnitTaxPercentage: null,
                        __typename: 'CataloguedProduct',
                      },
                      __typename: 'CataloguedProductEdge',
                    },
                  ],
                  __typename: 'CataloguedProductConnection',
                },
                __typename: 'Product',
              },
              image: null,
              sellUnitPrice: 100,
              sellUnitTaxPercentage: 10,
              longDescription: null,
              __typename: 'PricedCataloguedProduct',
            },
            __typename: 'PricedCataloguedProductEdge',
          },
          {
            node: {
              id: 22673146,
              product: {
                id: 894518,
                category: {
                  id: 109607,
                  name: 'Pinot Noir',
                  parent: {
                    id: 109444,
                    name: 'Wine Red',
                    __typename: 'Category',
                  },
                  __typename: 'Category',
                },
                brand: 'Penfolds - Bin 23',
                itemDescription: 'Pinot Noir : [Australian] {2009}',
                concatenatedDescription: 'Pinot Noir : [Australian] {2009} 750 ml bottle',
                concatenatedSellUnit: 'each of 1',
                itemPackName: 'bottle',
                itemMeasure: 'ml',
                itemSellPackName: 'each',
                itemSize: 750,
                pricings: {
                  edges: [
                    {
                      node: {
                        id: 1728020,
                        sellUnitPrice: null,
                        updatedAt: '2013-09-26T04:28:30+10:00',
                        sellUnitTaxPercentage: null,
                        __typename: 'CataloguedProduct',
                      },
                      __typename: 'CataloguedProductEdge',
                    },
                    {
                      node: {
                        id: 22673146,
                        sellUnitPrice: 150,
                        updatedAt: '2022-05-10T10:07:30+10:00',
                        sellUnitTaxPercentage: 10,
                        __typename: 'CataloguedProduct',
                      },
                      __typename: 'CataloguedProductEdge',
                    },
                  ],
                  __typename: 'CataloguedProductConnection',
                },
                __typename: 'Product',
              },
              image: null,
              sellUnitPrice: 150,
              sellUnitTaxPercentage: 10,
              longDescription: null,
              __typename: 'PricedCataloguedProduct',
            },
            __typename: 'PricedCataloguedProductEdge',
          },
          {
            node: {
              id: 22673147,
              product: {
                id: 898415,
                category: {
                  id: 109607,
                  name: 'Pinot Noir',
                  parent: {
                    id: 109444,
                    name: 'Wine Red',
                    __typename: 'Category',
                  },
                  __typename: 'Category',
                },
                brand: 'Penfolds - Bin 23',
                itemDescription: 'Pinot Noir : [Australian] {2009}',
                concatenatedDescription: 'Pinot Noir : [Australian] {2009} 750 ml bottle',
                concatenatedSellUnit: 'carton of 6',
                itemPackName: 'bottle',
                itemMeasure: 'ml',
                itemSellPackName: 'carton',
                itemSize: 750,
                pricings: {
                  edges: [
                    {
                      node: {
                        id: 2638230,
                        sellUnitPrice: null,
                        updatedAt: '2013-09-26T12:09:14+10:00',
                        sellUnitTaxPercentage: null,
                        __typename: 'CataloguedProduct',
                      },
                      __typename: 'CataloguedProductEdge',
                    },
                    {
                      node: {
                        id: 22673147,
                        sellUnitPrice: 200,
                        updatedAt: '2022-05-10T10:07:34+10:00',
                        sellUnitTaxPercentage: 10,
                        __typename: 'CataloguedProduct',
                      },
                      __typename: 'CataloguedProductEdge',
                    },
                  ],
                  __typename: 'CataloguedProductConnection',
                },
                __typename: 'Product',
              },
              image: null,
              sellUnitPrice: 200,
              sellUnitTaxPercentage: 10,
              longDescription: null,
              __typename: 'PricedCataloguedProduct',
            },
            __typename: 'PricedCataloguedProductEdge',
          },
          {
            node: {
              id: 22673145,
              product: {
                id: 2955072,
                category: {
                  id: 109604,
                  name: 'Shiraz',
                  parent: {
                    id: 109444,
                    name: 'Wine Red',
                    __typename: 'Category',
                  },
                  __typename: 'Category',
                },
                brand: 'Penfolds - Bin 128',
                itemDescription: 'Shiraz : [Australian Coonawara] {2014}',
                concatenatedDescription: 'Shiraz : [Australian Coonawara] {2014} 750 ml bottle',
                concatenatedSellUnit: 'carton of 6',
                itemPackName: 'bottle',
                itemMeasure: 'ml',
                itemSellPackName: 'carton',
                itemSize: 750,
                pricings: {
                  edges: [
                    {
                      node: {
                        id: 21495715,
                        sellUnitPrice: null,
                        updatedAt: '2021-08-18T13:06:00+10:00',
                        sellUnitTaxPercentage: null,
                        __typename: 'CataloguedProduct',
                      },
                      __typename: 'CataloguedProductEdge',
                    },
                    {
                      node: {
                        id: 7850166,
                        sellUnitPrice: null,
                        updatedAt: '2018-03-05T16:43:25+11:00',
                        sellUnitTaxPercentage: null,
                        __typename: 'CataloguedProduct',
                      },
                      __typename: 'CataloguedProductEdge',
                    },
                  ],
                  __typename: 'CataloguedProductConnection',
                },
                __typename: 'Product',
              },
              image: null,
              sellUnitPrice: 350,
              sellUnitTaxPercentage: 10,
              longDescription: null,
              __typename: 'PricedCataloguedProduct',
            },
            __typename: 'PricedCataloguedProductEdge',
          },
        ],
        pageInfo: {
          endCursor: 'NA',
          hasNextPage: false,
          __typename: 'PageInfo',
        },
        __typename: 'PricedCataloguedProductConnection',
      },
      supplier: {
        id: 592,
        name: 'Clarke Murphy Print (formerly Paper Print Designs)',
        billingAddressLine1: '14/171 Lower Gibbes St, ',
        billingAddressLine2: '',
        billingAddressStateProvince: 'NSW',
        billingAddressCity: 'Chatswood',
        billingAddressCountry: 'AU',
        billingAddressPostalCode: '2067',
        telephone: '9417 8100',
        deliveryChargeConfiguration: null,
        email: 'Chris@cmprint.com.au',
        __typename: 'Supplier',
      },
      __typename: 'PricedCatalogue',
    },
    __typename: 'Purchaser',
  },
}

export const GetPricedCatalogue = graphql.query<GetPricedCatalogueQuery>('GetPricedCatalogue', (_, res, ctx) => {
  return res(ctx.data(baseGetPricedCatalogueObject))
})

export default baseGetPricedCatalogueObject
