import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { StocktakeCountState } from '@/modules/shared/types'

export interface StockTakeCountStatusProps {
  state: StocktakeCountState | null | undefined
}

export default function StockTakeCountStatus({ state }: StockTakeCountStatusProps) {
  const { t } = useTranslation()
  const translationStateText = (state: StocktakeCountState | null | undefined) => {
    switch (state) {
      case StocktakeCountState.PartCounted:
        return t('general.partCounted', 'Partly Counted')
      case StocktakeCountState.CountComplete:
        return t('general.fullyCounted', 'Fully Counted')
      case StocktakeCountState.NotCounted:
        return t('general.notCounted', 'Not Counted')
      default:
        return t('general.unknown', 'Unknown')
    }
  }

  return (
    <span
      data-testid="stocktake-count-status"
      className={classNames(`rounded-full px-3 py-1.5 text-xs`, {
        'bg-success text-white': state === StocktakeCountState.CountComplete,
        'bg-[#EEB020] text-white': state === StocktakeCountState.PartCounted,
        'bg-gray-200': state === StocktakeCountState.NotCounted,
      })}
    >
      {translationStateText(state)}
    </span>
  )
}
