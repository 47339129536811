import { useRef } from 'react'
import { AriaButtonProps, useButton } from 'react-aria'

import { ThemeWrapper } from '@/modules/shared/components'
import { CloseIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface ChipProps {
  label: string
  onRemoveChip: () => void
  theme?: Themes
}

function Chip(props: AriaButtonProps & ChipProps) {
  let ref = useRef(null)
  let { buttonProps } = useButton(props, ref)

  return (
    <ThemeWrapper
      className="flex w-fit items-center gap-x-2 rounded-md px-3 py-2"
      theme={props.theme || Themes.Primary}
    >
      <span>{props.label}</span>
      <button
        {...buttonProps}
        ref={ref}
        onClick={props.onRemoveChip}
        className="rounded-sm border-none focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-1"
        data-testid="remove-chip"
      >
        <CloseIcon className="h-5 w-5" />
      </button>
    </ThemeWrapper>
  )
}

export default Chip
