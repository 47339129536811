import { useLazyQuery } from '@apollo/client'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useCheckShoppingAvailable } from '../../hooks'
import ApprovedBody from './ApprovedBody'
import AwaitingApprovalBody from './AwaitingApprovalBody'
import CancelledBody from './CancelledBody'
import DeclinedBody from './DeclinedBody'

import {
  Activity,
  Comment,
  GetRequisitionStatusActivitiesDocument,
  Requisition,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCancelRequisition } from '@/modules/requisitions/hooks'
import RequisitionStatus from '@/modules/requisitions/pages/all-requisitions/RequisitionStatus'
import { REQUISITION_STATUS } from '@/modules/requisitions/types'
import { formatStateForLocale, generateShopPath } from '@/modules/requisitions/utils'
import { Button, ConfirmDialog, Fallback, Modal, QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { CartIcon, TrashIcon } from '@/modules/shared/icons'
import { Dates, Themes } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface RequisitionStatusModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  requisitionId: number
  state: string
}

export default function RequisitionStatusModal({
  showModal,
  setShowModal,
  requisitionId,
  state,
}: RequisitionStatusModalProps) {
  const { t } = useTranslation()
  const [cancelIsOpen, setCancelIsOpen] = useState(false)

  const [cancelRequisition, { loading: cancelLoading }] = useCancelRequisition()
  const [fetchRequisition, { data, loading, error }] = useLazyQuery(GetRequisitionStatusActivitiesDocument, {
    variables: {
      requisitionId: Number(requisitionId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })
  const { ShoppingNotAvailableModal, modalProps, onShowModal, onCheckShoppingAvailable } = useCheckShoppingAvailable()

  const onCancelRequisition = () => {
    cancelRequisition({
      variables: { input: { id: requisitionId } },
      onCompleted() {
        setCancelIsOpen(false)
      },
    })
  }

  const requisition = data?.currentPurchaser?.requisition
  const {
    requestor,
    awaitingMyApproval,
    submitActivity,
    approveActivity,
    cancelActivity,
    declineActivity,
    declineComment,
    advanced,
  } = requisition || {}

  const approved = state === REQUISITION_STATUS.APPROVED
  const awaitingApproval = state === REQUISITION_STATUS.AWAITING_APPROVAL
  const cancelled = state === REQUISITION_STATUS.CANCELLED
  const declined = state === REQUISITION_STATUS.DECLINED
  const open = state === REQUISITION_STATUS.OPEN

  useEffect(() => {
    if (showModal) {
      fetchRequisition()
    }
  }, [showModal])

  const onCloseModal = () => setShowModal(false)

  /**
   * Returns translated string for status modal body
   *
   * @param {string} state
   * @returns {string}
   */
  const handleRequisitionStatusModalBodyText = (state: string) => {
    switch (state) {
      case REQUISITION_STATUS.APPROVED:
        return t(
          'requisition.statusModal.approvedStatusBody',
          'An approved requisition is a requisition that was submitted for approval, then approved by the relevant users in the approval workflow. An approved requisition will generate Purchase Orders for each Supplier included in the Requisition. An approved requisition can be closed, or it can be left in the approved state.'
        )
      case REQUISITION_STATUS.AWAITING_APPROVAL:
        return t(
          'requisition.statusModal.awaitingApprovalStatusBody',
          'A Requisition Awaiting Approval is a Requisition that has been submitted for approval, but is yet to be granted final approval. All relevant users in the approval workflow must grant approval in order for a Requisition to become Approved.'
        )
      case REQUISITION_STATUS.DECLINED:
        return t(
          'requisition.statusModal.declinedStatusBody',
          'A declined requisition is a requisition that was submitted for approval, then declined by an approving user in the approval workflow. A declined requisition can be cancelled, or shopping can be resumed and the Requisition can be re-submitted.'
        )
      case REQUISITION_STATUS.CANCELLED:
        return t(
          'requisition.statusModal.cancelledStatusBody',
          'A Cancelled Requisition is a Requisition that was Cancelled while it was in the Open, Awaiting Approval or Declined state. A Cancelled Requisition cannot be Re-Opened and is no longer in use at all at your Organisation. There is no action that can be taken on a Cancelled Requistion.'
        )
      case REQUISITION_STATUS.OPEN:
        return t(
          'requisition.statusModal.openStatusBody',
          'An Open Requisition is a Requisition that has been created, but is yet to be submitted for Approval. You can continue shopping while it is an Open Requisition, or it can be submitted for approval, or it can be Cancelled.'
        )
      default:
        return ''
    }
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        data-testid="requisition-status-modal"
        className="flex h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]"
      >
        <Modal.Title
          title={formatStateForLocale(String(state))}
          onCloseModal={onCloseModal}
          testId="requisition-status-modal-title"
        />

        <Modal.Body className="flex flex-col gap-y-3" data-testid="requisition-status-modal-body">
          <QueryResult loading={loading} error={error}>
            <section>
              <p className="font-bold">
                {t('requisition.statusModal.status', '{{ status }} Status', {
                  status: formatStateForLocale(String(state)),
                })}
              </p>
              <p className="text-gray-500">{handleRequisitionStatusModalBodyText(state)}</p>
              <div className="mt-2 flex h-[3.375rem] w-full items-center rounded-md border bg-gray-100 px-3">
                <RequisitionStatus state={String(state)} />
              </div>
            </section>
            {requestor && (
              <section>
                <p className="font-bold">{t('general.requestedBy', 'Requested By')}</p>
                <p className="text-gray-500">
                  {t('requisition.statusModal.requestedByBody', 'The user who created the Requisition.')}
                </p>
                <div className="mt-2 flex w-full flex-col justify-between gap-x-2 rounded-md border bg-gray-100 px-3 py-4 sm:flex-row">
                  <span>{requestor.fullName}</span>
                  <span className="text-gray-500">{formatDate(Dates.Long, requestor.createdAt)}</span>
                </div>
              </section>
            )}
            {!open && (
              <section>
                <p className="font-bold">{t('general.submittedBy', 'Submitted By')}</p>
                <p className="text-gray-500">
                  {t('requisition.statusModal.submittedByBody', 'The user who submitted the Requisition.')}
                </p>
                <div className="mt-2 flex w-full flex-col justify-between gap-x-2 rounded-md border bg-gray-100 px-3 py-4 sm:flex-row">
                  <Fallback condition={!!submitActivity}>
                    <span>{submitActivity?.user?.fullName}</span>
                    <span className="text-gray-500">{formatDate(Dates.Long, submitActivity?.createdAt)}</span>
                  </Fallback>
                </div>
              </section>
            )}
            {approved && (
              <ApprovedBody requisition={requisition as Requisition} approveActivity={approveActivity as Activity} />
            )}
            {awaitingApproval && <AwaitingApprovalBody requisition={requisition as Requisition} />}
            {declined && (
              <DeclinedBody declineActivity={declineActivity as Activity} declineComment={declineComment as Comment} />
            )}
            {cancelled && <CancelledBody cancelActivity={cancelActivity as Activity} />}
          </QueryResult>
        </Modal.Body>
        <Modal.Footer className="flex flex-col items-center justify-between gap-2 text-sm md:flex-row">
          <div className="flex w-full flex-col gap-2 md:flex-row">
            {awaitingMyApproval && (
              <Link
                to={generateShopPath(requisitionId, advanced)}
                className="flex h-11 items-center justify-center gap-x-2 rounded-md bg-gray-200 px-4 md:justify-start"
                data-testid="requisition-status-modal-update-requisition"
              >
                <span>{t('general.updateRequisition', 'Update Requisition')}</span>
                <CartIcon className="h-6 w-6" />
              </Link>
            )}
            {(declined || open) && (
              <>
                <Link
                  to={generateShopPath(requisitionId, advanced)}
                  className="flex h-11 items-center justify-center gap-x-2 rounded-md bg-gray-200 px-4 hover:brightness-95 md:justify-start"
                  onClick={(e) => {
                    if (!onCheckShoppingAvailable(requisition as Requisition)) {
                      e.preventDefault()
                      onShowModal()
                    }
                  }}
                >
                  <span>{t('general.continueShopping', 'Continue Shopping')}</span>
                  <CartIcon className="h-6 w-6" />
                </Link>
                <Button
                  className="flex h-11 items-center justify-center gap-x-2 rounded-md bg-gray-200 px-4"
                  onClick={() => setCancelIsOpen(true)}
                  data-testid="requisition-status-modal-cancel"
                >
                  {t('general.cancelRequisition', 'Cancel Requisition')}
                  <TrashIcon className="h-6 w-6" />
                </Button>
                <ConfirmDialog
                  title={t('general.cancelRequisition?', 'Cancel Requisition?')}
                  description={t(
                    'requisition.statusModal.cancel.description',
                    'Please confirm below if you wish to cancel the Requisition. Once cancelled, it will no longer be available to be shopped or submitted for approval.'
                  )}
                  primaryButtonLabel={t('general.continue', 'Cancel Requisition')}
                  secondaryButtonLabel={t('general.keepAsOpen', 'Keep as Open')}
                  isOpen={cancelIsOpen}
                  setIsOpen={setCancelIsOpen}
                  onPrimaryBtnClick={onCancelRequisition}
                  btnPrimaryLoading={cancelLoading}
                  theme={Themes.Primary}
                />
                <ShoppingNotAvailableModal {...modalProps} />
              </>
            )}
          </div>
          <Button className="h-11 w-full rounded-md bg-gray-200 px-8 md:w-auto" onClick={onCloseModal}>
            {t('general.close', 'Close')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
