import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { CheckIcon } from '../../icons'

interface CreateTypeSelectionRadioOptionProps {
  onSelectedType: (type: string) => void
  type: string
  name: string
  desc: string
  icon: ReactNode
  isReleased: boolean
}

export default function CreateTypeSelectionRadioOption({
  onSelectedType,
  type,
  name,
  desc,
  icon,
  isReleased,
}: CreateTypeSelectionRadioOptionProps) {
  const { t } = useTranslation()
  return (
    <RadioGroup.Option
      data-testid={`type-${type}`}
      value={type}
      disabled={!isReleased}
      onClick={() => onSelectedType(type)}
      className={({ checked }) =>
        classNames(
          'relative flex cursor-pointer justify-between rounded-md border bg-white px-6 py-4 shadow-sm focus:outline-none',
          {
            'border-transparent': checked,
            'border-gray-300': !checked,
            'border-success': checked,
            'cursor-not-allowed opacity-50': !isReleased,
          }
        )
      }
    >
      {({ active, checked }) => (
        <>
          <span className="flex items-center">
            <span className="hidden md:block">{icon}</span>
            <span className="ml-0 flex flex-col text-sm md:ml-4">
              <RadioGroup.Label as="span" className="text-base font-semibold text-gray-900">
                {name}
              </RadioGroup.Label>
              <RadioGroup.Description as="span" className="hidden text-gray-500 md:block">
                <span className="block sm:inline">{desc}</span>
              </RadioGroup.Description>
            </span>
          </span>
          <RadioGroup.Description as="span" className="flex items-center text-sm sm:ml-4 sm:mt-0 sm:text-right">
            {!isReleased && (
              <div className="mr-2">
                <span className="rounded-md bg-gray-100 px-3 py-2 text-xs text-gray-500 md:text-sm">
                  {t('general.comingSoon', 'Coming Soon!')}
                </span>
              </div>
            )}

            <div className="shrink-0">
              <CheckIcon className={classNames('h-7 w-7', checked ? 'text-success' : 'text-white')} />
            </div>
          </RadioGroup.Description>
          <span
            className={classNames('pointer-events-none absolute -inset-px rounded-md', {
              border: active,
              'border-1': !active,
              'border-success': checked,
              'border-transparent': !checked,
            })}
            aria-hidden="true"
          />
        </>
      )}
    </RadioGroup.Option>
  )
}
