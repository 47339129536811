import { graphql } from 'msw'

import { GetRequisitionLinesBySuppliersQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetRequisitionLinesBySuppliers: GetRequisitionLinesBySuppliersQuery = {
  currentPurchaser: {
    id: 44926,
    requisition: {
      requisitionNumber: 'PR00040',
      reference: 'Fruit and Vegetable Order',
      id: 5235941,
      totalValueExcludingTax: 99.604,
      totalTaxValue: 4.896,
      totalValue: 104.5,
      requisitionLinesBySuppliers: [
        {
          supplier: {
            id: 121239,
            name: '100 Proof',
            billingAddressCity: 'Surry Hills',
            billingAddressCountry: 'AU',
            billingAddressLine1: '1 Missenden',
            billingAddressLine2: '',
            billingAddressPostalCode: '2011',
            billingAddressStateProvince: 'NSW',
            email: '100proof@gmail.com',
            telephone: '0400112321',
            deliveryChargeConfiguration: {
              minimumOrderValueExTax: 150,
              deliveryChargeExTax: 40,
              deliveryChargeIncTax: 44,
              deliveryChargeTaxPercentage: 10,
              __typename: 'DeliveryChargeConfiguration',
            },
            __typename: 'Supplier',
          },
          total: 14.46,
          subTotalExTax: 0,
          totalTax: 0.0,
          lines: [
            {
              id: 26770575,
              quantity: 2.0,
              unitPrice: 5.23,
              productId: 5850915,
              cataloguedProductId: 5841814,
              image: null,
              orderInstruction: null,
              department: null,
              account: null,
              taxPercentage: 0,
              supplierProductCode: '185139-EA',
              longDescription: null,
              supplier: {
                id: 121239,
                name: '100 Proof',
              },
              availableQuotes: [
                {
                  unitPrice: 21.4,
                  taxPercentage: 0,
                  supplierName: 'In2Food (Operations) Pty Ltd - All States ',
                  supplierId: 4,
                  cataloguedProductId: 1,
                  preferredSupplier: false,
                  __typename: 'Price',
                },
                {
                  unitPrice: 40.4,
                  taxPercentage: 0,
                  supplierName: 'Zeus Wholesales',
                  supplierId: 5,
                  cataloguedProductId: 2,
                  preferredSupplier: false,
                  __typename: 'Price',
                },
              ],
              product: {
                id: 4756296,
                category: {
                  id: 109517,
                  name: 'Chocolate and Confectionery',
                  parent: {
                    id: 109438,
                    name: 'Prepared Food and Meals',
                    __typename: 'Category',
                  },
                  __typename: 'Category',
                },
                brand: 'Akiko',
                itemDescription: 'Gummy : Sour Berry Cocktail',
                concatenatedDescription: 'Gummy : Sour Berry Cocktail 18 g pack',
                concatenatedSellUnit: 'each of 1',
                itemPackName: 'pack',
                itemMeasure: 'g',
                itemSellPackName: 'each',
                itemSize: 18,
                __typename: 'Product',
              },
              tax: 0.0,
              total: 10.46,
              __typename: 'RequisitionLine',
            },
            {
              id: 26770590,
              quantity: 1.0,
              unitPrice: 4.0,
              productId: 5850916,
              cataloguedProductId: 5841812,
              taxPercentage: 0,
              supplierProductCode: '185139-EA',
              longDescription: null,
              orderInstruction: null,
              image: null,
              department: null,
              account: null,
              supplier: {
                id: 121239,
                name: '100 Proof',
              },
              availableQuotes: [
                {
                  unitPrice: 21.4,
                  taxPercentage: 0,
                  supplierName: 'In2Food (Operations) Pty Ltd - All States ',
                  supplierId: 4,
                  cataloguedProductId: 3,
                  preferredSupplier: false,
                  __typename: 'Price',
                },
              ],
              product: {
                id: 1232132,
                category: {
                  id: 432433,
                  name: 'Fruit',
                  parent: {
                    id: 31211,
                    name: 'Health',
                    __typename: 'Category',
                  },
                  __typename: 'Category',
                },
                brand: 'Farmers',
                itemDescription: 'Apple',
                concatenatedDescription: 'Apple',
                concatenatedSellUnit: 'each of 1',
                itemPackName: 'pack',
                itemMeasure: 'g',
                itemSellPackName: 'each',
                itemSize: 1,
                __typename: 'Product',
              },
              tax: 0.0,
              total: 4.0,
              __typename: 'RequisitionLine',
            },
          ],
          __typename: 'RequisitionLinesBySupplier',
        },
      ],
      __typename: 'Requisition',
    },
    __typename: 'Purchaser',
  },
}

export const GetRequisitionLinesBySuppliers = graphql.query<GetRequisitionLinesBySuppliersQuery>(
  'GetRequisitionLinesBySuppliers',
  (_, res, ctx) => {
    return res(ctx.data(baseGetRequisitionLinesBySuppliers))
  }
)
