import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { GetRequisitionLinesBySuppliersDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export const useGetRequisitionLinesBySuppliers = () => {
  const { requisitionId } = useParams<{ requisitionId: string }>()

  const { loading, error, data, refetch } = useQuery(GetRequisitionLinesBySuppliersDocument, {
    variables: {
      requisitionId: Number(requisitionId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })
  return { loading, error, data, refetch, requisitionId }
}
