import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SUPPORT_LINK } from '../../constants'
import Button from '../button'
import { Modal } from '../modal'

import InventoryModal from '@/modules/purchase-orders/pages/purchase-order/receiving-notes/InventoryModal'

export interface ReceiptsModalFooterProps {
  onCloseModal: () => void
  purchaseOrderId: string
}

export default function ReceiptsModalFooter({ onCloseModal, purchaseOrderId }: ReceiptsModalFooterProps) {
  const { t } = useTranslation()
  const [openInventoryModal, setOpenInventoryModal] = useState(false)
  return (
    <>
      <Modal.Footer className="flex flex-col-reverse items-center justify-between gap-2 md:flex-row">
        <a
          className="text-center text-sm text-primary"
          data-testid="learn-more-related-docs-link"
          href={SUPPORT_LINK}
          target="_blank"
          rel="noreferrer"
        >
          {t('general.learnMoreReceivingGoods', 'Learn More about Receiving Goods')}
        </a>
        <div className="space-x-2">
          <Button
            type="button"
            className="w-full rounded-md bg-gray-200 px-8 py-2.5 text-sm md:w-auto"
            onClick={onCloseModal}
            data-testid="match-invoice-close"
          >
            {t('general.close', 'Close')}
          </Button>
          <Button
            type="button"
            data-testid="create-another-receiving-note"
            className="w-full rounded-md bg-primary px-5 py-2.5 text-sm text-white md:w-auto"
            onClick={() => setOpenInventoryModal(true)}
          >
            {t('general.createAnother', 'Create Another')}
          </Button>
        </div>
      </Modal.Footer>
      <InventoryModal
        showModal={openInventoryModal}
        setShowModal={setOpenInventoryModal}
        purchaseOrderId={purchaseOrderId}
      />
    </>
  )
}
