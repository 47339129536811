import { graphql } from 'msw'

import { GetRequisitionAttachmentsQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

export const GetRequisitionAttachments = graphql.query<GetRequisitionAttachmentsQuery>(
  'GetRequisitionAttachments',
  (_, res, ctx) => {
    return res(
      ctx.data({
        currentPurchaser: {
          id: 44926,
          requisition: {
            id: 5235941,
            requisitionNumber: 'PR00040',
            requisitionAttachments: {
              edges: [
                {
                  node: {
                    id: 984919,
                    fileFileName: 'attachment_3.png',
                    caption: 'attachment 3',
                    __typename: 'RequisitionAttachment',
                  },
                  __typename: 'RequisitionAttachmentEdge',
                },
                {
                  node: {
                    id: 984917,
                    fileFileName: 'attachment_1.png',
                    caption: 'attachment 1',
                    __typename: 'RequisitionAttachment',
                  },
                  __typename: 'RequisitionAttachmentEdge',
                },
                {
                  node: {
                    id: 984918,
                    fileFileName: 'attachment_2.png',
                    caption: 'attachment 2',
                    __typename: 'RequisitionAttachment',
                  },
                  __typename: 'RequisitionAttachmentEdge',
                },
              ],
              __typename: 'RequisitionAttachmentConnection',
            },
            __typename: 'Requisition',
          },
          __typename: 'Purchaser',
        },
      })
    )
  }
)
