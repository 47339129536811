import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useGetRequisitionAwaitingApprovalCount } from '@/modules/requisitions/hooks'
import { ThemeWrapper, displayBadgeCount } from '@/modules/shared/components'
import { ChevronRightIcon, ClockIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

export default function AwaitingMyApproval() {
  const { t } = useTranslation()

  const { count } = useGetRequisitionAwaitingApprovalCount()

  return (
    <Link
      className="mt-5 flex cursor-pointer items-center justify-between rounded-md bg-white p-4 shadow"
      to="/requisitions/awaiting-my-approval"
      data-testid="dashboard-awaiting-my-approval"
    >
      <div className="flex items-center">
        <ThemeWrapper theme={Themes.Primary} className="flex h-14 w-14 items-center justify-center rounded-md">
          <ClockIcon className="h-10 w-10" />
        </ThemeWrapper>
        <span className="ml-3">
          <p className="font-bold">
            {t('requisition.numberOfPRsAwaiting', '{{ number }} Requisitions', {
              number: displayBadgeCount(count, 25),
            })}
          </p>
          <p className="text-sm text-gray-500">{t('general.awaitingMyApproval', 'Awaiting My Approval')}</p>
        </span>
      </div>
      <ChevronRightIcon className="h-6 text-gray-400" />
    </Link>
  )
}
