import { useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import ReceivingNotesTableView from './table-view/ReceivingNotesTableView'
import ReceivingNotesTileView from './tile-view/ReceivingNotesTileView'

import PurchaseOrdersEmptyStateImg from '@/assets/images/empty-purchase-orders.svg'
import {
  GetAllReceivingNotesDocument,
  RansackDirection,
  RansackMatcher,
  ReceivingDocument,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { ReceivingNoteState } from '@/modules/receiving-notes/types'
import { Spinner } from '@/modules/requisitions/components'
import { Breadcrumb, FilterTabs, QueryResult, TitleBar } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import SearchAndFilter, { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { HomeIcon, TruckIcon } from '@/modules/shared/icons'
import { FilterTabItem, QueryParameter } from '@/modules/shared/types'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export default function AllReceivingNotes() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()

  const [searchTermURLParam] = useQueryParam(QueryParameter.Search, StringParam)
  const [filterURLParam] = useQueryParam(QueryParameter.Status, withDefault(StringParam, 'all'))

  const onFilter = ({ searchValue, filterValue }: OnFilter) => {
    let filter: RansackMatcher[] = [
      {
        property: 'receivingDocumentNumber_cont',
        value: searchValue.trim(),
      },
    ]
    if (filterValue === ReceivingNoteState.Received) {
      filter.push({
        property: 'workflowState_eq',
        value: 'received',
      })
    } else if (filterValue === ReceivingNoteState.Draft) {
      filter.push({
        property: 'workflowState_eq',
        value: 'open',
      })
    } else {
      filter.push({
        property: 'workflowState_in',
        value: ['received', 'open'],
      })
    }
    refetch({
      filter: {
        q: [...filter],
      },
    })
  }

  const { data, networkStatus, refetch, fetchMore, error } = useQuery(GetAllReceivingNotesDocument, {
    variables: {
      after: null,
      filter: {
        q: [
          {
            property: 'receivingDocumentNumber_cont',
            value: searchTermURLParam ?? '',
          },
          {
            property: `workflowState_${filterURLParam === 'all' ? 'in' : 'eq'}`,
            value: filterURLParam === 'all' ? ['received', 'open'] : filterURLParam,
          },
        ],
      },
      sort: [
        {
          property: 'receivedDate',
          direction: RansackDirection.Desc,
        },
      ],
    },
    context: { uri: PURCHASING_GRAPHQL_API },
    notifyOnNetworkStatusChange: true,
  })

  const { setVariablesLoading, refetchLoading, loading } = checkNetworkStatus(networkStatus)

  const receivingNotes = extractEdges<ReceivingDocument>(data?.currentPurchaser?.receivingDocuments)

  const filterTabs: FilterTabItem[] = [
    {
      key: t('general.viewAll', 'View All'),
      filterValue: 'all',
    },
    {
      key: t('general.received', 'Received'),
      filterValue: 'received',
    },
    {
      key: t('general.draft', 'Draft'),
      filterValue: 'draft',
    },
  ]

  const onFetchMoreReceivingNotes = () => {
    fetchMore({
      variables: {
        after: data?.currentPurchaser?.receivingDocuments?.pageInfo.endCursor,
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{t('general.viewAllReceivingNotes', 'View All Receiving Notes')}</title>
      </Helmet>
      <main className="mx-auto space-y-5 px-4">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="/purchase-orders/receiving-notes">
            {t('general.receivingNotes', 'Receiving Notes')}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{filterTabs.find((e) => e.filterValue === filterURLParam)?.key}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="space-y-2">
          <TitleBar
            icon={<TruckIcon className="h-10 w-10" />}
            title={t('general.allReceivingNotes', 'All Receiving Notes')}
            subtitle={t('purchaseOrders.allReceivingNotes.viewAll', 'View All Receiving Notes')}
          />
          <FilterTabs tabs={filterTabs} />
        </section>
        <SearchAndFilter
          testId="receiving-notes-search-input"
          placeholder={t('purchaseOrders.allReceivingNotes.searchPlaceholder', 'Search by GR Number')}
          ariaLabel={t('purchaseOrders.allReceivingNotes.ariaLabel', 'Searching for Receiving Notes')}
          onFilter={onFilter}
          queryParamFilterType={QueryParameter.Status}
        />
        <section>
          <QueryResult loading={loading || refetchLoading || setVariablesLoading} error={error}>
            {data?.currentPurchaser && (
              <>
                <InfiniteScroll
                  dataLength={receivingNotes.length}
                  next={onFetchMoreReceivingNotes}
                  hasMore={!!data?.currentPurchaser?.receivingDocuments?.pageInfo.hasNextPage}
                  loader={<Spinner className="mt-5 h-14 md:w-16" />}
                >
                  {isLargeScreen ? (
                    <ReceivingNotesTableView receivingDocs={receivingNotes} />
                  ) : (
                    <div className="block space-y-5">
                      <ReceivingNotesTileView receivingDocs={receivingNotes} />
                    </div>
                  )}
                </InfiniteScroll>
                {!setVariablesLoading && receivingNotes.length === 0 && (
                  <EmptyState
                    img={PurchaseOrdersEmptyStateImg}
                    message={t(
                      'purchaseOrders.allReceivingNotes.noReceivingNotesFound',
                      "We couldn't find any Receiving Notes to display."
                    )}
                  />
                )}
              </>
            )}
          </QueryResult>
        </section>
      </main>
    </>
  )
}
