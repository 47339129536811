import { Purchaser } from '@/graphql/purchasing/generated/purchasing_graphql'

/**
 * If the descendant of the purchaser is selected, it returns true. Recursively checks all the children in the object
 * tree.
 */
export function isChildSelected(purchaser: Purchaser | null, selectedId: number, forceExpandAll?: boolean): boolean {
  if (!purchaser || (!purchaser.parent && !purchaser.departments)) {
    return false
  }

  if (forceExpandAll) {
    return true
  }

  if (purchaser.departments.some((dept) => dept.id === selectedId)) {
    return true
  }

  return purchaser.departments.some((dept) => isChildSelected(dept, selectedId, forceExpandAll))
}
