import { graphql } from 'msw'

import { DeleteRequisitionLineMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const DeleteRequisitionLine = graphql.mutation<DeleteRequisitionLineMutation>(
  'DeleteRequisitionLine',
  (_, res, ctx) => {
    return res(
      ctx.data({
        deleteRequisitionLine: {
          requisition: {
            id: 5235941,
            totalValue: 0,
            totalValueExcludingTax: 0,
            lines: [],
            requisitionLinesBySuppliers: [],
            __typename: 'Requisition',
          },
          __typename: 'DeleteRequisitionLineMutationPayload',
        },
      })
    )
  }
)
