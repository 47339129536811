import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { StockLocation } from '@/graphql/inventory/generated/inventory_graphql'
import { MoreOptionsMenu } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon } from '@/modules/shared/icons'

interface TableViewProps {
  stockLocations: StockLocation[]
}

function TableView(props: TableViewProps) {
  const { stockLocations } = props
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  const renderLocation = ({ name }: StockLocation) => {
    return name
  }
  const renderBalance = ({ balanceValue }: StockLocation) => {
    return formatMoney(balanceValue)
  }
  const renderMoreOptions = ({ id }: StockLocation) => {
    return (
      <MoreOptionsMenu showOpenInPPlus showHelp stockLocationId={id}>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </MoreOptionsMenu>
    )
  }

  return (
    <Table
      dataSource={stockLocations}
      dataTestId="stock-locations-table-view"
      keyExtractor={(record) => String(record.id)}
      columns={[
        {
          title: t('general.location', 'Location'),
          key: 'location',
          headerCellStyles: 'px-4 py-3 ',
          cellStyles: 'px-4 py-3',
          minWidth: 14.375,
          grow: true,
          render: renderLocation,
        },
        {
          title: t('general.balance', 'Balance'),
          key: 'balance',
          headerCellStyles: 'px-0 py-3 text-right',
          cellStyles: 'px-0 py-3 text-right',
          minWidth: 8.75,
          maxWidth: 11.25,
          render: renderBalance,
        },
        {
          title: t('general.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: renderMoreOptions,
        },
      ]}
    />
  )
}

export default memo(TableView)
