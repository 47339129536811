import { graphql } from 'msw'

import { GetPurchaseOrderLinesLinkableQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetPurchaseOrderLinesLinkable: GetPurchaseOrderLinesLinkableQuery = {
  currentPurchaser: {
    id: 44926,
    purchaseOrder: {
      id: 16566710,
      lineItemsNotInvoicedBy: [
        {
          id: 60215981,
          quantity: 10,
          unitPrice: 1.18,
          taxPercentage: 10,
          product: {
            id: 27983,
            category: {
              id: 109675,
              name: 'Marker Pens and Highlighters',
              parent: {
                id: 109449,
                name: 'Stationery',
                __typename: 'Category',
              },
              __typename: 'Category',
            },
            brand: null,
            itemDescription: '1253859 - Marker Black Fine 1.0mm - $1.18 each',
            concatenatedSellUnit: 'each of 1',
            itemPackName: null,
            itemMeasure: 'each',
            itemSellPackName: 'each',
            itemSize: 1,
            __typename: 'Product',
          },
          __typename: 'PurchaseOrderLineItem',
        },
      ],
      __typename: 'PurchaseOrder',
    },
    __typename: 'Purchaser',
  },
}

export const GetPurchaseOrderLinesLinkable = graphql.query<GetPurchaseOrderLinesLinkableQuery>(
  'GetPurchaseOrderLinesLinkable',
  (_, res, ctx) => {
    return res(ctx.data(baseGetPurchaseOrderLinesLinkable))
  }
)

export default baseGetPurchaseOrderLinesLinkable
