import { graphql } from 'msw'

import { GetRequisitionCommentsQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetRequisitionComments: GetRequisitionCommentsQuery = {
  currentPurchaser: {
    id: 44926,
    requisition: {
      id: 5235941,
      requisitionNumber: 'PR00040',
      reference: '',
      commentsCount: 3,
      privateComments: {
        edges: [
          {
            node: {
              id: 1985525,
              body: 'Private Comment',
              createdAt: '2023-02-02T16:45:33+11:00',
              creator: {
                id: 23783,
                fullName: 'Nathan Del Medico',
                __typename: 'User',
              },
              __typename: 'Comment',
            },
            __typename: 'CommentEdge',
          },
          {
            node: {
              id: 1985408,
              body: 'Decline Reason - test\n',
              createdAt: '2022-10-06T09:22:57+11:00',
              creator: {
                id: 18544,
                fullName: 'Sunny H Sun',
                __typename: 'User',
              },
              __typename: 'Comment',
            },
            __typename: 'CommentEdge',
          },
        ],
        __typename: 'CommentConnection',
      },
      publicComments: {
        edges: [
          {
            node: {
              id: 1985527,
              body: 'Public Comment',
              createdAt: '2023-02-02T16:47:34+11:00',
              creator: {
                id: 23790,
                fullName: 'Nathan Del Medico',
                __typename: 'User',
              },
              __typename: 'Comment',
            },
            __typename: 'CommentEdge',
          },
        ],
        __typename: 'CommentConnection',
      },
      __typename: 'Requisition',
    },
    __typename: 'Purchaser',
  },
}

export const GetRequisitionComments = graphql.query<GetRequisitionCommentsQuery>(
  'GetRequisitionComments',
  (req, res, ctx) => {
    return res(ctx.data(baseGetRequisitionComments))
  }
)

export default baseGetRequisitionComments
