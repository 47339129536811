import { Fallback, Tooltip } from '@/modules/shared/components'
import { QuestionMarkIcon } from '@/modules/shared/icons'

export interface SimpleTableItemWithTooltipProps {
  title: string
  tooltip?: string
  value?: string | null | JSX.Element
}

const SimpleTableItemWithTooltip = ({ title, tooltip, value }: SimpleTableItemWithTooltipProps) => (
  <div className="flex flex-col">
    <p className="flex items-center gap-x-1 font-bold">
      {title}
      {tooltip && (
        <Tooltip content={tooltip}>
          <QuestionMarkIcon className="h-5 w-5" />
        </Tooltip>
      )}
    </p>

    <Fallback as="p" condition={!!value}>
      {value}
    </Fallback>
  </div>
)

export default SimpleTableItemWithTooltip
