import { useTranslation } from 'react-i18next'

import { useMoney } from '../../hooks'

import NoImage from '@/assets/images/no-image.svg'
import { Maybe, Product } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatProductItem } from '@/modules/requisitions/utils'

interface ProductSummaryProps {
  product: Maybe<Product>
  unitPrice?: Maybe<number>
  taxPercentage?: Maybe<number>
  showPriceAndTaxPercentage?: boolean
}

export default function ProductSummary({
  product,
  taxPercentage,
  unitPrice,
  showPriceAndTaxPercentage = true,
}: ProductSummaryProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  return (
    <div className="mt-4 flex items-center gap-x-4 rounded-md border p-4">
      <img className="h-24 w-24 flex-none border" src={product?.image || NoImage} alt="product" />
      <span>
        <p className="text-sm text-primary">
          {product?.brand} {product?.itemDescription}
        </p>
        <p className="text-xs text-gray-500">
          {formatProductItem({
            itemSize: product?.itemSize,
            itemMeasure: product?.itemMeasure,
            itemPackName: product?.itemPackName,
            itemSellUnit: product?.concatenatedSellUnit,
          })}
        </p>
        {showPriceAndTaxPercentage && (
          <>
            <p className="text-sm font-bold">{formatMoney(unitPrice)}</p>
            <p className="text-xxs text-gray-500 sm:text-xs">
              {taxPercentage
                ? t('general.plusTax', 'Plus {{ percent }}% Tax', {
                    percent: taxPercentage,
                  })
                : t('general.taxFree', 'Tax Free')}
            </p>
          </>
        )}
      </span>
    </div>
  )
}
