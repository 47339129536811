import { RadioGroup } from '@headlessui/react'
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params'

import AdvancedSearchFilter from './AdvancedSearchFilter'

import { Tooltip } from '@/modules/shared/components'
import { QuestionMarkIcon } from '@/modules/shared/icons'
import { CheckboxIcon } from '@/modules/shared/icons-special'
import { QueryParameter } from '@/modules/shared/types'

type Option = {
  label: string
  tooltip: string
  value: null | boolean
}

interface FilterByFlagProps {
  title: string
  options: Option[]
  type: QueryParameter.Image | QueryParameter.Catalogued | QueryParameter.Matched | QueryParameter.Master
}

export default function FilterByFlag({ type, title, options }: FilterByFlagProps) {
  const [imageURLParam, setImageURLParam] = useQueryParam(type, withDefault(BooleanParam, null))

  return (
    <AdvancedSearchFilter buttonTestId="filters-section" buttonTitle={title}>
      <RadioGroup value={imageURLParam} onChange={setImageURLParam}>
        <div data-testid="flag-actions-group" className="space-y-2">
          {options.map((item) => (
            <RadioGroup.Option key={String(item.value)} value={item.value}>
              {({ checked }) => {
                if (checked && item.value === null) setImageURLParam(null)
                return (
                  <div className="flex cursor-pointer items-center gap-x-3">
                    <CheckboxIcon isSelected={checked} />
                    <div className="flex items-center gap-x-1">
                      <span className="text-sm">{item.label}</span>
                      <Tooltip content={item.tooltip}>
                        <QuestionMarkIcon className="h-5 w-5" />
                      </Tooltip>
                    </div>
                  </div>
                )
              }}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </AdvancedSearchFilter>
  )
}
