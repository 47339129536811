import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { ReceivingDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ReceivingNoteRoute } from '@/modules/purchase-orders/utils/receivingNoteRoute'
import { ReceivingNoteStatus } from '@/modules/receiving-notes/components'
import { ReceivingNoteState } from '@/modules/receiving-notes/types'
import { Fallback, SimpleTileViewItem } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TileViewProps {
  receivingDocs: ReceivingDocument[]
}

function TileView(props: TileViewProps) {
  const { receivingDocs } = props
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  return (
    <>
      {receivingDocs.map((receivingDoc) => {
        const {
          receivingDocumentNumber,
          receiver,
          workflowState,
          receivedDate,
          id,
          destination,
          transfer,
          receivingTotalValue,
          purchaseOrderId,
        } = receivingDoc
        return (
          <div className="mb-6 rounded-md border bg-white" key={id}>
            <div className="p-4">
              <span className="break-all text-base font-bold">{receivingDocumentNumber}</span>
              <p className="text-sm text-gray-500">
                {t('receivingNotes.receivingNoteByUser', 'By {{ name }}', { name: receiver?.fullName })}
              </p>
            </div>
            <hr />
            <section data-testid="receiving-note-details" className="flex flex-col gap-y-3 p-4 text-sm">
              <SimpleTileViewItem
                title={t('general.status', 'Status')}
                value={<ReceivingNoteStatus state={String(workflowState)} />}
              />
              <SimpleTileViewItem title={t('general.destination', 'Destination')} value={destination?.name} />
              <SimpleTileViewItem
                title={t('general.receivedDate', 'Received Date')}
                value={
                  <Fallback condition={workflowState !== ReceivingNoteState.Open}>
                    {formatDate(Dates.Short, new Date(receivedDate))}
                  </Fallback>
                }
              />
              <SimpleTileViewItem
                title={t('general.receivedTime', 'Received Time')}
                value={
                  <Fallback condition={workflowState !== ReceivingNoteState.Open}>
                    {formatDate(Dates.Time, new Date(receivedDate))}
                  </Fallback>
                }
              />
              <SimpleTileViewItem title={t('general.transfer', 'Transfer')} value={transfer?.transferNumber} />
              <SimpleTileViewItem title={t('general.total', 'Total')} value={formatMoney(receivingTotalValue)} />
            </section>

            <hr />
            <div className="flex w-full items-center justify-between p-4">
              <Link
                data-testid="view-receiving-note-link"
                to={generatePath(
                  `/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/${ReceivingNoteRoute(
                    workflowState as ReceivingNoteState
                  )}`,
                  {
                    receivingNoteId: String(id),
                    purchaseOrderId: String(purchaseOrderId),
                  }
                )}
                className="flex h-11 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
              >
                {t('general.viewDetails', 'View Details')}
              </Link>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default memo(TileView)
