import classNames from 'classnames'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import InvoiceStatus from '@/modules/invoices/pages/all-invoices/InvoiceStatus'
import { MoreOptionsMenu, PPNewDetailLink, SimpleTileViewItem } from '@/modules/shared/components'
import { PurchaseOrderLinkOrMatchButton, ShortDateItem } from '@/modules/shared/components/table/table-column-items'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon } from '@/modules/shared/icons'

interface TileViewProps {
  invoices: Invoice[]
}

function TileView({ invoices }: TileViewProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  return (
    <>
      {invoices.map((invoice) => {
        const {
          id,
          invoiceNumber,
          reference,
          totalTaxValue,
          totalValue,
          supplier,
          purchaseOrder,
          state,
          invoiceDate,
          department,
          account,
        } = invoice

        return (
          <div key={id} className="mb-6 rounded-md border bg-white">
            <div className="flex justify-between p-4">
              <div>
                <span className="break-all text-base font-bold">{invoiceNumber}</span>
                <p className="text-sm text-gray-500">{reference || invoiceNumber}</p>
              </div>
              <div>
                <div className="mt-0 text-right">
                  <span className="text-base font-bold">{`${formatMoney(totalValue)}`}</span>
                  <p className="text-sm text-gray-500">
                    {t('general.total', 'Total')} ({t('general.includingTax', 'Including Tax')})
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <section data-testid="invoice-details" className="p-4 text-sm">
              <SimpleTileViewItem title={t('general.supplier', 'Supplier')} value={supplier?.name} className="mb-3" />
              <SimpleTileViewItem
                className="mb-3"
                purchaseOrder={purchaseOrder}
                title={t('general.purchaseOrder', 'Purchase Order')}
                value={<PurchaseOrderLinkOrMatchButton invoice={invoice} />}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('general.status', 'Status')}
                value={<InvoiceStatus state={state || ''} />}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('general.invoiceDate', 'Invoice Date')}
                value={<ShortDateItem date={invoiceDate} />}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('general.department', 'Department')}
                value={department?.name}
              />
              <SimpleTileViewItem
                className="mb-3"
                title={t('general.accountCode', 'Account Code')}
                value={account?.accountName}
              />
              <SimpleTileViewItem className="mb-3" title={t('general.tax', 'Tax')} value={formatMoney(totalTaxValue)} />
            </section>

            <hr />
            <div className={classNames('flex w-full items-center justify-between p-4')}>
              <PPNewDetailLink
                type="invoice"
                testId="view-invoice-link-res"
                id={String(id)}
                className="flex h-12 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
              >
                {t('general.viewInvoice', 'View Invoice')}
              </PPNewDetailLink>
              <MoreOptionsMenu showHelp showOpenInPPlus showInvoiceLink showPrint invoiceId={id}>
                <OptionIcon className="h-10 w-10 text-gray-500" />
              </MoreOptionsMenu>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default memo(TileView)
