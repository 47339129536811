import { graphql } from 'msw'

import {
  GetAllPurchaseOrdersQuery,
  PurchaseOrderInvoicedStatusEnum,
  PurchaseOrderReceivedStatusEnum,
} from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetAllPurchaseOrders: GetAllPurchaseOrdersQuery = {
  currentPurchaser: {
    id: 44926,
    purchaseOrders: {
      edges: [
        {
          node: {
            id: 16564917,
            account: {
              accountName: '0060005 - Operating Account - 5',
              code: '0060005',
              id: 96389,
              __typename: 'Account',
            },
            department: {
              id: 85126,
              name: 'Food',
              __typename: 'Purchaser',
            },
            supplier: {
              id: 574,
              name: 'PFD Food Services Pty Ltd Sydney',
              __typename: 'Supplier',
            },
            receivingDocuments: {
              edges: [],

              __typename: 'ReceivingDocumentConnection',
            },
            invoices: {
              edges: [
                {
                  node: {
                    id: 884359,
                    invoiceNumber: '6783264327',
                    totalValue: 141.71,
                    __typename: 'Invoice',
                  },
                  __typename: 'InvoiceEdge',
                },
                {
                  node: {
                    id: 1036909,
                    invoiceNumber: '1234556',
                    totalValue: 100,
                    __typename: 'Invoice',
                  },
                  __typename: 'InvoiceEdge',
                },
                {
                  node: {
                    id: 1208604,
                    invoiceNumber: 'IVAN INVOICE',
                    totalValue: 111,
                    __typename: 'Invoice',
                  },
                  __typename: 'InvoiceEdge',
                },
                {
                  node: {
                    id: 5640257,
                    invoiceNumber: '564716781',
                    totalValue: 316.8,
                    __typename: 'Invoice',
                  },
                  __typename: 'InvoiceEdge',
                },
              ],
              __typename: 'InvoiceConnection',
            },
            deliveries: {
              edges: [],
            },
            expectedDeliveryDate: '2021-07-01',
            purchaseOrderNumber: 'PO01111',
            receivedStatus: PurchaseOrderReceivedStatusEnum.NotReceived,
            sentDate: null,
            requisition: null,
            status: 'Not Sent',
            totalValue: 111,
            received: 'All products have been fully received.',
            invoicedStatus: PurchaseOrderInvoicedStatusEnum.FullyInvoiced,
            sendToSupplier: false,
            updatedAt: '2020-02-12T14:24:33+11:00',

            updator: {
              id: 23790,
              fullName: 'Nathan Del Medico',
              __typename: 'User',
            },
            __typename: 'PurchaseOrder',
          },
        },
        {
          node: {
            id: 16564905,
            account: {
              accountName: '0060005 - Operating Account - 5',
              code: '0060005',
              id: 96389,
              __typename: 'Account',
            },
            department: {
              id: 85126,
              name: 'Food',
              __typename: 'Purchaser',
            },
            supplier: {
              id: 574,
              name: 'PFD Food Services Pty Ltd Sydney',
              __typename: 'Supplier',
            },
            receivingDocuments: {
              edges: [
                {
                  node: {
                    id: 6660098,
                    receivingDocumentNumber: 'GR02672',
                    workflowState: 'received',
                    __typename: 'ReceivingDocument',
                  },
                  __typename: 'ReceivingDocumentEdge',
                },
              ],
              __typename: 'ReceivingDocumentConnection',
            },
            invoices: {
              edges: [],
            },
            deliveries: {
              edges: [
                {
                  node: {
                    id: 11713367,
                    state: 'sent',
                    createdAt: '2022-11-02T14:06:48+11:00',
                    sender: {
                      id: 18544,
                      fullName: 'Sunny H Sun',
                      __typename: 'User',
                    },
                  },
                },
                {
                  node: {
                    id: 8183771,
                    state: 'resent',
                    createdAt: '2020-02-12T14:24:33+11:00',
                    sender: {
                      id: 8,
                      fullName: 'Sandi Murphy',
                      __typename: 'User',
                    },
                  },
                },
                {
                  node: {
                    id: 8194568,
                    state: 'resent',
                    createdAt: '2020-02-13T13:07:27+11:00',
                    sender: {
                      id: 8,
                      fullName: 'Sandi Murphy',
                      __typename: 'User',
                    },
                  },
                },
              ],
            },
            expectedDeliveryDate: '2021-07-02',
            purchaseOrderNumber: 'PO01112',
            receivedStatus: PurchaseOrderReceivedStatusEnum.FullyReceived,
            sentDate: '2022-02-12T14:24:27+11:00',
            requisition: {
              id: 5270092,
              reference: 'test',
              requisitionNumber: 'PR04218',
              __typename: 'Requisition',
            },
            status: 'Sent',
            totalValue: 222,
            received: 'At least one product has not been fully received on this purchase order.',
            invoicedStatus: PurchaseOrderInvoicedStatusEnum.FullyInvoiced,
            sendToSupplier: false,
            updatedAt: '2020-02-12T14:24:33+11:00',
            updator: {
              id: 23790,
              fullName: 'Nathan Del Medico',
              __typename: 'User',
            },
            __typename: 'PurchaseOrder',
          },
        },
        {
          node: {
            id: 16564916,
            account: {
              accountName: '0060005 - Operating Account - 5',
              code: '0060005',
              id: 96389,
              __typename: 'Account',
            },
            department: {
              id: 85126,
              name: 'Food',
              __typename: 'Purchaser',
            },
            supplier: {
              id: 574,
              name: 'PFD Food Services Pty Ltd Sydney',
              __typename: 'Supplier',
            },
            deliveries: {
              edges: [],
            },
            invoices: null,
            expectedDeliveryDate: '2021-07-03',
            purchaseOrderNumber: 'PO01113',
            receivedStatus: PurchaseOrderReceivedStatusEnum.PartlyReceived,
            sentDate: null,
            requisition: null,
            status: 'Received',
            totalValue: 333,
            received: 'All products have been fully received.',
            invoicedStatus: PurchaseOrderInvoicedStatusEnum.FullyInvoiced,
            sendToSupplier: false,
            updatedAt: '2020-02-12T14:24:33+11:00',
            receivingDocuments: {
              edges: [],
              __typename: 'ReceivingDocumentConnection',
            },
            updator: {
              id: 23790,
              fullName: 'Nathan Del Medico',
              __typename: 'User',
            },
            __typename: 'PurchaseOrder',
          },
        },
        {
          node: {
            id: 16564918,
            account: {
              accountName: '0060005 - Operating Account - 5',
              code: '0060005',
              id: 96389,
              __typename: 'Account',
            },
            department: {
              id: 85126,
              name: 'Food',
              __typename: 'Purchaser',
            },
            supplier: {
              id: 574,
              name: 'PFD Food Services Pty Ltd Sydney',
              __typename: 'Supplier',
            },
            invoices: {
              edges: [],
            },
            deliveries: {
              edges: [],
            },
            expectedDeliveryDate: '2021-07-04',
            purchaseOrderNumber: 'PO01114',
            receivedStatus: PurchaseOrderReceivedStatusEnum.NotReceived,
            sentDate: null,
            requisition: null,
            status: 'Invoiced',
            totalValue: 444,
            received: 'No products have been received from this purchase order.',
            invoicedStatus: PurchaseOrderInvoicedStatusEnum.FullyInvoiced,
            sendToSupplier: false,
            updatedAt: '2020-02-12T14:24:33+11:00',
            receivingDocuments: {
              edges: [],
              __typename: 'ReceivingDocumentConnection',
            },
            updator: {
              id: 23790,
              fullName: 'Nathan Del Medico',
              __typename: 'User',
            },
            __typename: 'PurchaseOrder',
          },
        },
        {
          node: {
            id: 16564919,
            account: {
              accountName: '0060005 - Operating Account - 5',
              code: '0060005',
              id: 96389,
              __typename: 'Account',
            },
            department: {
              id: 85126,
              name: 'Food',
              __typename: 'Purchaser',
            },
            supplier: {
              id: 574,
              name: 'PFD Food Services Pty Ltd Sydney',
              __typename: 'Supplier',
            },
            receivingDocuments: {
              edges: [],
              __typename: 'ReceivingDocumentConnection',
            },
            invoices: {
              edges: [],
            },
            deliveries: {
              edges: [],
            },
            expectedDeliveryDate: '2021-07-05',
            purchaseOrderNumber: 'PO01115',
            receivedStatus: PurchaseOrderReceivedStatusEnum.FullyReceived,
            sentDate: null,
            requisition: null,
            status: 'Closed',
            totalValue: 555,
            received: 'No products have been received from this purchase order.',
            invoicedStatus: PurchaseOrderInvoicedStatusEnum.FullyInvoiced,
            sendToSupplier: false,
            updatedAt: '2020-02-12T14:24:33+11:00',
            updator: {
              id: 23790,
              fullName: 'Nathan Del Medico',
              __typename: 'User',
            },
            __typename: 'PurchaseOrder',
          },
        },
        {
          node: {
            id: 16564930,
            account: {
              accountName: '0060005 - Operating Account - 5',
              code: '0060005',
              id: 96389,
              __typename: 'Account',
            },
            department: {
              id: 85126,
              name: 'Food',
              __typename: 'Purchaser',
            },
            receivingDocuments: {
              edges: [],
              __typename: 'ReceivingDocumentConnection',
            },
            supplier: {
              id: 574,
              name: 'PFD Food Services Pty Ltd Sydney',
              __typename: 'Supplier',
            },
            invoices: {
              edges: [],
            },
            deliveries: {
              edges: [],
            },
            expectedDeliveryDate: '2021-07-06',
            purchaseOrderNumber: 'PO01116',
            receivedStatus: PurchaseOrderReceivedStatusEnum.FullyReceived,
            sentDate: null,
            requisition: null,
            status: 'Cancelled',
            totalValue: 666,
            received: 'No products have been received from this purchase order.',
            invoicedStatus: PurchaseOrderInvoicedStatusEnum.FullyInvoiced,
            sendToSupplier: false,
            updatedAt: '2020-02-12T14:24:33+11:00',
            updator: {
              id: 23790,
              fullName: 'Nathan Del Medico',
              __typename: 'User',
            },
            __typename: 'PurchaseOrder',
          },
        },
      ],
      pageInfo: {
        hasNextPage: false,
        endCursor: 'MjU',
        __typename: 'PageInfo',
      },
    },
    __typename: 'Purchaser',
  },
}

export const GetAllPurchaseOrders = graphql.query<GetAllPurchaseOrdersQuery>('GetAllPurchaseOrders', (_, res, ctx) => {
  return res(ctx.data(baseGetAllPurchaseOrders))
})

export default baseGetAllPurchaseOrders
