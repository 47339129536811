import { Dispatch, SetStateAction } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { PurchaseOrder } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCancelPurchaseOrder, useUpdatePurchaseOrder } from '@/modules/purchase-orders/hooks'
import { CancelPurchaseOrderInput, PurchaseOrderStatuses } from '@/modules/purchase-orders/types'
import { statusText } from '@/modules/purchase-orders/utils/statusText'
import { Button, Modal } from '@/modules/shared/components'
import FormCheckBoxField from '@/modules/shared/components/create-form/FormCheckBoxField'

interface CancelPurchaseOrderModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  purchaseOrder: PurchaseOrder
  onCompleteCancellation: () => void
}

export default function CancelPurchaseOrderModal({
  showModal,
  setShowModal,
  purchaseOrder,
  onCompleteCancellation,
}: CancelPurchaseOrderModalProps) {
  const { t } = useTranslation()
  const { purchaseOrderNumber, id, status, sentDate } = purchaseOrder || {}

  const [updatePurchaseOrder, { loading: updateLoading }] = useUpdatePurchaseOrder()
  const [cancelPurchaseOrder, { loading: cancelLoading }] = useCancelPurchaseOrder()

  const sent = statusText(status, sentDate) === PurchaseOrderStatuses.Sent

  const formMethods = useForm<CancelPurchaseOrderInput>({
    defaultValues: { sendToSupplier: sent || !!purchaseOrder?.sendToSupplier },
  })

  const onSubmit: SubmitHandler<CancelPurchaseOrderInput> = (data) => {
    const { sendToSupplier } = data
    updatePurchaseOrder({
      variables: { input: { id, sendToSupplier } },
      onCompleted: () => {
        cancelPurchaseOrder({ variables: { input: { id } }, onCompleted: onCancelPurchaseOrder })
      },
    })
  }

  const onCancelPurchaseOrder = () => {
    onCompleteCancellation()
    onCloseModal()
  }

  const onCloseModal = () => {
    setShowModal(false)
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        as="form"
        onSubmit={formMethods.handleSubmit(onSubmit)}
        className="flex w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-2xl"
      >
        <FormProvider {...formMethods}>
          <Modal.Title
            title={t('purchaseOrders.cancelModal.title', 'Cancel {{ purchaseOrderNumber}}?', { purchaseOrderNumber })}
            onCloseModal={onCloseModal}
          />
          <Modal.Body className="space-y-4">
            <p className="text-gray-500">
              <Trans t={t} key="purchaseOrders.cancelModal.body">
                You are able to Cancel this Purchase Order as there have been no Goods Receiving Notes created against
                it and no Invoices matched to it. Once the Purchase Order has been Cancelled it can no longer be edited
                and the Cancel action cannot be undone.
              </Trans>
            </p>
            {sent && (
              <FormCheckBoxField
                text={t('purchaseOrders.cancelModal.sendToSupplier', 'Send Cancellation Notice to the Supplier')}
                register="sendToSupplier"
                title={t('purchaseOrders.cancelModal.sendToSupplierTitle', 'Send Cancellation Notice to the Supplier?')}
                body={
                  <Trans t={t} i18nKey="purchaseOrders.cancelModal.sendToSupplierBody">
                    When cancelling the Purchase Order, you can uncheck the option below to prevent the Supplier from
                    receiving a cancellation notice for this Purchase Order. There may be reasons why you don't need the
                    Supplier to receive a cancellation notice electronically, for example you may have phoned and
                    cancelled the order, or the Supplier,s system is not able to process cancellation notices correctly.
                  </Trans>
                }
              />
            )}
          </Modal.Body>
          <Modal.Footer className="flex flex-col-reverse justify-end gap-2 md:flex-row">
            <Button
              type="button"
              onClick={onCloseModal}
              className="h-11 w-full rounded-md bg-gray-200 px-5 text-sm md:w-fit"
            >
              {t('general.dontCancel', "Don't Cancel")}
            </Button>
            <Button
              type="submit"
              className="h-11 w-full rounded-md bg-error px-5 text-sm text-white md:w-fit"
              loading={cancelLoading || updateLoading}
              data-testid="cancel-purchase-order-button"
            >
              {t('general.confirmAndCancel', 'Confirm and Cancel')}
            </Button>
          </Modal.Footer>
        </FormProvider>
      </Modal.Panel>
    </Modal>
  )
}
