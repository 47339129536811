import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { ApproveRequisitionDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ConfirmationVariant, REQUISITION_STATUS } from '@/modules/requisitions/types'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useAlert } from '@/modules/shared/hooks'

export default function useApproveRequisition() {
  const { t } = useTranslation()
  const { alertDialog } = useAlert()
  const navigate = useNavigate()
  const mutation = useMutation(ApproveRequisitionDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    onCompleted(data) {
      const requisitionId = String(data.approveRequisition.requisition?.id)
      const requisitionPageStatus =
        data.approveRequisition.requisition?.state === REQUISITION_STATUS.APPROVED
          ? ConfirmationVariant.Approved
          : ConfirmationVariant.PartiallyApproved
      navigate(
        generatePath('/requisitions/:requisitionId', {
          requisitionId,
        }),
        { state: { requisitionPageStatus } }
      )
    },
    onError({ graphQLErrors }) {
      graphQLErrors?.forEach((e) => {
        if (e.extensions.code === 400) {
          if (e.message === 'Approve is not available from the state Approved') {
            alertDialog({
              type: 'error',
              title: t('general.alreadyApproved', 'Already Approved'),
              message: t(
                'alert.error.approveAction.message',
                'This Purchase Requisition has already been approved by you or someone else with the same approval permissions as you.'
              ),
              buttonText: t('general.okay', 'Okay'),
            })
          }
          if (e.message === 'Approve is not available from the state Declined') {
            alertDialog({
              type: 'error',
              title: t('general.alreadyDeclined', 'Already Declined'),
              message: t(
                'alert.error.declineAction.message',
                'This Purchase Requisition has already been declined by you or someone else with the same approval permissions as you.'
              ),
              buttonText: t('general.okay', 'Okay'),
            })
          }
        }
      })
    },
  })
  return mutation
}
