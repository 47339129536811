import { useTranslation } from 'react-i18next'

import StockTakeCountStatus from '../../components/StockTakeCountStatus'
import StockTakeStatus from '../../components/StockTakeStatus'

import { StockLocation } from '@/graphql/inventory/generated/inventory_graphql'
import { Fallback } from '@/modules/shared/components'
import { TableRowDouble, TableRowSingle } from '@/modules/shared/components/page-header-details-table'
import { Dates, StocktakeCountState, StocktakeState } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface StocktakeDetailsProps {
  stockLocation: StockLocation
}

export default function StocktakeDetails({ stockLocation }: StocktakeDetailsProps) {
  const { t } = useTranslation()

  const { name, stockTake } = stockLocation || {}
  const { closedAt, periodEndingOn, stockTakeStatus, blind, createdAt, id: stockTakeId } = stockTake || {}

  return (
    <section className="flex items-center justify-between rounded-md  bg-white p-4 text-sm shadow">
      <div className="w-full divide-y divide-gray-200 overflow-auto rounded-md border border-gray-300">
        <TableRowSingle
          titleText={t('general.stocktakePeriod', 'Stocktake Period')}
          tooltipText={t(
            'stocktake.details.stockTakePeriodTooltip',
            'The calendar year and month that the stocktake belongs to.'
          )}
          testId="period"
          value={<Fallback condition={!!periodEndingOn}>{formatDate(Dates.MonthYear, periodEndingOn)}</Fallback>}
        />
        <TableRowSingle
          titleText={t('general.stockLocation', 'Stock Location')}
          tooltipText={t(
            'stocktake.details.stockLocationTooltip',
            'The Stock Location where the stocktake is being undertaken.'
          )}
          testId="stockLocation"
          value={<Fallback condition={!!name}>{name}</Fallback>}
        />
        <TableRowSingle
          titleText={t('general.status', 'Status')}
          tooltipText={t(
            'stocktake.details.statusTooltip',
            'The current status of the Stocktake. The Stocktake Status will be In Progress if the Stocktake is ongoing, or Closed if the Stocktake has been finalised.'
          )}
          testId="status"
          value={<StockTakeStatus state={closedAt ? StocktakeState.Closed : StocktakeState.InProgress} />}
        />
        <TableRowSingle
          titleText={t('general.countStatus', 'Count Status')}
          tooltipText={t(
            'stocktake.details.countStatusTooltip',
            'The Count Status indicates if none, some, or all of the Stock Items in the Stock Location have had a count recorded against them.'
          )}
          testId="countStatus"
          value={<StockTakeCountStatus state={stockTakeStatus as StocktakeCountState} />}
        />
        <TableRowSingle
          titleText={t('general.blind', 'Blind')}
          tooltipText={t(
            'stocktake.details.blindTooltip',
            'If the Stocktake is Blind, then some users will not see the expected count or the variance when the Stocktake is In Progress.'
          )}
          testId="blind"
          value={blind ? t('general.yes', 'Yes') : t('general.no', 'No')}
        />
        <TableRowDouble
          leftTitle={t('general.startedAt', 'Started At')}
          leftTooltip={t('stocktake.details.startedAtTooltip', 'The time and date that the Stocktake was started.')}
          leftTestId="startedAt"
          leftValue={<Fallback condition={!!createdAt}>{formatDate(Dates.Long, createdAt)}</Fallback>}
          rightTitle={t('general.startedBy', 'Started By')}
          rightTooltip={t('stocktake.details.startedByTooltip', 'The user who started the Stocktake.')}
          rightTestId="startedBy"
          rightValue="--"
        />
        <TableRowDouble
          leftTitle={t('general.closedAt', 'Closed At')}
          leftTooltip={t(
            'stocktake.details.closedAtTooltip',
            'If the Stocktake is Closed, the time and date that the Stocktake was closed.'
          )}
          leftTestId="closedAt"
          leftValue={<Fallback condition={closedAt}>{formatDate(Dates.Long, closedAt)}</Fallback>}
          rightTitle={t('general.closedBy', 'Closed By')}
          rightTooltip={t(
            'stocktake.details.closedByTooltip',
            'If the Stocktake is Closed, the user who closed the Stocktake.'
          )}
          rightTestId="closedBy"
          rightValue="--"
        />
        <TableRowSingle
          titleText={t('general.totalVariance', 'Total Variance')}
          tooltipText={t(
            'stocktake.details.totalVarianceTooltip',
            'If the Stocktake is In Progress, the total value of the total variance will appear here.'
          )}
          testId="totalVariance"
          value="--"
        />
        <TableRowSingle
          titleText={t('general.stocktakeId', 'Stocktake ID')}
          tooltipText={t(
            'stocktake.details.stockTakeIDTooltip',
            'The unique identifier for this Stocktake in the PurchasePlus system.'
          )}
          testId="stocktakeID"
          value={stockTakeId}
        />
      </div>
    </section>
  )
}
