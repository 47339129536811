import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { SubmitRequisitionDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ConfirmationVariant, REQUISITION_STATUS } from '@/modules/requisitions/types'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useAlert } from '@/modules/shared/hooks'

export default function useSubmitRequisition() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { alertDialog } = useAlert()
  const mutation = useMutation(SubmitRequisitionDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    onCompleted(data) {
      const requisitionId = String(data.submitRequisition.requisition?.id)
      const requisitionPageStatus =
        data.submitRequisition.requisition?.state === REQUISITION_STATUS.AWAITING_APPROVAL
          ? ConfirmationVariant.AwaitingApproval
          : ConfirmationVariant.AutoApproved
      navigate(
        generatePath('/requisitions/:requisitionId', {
          requisitionId,
        }),
        { state: { requisitionPageStatus } }
      )
    },
    onError({ graphQLErrors }) {
      graphQLErrors?.forEach((e) => {
        if (e.extensions.code === 400 && e.message === 'Submit is not available from the state Awaiting Approval') {
          alertDialog({
            type: 'error',
            title: t('general.alreadySubmitted', 'Already Submitted'),
            message: t(
              'alert.error.submitAction.message',
              'This Purchase Requisition has already been submitted by you or someone else.'
            ),
            buttonText: t('general.okay', 'Okay'),
          })
        }
      })
    },
  })
  return mutation
}
