import { graphql } from 'msw'

import { GetCreditNoteQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetCreditNote: GetCreditNoteQuery = {
  currentPurchaser: {
    id: 44926,
    __typename: 'Purchaser',
    creditNote: {
      id: 813,
      creditNoteNumber: '12314',
      workflowState: 'reconciled',
      totalValue: 42.35,
      totalTaxValue: 3.85,
      deliveryChargeExTax: 0,
      deliveryChargeTax: 0,
      adjustmentExTax: 0,
      adjustmentTax: 0,
      commentsCount: 2,
      __typename: 'CreditNote',
      supplier: {
        id: 15065,
        name: 'Sydney Wholesale Co',
        billingAddressCity: 'Sydney',
        billingAddressCountry: 'AU',
        billingAddressLine1: '100 Miller Street',
        billingAddressLine2: '',
        billingAddressPostalCode: '2000',
        billingAddressStateProvince: 'NSW',
        email: 'testsupplier03@marketboomer.com',
        telephone: '02 8456 0500',
        deliveryChargeConfiguration: {
          minimumOrderValueExTax: 100,
          deliveryChargeExTax: 30,
          deliveryChargeIncTax: 33,
          deliveryChargeTaxPercentage: 10,
          __typename: 'DeliveryChargeConfiguration',
        },
        __typename: 'Supplier',
      },
      creditNoteLines: {
        edges: [
          {
            node: {
              id: 1928,
              quantity: 1,
              unitPrice: 38.5,
              taxPercentage: 10,
              lineTotal: 42.35,
              lineTax: 3.85,
              __typename: 'CreditNoteLine',
              product: {
                id: 3071,
                brand: 'Tooheys - New',
                itemDescription: 'Pale Lager : (6 Pack) [Australian]',
                concatenatedSellUnit: 'carton of 24',
                itemPackName: 'bottle',
                itemMeasure: 'ml',
                itemSellPackName: 'carton',
                itemSize: 375,
                __typename: 'Product',
              },
            },
            __typename: 'CreditNoteLineEdge',
          },
        ],
        __typename: 'CreditNoteLineConnection',
      },
    },
  },
}

export const GetCreditNote = graphql.query<GetCreditNoteQuery>('GetCreditNote', (_, res, ctx) => {
  return res(ctx.data(baseGetCreditNote))
})

export default baseGetCreditNote
