import classNames from 'classnames'
import { Dispatch, SetStateAction, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InvoiceLineItem, ReceivingDocumentLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ReceivingDocumentLineItemInput } from '@/modules/purchase-orders/types'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks'
import { Checkbox, Tooltip } from '@/modules/shared/components'
import NumberInput from '@/modules/shared/components/number-input'
import { useMoney } from '@/modules/shared/hooks'
import { QuestionMarkIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

interface ReceivingUnitPriceProps {
  receivingDocumentLineItem: ReceivingDocumentLineItem
  setInputFocus: Dispatch<SetStateAction<boolean>>
  inputFocus: boolean
  isReadOnly: boolean
}

export default function ReceivingUnitPrice({
  receivingDocumentLineItem,
  setInputFocus,
  isReadOnly,
}: ReceivingUnitPriceProps) {
  const { purchaseOrderLineItem } = receivingDocumentLineItem
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [overridedPrice, setOverridedPrice] = useState(!!receivingDocumentLineItem.overrideUnitValue)
  const { currentPurchaser } = useCurrentPurchaser()
  const { control, reset } = useFormContext<ReceivingDocumentLineItemInput>()

  const linkedInvoiceLines = extractEdges<InvoiceLineItem>(purchaseOrderLineItem?.invoiceLineItems)
  const hasInvoiceLines = linkedInvoiceLines.length > 0

  // TODO: Temporarily solution to get the latest invoice line item.
  const latestInvoiceLineItem = linkedInvoiceLines.reduce((latest, current) => {
    if (new Date(current.createdAt) < new Date(latest.createdAt)) {
      return current
    }
    return latest
  }, linkedInvoiceLines[0])
  const receivingUnitPrice = receivingDocumentLineItem.unitPrice
  const poUnitPrice = purchaseOrderLineItem?.unitPrice || 0
  const latestInvoiceLineUnitPrice = Number(latestInvoiceLineItem?.unitPrice || 0)
  const variance = hasInvoiceLines ? poUnitPrice - latestInvoiceLineUnitPrice : 0

  return (
    <section>
      <strong className="text-sm">{t('general.receivingUnitPrice', 'Receiving Unit Price')}</strong>
      <p className="text-sm text-gray-500">
        {t(
          'receivingNotes.advancedModal.receivingUnitPriceDesc',
          'Here you can view and manage the Unit Price that will be recorded when this Receiving Note is submitted. If the Purchase Order Line has been linked to one or more Invoice Lines, the Unit Price from the most recent Invoice will be shown here also. The latest Invoice Unit Price will be used to set the Receiving Unit Price.  If needed, you can override the Receiving Unit Price using the option below.'
        )}
      </p>
      <div className="mt-4 space-y-4" data-testid="receiving-unit-price-info">
        <div className="flex flex-col divide-y border">
          <div className="flex items-center justify-between px-4 py-2.5 text-sm">
            <p className="flex items-center gap-x-1">
              {t('receivingNotes.advancedModal.purchaseOrderUnitPrice', 'Purchase Order Unit Price')}
              <Tooltip
                content={t(
                  'receivingNotes.advancedModal.poUnitPriceTooltip',
                  'The Unit Price as shown on the Purchase Order.'
                )}
              >
                <QuestionMarkIcon className="h-4 w-4" />
              </Tooltip>
            </p>
            <div className="flex w-32 items-center justify-end rounded-md border bg-gray-200 p-3">
              {formatMoney(poUnitPrice)}
            </div>
          </div>
          <div className="flex items-center justify-between px-4 py-2.5 text-sm">
            <p className="flex items-center gap-x-1">
              {t('general.invoiceUnitPrice', 'Invoice Unit Price')}
              <Tooltip
                content={t(
                  'receivingNotes.advancedModal.invoiceUnitPriceTooltip',
                  'The Unit Price as shown on the latest Invoice, if any.'
                )}
              >
                <QuestionMarkIcon className="h-4 w-4" />
              </Tooltip>
            </p>
            <div className="flex w-32 items-center justify-end rounded-md border bg-gray-200 p-3">
              {hasInvoiceLines ? formatMoney(latestInvoiceLineUnitPrice) : '--'}
            </div>
          </div>
          <div className="flex items-center justify-between px-4 py-2.5 text-sm">
            <p className="flex items-center gap-x-1">
              {t('general.variance', 'Variance')}
              <Tooltip
                content={t(
                  'receivingNotes.advancedModal.varianceTooltip',
                  'The difference between the Purchase Order Unit Price and the Invoice Unit Price, if any.'
                )}
              >
                <QuestionMarkIcon className="h-4 w-4" />
              </Tooltip>
            </p>
            <div
              className={classNames('flex w-32 items-center justify-end rounded-md border p-3', {
                'border-error bg-error/20 text-error': variance !== 0,
                'bg-gray-200': variance === 0,
              })}
            >
              {variance !== 0 ? formatMoney(variance) : '--'}
            </div>
          </div>
        </div>
        <div className="space-y-4">
          <Checkbox
            dataTestId="override-unit-price-checkbox"
            defaultSelected={overridedPrice}
            disabled={isReadOnly}
            onChange={(e) => {
              if (!e) reset()
              setOverridedPrice(e)
            }}
          >
            {t('general.overrideUnitPrice', 'Override Unit Price')}
            <Tooltip
              content={t(
                'receivingNotes.advancedModal.overrideUnitPriceTooltip',
                'If required, check this box to manually override the Receiving Unit Price.'
              )}
            >
              <QuestionMarkIcon className="h-4 w-4" />
            </Tooltip>
          </Checkbox>
          <div className="flex items-center justify-between border px-4 py-2.5 text-sm">
            <p className="flex items-center gap-x-1">
              {t('general.receivingUnitPrice', 'Receiving Unit Price')}
              <Tooltip
                content={t(
                  'receivingNotes.advancedModal.receivingUnitPriceTooltip',
                  'This is the Unit Price that will be recorded on this Receiving Note. It will be the Purchase Order Unit Price, or it will be the Invoice Unit Price if an Invoice Line is already linked. You can also override the Unit Price manually if needed.'
                )}
              >
                <QuestionMarkIcon className="h-4 w-4" />
              </Tooltip>
            </p>
            <Controller
              control={control}
              name="overrideUnitValue"
              defaultValue={receivingUnitPrice || 0}
              render={({ field }) => (
                <NumberInput
                  data-testid="override-unit-price-input"
                  aria-label={t('general.overrideUnitPrice', 'Override Unit Price')}
                  isDisabled={!overridedPrice || isReadOnly}
                  className="w-32 rounded-md border border-gray-300  p-3 text-right"
                  value={field.value}
                  onChange={(e) => field.onChange(e)}
                  onFocus={() => setInputFocus(true)}
                  formatOptions={{
                    style: 'currency',
                    currency: currentPurchaser?.currency || 'USD',
                    currencyDisplay: 'symbol',
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
