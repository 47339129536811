import { useState } from 'react'

import InventoryModal from '../pages/purchase-order/receiving-notes/InventoryModal'

import { useSession } from '@/modules/access/hooks'
import { useCurrentOrganisation, useCurrentPurchaser } from '@/modules/purchasing/hooks'
import { useReceivingDocumentMutations } from '@/modules/receiving-notes/hooks'

export default function useCreateReceivingDocument() {
  const [openInventoryModal, setOpenInventoryModal] = useState(false)
  const { createReceivingDocument, createReceivingDocumentLoading } = useReceivingDocumentMutations()
  const { currentUser } = useSession()
  const { currentOrganisation } = useCurrentOrganisation()
  const { currentPurchaser } = useCurrentPurchaser()

  const onCreateReceivingDocument = (purchaseOrderId?: string) => {
    if (currentPurchaser?.isInventoryEnabled) {
      setOpenInventoryModal(true)
    } else {
      createReceivingDocument({
        variables: {
          input: {
            purchaseOrderId: Number(purchaseOrderId),
            receiverId: currentUser?.id,
            ownerOrganisationId: currentOrganisation?.id,
          },
        },
      })
    }
  }

  const modalProps = {
    showModal: openInventoryModal,
    setShowModal: setOpenInventoryModal,
  }

  return { onCreateReceivingDocument, InventoryModal, modalProps, createReceivingDocumentLoading }
}
