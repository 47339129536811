import { graphql } from 'msw'

import {
  GetPurchaseOrderLineItemQuery,
  PurchaseOrderLineItemInvoicedStatusEnum,
  PurchaseOrderLineItemReceivedStatusEnum,
} from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetPurchaseOrderLineItem: GetPurchaseOrderLineItemQuery = {
  currentPurchaser: {
    id: 44926,
    purchaseOrder: {
      id: 16566710,
      purchaseOrderLineItem: {
        id: 33124802,
        image: null,
        unitPrice: 128.83,
        taxPercentage: 10,
        invoicedStatus: PurchaseOrderLineItemInvoicedStatusEnum.FullyInvoiced,
        receivedStatus: PurchaseOrderLineItemReceivedStatusEnum.FullyReceived,
        receivedQuantity: 1,
        quantity: 1,
        product: {
          id: 62239,
          brand: 'Herrradura',
          itemDescription: 'Agave Nectar',
          itemSize: 478,
          itemMeasure: 'ml',
          itemPackName: 'bottle',
          concatenatedSellUnit: 'carton of 12',
          __typename: 'Product',
        },
        invoiceLineItems: {
          edges: [
            {
              node: {
                id: 3243105,
                quantity: 1,
                unitPrice: '128.83',
                taxPercentage: 10,
                invoicePriceVariance: 0,
                invoice: {
                  id: 884359,
                  invoiceNumber: '6783264327',
                  __typename: 'Invoice',
                },
                __typename: 'InvoiceLineItem',
              },
              __typename: 'InvoiceLineItemEdge',
            },
          ],
        },
        receivingDocumentLineItems: {
          nodes: [
            {
              id: 3263701,
              receivingDocument: {
                id: 927318,
                receivingDocumentNumber: 'GR00659',
                receivedDate: '2016-12-08T12:55:28+11:00',
                workflowState: 'received',
                transfer: null,
                receiver: {
                  id: 3,
                  fullName: 'Vidhya Natarajan',
                  __typename: 'User',
                },
              },
              quantity: 1,
              receivedQuantity: 1,
              unitPrice: 0,
              totalValue: 0,
              receivedToInventory: true,
              stockLocation: {
                id: '399',
                name: 'Main Beverage Store',
                __typename: 'StockLocation',
              },
            },
          ],
        },
      },
    },
  },
}

export const GetPurchaseOrderLineItem = graphql.query<GetPurchaseOrderLineItemQuery>(
  'GetPurchaseOrderLineItem',
  (_, res, ctx) => {
    return res(ctx.data(baseGetPurchaseOrderLineItem))
  }
)

export default baseGetPurchaseOrderLineItem
