import { graphql } from 'msw'

import { GetRequisitionsAwaitingApprovalCountQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetRequisitionsAwaitingApprovalCount: GetRequisitionsAwaitingApprovalCountQuery = {
  currentPurchaser: {
    id: 44926,
    __typename: 'Purchaser',
    requisitionsAwaitingMyApproval: {
      edges: [
        {
          node: {
            id: 5273351,
            __typename: 'Requisition',
          },
          __typename: 'RequisitionEdge',
        },
        {
          node: {
            id: 5273326,
            __typename: 'Requisition',
          },
          __typename: 'RequisitionEdge',
        },
        {
          node: {
            id: 5273357,
            __typename: 'Requisition',
          },
          __typename: 'RequisitionEdge',
        },
        {
          node: {
            id: 5273328,
            __typename: 'Requisition',
          },
          __typename: 'RequisitionEdge',
        },
      ],
      __typename: 'RequisitionConnection',
    },
  },
}

export const GetRequisitionsAwaitingApprovalCount = graphql.query<GetRequisitionsAwaitingApprovalCountQuery>(
  'GetRequisitionsAwaitingApprovalCount',
  (req, res, ctx) => {
    return res(ctx.data(baseGetRequisitionsAwaitingApprovalCount))
  }
)

export default baseGetRequisitionsAwaitingApprovalCount
