import classNames from 'classnames'
import { useState } from 'react'

import ProductModal from '../../product-modal/ProductModal'

import NoImage from '@/assets/images/no-image.svg'
import { RequisitionLine, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'

interface ProductImageProps {
  image: string
  clickToOpenModal?: boolean
  line?: RequisitionLine
  supplier?: Supplier
}

export const ProductImage = ({ image, clickToOpenModal, line, supplier }: ProductImageProps) => {
  const [showProductDetailsModal, setShowProductDetailsModal] = useState<boolean>(false)
  return (
    <>
      <div
        className={classNames('h-[3.75rem] w-[3.75rem] border border-gray-200', { 'cursor-pointer': clickToOpenModal })}
        onClick={() => clickToOpenModal && setShowProductDetailsModal(true)}
      >
        <img src={image || NoImage} alt="product" />
      </div>
      {line && (
        <ProductModal
          showModal={showProductDetailsModal}
          setShowModal={setShowProductDetailsModal}
          line={line}
          supplier={supplier as Supplier}
        />
      )}
    </>
  )
}
