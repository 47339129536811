import { useTranslation } from 'react-i18next'

import { CreditNoteLine, Product, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import Table from '@/modules/shared/components/table/Table'
import { ProductItem, UnitPrice } from '@/modules/shared/components/table/table-column-items'
import { useMoney } from '@/modules/shared/hooks'

interface CreditNoteLinesTableViewProps {
  creditNoteLines: CreditNoteLine[]
  supplier: Supplier
}

export default function CreditNoteLinesTableView({ creditNoteLines, supplier }: CreditNoteLinesTableViewProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const renderQuantity = ({ quantity }: CreditNoteLine) => {
    return (
      <p
        data-testid="credit-note-line-quantity"
        className="m-auto w-fit min-w-[4rem] rounded-md border border-gray-300 bg-gray-200 px-4 py-3 text-center"
      >
        {quantity}
      </p>
    )
  }

  const renderUnitPrice = ({ unitPrice }: CreditNoteLine) => {
    return (
      <div className="flex items-center justify-end">
        <p
          data-testid="credit-note-line-price"
          className="w-fit min-w-[6rem] rounded-md border border-gray-300 bg-gray-200 py-3 pl-4 pr-2 text-right"
        >{`${formatMoney(unitPrice)}`}</p>
      </div>
    )
  }

  const renderTax = ({ taxPercentage }: CreditNoteLine) => {
    return (
      <>
        <p
          data-testid="credit-note-line-tax"
          className="m-auto w-fit min-w-[4rem] rounded-md border border-gray-300 bg-gray-200 px-4 py-3 text-center"
        >
          {`${taxPercentage}%`}
        </p>
      </>
    )
  }
  return (
    <Table
      dataTestId="credit-note-lines-table"
      dataSource={creditNoteLines}
      keyExtractor={(record) => String(record.id)}
      columns={[
        {
          title: t('general.product', 'Product'),
          key: 'product',
          headerCellStyles: 'pl-4 py-3',
          cellStyles: 'pl-4 py-3',
          minWidth: 13.75,
          grow: true,
          render: (record) => (
            <ProductItem product={record.product as Product} clickToOpenModal supplier={supplier} line={record} />
          ),
        },
        {
          title: t('general.quantity', 'Quantity'),
          key: 'quantity',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 5.1,
          maxWidth: 5.1,
          render: renderQuantity,
        },
        {
          title: t('general.unitPrice', 'Unit Price'),
          key: 'unitPrice',
          headerCellStyles: 'py-3 pr-2 text-right',
          cellStyles: 'py-3 pr-2 text-right',
          minWidth: 5.625,
          maxWidth: 7.5,
          render: renderUnitPrice,
        },
        {
          title: t('general.tax', 'Tax'),
          key: 'tax',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: renderTax,
        },
        {
          title: t('general.total', 'Total'),
          key: 'total',
          headerCellStyles: 'py-3 pr-5 text-right',
          cellStyles: 'py-3 pr-5 text-right',
          minWidth: 6.25,
          maxWidth: 8.125,
          render: ({ lineTotal }) => <UnitPrice unitPrice={lineTotal} />,
        },
      ]}
    />
  )
}
