import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { CreateInvoiceDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useAlert } from '@/modules/shared/hooks'

export default function useCreateInvoice() {
  const navigate = useNavigate()
  const { alertDialog } = useAlert()
  const { t } = useTranslation()

  const mutation = useMutation(CreateInvoiceDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    onCompleted: (data) => {
      if (!!data.createInvoice.errors?.length) {
        alertDialog({
          type: 'error',
          title: t('createInvoice.error.title', 'Invoice Number Already In Use'),
          message: t(
            'createInvoice.error.description',
            'The Invoice Number you have entered is already in use for the Supplier you have selected. Please update the Invoice Number so that it is unique for the selected Supplier.'
          ),
          buttonText: t('general.okayGotIt', 'Okay, Got It'),
        })
      } else {
        navigate(
          generatePath('/invoices/:invoiceId/summary', {
            invoiceId: String(data?.createInvoice?.invoice?.id),
          })
        )
      }
    },
  })

  return mutation
}
