import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import InvoiceStatus from '../../all-invoices/InvoiceStatus'

import { CreditNote, PageInfo } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback, MoreOptionsMenu, PPNewDetailLink } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { AccountCodeItem, Tax } from '@/modules/shared/components/table/table-column-items'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface AllCreditNotesTableViewProps {
  creditNotes: CreditNote[]
  paginationLoading: boolean
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'endCursor'>
  onNextPage: () => void
  onPreviousPage: () => void
}

function AllCreditNotesTableView({
  creditNotes,
  pageInfo: { hasNextPage, hasPreviousPage, endCursor },
  onNextPage,
  onPreviousPage,
  paginationLoading,
}: AllCreditNotesTableViewProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  return (
    <Table
      dataTestId="credit-notes-table-view"
      dataSource={creditNotes}
      keyExtractor={(record) => String(record.id)}
      pagination={{
        hasNextPage,
        hasPreviousPage,
        onNextPage: onNextPage,
        onPreviousPage: onPreviousPage,
        loading: paginationLoading,
        endCursor: endCursor,
      }}
      columns={[
        {
          title: t('general.creditNote', 'Credit Note'),
          key: 'creditNote',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 8.125,
          maxWidth: 11.25,
          render: (record) => (
            <PPNewDetailLink type="credit-note" id={String(record.id)}>
              {record.creditNoteNumber}
            </PPNewDetailLink>
          ),
        },
        {
          title: t('general.reference', 'Reference'),
          key: 'reference',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 11.25,
          maxWidth: 13.75,
          render: (record) => <Fallback condition={!!record.reference}>{record.reference}</Fallback>,
        },
        {
          title: t('general.invoice', 'Invoice'),
          key: 'invoice',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 8.125,
          maxWidth: 8.125,
          render: (record) => (
            <Fallback condition={!!record.invoice}>
              <PPNewDetailLink type="invoice" id={String(record.invoice?.id)}>
                {record.invoice?.invoiceNumber}
              </PPNewDetailLink>
            </Fallback>
          ),
        },
        {
          title: t('general.supplier', 'Supplier'),
          key: 'supplier',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 12.5,
          maxWidth: 15,
          render: (record) => <p>{record.supplier?.name}</p>,
        },
        {
          title: t('general.accountCode', 'Account Code'),
          key: 'accountCode',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 15,
          grow: true,
          render: ({ department, account }) => <AccountCodeItem department={department} account={account} />,
        },
        {
          title: t('general.status', 'Status'),
          key: 'status',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 7.5,
          maxWidth: 7.5,
          render: (record) => <InvoiceStatus state={String(record.workflowState)} />,
        },
        {
          title: t('general.creditNoteDate', 'Credit Note Date'),
          key: 'invoiceDate',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 7.5,
          maxWidth: 7.5,
          render: (record) => <p>{formatDate(Dates.ShortOrdinal, record.creditNoteDate)}</p>,
        },
        {
          title: t('general.tax', 'Tax'),
          key: 'tax',
          headerCellStyles: 'px-1 py-3 text-right',
          cellStyles: 'px-1 py-3 text-right',
          minWidth: 5.625,
          maxWidth: 8.125,
          render: ({ totalTaxValue }) => <Tax taxInclusiveTotal={totalTaxValue} />,
        },
        {
          title: t('general.total', 'Total'),
          key: 'total',
          headerCellStyles: 'px-1 py-3 text-right',
          cellStyles: 'px-1 py-3 text-right',
          minWidth: 6.875,
          maxWidth: 9.375,
          render: (record) => (
            <>
              <p className="text-base font-bold">{formatMoney(record.totalValue)}</p>
              <p className="text-xs text-gray-600">{t('general.taxInclusive', 'Tax Inclusive')}</p>
            </>
          ),
        },
        {
          title: t('general.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: (record) => (
            <MoreOptionsMenu showHelp showOpenInPPlus creditNoteId={record.id}>
              <OptionIcon className="h-10 w-10 text-gray-500" />
            </MoreOptionsMenu>
          ),
        },
      ]}
    />
  )
}

export default memo(AllCreditNotesTableView)
