import { useTranslation } from 'react-i18next'

import TileView from './TileView'
import TableView from './table-view'

import EmptyInvoices from '@/assets/images/empty-invoices.svg'
import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import EmptyState from '@/modules/shared/components/empty-state'
import { useWindowSize } from '@/modules/shared/hooks'

interface InvoiceTableProps {
  invoices: Invoice[]
  loading: boolean
}

export default function InvoiceTable({ invoices, loading }: InvoiceTableProps) {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()

  return (
    <section className="mt-4 flex flex-col gap-y-3">
      {isLargeScreen ? <TableView invoices={invoices} /> : <TileView invoices={invoices} />}
      {!loading && invoices.length === 0 && (
        <EmptyState
          img={EmptyInvoices}
          message={t('invoices.allInvoices.noInvoices', "We couldn't find any Invoices to display.")}
        />
      )}
    </section>
  )
}
