import { graphql } from 'msw'

import { DeleteInvoiceLineItemMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const DeleteInvoiceLineItem = graphql.mutation<DeleteInvoiceLineItemMutation>(
  'DeleteInvoiceLineItem',
  (req, res, ctx) => {
    return res(
      ctx.data({
        deleteInvoiceLineItem: {
          success: 'Resource was deleted successfully',
          __typename: 'DeleteInvoiceLineItemMutationPayload',
        },
      })
    )
  }
)
