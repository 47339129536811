import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { PageInfo, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useApproveRequisition } from '@/modules/requisitions/hooks'
import { Button, Fallback, MoreOptionsMenu, PPNewDetailLink, Tooltip } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { AccountCodeItem, ShortDateItem, TotalTaxInclusive } from '@/modules/shared/components/table/table-column-items'
import { CheckAltIcon, CloseIcon, OptionIcon } from '@/modules/shared/icons'
import { Loading } from '@/modules/shared/icons-special'

interface RequisitionsAwaitingApprovalTableViewProps {
  approvalList: Requisition[]
  paginationLoading: boolean
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'endCursor'>
  onNextPage: () => void
  onPreviousPage: () => void
}

function RequisitionsAwaitingApprovalTableView({
  approvalList,
  paginationLoading,
  pageInfo,
  onNextPage,
  onPreviousPage,
}: RequisitionsAwaitingApprovalTableViewProps) {
  const { hasNextPage, hasPreviousPage, endCursor } = pageInfo || {}
  const { t } = useTranslation()
  const [actionLoadingById, setActionloadingById] = useState<number | null>(null)

  const [approveRequisition, { loading: actionLoading }] = useApproveRequisition()

  const onApprove = (requisitionId: number) => {
    setActionloadingById(requisitionId)
    approveRequisition({
      variables: {
        input: {
          id: requisitionId,
        },
      },
    })
  }

  const renderRequisition = ({ id, requisitionNumber }: Requisition) => {
    return (
      <PPNewDetailLink type="requisition" id={String(id)}>
        {requisitionNumber}
      </PPNewDetailLink>
    )
  }

  const renderReference = ({ reference }: Requisition) => {
    return <Fallback condition={!!reference}>{reference}</Fallback>
  }

  const renderCreator = ({ creator }: Requisition) => {
    return <p>{creator?.fullName}</p>
  }

  const renderType = ({ originName }: Requisition) => {
    return <Fallback condition={!!originName}>{originName}</Fallback>
  }

  const renderSendPO = ({ sendToSupplier }: Requisition) => {
    return <p>{sendToSupplier ? t('general.yes', 'Yes') : t('general.no', 'No')}</p>
  }

  const renderActions = ({ id }: Requisition) => {
    const loading = actionLoading && actionLoadingById === id
    return (
      <div className="flex items-center justify-center gap-x-1.5">
        <Tooltip content={t('general.clickToDecline', 'Click to Decline')}>
          <Link
            data-testid="decline-button"
            to={generatePath('/requisitions/:id/decline', {
              id: String(id),
            })}
            className="flex h-10 w-10 items-center justify-center rounded-full bg-error text-white transition hover:brightness-90"
          >
            <CloseIcon className="h-6 w-6" />
          </Link>
        </Tooltip>
        <Tooltip content={t('general.clickToApprove', 'Click to Approve')}>
          <Button
            data-testid="approve-button"
            disabled={loading}
            className="flex h-10 w-10 items-center justify-center rounded-full bg-success text-white"
            onClick={() => onApprove(id)}
          >
            {loading ? <Loading className="h-6 w-6 fill-white text-gray-300" /> : <CheckAltIcon className="h-5 w-5" />}
          </Button>
        </Tooltip>
      </div>
    )
  }

  const renderMoreOptions = ({ id }: Requisition) => {
    return (
      <MoreOptionsMenu showOpenInPPlus showPrint showHelp showViewRequisition requisitionId={id}>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </MoreOptionsMenu>
    )
  }

  return (
    <Table
      dataTestId="requisition-table-view"
      dataSource={approvalList}
      keyExtractor={(record) => String(record.id)}
      pagination={{
        hasNextPage: hasNextPage,
        hasPreviousPage: hasPreviousPage,
        onNextPage: onNextPage,
        onPreviousPage: onPreviousPage,
        loading: paginationLoading,
        endCursor: endCursor,
      }}
      columns={[
        {
          title: t('general.requisition', 'Requisition'),
          key: 'requisition',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3 ',
          minWidth: 8.125,
          maxWidth: 8.125,
          render: renderRequisition,
        },
        {
          title: t('general.reference', 'Reference'),
          key: 'reference',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3 ',
          minWidth: 11.25,
          maxWidth: 13.75,
          render: renderReference,
        },
        {
          title: t('general.requestor', 'Requestor'),
          key: 'requestor',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 12.5,
          maxWidth: 12.5,
          render: renderCreator,
        },
        {
          title: t('general.type', 'Type'),
          key: 'type',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3 ',
          minWidth: 13.75,
          maxWidth: 15,
          render: renderType,
        },
        {
          title: t('general.accountCode', 'Account Code'),
          key: 'account',
          headerCellStyles: 'px-2 py-3',
          cellStyles: ' px-2 py-3 ',
          grow: true,
          minWidth: 15,
          render: ({ department, account }) => <AccountCodeItem department={department} account={account} />,
        },
        {
          title: t('general.deliveryDate', 'Delivery Date'),
          key: 'deliveryDate',
          headerCellStyles: 'px-1 py-3 text-center',
          cellStyles: 'px-1 py-3 text-center',
          minWidth: 7.5,
          maxWidth: 7.5,
          render: ({ expectedDeliveryDate }) => <ShortDateItem date={expectedDeliveryDate} />,
        },
        {
          title: t('general.sendPo', 'Send PO'),
          key: 'sendPO',
          headerCellStyles: 'px-1 py-3 text-center',
          cellStyles: 'px-1 py-3 text-center',
          minWidth: 5,
          maxWidth: 5,
          render: renderSendPO,
        },
        {
          title: t('general.totalValue', 'Total Value'),
          key: 'totalValue',
          headerCellStyles: 'p-3 text-right',
          cellStyles: 'p-3 text-right',
          minWidth: 6.875,
          maxWidth: 9.375,
          render: ({ totalValue }) => <TotalTaxInclusive totalValue={totalValue} />,
        },
        {
          title: t('general.actions', 'Actions'),
          key: 'actions',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 6.875,
          maxWidth: 6.875,
          render: renderActions,
        },
        {
          title: t('general.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: renderMoreOptions,
        },
      ]}
    />
  )
}

export default memo(RequisitionsAwaitingApprovalTableView)
