import { QuestionMarkIcon } from '../../icons'

import { Fallback, Tooltip } from '@/modules/shared/components'

export interface DetailsPanelItemProps {
  title: string
  value?: string | number | null
  description?: string
}

const DetailsPanelItem = ({ title, value, description }: DetailsPanelItemProps) => (
  <div className="flex items-center bg-gray-100 p-3 odd:bg-gray-100 even:bg-white">
    <div className="flex w-4/5 items-center gap-1 break-all sm:w-1/3">
      <strong className="text-sm">{title}</strong>
      {description && (
        <Tooltip content={description}>
          <div>
            <QuestionMarkIcon className="h-4 w-4" />
          </div>
        </Tooltip>
      )}
    </div>
    <div className="w-3/5 break-all sm:w-2/3">
      <Fallback as="p" className="text-sm" condition={!!value}>
        {value}
      </Fallback>
    </div>
  </div>
)

export default DetailsPanelItem
