import { graphql } from 'msw'

import {
  MatchPurchaseOrderInvoiceMutation,
  PurchaseOrderInvoicedStatusEnum,
} from '@/graphql/purchasing/generated/purchasing_graphql'

const baseMatchPurchaseOrderInvoice: MatchPurchaseOrderInvoiceMutation = {
  matchPurchaseOrderInvoice: {
    invoice: {
      id: 5697379,
      purchaseOrderId: 16564905,
      purchaseOrder: {
        id: 16564905,
        invoicedStatus: 'PARTLY_INVOICED' as PurchaseOrderInvoicedStatusEnum,
        invoices: {
          edges: [
            {
              node: {
                id: 7524145,
                invoiceNumber: 'INV111',
                totalValue: 0.3,
                __typename: 'Invoice',
              },
              __typename: 'InvoiceEdge',
            },
          ],
          __typename: 'InvoiceConnection',
        },
        __typename: 'PurchaseOrder',
      },
      __typename: 'Invoice',
    },
    __typename: 'MatchPurchaseOrderInvoiceMutationPayload',
  },
}

export const MatchPurchaseOrderInvoice = graphql.mutation<MatchPurchaseOrderInvoiceMutation>(
  'MatchPurchaseOrderInvoice',
  (_, res, ctx) => {
    return res(ctx.data(baseMatchPurchaseOrderInvoice))
  }
)

export default baseMatchPurchaseOrderInvoice
