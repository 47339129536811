import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Catalogue } from '@/graphql/purchasing/generated/purchasing_graphql'
import { MoreOptionsMenu, PPNewDetailLink } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { AccountCodeItem } from '@/modules/shared/components/table/table-column-items'
import { OptionIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TableViewProps {
  buyLists: Catalogue[]
}

function TableView(props: TableViewProps) {
  const { buyLists } = props
  const { t } = useTranslation()

  const renderBuyList = (record: Catalogue) => {
    return (
      <PPNewDetailLink type="buy-list" id={String(record.id)}>
        {record.title}
      </PPNewDetailLink>
    )
  }
  const renderOwner = (record: Catalogue) => {
    return record.owner?.name
  }
  const renderAccountCode = ({ chargingDefinitions }: Catalogue) => {
    const account = chargingDefinitions?.edges![0]?.node?.account
    const department = chargingDefinitions?.edges![0]?.node?.department
    return <AccountCodeItem department={department} account={account} />
  }
  const renderLastUpdated = ({ updatedAt }: Catalogue) => {
    return (
      <>
        <p className="break-words">{formatDate(Dates.Short, updatedAt)}</p>
        <p className="break-words text-xs">{formatDate(Dates.Time, updatedAt)}</p>
      </>
    )
  }
  const renderMoreOptions = ({ id }: Catalogue) => {
    return (
      <MoreOptionsMenu showOpenInPPlus showHelp buyListId={id}>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </MoreOptionsMenu>
    )
  }

  return (
    <Table
      dataTestId="buy-lists-table-view"
      dataSource={buyLists}
      keyExtractor={(record) => String(record.id)}
      columns={[
        {
          title: t('general.buyList', 'Buy List'),
          key: 'buyList',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 14.375,
          maxWidth: 17.5,
          render: renderBuyList,
        },
        {
          title: t('general.owner', 'Owner'),
          key: 'owner',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 11.25,
          maxWidth: 13.75,
          render: renderOwner,
        },
        {
          title: t('general.accountCode', 'Account Code'),
          key: 'accountCode',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 14.375,
          grow: true,
          render: renderAccountCode,
        },
        {
          title: t('general.lastUpdated', 'Last Updated'),
          key: 'latUpdated',
          headerCellStyles: 'px-0 py-3 text-center',
          cellStyles: 'px-0 py-3 text-center',
          minWidth: 7.5,
          maxWidth: 7.5,
          render: renderLastUpdated,
        },
        {
          title: t('general.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: renderMoreOptions,
        },
      ]}
    />
  )
}

export default memo(TableView)
