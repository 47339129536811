import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import PurchaseOrderLineItemInvoicedStatus from '../PurchaseOrderLineItemInvoicedStatus'
import PurchaseOrderLineItemReceivedStatus from '../PurchaseOrderLineItemReceivedStatus'
import POLineInfoModal from './po-line-modal'

import { PurchaseOrder, PurchaseOrderLineItem, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Button } from '@/modules/shared/components'
import ProductModal from '@/modules/shared/components/product-modal/ProductModal'
import ExtraLine from '@/modules/shared/components/table/ExtraLine'
import Table from '@/modules/shared/components/table/Table'
import { useMoney } from '@/modules/shared/hooks'
import { InfoIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface TableViewProps {
  poLines: PurchaseOrderLineItem[]
  supplier: Supplier
  purchaseOrder: PurchaseOrder
}

export default function TableView({ poLines, purchaseOrder, supplier }: TableViewProps) {
  const { t } = useTranslation()
  const [showProductModal, setShowProductModal] = useState(false)
  const [showPOLineModal, setShowPOLineModal] = useState(false)
  const [activeLineId, setActiveLineId] = useState<number | null>(null)
  const { formatMoney } = useMoney()

  const renderProduct = (record: PurchaseOrderLineItem) => {
    const { brand, itemDescription, itemMeasure, itemPackName, itemSize, concatenatedSellUnit } = record.product || {}
    return (
      <>
        <span
          className="cursor-pointer text-primary"
          onClick={() => {
            setActiveLineId(record.id)
            setShowProductModal(true)
          }}
          data-testid="product-details-button"
        >
          {brand} {itemDescription}
        </span>
        <span className="text-xxs text-gray-500 sm:text-xs">
          <p>
            {formatProductItem({
              itemMeasure: itemMeasure,
              itemPackName: itemPackName,
              itemSize: itemSize,
              itemSellUnit: concatenatedSellUnit,
            })}
          </p>
        </span>
        <ProductModal
          line={record}
          showModal={showProductModal && activeLineId === record.id}
          setShowModal={setShowProductModal}
          supplier={supplier}
        />
      </>
    )
  }

  const renderInvoiced = ({ invoicedStatus }: PurchaseOrderLineItem) => {
    return invoicedStatus && <PurchaseOrderLineItemInvoicedStatus status={invoicedStatus} />
  }

  const renderReceived = ({ receivedStatus }: PurchaseOrderLineItem) => {
    return receivedStatus && <PurchaseOrderLineItemReceivedStatus status={receivedStatus} />
  }

  const renderInfo = (record: PurchaseOrderLineItem) => {
    return (
      <>
        <Button
          className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200"
          onClick={() => {
            setActiveLineId(record.id)
            setShowPOLineModal(true)
          }}
        >
          <InfoIcon className="h-6 w-6" />
        </Button>
        <POLineInfoModal
          show={showPOLineModal && activeLineId === record.id}
          setShow={setShowPOLineModal}
          purchaseOrderId={purchaseOrder.id}
          purchaseOrderLineItemId={record.id}
        />
      </>
    )
  }

  const renderExtraLine = ({ orderInstruction, department, account }: PurchaseOrderLineItem) => {
    const sameDeptAndAccount =
      department?.id === purchaseOrder?.department?.id && account?.id === purchaseOrder?.account?.id
    return (
      <>
        {orderInstruction && (
          <ExtraLine
            theme={Themes.Primary}
            dataTestId="order-instruction-block"
            title={t('general.orderInstruction', 'Order Instruction')}
            value={orderInstruction}
          />
        )}
        {!!department && !!account && !sameDeptAndAccount && (
          <ExtraLine
            theme={Themes.Primary}
            dataTestId="dept-account-block"
            title={t('shopPage.cart.summary.table.deptAndAccount', 'Department and Account Code')}
            value={`${department.name} ${account.code}`}
          />
        )}
      </>
    )
  }

  return (
    <Table
      dataTestId="po-product-summary-table"
      dataSource={poLines}
      keyExtractor={(record) => String(record.id)}
      hasExtraLine={({ account, department, orderInstruction }) => {
        const sameDeptAndAccount =
          department?.id === purchaseOrder?.department?.id && account?.id === purchaseOrder?.account?.id
        return !!(orderInstruction || (department && account && !sameDeptAndAccount))
      }}
      renderExtraLine={renderExtraLine}
      columns={[
        {
          title: t('general.product', 'Product'),
          key: 'product',
          headerCellStyles: 'py-3 px-4',
          cellStyles: 'py-3 px-4',
          minWidth: 13.75,
          grow: true,
          render: renderProduct,
        },
        {
          title: t('general.invoiced', 'Invoiced'),
          key: 'invoicedStatus',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 8.125,
          maxWidth: 8.125,
          render: renderInvoiced,
        },
        {
          title: t('general.received', 'Received'),
          key: 'receivedStatus',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 8.125,
          maxWidth: 8.125,
          render: renderReceived,
        },
        {
          title: t('general.receivedQty', 'Received Qty'),
          key: 'receivedQuantity',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 5.625,
          maxWidth: 5.625,
          render: ({ receivedQuantity }: PurchaseOrderLineItem) => <p>{receivedQuantity}</p>,
        },
        {
          title: t('general.orderedQty', 'Ordered Qty'),
          key: 'orderedQuantity',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 5.625,
          maxWidth: 5.625,
          render: ({ quantity }: PurchaseOrderLineItem) => <p>{quantity}</p>,
        },
        {
          title: t('general.info', 'Info'),
          key: 'info',
          headerCellStyles: 'px-4 py-3 text-center',
          cellStyles: 'px-4  py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: renderInfo,
        },
        {
          title: t('general.unitPrice', 'Unit Price'),
          key: 'unitPrice',
          headerCellStyles: 'px-4 py-3 text-right',
          cellStyles: 'px-4 py-3 text-right',
          minWidth: 5.625,
          maxWidth: 8.75,
          render: ({ unitPrice }: PurchaseOrderLineItem) => <p>{formatMoney(unitPrice)}</p>,
        },
        {
          title: t('general.tax', 'Tax'),
          key: 'tax',
          headerCellStyles: 'px-4 py-3 text-right',
          cellStyles: 'px-4 py-3 text-right',
          minWidth: 5,
          maxWidth: 5,
          render: ({ taxPercentage }: PurchaseOrderLineItem) => <p>{`${taxPercentage}%`}</p>,
        },
        {
          title: t('general.total', 'Total'),
          key: 'total',
          headerCellStyles: 'px-4 py-3 text-right',
          cellStyles: 'px-4 py-3 text-right',
          minWidth: 5.625,
          maxWidth: 8.75,
          render: ({ lineTotal }: PurchaseOrderLineItem) => <strong>{`${formatMoney(lineTotal)}`}</strong>,
        },
      ]}
    />
  )
}
