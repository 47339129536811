import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Outlet, generatePath, matchPath, useLocation, useNavigate } from 'react-router-dom'

import InvoiceStatus from '../../all-invoices/InvoiceStatus'
import Details from './Details'
import InvoiceActionConfirmDialog from './InvoiceActionConfirmDialog'
import InvoiceActionSuccessModal from './InvoiceActionSuccessModal'
import InvoiceActions from './InvoiceActions'
import MatchPOToInvoiceModal from './MatchPOToInvoiceModal'
import BalancePanel from './balance-panel'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useGetInvoice, useInvoiceActions } from '@/modules/invoices/hooks'
import { InvoiceActionTypes, InvoiceStatuses } from '@/modules/invoices/types'
import { Breadcrumb, Button, QueryResult, ThemeWrapper, Tooltip } from '@/modules/shared/components'
import { MoreOptionsMenu } from '@/modules/shared/components'
import HeaderTabs, { TabItem } from '@/modules/shared/components/header-tabs'
import { useMoney } from '@/modules/shared/hooks'
import { HomeIcon, InfoIcon, InvoiceIcon, OptionIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'
import { findActiveRoute } from '@/modules/shared/utils'

export default function InvoicePageHeader() {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const navigate = useNavigate()
  const [showInfo, setShowInfo] = useState(false)
  const [showMatchPOModal, setShowMatchPOModal] = useState(false)

  const location = useLocation()

  const {
    invoice,
    invoiceId,
    loading,
    invoiceNumber,
    totalValue,
    state,
    supplier,
    purchaseOrderId,
    commentsCount,
    error,
    flaggingMessages,
  } = useGetInvoice()

  const {
    showConfirmActionDialog,
    setShowConfirmActionDialog,
    showInvoiceActionSuccessModal,
    setShowInvoiceActionSuccessModal,
    onShowConfirmActionDialog,
    invoiceAction,
    onConfirmInvoiceAction,
    confirmActionLoading,
  } = useInvoiceActions(invoice as Invoice)

  const actionList: TabItem[] = [
    { target: 'summary', label: t('general.productSummary', 'Product Summary') },
    {
      target: 'flags',
      label: t('general.flags', 'Flags'),
      count: Number(flaggingMessages?.length) > 0 ? flaggingMessages?.length : undefined,
      hiddenOnHeaderTabs: state !== InvoiceStatuses.Open && state !== InvoiceStatuses.Flagged,
    },
    { target: 'settings', label: t('general.settings', 'Settings') },
    {
      target: 'comments',
      label: t('general.comments', 'Comments'),
      count: Number(commentsCount),
    },
    {
      target: 'purchase-order',
      label: t('general.purchaseOrder', 'Purchase Order'),
    },
    {
      target: 'receiving-notes',
      label: t('general.receivingNotes', 'Receiving Notes'),
    },
    { target: 'audit-log', label: t('general.auditLog', 'Audit Log'), hiddenOnHeaderTabs: true },
  ]

  const isRedirect = matchPath('/invoices/:invoiceId', location.pathname)

  useEffect(() => {
    if (isRedirect) {
      navigate(generatePath('/invoices/:invoiceId/summary', { invoiceId: String(invoiceId) }))
    }
  }, [])

  // match po section is shown only if the invoice status is flagged or open, and not include PO in prior
  const showMatchPOSection = (state === InvoiceStatuses.Open || state === InvoiceStatuses.Flagged) && !purchaseOrderId

  const renderTitle = () => {
    const targetArray = location.pathname.split('/')
    if (targetArray[targetArray.length - 1] === 'summary') {
      return t('general.summary', 'Summary')
    }
    return findActiveRoute(actionList, location.pathname)
  }

  return (
    <QueryResult loading={loading} error={error}>
      <Helmet>
        <title>{`${invoice?.invoiceNumber || ''} ${renderTitle()}`}</title>
      </Helmet>
      <div className="mx-auto max-w-[95rem] space-y-5 px-4">
        <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2" data-testid="invoice-breadcrumb">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="/invoices">{t('general.invoices', 'Invoices')}</Breadcrumb.Item>
          <Breadcrumb.Item href="#">{invoiceNumber}</Breadcrumb.Item>
          <Breadcrumb.Item>{findActiveRoute(actionList, location.pathname)}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="my-5 flex flex-col gap-y-2">
          <section className="flex w-full flex-col justify-between rounded-md bg-white shadow sm:flex-row">
            <div className="flex items-center gap-4 p-4">
              <ThemeWrapper theme={Themes.Primary} className="flex items-center justify-center rounded-md p-2">
                <InvoiceIcon className="h-10 w-10 text-primary" />
              </ThemeWrapper>
              <div className="flex justify-between">
                <span>
                  <p className="text-xl font-bold" data-testid="cart-value">
                    {formatMoney(totalValue)}
                  </p>
                  <p className="text-sm">{invoiceNumber}</p>
                </span>
              </div>
            </div>
            <hr className="sm:hidden" />
            <div className="flex items-center justify-between p-4 sm:justify-center">
              <p className="text-sm text-gray-500 sm:hidden">{t('general.actions')}</p>
              <div className="flex gap-x-2">
                <Tooltip
                  content={t('purchaseOrders.purchaseOrder.details.info', 'Show or hide details')}
                  showArrow={false}
                >
                  <Button
                    data-testid="show-details-button"
                    className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                    onClick={() => setShowInfo(!showInfo)}
                  >
                    <InfoIcon className="h-8 w-8 text-gray-600" />
                  </Button>
                </Tooltip>
                {invoice?.attachmentUrl && (
                  <Tooltip content={t('general.viewPdfOriginal', 'View PDF Original')} showArrow={false}>
                    <a
                      data-testid="view-pdf-button"
                      className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                      href={invoice.attachmentUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <InvoiceIcon className="h-8 w-8 text-gray-600" />
                    </a>
                  </Tooltip>
                )}
                <MoreOptionsMenu
                  showHelp
                  showOpenInPPlus
                  showPrint
                  showAuditLog
                  invoiceId={invoiceId}
                  buttonTheme="dark"
                  showReopenInvoice={state === InvoiceStatuses.Cancelled || state === InvoiceStatuses.Reconciled}
                  showCancelInvoice={state === InvoiceStatuses.Open || state === InvoiceStatuses.Flagged}
                  showParkInvoice={state === InvoiceStatuses.Open || state === InvoiceStatuses.Flagged}
                  showHaltInvoice={state === InvoiceStatuses.Processing}
                  onShowConfirmActionDialog={onShowConfirmActionDialog}
                >
                  <OptionIcon className="h-8 w-8" />
                </MoreOptionsMenu>
              </div>
            </div>
          </section>
          {showInfo && <Details invoice={invoice as Invoice} />}
          {invoice && <BalancePanel invoice={invoice as Invoice} />}
          {showMatchPOSection && (
            <section>
              <div className="flex flex-col items-center justify-between gap-3 rounded-md bg-white px-5 py-3 shadow md:flex-row">
                <span className="text-sm">
                  {t('invoices.invoice.match.canNotMatch', 'There is no Purchase Order matched to this Invoice')}
                </span>
                <Button
                  data-testid="match-invoice-modal-open"
                  className="rounded-md bg-gray-200 px-4 py-2 text-sm"
                  onClick={() => setShowMatchPOModal(true)}
                >
                  {t('invoices.invoice.match.findAndMatch', 'Find and match')}
                </Button>
              </div>
            </section>
          )}
          <InvoiceActions
            invoice={invoice as Invoice}
            onConfirmInvoiceAction={onConfirmInvoiceAction}
            confirmActionLoading={confirmActionLoading}
          />
          <MatchPOToInvoiceModal
            invoiceId={invoiceId}
            invoiceNumber={invoiceNumber}
            supplierId={supplier?.id}
            supplierName={supplier?.name}
            showModal={showMatchPOModal}
            setShowModal={setShowMatchPOModal}
          />
          <section className="flex items-center justify-between rounded-md bg-white px-4 py-3 text-sm shadow">
            <p>{t('general.status', 'Status')}</p>
            {state && <InvoiceStatus state={state} />}
          </section>
          <section>
            <HeaderTabs actionList={actionList.filter((action) => !action.hiddenOnHeaderTabs)} />
          </section>
        </section>
        <Outlet />
        <InvoiceActionConfirmDialog
          invoiceAction={invoiceAction as InvoiceActionTypes}
          showConfirmActionDialog={showConfirmActionDialog}
          setShowConfirmActionDialog={setShowConfirmActionDialog}
          confirmActionLoading={confirmActionLoading}
          onConfirmInvoiceAction={onConfirmInvoiceAction}
          invoice={invoice as Invoice}
        />
        <InvoiceActionSuccessModal
          invoiceAction={invoiceAction as InvoiceActionTypes}
          showModal={showInvoiceActionSuccessModal}
          setShowModal={setShowInvoiceActionSuccessModal}
          state={state as InvoiceStatuses}
        />
      </div>
    </QueryResult>
  )
}
