import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback } from '@/modules/shared/components'

interface PRLineSupplierCodeProps {
  requisitionLine: RequisitionLine
}

export default function PRLineSupplierCode({ requisitionLine }: PRLineSupplierCodeProps) {
  const { supplierProductCode } = requisitionLine

  return <Fallback condition={!!supplierProductCode}>{supplierProductCode}</Fallback>
}
