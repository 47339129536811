import { Combobox } from '@headlessui/react'
import classNames from 'classnames'

import ComboboxOptionButton from '../../combobox/shared/ComboboxOptionButton'
import { ComboboxOptionItem } from '../../combobox/shared/ComboboxOptionItem'

interface ComboboxFilterProps {
  placeholder: string
  disabled?: boolean
  className?: string
  onSelected: (item: string | number) => void
  items: string[] | number[]
  selectedItems: string[]
  testId?: string
  isSelected?: boolean
}

const ComboboxFilter = ({
  placeholder,
  disabled,
  className,
  onSelected,
  items = [],
  selectedItems = [],
  testId,
  isSelected,
}: ComboboxFilterProps) => {
  return (
    <Combobox
      as="div"
      className={classNames(className, 'w-full lg:w-80')}
      disabled={disabled}
      value={placeholder}
      onChange={onSelected}
    >
      <div className={'relative'}>
        <Combobox.Button as="div">
          <Combobox.Input
            autoComplete="off"
            data-testid={`combobox-filter${testId ? `-${testId}` : ''}`}
            className={classNames(
              'w-full cursor-pointer rounded-md border border-gray-300 p-3 text-sm shadow-sm focus:outline-none focus:ring-primary',
              {
                'bg-gray-200': disabled,
                'border-primary bg-primary/10 text-primary': isSelected,
              }
            )}
            onChange={(event) => onSelected(event.target.value)}
            placeholder={placeholder}
          />
        </Combobox.Button>

        <ComboboxOptionButton testId={testId} disabled={disabled} loading={false} />

        {items.length > 0 && (
          <Combobox.Options
            data-testid={`options-wrapper${testId ? `-${testId}` : ''}`}
            className="absolute z-10 mt-1 max-h-72 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-primary/5 focus:outline-none sm:text-sm"
          >
            {items.map((item) => (
              <ComboboxOptionItem
                key={`combobox-option-item-${item}`}
                item={String(item)}
                testId={testId}
                keyExtractor={(item) => String(item)}
                getSelectedItem={(item) => String(item)}
                selectedItems={selectedItems}
                isClient={true}
              />
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}

export default ComboboxFilter
