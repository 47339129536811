import { graphql } from 'msw'

import { GetOrganisationsQuery } from '@/graphql/access/generated/access_graphql'

export const GetOrganisations = graphql.query<GetOrganisationsQuery>('GetOrganisations', (_, res, ctx) => {
  return res(
    ctx.data({
      organisations: {
        edges: [
          {
            node: {
              id: 101,
              name: 'Org 1',
              parent: null,
              __typename: 'Organisation',
            },
            __typename: 'OrganisationEdge',
          },
          {
            node: {
              id: 102,
              name: 'Org 2',
              parent: null,
              __typename: 'Organisation',
            },
            __typename: 'OrganisationEdge',
          },
          {
            node: {
              id: 103,
              name: 'Org 3',
              parent: null,
              __typename: 'Organisation',
            },
            __typename: 'OrganisationEdge',
          },
        ],
        pageInfo: {
          hasNextPage: false,
          endCursor: 'abc',
          __typename: 'PageInfo',
        },
        __typename: 'OrganisationConnection',
      },
    })
  )
})
