import { useMutation } from '@apollo/client'
import { generatePath, useNavigate } from 'react-router-dom'

import {
  CancelReceivingDocumentDocument,
  CreateReceivingDocumentDocument,
  ReceiveReceivingDocumentDocument,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useReceivingDocumentMutations() {
  const navigate = useNavigate()

  const [receiveReceivingDocument, { loading: receiveReceivingDocumentLoading }] = useMutation(
    ReceiveReceivingDocumentDocument,
    {
      onCompleted(data) {
        navigate(
          generatePath('/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/received-success', {
            purchaseOrderId: String(data?.receiveReceivingDocument.receivingDocument?.purchaseOrder?.id),
            receivingNoteId: String(data.receiveReceivingDocument.receivingDocument?.id),
          }),
          { state: { receivedSuccess: true } }
        )
      },
      context: { uri: PURCHASING_GRAPHQL_API },
    }
  )

  const [cancelReceivingDocument, { loading: cancelReceivingDocumentLoading }] = useMutation(
    CancelReceivingDocumentDocument,
    {
      onCompleted(data) {
        navigate(
          generatePath('/purchase-orders/:purchaseOrderId/receiving-notes', {
            purchaseOrderId: String(data?.cancelReceivingDocument.receivingDocument?.purchaseOrder?.id),
          })
        )
      },
      context: { uri: PURCHASING_GRAPHQL_API },
    }
  )

  const [createReceivingDocument, { loading: createReceivingDocumentLoading }] = useMutation(
    CreateReceivingDocumentDocument,
    {
      onCompleted(data) {
        if (data.createReceivingDocument.receivingDocument) {
          navigate(
            generatePath('/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/draft', {
              purchaseOrderId: String(data.createReceivingDocument.receivingDocument?.purchaseOrder?.id),
              receivingNoteId: String(data.createReceivingDocument.receivingDocument?.id),
            })
          )
        }
      },
      context: { uri: PURCHASING_GRAPHQL_API },
    }
  )

  return {
    createReceivingDocument,
    cancelReceivingDocument,
    receiveReceivingDocument,
    receiveReceivingDocumentLoading,
    cancelReceivingDocumentLoading,
    createReceivingDocumentLoading,
  }
}
