import { graphql } from 'msw'

import { ProcessInvoiceMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseProcessInvoice: ProcessInvoiceMutation = {
  processInvoice: {
    invoice: {
      id: 7524140,
      state: 'processing',
      __typename: 'Invoice',
    },
    __typename: 'ProcessInvoiceMutationPayload',
  },
}

export const ProcessInvoice = graphql.mutation<ProcessInvoiceMutation>('ProcessInvoice', (_, res, ctx) => {
  return res(ctx.data(baseProcessInvoice))
})

export default baseProcessInvoice
