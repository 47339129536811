import { graphql } from 'msw'

import { GetPoReceivingNotesQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetPOReceivingNotes: GetPoReceivingNotesQuery = {
  currentPurchaser: {
    id: 44926,
    purchaseOrder: {
      id: 16566710,
      purchaseOrderNumber: 'PO02775',
      outstandingTotal: 21.6,
      receivedTotal: 0,
      totalValue: 21.6,
      totalValueExcludingTax: 21.6,
      receivingDocuments: {
        edges: [
          {
            node: {
              id: 5624864,
              receivingDocumentNumber: 'GR02076',
              receivedDate: '2020-11-20T09:36:53+11:00',
              workflowState: 'open',
              destinationId: 3749,
              purchaseOrderId: 16566710,
              destination: null,
              transfer: null,
              receivingTotalValue: 0,
              receiver: {
                id: 20275,
                fullName: 'Sandi Tognolini',
                __typename: 'User',
              },
              __typename: 'ReceivingDocument',
            },
            __typename: 'ReceivingDocumentEdge',
          },
          {
            node: {
              id: 6659700,
              receivingDocumentNumber: 'GR02326',
              purchaseOrderId: 16566710,
              receivedDate: '2022-04-06T13:43:32+10:00',
              workflowState: 'received',
              destinationId: null,
              destination: null,
              transfer: null,
              receivingTotalValue: 0,
              receiver: {
                id: 18544,
                fullName: 'Sunny H Sun',
                __typename: 'User',
              },
              __typename: 'ReceivingDocument',
            },
            __typename: 'ReceivingDocumentEdge',
          },
        ],
        __typename: 'ReceivingDocumentConnection',
      },
      __typename: 'PurchaseOrder',
    },
    __typename: 'Purchaser',
  },
}

export const GetPOReceivingNotes = graphql.query<GetPoReceivingNotesQuery>('GetPOReceivingNotes', (_, res, ctx) => {
  return res(ctx.data(baseGetPOReceivingNotes))
})

export default baseGetPOReceivingNotes
