import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useMoney } from '../../hooks'
import Button from '../button'
import Fallback from '../fallback'
import { Modal } from '../modal'
import NumberInput from '../number-input'
import Table from '../table/Table'

import { Price } from '@/graphql/purchasing/generated/purchasing_graphql'
import TaxPercentage from '@/modules/shared/components/table/table-column-items/TaxPercentage'
import UnitPrice from '@/modules/shared/components/table/table-column-items/UnitPrice'

export interface SupplierQuotesModalFooterProps {
  currentSupplierId?: number | null
  lineId?: number | null
  onCloseModal: () => void
  updateSupplier: () => void
  selectedSupplier: Price | null | undefined
  advanced?: boolean
  originalQuantity: number
  createRequisitionLine: () => void
  quantity: number
  setQuantity: (quantity: number) => void
  loading: boolean
}

export const SupplierQuotesModalFooter = ({
  onCloseModal,
  currentSupplierId,
  updateSupplier,
  lineId,
  selectedSupplier,
  advanced,
  originalQuantity,
  createRequisitionLine,
  quantity,
  setQuantity,
  loading,
}: SupplierQuotesModalFooterProps) => {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [inputError, setInputError] = useState(false)

  const onSubmitChanges = () => {
    if ((selectedSupplier?.supplierId !== currentSupplierId && quantity > 0) || quantity !== originalQuantity) {
      if (!lineId) {
        createRequisitionLine()
      } else {
        updateSupplier() // this is updateRequisitionLine
      }
    } else {
      setInputError(true)
    }
  }

  const calculateTotal = ({ unitPrice, taxPercentage }: Price) => {
    const price = unitPrice || 0
    const tax = (taxPercentage || 0) / 100
    return formatMoney(price * quantity + price * quantity * tax)
  }

  return (
    <div className="min-h-min">
      {advanced && (
        <Table
          dataTestId="supplier-quotes-modal-table"
          dataSource={[selectedSupplier]}
          keyExtractor={(record) => String(record?.supplierId)}
          tableStyles="border-l-0 border-r-0"
          tableHeadStyles="border-l-0 border-r-0"
          tableBodyStyles="border-l-0 border-r-0"
          columns={[
            {
              title: t('general.selectedSupplier', 'Selected Supplier'),
              key: 'selected-supplier',
              headerCellStyles: 'pl-5 pr-4 py-3',
              cellStyles: 'pl-5 pr-4 py-8',
              minWidth: 17.5,
              maxWidth: 18.75,
              grow: true,
              render: (record) => <Fallback condition={!!record?.supplierName}>{record?.supplierName}</Fallback>,
            },
            {
              title: t('general.unitPrice', 'Unit Price'),
              key: 'unitPrice',
              headerCellStyles: 'py-3 text-right',
              cellStyles: 'py-3 text-right',
              minWidth: 5.625,
              maxWidth: 5.625,
              render: (record) => <UnitPrice unitPrice={record?.unitPrice} availableQuotes={!!record} />,
            },
            {
              title: t('general.tax%', 'Tax %'),
              key: 'taxPercentage',
              headerCellStyles: 'py-3 text-center',
              cellStyles: 'py-3 text-center',
              minWidth: 5.625,
              maxWidth: 5.625,
              render: (record) => <TaxPercentage taxPercentage={record?.taxPercentage} availableQuotes={!!record} />,
            },
            {
              title: t('general.quantity', 'Quantity'),
              key: 'quantity',
              headerCellStyles: 'px-4 py-3 text-center',
              cellStyles: 'px-4 py-3 text-center',
              minWidth: 5.625,
              maxWidth: 5.625,
              render: () => (
                <NumberInput
                  className={'max-w-[3.75rem] rounded-md text-center'}
                  aria-label="supplier-quotes-modal-quantity-input"
                  data-testid="supplier-quotes-modal-quantity-input"
                  value={quantity}
                  minValue={0}
                  maxValue={999}
                  formatOptions={{
                    maximumFractionDigits: 3,
                  }}
                  hasError={inputError}
                  loading={loading}
                  isDisabled={!selectedSupplier}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    // maxValue does not work normally with onInput
                    const inputValue = Number(e.target.value)
                    if (inputValue > 999) {
                      setQuantity(999)
                    } else {
                      setQuantity(inputValue)
                      if (
                        inputError &&
                        (selectedSupplier?.supplierId !== currentSupplierId || quantity !== originalQuantity)
                      ) {
                        setInputError(false)
                      }
                    }
                  }}
                />
              ),
            },
            {
              title: t('general.total', 'Total'),
              key: 'total',
              headerCellStyles: 'pl-3 pr-5 px-4 text-right',
              cellStyles: 'pl-3 pr-5 px-4 text-right',
              minWidth: 5.625,
              maxWidth: 7.5,
              render: (record) => (
                <strong>
                  <Fallback condition={!!record}>{record && calculateTotal(record)}</Fallback>
                </strong>
              ),
            },
          ]}
        />
      )}
      <Modal.Footer className={classNames('flex items-center justify-end', { 'border-none': advanced })}>
        <div className="flex w-full flex-col-reverse gap-2 md:w-fit md:flex-row">
          <Button
            className="h-11 w-full rounded-md bg-gray-200 px-6 text-sm md:w-fit"
            onClick={onCloseModal}
            data-testid="close-supplier-quotes-modal"
          >
            {t('general.close', 'Close')}
          </Button>

          {(lineId || advanced) && (
            <Button
              className="h-11 w-full min-w-[9.3rem] rounded-md bg-primary px-6 text-sm text-white md:w-fit"
              onClick={onSubmitChanges}
              loading={loading}
              data-testid="submit-supplier-quotes-modal"
            >
              {t('general.applyChanges', 'Apply Changes')}
            </Button>
          )}
        </div>
      </Modal.Footer>
    </div>
  )
}
