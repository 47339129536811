import { useLazyQuery, useMutation } from '@apollo/client'
import { Dispatch, SetStateAction, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import {
  GetAllPurchaseOrdersDocument,
  MatchPurchaseOrderInvoiceDocument,
  PurchaseOrder,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import PurchaseOrderStatus from '@/modules/purchase-orders/pages/all-purchase-order/PurchaseOrderStatus'
import { statusText } from '@/modules/purchase-orders/utils/statusText'
import { Spinner } from '@/modules/requisitions/components'
import {
  Button,
  Checkbox,
  ConfirmDialog,
  Fallback,
  Input,
  Modal,
  PPNewDetailLink,
  QueryResult,
} from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useDebounce, useMoney } from '@/modules/shared/hooks'
import { CheckIcon, MagnifyingGlassIcon, NewTabIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

interface MatchPOToInvoiceModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  invoiceId?: string
  invoiceNumber?: string | null
  supplierId?: number
  supplierName?: string | null
}

function MatchPOToInvoiceModal({
  invoiceId,
  invoiceNumber,
  supplierId,
  supplierName,
  showModal,
  setShowModal,
}: MatchPOToInvoiceModalProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const [inputFocus, setInputFocus] = useState(false)
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState<PurchaseOrder | null>()
  const [hasMatched, setHasMatched] = useState(false)
  const [showError, setShowError] = useState(false)
  const purchaseOdersFilterQ = [
    /* filtersame supplier id */
    { property: 'supplier_id_eq', value: supplierId },

    /* filter all states that not closed or cancelled, these are fake statuses not real ones */
    { property: 'state_not_eq', value: ['closed'] },
    {
      property: 'cancelled_eq',
      value: false,
    },
  ]

  const [fetchPurchaseOrders, { data, error, refetch, networkStatus, fetchMore }] = useLazyQuery(
    GetAllPurchaseOrdersDocument,
    {
      context: {
        uri: PURCHASING_GRAPHQL_API,
      },
      variables: {
        filter: {
          q: purchaseOdersFilterQ,
        },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    }
  )

  const [matchInvoice, { loading: matchInvoiceLoading }] = useMutation(MatchPurchaseOrderInvoiceDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const { setVariablesLoading, loading, fetchMoreLoading } = checkNetworkStatus(networkStatus)

  const onMatchInvoice = () => {
    matchInvoice({
      variables: { input: { id: Number(invoiceId), purchaseOrderId: selectedPurchaseOrder!.id } },
      onCompleted: () => setHasMatched(true),
      onError: () => setShowError(true),
    })
  }

  const onCloseModal = () => {
    setShowModal(false)

    setTimeout(() => {
      setSelectedPurchaseOrder(null)
      setHasMatched(false)
    }, 1000) // wait for modal to fade out before reseting state
  }

  useEffect(() => {
    if (inputFocus) {
      refetch({
        filter: {
          q: [
            ...purchaseOdersFilterQ,
            { property: 'purchaseOrderNumber_or_requisition_reference_cont', value: debouncedSearchValue },
          ],
        },
      })
    }
  }, [debouncedSearchValue])

  const onFetchMorePurchaseOrders = () => {
    fetchMore({ variables: { after: data?.currentPurchaser?.purchaseOrders?.pageInfo.endCursor } })
  }

  useEffect(() => {
    if (showModal) {
      fetchPurchaseOrders()
    }
  }, [showModal])

  const purchaseOrders = extractEdges<PurchaseOrder>(data?.currentPurchaser?.purchaseOrders)

  const POMatchedSuccessfully = () => (
    <div className="py-16 text-center" data-testid="successfully-matched">
      <CheckIcon className="m-auto mb-4 h-12 w-12 text-success" />
      <p className="text-center text-lg font-bold">{t('general.successfullyMatched', 'Successfully Matched')}</p>
      <p className="text-center text-gray-500">
        {t(
          'purchaseOrders.allPurchaseOrders.table.matchInvoiceModal.matchedBody',
          '{{ invoiceNumber }} was successfully matched to {{ purchaseOrderNumber }}',
          {
            invoiceNumber,
            purchaseOrderNumber: selectedPurchaseOrder?.purchaseOrderNumber,
          }
        )}
      </p>
    </div>
  )

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal} dataTestId="match-invoice-modal">
      <Modal.Panel
        as="div"
        className="flex max-h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[56.25rem]"
      >
        <Modal.Title
          title={
            hasMatched
              ? t('general.successfullyMatched', 'Successfully Matched')
              : t('invoices.invoice.match.title', 'Match Purchase Order to {{ invoiceNumber }}', {
                  invoiceNumber,
                })
          }
          onCloseModal={onCloseModal}
        />
        {hasMatched ? (
          <POMatchedSuccessfully />
        ) : (
          <Modal.Body className="text-sm" id="InfiniteScroll">
            <p className="mb-3 rounded-md bg-gray-200 p-4">
              {t(
                'invoices.invoice.match.highlights',
                'PurchasePlus will automatically match a Purchase Order to a Paperless Invoice when it finds a logical match. You may need to manually match a Purchase Order to a Paperless Invoice because the Supplier did not add the Purchase Order number to the Invoice when they sent it to you via PurchasePlus. If the Invoice was manually created, the Purchase Order must also be manually matched.'
              )}
            </p>
            <p className="font-bold">{t('general.availablePurchaseOrders', 'Available Purchase Orders')}</p>
            <p className="text-gray-500">
              {t(
                'invoices.invoice.match.instruction',
                "Here you can search for a Purchase Order to match to this Invoice. Only Purchase Orders from the Supplier {{ supplierName }} will appear here, and we're only showing Purchase Orders that are currently in a Not Sent, or Sent state. Click a Purchase Order to open it in a new tab if you'd like to view the Purchase Order contents.",
                { supplierName }
              )}
            </p>
            <Input
              suffixIcon={MagnifyingGlassIcon}
              className="my-4 w-full rounded-md border border-gray-300 p-3 text-sm shadow-sm focus:border-primary focus:outline-none focus:ring-primary"
              onChange={(e) => setSearchValue(e)}
              placeholder={t('invoices.invoice.match.searchBy', 'Search by PO Number or Reference')}
              onFocus={() => setInputFocus(true)}
              aria-label={t('invoices.invoice.match.searchBy', 'Search by PO Number or Reference')}
              data-testid="search-po-input"
            />
            <QueryResult loading={loading || setVariablesLoading} error={error} size="md">
              <InfiniteScroll
                dataLength={purchaseOrders.length}
                next={onFetchMorePurchaseOrders}
                hasMore={!!data?.currentPurchaser?.purchaseOrders?.pageInfo.hasNextPage}
                loader={fetchMoreLoading && <Spinner className="mt-5 h-14 md:w-16" />}
                scrollableTarget="InfiniteScroll"
                endMessage={
                  !setVariablesLoading &&
                  purchaseOrders.length === 0 && (
                    <div className="border bg-gray-100 p-4 text-center">
                      {t(
                        'purchaseOrders.allPurchaseOrders.noPurchaseOrderFound',
                        "We couldn't find any Purchase Orders to display."
                      )}
                    </div>
                  )
                }
              >
                <Table
                  dataSource={purchaseOrders}
                  keyExtractor={(record, index) => String(`${record.id}_${index}`)}
                  tableHeadStyles="border-none"
                  columns={[
                    {
                      title: t('general.purchaseOrder', 'Purchase Order'),
                      key: 'purchaseOrder',
                      headerCellStyles: 'pl-3 py-3',
                      cellStyles: 'pl-3 py-3 text-primary',
                      minWidth: 8.75,
                      maxWidth: 8.75,
                      render: ({ purchaseOrderNumber, id }: PurchaseOrder) => (
                        <PPNewDetailLink
                          data-testid="po-number"
                          openNewTab
                          className="flex items-center gap-x-1"
                          type="purchase-order"
                          id={String(id)}
                        >
                          <span>{purchaseOrderNumber}</span>
                          <NewTabIcon className="h-5 w-5" />
                        </PPNewDetailLink>
                      ),
                    },
                    {
                      title: t('general.reference', 'Reference'),
                      key: 'reference',
                      headerCellStyles: 'px-2 py-3',
                      cellStyles: 'px-2 py-3',
                      minWidth: 11.25,
                      grow: true,
                      render: ({ requisition }: PurchaseOrder) => (
                        <Fallback condition={!!requisition?.reference}>{requisition?.reference}</Fallback>
                      ),
                    },
                    {
                      title: t('general.status', 'Status'),
                      key: 'status',
                      headerCellStyles: 'py-3 text-center',
                      cellStyles: 'py-3 text-center',
                      minWidth: 10,
                      maxWidth: 10,
                      render: ({ status, sentDate }: PurchaseOrder) => (
                        <PurchaseOrderStatus status={statusText(status, sentDate)} />
                      ),
                    },
                    {
                      title: t('general.total', 'Total'),
                      key: 'total',
                      headerCellStyles: 'py-3 text-right',
                      cellStyles: 'py-3 text-right font-bold',
                      minWidth: 6.875,
                      maxWidth: 7.5,
                      render: ({ totalValue }: PurchaseOrder) => <>{formatMoney(totalValue)}</>,
                    },
                    {
                      title: t('general.match', 'Match'),
                      key: 'match',
                      headerCellStyles: 'py-3 text-center',
                      cellStyles: 'py-3 text-center',
                      minWidth: 5,
                      maxWidth: 5,
                      render: (purchaseOrder: PurchaseOrder) => (
                        <span
                          className="flex justify-center"
                          onClick={() => setSelectedPurchaseOrder(purchaseOrder)}
                          data-testid="select-po"
                        >
                          <Checkbox isSelected={selectedPurchaseOrder?.id === purchaseOrder?.id} aria-label="match" />
                        </span>
                      ),
                    },
                  ]}
                />
              </InfiniteScroll>
            </QueryResult>
            <ConfirmDialog
              title={t('general.failedToMatch', 'Failed to Match')}
              description={t(
                'purchaseOrders.allPurchaseOrders.table.matchInvoiceModal.errorDescription',
                'Sorry, something went wrong and we were unable to match the Invoice to the Purchase Order.'
              )}
              primaryButtonLabel={t('general.okay', 'Okay')}
              onPrimaryBtnClick={() => setShowError(false)}
              theme={Themes.Error}
              isOpen={showError}
              setIsOpen={setShowError}
            />
          </Modal.Body>
        )}
        <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
          <Button
            type="button"
            className="w-full rounded-md bg-gray-200 px-8 py-2.5 text-sm md:w-auto"
            onClick={onCloseModal}
            data-testid="match-po-close"
          >
            {hasMatched ? t('general.close', 'Close') : t('general.cancel', 'Cancel')}
          </Button>
          {!hasMatched && (
            <Button
              type="button"
              className="w-full rounded-md bg-primary px-8 py-2.5 text-sm text-white md:w-auto"
              loading={matchInvoiceLoading}
              onClick={onMatchInvoice}
              disabled={!selectedPurchaseOrder}
              data-testid="apply-match"
            >
              {t('general.applyMatch', 'Apply Match')}
            </Button>
          )}
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}

export default memo(MatchPOToInvoiceModal)
