import { graphql } from 'msw'

import { GetPurchaserQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetPurchaser: GetPurchaserQuery = {
  purchaser: {
    id: 111291,
    name: 'Test 2 Darling Novotel',
    legalEntity: false,
    createdAt: '2016-11-22T06:17:31Z',
    featureFlags: {
      inventory: true,
      promoBanners: false,
      quickCreate: false,
      __typename: 'OrganisationFeatureFlags',
    },
    __typename: 'Purchaser',
    parent: {
      id: 111290,
      name: 'TEST Darling Novotel',
      __typename: 'Purchaser',
    },
  },
}

export const GetPurchaser = graphql.query<GetPurchaserQuery>('GetPurchaser', (_, res, ctx) => {
  return res(ctx.data(baseGetPurchaser))
})

export default baseGetPurchaser
