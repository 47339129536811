import { graphql } from 'msw'

import { GetAllStocktakesQuery } from '@/graphql/inventory/generated/inventory_graphql'

const baseGetAllStocktakes: GetAllStocktakesQuery = {
  legalHolder: {
    id: 12,
    stockTakes: {
      nodes: [
        {
          id: 53692,
          periodEndingOn: '2023-04-30',
          stockTakeStatus: 'Count Not Started',
          blind: true,
          closedAt: null,
          stockLocation: {
            id: 2162,
            name: 'Cafe101',
            __typename: 'StockLocation',
          },
          __typename: 'StockTake',
        },
        {
          id: 53690,
          periodEndingOn: '2023-01-31',
          stockTakeStatus: 'In Progress',
          blind: null,
          closedAt: true,
          stockLocation: {
            id: 23887,
            name: 'Johan',
            __typename: 'StockLocation',
          },
          __typename: 'StockTake',
        },
        {
          id: 53684,
          periodEndingOn: '2022-10-31',
          stockTakeStatus: 'Count Complete',
          blind: null,
          closedAt: null,
          stockLocation: {
            id: 3997,
            name: 'NotSoMiniBar',
            __typename: 'StockLocation',
          },
          __typename: 'StockTake',
        },
      ],
      pageInfo: {
        startCursor: 'MQ',
        endCursor: 'MTY',
        hasNextPage: false,
        hasPreviousPage: false,
        __typename: 'PageInfo',
      },
      __typename: 'StockTakeConnection',
    },
    __typename: 'Holder',
  },
}

export const GetAllStocktakes = graphql.query<GetAllStocktakesQuery>('GetAllStocktakes', (_, res, ctx) => {
  return res(ctx.data(baseGetAllStocktakes))
})

export default baseGetAllStocktakes
