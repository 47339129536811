import { ReactNode } from 'react'

import TableItem from './TableItem'

interface TableRowDoubleProps {
  leftTitle: string
  leftValue: string | ReactNode
  leftUpdate?: string
  leftTooltip: string
  leftTestId: string
  rightTitle: string
  rightValue: string | ReactNode
  rightUpdate?: string
  rightTooltip: string
  rightTestId: string
}

function TableRowDouble({
  leftTitle,
  leftValue,
  leftUpdate,
  leftTooltip,
  leftTestId,
  rightTitle,
  rightValue,
  rightUpdate,
  rightTooltip,
  rightTestId,
}: TableRowDoubleProps) {
  return (
    <>
      <div className="flex divide-x divide-gray-200">
        <div className="flex grow items-center 2xl:w-1/2">
          <TableItem
            titleText={leftTitle}
            value={leftValue}
            update={leftUpdate}
            tooltipText={leftTooltip}
            testId={leftTestId}
          />
        </div>
        <div className="hidden w-1/2 items-center 2xl:flex">
          <TableItem
            titleText={rightTitle}
            value={rightValue}
            update={rightUpdate}
            tooltipText={rightTooltip}
            testId={rightTestId}
          />
        </div>
      </div>
      <div className="flex 2xl:hidden">
        <div className="flex grow items-center">
          <TableItem
            titleText={rightTitle}
            value={rightValue}
            update={rightUpdate}
            tooltipText={rightTooltip}
            testId={rightTestId}
          />
        </div>
      </div>
    </>
  )
}

export default TableRowDouble
