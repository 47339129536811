import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import AddItem from '../../AddItem'
import AddToBuyListModal from '../../AddToBuyListModal'

import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import AddCatalogProductModal from '@/modules/catalogs/pages/catalog/add-catalog-product/AddCatalogProductModal'
import AddInvoiceLineModal from '@/modules/invoices/pages/invoice/add-invoice-line/AddInvoiceLineModal'
import { useChangeRequisitionLineQuantity } from '@/modules/requisitions/hooks'
import { Button, Stepper } from '@/modules/shared/components'
import { ProductListType, ProductType } from '@/modules/shared/types'

export interface ProductInformationProps {
  product: ProductType
  type: ProductListType
  lines?: RequisitionLine[]
}

const ShopButton = ({ product, lines }: ProductInformationProps) => {
  const { t } = useTranslation()
  const { onUpdateRequisitionLineQuantity, loading: changeQuantityLoading } = useChangeRequisitionLineQuantity()
  const { quantity, id: lineId, productId, cataloguedProductId } = product
  const isAddedToCart = lines && lines.find((line) => line.cataloguedProductId === product.cataloguedProductId)
  return (
    <>
      {isAddedToCart ? (
        <Stepper
          loading={changeQuantityLoading}
          value={quantity || 0}
          stepperAriaLabel={t('shopPage.stepperAriaLabel', 'Entering number of products')}
          onChange={(newQty: number) =>
            onUpdateRequisitionLineQuantity({
              qty: newQty,
              lineId,
              productId,
              cataloguedProductId,
            })
          }
        />
      ) : (
        <AddItem line={product} />
      )}
    </>
  )
}

const BuyListBuilderButton = ({ product }: ProductInformationProps) => {
  const { t } = useTranslation()
  const [showAddToBuyListModal, setShowAddToBuyListModal] = useState(false)
  const isInBuyLists = !!product.product?.catalogues?.edges?.length
  return (
    <>
      <Button
        data-testid="add-to-buy-list-button"
        className={classNames('w-36 rounded-full bg-gray-200 py-2.5 text-sm', {
          'bg-gray-200': !isInBuyLists,
          'bg-primary text-white': isInBuyLists,
        })}
        onClick={() => setShowAddToBuyListModal(true)}
      >
        {isInBuyLists
          ? t('buyLists.builder.inBuyLists', {
              defaultValue_one: 'In {{ count }} Buy List',
              defaultValue_many: 'In {{ count }} Buy Lists',
              defaultValue_other: 'In {{ count }} Buy Lists',
              count: product.product?.catalogues?.edges?.length,
            })
          : t('buyLists.builder.addToBuyList', 'Add to Buy List')}
      </Button>
      <AddToBuyListModal
        requisitionLine={product}
        showModal={showAddToBuyListModal}
        setShowModal={setShowAddToBuyListModal}
      />
    </>
  )
}

const AddInvoiceLineButton = ({ product }: ProductInformationProps) => {
  const { t } = useTranslation()
  const [showAddToInvoiceModal, setShowAddToInvoiceModal] = useState(false)
  let isLine = product.product?.invoiced
  const { invoiceId } = useParams<{ invoiceId: string }>()

  return (
    <>
      <Button
        data-testid="create-invoice-line-button"
        className={classNames('w-36 rounded-full py-2.5 text-sm', {
          'bg-gray-200': !isLine,
          'bg-primary text-white': isLine,
        })}
        onClick={() => !isLine && setShowAddToInvoiceModal(true)}
      >
        {isLine ? t('general.alreadyAdded', 'Already Added') : t('general.addToInvoice', 'Add to Invoice')}
      </Button>
      <AddInvoiceLineModal
        showModal={showAddToInvoiceModal}
        setShowModal={setShowAddToInvoiceModal}
        line={product}
        invoiceId={Number(invoiceId)}
      />
    </>
  )
}

const AddCatalogProductButton = ({ product }: ProductInformationProps) => {
  const { t } = useTranslation()
  const [showAddToCatalog, setShowAddToCatalog] = useState(false)
  const isInCatalog = product.product?.catalogued
  const { pricedCatalogueId } = useParams<{ pricedCatalogueId: string }>()

  return (
    <>
      <Button
        data-testid="add-to-catalog-button"
        className={classNames('w-36 rounded-full py-2.5 text-sm', {
          'bg-gray-200': !isInCatalog,
          'bg-primary text-white': isInCatalog,
        })}
        onClick={() => !isInCatalog && setShowAddToCatalog(true)}
      >
        {isInCatalog ? t('general.alreadyAdded', 'Already Added') : t('general.addToCatalog', 'Add to Catalog')}
      </Button>
      <AddCatalogProductModal
        showModal={showAddToCatalog}
        setShowModal={setShowAddToCatalog}
        line={product}
        catalogId={Number(pricedCatalogueId)}
      />
    </>
  )
}

export default function ProductPrimaryButton({ product, type, lines }: ProductInformationProps) {
  switch (type) {
    case ProductListType.Shop:
      return <ShopButton product={product} lines={lines} type={type} />
    case ProductListType.BuyListBuilder:
      return <BuyListBuilderButton product={product} lines={lines} type={type} />
    case ProductListType.AddInvoiceLine:
      return <AddInvoiceLineButton product={product} lines={lines} type={type} />
    case ProductListType.AddCatalogProduct:
      return <AddCatalogProductButton product={product} lines={lines} type={type} />
  }
}
