import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import CartHeader from '../view-cart/page-header/CartHeader'
import ShopCarousel from './ShopCarousel'
import { useGetPossibleRequisitionLines } from './hooks/useGetPossibleRequisitionLines'

import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import { ShopHeader } from '@/modules/requisitions/layouts'
import { QueryResult } from '@/modules/shared/components'
import ProductList from '@/modules/shared/components/product-list'
import { ProductListType } from '@/modules/shared/types'

function Shop() {
  const {
    loading,
    requisition,
    error,
    requisitionId,
    currentPurchaser,
    onFilter,
    possibleRequisitionLines,
    onFetchMoreProduct,
    hasNextPage,
    lines,
    searchProductLoading,
  } = useGetPossibleRequisitionLines()
  const { t } = useTranslation()
  const { currentOrganisation } = useCurrentOrganisation()

  return (
    <>
      <Helmet>
        <title>{`${requisition?.requisitionNumber || ''} ${t('general.shopProducts', 'Shop Products')}`}</title>
      </Helmet>
      <ShopHeader />
      <div className="mx-auto w-full p-4 lg:max-w-[112.5rem]">
        <QueryResult loading={loading} error={error}>
          {requisitionId && currentPurchaser && (
            <div className="flex flex-col gap-5">
              <CartHeader isShop />
              {currentOrganisation?.featureFlags?.promoBanners && <ShopCarousel />}
              <ProductList
                prNumber={requisition?.requisitionNumber}
                prReference={requisition?.reference || ''}
                products={possibleRequisitionLines}
                onFetchMoreProduct={onFetchMoreProduct}
                searchProductLoading={searchProductLoading}
                hasMoreProduct={Boolean(hasNextPage)}
                onFilter={onFilter}
                type={ProductListType.Shop}
                lines={lines}
              />
            </div>
          )}
        </QueryResult>
      </div>
    </>
  )
}

export default Shop
