import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import SelectInventory from './SelectInventory'

import { CreateReceivingDocumentMutationInput } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useSession } from '@/modules/access/hooks'
import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import useReceivingDocumentMutations from '@/modules/receiving-notes/hooks/useReceivingDocumentMutations'
import { Button, Checkbox, Modal } from '@/modules/shared/components'

interface InventoryModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  purchaseOrderId: string
}

export default function InventoryModal({ showModal, setShowModal, purchaseOrderId }: InventoryModalProps) {
  const { t } = useTranslation()
  const [isReceiveToInventory, setIsReceiveToInventory] = useState(false)
  const [selectedInventoryId, setSelectedInventoryId] = useState<number | null>(null)
  const { currentUser } = useSession()
  const { currentOrganisation } = useCurrentOrganisation()

  const { createReceivingDocument, createReceivingDocumentLoading } = useReceivingDocumentMutations()

  useEffect(() => {
    // reset the selectedInventoryId state if user unchecks the checkbox
    if (!isReceiveToInventory) setSelectedInventoryId(null)
  }, [isReceiveToInventory])

  const onCloseModal = () => {
    setShowModal(false)
    setIsReceiveToInventory(false)
    setSelectedInventoryId(null)
  }

  const onCreateReceivingDocument = () => {
    const input: CreateReceivingDocumentMutationInput = {
      purchaseOrderId: Number(purchaseOrderId),
      receiverId: currentUser?.id,
      ownerOrganisationId: currentOrganisation?.id,
    }

    if (isReceiveToInventory && selectedInventoryId) {
      input.destinationId = Number(selectedInventoryId)
    }

    createReceivingDocument({ variables: { input } })
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        as="div"
        className="flex h-[70vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]"
      >
        <Modal.Title title={t('general.receiveGoods', 'Receive Goods')} onCloseModal={onCloseModal} />

        <Modal.Body className="space-y-4">
          <section className="rounded-md bg-gray-200 px-4 py-3 text-sm">
            {t(
              'purchaseOrders.purchaseOrder.inventoryModal.bulkReceiveDesc',
              'You are about to create a Goods Receiving note. Here you can decide to receive your goods to a Stock Location in your inventory, or not. If you decide not to receive to Inventory, the items will be marked as received, but they will not be added to any Stock Location within your inventory.'
            )}
          </section>
          <section className="mt-8">
            <h2 className="text-sm font-semibold">{t('general.receiveToInventory', 'Receive to Inventory')}</h2>
            <p className="text-sm text-gray-500">
              {t(
                'purchaseOrders.purchaseOrder.inventoryModal.receiveToInventoryDesc',
                'Decide if you wish to receive these items to your Inventory. If this is left unchecked, the items will be marked as received, but they will not be added to any stock location within your inventory.'
              )}
            </p>
            <div className="mt-4">
              <Checkbox
                dataTestId="receive-to-inventory-checkbox"
                onChange={(isReceiveToInventory) => setIsReceiveToInventory(isReceiveToInventory)}
              >
                {t('general.receiveToInventory', 'Receive to Inventory')}
              </Checkbox>
            </div>
          </section>
          {isReceiveToInventory && <SelectInventory onSelectedInventory={setSelectedInventoryId} />}
        </Modal.Body>
        <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
          <Button className="h-11 w-full rounded-md bg-gray-200 px-5 text-sm md:w-fit" onClick={() => onCloseModal()}>
            {t('general.cancel', 'Cancel')}
          </Button>
          <Button
            data-testid="create-receiving-document-button"
            className="h-11 w-full rounded-md bg-primary px-5 text-sm text-white md:w-fit"
            disabled={isReceiveToInventory && !selectedInventoryId}
            loading={createReceivingDocumentLoading}
            onClick={onCreateReceivingDocument}
          >
            {t('general.saveAndContinue', 'Save and Continue')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
