import { useContext } from 'react'

import AlertContext from '../providers/AlertProvider'

export default function useAlert() {
  const alert = useContext(AlertContext)

  if (!alert) {
    throw new Error('useAlert() must be used within an AlertProvider')
  }

  return { alertDialog: alert!.alert }
}
