import { useTranslation } from 'react-i18next'

import { PurchaseOrderLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Accordion, PPNewDetailLink, ProductSummary, TableLineItem } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { NewTabIcon } from '@/modules/shared/icons'

interface POLineItemProps {
  purchaseOrderLineItem: PurchaseOrderLineItem
}

export default function POLineItem({ purchaseOrderLineItem }: POLineItemProps) {
  const { purchaseOrder, quantity, receivedQuantity, unitPrice, taxPercentage, product, id } =
    purchaseOrderLineItem || {}
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  return (
    <section>
      <strong className="text-sm">{t('general.purchaseOrderLine', 'Purchase Order Line')}</strong>
      <p className="text-sm text-gray-500">
        {t(
          'invoices.invoice.invoiceLineModal.poLineOverviewDesc',
          'Details of the Purchase Order line linked to this Invoice line. An Invoice line can only be linked to a single Purchase Order line.'
        )}
      </p>
      {!purchaseOrderLineItem && (
        <div className="mt-1 border bg-gray-100 py-4">
          <p className="text-center text-sm text-gray-500">
            {t('invoices.invoice.invoiceLineModal.noPOLineLinked', 'There is no linked Purchase Order line')}
          </p>
        </div>
      )}
      {!!purchaseOrderLineItem && (
        <Accordion
          testId="accordion-po-line-item"
          title={purchaseOrder?.purchaseOrderNumber || ''}
          buttonClassName="px-3 h-11 mt-4"
          titleClassName="font-bold"
        >
          <ProductSummary product={product} unitPrice={unitPrice} taxPercentage={taxPercentage} />

          <div className="mt-4 border" data-testid="po-line-details">
            <TableLineItem
              title={t('general.purchaseOrder', 'Purchase Order')}
              tooltip={t(
                'invoices.invoice.invoiceLineModal.purchaseOrderTooltip',
                'The Purchase Order that is matched to this Invoice.'
              )}
              value={
                <PPNewDetailLink
                  openNewTab
                  type="purchase-order"
                  id={String(purchaseOrder?.id)}
                  className="flex items-center text-primary"
                >
                  {purchaseOrder?.purchaseOrderNumber}
                  <NewTabIcon className="ml-1 h-4 w-4" />
                </PPNewDetailLink>
              }
            />
            <TableLineItem
              title={t('general.purchaseOrderLineId', 'Purchase Order Line ID')}
              tooltip={t(
                'invoices.invoice.invoiceLineModal.purchaseOrderLineIdTooltip',
                'The unique identifier of the Purchase Order line.'
              )}
              value={id}
            />
            <TableLineItem
              title={t('general.orderedQuantity', 'Ordered Quantity')}
              tooltip={t(
                'invoices.invoice.invoiceLineModal.orderedQuantityTooltip',
                'The Quantity shown on the linked Purchase Order line.'
              )}
              value={quantity}
            />
            <TableLineItem
              title={t('general.receivedQuantity', 'Received Quantity')}
              tooltip={t(
                'invoices.invoice.invoiceLineModal.receivedQuantityTooltip',
                'The Quantity that has been marked as received on one or more Receiving Notes.'
              )}
              value={receivedQuantity}
            />
            <TableLineItem
              title={t('general.unitPrice', 'Unit Price')}
              tooltip={t(
                'invoices.invoice.invoiceLineModal.unitPriceTooltip',
                'The tax exclusive Unit Price on the  linked Purchase Order line.'
              )}
              value={formatMoney(unitPrice)}
            />
            <TableLineItem
              title={t('general.tax%', 'Tax %')}
              tooltip={t(
                'invoices.invoice.invoiceLineModal.taxPercentageTooltip',
                'The Tax Percentage on the Purchase Order line that is added to the Purchase Order Unit Price.'
              )}
              value={`${taxPercentage}%`}
            />{' '}
          </div>
        </Accordion>
      )}
    </section>
  )
}
