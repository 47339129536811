import { useTranslation } from 'react-i18next'

import InvoiceLinkedStatus from '../../InvoiceLinkedStatus'
import InvoiceReceivedStatus from '../../InvoiceReceivedStatus'

import { InvoiceLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ProductSummary } from '@/modules/shared/components'
import { TableLineItem } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'

interface LineDetailsProps {
  invoiceLineItem: InvoiceLineItem
}

export default function LineDetails({ invoiceLineItem }: LineDetailsProps) {
  const { t } = useTranslation()
  const {
    product,
    taxPercentage,
    purchaseOrderLineItem,
    totalInvoicedQuantity,
    totalReceivedQuantity,
    invoicePriceVariance,
    unitPrice,
    receivedStatus,
    quantity,
  } = invoiceLineItem
  const { formatMoney } = useMoney()
  const quantityVariance = (totalInvoicedQuantity || 0) - (totalReceivedQuantity || 0)

  return (
    <section>
      <strong className="text-sm">{t('general.lineOverview', 'Line Overview')}</strong>
      <p className="text-sm text-gray-500">
        {t('invoices.invoice.invoiceLineModal.lineOverviewDesc', 'A summary of the Invoice line.')}
      </p>
      {/* TODO: will be replaced with an image when it's available */}
      <ProductSummary product={product} unitPrice={Number(unitPrice)} taxPercentage={taxPercentage} />
      <div className="mt-4 border" data-testid="invoice-line-details">
        <TableLineItem
          title={t('general.linkedStatus', 'Linked Status')}
          tooltip={t(
            'invoices.invoice.invoiceLineModal.linkedStatusTooltip',
            'The Linked status indicates if the Invoice line is linked to a Purchase Order line.'
          )}
          value={<InvoiceLinkedStatus purchaseOrder={!!purchaseOrderLineItem} />}
        />
        <TableLineItem
          title={t('general.receivedStatus', 'Received Status')}
          tooltip={t(
            'invoices.invoice.invoiceLineModal.receivedStatusTooltip',
            'The Received status indicates if the Total Received Quantity is equal to or greater than the Total Invoiced Quantity.'
          )}
          value={receivedStatus && <InvoiceReceivedStatus status={receivedStatus} />}
        />
        <TableLineItem
          title={t('general.purchaseOrderLineId', 'Purchase Order Line ID')}
          tooltip={t(
            'invoices.invoice.invoiceLineModal.invoicePurchaseOrderLineIdTooltip',
            'The unique identifier of the Purchase Order line that this Invoice Line is linked to.'
          )}
          value={purchaseOrderLineItem?.id}
        />
        <TableLineItem
          title={t('general.invoiceLineQuantity', 'Invoice Line Quantity')}
          tooltip={t(
            'invoices.invoice.invoiceLineModal.invoiceQuantityTooltip',
            'The quantity shown on the Invoice line of this Invoice.'
          )}
          value={quantity}
        />
        <TableLineItem
          title={t('general.totalInvoicedQuantity', 'Total Invoiced Quantity')}
          tooltip={t(
            'invoices.invoice.invoiceLineModal.totalInvoicedQuantityTooltip',
            'If this Invoice line is linked to a Purchase Order line, this is the total quantity of all Invoice lines linked to the Purhcase Order line.'
          )}
          value={totalInvoicedQuantity}
        />
        <TableLineItem
          title={t('general.poLineQuantity', 'PO Line Quantity')}
          tooltip={t(
            'invoices.invoice.invoiceLineModal.poQuantityTooltip',
            'If this Invoice line is linked to a Purchase Order line, this is the Quantity shown on the linked Purchase Order line.'
          )}
          value={purchaseOrderLineItem?.quantity || '--'}
        />
        <TableLineItem
          title={t('general.totalReceivedQuantity', 'Total Received Quantity')}
          tooltip={t(
            'invoices.invoice.invoiceLineModal.totalReceivedQuantityTooltip',
            'The Quantity that has been marked as received on one or more Receiving Notes associated to the linked Purchase Order.'
          )}
          value={totalReceivedQuantity}
        />
        <TableLineItem
          title={t('general.quantityVariance', 'Quantity Variance')}
          tooltip={t(
            'invoices.invoice.invoiceLineModal.quantityVarianceTooltip',
            'The difference between the total Quantity of all linked Invoice lines, and the total Received Quantity on all Receiving Notes associated to the Invoice line.'
          )}
          value={quantityVariance.toFixed(1)}
          extraInfo={quantityVariance === 0 && <p className="text-success">{t('general.noVariance', 'No Variance')}</p>}
        />
        <TableLineItem
          title={t('general.invoiceUnitPrice', 'Invoice Unit Price')}
          tooltip={t(
            'invoices.invoice.invoiceLineModal.invoiceUnitPriceTooltip',
            'The tax exclusive Unit Price on the Invoice line.'
          )}
          value={formatMoney(unitPrice)}
        />
        <TableLineItem
          title={t('general.invoiceTax%', 'Invoice Tax %')}
          tooltip={t(
            'invoices.invoice.invoiceLineModal.invoiceTaxPercentageTooltip',
            'The Tax Percentage on the Invoice line that will be added to the Invoice Unit Price.'
          )}
          value={`${taxPercentage || 0}%`}
        />
        <TableLineItem
          title={t('general.poUnitPrice', 'PO Unit Price')}
          tooltip={t(
            'invoices.invoice.invoiceLineModal.poUnitPriceTooltip',
            'If this Invoice line is linked to a Purchase Order line, this is the tax exclusive Unit Price on the linked Purchase Order line.'
          )}
          value={purchaseOrderLineItem?.unitPrice ? formatMoney(purchaseOrderLineItem?.unitPrice) : '--'}
        />
        <TableLineItem
          title={t('general.poTax%', 'PO Tax %')}
          tooltip={t(
            'invoices.invoice.invoiceLineModal.poTaxPercentageTooltip',
            'If this Invoice line is linked to a Purchase Order line, this is the Tax Percentage on the Purchase Order line that is added to the Purchase Order Unit Price.'
          )}
          value={purchaseOrderLineItem ? `${purchaseOrderLineItem?.taxPercentage}%` : '--'}
        />
        <TableLineItem
          title={t('general.unitPriceVariance', 'Unit Price Variance')}
          tooltip={t(
            'invoices.invoice.invoiceLineModal.unitPriceVarianceTooltip',
            'If this Invoice line is linked to a Purchase Order line, this is the difference between the Invoice line Unit Price and the Unit Price on the linked Purchase Order line.'
          )}
          value={formatMoney(invoicePriceVariance)}
          extraInfo={
            (invoicePriceVariance === 0 || invoicePriceVariance === null) && (
              <p className="text-success">{t('general.noVariance', 'No Variance')}</p>
            )
          }
        />
      </div>
    </section>
  )
}
