import { useLazyQuery, useMutation } from '@apollo/client'
import { Listbox } from '@headlessui/react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import NoImage from '@/assets/images/no-image.svg'
import {
  Catalogue,
  CreateCataloguedProductsDocument,
  GetAllBuyListsDocument,
  RansackDirection,
  RequisitionLine,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { Spinner } from '@/modules/requisitions/components'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Button, Checkbox, Modal, QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

interface AddToBuyListModalProps {
  requisitionLine: RequisitionLine
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
}

export default function AddToBuyListModal({ requisitionLine, showModal, setShowModal }: AddToBuyListModalProps) {
  const { image, supplier, product, productId } = requisitionLine
  const { brand, itemDescription, itemMeasure, itemPackName, itemSize, concatenatedSellUnit } = product || {}
  const { t } = useTranslation()

  const [selectedBuyLists, setSelectedBuyLists] = useState<number[]>([])
  const [createCataloguedProduct, { loading: actionLoading }] = useMutation(CreateCataloguedProductsDocument, {
    context: { uri: PURCHASING_GRAPHQL_API },
  })
  const [fetchBuyList, { data, error, fetchMore, refetch, networkStatus }] = useLazyQuery(GetAllBuyListsDocument, {
    variables: {
      after: null,
      filter: {},
      cataloguedProductsFilter: {
        q: [
          {
            property: 'productId_eq',
            value: productId,
          },
        ],
      },
      sort: [
        {
          property: 'updatedAt',
          direction: RansackDirection.Desc,
        },
      ],
    },
    notifyOnNetworkStatusChange: true,
    context: { uri: PURCHASING_GRAPHQL_API },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (showModal) fetchBuyList()
  }, [showModal])

  const buyLists = extractEdges<Catalogue>(data?.currentPurchaser?.catalogues)

  const { loading } = checkNetworkStatus(networkStatus)

  const onCloseModal = () => {
    setShowModal(false)
    setSelectedBuyLists([])
  }
  const onSave = () => {
    createCataloguedProduct({
      variables: {
        input: { productId: Number(productId), catalogueIds: selectedBuyLists },
      },
      onCompleted() {
        onCloseModal()
        refetch()
      },
    })
  }

  const onCheckProductAlreadyInBuyList = (buyList: Catalogue): boolean => {
    return (buyList.cataloguedProducts?.edges || []).length > 0
  }

  const onFetchMoreBuyLists = () => {
    fetchMore({ variables: { after: data?.currentPurchaser?.catalogues?.pageInfo.endCursor } })
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel className="flex h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]">
        <Modal.Title
          title={t('shopPage.productList.addToBuyListModal.title', 'Add to Buy List')}
          onCloseModal={onCloseModal}
        />

        <Modal.Body id="InfiniteScroll">
          <section className="flex items-center gap-x-4 rounded-md border p-4">
            <img className="h-28 w-28 border" src={image || NoImage} alt="product" />
            <span>
              <p className="text-sm text-primary">
                {brand} {itemDescription}
              </p>
              <span className="text-xxs text-gray-500 sm:text-xs">
                {formatProductItem({
                  itemMeasure: itemMeasure,
                  itemPackName: itemPackName,
                  itemSize: itemSize,
                  itemSellUnit: concatenatedSellUnit,
                })}
              </span>
              <p className="text-xs">{supplier?.name}</p>
            </span>
          </section>
          <section className="mt-4 space-y-2">
            <div>
              <h2 className="text-sm font-semibold">
                {t('shopPage.productList.addToBuyListModal.addToOrgBuyLists', 'Add to Organisation Buy Lists')}
              </h2>
              <p className="text-sm text-gray-500">
                {t(
                  'shopPage.productList.addToBuyListModal.addToOrgBuyListsDesc',
                  'You can add this product a Buy List at your Organisation by selecting a Buy List below. If the product has already been added to a Buy List, the Buy List will appear already checked.'
                )}
              </p>
            </div>
            <div>
              <QueryResult error={error} loading={loading} size="md">
                <InfiniteScroll
                  dataLength={buyLists.length}
                  next={onFetchMoreBuyLists}
                  hasMore={!!data?.currentPurchaser?.catalogues?.pageInfo.hasNextPage}
                  loader={<Spinner className="mt-5 h-10 md:w-16" />}
                  scrollableTarget="InfiniteScroll"
                >
                  <div className="rounded-md border bg-gray-100">
                    <Listbox value={selectedBuyLists} onChange={(e) => setSelectedBuyLists(e)} multiple>
                      <Listbox.Options static>
                        {buyLists.map((buyList) => {
                          const isChecked =
                            onCheckProductAlreadyInBuyList(buyList) || selectedBuyLists?.includes(Number(buyList.id))
                          return (
                            <Listbox.Option
                              disabled={onCheckProductAlreadyInBuyList(buyList)}
                              key={buyList.id}
                              value={buyList.id}
                              data-testid={`list-${buyList.id}`}
                              className="cursor-pointer items-center gap-x-2 border-b p-3 text-sm last:border-b-0"
                            >
                              <Checkbox
                                aria-label={String(buyList.title)}
                                isSelected={isChecked}
                                disabled={onCheckProductAlreadyInBuyList(buyList)}
                              >
                                <span className="flex w-full items-center justify-between">
                                  <p>{buyList.title}</p>
                                  {onCheckProductAlreadyInBuyList(buyList) && (
                                    <p>{t('general.alreadyAdded', 'Already Added')}</p>
                                  )}
                                </span>
                              </Checkbox>
                            </Listbox.Option>
                          )
                        })}
                      </Listbox.Options>
                    </Listbox>
                  </div>
                </InfiniteScroll>
              </QueryResult>
            </div>
          </section>
        </Modal.Body>
        <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
          <Button
            type="button"
            className="h-11 w-full rounded-md bg-gray-200 px-8 text-sm md:w-fit"
            onClick={onCloseModal}
          >
            {t('general.cancel', 'Cancel')}
          </Button>
          {selectedBuyLists.length > 0 && (
            <Button
              data-testid="on-save-button"
              className="h-11 w-full rounded-md bg-primary px-5 text-sm text-white md:w-fit"
              onClick={onSave}
              loading={actionLoading}
            >
              {t('shopPage.productList.addToBuyListModal.addToBuyList', 'Add to Buy List')}
            </Button>
          )}
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
