import { Fallback } from '@/modules/shared/components'

export interface SimpleTableItemProps {
  title: string
  value?: string | number | null | JSX.Element
}

function SimpleTableItem({ title, value }: SimpleTableItemProps) {
  return (
    <div className="flex flex-col">
      <p className="flex items-center gap-x-1 font-bold">{title}</p>
      <Fallback as="p" condition={!!value}>
        {value}
      </Fallback>
    </div>
  )
}

export default SimpleTableItem
