import { graphql } from 'msw'

import { DeleteRequisitionAttachmentMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const DeleteRequisitionAttachment = graphql.mutation<DeleteRequisitionAttachmentMutation>(
  'DeleteRequisitionAttachment',
  (_, res, ctx) => {
    return res(
      ctx.data({
        deleteRequisitionAttachment: {
          success: 'Resource was deleted successfully',
          errors: [],
        },
      })
    )
  }
)
