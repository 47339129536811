import { graphql } from 'msw'

import { GetCreditNoteCommentsQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetCreditNoteComments: GetCreditNoteCommentsQuery = {
  currentPurchaser: {
    id: 44926,
    __typename: 'Purchaser',
    creditNote: {
      id: 813,
      creditNoteNumber: '12314',
      commentsCount: 2,
      __typename: 'CreditNote',
      privateComments: {
        edges: [
          {
            node: {
              id: 1985736,
              body: 'Private Comment',
              createdAt: '2023-06-19T14:00:52+10:00',
              __typename: 'Comment',
              creator: {
                id: 23783,
                fullName: 'Duong Nguyen',
                __typename: 'User',
              },
            },
            __typename: 'CommentEdge',
          },
        ],
        __typename: 'CommentConnection',
      },
      publicComments: {
        edges: [
          {
            node: {
              id: 1985735,
              body: 'Public Comment',
              createdAt: '2023-06-19T14:00:50+10:00',
              __typename: 'Comment',
              creator: {
                id: 23784,
                fullName: 'Chris Dat Truong',
                __typename: 'User',
              },
            },
            __typename: 'CommentEdge',
          },
        ],
        __typename: 'CommentConnection',
      },
    },
  },
}

export const GetCreditNoteComments = graphql.query<GetCreditNoteCommentsQuery>(
  'GetCreditNoteComments',
  (_, res, ctx) => {
    return res(ctx.data(baseGetCreditNoteComments))
  }
)

export default baseGetCreditNoteComments
