import { useTranslation } from 'react-i18next'

import { useMoney } from '@/modules/shared/hooks'

interface TotalTaxInclusiveProps {
  totalValue: number | null | undefined
}

export const TotalTaxInclusive = ({ totalValue }: TotalTaxInclusiveProps) => {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  return (
    <>
      <p className="text-base font-bold">{formatMoney(totalValue)}</p>
      <p className="text-xs text-gray-600">{t('general.taxInclusive', 'Tax Inclusive')}</p>
    </>
  )
}
