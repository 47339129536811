import { graphql } from 'msw'

import { CreateCataloguedProductMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const CreateCataloguedProduct = graphql.mutation<CreateCataloguedProductMutation>(
  'CreateCataloguedProduct',
  (_, res, ctx) => {
    return res(
      ctx.data({
        createCataloguedProduct: {
          cataloguedProduct: {
            id: 22676877,
            productId: 5814401,
            __typename: 'CataloguedProduct',
          },
          __typename: 'CreateCataloguedProductMutationPayload',
        },
      })
    )
  }
)
