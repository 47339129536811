import { graphql } from 'msw'

import { ReopenInvoiceMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseReopenInvoice: ReopenInvoiceMutation = {
  reopenInvoice: {
    invoice: {
      id: 7524140,
      state: 'open',
      __typename: 'Invoice',
    },
    __typename: 'ReopenInvoiceMutationPayload',
  },
}

export const ReopenInvoice = graphql.mutation<ReopenInvoiceMutation>('ReopenInvoice', (_, res, ctx) => {
  return res(ctx.data(baseReopenInvoice))
})

export default baseReopenInvoice
