import { useMutation } from '@apollo/client'
import { produce } from 'immer'

import {
  DeleteRequisitionAttachmentDocument,
  GetRequisitionAttachmentsQuery,
  RansackDirection,
  RequisitionAttachmentEdge,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { GET_REQUISITION_ATTACHMENTS } from '@/graphql/purchasing/operations/queries/getRequisitionAttachments'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useDeleteRequisitionAttachment(attachmentId: number, requisitionId: number) {
  const mutation = useMutation(DeleteRequisitionAttachmentDocument, {
    update(cache) {
      const queryToCache = {
        query: GET_REQUISITION_ATTACHMENTS,
        variables: {
          requisitionId: Number(requisitionId),
          sort: [
            {
              direction: RansackDirection.Desc,
              property: 'createdAt',
            },
          ],
        },
      }

      const data = cache.readQuery(queryToCache) as GetRequisitionAttachmentsQuery
      const newData = produce(data, (draft) => {
        const { edges } = draft.currentPurchaser?.requisition?.requisitionAttachments || {}
        draft.currentPurchaser!.requisition!.requisitionAttachments!.edges = edges?.filter((e) => {
          return e?.node?.id !== attachmentId
        }) as RequisitionAttachmentEdge[]
      })

      cache.writeQuery({ ...queryToCache, data: newData })
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  return mutation
}
