import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { GetRequisitionDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useGetRequisition() {
  const { requisitionId } = useParams<{ requisitionId: string }>()

  const { loading, error, data } = useQuery(GetRequisitionDocument, {
    variables: {
      requisitionId: Number(requisitionId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  return { loading, error, requisition: data?.currentPurchaser?.requisition }
}
