import { useTranslation } from 'react-i18next'

import Fallback from '../../../fallback'
import PreferredSupplier from '../../PreferredSupplier'

import { ProductListType, ProductType } from '@/modules/shared/types'

export interface ProductInformationProps {
  product: ProductType
  type: ProductListType
}

export default function ProductInformation({ type, product }: ProductInformationProps) {
  const { t } = useTranslation()
  const { preferredSupplier, supplier, availableQuotes } = product || {}

  switch (type) {
    case ProductListType.Shop:
      return <PreferredSupplier preferredSupplier={!!preferredSupplier} supplierName={supplier?.name} />
    case ProductListType.AddInvoiceLine:
    case ProductListType.BuyListBuilder:
      return (
        <>
          {t('buyLists.buyListBuilder.supplierCount', {
            defaultValue_one: '{{ count }} Supplier',
            defaultValue_many: '{{ count }} Suppliers',
            defaultValue_other: '{{ count }} Suppliers',
            count: availableQuotes?.length,
          })}
        </>
      )
    case ProductListType.AddCatalogProduct:
      return (
        <Fallback
          condition={!!product?.product?.catalogued}
          fallbackValue={t('general.notInCatalog', 'Not in Catalog')}
        >
          {t('general.addedToCatalog', 'Added To Catalog')}
        </Fallback>
      )
  }
}
