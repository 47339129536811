import { graphql } from 'msw'

import { GetRequisitionInvoicesQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetRequisitionInvoices: GetRequisitionInvoicesQuery = {
  currentPurchaser: {
    id: 44926,
    requisition: {
      id: 5235941,
      requisitionNumber: 'PR04242',
      invoices: {
        edges: [
          {
            node: {
              id: 5810834,
              invoiceNumber: 'C8221725',
              invoiceDate: '2020-06-23',
              state: 'flagged',
              totalTaxValue: 679.13,
              totalValue: 7470.41,
              reference: null,
              department: {
                id: 1001,
                name: 'Department 1',
              },
              account: {
                id: 96429,
                accountName: '0060110 - Postage Fund',
                code: '0060110',
                __typename: 'Account',
              },
              purchaseOrder: {
                id: 16564972,
                purchaseOrderNumber: 'PO03202',
                requisition: {
                  id: 5235941,
                  reference: null,
                  __typename: 'Requisition',
                },
                __typename: 'PurchaseOrder',
              },
              __typename: 'Invoice',
            },
            __typename: 'InvoiceEdge',
          },
        ],
        __typename: 'InvoiceConnection',
      },
      __typename: 'Requisition',
    },
    __typename: 'Purchaser',
  },
}

export const GetRequisitionInvoices = graphql.query<GetRequisitionInvoicesQuery>(
  'GetRequisitionInvoices',
  (req, res, ctx) => {
    return res(ctx.data(baseGetRequisitionInvoices))
  }
)

export default baseGetRequisitionInvoices
