import { useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { RequisitionFormInputs } from '../../types'
import { formatStateForLocale, generateShopPath } from '../../utils'
import { DeptAndAccount, SelectAddress, SendPurchaseOrder } from '../create/form-elements'

import {
  Account,
  DeliveryAddress,
  GetRequisitionDocument,
  Purchaser,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { useSubmitRequisition } from '@/modules/requisitions/hooks'
import { Button, SimpleTileViewItem } from '@/modules/shared/components'
import FormDate from '@/modules/shared/components/create-form/FormDate'
import FormInput from '@/modules/shared/components/create-form/FormInput'
import QueryResult from '@/modules/shared/components/query-result'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useMoney } from '@/modules/shared/hooks'
import { ChevronLeftIcon } from '@/modules/shared/icons'

export default function Submit() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { requisitionId } = useParams<{ requisitionId: string }>()
  const { formatMoney } = useMoney()
  const formMethods = useForm<RequisitionFormInputs>()

  const { data, loading, error } = useQuery(GetRequisitionDocument, {
    variables: {
      requisitionId: Number(requisitionId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    onCompleted(data) {
      formMethods.setValue('reference', String(data.currentPurchaser?.requisition?.reference))
      formMethods.setValue('sendToSupplier', Boolean(data.currentPurchaser?.requisition?.sendToSupplier))
      formMethods.setValue('deliveryAddressId', Number(data.currentPurchaser?.requisition?.deliveryAddress?.id))
      formMethods.setValue('expectedDeliveryDate', data.currentPurchaser?.requisition?.expectedDeliveryDate)
      formMethods.setValue('departmentId', Number(data.currentPurchaser?.requisition?.department?.id))
      formMethods.setValue('accountId', Number(data.currentPurchaser?.requisition?.account?.id))
    },
  })
  const [submitRequisition, { loading: submitLoading }] = useSubmitRequisition()

  const onSubmit: SubmitHandler<RequisitionFormInputs> = (data) => {
    submitRequisition({
      variables: {
        input: {
          ...data,
          id: Number(requisitionId),
        },
        id: Number(requisitionId),
      },
    })
  }

  const {
    reference,
    requisitionNumber,
    totalValue,
    originName,
    state,
    approvalWorkflow,
    account,
    department,
    advanced,
    totalValueExcludingTax,
    totalTaxValue,
    sendToSupplier,
    deliveryAddress,
    expectedDeliveryDate,
  } = data?.currentPurchaser?.requisition || {}

  return (
    <>
      <Helmet>
        <title>{t('general.confirmPrSubmission', 'Confirm PR Submission')}</title>
      </Helmet>
      <header className="px-4 py-3">
        <Button
          data-testid="action-return"
          onClick={() => navigate(-1)}
          className="flex h-11 w-fit items-center justify-between gap-x-2 rounded-md bg-gray-200 px-4"
        >
          <ChevronLeftIcon className="-ml-2 h-5 w-5" />
          <p className="text-sm">{t('confirmRequisition.back', 'Cancel and Go Back')}</p>
        </Button>
      </header>
      <div className="mx-auto w-full px-4 py-10 lg:w-[43.625rem]">
        <QueryResult loading={loading} error={error}>
          <div>
            {requisitionId && data?.currentPurchaser && (
              <>
                <div className="flex flex-col">
                  <h1 className="text-center text-2xl font-bold">
                    {t('general.confirmSubmission', 'Confirm Submission')}
                  </h1>
                  <p className="mt-2 text-center text-sm text-gray-500">
                    {t(
                      'confirmRequisition.pleaseConfirm',
                      'Please confirm the details below and submit the Requisition for Approval. Based on the Approval Workflow, approving users will be notified by email and they will be able to approve or decline your Requisition. Purchase Orders will be generated for the Suppliers included in the Requisition only once all required approvals have been granted.'
                    )}
                  </p>
                </div>
                <div className="mt-8">
                  <div className="mb-6 rounded-md border bg-white">
                    <div className="flex flex-col justify-between p-4 md:flex-row">
                      <div>
                        <span className="break-all text-base font-bold">{reference || requisitionNumber}</span>
                        <p className="text-sm text-gray-500">{requisitionNumber}</p>
                      </div>
                      <div>
                        <div className="mt-4 text-left md:mt-0 md:text-right">
                          <span className="text-base font-bold">{`${formatMoney(totalValue)}`}</span>
                          <p className="text-sm text-gray-500">
                            {t('general.total', 'Total')} ({t('general.includingTax', 'Including Tax')})
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <section data-testid="requisition-details" className="p-4 text-sm">
                      <SimpleTileViewItem
                        className="mb-3"
                        title={t('general.requisitionType', 'Requisition Type')}
                        value={originName}
                      />
                      <SimpleTileViewItem
                        className="mb-3"
                        title={t('general.status', 'Status')}
                        value={formatStateForLocale(state || '')}
                      />
                      <SimpleTileViewItem
                        className="mb-3"
                        title={t('general.approvalWorkflow', 'Approval Workflow')}
                        value={approvalWorkflow?.title}
                      />
                      <SimpleTileViewItem
                        className="mb-3"
                        title={t('general.totalExcludingTaxBrackets', 'Total (Excluding Tax)')}
                        value={`${formatMoney(totalValueExcludingTax)}`}
                      />
                      <SimpleTileViewItem
                        className="mb-3"
                        title={t('general.tax', 'Tax')}
                        value={`${formatMoney(totalTaxValue)}`}
                      />
                      <SimpleTileViewItem
                        className="mb-3"
                        title={t('general.totalIncludingTaxBrackets', 'Total (Including Tax)')}
                        value={`${formatMoney(totalValue)}`}
                      />
                    </section>
                  </div>
                </div>
                <form onSubmit={formMethods.handleSubmit(onSubmit)} className="mb-80">
                  <FormProvider {...formMethods}>
                    <FormInput
                      title={t('general.reference', 'Reference')}
                      description={t(
                        'createRequisition.reference.explain',
                        'Please include a brief description of the Purchase Requisition. This reference will appear on all Purchase Orders that are created when this Requisition is submitted and fully approved. The reference will be visible to Suppliers and anyone at your organisation.'
                      )}
                      register="reference"
                      placeholder={t('createRequisition.reference.placeholder', 'E.g. Beverage Order')}
                    />
                    <SendPurchaseOrder
                      label={t('createRequisition.sendPO.label', 'Send Purchase Orders to Suppliers')}
                      defaultValue={Boolean(sendToSupplier)}
                      title={t('createRequisition.sendPO.title', 'Send Purchase Orders to Suppliers?')}
                      description={t(
                        'createRequisition.sendPO.explain',
                        "Decide if you'd like a Purchase Order to be sent to each Supplier once the Requisition has been fully approved, and the Purchase Orders are marked as Sent. If you leave this option un-checked, Purchase Orders will still be generated for each Supplier, but they will not be electronically sent to the Supplier, even when the Purchase Order is marked as Sent. There may be reasons why you don't need the Supplier to receive it, for example you may have phoned ahead and placed your order and you don't wish to send the Supplier a duplicate order."
                      )}
                    />
                    <SelectAddress
                      defaultValue={deliveryAddress as DeliveryAddress}
                      title={t('general.deliveryAddress', 'Delivery Address')}
                      description={t(
                        'createRequisition.address.explain',
                        'Select a delivery address from the list below. Additional delivery addresses can be added by an administrator at your organisation. Please speak with your administrator to request additional delivery addresses.'
                      )}
                    />
                    <FormDate
                      defaultDate={expectedDeliveryDate}
                      title={t('general.deliveryDate', 'Delivery Date')}
                      description={t(
                        'createRequisition.deliveryDate.explain',
                        "Please select the date you'd like to take delivery of this order. This date will be included on all Purchase Orders that are created when this Requisition is submitted and fully approved. The date will be visible to Suppliers and anyone at your organisation."
                      )}
                      register="expectedDeliveryDate"
                    />
                    <DeptAndAccount
                      defaultDepartmentValue={department as Purchaser}
                      defaultAccountValue={account as Account}
                      title={t('createRequisition.deptAndAccount.title', 'Department and Account Code')}
                      description={t(
                        'createRequisition.deptAndAccount.explain',
                        'If applicable, select a Department then an Account code to record this Requisition against. Your organisation may use Department and Account Codes for recording Requisition expenses.'
                      )}
                    />
                  </FormProvider>
                  <Button
                    type="submit"
                    data-testid="action-submit"
                    className="mb-2 mt-8 block h-11 w-full rounded-md bg-success px-3 text-center text-sm text-white"
                    loading={submitLoading}
                  >
                    {t('general.submitForApproval', 'Submit for Approval')}
                  </Button>
                  <Link
                    data-testid="action-return-2"
                    to={generateShopPath(requisitionId, advanced)}
                    className="flex h-11 w-full items-center justify-center rounded-md bg-gray-200 px-3 text-center text-sm transition duration-200 hover:brightness-95"
                  >
                    <p className="text-sm">{t('confirmRequisition.back', 'Cancel and Go Back')}</p>
                  </Link>
                </form>
              </>
            )}
          </div>
        </QueryResult>
      </div>
    </>
  )
}
