import { graphql } from 'msw'

import { GetCurrentUserQuery } from '@/graphql/access/generated/access_graphql'

const baseGetCurrentUserObject: GetCurrentUserQuery = {
  currentUser: {
    id: 23783,
    email: 'duong.nguyen@marketboomer.com',
    fullName: 'Duong Nguyen',
    isAdmin: true,
    __typename: 'User',
  },
}

export const GetCurrentUser = graphql.query<GetCurrentUserQuery>('GetCurrentUser', (_, res, ctx) => {
  return res(ctx.data(baseGetCurrentUserObject))
})

export default baseGetCurrentUserObject
