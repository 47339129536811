import { Controller, useFormContext } from 'react-hook-form'

import { RequisitionFormInputs } from '@/modules/requisitions/types'
import { Checkbox } from '@/modules/shared/components'

interface SendPurchaseOrderProps {
  defaultValue?: boolean
  showExplanation?: boolean
  disabled?: boolean
  title: string
  description: string
  label: string
}

export default function SendPurchaseOrder(props: SendPurchaseOrderProps) {
  const { defaultValue = true, showExplanation = true, disabled, title, description, label } = props

  const { control } = useFormContext<RequisitionFormInputs>()
  return (
    <section className="mt-8">
      {showExplanation && (
        <>
          <h2 className="text-sm font-semibold">{title}</h2>
          <p className="text-sm text-gray-500">{description}</p>
        </>
      )}
      <div className="mt-4">
        <label className="flex items-center text-sm">
          <Controller
            control={control}
            name="sendToSupplier"
            render={({ field }) => (
              <Checkbox
                dataTestId="sendpo-checkbox"
                onChange={(e) => !disabled && field.onChange(e)}
                defaultSelected={defaultValue}
                disabled={disabled}
              >
                {label}
              </Checkbox>
            )}
          />
        </label>
      </div>
    </section>
  )
}
