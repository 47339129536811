import { PropsWithChildren, ReactElement } from 'react'

import { Themes } from '../../types'
import ThemeWrapper from '../theme-wrapper'

interface TitleBarProps extends PropsWithChildren {
  icon: ReactElement
  title: string
  subtitle: string
  dataTestId?: string
}

const TitleBar = ({ icon, title, subtitle, children, dataTestId }: TitleBarProps) => {
  return (
    <section className="flex justify-between rounded-md bg-white p-4 shadow" data-testid={dataTestId}>
      <div className="flex items-center">
        <ThemeWrapper theme={Themes.Primary} className="flex h-14 w-14 items-center justify-center rounded-md ">
          {icon}
        </ThemeWrapper>
        <span className="ml-3">
          <p className="font-bold">{title}</p>
          <p className="text-sm text-gray-500">{subtitle}</p>
        </span>
      </div>
      {children}
    </section>
  )
}

export default TitleBar
