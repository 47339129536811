import { useQuery } from '@apollo/client'
import { StringParam, useQueryParam } from 'use-query-params'

import { GetBuyListDocument, RansackDirection } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { QueryParameter } from '@/modules/shared/types'

export const useGetBuyList = (buyListId: string) => {
  const [searchTermURLParam] = useQueryParam(QueryParameter.Search, StringParam)

  const { data, loading, fetchMore, networkStatus, error, refetch } = useQuery(GetBuyListDocument, {
    variables: {
      catalogueId: Number(buyListId),
      sortPrices: [{ direction: RansackDirection.Asc, property: 'unitPrice' }],
      filter: {
        q: [
          {
            property: 'product_itemDescription_or_product_brand_cont',
            value: searchTermURLParam || '',
          },
        ],
      },
    },
    context: { uri: PURCHASING_GRAPHQL_API },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  const onFetchMoreProducts = () => {
    fetchMore({
      variables: {
        after: data?.currentPurchaser?.catalogue?.cataloguedProducts?.pageInfo.endCursor,
      },
    })
  }

  const buyList = data?.currentPurchaser?.catalogue

  return { buyList, fetchMore, loading, refetch, data, networkStatus, error, onFetchMoreProducts }
}
