import { useMutation } from '@apollo/client'

import { DeleteCommentDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useCreateComment() {
  const mutation = useMutation(DeleteCommentDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })
  return mutation
}
