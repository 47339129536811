import { useTranslation } from 'react-i18next'

import { Delivery } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Accordion } from '@/modules/shared/components'
import { TableRowSingle } from '@/modules/shared/components/page-header-details-table'
import { DetailsContext } from '@/modules/shared/contexts/DetailsContext'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface DeliveryEventProps {
  delivery: Delivery
}

export default function DeliveryEvent({ delivery }: DeliveryEventProps) {
  const { createdAt, state, sender } = delivery
  const { t } = useTranslation()

  /**
   * Return translation string for delivery details for each Delivery type.
   *
   * @returns String
   */
  const handleDeliveryDetailsString = (deliveryState?: string | null) => {
    switch (deliveryState) {
      case 'resent':
        return t('general.resent', 'Resent')
      case 'sent':
        return t('general.sent', 'Sent')
      default:
        return t('general.unknown', 'Unknown')
    }
  }

  return (
    <Accordion title={formatDate(Dates.Long, createdAt)} buttonClassName="p-3 font-bold" testId="po-delivery">
      <DetailsContext.Provider value={{ tooltip: false }}>
        <div
          className="mt-2 w-full divide-y divide-gray-200 overflow-auto border border-gray-300"
          data-testid="po-delivery-details"
        >
          <TableRowSingle
            testId="sendDate"
            titleText={t('general.sendDate', 'Send Date')}
            value={formatDate(Dates.Long, createdAt)}
          />
          <TableRowSingle
            testId="state"
            titleText={t('general.state', 'State')}
            value={handleDeliveryDetailsString(state)}
          />
          <TableRowSingle testId="sentBy" titleText={t('general.sentBy', 'Sent By')} value={sender?.fullName} />
        </div>
      </DetailsContext.Provider>
    </Accordion>
  )
}
