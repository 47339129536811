import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useDeleteCataloguedProduct } from '../hooks/useDeleteCataloguedProduct'
import { useGetBuyList } from '../hooks/useGetBuyList'

import { Button, Modal } from '@/modules/shared/components'

interface RemoveProductModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<number | null>>
  cataloguedProductId: number
}

export default function RemoveProductModal({ cataloguedProductId, showModal, setShowModal }: RemoveProductModalProps) {
  const { t } = useTranslation()
  const { buyListId } = useParams<{ buyListId: string }>()
  const [deleteCataloguedProduct, { loading }] = useDeleteCataloguedProduct()
  const { refetch } = useGetBuyList(buyListId || '')

  const onCloseModal = () => {
    setShowModal(null)
  }

  const onRemoveProduct = () => {
    deleteCataloguedProduct({
      variables: {
        input: {
          id: cataloguedProductId,
        },
      },
      onCompleted() {
        refetch()
        onCloseModal()
      },
    })
  }

  return (
    <Modal dataTestId="remove-product-modal" showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel className="w-full max-w-md overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
        <Modal.Title
          title={t('buyList.removeItem.title', 'Remove Buy List Item')}
          testId="update-modal-title"
          className="text-lg font-medium leading-6 text-gray-900"
        />
        <p className="my-4 text-sm text-gray-500">
          {t(
            'buyList.removeItem.description',
            'Are you sure you want to remove this item from the Buy List? You can add it back in again later if you need to.'
          )}
        </p>
        <div className="mt-4 flex flex-col justify-end gap-2 md:flex-row">
          <Button
            type="button"
            className="h-11 w-full rounded-md bg-gray-200 px-8 text-sm md:w-fit"
            onClick={onCloseModal}
            data-testid="cancel-btn-bottom"
          >
            {t('general.cancel', 'Cancel')}
          </Button>
          <Button
            data-testid="remove-item-btn"
            onClick={onRemoveProduct}
            loading={loading}
            className="h-11 w-full rounded-md bg-error px-5 text-sm text-white md:w-fit"
          >
            {t('general.removeItem', 'Remove Item')}
          </Button>
        </div>
      </Modal.Panel>
    </Modal>
  )
}
