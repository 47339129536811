import { graphql } from 'msw'

import { CancelRequisitionMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const CancelRequisition = graphql.mutation<CancelRequisitionMutation>('CancelRequisition', (req, res, ctx) => {
  return res(
    ctx.data({
      cancelRequisition: {
        requisition: {
          id: 5235941,
          state: 'cancelled',
          __typename: 'Requisition',
        },
        __typename: 'CancelRequisitionMutationPayload',
      },
    })
  )
})
