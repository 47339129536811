import { useLazyQuery, useQuery } from '@apollo/client'
import { Dispatch, SetStateAction } from 'react'
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  GetSupplierRelationshipDocument,
  GetSupplierRelationshipsWithCatalogueDocument,
  SupplierRelationship,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { RequisitionFormInputs } from '@/modules/requisitions/types'
import { ComboboxServer } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { extractEdges } from '@/modules/shared/utils'

interface SelectSupplierProps {
  setSupplierRelationship?: Dispatch<SetStateAction<SupplierRelationship | null>>
  useSupplierId?: boolean
}

export default function SelectSupplier({ setSupplierRelationship, useSupplierId }: SelectSupplierProps) {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
    resetField,
  } = useFormContext<RequisitionFormInputs>()
  const {
    data: suppliersData,
    networkStatus,
    refetch,
    fetchMore,
  } = useQuery(GetSupplierRelationshipsWithCatalogueDocument, {
    variables: {
      searchText: '',
      first: 25,
      after: null,
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
  })
  const [getSupplierRelationship] = useLazyQuery(GetSupplierRelationshipDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const onSearchSupplierRelationship = (text: string) => {
    refetch({
      searchText: text,
    })
  }

  const applyNewDefaultDepartmentAndAccount = (id: number) => {
    if (setSupplierRelationship) {
      resetField('departmentId')
      resetField('accountId')
      getSupplierRelationship({
        variables: { id },
        onCompleted(data) {
          const supplierRelationship = data?.currentPurchaser?.supplierRelationship
          setSupplierRelationship(supplierRelationship as SupplierRelationship)
        },
      })
    }
  }

  const onSelected = (field: ControllerRenderProps<RequisitionFormInputs, 'originId'>, e: SupplierRelationship) => {
    const id = useSupplierId ? e.supplierId : e.id
    field.onChange(id)
    applyNewDefaultDepartmentAndAccount(Number(id))
  }

  return (
    <section className="mt-8">
      <h2 className="text-sm font-semibold">{t('general.selectASupplier', 'Select a Supplier')}</h2>
      <p className="text-sm text-gray-500">
        {t('createRequisition.supplier.explain', 'Please select a Supplier you wish to order products from.')}
      </p>
      <div className="mt-2">
        <Controller
          control={control}
          name="originId"
          rules={{ required: true }}
          render={({ field }) => (
            <ComboboxServer
              networkStatus={networkStatus}
              placeholder={t('general.selectASupplierDots', 'Select a Supplier...')}
              keyExtractor={(e) => String(e.id)}
              onDisplay={(e) => String(e.supplierName)}
              items={extractEdges<SupplierRelationship>(
                suppliersData?.currentPurchaser?.supplierRelationshipsWithCatalogue
              )}
              onInputChange={onSearchSupplierRelationship}
              onSelected={(e) => {
                onSelected(field, e)
              }}
              hasError={Boolean(errors.originId)}
              errorMessage={t('createRequisition.supplier.required', 'You must select a Supplier.')}
              onFetchMore={() =>
                fetchMore({
                  variables: {
                    after: suppliersData?.currentPurchaser?.supplierRelationshipsWithCatalogue?.pageInfo.endCursor,
                  },
                })
              }
              hasMore={Boolean(
                suppliersData?.currentPurchaser?.supplierRelationshipsWithCatalogue?.pageInfo.hasNextPage
              )}
            />
          )}
        />
      </div>
    </section>
  )
}
