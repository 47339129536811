import { useTranslation } from 'react-i18next'

import SideNavLink from '../SideNavLink'
import { SideBarItem } from './PurchaserNavigation'

import { Accordion } from '@/modules/shared/components'
import { SettingsIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface SystemSettingsSideBarItemProps extends SideBarItem {
  systemSettingRoutesMatch: boolean
}

export const SystemSettingsSideBarItem = ({
  systemSettingRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
}: SystemSettingsSideBarItemProps) => {
  const { t } = useTranslation()
  return (
    <Accordion
      titlePrefixIcon={<SettingsIcon className="h-7 w-7" />}
      title={t('general.systemSettings', 'System Settings')}
      activeColor={systemSettingRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === 7}
      handleTriggerClick={() => changeOpenAccordionKey(7)}
      buttonClassName="h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="systemSettings-navigation"
        sideNavLinkItems={[
          {
            key: t('general.users', 'Users'),
            target: '/users',
          },
        ]}
      />
    </Accordion>
  )
}
