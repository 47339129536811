import { graphql } from 'msw'

import { CreatePricedCatalogMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseCreatePricedCatalog: CreatePricedCatalogMutation = {
  createPricedCatalogue: {
    pricedCatalogue: {
      id: 182672,
      __typename: 'PricedCatalogue',
    },
    __typename: 'CreatePricedCatalogueMutationPayload',
  },
}

export const CreatePricedCatalog = graphql.mutation<CreatePricedCatalogMutation>(
  'CreatePricedCatalog',
  (_, res, ctx) => {
    return res(ctx.data(baseCreatePricedCatalog))
  }
)

export default baseCreatePricedCatalog
