import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Product } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, ConfirmDialog } from '@/modules/shared/components'
import { Themes } from '@/modules/shared/types'

interface AddOrViewProductCodeProps {
  product: Product
}

export default function AddOrViewProductCode({ product }: AddOrViewProductCodeProps) {
  const { t } = useTranslation()
  const { productCode } = product
  const [showProductCodeModal, setShowProductCodeModal] = useState(false)

  if (!productCode)
    return (
      <>
        <Button
          data-testid="action-add-product-code"
          onClick={() => setShowProductCodeModal(true)}
          className="rounded-md bg-gray-200 px-3 py-1 text-xs"
        >
          {t('general.addCode', 'Add Code')}
        </Button>
        <ConfirmDialog
          theme={Themes.Primary}
          isOpen={showProductCodeModal}
          setIsOpen={setShowProductCodeModal}
          title={t('general.comingSoon', 'Coming Soon!')}
          description={t(
            'supplier.myProducts.addOrRemoveProductCode',
            'Soon you’ll be able to add or edit your Supplier Product Code here, for any product. We’re still working on this feature.'
          )}
          primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
          onPrimaryBtnClick={() => setShowProductCodeModal(false)}
        />
      </>
    )

  return <span>{productCode}</span>
}
