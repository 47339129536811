import { graphql } from 'msw'

import { GetAvailableProductsQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

export const GetAvailableProducts = graphql.query<GetAvailableProductsQuery>('GetAvailableProducts', (_, res, ctx) => {
  return res(
    ctx.data({
      currentPurchaser: {
        id: 44926,
        __typename: 'Purchaser',
        availableProducts: {
          edges: [
            {
              node: {
                id: 5814401,
                brand: 'Maredsous 8',
                itemDescription: 'Lager : [Belgian]',
                concatenatedDescription: 'Lager : [Belgian] 330 ml bottle',
                concatenatedSellUnit: 'carton of 24',
                itemPackName: 'bottle',
                itemMeasure: 'ml',
                itemSellPackName: 'carton',
                itemSize: 330,
                image: null,
                invoiced: false,
                catalogued: false,
                __typename: 'Product',
                prices: {
                  edges: [],
                  __typename: 'PriceConnection',
                },
                catalogues: {
                  edges: [
                    {
                      node: {
                        id: 182708,
                        title: 'test',
                        __typename: 'Catalogue',
                      },
                      __typename: 'CatalogueEdge',
                    },
                  ],
                  __typename: 'CatalogueConnection',
                },
                category: {
                  id: 109628,
                  name: 'Lager',
                  __typename: 'Category',
                  parent: {
                    id: 109446,
                    name: 'Beer',
                    __typename: 'Category',
                  },
                },
              },
              __typename: 'ProductEdge',
            },
            {
              node: {
                id: 20521,
                brand: 'Amici Food Group',
                itemDescription: 'Muffin Raspberry & White Chocolate : Mini',
                concatenatedDescription: 'Muffin Raspberry & White Chocolate : Mini 45 g',
                concatenatedSellUnit: 'each of 1',
                itemPackName: null,
                itemMeasure: 'g',
                itemSellPackName: 'each',
                itemSize: 45,
                image: null,
                invoiced: false,
                catalogued: false,
                __typename: 'Product',
                prices: {
                  edges: [],
                  __typename: 'PriceConnection',
                },
                catalogues: {
                  edges: [
                    {
                      node: {
                        id: 182708,
                        title: 'test',
                        __typename: 'Catalogue',
                      },
                      __typename: 'CatalogueEdge',
                    },
                  ],
                  __typename: 'CatalogueConnection',
                },
                category: {
                  id: 109623,
                  name: 'Muffins',
                  __typename: 'Category',
                  parent: {
                    id: 109445,
                    name: 'Bakery',
                    __typename: 'Category',
                  },
                },
              },
              __typename: 'ProductEdge',
            },
            {
              node: {
                id: 27131,
                brand: 'VSE',
                itemDescription: 'Pumpkin Scones Small 5cm (Minimum Order 12 Portions)',
                concatenatedDescription: 'Pumpkin Scones Small 5cm (Minimum Order 12 Portions) 1 each',
                concatenatedSellUnit: 'carton of 12',
                itemPackName: null,
                itemMeasure: 'each',
                itemSellPackName: 'carton',
                itemSize: 1,
                image: null,
                invoiced: false,
                catalogued: false,
                __typename: 'Product',
                prices: {
                  edges: [],
                  __typename: 'PriceConnection',
                },
                catalogues: {
                  edges: [
                    {
                      node: {
                        id: 182708,
                        title: 'test',
                        __typename: 'Catalogue',
                      },
                      __typename: 'CatalogueEdge',
                    },
                  ],
                  __typename: 'CatalogueConnection',
                },
                category: {
                  id: 109533,
                  name: 'Cakes and Slices',
                  __typename: 'Category',
                  parent: {
                    id: 109438,
                    name: 'Prepared Food and Meals',
                    __typename: 'Category',
                  },
                },
              },
              __typename: 'ProductEdge',
            },
          ],
          pageInfo: {
            endCursor: 'MjU',
            hasNextPage: true,
            __typename: 'PageInfo',
          },
          __typename: 'ProductConnection',
        },
      },
    })
  )
})
