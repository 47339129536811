import { graphql } from 'msw'

import { UpdateOrganisationMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseUpdateOrganisation: UpdateOrganisationMutation = {
  updateOrganisation: {
    organisation: {
      id: 44926,
      featureFlags: {
        inventory: false,
        promoBanners: false,
        quickCreate: false,
        __typename: 'OrganisationFeatureFlags',
      },
      __typename: 'Organisation',
    },
    __typename: 'UpdateOrganisationMutationPayload',
  },
}

export const UpdateOrganisation = graphql.mutation<UpdateOrganisationMutation>('UpdateOrganisation', (_, res, ctx) => {
  return res(ctx.data(baseUpdateOrganisation))
})

export default baseUpdateOrganisation
