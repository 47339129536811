import { ElementType, Fragment, PropsWithChildren } from 'react'

interface FallbackProps extends PropsWithChildren {
  /** Condition to check the existence of the children */
  condition: boolean

  /** The fallback value. Default is '--' */
  fallbackValue?: string

  /** Select the wrapper element type */
  as?: ElementType

  /** classNames for the selected wrapper element type. Note: classNames only for when you provide the `as` prop */
  className?: string
}

export default function Fallback({ children, condition, fallbackValue = '--', as, className }: FallbackProps) {
  const Element = as || Fragment
  const elementProps = as ? { className } : {}
  return <Element {...elementProps}>{condition ? children : fallbackValue}</Element>
}
