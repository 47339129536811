import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import AddDeptAndAccountModal from '@/modules/requisitions/components/lines-by-suppliers/AddDeptAndAccountModal'
import AddOrderInstructionModal from '@/modules/requisitions/components/lines-by-suppliers/AddOrderInstructionModal'
import { useDeleteAdvancedRequisitionLine } from '@/modules/requisitions/hooks'
import { Button, Tooltip } from '@/modules/shared/components'
import { NoteIcon, SettingsIcon, TrashIcon } from '@/modules/shared/icons'

interface PRLineActionsProps {
  requisitionLine: RequisitionLine
  isAddedToCart: (cataloguedProductId: number) => boolean
}

export default function PRLineActions({ requisitionLine, isAddedToCart }: PRLineActionsProps) {
  const { t } = useTranslation()
  const { requisitionId } = useParams<{ requisitionId: string }>()
  const { orderInstruction, department, account, cataloguedProductId } = requisitionLine
  const hasDeptAndAccount = department && account

  const [showAddOrderInstructionModal, setShowAddOrderInstructionModal] = useState(false)
  const [showAddDeptAndAccountModal, setShowAddDeptAndAccountModal] = useState(false)

  const [deleteAdvancedRequisitionLine, { loading }] = useDeleteAdvancedRequisitionLine(
    Number(requisitionId),
    Number(cataloguedProductId)
  )

  const onRemoveProductFromPR = (id: number) => {
    deleteAdvancedRequisitionLine({
      variables: {
        input: {
          id,
        },
      },
    })
  }

  return (
    <div className="flex items-center justify-center gap-x-1.5">
      <Tooltip
        content={t(
          'shopPage.cart.summary.table.noteTooltipContent',
          'Click to add order instruction notes to this product. The instruction will be included on the Purchase Order sent to the Supplier.'
        )}
      >
        <Button
          disabled={!isAddedToCart(Number(cataloguedProductId))}
          data-testid="add-order-instruction-btn"
          className={classNames('rounded-full  p-1 ', {
            'bg-primary/10 hover:bg-primary/20': orderInstruction,
            'text-primary': orderInstruction,
            'bg-gray-200': !orderInstruction,
            'text-gray-500': !orderInstruction,
          })}
          onClick={() => setShowAddOrderInstructionModal(true)}
        >
          <NoteIcon className="h-7 w-7" />
        </Button>
      </Tooltip>
      <Tooltip
        content={t(
          'shopPage.cart.summary.table.deptAndAccountTooltipContent',
          'Click to add a specific Department and Account cost centre code to this product. Your organisation may use cost centre codes to manage and track expenses.'
        )}
      >
        <Button
          disabled={!isAddedToCart(Number(cataloguedProductId))}
          data-testid="add-dept-account-btn"
          className={classNames('rounded-full  p-1 ', {
            'bg-primary/10 hover:bg-primary/20': hasDeptAndAccount,
            'text-primary': hasDeptAndAccount,
            'bg-gray-200': !hasDeptAndAccount,
            'text-gray-500': !hasDeptAndAccount,
          })}
          onClick={() => setShowAddDeptAndAccountModal(true)}
        >
          <SettingsIcon className="h-7 w-7" />
        </Button>
      </Tooltip>
      <Tooltip
        content={t(
          'shopPage.cart.summary.table.removeProductTooltipContent',
          'Click to remove this product from the Requisition.'
        )}
      >
        <Button
          data-testid="remove-product-btn"
          loading={loading}
          disabled={!isAddedToCart(Number(cataloguedProductId))}
          className="rounded-full bg-gray-200 p-1 text-gray-500"
          onClick={() => onRemoveProductFromPR(Number(requisitionLine.id))}
        >
          <TrashIcon className="h-7 w-7" />
        </Button>
      </Tooltip>
      <AddOrderInstructionModal
        showModal={showAddOrderInstructionModal}
        setShowModal={setShowAddOrderInstructionModal}
        line={requisitionLine}
      />
      <AddDeptAndAccountModal
        showModal={showAddDeptAndAccountModal}
        setShowModal={setShowAddDeptAndAccountModal}
        line={requisitionLine}
      />
    </div>
  )
}
