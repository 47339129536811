import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import { useGetStocktake } from '../../hooks/useGetStocktake'
import StocktakeStockCountTable from './table-view/StocktakeStockCountTable'

import StocktakesEmptyStateImg from '@/assets/images/empty-stocktakes.svg'
import { StockLocation } from '@/graphql/inventory/generated/inventory_graphql'
import { Spinner } from '@/modules/requisitions/components'
import EmptyState from '@/modules/shared/components/empty-state'
import { InventoryIcon } from '@/modules/shared/icons'

export const StocktakeStockCount = () => {
  const { t } = useTranslation()
  const { fetchMoreLoading, stockLocation, stockCounts, onFetchMoreStockCounts } = useGetStocktake()

  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center">
          <InventoryIcon className="h-10 w-10 " />
          <p className="ml-3 text-sm">{t('general.stockCount', 'Stock Count')}</p>
        </div>
      </div>
      <InfiniteScroll
        dataLength={stockCounts.length}
        next={onFetchMoreStockCounts}
        hasMore={!!stockLocation?.stockTake?.stockCounts?.pageInfo.hasNextPage}
        loader={fetchMoreLoading && <Spinner className="mt-5 h-14 md:w-16" />}
      >
        <StocktakeStockCountTable stockCounts={stockCounts} stockLocation={stockLocation as StockLocation} />
        {!fetchMoreLoading && stockCounts.length === 0 && (
          <EmptyState
            img={StocktakesEmptyStateImg}
            message={t('stocktakes.search.noStocktakes', "We couldn't find any Stocktakes to display.")}
          />
        )}
      </InfiniteScroll>
    </>
  )
}
