import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import InvoiceStatus from '@/modules/invoices/pages/all-invoices/InvoiceStatus'
import { MoreOptionsMenu, PPNewDetailLink } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import {
  AccountCodeItem,
  ShortDateItem,
  Tax,
  TotalTaxInclusive,
} from '@/modules/shared/components/table/table-column-items'
import { OptionIcon } from '@/modules/shared/icons'

interface TableviewProps {
  invoices: Invoice[]
}

function TableView(props: TableviewProps) {
  const { invoices } = props
  const { t } = useTranslation()

  return (
    <Table
      dataTestId="invoices-table-view"
      dataSource={invoices}
      keyExtractor={(record) => String(record.id)}
      columns={[
        {
          title: t('general.invoice', 'Invoice'),
          key: 'invoice',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 8.125,
          maxWidth: 11.25,
          render: (record) => (
            <PPNewDetailLink type="invoice" id={String(record.id)}>
              {record.invoiceNumber}
            </PPNewDetailLink>
          ),
        },
        {
          title: t('general.purchaseOrder', 'Purchase Order'),
          key: 'purchaseOrder',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 8.125,
          maxWidth: 8.125,
          render: (record) => (
            <PPNewDetailLink type="purchase-order" id={String(record.purchaseOrder?.id)}>
              {record.purchaseOrder?.purchaseOrderNumber}
            </PPNewDetailLink>
          ),
        },
        {
          title: t('general.accountCode', 'Account Code'),
          key: 'accountCode',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 15,
          grow: true,
          render: ({ department, account }) => <AccountCodeItem department={department} account={account} />,
        },
        {
          title: t('general.status', 'Status'),
          key: 'status',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 7.5,
          maxWidth: 7.5,
          render: ({ state }) => state && <InvoiceStatus state={state} />,
        },
        {
          title: t('general.invoiceDate', 'Invoice Date'),
          key: 'invoiceDate',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 7.5,
          maxWidth: 7.5,
          render: ({ invoiceDate }) => <ShortDateItem date={invoiceDate} />,
        },
        {
          title: t('general.tax', 'Tax'),
          key: 'tax',
          headerCellStyles: 'px-1 py-3 text-right',
          cellStyles: 'px-1 py-3 text-right',
          minWidth: 5.625,
          maxWidth: 8.125,
          render: ({ totalTaxValue }) => <Tax taxInclusiveTotal={totalTaxValue} />,
        },
        {
          title: t('general.total', 'Total'),
          key: 'total',
          headerCellStyles: 'px-1 py-3 text-right',
          cellStyles: 'px-1 py-3 text-right',
          minWidth: 6.875,
          maxWidth: 9.375,
          render: ({ totalValue }) => <TotalTaxInclusive totalValue={totalValue} />,
        },
        {
          title: t('general.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: (record) => (
            <MoreOptionsMenu showHelp showOpenInPPlus showInvoiceLink showPrint invoiceId={record.id}>
              <OptionIcon className="h-10 w-10 text-gray-500" />
            </MoreOptionsMenu>
          ),
        },
      ]}
    />
  )
}

export default memo(TableView)
