import { gql } from '@apollo/client'

export const GET_REQUISITION_ATTACHMENTS = gql`
  query GetRequisitionAttachments($requisitionId: Int, $sort: [RansackSortType!]) {
    currentPurchaser {
      id
      requisition(id: $requisitionId) {
        id
        requisitionNumber
        requisitionAttachments(sort: $sort) {
          edges {
            node {
              id
              fileFileName
              caption
            }
          }
        }
      }
    }
  }
`
