import classNames from 'classnames'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import SupplierConnectModal from '../../supplier-directory/SupplierConnectModal'
import SupplierOptions from '../SupplierOptions'

import { Supplier, SupplierRelationship } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, PPNewDetailLink } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { OptionIcon } from '@/modules/shared/icons'

interface TableViewProps {
  suppliers: SupplierRelationship[]
}

function TableView(props: TableViewProps) {
  const { suppliers } = props
  const { t } = useTranslation()
  const [showConnect, setShowConnect] = useState<number | null>(null)

  const renderSupplier = (record: SupplierRelationship) => {
    return (
      <>
        <PPNewDetailLink type="supplier" id={String(record.id)}>
          {record.supplierName}
        </PPNewDetailLink>
        <p className="text-xs">{record.supplierNumber}</p>
      </>
    )
  }
  const renderContactInfo = ({ supplier }: SupplierRelationship) => {
    return (
      <>
        <p>{supplier?.telephone}</p>
        <p>{supplier?.email}</p>
      </>
    )
  }
  const renderRelationship = (record: SupplierRelationship) => {
    return (
      <>
        <Button
          className={classNames(`w-24 rounded-md py-3`, {
            'bg-success text-white': record.isEnabled,
            'bg-error text-white': !record.isEnabled,
          })}
          onClick={() => setShowConnect(record.supplierId)}
        >
          {record.isEnabled ? t('general.enabled', 'Enabled') : t('general.disabled', 'Disabled')}
        </Button>
        <SupplierConnectModal
          showModal={showConnect === record.supplierId}
          setShowModal={setShowConnect}
          supplier={{ ...record.supplier, supplierRelationship: { isEnabled: record.isEnabled } } as Supplier}
        />
      </>
    )
  }
  const renderMoreOptions = (record: SupplierRelationship) => {
    return (
      <SupplierOptions id={Number(record.id)}>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </SupplierOptions>
    )
  }

  return (
    <Table
      dataTestId="my-suppliers-table"
      dataSource={suppliers}
      keyExtractor={(record) => String(record.id)}
      columns={[
        {
          title: t('general.supplier', 'Supplier'),
          key: 'supplier',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 14.375,
          maxWidth: 17.5,
          render: renderSupplier,
        },
        {
          title: t('general.contactInfo', 'Contact Info'),
          key: 'contactInfo',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 14.375,
          grow: true,
          render: renderContactInfo,
        },

        {
          title: t('general.relationship', 'Relationship'),
          key: 'relationship',
          headerCellStyles: 'px-0 py-3 text-center',
          cellStyles: 'px-0 py-3 text-center',
          minWidth: 7.5,
          maxWidth: 7.5,
          render: renderRelationship,
        },
        {
          title: t('general.selfManaged', 'Self Managed'),
          key: 'seflManaged',
          headerCellStyles: 'py-3 px-1 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: (record) => (record.supplier?.isVerified ? t('general.no', 'No') : t('general.yes', 'Yes')),
        },
        {
          title: t('general.preferred', 'Preferred'),
          key: 'preferredSupplier',
          headerCellStyles: 'py-3 px-1 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: (record) => (record.preferredSupplier ? t('general.yes', 'Yes') : t('general.no', 'No')),
        },
        {
          title: t('general.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: renderMoreOptions,
        },
      ]}
    />
  )
}

export default memo(TableView)
