import { useMutation } from '@apollo/client'
import { Dispatch, SetStateAction } from 'react'
import { Controller, ControllerRenderProps, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import NoImage from '@/assets/images/no-image.svg'
import {
  PricedCataloguedProduct,
  UpdatePricedCataloguedProductDocument,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { UpdatePriceFormInputs } from '@/modules/catalogs/types'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Button, Modal } from '@/modules/shared/components'
import NumberInput from '@/modules/shared/components/number-input'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export interface UpdateCatalogProductModalProps {
  showModal: boolean
  setModalProductId: Dispatch<SetStateAction<number | null>>
  cataloguedProduct: PricedCataloguedProduct
}

export default function UpdateCatalogProductModal({
  showModal,
  setModalProductId,
  cataloguedProduct,
}: UpdateCatalogProductModalProps) {
  const { t } = useTranslation()

  const { id: cataloguedProductId, product, sellUnitPrice, sellUnitTaxPercentage, image } = cataloguedProduct
  const { brand, itemDescription, itemMeasure, itemPackName, itemSize, concatenatedSellUnit } = product || {}
  const { currentPurchaser } = useCurrentPurchaser()
  const formMethods = useForm<UpdatePriceFormInputs>({
    defaultValues: {
      sellUnitPrice: Number(sellUnitPrice),
      sellUnitTaxPercentage: sellUnitTaxPercentage !== null ? Number(sellUnitTaxPercentage) : null,
    },
  })
  const errorSellUnitPrice =
    formMethods.formState.errors.sellUnitPrice && formMethods.formState.errors.sellUnitPrice.type === 'required'
  const errorTax =
    formMethods.formState.errors.sellUnitTaxPercentage &&
    formMethods.formState.errors.sellUnitTaxPercentage.type === 'required'

  const [updatePurchaseOrder, { loading }] = useMutation(UpdatePricedCataloguedProductDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const onSubmit: SubmitHandler<UpdatePriceFormInputs> = (data) => {
    updatePurchaseOrder({
      variables: {
        input: {
          id: cataloguedProductId,
          sellUnitPrice: Number(data.sellUnitPrice),
          sellUnitTaxPercentage: data.sellUnitTaxPercentage ? Number(data.sellUnitTaxPercentage) : null,
        },
      },
      onCompleted() {
        onCloseModal()
      },
    })
  }

  const onCloseModal = () => {
    setModalProductId(null)
    if (formMethods.formState.errors) formMethods.reset()
  }

  const onChangeInput = <K extends keyof UpdatePriceFormInputs>(
    field: ControllerRenderProps<UpdatePriceFormInputs, K>,
    value: number
  ) => {
    // To disable the apply changes button when no input is provided.
    if (isNaN(value)) field.onChange(null)
    else field.onChange(value)
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        data-testid="update-price-modal"
        className="flex w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]"
      >
        <Modal.Title
          title={t('catalogs.selfManagedCatalog.updatePriceModal.title', 'Update Unit Price and Tax Percentage')}
          onCloseModal={onCloseModal}
        />

        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Modal.Body>
              <div className="mb-5 flex gap-x-3 rounded-md border p-4">
                <img className="h-24 w-24 flex-none border" src={image || NoImage} alt="product" />
                <div className="flex flex-col justify-center text-sm">
                  <span className="text-primary">
                    {brand} {itemDescription}
                  </span>
                  <span className="text-xxs text-gray-500 sm:text-xs">
                    <p>
                      {formatProductItem({
                        itemMeasure: itemMeasure,
                        itemPackName: itemPackName,
                        itemSize: itemSize,
                        itemSellUnit: concatenatedSellUnit,
                      })}
                    </p>
                  </span>
                </div>
              </div>
              <div className="space-y-4 text-sm">
                <div>
                  <p className="font-bold">{t('general.unitPrice', 'Unit Price')}</p>
                  <p className="text-gray-500">
                    {t(
                      'catalogs.selfManagedCatalog.updatePriceModal.unitPriceDescription',
                      'Set the price for this product in the Catalog. The price is exclusive of tax, and the tax percentage to be added to the price is managed in the field below.'
                    )}
                  </p>
                  <Controller
                    control={formMethods.control}
                    name="sellUnitPrice"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <NumberInput
                        className="mt-2 h-12 w-full rounded-md border text-sm shadow-sm"
                        leftUnit={currentPurchaser?.currency}
                        data-testid="price-input"
                        aria-label={t('general.unitPrice', 'Unit Price')}
                        hasError={errorSellUnitPrice}
                        errorMessage={t(
                          'catalogs.selfManagedCatalog.updatePriceModal.unitPriceErrorMsg',
                          'You must provide a Quantity.'
                        )}
                        defaultValue={Number(sellUnitPrice)}
                        onChange={(e) => onChangeInput(field, e)}
                      />
                    )}
                  />
                </div>
                <div>
                  <p className="font-bold">{t('general.taxPercentage', 'Tax Percentage')}</p>
                  <p className="text-gray-500">
                    {t(
                      'catalogs.selfManagedCatalog.updatePriceModal.taxPercentageDescription',
                      'Set the Tax percentage for this product in the Catalog. The tax percentage is the amount that will be added to the tax exclusive unit price above.'
                    )}
                  </p>
                  <Controller
                    control={formMethods.control}
                    name="sellUnitTaxPercentage"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <NumberInput
                        className="mt-2 h-12 w-full rounded-md border text-sm shadow-sm"
                        leftUnit={t('general.taxWithPercentage', 'Tax %')}
                        data-testid="tax-input"
                        aria-label={t('general.taxPercentage', 'Tax Percentage')}
                        hasError={errorTax}
                        errorMessage={t(
                          'catalogs.selfManagedCatalog.updatePriceModal.taxPercentageErrorMsg',
                          'You must provide a Tax Percentage, even if it is zero.'
                        )}
                        defaultValue={sellUnitTaxPercentage !== null ? Number(sellUnitTaxPercentage) : 0}
                        onChange={(e) => onChangeInput(field, e)}
                      />
                    )}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
              <Button
                className="h-11 w-full rounded-md bg-gray-200 px-8 text-sm md:w-fit"
                onClick={onCloseModal}
                type="button"
                data-testid="cancel-button"
              >
                {t('general.cancel', 'Cancel')}
              </Button>
              <Button
                className="h-11 w-full rounded-md bg-primary px-8 text-sm text-white md:w-fit"
                type="submit"
                loading={loading}
                data-testid="submit-button"
              >
                {t('general.applyUpdate', 'Apply Update')}
              </Button>
            </Modal.Footer>
          </form>
        </FormProvider>
      </Modal.Panel>
    </Modal>
  )
}
