import { useState } from 'react'

import { PurchaseOrder } from '@/graphql/purchasing/generated/purchasing_graphql'
import PurchaseOrderStatus from '@/modules/purchase-orders/pages/all-purchase-order/PurchaseOrderStatus'
import { statusText } from '@/modules/purchase-orders/utils/statusText'
import PurchaseOrderModal from '@/modules/requisitions/pages/confirmation/requisition-purchase-orders/PurchaseOrderModal'

interface POSentStatusProps {
  record: PurchaseOrder
}
export const POSentStatus = ({ record }: POSentStatusProps) => {
  const [isOpen, setIsOpen] = useState<number | null>(null)
  const { sentDate, status, id } = record
  return (
    <>
      <span className="cursor-pointer" onClick={() => setIsOpen(id)} data-testid="purchase-order-send">
        <PurchaseOrderStatus status={statusText(status, sentDate)} />
      </span>
      <PurchaseOrderModal showModal={!!isOpen} setShowModal={setIsOpen} purchaseOrder={record} />
    </>
  )
}
