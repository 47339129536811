import { useState } from 'react'

import { ColumnDefinition } from '../Table'

import { useSession } from '@/modules/access/hooks'

interface UseFilterColumnsProps<T> {
  tableName?: string
  columnsHidden?: string[]
  columns: ColumnDefinition<T>[]
}

// Filters columns in the table based on columnsHidden and user preferences
export default function useFilterColumns<T>({ tableName, columnsHidden, columns }: UseFilterColumnsProps<T>) {
  const { currentUser } = useSession()
  const storageKey = `columns:${tableName}:${currentUser?.id}`

  // Function to get column preferences from local storage or set them to default
  const getColumnPreferences = (): { [key: string]: boolean } => {
    const savedPreferencesJSON = localStorage.getItem(storageKey)
    const defaultHiddenPreference = columnsHidden
      ? columnsHidden.reduce((acc, key) => ({ ...acc, [key]: true }), {})
      : {}

    if (savedPreferencesJSON) {
      return JSON.parse(savedPreferencesJSON)
    }
    return defaultHiddenPreference
  }

  const columnPreferences = getColumnPreferences()

  const filteredColumns = columns.map((column) => ({
    ...column,
    hidden: column.alwaysVisible ? false : columnPreferences[column.key],
  }))

  const [rerenderState, setRerenderState] = useState(false)
  const forceUpdate = () => {
    setRerenderState(!rerenderState)
  }

  // Function to handle updating filtered columns and saving changes to local storage
  const handleSetFilteredColumns = (columns: ColumnDefinition<T>[]): void => {
    // Convert updated columns to an object with keys and hidden values
    const updatedColumns = columns.reduce<{ [key: string]: boolean | undefined }>((acc, column) => {
      acc[column.key] = column.hidden
      return acc
    }, {})

    // Save updatedColumns to local storage
    localStorage.setItem(storageKey, JSON.stringify(updatedColumns))
    forceUpdate()
  }

  return { filteredColumns, handleSetFilteredColumns }
}
