import Fallback from '../../fallback'

export interface TaxPercentageProps {
  taxPercentage: number | null | undefined
  availableQuotes?: boolean
}

export default function TaxPercentage({ taxPercentage, availableQuotes = true }: TaxPercentageProps) {
  return (
    <Fallback condition={!!availableQuotes}>
      <p>{`${taxPercentage}%`}</p>
    </Fallback>
  )
}
