import { t } from 'i18next'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'

interface AwaitingApprovalBodyProps {
  requisition: Requisition
}

export default function AwaitingApprovalBody(props: AwaitingApprovalBodyProps) {
  const { requisition } = props

  return (
    <>
      <section>
        <p className="font-bold">{t('general.approvalWorkflow', 'Approval Workflow')}</p>
        <p className="text-gray-500">
          {t('requisition.statusModal.approvalWorkflowBody', 'The Approval Workflow that applies to this Requisition.')}
        </p>
        <div className="mt-2 w-full rounded-md border bg-gray-100 px-3 py-4">
          <span>{requisition?.approvalWorkflow?.title}</span>
        </div>
      </section>
      <section>
        <p className="font-bold">{t('general.awaitingApprovalBy', 'Awaiting Approval By')}</p>
        <p className="text-gray-500">
          {t(
            'requisition.statusModal.awaitingApprovalByBody',
            'Any of the following users can approve this Requisition next.'
          )}
        </p>
        <div className="mt-2 flex w-full flex-col divide-y rounded-md border bg-gray-100">
          {requisition?.nextApprovingUsers?.map((user) => (
            <div className="px-3 py-4" key={user.id}>
              {user.fullName}
            </div>
          ))}
        </div>
      </section>
    </>
  )
}
