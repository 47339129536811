import { Dispatch, SetStateAction } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ReceivingDocumentLineItemInput } from '@/modules/purchase-orders/types'
import { DatePicker } from '@/modules/shared/components'

interface ProductExpiryDateProps {
  expDate: string
  isReadOnly: boolean
  setInputFocus: Dispatch<SetStateAction<boolean>>
}

export default function ProductExpiryDate({ expDate, setInputFocus, isReadOnly }: ProductExpiryDateProps) {
  const { t } = useTranslation()
  const { control } = useFormContext<ReceivingDocumentLineItemInput>()
  return (
    <section>
      <strong className="text-sm">{t('general.productExpiryDate', 'Product Expiry Date')}</strong>
      <p className="text-sm text-gray-500">
        {t(
          'receivingNotes.advancedModal.productExpiryDateDesc',
          'If required, use the field below to record the expiration date of the product at the time this product was received.'
        )}
      </p>
      <div className="mt-4">
        <Controller
          control={control}
          name="expDate"
          defaultValue={expDate}
          render={({ field }) => (
            <DatePicker
              defaultDate={expDate || ''}
              canBeEmpty
              disabled={isReadOnly}
              isSelected={field.value === '--' ? false : true}
              hasSelectedColor={false}
              onSelected={(e) => {
                setInputFocus(true)
                field.onChange(e)
              }}
              label="date-picker"
              classNames="lg:w-full"
              showRight
              placeholder={t('general.productExpirationDate', 'Product Expiration Date')}
            />
          )}
        />
      </div>
    </section>
  )
}
