import { useTranslation } from 'react-i18next'
import { NumberParam, useQueryParam } from 'use-query-params'

import AdvancedSearchFilter from './AdvancedSearchFilter'
import ComboboxFilter from './ComboboxFilter'

import { Chip } from '@/modules/shared/components'
import { QueryParameter } from '@/modules/shared/types'

export interface PeriodRangeProps {
  title: string
}

export default function PeriodRange({ title }: PeriodRangeProps) {
  const { t, i18n } = useTranslation()
  const [periodYearParam, setPeriodYearParam] = useQueryParam(QueryParameter.PeriodYear, NumberParam)
  const [periodMonthParam, setPeriodMonthParam] = useQueryParam(QueryParameter.PeriodMonth, NumberParam)

  const currentYear = new Date().getFullYear()
  const years = Array.from({ length: 12 }, (_, i) => currentYear + 1 - i)
  const months = Array.from({ length: 12 }, (_, i) => i + 1)

  const getMonthNameFromNumber = (monthNumber: number) => {
    const currentLocale = i18n.language
    return new Intl.DateTimeFormat(currentLocale, { month: 'long' }).format(new Date(0, monthNumber))
  }

  const monthNames = months.map((month) => getMonthNameFromNumber(month - 1))

  const handleSetPeriodYear = (year: number | string | null) => {
    if (year) {
      setPeriodYearParam(Number(year))
    } else {
      setPeriodYearParam(null)
    }
  }

  const handleSelectedYears = () => {
    return periodYearParam ? [String(periodYearParam)] : []
  }

  const handleSelectedMonths = () => {
    return periodMonthParam ? [monthNames[periodMonthParam - 1]] : []
  }

  return (
    <AdvancedSearchFilter buttonTestId="date-filter-section" buttonTitle={title}>
      <div className="flex flex-col gap-2 lg:flex-row">
        <ComboboxFilter
          testId="year"
          placeholder={t('general.year', 'Year')}
          items={years}
          selectedItems={handleSelectedYears()}
          onSelected={(year) => handleSetPeriodYear(year)}
          isSelected={!!periodYearParam}
        />
        <ComboboxFilter
          testId="month"
          disabled={!periodYearParam}
          placeholder={t('general.month', 'Month')}
          items={monthNames}
          selectedItems={handleSelectedMonths()}
          onSelected={(month) => setPeriodMonthParam(months[monthNames.indexOf(String(month))])}
          isSelected={!!periodMonthParam}
        />
      </div>
      <div className="flex gap-x-2 text-sm">
        {periodYearParam && (
          <Chip key={periodYearParam} label={String(periodYearParam)} onRemoveChip={() => handleSetPeriodYear(null)} />
        )}
        {periodMonthParam && (
          <Chip
            key={periodMonthParam}
            label={getMonthNameFromNumber(periodMonthParam - 1)}
            onRemoveChip={() => setPeriodMonthParam(null)}
          />
        )}
      </div>
    </AdvancedSearchFilter>
  )
}
