import { useMutation } from '@apollo/client'

import { UpdateInvoiceDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useUpdateInvoice() {
  const mutation = useMutation(UpdateInvoiceDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  return mutation
}
