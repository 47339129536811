import { useParams } from 'react-router-dom'

import AttachmentMoreOptionMenu from './MoreOptionMenu'

import { RequisitionAttachment } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback } from '@/modules/shared/components'
import { ENTERPRISE_REQUISITION_URL } from '@/modules/shared/constants'

interface AttachmentItemProps {
  attachment: RequisitionAttachment
}

export default function AttachmentItem(props: AttachmentItemProps) {
  const { attachment } = props
  const { id, fileFileName, caption } = attachment
  const { requisitionId } = useParams<{ requisitionId: string }>()

  const downloadAttachmentURL = `${ENTERPRISE_REQUISITION_URL}/${requisitionId}/requisition_attachments/${id}/download`

  return (
    <div
      className="flex min-h-[3.75rem] items-center justify-between border-b px-4 last:border-none"
      data-testid="attachment-item"
    >
      <div className="flex grow flex-col justify-between py-2 pr-4 md:flex-row md:items-center">
        <Fallback condition={!!fileFileName}>
          <a className="text-sm text-primary" href={downloadAttachmentURL} target="_blank" rel="noreferrer">
            {fileFileName}
          </a>
        </Fallback>
        <p className="text-sm text-gray-500">{caption}</p>
      </div>
      <AttachmentMoreOptionMenu attachmentId={id} downloadAttachmentURL={downloadAttachmentURL} />
    </div>
  )
}
