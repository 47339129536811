import { gql } from '@apollo/client'

export const GET_AVAILABLE_PRODUCTS = gql`
  query GetAvailableProducts(
    $searchText: String
    $sortPrices: [RansackSortType!]
    $after: String
    $categoryIds: [Int!]
    $supplierIds: [Int!]
    $hasQuotes: Boolean
    $supplierProductCode: String
    $purchaserProductCode: String
    $productId: Int
    $master: Boolean
    $invoiceId: Int!
    $catalogueId: Int!
  ) {
    currentPurchaser {
      id
      availableProducts(
        searchText: $searchText
        after: $after
        categoryIds: $categoryIds
        supplierIds: $supplierIds
        hasQuotes: $hasQuotes
        supplierProductCode: $supplierProductCode
        purchaserProductCode: $purchaserProductCode
        productId: $productId
        master: $master
      ) {
        edges {
          node {
            id
            category {
              id
              name
              parent {
                id
                name
              }
            }
            brand
            itemDescription
            concatenatedDescription
            concatenatedSellUnit
            itemPackName
            itemMeasure
            itemSellPackName
            itemSize
            image
            invoiced(invoiceId: $invoiceId)
            catalogued(catalogueId: $catalogueId)
            prices(sort: $sortPrices) {
              edges {
                node {
                  unitPrice
                  supplierName
                  taxPercentage
                }
              }
            }
            catalogues {
              edges {
                node {
                  id
                  title
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`
