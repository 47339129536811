import classNames from 'classnames'

import { Tooltip } from '@/modules/shared/components'
import { QuestionMarkIcon } from '@/modules/shared/icons'

export interface InvoiceLineModalItemProps {
  title: string
  tooltip: string
  value?: string | number | null | JSX.Element
  extraInfo?: string | number | null | JSX.Element | boolean
}

const TableLineItem = ({ title, tooltip, value, extraInfo }: InvoiceLineModalItemProps) => (
  <div className="flex border-b last:border-none">
    <div className="flex w-40 items-center border-r border-gray-300 bg-gray-100 p-3 text-xs sm:w-48 sm:text-sm lg:w-64 ">
      <span className="mr-1">{title}</span>
      <Tooltip content={tooltip}>
        <QuestionMarkIcon className="h-4 w-4" />
      </Tooltip>
    </div>
    <div
      className={classNames('flex flex-grow items-center p-3 text-xs sm:text-sm', {
        'justify-between': !!extraInfo,
      })}
    >
      <p>{value}</p>
      {extraInfo}
    </div>
  </div>
)

export default TableLineItem
