import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import AddDeptAndAccountModal from '@/modules/requisitions/components/lines-by-suppliers/AddDeptAndAccountModal'
import { useUpdateAdvancedRequisitionLine } from '@/modules/requisitions/hooks'
import ExtraLine from '@/modules/shared/components/table/ExtraLine'
import { Themes } from '@/modules/shared/types'

interface PRLinesDeptAndAccountProps {
  requisitionLine: RequisitionLine
}

export default function PRLinesDeptAndAccount({ requisitionLine }: PRLinesDeptAndAccountProps) {
  const { t } = useTranslation()
  const { department, account } = requisitionLine

  const [showAddDeptAndAccountModal, setShowAddDeptAndAccountModal] = useState(false)
  const [updateAdvancedRequisitionLine, { loading }] = useUpdateAdvancedRequisitionLine()

  const onRemoveDeptAndAccount = (input: RequisitionLine) => {
    const { id, productId, cataloguedProductId } = input
    updateAdvancedRequisitionLine({
      variables: {
        input: {
          id: Number(id),
          productId: Number(productId),
          cataloguedProductId: Number(cataloguedProductId),
          departmentId: null,
          accountId: null,
        },
      },
    })
  }
  return (
    <>
      <ExtraLine
        theme={Themes.Primary}
        isResponsive={false}
        dataTestId="dept-account-block"
        removeActionTestId="remove-dept-account-btn"
        title={t('shopPage.cart.summary.table.deptAndAccount', 'Department and Account Code')}
        value={`${department?.name} ${account?.code}`}
        showRemoveLineAction
        removeLineLoading={loading}
        removeLineTooltipContent={t(
          'shopPage.cart.summary.table.removeDeptAndAccountTooltipContent',
          'Remove Department and Account Code'
        )}
        onLineClick={() => setShowAddDeptAndAccountModal(true)}
        onRemoveLine={() => onRemoveDeptAndAccount(requisitionLine)}
      />
      <AddDeptAndAccountModal
        showModal={showAddDeptAndAccountModal}
        setShowModal={setShowAddDeptAndAccountModal}
        line={requisitionLine}
      />
    </>
  )
}
