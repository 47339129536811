import { Controller, useFormContext } from 'react-hook-form'

import { DatePicker } from '@/modules/shared/components'

interface FormDateInput {
  defaultDate?: string
  showExplanation?: boolean
  disabled?: boolean
  title: string
  description: string
  register: string
}

export default function FormDate({
  defaultDate,
  disabled,
  showExplanation = true,
  title,
  description,
  register,
}: FormDateInput) {
  const { control } = useFormContext()
  return (
    <section className="mt-8">
      {showExplanation && (
        <>
          <h2 className="text-sm font-semibold">{title}</h2>
          <p className="text-sm text-gray-500">{description}</p>
        </>
      )}
      <div className="mt-4">
        <Controller
          control={control}
          name={register}
          render={({ field }) => (
            <DatePicker
              defaultDate={defaultDate}
              onSelected={(e) => field.onChange(e)}
              label="date-picker"
              disabled={disabled}
            />
          )}
        />
      </div>
    </section>
  )
}
