import { graphql } from 'msw'

import { GetUserQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetUser: GetUserQuery = {
  currentOrganisationUnit: {
    id: 44926,
    user: {
      id: 7831,
      fullName: 'BAU Marketboomer',
      email: 'bau@marketboomer.com',
      telephone: '',
      staffCode: '1234',
      autoSendPurchaseOrder: true,
      enforceBuyList: false,
      createdAt: '2016-10-13T12:10:50+11:00',
      lastSignInAt: '2022-08-11T19:46:12+10:00',
      position: 'BAU',
      isDeleted: false,
      defaultOrganisationId: 1,
      defaultOrganisation: {
        id: 1,
        name: 'Marketboomer',
        __typename: 'Organisation',
      },
      userOrganisations: {
        edges: [
          {
            node: {
              organisation: {
                id: 1,
                name: 'Marketboomer',
                __typename: 'Organisation',
              },
              __typename: 'UserOrganisation',
            },
            __typename: 'UserOrganisationEdge',
          },
          {
            node: {
              organisation: {
                id: 44926,
                name: 'Test Account',
                __typename: 'Organisation',
              },
              __typename: 'UserOrganisation',
            },
            __typename: 'UserOrganisationEdge',
          },
        ],
        __typename: 'UserOrganisationConnection',
      },
      __typename: 'User',
    },
    __typename: 'Purchaser',
  },
}

export const GetUser = graphql.query<GetUserQuery>('GetUser', (_, res, ctx) => {
  return res(ctx.data(baseGetUser))
})

export default baseGetUser
