import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'

interface PRLineCategoryProps {
  requisitionLine: RequisitionLine
}

export default function PRLineCategory({ requisitionLine }: PRLineCategoryProps) {
  const { product } = requisitionLine
  const { category } = product || {}

  return (
    <>
      <p>{category?.name}</p>
      <p className="text-xs">{category?.parent?.name}</p>
    </>
  )
}
