import { useTranslation } from 'react-i18next'

import { InvoiceActionTypes } from '@/modules/invoices/types'
import { CancelIcon, HaltIcon, HeartIcon, ParkIcon, ReopenIcon } from '@/modules/shared/icons'

export interface MoreOptionsMenuInvoiceActionsProps {
  invoiceId?: string | number
  showReopenInvoice?: boolean
  showCancelInvoice?: boolean
  showHaltInvoice?: boolean
  showResendInvoice?: boolean
  showParkInvoice?: boolean
  onShowConfirmActionDialog?: (action: InvoiceActionTypes) => void
}

export default function MoreOptionsMenuInvoiceActions({
  invoiceId,
  showReopenInvoice,
  showCancelInvoice,
  showHaltInvoice,
  showResendInvoice,
  showParkInvoice,
  onShowConfirmActionDialog,
}: MoreOptionsMenuInvoiceActionsProps) {
  const { t } = useTranslation()

  return (
    <>
      {invoiceId && (
        <>
          {showReopenInvoice && (
            <p
              className="flex w-full cursor-pointer items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
              onClick={() => onShowConfirmActionDialog && onShowConfirmActionDialog(InvoiceActionTypes.Reopen)}
              data-testid="reopen-invoice"
            >
              <ReopenIcon className="mr-2 h-5 w-5" />
              {t('general.reopenInvoice', 'Re-Open Invoice')}
            </p>
          )}
          {showHaltInvoice && (
            <p
              className="flex w-full cursor-pointer items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
              onClick={() => onShowConfirmActionDialog && onShowConfirmActionDialog(InvoiceActionTypes.Halt)}
              data-testid="halt-invoice"
            >
              <HaltIcon className="mr-2 h-5 w-5" />
              {t('general.haltInvoice', 'Halt Invoice')}
            </p>
          )}
          {showResendInvoice && (
            <p
              className="flex w-full cursor-pointer items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
              onClick={() => onShowConfirmActionDialog && onShowConfirmActionDialog(InvoiceActionTypes.Resend)}
              data-testid="resend-invoice"
            >
              <HeartIcon className="mr-2 h-5 w-5" />
              {t('general.resendInvoice', 'Resend Invoice')}
            </p>
          )}
          {showParkInvoice && (
            <p
              className="flex w-full cursor-pointer items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
              onClick={() => onShowConfirmActionDialog && onShowConfirmActionDialog(InvoiceActionTypes.Park)}
              data-testid="park-invoice"
            >
              <ParkIcon className="mr-2 h-5 w-5" />
              {t('general.parkInvoice', 'Park Invoice')}
            </p>
          )}
          {showCancelInvoice && (
            <p
              className="flex w-full cursor-pointer items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900"
              onClick={() => onShowConfirmActionDialog && onShowConfirmActionDialog(InvoiceActionTypes.Cancel)}
              data-testid="cancel-invoice"
            >
              <CancelIcon className="mr-2 h-5 w-5" />
              {t('general.cancelInvoice', 'Cancel Invoice')}
            </p>
          )}
        </>
      )}
    </>
  )
}
