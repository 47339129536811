import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import UserForm from './UserForm'

import { GetUserDocument, User } from '@/graphql/purchasing/generated/purchasing_graphql'
import { SimpleTableItem } from '@/modules/shared/components'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

export default function UserDetails() {
  const { t } = useTranslation()
  const { userId } = useParams<{ userId: string }>()
  const [key, setKey] = useState(0)

  const { data } = useQuery(GetUserDocument, {
    variables: {
      userId: Number(userId),
    },
  })

  const user = data?.currentOrganisationUnit?.user
  const { createdAt, lastSignInAt, defaultOrganisation, email } = user || {}

  return (
    <div className="flex flex-col-reverse justify-between gap-10 xl:flex-row">
      <section className="xl:w-[52.5rem]">
        <p className="text-lg font-bold">{t('general.general', 'General')}</p>
        <hr className="my-3" />
        <div key={key}>
          <UserForm user={user as User} keyVal={key} setKey={setKey} />
        </div>
      </section>
      <section className="flex w-full flex-col gap-y-4 text-sm xl:w-96">
        <section className="divide-y rounded-md bg-white shadow" data-testid="user-details">
          <h2 className="px-5 py-4 text-base font-bold">{t('general.userDetails', 'User Details')}</h2>
          <div className="flex flex-col gap-y-3 p-5 text-sm">
            <SimpleTableItem title={t('general.emailAddress', 'Email Address')} value={email} />
            <SimpleTableItem title={t('general.createdOn', 'Created On')} value={formatDate(Dates.Long, createdAt)} />
            <SimpleTableItem
              title={t('general.lastSignIn', 'Last Sign In')}
              value={formatDate(Dates.Long, lastSignInAt)}
            />
            <SimpleTableItem
              title={t('general.defaultOrganisation', 'Default Organisation')}
              value={defaultOrganisation?.name}
            />
          </div>
        </section>
      </section>
    </div>
  )
}
