import classNames from 'classnames'

import { ReceivingNoteState } from '../types'
import { translationStateText } from '../utils/getStateTranslationKey'

export interface ReceivingNoteStatusProps {
  state: string | null
}

export default function ReceivingNoteStatus({ state }: ReceivingNoteStatusProps) {
  // convert state to string if it is null or undefined
  state = state ?? ''
  return (
    <span
      data-testid="receiving-note-status"
      className={classNames(`rounded-full px-3 py-1.5 text-xs`, {
        'bg-success text-white': state === ReceivingNoteState.Received,
        'bg-gray-200': state === ReceivingNoteState.Open,
        'bg-gray-500 text-white': state === ReceivingNoteState.Cancelled,
      })}
    >
      {translationStateText(state)}
    </span>
  )
}
