import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import StockTakeStatus from '../../components/StockTakeStatus'
import { useGetStocktake } from '../../hooks/useGetStocktake'
import StocktakeDetails from './StocktakeDetails'

import { StockLocation } from '@/graphql/inventory/generated/inventory_graphql'
import { Breadcrumb, Button, MoreOptionsMenu, QueryResult, ThemeWrapper, Tooltip } from '@/modules/shared/components'
import HeaderTabs, { TabItem } from '@/modules/shared/components/header-tabs'
import { HomeIcon, InfoIcon, InventoryIcon, OptionIcon } from '@/modules/shared/icons'
import { Dates, StocktakeState, Themes } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

export default function ViewStocktake() {
  const { t } = useTranslation()
  const [showInfo, setShowInfo] = useState(false)

  const { loading, error, stockLocation } = useGetStocktake()

  const actionList: TabItem[] = [{ target: 'stock-count', label: t('general.stockCount', 'Stock Count') }]

  return (
    <QueryResult loading={loading} error={error}>
      <div className="mx-auto max-w-[95rem] px-4">
        <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2" data-testid="stocktake-breadcrumb">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          {/* TODO: add links once pages are added */}
          <Breadcrumb.Item>{t('general.inventory', 'Inventory')}</Breadcrumb.Item>
          <Breadcrumb.Item>{stockLocation?.name}</Breadcrumb.Item>
          <Breadcrumb.Item href={'/stocktakes'}>{t('general.stocktakes', 'Stocktakes')}</Breadcrumb.Item>
          <Breadcrumb.Item>{formatDate(Dates.MonthYear, stockLocation?.stockTake?.periodEndingOn)}</Breadcrumb.Item>
          <Breadcrumb.Item>{t('general.stockCount', 'Stock Count')}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="my-5 flex flex-col gap-y-2">
          <section className="flex justify-between rounded-md bg-white p-4 shadow">
            <div className="flex items-center">
              <ThemeWrapper theme={Themes.Primary} className="flex h-14 w-14 items-center justify-center rounded-md ">
                <InventoryIcon className="h-10 w-10 " />
              </ThemeWrapper>
              <span className="ml-3">
                <p className="font-bold">
                  {t('stocktake.monthYearStocktake', '{{monthYear}} Stocktake', {
                    monthYear: formatDate(Dates.MonthYear, stockLocation?.stockTake?.periodEndingOn),
                  })}
                </p>
                <p className="text-sm text-gray-500">{stockLocation?.name}</p>
              </span>
            </div>
            <div className="flex items-center gap-x-2">
              <Tooltip content={t('general.showDetails', 'Show or hide details')} showArrow={false}>
                <Button
                  data-testid="show-details-button"
                  className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                  onClick={() => setShowInfo(!showInfo)}
                >
                  <InfoIcon className="h-8 w-8 text-gray-600" />
                </Button>
              </Tooltip>
              <MoreOptionsMenu
                showOpenInPPlus
                showHelp
                buttonTheme="dark"
                stockTakeInfo={{
                  stockLocationId: Number(stockLocation?.id),
                  stocktakeId: Number(stockLocation?.stockTake?.id),
                }}
              >
                <OptionIcon className="h-8 w-8" />
              </MoreOptionsMenu>
            </div>
          </section>
          {showInfo && <StocktakeDetails stockLocation={stockLocation as StockLocation} />}
          <section className="flex items-center justify-between rounded-md bg-white px-4 py-3 text-sm shadow">
            <p>{t('general.status', 'Status')}</p>
            <StockTakeStatus
              state={stockLocation?.stockTake?.closedAt ? StocktakeState.Closed : StocktakeState.InProgress}
            />
          </section>
          <HeaderTabs actionList={actionList} />
        </section>
        <Outlet />
      </div>
    </QueryResult>
  )
}
