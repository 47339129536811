import { useTranslation } from 'react-i18next'

import TableView from './TableView'

import { useGetPOSendEvents } from '@/modules/purchase-orders/hooks'
import { QueryResult } from '@/modules/shared/components'
import { ControlBarIcon } from '@/modules/shared/icons'

export default function Advanced() {
  const { t } = useTranslation()
  const { data, sendEvents, loading, error } = useGetPOSendEvents({})

  return (
    <QueryResult loading={loading} error={error}>
      <div className="flex items-center gap-x-3" data-testid="purchase-order-comments">
        <ControlBarIcon className="h-10 w-10" />
        <p className="text-sm">
          {data?.currentPurchaser?.purchaseOrder?.purchaseOrderNumber} {t('general.advanced', 'Advanced')}
        </p>
      </div>
      <div className="mt-4 space-y-4 rounded-md bg-white p-5 shadow-sm">
        <span>
          <strong className="text-sm">{t('general.electronicSendEvents', 'Electronic Send Events')}</strong>
          <p className="text-sm text-gray-500">
            {t(
              'purchaseOrders.purchaseOrder.sendEvents.sendEventsDesc',
              'If the Purchase Order has been Sent, depending on the settings applied at the time, it may or may not have been electronically sent to the Supplier. If it was electronically sent, each send event will be listed below. There may be reasons why a Purchase Order was not electronically sent to the Supplier, for example the order may have been placed by phone and the user did not want the Supplier to receive a duplicate order.'
            )}
          </p>
        </span>
        <TableView sendEvents={sendEvents} />
        {sendEvents.length === 0 && (
          <div className="flex items-center justify-center rounded-md border bg-gray-100 p-5 py-8">
            <span className="text-sm text-gray-500">
              {t(
                'purchaseOrders.purchaseOrder.sendEvents.noSendEvents',
                'There are no electronic send events for this Purchase Order.'
              )}
            </span>
          </div>
        )}
      </div>
    </QueryResult>
  )
}
