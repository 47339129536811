import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useCancelRequisition } from '../../hooks'

import { RefetchContext } from '@/modules/purchasing/pages/dashboard/draft-requisitions'
import { ConfirmDialog, Tooltip } from '@/modules/shared/components'
import { TrashIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface CancelRequisitionButtonProps {
  requisitionId: number
}

export default function CancelRequisitionButton({ requisitionId }: CancelRequisitionButtonProps) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [cancelRequisition, { loading }] = useCancelRequisition()
  const onCancelRefetch = useContext(RefetchContext)

  const onCancelRequisition = (requisitionId: number) => {
    cancelRequisition({
      variables: { input: { id: requisitionId } },
      onCompleted() {
        onCancelRefetch && onCancelRefetch()
        setIsOpen(false)
      },
    })
  }

  return (
    <>
      <Tooltip content={t('general.cancelRequisition', 'Cancel Requisition')} delay={100}>
        <button
          data-testid="cancel-button"
          className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200 text-gray-600 transition hover:brightness-95"
          onClick={() => {
            setIsOpen(true)
          }}
        >
          <TrashIcon className="h-8 w-8" />
        </button>
      </Tooltip>
      <ConfirmDialog
        title={t('general.cancelRequisition?', 'Cancel Requisition?')}
        description={t(
          'dashboard.draftRequisitions.confirm.cancelDescription',
          'Please confirm below if you wish to cancel the Requisition. Once cancelled it will be removed from your drafts and will no longer be available.'
        )}
        primaryButtonLabel={t('general.cancelRequisition', 'Cancel Requisition')}
        secondaryButtonLabel={t('general.keepAsDraft', 'Keep as Draft')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onPrimaryBtnClick={() => onCancelRequisition(requisitionId)}
        theme={Themes.Primary}
        btnPrimaryLoading={loading}
      />
    </>
  )
}
