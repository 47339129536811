import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { useGetAllInvoices } from '../../hooks'
import InvoiceTableView from './table-view/InvoiceTableView'
import TileView from './tile-view'

import EmptyInvoices from '@/assets/images/empty-invoices.svg'
import { ProductListEmptyState, Spinner } from '@/modules/requisitions/components'
import { Breadcrumb, FilterTabs, MoreOptionsMenu, QueryResult, TitleBar, Tooltip } from '@/modules/shared/components'
import AdvancedSearch from '@/modules/shared/components/advanced-search/AdvancedSearch'
import SearchAndFilter from '@/modules/shared/components/search-input/SearchAndFilter'
import { useWindowSize } from '@/modules/shared/hooks'
import { HomeIcon, InvoiceIcon, OptionIcon, PlusIcon } from '@/modules/shared/icons'
import SearchContainer from '@/modules/shared/search-container'
import { Document } from '@/modules/shared/types'
import { FilterTabItem, QueryParameter } from '@/modules/shared/types'

export default function AllInvoices() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()

  const [filterURLParam] = useQueryParam(QueryParameter.Status, withDefault(StringParam, 'all'))
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false)
  const [preventAdvancedSearchAutoOpening, setPreventAdvancedSearchAutoOpening] = useState<boolean>(false)

  const {
    onFetchMoreInvoices,
    onFilter,
    onNextPage,
    onPreviousPage,
    error,
    loadingStates,
    invoices,
    pageInfo,
    paginationResultsPerPage,
  } = useGetAllInvoices({})

  const filterTabs: FilterTabItem[] = [
    {
      key: t('general.viewAll', 'View All'),
      // Not an actual state
      filterValue: 'all',
    },
    {
      key: t('general.open', 'Open'),
      filterValue: 'open',
    },
    {
      key: t('general.flagged', 'Flagged'),
      filterValue: 'flagged',
    },
    {
      key: t('general.reconciled', 'Reconciled'),
      filterValue: 'reconciled',
    },
    {
      key: t('general.processing', 'Processing'),
      filterValue: 'processing',
    },
    {
      key: t('general.processed', 'Processed'),
      filterValue: 'processed',
    },
    {
      key: t('general.cancelled', 'Cancelled'),
      filterValue: 'cancelled',
    },
    {
      key: t('general.parked', 'Parked'),
      filterValue: 'parked',
    },
  ]

  return (
    <>
      <Helmet>
        <title>{t('general.viewAllInvoices', 'View All Invoices')}</title>
      </Helmet>

      <main className="mx-auto space-y-5 px-4">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="/invoices">{t('general.invoices', 'Invoices')}</Breadcrumb.Item>
          <Breadcrumb.Item>{filterTabs.find((e) => e.filterValue === filterURLParam)?.key}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="space-y-2">
          <TitleBar
            icon={<InvoiceIcon className="h-10 w-10" />}
            title={t('general.allInvoices', 'All Invoices')}
            subtitle={t('general.viewAllInvoices', 'View All Invoices')}
          >
            <div className="flex items-center gap-x-2">
              <Tooltip content={t('createInvoice.createTooltip', 'Create a New Invoice')}>
                <Link to="/invoices/create">
                  <PlusIcon className="h-11 w-11 cursor-pointer rounded-full bg-gray-200 p-1 text-gray-600" />
                </Link>
              </Tooltip>
              <MoreOptionsMenu showHelp showOpenInPPlus allInvoices>
                <OptionIcon className="h-11 w-11 rounded-full bg-gray-200 p-1.5 text-gray-600 outline-none hover:brightness-95" />
              </MoreOptionsMenu>
            </div>
          </TitleBar>
          <FilterTabs tabs={filterTabs} />
        </section>
        <SearchContainer
          showAdvancedSearch={showAdvancedSearch}
          setShowAdvancedSearch={setShowAdvancedSearch}
          openByDefault={!preventAdvancedSearchAutoOpening}
        >
          <SearchAndFilter
            testId="invoices-search-input"
            placeholder={t('invoices.allInvoices.searchPlaceholder', 'Search by Invoice Number or Reference')}
            ariaLabel={t('general.searchingForInvoices', 'Searching for Invoices')}
            onFilter={onFilter}
            queryParamFilterType={QueryParameter.Status}
          />
        </SearchContainer>
        {showAdvancedSearch && <AdvancedSearch type={Document.Invoice} showSupplier />}
        <section>
          <QueryResult loading={loadingStates.loading || loadingStates.refetchLoading} error={error}>
            {isLargeScreen ? (
              <InvoiceTableView
                invoices={invoices.slice(0, paginationResultsPerPage)}
                onNextPage={onNextPage}
                onPreviousPage={onPreviousPage}
                pageInfo={pageInfo}
                paginationLoading={loadingStates.setVariablesLoading}
                setPreventAdvancedSearchAutoOpening={setPreventAdvancedSearchAutoOpening}
              />
            ) : (
              <InfiniteScroll
                dataLength={invoices.length}
                next={onFetchMoreInvoices}
                hasMore={!!pageInfo.hasNextPage}
                loader={<Spinner className="mt-5 h-14 md:w-16" />}
              >
                <TileView invoices={invoices} />
              </InfiniteScroll>
            )}
            {!loadingStates.setVariablesLoading && invoices.length === 0 && (
              <ProductListEmptyState
                image={EmptyInvoices}
                message={t('invoices.allInvoices.noInvoices', "We couldn't find any Invoices to display.")}
              />
            )}
          </QueryResult>
        </section>
        <div className="h-96" />
      </main>
    </>
  )
}
