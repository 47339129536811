import { graphql } from 'msw'

import { UpdateRequisitionMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseUpdateRequisition: UpdateRequisitionMutation = {
  updateRequisition: {
    requisition: {
      id: 5235941,
      reference: 'reference 123',
      sendToSupplier: false,
      advanced: false,
      approvalWorkflow: {
        id: 6326,
        title: 'Chris approval',
        __typename: 'RequisitionApprovalWorkflow',
      },
      nextApprovingAgentsNames: '',
      department: {
        id: 204632,
        name: 'AStore Shop Demo',
        __typename: 'Purchaser',
      },
      account: {
        id: 256446,
        accountName: '0060110 - Tickets for Attractions',
        __typename: 'Account',
      },
      deliveryAddress: {
        id: 1226,
        locationName: 'Main Dock',
        line1: 'MB Head Office',
        line2: '343 Pacific Hwy',
        city: 'North Sydney',
        stateProvince: 'NSW',
        country: 'AU',
        __typename: 'DeliveryAddress',
      },
      expectedDeliveryDate: '2022-07-12',
      __typename: 'Requisition',
    },
    __typename: 'UpdateRequisitionMutationPayload',
  },
}

export const UpdateRequisition = graphql.mutation<UpdateRequisitionMutation>('UpdateRequisition', (_, res, ctx) => {
  return res(ctx.data(baseUpdateRequisition))
})

export default baseUpdateRequisition
