import { t } from 'i18next'

import { Activity } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback } from '@/modules/shared/components'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface CancelledBodyProps {
  cancelActivity: Activity
}

export default function CancelledBody({ cancelActivity }: CancelledBodyProps) {
  return (
    <section>
      <p className="font-bold">{t('general.cancelledBy', 'Cancelled By')}</p>
      <p className="text-gray-500">
        {t('requisition.statusModal.cancelledByBody', 'The user who Cancelled the Requisition.')}
      </p>
      <div className="mt-2 flex w-full flex-col justify-between gap-x-2 rounded-md border bg-gray-100 px-3 py-4 sm:flex-row">
        <Fallback condition={!!cancelActivity}>
          <span>{cancelActivity?.user?.fullName}</span>
          <span className="text-gray-500">{formatDate(Dates.Long, cancelActivity?.createdAt)}</span>
        </Fallback>
      </div>
    </section>
  )
}
