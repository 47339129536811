import classNames from 'classnames'

import { invoiceFilterText } from './translationUtils'

import { InvoiceStatuses } from '@/modules/invoices/types'

interface InvoiceStatusProps {
  state: string
}

export default function InvoiceStatus({ state }: InvoiceStatusProps) {
  return (
    <span
      className={classNames('inline-flex rounded-full px-3 py-1.5 text-center text-xs', {
        'bg-[#1877F2] text-white': state === InvoiceStatuses.Open,
        'bg-[#EEB020] text-white': state === InvoiceStatuses.Processing,
        'bg-[#8000BF] text-white': state === InvoiceStatuses.Processed,
        'bg-success text-white': state === InvoiceStatuses.Reconciled,
        'bg-error text-white': state === InvoiceStatuses.Flagged,
        'bg-gray-200': state === InvoiceStatuses.Parked,
        'bg-gray-600 text-white': state === InvoiceStatuses.Cancelled,
      })}
    >
      {invoiceFilterText(state)}
    </span>
  )
}
