import { Dispatch, SetStateAction, useEffect } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import NoImage from '@/assets/images/no-image.svg'
import { Account, Purchaser, RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useUpdateRequisitionLine } from '@/modules/requisitions/hooks'
import { DeptAndAccount } from '@/modules/requisitions/pages/create/form-elements'
import { RequisitionFormInputs } from '@/modules/requisitions/types'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Button, Modal } from '@/modules/shared/components'

interface AddDeptAndAccountModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  line: RequisitionLine
}

export default function AddDeptAndAccountModal(props: AddDeptAndAccountModalProps) {
  const { line, showModal, setShowModal } = props
  const { id: lineId, productId, image, productBrand, supplier, department, account, product } = line
  const { brand, itemDescription, itemMeasure, itemPackName, itemSize, concatenatedSellUnit } = product || {}
  const { t } = useTranslation()

  const formMethods = useForm<RequisitionFormInputs>()
  const [updateRequisitionLine, { loading }] = useUpdateRequisitionLine()

  useEffect(() => {
    formMethods.reset({ departmentId: department?.id, accountId: account?.id })
  }, [department, account])

  const onCloseModal = () => {
    formMethods.reset()
    setShowModal(false)
  }

  const onSubmit: SubmitHandler<RequisitionFormInputs> = (inputData) => {
    const { departmentId, accountId } = inputData
    updateRequisitionLine({
      variables: {
        input: {
          id: Number(lineId),
          productId,
          departmentId,
          accountId,
        },
      },
      onCompleted() {
        setShowModal(false)
      },
    })
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        as="form"
        onSubmit={formMethods.handleSubmit(onSubmit)}
        className="flex w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]"
      >
        <Modal.Title
          title={t('shopPage.cart.summary.table.deptAndAccountModalTitle', 'Add Department and Account Code')}
          onCloseModal={onCloseModal}
        />

        <Modal.Body>
          <section className="flex items-center gap-x-4 rounded-md border p-4">
            <img className="h-28 w-28" src={image || NoImage} alt={String(productBrand)} />
            <span>
              <p className="text-sm text-primary">
                {brand} {itemDescription}
              </p>
              <span className="text-xxs text-gray-500 sm:text-xs">
                {formatProductItem({
                  itemMeasure: itemMeasure,
                  itemPackName: itemPackName,
                  itemSize: itemSize,
                  itemSellUnit: concatenatedSellUnit,
                })}
              </span>
              <p className="text-xs">{supplier?.name}</p>
            </span>
          </section>
          <section className="mt-4">
            <h2 className="text-sm font-semibold">
              {t('shopPage.cart.summary.table.deptAndAccount', 'Department and Account Code')}
            </h2>
            <p className="mb-4 text-sm">
              {t(
                'shopPage.cart.summary.table.deptAndAccountExplain',
                'You can select a specific Department and Account Code to record this particular product against. This will be used instead of the Department and Account Code applied to the entire Requisition, for this product only. Your organisation may use Department and Account Codes for recording expenses.'
              )}
            </p>
            <FormProvider {...formMethods}>
              <DeptAndAccount
                defaultAccountValue={account as Account}
                defaultDepartmentValue={department as Purchaser}
                showExplanation={false}
                title={t('createRequisition.deptAndAccount.title', 'Department and Account Code')}
                description={t(
                  'createRequisition.deptAndAccount.explain',
                  'If applicable, select a Department then an Account code to record this Requisition against. Your organisation may use Department and Account Codes for recording Requisition expenses.'
                )}
              />
            </FormProvider>
            <div className="h-52" />
          </section>
        </Modal.Body>
        <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
          <Button
            type="button"
            className="h-11 w-full rounded-md bg-gray-200 px-8 text-sm md:w-fit"
            onClick={onCloseModal}
          >
            {t('general.cancel', 'Cancel')}
          </Button>

          {formMethods.formState.isDirty && (
            <Button
              data-testid="save-changes-dept-account-btn"
              type="submit"
              className="h-11 w-full rounded-md bg-primary px-5 text-sm text-white md:w-fit"
              loading={loading}
            >
              {t('general.save&Close', 'Save & Close')}
            </Button>
          )}
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
