import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CommentBlock from './CommentBlock'

import { Comment } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCreateComment } from '@/modules/requisitions/hooks'
import { CommentType } from '@/modules/requisitions/types'
import { Document, DocumentType } from '@/modules/shared/types'

interface CommentSectionProps {
  privateComments: Comment[]
  publicComments: Comment[]
  refetch: () => void
  docId: number
  type: DocumentType
  translate: Document
}

export default function CommentSection({
  privateComments,
  publicComments,
  refetch,
  docId,
  type,
  translate,
}: CommentSectionProps) {
  const [commentText, setCommentText] = useState('')
  const [privateCommentText, setPrivateCommentText] = useState('')
  const [createComment, { loading: createLoading }] = useCreateComment()
  const { t } = useTranslation()

  const onCreateComment = (body: string, isPrivate: boolean) => {
    createComment({
      variables: {
        input: {
          commentableType: type,
          commentableId: docId,
          body,
          private: isPrivate,
        },
      },
      onCompleted: () => {
        if (isPrivate) {
          setPrivateCommentText('')
        } else {
          setCommentText('')
        }
        refetch()
      },
    })
  }

  const handleExternalBodyText = (document: Document) => {
    switch (document) {
      case Document.Requisition:
        return t(
          'comments.external.body.requisition',
          'Add an external comment to this Requisition for Suppliers and other users at your organisation to view. When the Requisition is approved and Purchase Orders are generated for Suppliers, these external comments will be included on the Purchase Orders for all Suppliers included in the Requisition.'
        )
      case Document.PurchaseOrder:
        return t(
          'comments.external.body.purchaseOrder',
          'Add an external comment to this Purchase Order for the Supplier and other users at your organisation to view. When the Purchase Order is sent to the Supplier, these external comments will be printed on the Purchase Order. They will also be visible to the Supplier on the Purchase Order if they view it within PurchasePlus.'
        )
      case Document.CreditNote:
        return t(
          'comments.external.body.creditNote',
          'Add an external comment to this Credit Note for the Supplier and other users at your organisation to view. If the Supplier is using PurchasePlus to view this Credit Note, these external comments will be visible to them.'
        )
      case Document.Invoice:
      default: // Document.Stocktake not yet supported
        return t(
          'comments.external.body.invoice',
          'Add an external comment to this Invoice for the Supplier and other users at your organisation to view. If the Supplier is using PurchasePlus to view this Invoice, these external comments will be visible to them.'
        )
    }
  }

  const handleInternalBodyText = (document: Document) => {
    switch (document) {
      case Document.Requisition:
        return t(
          'comments.internal.body.requisition',
          'Add an internal comment to this Requisition for other users at your organisation to view. Suppliers will not be able to view internal comments.'
        )
      case Document.PurchaseOrder:
        return t(
          'comments.internal.body.purchaseOrder',
          'Add an internal comment to this Purchase Order for other users at your organisation to view. The Supplier will not be able to view internal comments.'
        )
      case Document.CreditNote:
        return t(
          'comments.internal.body.creditNote',
          'Add an internal comment to this Credit Note for other users at your organisation to view. The Supplier will not be able to view internal comments.'
        )
      case Document.Invoice:
      default: // Document.Stocktake not yet supported
        return t(
          'comments.internal.body.invoice',
          'Add an internal comment to this Invoice for other users at your organisation to view. The Supplier will not be able to view internal comments.'
        )
    }
  }

  return (
    <>
      {/* Internal */}
      <CommentBlock
        commentType={CommentType.Internal}
        privateComment={true}
        comments={privateComments}
        commentText={privateCommentText}
        titleText={t('general.internalComments', 'Internal Comments')}
        bodyText={handleInternalBodyText(translate)}
        placeholderText={t('comments.internal.placeholder', 'Type an internal comment...')}
        noCommentText={t('comments.internal.noComments', 'No internal comments have been added.')}
        onCreateComment={onCreateComment}
        createLoading={createLoading}
        setText={setPrivateCommentText}
        refetch={refetch}
      />
      {/* External */}
      <CommentBlock
        commentType={CommentType.External}
        privateComment={false}
        comments={publicComments}
        commentText={commentText}
        titleText={t('general.externalComments', 'External Comments')}
        bodyText={handleExternalBodyText(translate)}
        placeholderText={t('comments.external.placeholder', 'Type an external comment...')}
        noCommentText={t('comments.external.noComments', 'No external comments have been added.')}
        onCreateComment={onCreateComment}
        createLoading={createLoading}
        setText={setCommentText}
        refetch={refetch}
      />
    </>
  )
}
