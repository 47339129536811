import { useTranslation } from 'react-i18next'
import { DelimitedArrayParam, useQueryParam } from 'use-query-params'

import AdvancedSearchFilter from './AdvancedSearchFilter'

import { StockLocation } from '@/graphql/inventory/generated/inventory_graphql'
import { useGetAllStockLocations } from '@/modules/inventory/pages/all-stock-locations/hooks/useGetAllStockLocations'
import { Chip, ComboboxServer } from '@/modules/shared/components'
import { QueryParameter } from '@/modules/shared/types'

export default function FilterByStockLocation() {
  const { t } = useTranslation()
  const [stockLocationsURLParam, setStockLocationsURLParam] = useQueryParam(
    QueryParameter.StockLocations,
    DelimitedArrayParam
  )

  const { stockLocations, onFetchMoreStockLocations, networkStatus, refetch, pageInfo } = useGetAllStockLocations()

  const handleSelected = (selectedItem: StockLocation) => {
    if (!stockLocationsURLParam?.includes(selectedItem?.name)) {
      setStockLocationsURLParam((prevParams) => [...(prevParams || []), selectedItem?.name])
    }
  }

  return (
    <AdvancedSearchFilter
      buttonTestId="filters-section"
      buttonTitle={t('advancedSearch.filters.filterByStockLocation', 'Filter By Stock Location')}
    >
      <div className="mb-2 flex flex-col gap-2 lg:max-w-[20rem]">
        <ComboboxServer
          comboItemSelected={!!stockLocationsURLParam}
          placeholder={t('general.stockLocation', 'Stock Location')}
          networkStatus={networkStatus}
          onDisplay={(e) => `${e?.name}`}
          hasError={false}
          keyExtractor={(e) => `${e?.id}`}
          items={stockLocations}
          onInputChange={(e) =>
            refetch({
              filter: {
                q: [
                  {
                    property: 'name_cont',
                    value: e.trim(),
                  },
                ],
              },
            })
          }
          onSelected={handleSelected}
          onFetchMore={onFetchMoreStockLocations}
          hasMore={Boolean(pageInfo?.hasNextPage)}
          testId="select-stock-location"
          disableSelected={true}
        />
      </div>
      {stockLocationsURLParam && (
        <div className="flex flex-wrap items-center gap-2 text-sm" data-testid="chips">
          {stockLocationsURLParam.map((location, index) => (
            <Chip
              key={index}
              onRemoveChip={() => {
                setStockLocationsURLParam((prevParams) => {
                  const newParams = prevParams?.filter((param) => param !== location) || []
                  if (newParams.length > 0) return newParams
                  return undefined
                })
              }}
              label={String(location)}
            />
          ))}
        </div>
      )}
    </AdvancedSearchFilter>
  )
}
