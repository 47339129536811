import accessMutations from './access/mutations'
import accessQueries from './access/queries'
import inventoryQueries from './inventory/queries'
import purchasingMutations from './purchasing/mutations'
import purchasingQueries from './purchasing/queries'

const queries = [...purchasingQueries, ...inventoryQueries, ...accessQueries]

const mutations = [...purchasingMutations, ...accessMutations]

export const handlers = queries.concat(mutations)
