import { useTranslation } from 'react-i18next'

import Button from '../../button'
import Tooltip from '../../tooltip'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useInvoiceActions } from '@/modules/invoices/hooks'
import InvoiceActionSuccessModal from '@/modules/invoices/pages/invoice/page-header/InvoiceActionSuccessModal'
import { InvoiceActionTypes, InvoiceStatuses } from '@/modules/invoices/types'
import { CheckAltIcon } from '@/modules/shared/icons'

export interface OperationallyApprovedProps {
  invoice: Invoice
  type: InvoiceActionTypes.OperationalApprove | InvoiceActionTypes.FinancialApprove
}

export default function InvoiceApproval({ invoice, type }: OperationallyApprovedProps) {
  const { t } = useTranslation()
  const { isOperationallyApproved, isFinanciallyApproved, state } = invoice || {}
  const {
    showInvoiceActionSuccessModal,
    setShowInvoiceActionSuccessModal,
    invoiceAction,
    onConfirmInvoiceAction,
    confirmActionLoading,
  } = useInvoiceActions(invoice)

  const isTypeOperational = type === InvoiceActionTypes.OperationalApprove

  const isApproved = isTypeOperational ? isOperationallyApproved : isFinanciallyApproved

  return (
    <>
      {isApproved ? (
        <Tooltip
          content={
            isTypeOperational
              ? t(
                  'invoice.operationallyApprovedTooltip.approved',
                  'This Invoice has already been granted Operational Approval'
                )
              : t(
                  'invoice.financiallyApprovedTooltip.approved',
                  'This Invoice has already been granted Financial Approval'
                )
          }
          showArrow={false}
        >
          <CheckAltIcon className="h-10 w-10 cursor-pointer rounded-full bg-success p-2 text-white hover:bg-success/90" />
        </Tooltip>
      ) : (
        <Tooltip
          content={
            isTypeOperational
              ? t(
                  'invoice.operationallyApprovedTooltip.notApproved',
                  'This Invoice has not yet been granted Operational Approval. Click to Approve.'
                )
              : t(
                  'invoice.financiallyApprovedTooltip.notApproved',
                  'This Invoice has not yet been granted Financial Approval. Click to Approve.'
                )
          }
          showArrow={false}
        >
          <Button
            className="rounded-full bg-gray-200 px-4 py-2.5"
            onClick={() => onConfirmInvoiceAction(type)}
            loading={confirmActionLoading}
            data-testid="approve-invoice-button"
          >
            {t('general.approve', 'Approve')}
          </Button>
        </Tooltip>
      )}
      <InvoiceActionSuccessModal
        invoiceAction={invoiceAction as InvoiceActionTypes}
        showModal={showInvoiceActionSuccessModal}
        setShowModal={setShowInvoiceActionSuccessModal}
        state={state as InvoiceStatuses}
      />
    </>
  )
}
