import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BooleanParam, useQueryParam } from 'use-query-params'

import { NestedPurchaserRow } from './NestedPurchaserRow'
import { generateNestedOrgs } from './utils/generateNestedOrgs'

import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import { Button, ConfirmDialog, QueryResult } from '@/modules/shared/components'
import { useGetDepartments } from '@/modules/shared/hooks'
import { ChevronDownIcon } from '@/modules/shared/icons'
import { QueryParameter, Themes } from '@/modules/shared/types'

export default function OrganisationHierarchy() {
  const { t } = useTranslation()
  const [showOrgHierarchy, setShowOrgHierarchy] = useQueryParam(QueryParameter.OpenHierarchy, BooleanParam)
  const [showComingSoon, setShowComingSoon] = useState(false)
  const { data, loading, error } = useGetDepartments()
  const { currentOrganisation } = useCurrentOrganisation()

  const orgHierarchyData = useMemo(() => {
    if (data) {
      return generateNestedOrgs(data, currentOrganisation?.id)
    }
    return null
  }, [data])

  return (
    <section className="rounded-md bg-white text-sm shadow-sm">
      <div className="flex min-h-[60px] items-center justify-between px-4 py-3">
        <div className="flex items-center gap-x-2 font-bold">
          {t('general.organisationHierarchy', 'Organisation Hierarchy')}
        </div>
        <div className="flex items-center gap-x-2">
          <Button
            className="rounded-md bg-gray-200 px-4 py-2"
            onClick={() => setShowComingSoon(true)}
            data-testid="add-org-modal-button"
          >
            {t('general.addOrganisation', 'Add Organisation')}
          </Button>
          <ChevronDownIcon
            className={classNames(
              'h-8 w-8 cursor-pointer rounded-full p-1 text-gray-500 transition hover:bg-gray-100',
              {
                '-rotate-180 transform': showOrgHierarchy,
              }
            )}
            onClick={() => setShowOrgHierarchy(!showOrgHierarchy)}
            data-testid="show-org-hierarchy-panel"
          />
        </div>
      </div>
      <hr />
      {showOrgHierarchy && (
        <QueryResult loading={loading} error={error} spinnerClassName="mb-12">
          <div className="p-4">
            <table className="w-full bg-white text-left text-sm" data-testid="org-hierarchy-table">
              <thead className="text-xs text-gray-500">
                <tr>
                  <th className="w-full whitespace-nowrap">
                    <div className="w-min rounded bg-gray-100 px-3 py-1">
                      {t('general.organisationName', 'Organisation Name')}
                    </div>
                  </th>
                  <th className="min-w-[6rem]  whitespace-nowrap">
                    <div className="flex justify-center">
                      <div className="w-min rounded bg-gray-100 px-3 py-1">
                        {t('general.legalEntity', 'Legal Entity')}
                      </div>
                    </div>
                  </th>
                  <th className="min-w-[11rem] whitespace-nowrap">
                    <div className="flex justify-center">
                      <div className="w-min rounded bg-gray-100 px-3 py-1">
                        {t('general.costCenterCode', 'Cost Center Code')}
                      </div>
                    </div>
                  </th>
                  <th className="min-w-[12rem] whitespace-nowrap">
                    <div className="flex justify-center">
                      <div className="w-min rounded bg-gray-100 px-3 py-1">
                        {t('general.defaultAccount', 'Default Account')}
                      </div>
                    </div>
                  </th>
                  <th className="min-w-[6rem] whitespace-nowrap">
                    <div className="flex justify-center">
                      <div className="w-min rounded bg-gray-100 px-3 py-1">{t('general.viewInfo', 'View Info')}</div>
                    </div>
                  </th>
                  <th className="min-w-[6rem] whitespace-nowrap">
                    <div className="flex justify-center">
                      <div className="w-min rounded bg-gray-100 px-3 py-1">{t('general.select', 'Select')}</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <NestedPurchaserRow purchaser={orgHierarchyData} level={0} />
              </tbody>
            </table>
          </div>
        </QueryResult>
      )}
      <ConfirmDialog
        theme={Themes.Primary}
        isOpen={showComingSoon}
        setIsOpen={setShowComingSoon}
        title={t('general.comingSoon', 'Coming Soon!')}
        description={t(
          'organisationHierarchy.comingSoonContent',
          "Soon you will be able to add and organisation here, but we're still working on it."
        )}
        primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
        onPrimaryBtnClick={() => setShowComingSoon(false)}
      />
    </section>
  )
}
