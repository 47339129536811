import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, generatePath, useLocation, useParams } from 'react-router-dom'

import { GetPoReceivingNoteDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { QueryResult } from '@/modules/shared/components'
import { ENTERPRISE_RECEIVING_TRANSFER, PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { CheckIcon, ChevronLeftIcon } from '@/modules/shared/icons'

export default function ReceivingDocSuccessState() {
  const { t } = useTranslation()
  const { receivingNoteId, purchaseOrderId } = useParams<{ receivingNoteId: string; purchaseOrderId: string }>()
  const location = useLocation()
  const { data, loading, error } = useQuery(GetPoReceivingNoteDocument, {
    variables: {
      receivingDocumentId: Number(receivingNoteId),
      purchaseOrderId: Number(purchaseOrderId),
    },
    fetchPolicy: 'network-only',
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })
  const transfer = data?.currentPurchaser?.purchaseOrder?.receivingDocument?.transfer
  const receiveDocument = data?.currentPurchaser?.purchaseOrder?.receivingDocument
  const receiveDocumentPO = data?.currentPurchaser?.purchaseOrder
  const receivedSuccessState = location?.state?.receivedSuccess || null

  if (!receivedSuccessState) {
    return (
      <Navigate
        to={generatePath('/purchase-orders/:purchaseOrderId/summary', {
          purchaseOrderId: String(purchaseOrderId),
        })}
        replace
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>{`${data?.currentPurchaser?.purchaseOrder?.receivingDocument?.receivingDocumentNumber || ''} ${t(
          'general.successfullyReceived',
          'Successfully Received'
        )}`}</title>
      </Helmet>
      <header className="px-4 py-3">
        <Link
          data-testid="action-return"
          to={generatePath('/purchase-orders/:purchaseOrderId/summary', {
            purchaseOrderId: String(purchaseOrderId),
          })}
          className="flex h-11 w-fit items-center justify-between gap-x-2 rounded-md bg-gray-200 px-4 transition hover:brightness-95"
        >
          <ChevronLeftIcon className="-ml-2 h-5 w-5" />
          <p className="text-sm">{t('receivingNotes.returnToPO', 'Return to Purchase Order')}</p>
        </Link>
      </header>
      <div className="mx-auto w-full px-4 py-9 lg:w-[43.625rem]">
        <div className="flex flex-col items-center justify-center gap-y-5">
          <CheckIcon className="h-16 w-16 text-success" />
          <h1 className="text-center text-2xl font-bold">
            {t('general.successfullyReceived', 'Successfully Received')}
          </h1>
          <p className="px-5 text-center text-sm text-gray-500">
            {t(
              'receivingNotes.receivedSuccessDesc',
              "Your items have been successfully received and we've created a Receiving Note to record this receiving event. You can visit the Receiving Note below or return to the Purchase Order using the button above. If you received your items to an Inventory location, a transfer file has also been created to record this."
            )}
          </p>
        </div>
        <QueryResult loading={loading} error={error}>
          <div className="mt-10">
            {!!receiveDocument && (
              <div
                className={classNames(
                  'flex items-center justify-between rounded-t-md  border-b bg-white p-5 shadow-sm',
                  {
                    'rounded-b-none': !!transfer,
                    'rounded-b-md border-b-0 bg-primary': !transfer,
                  }
                )}
                data-testid="receiving-note-item"
              >
                <span>
                  <strong>{receiveDocument?.receivingDocumentNumber}</strong>
                  <p className="text-sm text-gray-500">
                    {receiveDocumentPO?.purchaseOrderNumber} {t('general.receivingNote', 'Receiving Note')}
                  </p>
                </span>
                <Link
                  // TODO: will land user on a receiving note detail page (PP-1623)
                  to={generatePath('/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId', {
                    purchaseOrderId: String(purchaseOrderId),
                    receivingNoteId: String(receivingNoteId),
                  })}
                  className="flex h-11 w-24 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
                >
                  {t('general.view', 'View')}
                </Link>
              </div>
            )}
            {!!transfer && (
              <div
                className="flex items-center justify-between rounded-b-md rounded-t-none bg-white p-5 shadow-sm"
                data-testid="transfer-item"
              >
                <span>
                  <strong>{transfer?.transferNumber}</strong>
                  <p className="text-sm text-gray-500">
                    {receiveDocumentPO?.purchaseOrderNumber} {t('general.receivingTransfer', 'Receiving Transfer')}
                  </p>
                </span>
                <a
                  href={`${ENTERPRISE_RECEIVING_TRANSFER}/${transfer?.id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="flex h-11 w-24 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
                >
                  {t('general.view', 'View')}
                </a>
              </div>
            )}
          </div>
        </QueryResult>
      </div>
    </>
  )
}
