import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Activity, GetPoActivitiesDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { ClockIcon } from '@/modules/shared/icons'
import { AuditLogTable } from '@/modules/shared/layouts'
import { extractEdges } from '@/modules/shared/utils'

function POAuditLog() {
  const { t } = useTranslation()
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>()
  const { loading, error, data } = useQuery(GetPoActivitiesDocument, {
    fetchPolicy: 'cache-and-network',
    context: { uri: PURCHASING_GRAPHQL_API },
    variables: {
      purchaseOrderId: Number(purchaseOrderId),
    },
  })

  const auditLogsList = extractEdges<Activity>(data?.currentPurchaser?.purchaseOrder?.activities)
  const poNumber = data?.currentPurchaser?.purchaseOrder?.purchaseOrderNumber

  return (
    <QueryResult loading={loading} error={error}>
      <div className="flex items-center gap-x-2">
        <ClockIcon className="h-11 w-11" />
        <span className="text-sm" data-testid={`poNumber-${poNumber}`}>
          {t('auditLog.number', '{{ number }} Audit Log', { number: poNumber })}
        </span>
      </div>
      <div className="overflow-x-auto">
        {auditLogsList.length > 0 && <AuditLogTable auditLogsList={auditLogsList} />}
      </div>
    </QueryResult>
  )
}

export default POAuditLog
