import { graphql } from 'msw'

import { GetAllCreditNotesQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetAllCreditNotes: GetAllCreditNotesQuery = {
  currentPurchaser: {
    id: 44926,
    creditNotes: {
      edges: [
        {
          node: {
            id: 813,
            workflowState: 'reconciled',
            creditNoteNumber: '12314',
            reference: 'Hello',
            creditNoteDate: '2016-04-07',
            totalValue: 42.35,
            totalTaxValue: 3.85,
            discountTotal: 0,
            createdAt: '2016-04-18T17:01:34+10:00',
            __typename: 'CreditNote',
            owner: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Purchaser',
            },
            department: {
              id: 85127,
              name: 'Beverage',
              __typename: 'Purchaser',
            },
            supplier: {
              id: 15065,
              name: 'Sydney Wholesale Co',
              __typename: 'Supplier',
            },
            invoice: {
              id: 532947,
              invoiceNumber: 'Test557578686',
              __typename: 'Invoice',
            },
            creator: {
              id: 3708,
              fullName: 'Kristine Tadem',
              __typename: 'User',
            },
          },
          __typename: 'CreditNoteEdge',
        },
        {
          node: {
            id: 834,
            workflowState: 'reconciled',
            creditNoteNumber: 'CNujgut97987986',
            reference: '',
            creditNoteDate: '2016-04-13',
            totalValue: 0.8,
            totalTaxValue: 0,
            discountTotal: 0,
            createdAt: '2016-04-20T14:58:01+10:00',
            __typename: 'CreditNote',
            owner: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Purchaser',
            },
            department: {
              id: 44926,
              name: 'Test Account',
              __typename: 'Purchaser',
            },
            supplier: {
              id: 168,
              name: 'Zeus Wholesale',
              __typename: 'Supplier',
            },
            invoice: {
              id: 549538,
              invoiceNumber: '897967869860o7',
              __typename: 'Invoice',
            },
            creator: {
              id: 3744,
              fullName: 'Anne Cailleaux',
              __typename: 'User',
            },
          },
          __typename: 'CreditNoteEdge',
        },
      ],

      __typename: 'CreditNoteConnection',
      pageInfo: {
        hasPreviousPage: false,
        hasNextPage: false,
        startCursor: null,
        endCursor: null,
        __typename: 'PageInfo',
      },
    },
    __typename: 'Purchaser',
  },
}

export const GetAllCreditNotes = graphql.query<GetAllCreditNotesQuery>('GetAllCreditNotes', (_, res, ctx) => {
  return res(ctx.data(baseGetAllCreditNotes))
})

export default baseGetAllCreditNotes
