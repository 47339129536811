import { graphql } from 'msw'

import { ParkInvoiceMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseParkInvoice: ParkInvoiceMutation = {
  parkInvoice: {
    invoice: {
      id: 7524140,
      state: 'parked',
      __typename: 'Invoice',
    },
    __typename: 'ParkInvoiceMutationPayload',
  },
}

export const ParkInvoice = graphql.mutation<ParkInvoiceMutation>('ParkInvoice', (_, res, ctx) => {
  return res(ctx.data(baseParkInvoice))
})

export default baseParkInvoice
