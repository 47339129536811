import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SupplierQuotesModalBody } from './SupplierQuotesModalBody'
import { SupplierQuotesModalFooter } from './SupplierQuotesModalFooter'
import { useSupplierQuotesModalActions } from './hooks/useSupplierQuotesModalActions'

import { Price, Product } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Modal } from '@/modules/shared/components'

export interface SupplierQuotesModalProps {
  showModal: boolean
  setShowModal: (value: false) => void
  currentSupplierId?: number | null // the supplier visible on the button where the modal is opened from
  product: Product | undefined | null
  prices: Price[] | null
  lineId?: number | null // a lineId is required to change the supplier
  image?: string | null
  refetch?: () => void
  basicShop?: boolean // displays the basic shop text
  cataloguedProductId?: number | null // a cataloguedProductId is required for advanced mode
  originalQuantity?: number | null // a originalQuantity is required for advanced mode
}

export default function SupplierQuotesModal({
  showModal,
  setShowModal,
  product,
  prices,
  currentSupplierId,
  lineId,
  image,
  refetch,
  basicShop,
  cataloguedProductId,
  originalQuantity,
}: SupplierQuotesModalProps) {
  const { t } = useTranslation()
  const { id: productId } = product || {}
  const [quantity, setQuantity] = useState(0)
  const [selectedSupplier, setSelectedSupplier] = useState<Price | null>()

  const onCloseModal = () => {
    setSelectedSupplier(null)
    setShowModal(false)
  }

  const { onCreateRequisitionLine, updateSupplier, loading } = useSupplierQuotesModalActions({
    onCloseModal,
    refetch,
    cataloguedProductId: Number(selectedSupplier?.cataloguedProductId),
  })

  const handleCreateRequisitionLine = () => {
    if (productId && selectedSupplier) {
      onCreateRequisitionLine({ productId, selectedSupplier, quantity })
    }
  }

  const handleUpdateSupplier = () => {
    updateSupplier({ lineId, selectedSupplier, quantity })
  }

  useEffect(() => {
    // showModal will reset back to default state when closing the modal
    if (prices && currentSupplierId && showModal) {
      setSelectedSupplier(prices.find((price) => price.supplierId === currentSupplierId))
    }
  }, [prices, currentSupplierId, showModal])

  useEffect(() => {
    if (showModal) setQuantity(originalQuantity || 0)
  }, [originalQuantity, showModal])

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        as="div"
        className="flex max-h-[80vh] w-full flex-col rounded-md bg-white shadow-xl transition-all md:max-w-[56.25rem]"
      >
        <Modal.Title title={t('general.supplierQuotes', 'Supplier Quotes')} onCloseModal={onCloseModal} />
        <SupplierQuotesModalBody
          currentSupplierId={currentSupplierId}
          lineId={lineId}
          image={image}
          basicShop={basicShop}
          cataloguedProductId={cataloguedProductId}
          selectedSupplier={selectedSupplier}
          setSelectedSupplier={setSelectedSupplier}
          product={product}
          prices={prices}
        ></SupplierQuotesModalBody>
        <SupplierQuotesModalFooter
          currentSupplierId={currentSupplierId}
          lineId={lineId}
          onCloseModal={onCloseModal}
          updateSupplier={handleUpdateSupplier}
          createRequisitionLine={handleCreateRequisitionLine}
          selectedSupplier={selectedSupplier}
          advanced={!!cataloguedProductId || (!prices?.length && !basicShop)}
          originalQuantity={originalQuantity || 0}
          quantity={quantity}
          setQuantity={setQuantity}
          loading={loading}
        />
      </Modal.Panel>
    </Modal>
  )
}
