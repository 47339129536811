import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import { useAtomValue } from 'jotai'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { CreateRequisitionLineDocument, RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import { isChangingProductQtyAtom } from '@/modules/requisitions/state'
import { Button, ConfirmDialog } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { Themes } from '@/modules/shared/types'

interface AddItemProps {
  line: RequisitionLine
}

function AddItem({ line }: AddItemProps) {
  const { t } = useTranslation()
  const { requisitionId } = useParams<{ requisitionId: string }>()
  const [isOpen, setIsOpen] = useState(false)
  const isUpdatingQuantity = useAtomValue(isChangingProductQtyAtom)
  const [createRequisitionLine, { loading }] = useMutation(CreateRequisitionLineDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },

    onCompleted(data) {
      const errors = data.createRequisitionLine.errors
      if (errors) {
        errors.forEach((e) => {
          if (e.extensions?.code === 406) {
            e.message.forEach((m) => {
              if (m === 'Product has already been taken') {
                setIsOpen(true)
              }
            })
          }
        })
      }
    },
  })

  const onCreateRequisitionLine = () => {
    if (line.supplier?.id) {
      createRequisitionLine({
        variables: {
          input: {
            cataloguedProductId: line.cataloguedProductId,
            productId: line.productId,
            supplierId: line.supplier?.id,
            unitPrice: line.unitPrice,
            requisitionId: Number(requisitionId), // TODO: API should provide this
            taxPercentage: line.taxPercentage,
            quantity: 1,
          },
        },
      })
    }
  }

  return (
    <>
      <Button
        data-testid="add-product-btn"
        loading={loading}
        className={classNames('w-40 rounded-full bg-gray-200 py-2.5 text-sm', {
          'pointer-events-none': isUpdatingQuantity, // to avoid 409 error
        })}
        onClick={onCreateRequisitionLine}
      >
        {line.supplier?.id ? t('general.addItem', 'Add Item') : t('general.noSuppliers', 'No Suppliers')}
      </Button>
      <ConfirmDialog
        title={t('shopPage.productList.productAddedTitle', 'Already Added to Cart')}
        description={t(
          'shopPage.productList.productAddedDesc',
          "You have already added a product exactly the same as this to your cart from a different Supplier. You can't add this product to your cart unless you remove the other product."
        )}
        primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
        onPrimaryBtnClick={() => setIsOpen(false)}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        theme={Themes.Primary}
      />
    </>
  )
}

export default AddItem
