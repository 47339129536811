import { graphql } from 'msw'

import { UpdateCataloguedProductMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const baseUpdateCataloguedProduct: UpdateCataloguedProductMutation = {
  updateCataloguedProduct: {
    cataloguedProduct: {
      id: 22675827,
      department: {
        id: 44926,
        name: 'Test Account',
        __typename: 'Purchaser',
      },
      account: {
        id: 97162,
        accountName: '9999911 - No. of Guest Arrivals',
        __typename: 'Account',
      },
      __typename: 'CataloguedProduct',
    },
  },
}

export const UpdateCataloguedProduct = graphql.mutation<UpdateCataloguedProductMutation>(
  'UpdateCataloguedProduct',
  (_, res, ctx) => {
    return res(ctx.data(baseUpdateCataloguedProduct))
  }
)
