import { useCurrentOrganisation } from '@/modules/purchasing/hooks'

interface ElementSwitchProps {
  aStoreElement: JSX.Element
  defaultElement: JSX.Element
}

function ElementSwitch(props: ElementSwitchProps) {
  const { currentOrganisation } = useCurrentOrganisation()

  if (currentOrganisation?.ppxAccess) return props.aStoreElement

  return props.defaultElement
}

export default ElementSwitch
