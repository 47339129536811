import { graphql } from 'msw'

import { GetAllCatalogsQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetAllCatalogsObject: GetAllCatalogsQuery = {
  currentPurchaser: {
    id: 44926,
    availableCatalogues: {
      edges: [
        {
          node: {
            id: 137123,
            title: 'Self Managed - New Yen Yen',
            supplier: {
              id: 26627,
              name: 'Bezzera',
              __typename: 'Supplier',
            },
            owner: {
              id: 44926,
              name: 'Test Account',
              isAPurchaser: true,
              isASupplier: false,
              __typename: 'Purchaser',
            },
            updatedAt: '2019-07-24T11:39:10+11:00',
            __typename: 'PricedCatalogue',
          },
          __typename: 'PricedCatalogueEdge',
        },
        {
          node: {
            id: 137283,
            title: 'Self Managed - AAPC',
            supplier: {
              id: 67087,
              name: 'AAPC Limited',
              __typename: 'Supplier',
            },
            owner: {
              id: 44926,
              name: 'Test Account',
              isAPurchaser: false,
              isASupplier: true,
              __typename: 'Supplier',
            },
            updatedAt: '2022-01-27T11:39:10+11:00',
            __typename: 'PricedCatalogue',
          },
          __typename: 'PricedCatalogueEdge',
        },
      ],
      pageInfo: {
        endCursor: null,
        startCursor: null,
        hasPreviousPage: false,
        hasNextPage: false,
        __typename: 'PageInfo',
      },
    },
    __typename: 'Purchaser',
  },
}

export const GetAllCatalogs = graphql.query<GetAllCatalogsQuery>('GetAllCatalogs', (_, res, ctx) => {
  return res(ctx.data(baseGetAllCatalogsObject))
})

export default baseGetAllCatalogsObject
