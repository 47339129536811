import { graphql } from 'msw'

import { CompleteInvoiceMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseCompleteInvoice: CompleteInvoiceMutation = {
  completeInvoice: {
    invoice: {
      id: 7524140,
      state: 'flagged',
      isOperationallyApproved: false,
      isFinanciallyApproved: false,
      __typename: 'Invoice',
    },
    __typename: 'CompleteInvoiceMutationPayload',
  },
}

export const CompleteInvoice = graphql.mutation<CompleteInvoiceMutation>('CompleteInvoice', (_, res, ctx) => {
  return res(ctx.data(baseCompleteInvoice))
})

export default baseCompleteInvoice
