import { useState } from 'react'

import { useDeleteRequisitionLine, useUpdateRequisitionLine } from '@/modules/requisitions/hooks'

export default function useRequisitionLineMutations(lineId: number, productId: number) {
  const [updateRequisitionLine, { loading }] = useUpdateRequisitionLine()
  const [deleteRequisitionLine, { loading: loadingDelete }] = useDeleteRequisitionLine()
  const [activeOrderInstructionLoadingId, setActiveOrderInstructionLoadingId] = useState<number | null>(null)
  const [activeDeptAndAccountLoadingId, setActiveDeptAndAccountLoadingId] = useState<number | null>(null)

  const onRemoveOrderInstruction = () => {
    setActiveOrderInstructionLoadingId(lineId)
    updateRequisitionLine({
      variables: {
        input: {
          id: Number(lineId),
          productId,
          orderInstruction: null,
        },
      },
    })
  }

  const onRemoveRequisitionLine = () => {
    deleteRequisitionLine({
      variables: {
        input: {
          id: Number(lineId),
        },
      },
    })
  }

  const onRemoveDeptAndAccount = () => {
    setActiveDeptAndAccountLoadingId(lineId)
    updateRequisitionLine({
      variables: {
        input: {
          id: Number(lineId),
          productId,
          departmentId: null,
          accountId: null,
        },
      },
    })
  }

  return {
    onRemoveOrderInstruction,
    onRemoveRequisitionLine,
    onRemoveDeptAndAccount,
    loading,
    loadingDelete,
    activeOrderInstructionLoadingId,
    activeDeptAndAccountLoadingId,
  }
}
