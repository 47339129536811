import { graphql } from 'msw'

import { GetRequisitionQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetRequisition: GetRequisitionQuery = {
  currentPurchaser: {
    id: 44926,
    requisition: {
      id: 5235941,
      sourceApplication: 'ppx',
      editableForCurrentUser: false,
      state: 'awaiting_approval',
      requisitionNumber: 'PR00040',
      reference: 'reference',
      originName: null,
      advanced: false,
      creator: {
        id: 19897,
        fullName: 'Johan W. Baaij',
      },
      createdAt: '2022-07-08T12:49:06+10:00',
      totalValueExcludingTax: 99.604,
      totalTaxValue: 4.896,
      totalValue: 104.5,
      nextApprovingAgentsNames: 'Kevin Milner',
      expectedDeliveryDate: '2022-07-12',
      sendToSupplier: true,
      awaitingMyApproval: true,
      commentsCount: 0,
      nextApprovalUsers: [
        { id: 19897, fullName: 'Johan W. Baaij', __typename: 'User' },
        {
          id: 18544,
          fullName: 'Sunny H Sun',
          __typename: 'User',
        },
      ],
      requestor: {
        id: 19897,
        fullName: 'Johan W. Baaij',
        createdAt: '2022-07-08T12:49:06+10:00',
        __typename: 'User',
      },
      deliveryAddress: {
        id: 1226,
        locationName: 'Main Dock',
        line1: 'MB Head Office',
        line2: '343 Pacific Hwy',
        city: 'North Sydney',
        stateProvince: 'NSW',
        country: 'AU',
      },
      receivingDocuments: {
        edges: [
          {
            node: {
              id: 6659699,
              receivingDocumentNumber: 'GR02325',
              __typename: 'ReceivingDocument',
            },
          },
          {
            node: {
              id: 6659700,
              receivingDocumentNumber: 'GR02326',
              __typename: 'ReceivingDocument',
            },
          },
        ],
        __typename: 'ReceivingDocumentConnection',
      },
      invoices: {
        edges: [
          {
            node: {
              id: 884359,
              invoiceNumber: '6783264327',
              __typename: 'Invoice',
            },
          },
          {
            node: {
              id: 1036909,
              invoiceNumber: '1234556',
              __typename: 'Invoice',
            },
          },
        ],
        __typename: 'InvoiceConnection',
      },
      suppliers: {
        edges: [],
      },
      approvalWorkflow: {
        id: 5972,
        title: 'Demo',
      },
      department: {
        id: 204632,
        name: 'AStore Shop Demo',
      },
      account: {
        id: 256446,
        accountName: '0060110 - Tickets for Attractions',
      },
      purchaseOrders: {
        edges: [
          {
            node: {
              id: 3929,
              sentDate: null,
              purchaseOrderNumber: 'PO22932',
              totalValue: 40.23,
              sendToSupplier: false,
              status: 'Not Sent',
              supplier: {
                name: 'Supplier 1',
                id: 48289,
                __typename: 'Supplier',
              },
              __typename: 'PurchaseOrder',
            },
          },
          {
            node: {
              id: 3123,
              sentDate: '2022-11-04T16:22:34+11:00',
              purchaseOrderNumber: 'PO543555',
              totalValue: 82.12,
              sendToSupplier: false,
              status: 'Sent',
              supplier: {
                name: 'Supplier 2',
                id: 31233,
                __typename: 'Supplier',
              },
              __typename: 'PurchaseOrder',
            },
          },
          {
            node: {
              id: 5342,
              sentDate: null,
              purchaseOrderNumber: 'PO29992',
              totalValue: 12.11,
              sendToSupplier: false,
              status: 'Not Sent',
              supplier: {
                name: 'Supplier 3',
                id: 53331,
                __typename: 'Supplier',
              },
              __typename: 'PurchaseOrder',
            },
          },
        ],
        __typename: 'PurchaseOrderConnection',
      },
      __typename: 'Requisition',
    },
    __typename: 'Purchaser',
  },
}

export const GetRequisition = graphql.query<GetRequisitionQuery>('GetRequisition', (req, res, ctx) => {
  return res(ctx.data(baseGetRequisition))
})

export default baseGetRequisition
