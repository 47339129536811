import { useCallback, useState } from 'react'

import { ShoppingNotAvailableModal } from '../components'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { SOURCE_APPLICATION } from '@/modules/shared/constants'

export default function useCheckShoppingAvailable() {
  const [showShoppingNotAvailable, setShowShoppingNotAvailable] = useState(false)
  const [activeRequisitionId, setActiveRequisitionId] = useState<number>(0)

  const modalProps = {
    open: showShoppingNotAvailable,
    setOpen: setShowShoppingNotAvailable,
    requisitionId: activeRequisitionId,
  }

  const onShowModal = useCallback(() => {
    setShowShoppingNotAvailable(true)
  }, [setShowShoppingNotAvailable])

  const onCheckShoppingAvailable = useCallback(
    (requisition: Requisition): boolean => {
      setActiveRequisitionId(requisition.id)
      return requisition?.editableForCurrentUser && requisition?.sourceApplication === SOURCE_APPLICATION
    },
    [setActiveRequisitionId]
  )

  return {
    onShowModal,
    ShoppingNotAvailableModal,
    onCheckShoppingAvailable,
    modalProps,
  }
}
