import { memo } from 'react'

import ProductCard from './product-card-tile-view/ProductCard'

import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ProductListType, ProductType } from '@/modules/shared/types'

interface ProductsTileViewProps {
  products: ProductType[]
  type: ProductListType
  lines?: RequisitionLine[]
}

function ProductsTileView({ products, type, lines }: ProductsTileViewProps) {
  return (
    <div className="grid gap-2 md:grid-cols-2 lg:grid-cols-3" data-testid="basic-product-list">
      {products.map((product) => {
        return <ProductCard key={product.cataloguedProductId} product={product} type={type} lines={lines} />
      })}
    </div>
  )
}

export default memo(ProductsTileView)
