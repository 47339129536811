import { ApolloError } from '@apollo/client/errors'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InvoiceActionTypes } from '../types'
import {
  useCancelInvoice,
  useCompleteInvoice,
  useFinancialApproveInvoice,
  useHaltInvoice,
  useOperationalApproveInvoice,
  useParkInvoice,
  useProcessInvoice,
  useReopenInvoice,
  useResendInvoice,
} from './index'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useAlert } from '@/modules/shared/hooks'

export default function useInvoiceActions(invoice: Invoice) {
  const { id, outOfBalance, lines } = invoice || {}

  const [showConfirmActionDialog, setShowConfirmActionDialog] = useState<boolean>(false)
  const [invoiceAction, setInvoiceAction] = useState<InvoiceActionTypes | null>(null)
  const [showInvoiceActionSuccessModal, setShowInvoiceActionSuccessModal] = useState<boolean>(false)
  const { alertDialog } = useAlert()
  const { t } = useTranslation()

  const [operationalApproveInvoice, { loading: operationalApproveInvoiceLoading }] = useOperationalApproveInvoice()
  const [financialApproveInvoice, { loading: financialApproveInvoiceLoading }] = useFinancialApproveInvoice()
  const [completeInvoice, { loading: completeInvoiceLoading }] = useCompleteInvoice()
  const [processInvoice, { loading: processInvoiceLoading }] = useProcessInvoice()
  const [cancelInvoice, { loading: cancelInvoiceLoading }] = useCancelInvoice()
  const [reopenInvoice, { loading: reopenInvoiceLoading }] = useReopenInvoice()
  const [resendInvoice, { loading: resendInvoiceLoading }] = useResendInvoice()
  const [haltInvoice, { loading: haltInvoiceLoading }] = useHaltInvoice()
  const [parkInvoice, { loading: parkInvoiceLoading }] = useParkInvoice()

  const mutationOptions = {
    variables: { input: { id } },
    onCompleted: () => {
      setShowConfirmActionDialog(false)
      setShowInvoiceActionSuccessModal(true)
    },
  }

  const approveErrors = {
    onError: ({ graphQLErrors }: ApolloError) => {
      graphQLErrors?.forEach((e) => {
        alertDialog({
          type: 'error',
          title: t('general.approvalNotPermitted', 'Approval Not Permitted'),
          message: String(e.path![0]).includes('financial')
            ? t(
                'invoices.invoice.actionSuccess.financialApprove.message',
                "You don't have the permission to Financially Approve this Invoice. Please speak with a PurchasePlus administrator at your organisation if you think you need this permission."
              )
            : t(
                'invoices.invoice.actionSuccess.operationalApprove.message',
                "You don't have the permission to Operationally Approve this Invoice. Please speak with a PurchasePlus administrator at your organisation if you think you need this permission."
              ),
          buttonText: t('general.okay', 'Okay'),
        })
      })
    },
  }

  const onShowConfirmActionDialog = (action: InvoiceActionTypes) => {
    setInvoiceAction(action)
    setShowConfirmActionDialog(true)
  }

  const onConfirmInvoiceAction = (action?: InvoiceActionTypes) => {
    setInvoiceAction(action ?? invoiceAction)
    switch (action ?? invoiceAction) {
      case InvoiceActionTypes.OperationalApprove:
        if (outOfBalance) {
          return onShowConfirmActionDialog(InvoiceActionTypes.OperationalApprove)
        } else {
          return operationalApproveInvoice({ ...mutationOptions, ...approveErrors })
        }
      case InvoiceActionTypes.FinancialApprove:
        if (outOfBalance) {
          return onShowConfirmActionDialog(InvoiceActionTypes.FinancialApprove)
        } else {
          return financialApproveInvoice({ ...mutationOptions, ...approveErrors })
        }
      case InvoiceActionTypes.Process:
        return processInvoice(mutationOptions)
      case InvoiceActionTypes.Cancel:
        return cancelInvoice(mutationOptions)
      case InvoiceActionTypes.Reopen:
        return reopenInvoice(mutationOptions)
      case InvoiceActionTypes.Resend:
        return resendInvoice(mutationOptions)
      case InvoiceActionTypes.Halt:
        return haltInvoice(mutationOptions)
      case InvoiceActionTypes.Park:
        return parkInvoice(mutationOptions)
      case InvoiceActionTypes.Complete:
        if (outOfBalance || !lines?.edges?.length) {
          return onShowConfirmActionDialog(InvoiceActionTypes.Complete)
        } else {
          return completeInvoice(mutationOptions)
        }
    }
  }

  const confirmActionLoading =
    operationalApproveInvoiceLoading ||
    financialApproveInvoiceLoading ||
    completeInvoiceLoading ||
    processInvoiceLoading ||
    cancelInvoiceLoading ||
    reopenInvoiceLoading ||
    resendInvoiceLoading ||
    haltInvoiceLoading ||
    parkInvoiceLoading

  return {
    showConfirmActionDialog,
    setShowConfirmActionDialog,
    showInvoiceActionSuccessModal,
    setShowInvoiceActionSuccessModal,
    onShowConfirmActionDialog,
    invoiceAction,
    confirmActionLoading,
    onConfirmInvoiceAction,
  }
}
