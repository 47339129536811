import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { PurchaseOrderLineItemInvoicedStatusEnum } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Tooltip } from '@/modules/shared/components'
import { StatusMapping } from '@/modules/shared/types'

interface PurchaseOrderLineItemInvoicedStatusProps {
  status: PurchaseOrderLineItemInvoicedStatusEnum
}

export default function PurchaseOrderLineItemInvoicedStatus(props: PurchaseOrderLineItemInvoicedStatusProps) {
  const { t } = useTranslation()
  const status: StatusMapping<PurchaseOrderLineItemInvoicedStatusEnum> = {
    [PurchaseOrderLineItemInvoicedStatusEnum.FullyInvoiced]: {
      content: t('general.invoiced', 'Invoiced'),
      tooltip: t(
        'purchaseOrders.allPurchaseOrders.table.lineInvoicedStatus.tooltips.invoiced',
        'All of the ordered items have been Invoiced.'
      ),
      customStyles: 'bg-success text-white',
    },
    [PurchaseOrderLineItemInvoicedStatusEnum.PartlyInvoiced]: {
      content: t('general.partlyInvoiced', 'Partly Invoiced'),
      tooltip: t(
        'purchaseOrders.allPurchaseOrders.table.lineInvoicedStatus.tooltips.partlyInvoiced',
        'Some of the ordered items have been Invoiced.'
      ),
      customStyles: 'bg-[#EEB020] text-white',
    },
    [PurchaseOrderLineItemInvoicedStatusEnum.NotInvoiced]: {
      content: t('general.notInvoiced', 'Not Invoiced'),
      tooltip: t(
        'purchaseOrders.allPurchaseOrders.table.lineInvoicedStatus.tooltips.notInvoiced',
        'None of the ordered items have been Invoiced.'
      ),
      customStyles: 'bg-gray-200',
    },
  }
  const { tooltip, content, customStyles } = status[props.status]

  return (
    <Tooltip content={tooltip} showArrow={false}>
      <span className={classNames('rounded-full px-3 py-1.5 text-xs', customStyles)}>{content}</span>
    </Tooltip>
  )
}
