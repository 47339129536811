import { Helmet } from 'react-helmet-async'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParam } from 'use-query-params'

import { useCreateBuyList } from '@/modules/buy-lists/hooks'
import { BuyListFormInputs, BuyListTypeKey } from '@/modules/buy-lists/types'
import { CatalogueTypes } from '@/modules/catalogs/types'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks'
import { DeptAndAccount } from '@/modules/requisitions/pages/create/form-elements'
import CreateFooter from '@/modules/shared/components/create-form/CreateFooter'
import CreateTypeSelection from '@/modules/shared/components/create-form/CreateTypeSelection'
import CreateTypeSelectionRadioOption from '@/modules/shared/components/create-form/CreateTypeSelectionRadioOption'
import FormInput from '@/modules/shared/components/create-form/FormInput'
import { BuyListIcon, InventoryIcon } from '@/modules/shared/icons'

export default function CreateBuyList() {
  const { t } = useTranslation()
  const [selectedTypeURLParam, setSelectedTypeURLParam] = useQueryParam('type', StringParam)
  const formMethods = useForm<BuyListFormInputs>()
  const { currentPurchaser } = useCurrentPurchaser()

  const { createBuyList, loading } = useCreateBuyList()

  const onSubmit: SubmitHandler<BuyListFormInputs> = (data) => {
    const { title, departmentId, accountId } = data
    createBuyList({
      variables: {
        input: {
          title,
          departmentId,
          accountId,
          catalogueType: CatalogueTypes.Product,
          ownerId: currentPurchaser?.id,
          ownerType: 'Organisation',
        },
      },
    })
  }

  const onSelectedBuyListType = (key: string) => {
    setSelectedTypeURLParam(key)
  }

  return (
    <>
      <Helmet>
        <title>{t('createBuyList.pageTitle', 'Create a New Buy List')}</title>
      </Helmet>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} className="mb-80">
        <div className="mx-auto my-20 w-full p-4 md:w-[50rem]">
          <h1 className="text-2xl font-semibold">{t('createBuyList.pageTitle', 'Create a New Buy List')}</h1>
          <FormProvider {...formMethods}>
            <FormInput
              title={t('general.buyListName', 'Buy List Name')}
              description={t('createBuyList.reference.explain', 'Please provide a name for the Buy List.')}
              placeholder={t('general.buyListName', 'Buy List Name')}
              required
              requiredError={t('createBuyList.title.requiredError', 'You must provide a name for the Buy List.')}
              register="title"
            />
            <CreateTypeSelection
              title={t('general.buyListType', 'Buy List Type')}
              description={t('createBuyList.type.explain', "Choose the type of Buy List that you'd like to create.")}
              selectedTypeURLParam={String(selectedTypeURLParam)}
            >
              <CreateTypeSelectionRadioOption
                onSelectedType={onSelectedBuyListType}
                type={BuyListTypeKey.Requisition}
                name={t('general.requisitionBuyList', 'Requisition Buy List')}
                desc={t('createBuyList.types.requisitionExplain', 'Used to order items from your Suppliers')}
                icon={<BuyListIcon className="h-10 w-10" />}
                isReleased
              />
              <CreateTypeSelectionRadioOption
                onSelectedType={onSelectedBuyListType}
                type={BuyListTypeKey.Inventory}
                name={t('general.inventoryBuyList', 'Inventory Buy List')}
                desc={t('createBuyList.types.inventoryExplain', 'Used to order items from an Inventory Stock Location')}
                icon={<InventoryIcon className="h-10 w-10" />}
                isReleased={false}
              />
            </CreateTypeSelection>
            {selectedTypeURLParam === BuyListTypeKey.Requisition && (
              <DeptAndAccount
                title={t('createBuyList.deptAndAccount.title', 'Department and Account Code')}
                description={t(
                  'createBuyList.deptAndAccount.description',
                  'If applicable, select a Department then an Account code to set for this Buy List. When a Requisition is created using this Buy List, the Requisition will be set to this Department and Account Code by default. Your organisation may use Department and Account Codes for recording Requisition expenses.'
                )}
                required={false}
              />
            )}
          </FormProvider>
          <CreateFooter
            getHelpText={t('createBuyList.actions.help', 'Get Help with Buy Lists')}
            createButtonText={t('general.createBuyList', 'Create Buy List')}
            disabled={!selectedTypeURLParam}
            createLoading={loading}
          />
        </div>
      </form>
    </>
  )
}
