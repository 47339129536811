import { graphql } from 'msw'

import { HaltInvoiceMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseHaltInvoice: HaltInvoiceMutation = {
  haltInvoice: {
    invoice: {
      id: 7524140,
      state: 'reconciled',
      __typename: 'Invoice',
    },
    __typename: 'HaltInvoiceMutationPayload',
  },
}

export const HaltInvoice = graphql.mutation<HaltInvoiceMutation>('HaltInvoice', (_, res, ctx) => {
  return res(ctx.data(baseHaltInvoice))
})

export default baseHaltInvoice
