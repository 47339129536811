import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AdvancedModal from '../table-view/AdvancedModal'

import NoImage from '@/assets/images/no-image.svg'
import { ReceivingDocumentLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useUpdateReceivingDocumentLine } from '@/modules/receiving-notes/hooks'
import { ReceivingNoteState } from '@/modules/receiving-notes/types'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Button, Fallback, Stepper, TableLineItem } from '@/modules/shared/components'
import { ReceivingDocumentLineExtraLines } from '@/modules/shared/components/table/table-column-items'
import { useMoney } from '@/modules/shared/hooks'
import { SettingsIcon } from '@/modules/shared/icons'
import { formatNumber } from '@/modules/shared/utils'

interface TileViewCardItemProps {
  receivingDocumentLineItem: ReceivingDocumentLineItem
  lineIndex: number
  isReadOnly: boolean
}

export default function TileViewCardItem({ receivingDocumentLineItem, lineIndex, isReadOnly }: TileViewCardItemProps) {
  const {
    id: lineId,
    product,
    purchaseOrderLineItem,
    quantity,
    comment,
    receivingDocument,
    unitPrice,
    totalValue,
    receivedQuantity,
    expDate,
    temperature,
    vehicleTemperature,
    overrideUnitValue,
  } = receivingDocumentLineItem
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [showAdvancedModal, setShowAdvancedModal] = useState(false)

  const [updateReceivingDocumentLine, { loading }] = useUpdateReceivingDocumentLine()

  const goodReceivingDocIsReceived = receivingDocument?.workflowState === ReceivingNoteState.Received
  const yetToReceive = (purchaseOrderLineItem?.quantity || 0) - (receivedQuantity || 0)
  const hasExtraLine = comment || overrideUnitValue || expDate || vehicleTemperature || temperature

  const onUpdateReceivingDocumentLineQty = (qty: number) => {
    updateReceivingDocumentLine({
      variables: {
        input: {
          id: lineId,
          productId: product?.id,
          quantity: qty,
          receivingDocumentId: receivingDocument?.id,
        },
      },
    })
  }

  const renderAction = () => {
    if ((receivedQuantity || 0) >= (purchaseOrderLineItem?.quantity || 0)) {
      return (
        <Button
          disabled={receivingDocument?.workflowState !== ReceivingNoteState.Open}
          data-testid="already-received-btn"
          className="w-[9.25rem] rounded-full bg-success py-2.5 text-sm text-white"
        >
          {t('general.alreadyReceived', 'Already Received')}
        </Button>
      )
    }

    if (quantity === 0) {
      return (
        <Button
          disabled={receivingDocument?.workflowState !== ReceivingNoteState.Open}
          data-testid="receive-items-btn"
          className="w-[9.25rem] rounded-full bg-gray-200 py-2.5 text-sm"
          loading={loading}
          onClick={() => onUpdateReceivingDocumentLineQty(1)}
        >
          {t('general.receiveItems', 'Receive Items')}
        </Button>
      )
    }

    if ((quantity || 0) > 0) {
      return (
        <Stepper
          loading={loading}
          disabled={receivingDocument?.workflowState !== ReceivingNoteState.Open}
          value={quantity || 0}
          stepperAriaLabel={t('general.receiveItems', 'Receive Items')}
          onChange={(newQty) => onUpdateReceivingDocumentLineQty(newQty)}
        />
      )
    }
  }
  return (
    <div className="rounded-md bg-white" data-testid="receiving-note-line-items-tile">
      <section className=" flex justify-between gap-x-4 p-4">
        <div className="flex items-center gap-x-4">
          <img className="h-24 w-24 flex-none border" src={purchaseOrderLineItem?.image || NoImage} alt="product" />
          <span>
            <p className="text-sm text-primary">
              {product?.brand} {product?.itemDescription}
            </p>
            <p className="text-xs text-gray-500">
              {formatProductItem({
                itemSize: product?.itemSize,
                itemMeasure: product?.itemMeasure,
                itemPackName: product?.itemPackName,
                itemSellUnit: product?.concatenatedSellUnit,
              })}
            </p>
            <strong>{formatMoney(purchaseOrderLineItem?.unitPrice)}</strong>
            <p className="text-xs text-gray-500">{t('general.unitPrice', 'Unit Price')}</p>
          </span>
        </div>
        <Button
          className={classNames('flex h-10 w-10 shrink-0 items-center justify-center rounded-full text-gray-600', {
            'bg-gray-200': !hasExtraLine,
            'bg-primary/10 text-primary hover:bg-primary/20': hasExtraLine,
          })}
          data-testid="show-advanced-modal-btn"
          onClick={() => setShowAdvancedModal(true)}
        >
          <SettingsIcon className="h-6 w-6" />
        </Button>
      </section>
      <hr />
      <section className="p-4">
        <div className="border" data-testid="order-summary">
          <TableLineItem
            title={t('general.orderedQty', 'Ordered Qty')}
            tooltip={t('receivingNotes.orderedQtyTooltip', 'The quantity on the Purchase Order.')}
            value={purchaseOrderLineItem?.quantity || 0}
          />
          {!goodReceivingDocIsReceived && (
            <TableLineItem
              title={t('general.alreadyReceived', 'Already Received')}
              tooltip={t(
                'receivingNotes.alreadyReceivedTooltip',
                'The quantity that has been already been received on one or more Receiving Notes.'
              )}
              value={receivedQuantity || 0}
            />
          )}
          {!goodReceivingDocIsReceived && (
            <TableLineItem
              title={t('general.yetToReceive', 'Yet To Receive')}
              tooltip={t(
                'receivingNotes.yetToReceiveTooltip',
                'The quantity still outstanding and yet to be received.'
              )}
              value={
                <Fallback condition={yetToReceive >= 0} fallbackValue="0">
                  {formatNumber(yetToReceive, 3)}
                </Fallback>
              }
            />
          )}
          <TableLineItem
            title={t('general.unitPrice', 'Unit Price')}
            tooltip={t('receivingNotes.unitPriceTooltip', 'The tax exclusive Unit Price on the Purchase Order.')}
            value={formatMoney(unitPrice)}
          />
          <TableLineItem
            title={t('general.receivingTotal', 'Receiving Total')}
            tooltip={t(
              'receivingNotes.receivingTotalTooltip',
              'This is the Unit Price multiplied by the quantity received on this Receiving Note.'
            )}
            value={formatMoney(totalValue)}
          />
        </div>
      </section>

      <div className={classNames({ 'space-y-3 border-t px-4 py-3': hasExtraLine })}>
        <ReceivingDocumentLineExtraLines
          receivingDocumentLineItem={receivingDocumentLineItem}
          lineIndex={lineIndex}
          isReadOnly={isReadOnly}
        />
      </div>
      <hr />
      <section className="flex items-center justify-between p-4">
        <p className="text-sm">{t('general.receivingQuantity')}</p>

        {goodReceivingDocIsReceived ? <div className="bg-gray-100 px-6 py-2">{quantity || 0}</div> : renderAction()}
      </section>
      <AdvancedModal
        setShowModal={setShowAdvancedModal}
        showModal={showAdvancedModal}
        receivingDocumentLineItem={receivingDocumentLineItem}
      />
    </div>
  )
}
