import { graphql } from 'msw'

import { SubmitRequisitionMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseSubmitRequisition: SubmitRequisitionMutation = {
  updateRequisition: {
    requisition: {
      id: 5235941,
      reference: 'reference',
      sendToSupplier: true,
      expectedDeliveryDate: '2022-07-12',
      nextApprovingAgentsNames: '',
      approvalWorkflow: {
        id: 1,
        title: 'Default',
        __typename: 'RequisitionApprovalWorkflow',
      },
      deliveryAddress: {
        id: 1942,
        locationName: '',
        line1: 'MarketBoomer',
        line2: '343',
        city: 'Sydney',
        stateProvince: 'NSW',
        country: 'AU',
        __typename: 'DeliveryAddress',
      },
      department: {
        id: 1,
        name: 'Default',
        __typename: 'Purchaser',
      },
      account: {
        id: 1,
        accountName: 'Default',
        __typename: 'Account',
      },
      __typename: 'Requisition',
    },
  },
  submitRequisition: {
    requisition: {
      id: 5235941,
      state: 'awaiting_approval',
      requisitionNumber: 'PR00040',
      nextApprovingAgentsNames: '',
      awaitingMyApproval: true,
      purchaseOrders: {
        edges: [
          {
            node: {
              id: 3929,
              sentDate: null,
              purchaseOrderNumber: 'PO22932',
              totalValue: 40.23,
              status: 'Not Sent',
              supplier: {
                name: 'Supplier 1',
                id: 48289,
                __typename: 'Supplier',
              },
              __typename: 'PurchaseOrder',
            },
          },
          {
            node: {
              id: 3123,
              sentDate: '2022-11-04T16:22:34+11:00',
              purchaseOrderNumber: 'PO543555',
              totalValue: 82.12,
              status: 'Sent',
              supplier: {
                name: 'Supplier 2',
                id: 31233,
                __typename: 'Supplier',
              },
              __typename: 'PurchaseOrder',
            },
          },
          {
            node: {
              id: 5342,
              sentDate: null,
              purchaseOrderNumber: 'PO29992',
              totalValue: 12.11,
              status: 'Not Sent',
              supplier: {
                name: 'Supplier 3',
                id: 53331,
                __typename: 'Supplier',
              },
              __typename: 'PurchaseOrder',
            },
          },
        ],
        __typename: 'PurchaseOrderConnection',
      },
      __typename: 'Requisition',
    },
    __typename: 'SubmitRequisitionMutationPayload',
  },
}

export const SubmitRequisition = graphql.mutation<SubmitRequisitionMutation>('SubmitRequisition', (_, res, ctx) => {
  return res(ctx.data(baseSubmitRequisition))
})

export default baseSubmitRequisition
