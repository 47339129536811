import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Outlet, generatePath, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'

import { GetSupplierDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Breadcrumb, QueryResult, ThemeWrapper } from '@/modules/shared/components'
import HeaderTabs, { TabItem } from '@/modules/shared/components/header-tabs'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { HomeIcon, TruckIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'
import { findActiveRoute } from '@/modules/shared/utils'

export default function SupplierPublicPageHeader() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { supplierId } = useParams<{ supplierId: string }>()

  const location = useLocation()

  const { data, loading } = useQuery(GetSupplierDocument, {
    variables: {
      supplierId: Number(supplierId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const supplier = data?.supplier
  const actionList: TabItem[] = [
    { target: 'supplier-details', label: t('general.supplierDetails', 'Supplier Details') },
    { target: 'products', label: t('general.products', 'Products') },
  ]

  const isRedirect = matchPath('/supplier-directory/:supplierId', location.pathname)

  useEffect(() => {
    if (isRedirect) {
      navigate(generatePath('/supplier-directory/:supplierId/supplier-details', { supplierId: String(supplierId) }))
    }
  }, [])

  const renderTitle = () => {
    const targetArray = location.pathname.split('/')
    if (targetArray[targetArray.length - 1] === 'supplier-details') {
      return t('general.details', 'Details')
    }
    return findActiveRoute(actionList, location.pathname)
  }

  return (
    <>
      <Helmet>
        <title>{`${supplier?.name} ${renderTitle()}`}</title>
      </Helmet>
      <QueryResult loading={loading}>
        <div className="mx-auto flex max-w-[112.5rem] flex-col gap-y-5 px-4" data-testid="supplier-public-page">
          <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2" data-testid="supplier-public-breadcrumb">
            <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
            <Breadcrumb.Item href="/supplier-directory">
              {t('general.supplierDirectory', 'Supplier Directory')}
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#">{supplier?.name}</Breadcrumb.Item>
            <Breadcrumb.Item>{findActiveRoute(actionList, location.pathname)}</Breadcrumb.Item>
          </Breadcrumb>
          <section className="flex flex-col gap-y-2">
            <section
              className="flex items-center justify-between gap-y-3 rounded-md bg-white p-4 shadow"
              data-testid="supplier-public-header"
            >
              <div className="flex items-center gap-4">
                <ThemeWrapper theme={Themes.Primary} className="flex items-center justify-center rounded-md p-2">
                  <TruckIcon className="h-10 w-10 text-primary" />
                </ThemeWrapper>
                <div className="flex flex-col">
                  <p className="font-bold">{supplier?.name}</p>
                </div>
              </div>
            </section>
            <HeaderTabs actionList={actionList} />
          </section>
          <Outlet />
        </div>
      </QueryResult>
    </>
  )
}
