export default function DownloadIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M16.43,16.08H3.56a.93.93,0,1,0,0,1.85H16.44a.93.93,0,1,0,0-1.85Z" fill="currentColor" />
      <path
        d="M9.32,14.15a.91.91,0,0,0,1.35,0l4.58-4.92a.88.88,0,0,0,.25-.66.86.86,0,0,0-.29-.64A.93.93,0,0,0,13.9,8l-3,3.19V3A.93.93,0,1,0,9.07,3v8.16L6.1,8A.93.93,0,0,0,4.74,9.23Z"
        fill="currentColor"
      />
    </svg>
  )
}
