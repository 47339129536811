import { PurchaseOrderStatuses } from '../types'

// TODO: Temp solution till BE work
export const statusText = (status: string | null | undefined, sentDate: string) => {
  if (status !== PurchaseOrderStatuses.Cancelled && status !== PurchaseOrderStatuses.Closed) {
    if (sentDate) {
      return PurchaseOrderStatuses.Sent
    } else {
      return PurchaseOrderStatuses.NotSent
    }
  } else {
    return status
  }
}
