import { Dispatch, SetStateAction } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { useCreateInvoiceLineItem } from '@/modules/invoices/hooks'
import { UpdateInvoiceLineFormInputs } from '@/modules/invoices/types'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks'
import { Button, Modal, ProductSummary } from '@/modules/shared/components'
import NumberInput from '@/modules/shared/components/number-input'
import { ProductType } from '@/modules/shared/types'

export interface AddInvoiceLineModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  line: ProductType
  invoiceId: number
}

export default function AddInvoiceLineModal({ showModal, setShowModal, line, invoiceId }: AddInvoiceLineModalProps) {
  const { t } = useTranslation()

  const { product } = line
  const { currentPurchaser } = useCurrentPurchaser()
  const [createInvoiceLineItem, { loading }] = useCreateInvoiceLineItem()
  const navigate = useNavigate()

  const formMethods = useForm<UpdateInvoiceLineFormInputs>({
    defaultValues: { taxPercentage: 0 },
  })

  const errorQuantity =
    formMethods.formState.errors.quantity && formMethods.formState.errors.quantity.type === 'required'
  const errorUnitPrice =
    formMethods.formState.errors.invoicePrice && formMethods.formState.errors.invoicePrice.type === 'required'

  const onCloseModal = () => {
    setShowModal(false)
    if (formMethods.formState.errors) formMethods.reset()
  }

  const onSubmit: SubmitHandler<UpdateInvoiceLineFormInputs> = (data) => {
    createInvoiceLineItem({
      variables: {
        input: { invoiceId, productId: line?.product?.id, description: line?.product?.itemDescription, ...data },
      },
      onCompleted: () => {
        navigate(generatePath('/invoices/:invoiceId/summary', { invoiceId: String(invoiceId) }))
      },
    })
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel className="flex w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]">
        <Modal.Title title={t('general.addInvoiceLine', 'Add Invoice Line')} onCloseModal={onCloseModal} />

        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Modal.Body>
            <p className="font-bold">{t('invoices.addLine.modal.title', 'Add Product as Invoice Line')}</p>
            <p className="mb-2 text-gray-500">
              {t(
                'invoices.addLine.modal.description',
                'You can add the product below as a new line to your Invoice. Please add the Quantity, Unit Price and Tax Percentage and then add the Invoice Line and you will be return to the Invoice.'
              )}
            </p>
            <ProductSummary product={product} showPriceAndTaxPercentage={false} />
            <div className="space-y-4 text-sm">
              <div>
                <p className="font-bold">{t('general.quantity', 'Quantity')}</p>
                <p className="text-gray-500">
                  {t(
                    'invoices.addLine.modal.quantityDescription',
                    'Set the quantity for this product on the Invoice line.'
                  )}
                </p>
                <Controller
                  control={formMethods.control}
                  name="quantity"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <NumberInput
                      className="mt-2 h-12 w-full rounded-md border text-sm shadow-sm"
                      leftUnit={t('general.qty', 'QTY')}
                      data-testid="qty-input"
                      aria-label={t('general.quantity', 'Quantity')}
                      hasError={errorQuantity}
                      errorMessage={t('invoices.addLine.modal.quantityRequiredMsg', 'You must provide a Quantity.')}
                      defaultValue={0}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </div>
              <div>
                <p className="font-bold">{t('general.unitPrice', 'Unit Price')}</p>
                <p className="text-gray-500">
                  {t(
                    'invoices.addLine.modal.unitPriceDescription',
                    'Set the price for this product on Invoice line. The price is exclusive of tax, and the tax percentage to be added to the price is managed in the field below.'
                  )}
                </p>
                <Controller
                  control={formMethods.control}
                  name="invoicePrice"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <NumberInput
                      className="mt-2 h-12 w-full rounded-md border text-sm shadow-sm"
                      leftUnit={currentPurchaser?.currency}
                      data-testid="price-input"
                      aria-label={t('general.unitPrice', 'Unit Price')}
                      hasError={errorUnitPrice}
                      errorMessage={t('invoices.addLine.modal.unitPriceRequiredMsg', 'You must provide a Unit Price.')}
                      formatOptions={{
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      }}
                      defaultValue={0}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </div>
              <div>
                <p className="font-bold">{t('general.taxPercentage', 'Tax Percentage')}</p>
                <p className="text-gray-500">
                  {t(
                    'invoices.addLine.modal.taxPercentageDescription',
                    'Set the tax percentage for this product on the Invoice line. The tax percentage is the amount that will be added to the tax exclusive unit price above.'
                  )}
                </p>
                <Controller
                  control={formMethods.control}
                  name="taxPercentage"
                  render={({ field }) => (
                    <NumberInput
                      className="mt-2 h-12 w-full rounded-md border text-sm shadow-sm"
                      leftUnit={t('general.taxWithPercentage', 'Tax %')}
                      data-testid="tax-input"
                      aria-label={t('general.taxPercentage', 'Tax Percentage')}
                      defaultValue={0}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
            <Button
              className="h-11 w-full rounded-md bg-gray-200 px-5 text-sm md:w-fit"
              onClick={onCloseModal}
              type="button"
              data-testid="cancel-button"
            >
              {t('general.cancel', 'Cancel')}
            </Button>
            <Button
              className="h-11 w-full rounded-md bg-primary px-5 text-sm text-white md:w-fit"
              type="submit"
              loading={loading}
              data-testid="submit-button"
            >
              {t('general.addInvoiceLine', 'Add Invoice Line')}
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Panel>
    </Modal>
  )
}
