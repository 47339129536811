import { Dispatch, SetStateAction } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ReceivingDocumentLineItemInput } from '@/modules/purchase-orders/types'
import { Input } from '@/modules/shared/components'

interface ProductTemperatureProps {
  temperature: string
  isReadOnly: boolean
  setInputFocus: Dispatch<SetStateAction<boolean>>
}

export default function ProductTemperature({ temperature, setInputFocus, isReadOnly }: ProductTemperatureProps) {
  const { t } = useTranslation()
  const { control } = useFormContext<ReceivingDocumentLineItemInput>()
  return (
    <section>
      <strong className="text-sm">{t('general.productTemperature', 'Product Temperature')}</strong>
      <p className="text-sm text-gray-500">
        {t(
          'receivingNotes.advancedModal.productTemperatureDesc',
          'If required, use the field below to record the temperature of this product at the time it was received.'
        )}
      </p>
      <div className="mt-4">
        <Controller
          control={control}
          name="temperature"
          defaultValue={temperature || ''}
          render={({ field }) => (
            <Input
              isDisabled={isReadOnly}
              data-testid="product-temperature-input"
              className="w-full rounded-md border border-gray-200 p-3 text-sm focus:ring-primary"
              placeholder={t('general.productTemperature', 'Product Temperature')}
              aria-label={t('general.productTemperature', 'Product Temperature')}
              value={field.value}
              onFocus={() => setInputFocus(true)}
              onChange={(e) => field.onChange(e)}
            />
          )}
        />
      </div>
    </section>
  )
}
