import classNames from 'classnames'
import { useRef } from 'react'
import { AriaButtonProps, useButton } from 'react-aria'

interface FilterButtonProps {
  className?: string
  isActive?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

function FilterButton(props: AriaButtonProps & FilterButtonProps) {
  let ref = useRef(null)
  let { buttonProps } = useButton(props, ref)
  let { children } = props

  return (
    <button
      {...buttonProps}
      ref={ref}
      className={classNames(props.className, {
        'border-primary bg-primary/10 text-primary': props.isActive,
        'cursor-not-allowed opacity-60': props.disabled,
      })}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {children}
    </button>
  )
}

export default FilterButton
