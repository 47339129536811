import classNames from 'classnames'
import { useState } from 'react'

import RequisitionStatusModal from '../../components/requisition-status-modal'

import { REQUISITION_STATUS } from '@/modules/requisitions/types'
import { formatStateForLocale } from '@/modules/requisitions/utils'

interface RequisitionStatusProps {
  state: string
  requisitionId?: number
}

export default function RequisitionStatus(props: RequisitionStatusProps) {
  const { state, requisitionId } = props
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <span
        className={classNames('inline-flex cursor-pointer rounded-full px-3 py-1.5 text-center text-xs', {
          'bg-[#1877F2] text-white': state === REQUISITION_STATUS.OPEN,
          'bg-[#EEB020] text-white': state === REQUISITION_STATUS.AWAITING_APPROVAL,
          'bg-success text-white': state === REQUISITION_STATUS.APPROVED,
          'bg-error text-white': state === REQUISITION_STATUS.DECLINED,
          'bg-gray-200': state === REQUISITION_STATUS.CLOSED,
          'bg-gray-600 text-white': state === REQUISITION_STATUS.CANCELLED,
        })}
        onClick={() => setShowModal(true)}
        data-testid="requisition-status-btn"
      >
        {formatStateForLocale(state)}
      </span>
      {requisitionId && state !== REQUISITION_STATUS.CLOSED && (
        <RequisitionStatusModal
          showModal={showModal && state !== REQUISITION_STATUS.CLOSED}
          setShowModal={setShowModal}
          requisitionId={requisitionId}
          state={state}
        />
      )}
    </>
  )
}
