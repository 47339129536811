import { graphql } from 'msw'

import { GetPoReceivingNoteQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetPOReceivingNote: GetPoReceivingNoteQuery = {
  currentPurchaser: {
    id: 44926,
    purchaseOrder: {
      id: 16566710,
      totalValueExcludingTax: 25,
      purchaseOrderNumber: 'PO04331',
      supplier: {
        id: 121239,
        name: '100 Proof',
        __typename: 'Supplier',
      },
      receivingDocument: {
        id: 5854902,
        workflowState: 'open',
        receivingDocumentNumber: 'GR02404',
        destination: null,
        receivingTotalValue: 25,
        transfer: null,
        receiver: {
          id: 18544,
          fullName: 'Sunny H Sun',
          __typename: 'User',
        },
        receivedDate: '2023-03-22T10:50:58+11:00',
        receivingDocumentLineItems: {
          edges: [
            {
              node: {
                id: 22273758,
                purchaseOrderLineItem: {
                  id: 16566710,
                  quantity: 1,
                  unitPrice: 12,
                  invoiceLineItems: {
                    edges: [
                      {
                        node: {
                          id: 24587461,
                          quantity: 0.5,
                          unitPrice: '12.8',
                          taxPercentage: 10,
                          createdAt: '2023-05-16T10:48:59+10:00',
                          invoice: {
                            id: 7524316,
                            invoiceNumber: 'INPO050123',
                            __typename: 'Invoice',
                          },
                          __typename: 'InvoiceLineItem',
                        },
                        __typename: 'InvoiceLineItemEdge',
                      },
                    ],
                    __typename: 'InvoiceLineItemConnection',
                  },
                  image: null,
                  __typename: 'PurchaseOrderLineItem',
                },
                receivingDocument: {
                  id: 5854902,
                  workflowState: 'open',
                  __typename: 'ReceivingDocument',
                },
                quantity: 1,
                receivedQuantity: 0,
                comment: null,
                unitPrice: 12,
                totalValue: 12,
                overrideUnitValue: null,
                expDate: null,
                temperature: null,
                vehicleTemperature: null,
                product: {
                  id: 1541245,
                  brand: 'Mighty Soft',
                  itemDescription: 'Bread : Raisin Toast',
                  concatenatedDescription: 'Bread : Raisin Toast 600 g loaf',
                  concatenatedSellUnit: 'each of 1',
                  itemPackName: 'loaf',
                  itemMeasure: 'g',
                  itemSellPackName: 'each',
                  itemSize: 600,
                },
                __typename: 'ReceivingDocumentLineItem',
              },
            },
            {
              node: {
                id: 3263701,
                purchaseOrderLineItem: {
                  id: 16566711,
                  quantity: 1,
                  image: null,
                  invoiceLineItems: {
                    edges: [],
                    __typename: 'InvoiceLineItemConnection',
                  },
                  unitPrice: 15,
                  __typename: 'PurchaseOrderLineItem',
                },
                receivingDocument: {
                  id: 5854902,
                  workflowState: 'open',
                  __typename: 'ReceivingDocument',
                },
                quantity: 1,
                receivedQuantity: 0,
                comment: null,
                unitPrice: 15,
                totalValue: 15,
                overrideUnitValue: null,
                expDate: null,
                temperature: null,
                vehicleTemperature: null,
                product: {
                  id: 62239,
                  brand: 'Herrradura',
                  itemDescription: 'Agave Nectar',
                  concatenatedDescription: 'Agave Nectar 478 ml bottle',
                  concatenatedSellUnit: 'carton of 12',
                  itemPackName: 'bottle',
                  itemMeasure: 'ml',
                  itemSellPackName: 'carton',
                  itemSize: 478,
                },
                __typename: 'ReceivingDocumentLineItem',
              },
            },
          ],
        },
        __typename: 'ReceivingDocument',
      },
      __typename: 'PurchaseOrder',
    },
    __typename: 'Purchaser',
  },
}

export const GetPOReceivingNote = graphql.query<GetPoReceivingNoteQuery>('GetPOReceivingNote', (_, res, ctx) => {
  return res(ctx.data(baseGetPOReceivingNote))
})

export default baseGetPOReceivingNote
