import { graphql } from 'msw'

import { GetRequisitionsApprovedQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

export const GetRequisitionsApproved = graphql.query<GetRequisitionsApprovedQuery>(
  'GetRequisitionsApproved',
  (_, res, ctx) => {
    return res(
      ctx.data({
        currentPurchaser: {
          id: 44926,
          requisitions: {
            edges: [
              {
                node: {
                  id: 5235941,
                  requisitionNumber: 'PR04207',
                  reference: 'reference',
                  __typename: 'Requisition',
                },
                __typename: 'RequisitionEdge',
              },
              {
                node: {
                  id: 31233,
                  requisitionNumber: 'PR04241',
                  reference: 'reference',
                  __typename: 'Requisition',
                },
                __typename: 'RequisitionEdge',
              },
              {
                node: {
                  id: 123456,
                  requisitionNumber: 'PR01203',
                  reference: 'reference',
                  __typename: 'Requisition',
                },
                __typename: 'RequisitionEdge',
              },
            ],
            pageInfo: {
              endCursor: 'MTQ',
              hasNextPage: false,
              __typename: 'PageInfo',
            },
          },
          __typename: 'Purchaser',
        },
      })
    )
  }
)
