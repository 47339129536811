import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Outlet, generatePath, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'

import Details from './Details'

import { GetSupplierRelationshipDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Breadcrumb, Button, QueryResult, ThemeWrapper, Tooltip } from '@/modules/shared/components'
import { MoreOptionsMenu } from '@/modules/shared/components'
import HeaderTabs, { TabItem } from '@/modules/shared/components/header-tabs'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { HomeIcon, InfoIcon, OptionIcon, TruckIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'
import { findActiveRoute } from '@/modules/shared/utils'

export default function SupplierPageHeader() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { supplierId } = useParams<{ supplierId: string }>()
  const [showInfo, setShowInfo] = useState(false)

  const location = useLocation()

  const { data, loading } = useQuery(GetSupplierRelationshipDocument, {
    variables: {
      id: Number(supplierId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const relationship = data?.currentPurchaser?.supplierRelationship
  const supplier = relationship?.supplier
  const actionList: TabItem[] = [
    { target: 'supplier-details', label: t('general.supplierDetails', 'Supplier Details') },
  ]

  const isRedirect = matchPath('/my-suppliers/:supplierId', location.pathname)

  useEffect(() => {
    if (isRedirect) {
      navigate(generatePath('/my-suppliers/:supplierId/supplier-details', { supplierId: String(supplierId) }))
    }
  }, [])

  const renderTitle = () => {
    const targetArray = location.pathname.split('/')
    if (targetArray[targetArray.length - 1] === 'supplier-details') {
      return t('general.details', 'Details')
    }
    return findActiveRoute(actionList, location.pathname)
  }

  return (
    <>
      <Helmet>
        <title>{`${supplier?.name || ''} ${renderTitle()}`}</title>
      </Helmet>
      <QueryResult loading={loading}>
        <div className="mx-auto flex max-w-[112.5rem] flex-col gap-y-5 px-4" data-testid="supplier-page">
          <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2" data-testid="supplier-breadcrumb">
            <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
            <Breadcrumb.Item href="/my-suppliers">{t('general.mySuppliers', 'My Suppliers')}</Breadcrumb.Item>
            <Breadcrumb.Item href="#">{supplier?.name}</Breadcrumb.Item>
            <Breadcrumb.Item>{findActiveRoute(actionList, location.pathname)}</Breadcrumb.Item>
          </Breadcrumb>
          <section className="flex flex-col gap-y-2">
            <section
              className="flex w-full flex-col justify-between rounded-md bg-white shadow sm:flex-row"
              data-testid="supplier-header"
            >
              <div className="flex items-center gap-4 p-4">
                <ThemeWrapper theme={Themes.Primary} className="flex items-center justify-center rounded-md p-2">
                  <TruckIcon className="h-10 w-10 text-primary" />
                </ThemeWrapper>
                <div className="flex flex-col">
                  <p className="font-bold">{supplier?.name}</p>
                  <p className="text-sm">{relationship?.supplierNumber}</p>
                </div>
              </div>
              <hr className="sm:hidden" />
              <div className="flex items-center justify-between p-4 sm:justify-center">
                <p className="text-sm text-gray-500 sm:hidden">{t('general.actions')}</p>
                <div className="flex gap-x-2">
                  <Tooltip content={t('general.showOrHideDetails', 'Show or hide details')} showArrow={false}>
                    <Button
                      data-testid="show-details-button"
                      className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                      onClick={() => setShowInfo(!showInfo)}
                    >
                      <InfoIcon className="h-8 w-8 text-gray-600" />
                    </Button>
                  </Tooltip>
                  <MoreOptionsMenu showHelp showOpenInPPlus supplierId={supplierId} buttonTheme="dark">
                    <OptionIcon className="h-8 w-8" />
                  </MoreOptionsMenu>
                </div>
              </div>
            </section>
            {showInfo && <Details />}
            <HeaderTabs actionList={actionList} />
          </section>
          <div>
            <Outlet />
          </div>
        </div>
      </QueryResult>
    </>
  )
}
