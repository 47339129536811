import ProductEmptyStateImg from '@/assets/images/product-empty-state.svg'

interface ProductListEmptyStateProps {
  message: string
  image?: string
}

export default function ProductListEmptyState({ message, image }: ProductListEmptyStateProps) {
  return (
    <div className="mb-3 flex flex-col items-center justify-center">
      <img src={image ?? ProductEmptyStateImg} className="h-64 md:h-72" alt="" />
      <div className="text-center">
        <p className="text-xs text-gray-500 md:text-sm">{message}</p>
      </div>
    </div>
  )
}
