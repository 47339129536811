import { useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import TableView from './table-view'
import TileView from './tile-view'

import EmptyStateImg from '@/assets/images/empty-suppliers.svg'
import { GetAllSuppliersDocument, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Spinner } from '@/modules/requisitions/components'
import { Breadcrumb, QueryResult } from '@/modules/shared/components'
import { MoreOptionsMenu } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import SearchAndFilter, { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { HomeIcon, OptionIcon, TruckIcon } from '@/modules/shared/icons'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export default function SupplierDirectory() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()

  const { data, error, fetchMore, refetch, networkStatus } = useQuery(GetAllSuppliersDocument, {
    variables: {
      first: 25,
      after: null,
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
  })

  const onFilter = ({ searchValue }: OnFilter) => {
    refetch({ searchText: searchValue })
  }

  const onFetchMoreSuppliers = () => {
    fetchMore({
      variables: {
        after: data?.suppliers?.pageInfo?.endCursor,
      },
    })
  }

  const suppliers = extractEdges<Supplier>(data?.suppliers)

  const { setVariablesLoading, refetchLoading, loading } = checkNetworkStatus(networkStatus)

  return (
    <>
      <Helmet>
        <title>{t('general.supplierDirectory', 'Supplier Directory')}</title>
      </Helmet>
      <div className="mx-auto space-y-5 px-4" data-testid="all-suppliers">
        <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="#">{t('general.supplierDirectory', 'Supplier Directory')}</Breadcrumb.Item>
          <Breadcrumb.Item href="#">{t('general.viewAll', 'View All')}</Breadcrumb.Item>
        </Breadcrumb>
        <section
          className="flex w-full flex-col justify-between rounded-md bg-white shadow sm:flex-row"
          data-testid="all-suppliers-header"
        >
          <div className="flex items-center p-4">
            <div className="flex h-14 w-14 items-center justify-center rounded-md bg-primary/10">
              <TruckIcon className="h-10 w-10 text-primary" />
            </div>
            <div className="ml-3">
              <p className="font-bold">{t('general.supplierDirectory', 'Supplier Directory')}</p>
              <p className="text-sm text-gray-500">
                {t('supplierDirectory.subtitle', 'Connect with Registered Suppliers')}
              </p>
            </div>
          </div>
          <hr className="sm:hidden" />
          <div className="flex items-center justify-between p-4 sm:justify-center">
            <p className="text-sm text-gray-500 sm:hidden">{t('general.actions')}</p>
            <MoreOptionsMenu showHelp supplier showOpenInPPlus buttonTheme="dark">
              <OptionIcon className="h-8 w-8" />
            </MoreOptionsMenu>
          </div>
        </section>
        <SearchAndFilter
          testId="supplier-search-input"
          placeholder={t('supplierDirectory.searchPlaceholder', 'Search by Supplier Name')}
          ariaLabel={t('supplierDirectory.searchPlaceholder', 'Search by Supplier Name')}
          onFilter={onFilter}
        />
        <QueryResult loading={refetchLoading || setVariablesLoading || loading} error={error}>
          {data && (
            <>
              {!!suppliers.length && (
                <InfiniteScroll
                  dataLength={suppliers.length}
                  next={onFetchMoreSuppliers}
                  hasMore={!!data?.suppliers?.pageInfo?.hasNextPage}
                  loader={<Spinner className="mt-5 h-14 md:w-16" />}
                >
                  {isLargeScreen ? <TableView suppliers={suppliers} /> : <TileView suppliers={suppliers} />}
                </InfiniteScroll>
              )}
              {!setVariablesLoading && suppliers.length === 0 && (
                <EmptyState
                  img={EmptyStateImg}
                  message={t('mySuppliers.noSuppliers', "We couldn't find any suppliers to display.")}
                />
              )}
            </>
          )}
        </QueryResult>
      </div>
    </>
  )
}
