import { useTranslation } from 'react-i18next'

import SideNavLink from '../SideNavLink'
import { SideBarItem } from './PurchaserNavigation'

import { Accordion } from '@/modules/shared/components'
import { InventoryIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface InventorySideBarItemProps extends SideBarItem {
  inventoryRoutesMatch: boolean
}

export const InventorySideBarItem = ({
  inventoryRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
}: InventorySideBarItemProps) => {
  const { t } = useTranslation()
  return (
    <Accordion
      titlePrefixIcon={<InventoryIcon className="h-7 w-7" />}
      title={t('general.inventory', 'Inventory')}
      activeColor={inventoryRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === 6}
      handleTriggerClick={() => changeOpenAccordionKey(6)}
      buttonClassName="h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="inventory-navigation"
        sideNavLinkItems={[
          {
            key: t('general.stockLocations', 'Stock Locations'),
            target: '/stock-locations',
          },
          {
            key: t('general.stocktakes', 'Stocktakes'),
            target: '/stocktakes',
          },
        ]}
      />
    </Accordion>
  )
}
