import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath, useNavigate } from 'react-router-dom'

import ApproveOrDeclineHeader from '../../requisition/page-header/ApproveOrDeclineHeader'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { REQUISITION_STATUS } from '@/modules/requisitions/types'
import { Button, ConfirmDialog } from '@/modules/shared/components'
import { Themes } from '@/modules/shared/types'

interface CartActionsProps {
  requisition: Requisition
}

export default function CartActions({ requisition }: CartActionsProps) {
  const { id, state, awaitingMyApproval, totalValue } = requisition || {}
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isSaveAndCloseOpen, setIsSaveAndCloseOpen] = useState(false)
  const isCartEmpty = (totalValue || 0) === 0

  return (
    <>
      {(state === REQUISITION_STATUS.OPEN || state === REQUISITION_STATUS.DECLINED) && (
        <section className="flex w-full items-center justify-between gap-x-2 rounded-md bg-white px-4 py-3 text-sm shadow">
          {t('requisitionInformation.submitRequisitionForApproval', 'Submit Requisition For Approval')}
          {!isCartEmpty ? (
            <Link
              className="cursor-pointer rounded-md bg-primary px-4 py-2 text-white"
              to={generatePath(`/requisitions/:id/submit`, {
                id: String(id),
              })}
              data-testid="submit-requisition-btn"
            >
              {t('general.submitRequisition', 'Submit Requisition')}
            </Link>
          ) : (
            <span
              className="cursor-not-allowed rounded-md bg-primary px-4 py-2 text-white opacity-50"
              data-testid="unsubmittable-requisition-button"
            >
              {t('general.submitRequisition', 'Submit Requisition')}
            </span>
          )}
        </section>
      )}
      {state === REQUISITION_STATUS.AWAITING_APPROVAL && (
        <>
          <section className="flex w-full items-center justify-between gap-x-2 rounded-md bg-white px-4 py-3 text-sm shadow">
            {t('requisitionInformation.saveAndCloseDesc', 'You can Save and Close the Requisition')}
            <Button
              className={classNames('cursor-pointer rounded-md bg-primary px-4 py-2 text-white', {
                'cursor-not-allowed opacity-50': isCartEmpty,
              })}
              data-testid="save-and-close-requisition-btn"
              onClick={() => setIsSaveAndCloseOpen(true)}
            >
              {t('general.saveAndClose', 'Save and Close')}
            </Button>
          </section>
          {awaitingMyApproval && <ApproveOrDeclineHeader requisitionId={Number(id)} />}
        </>
      )}
      <ConfirmDialog
        title={t('general.save&Close', 'Save & Close')}
        description={t(
          'access.shop.confirm.saveDescription',
          'By choosing Save and Close you will be returned to the dashboard and the Requisition will be saved in your drafts list. You will be able to resume shopping the Requisition again anytime in the future.'
        )}
        primaryButtonLabel={t('general.saveAndClose', 'Save and Close')}
        secondaryButtonLabel={t('general.continueShopping', 'Continue Shopping')}
        isOpen={isSaveAndCloseOpen}
        setIsOpen={setIsSaveAndCloseOpen}
        onPrimaryBtnClick={() => navigate('/dashboard')}
        theme={Themes.Primary}
      />
    </>
  )
}
