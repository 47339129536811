import { useQuery } from '@apollo/client'
import { SetStateAction } from 'jotai'
import { Dispatch, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'

import { GetRequisitionDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, ConfirmDialog, ThemeWrapper, Tooltip } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useMoney } from '@/modules/shared/hooks'
import { CartIcon, ChevronRightIcon, InfoIcon, SaveIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface CartHeaderProps {
  showInfo?: boolean
  setShowInfo?: Dispatch<SetStateAction<boolean>>
  isShop?: boolean
}

function CartHeader({ showInfo, setShowInfo, isShop }: CartHeaderProps) {
  const { formatMoney } = useMoney()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [isSaveAndCloseOpen, setIsSaveAndCloseOpen] = useState(false)

  const { requisitionId } = useParams<{ requisitionId: string }>()
  const { data } = useQuery(GetRequisitionDocument, {
    variables: {
      requisitionId: Number(requisitionId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const { totalTaxValue, totalValueExcludingTax } = data?.currentPurchaser?.requisition || {}

  return (
    <section className="flex w-full flex-col justify-between rounded-md bg-white shadow sm:flex-row">
      <div className="flex grow justify-between gap-x-2 p-4">
        <div className="flex items-center gap-4">
          <ThemeWrapper theme={Themes.Primary} className="flex h-14 w-14 items-center justify-center rounded-md">
            <CartIcon className="h-10 w-10" />
          </ThemeWrapper>
          <span>
            <p className="font-bold" data-testid="cart-value">
              {formatMoney(totalValueExcludingTax)}
            </p>
            <p className="text-sm text-gray-500">
              {t('requisitionInformation.cart.plusTaxOf', 'Plus Tax of {{ totalTaxValue }}', {
                totalTaxValue: formatMoney(totalTaxValue),
              })}
            </p>
          </span>
        </div>
      </div>
      <hr className="sm:hidden" />
      <div className="flex items-center p-4">
        {isShop ? (
          <Link
            to={`summary${location.search}`}
            className="flex h-11 items-center justify-between gap-1 rounded-md bg-primary px-4 text-sm text-white hover:brightness-95"
          >
            <span>{t('general.viewCart', 'View Cart')}</span>
            <ChevronRightIcon className="h-6 w-6" />
          </Link>
        ) : (
          <div className="flex w-full items-center justify-between sm:justify-end">
            <p className="text-sm text-gray-500 sm:hidden">{t('general.actions', 'Actions')}</p>
            <div className="flex gap-x-2">
              <Tooltip content={t('general.continueShopping', 'Continue Shopping')} showArrow={false}>
                <Link
                  data-testid="continue-shopping-btn"
                  to={generatePath(`/requisitions/:requisitionId/shop/:queryParam`, {
                    requisitionId: String(requisitionId),
                    queryParam: location.search,
                  })}
                  className="flex w-fit rounded-full bg-gray-200 p-1.5 text-sm text-gray-600 hover:brightness-95"
                >
                  <CartIcon className="h-8 w-8" />
                </Link>
              </Tooltip>
              <Tooltip content={t('general.saveAndClose', 'Save and Close')} showArrow={false}>
                <Button
                  data-testid="save-and-close-shopping-btn"
                  className="flex rounded-full bg-gray-200 p-1.5 text-sm text-gray-600 hover:brightness-95"
                  onClick={() => setIsSaveAndCloseOpen(true)}
                >
                  <SaveIcon className="h-8 w-8" />
                </Button>
              </Tooltip>
              <Tooltip content={t('general.infoTooltip', 'Show or hide details')} showArrow={false}>
                <Button
                  data-testid="show-details-button"
                  className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                  onClick={() => setShowInfo && setShowInfo(!showInfo)}
                >
                  <InfoIcon className="h-8 w-8 text-gray-600" />
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
      <ConfirmDialog
        title={t('general.save&Close', 'Save & Close')}
        description={t(
          'access.shop.confirm.saveDescription',
          'By choosing Save and Close you will be returned to the dashboard and the Requisition will be saved in your drafts list. You will be able to resume shopping the Requisition again anytime in the future.'
        )}
        primaryButtonLabel={t('general.saveAndClose', 'Save and Close')}
        secondaryButtonLabel={t('general.continueShopping', 'Continue Shopping')}
        isOpen={isSaveAndCloseOpen}
        setIsOpen={setIsSaveAndCloseOpen}
        onPrimaryBtnClick={() => navigate('/dashboard')}
        theme={Themes.Primary}
        testId="save-and-close-dialog"
      />
    </section>
  )
}

export default CartHeader
