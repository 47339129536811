import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Invoice, ReceivingDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ReceivingNoteStatus } from '@/modules/receiving-notes/components'
import { Modal, PPNewDetailLink } from '@/modules/shared/components'
import ReceiptsModalFooter from '@/modules/shared/components/receipts-modal-footer/ReceiptsModalFooter'
import Table from '@/modules/shared/components/table/Table'
import { NewTabIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

interface InvoiceReceiptsModalProps {
  invoice: Invoice
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
}

export default function InvoiceReceiptsModal({ invoice, showModal, setShowModal }: InvoiceReceiptsModalProps) {
  const { t } = useTranslation()
  const receipts = extractEdges<ReceivingDocument>(invoice.receivingDocuments)
  const purchaseOrderId = invoice.purchaseOrder?.id

  const onCloseModal = () => {
    setShowModal(false)
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal} dataTestId="receipts-modal">
      <Modal.Panel
        as="div"
        className="flex max-h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]"
      >
        <Modal.Title
          title={`${invoice.invoiceNumber} ${t('general.receivingNotes', 'Receiving Notes')}`}
          onCloseModal={onCloseModal}
        />
        <Modal.Body className="space-y-4 text-sm">
          <section>
            <p className="font-bold">{t('general.receivingNotes', 'Receiving Notes')}</p>
            <p className="text-gray-500">
              {t(
                'invoices.details.receiptsModal.receivingNotesDesc',
                'The following Receiving Notes have already been created against this Purchase Order matched to this Invoice. You can create another Receiving Note using the button below.'
              )}
            </p>
            <Table
              dataSource={receipts}
              dataTestId="receiving-notes-table"
              keyExtractor={(record) => String(record.id)}
              tableHeadStyles="border-none"
              columns={[
                {
                  title: t('general.receivingNote', 'Receiving Note'),
                  key: 'receivingNote',
                  headerCellStyles: 'pl-3 pr-4 py-4',
                  cellStyles: 'pl-3 pr-4 py-4',
                  minWidth: 8.75,
                  grow: true,
                  render(record) {
                    return (
                      <PPNewDetailLink
                        testId="receiving-note-number"
                        openNewTab
                        className="flex items-center gap-x-1 text-primary"
                        type="receiving-document"
                        secondaryId={String(purchaseOrderId)}
                        id={String(record.id)}
                      >
                        <span>{record.receivingDocumentNumber}</span>
                        <NewTabIcon className="h-5 w-5" />
                      </PPNewDetailLink>
                    )
                  },
                },
                {
                  title: t('general.status', 'Status'),
                  key: 'status',
                  headerCellStyles: 'py-4 text-center',
                  cellStyles: 'py-4 text-center',
                  minWidth: 7.5,
                  maxWidth: 7.5,
                  render(record) {
                    return <ReceivingNoteStatus state={String(record.workflowState)} />
                  },
                },
              ]}
            />
          </section>
        </Modal.Body>
        <ReceiptsModalFooter onCloseModal={onCloseModal} purchaseOrderId={String(purchaseOrderId)} />
      </Modal.Panel>
    </Modal>
  )
}
