import { useQuery } from '@apollo/client'

import { PURCHASING_GRAPHQL_API } from '../../shared/constants'

import {
  DeliveryAddress,
  GetDeliveryAddressesDocument,
  RansackDirection,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { extractEdges } from '@/modules/shared/utils'

export const useGetDeliveryAddress = () => {
  const { data, refetch, networkStatus, fetchMore, loading } = useQuery(GetDeliveryAddressesDocument, {
    variables: {
      after: null,
      filter: null,
      sort: [
        {
          property: 'locationName',
          direction: RansackDirection.Asc,
        },
      ],
      first: 25,
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
  })

  return {
    deliveryAddresses: extractEdges<DeliveryAddress>(data?.currentPurchaser?.deliveryAddresses),
    defaultDeliveryAddress: data?.currentPurchaser?.defaultDeliveryAddress,
    actions: { refetch, fetchMore },
    pageInfo: data?.currentPurchaser?.deliveryAddresses?.pageInfo,
    networkStatus,
    loading,
  }
}
