export enum REQUISITION_STATUS {
  OPEN = 'open',
  AWAITING_APPROVAL = 'awaiting_approval',
  APPROVED = 'approved',
  DECLINED = 'declined',
  CLOSED = 'closed',
  CANCELLED = 'cancelled',
}

export enum PRODUCT_VIEW {
  TILE_VIEW,
  LIST_VIEW,
}

export enum ConfirmationVariant {
  Approved = 'Approved',
  Declined = 'Declined',
  AwaitingApproval = 'AwaitingApproval',
  AutoApproved = 'AutoApproved',
  PartiallyApproved = 'PartiallyApproved',
}

export type ShoppingCartContext = {
  readonly?: boolean
}

export type RequisitionFormInputs = {
  originId: number
  reference: string
  deliveryAddressId: number
  sendToSupplier: boolean
  departmentId: number
  accountId: number | null
  expectedDeliveryDate: string
  stockLocationName: string
  advanced: boolean
}

export type PurchaseOrderFormInputs = Omit<RequisitionFormInputs, 'originId'>

export type RequisitionType = {
  key: string
  name: string
  desc: string
  icon: JSX.Element
  isReleased: boolean
} | null

export enum RequisitionTypeKey {
  AllSupplier = 'all-supplier',
  SingleSupplier = 'single-supplier',
  BuyList = 'buy-list',
  ReOrder = 're-order',
}

export enum OriginType {
  Purchaser = 'Purchasing::Purchaser',
  SupplierRelationship = 'Purchasing::SupplierRelationship',
  Catalogue = 'Purchasing::Catalogue',
  Requisition = 'Purchasing::Requisition',
}

export type SupplierFilter = {
  id: number
  name: string
}

export enum EditRequisitionModalEnum {
  Reference = 'Reference',
  DeliveryAddress = 'DeliveryAddress',
  DeptAndAcc = 'DeptAndAcc',
  ExpectedDeliveryDate = 'ExpectedDeliveryDate',
  SendToSupplier = 'SendToSupplier',
}

export enum CommentType {
  Internal = 'Internal',
  External = 'External',
}
