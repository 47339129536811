import { Helmet } from 'react-helmet-async'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreatePricedCatalog } from '../../hooks'

import { CatalogFormInputs } from '@/modules/catalogs/types'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks'
import { SelectSupplier } from '@/modules/requisitions/pages/create/form-elements'
import CreateFooter from '@/modules/shared/components/create-form/CreateFooter'
import FormInput from '@/modules/shared/components/create-form/FormInput'

export default function CreateCatalog() {
  const { t } = useTranslation()
  const formMethods = useForm<CatalogFormInputs>()
  const { currentPurchaser } = useCurrentPurchaser()

  const [createPricedCatalog, { loading }] = useCreatePricedCatalog()

  const onSubmit: SubmitHandler<CatalogFormInputs> = (data) => {
    const { title, originId } = data
    createPricedCatalog({
      variables: {
        input: {
          title,
          ownerId: currentPurchaser?.id,
          ownerType: 'Organisation',
          supplierId: Number(originId),
        },
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{t('createCatalog.pageTitle', 'Create a New Self-Managed Catalog')}</title>
      </Helmet>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} className="mb-80">
        <div className="mx-auto my-20 w-full p-4 md:w-[50rem]">
          <h1 className="text-2xl font-semibold">
            {t('createCatalog.pageTitle', 'Create a New Self-Managed Catalog')}
          </h1>
          <FormProvider {...formMethods}>
            <FormInput
              title={t('general.catalogName', 'Catalog Name')}
              description={t('createCatalog.reference.explain', 'Please provide a name for the Self-Managed Catalog.')}
              placeholder={t('general.name', 'Name')}
              required
              requiredError={t(
                'createCatalog.title.requiredError',
                'You must provide a name for the Self-Managed Catalog'
              )}
              register="title"
            />
            <SelectSupplier useSupplierId />
          </FormProvider>
          <CreateFooter
            getHelpText={t('createCatalog.actions.help', 'Get Help creating Catalogs')}
            createButtonText={t('general.createCatalog', 'Create Catalog')}
            createLoading={loading}
          />
        </div>
      </form>
    </>
  )
}
