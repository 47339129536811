import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Outlet, generatePath, matchPath, useLocation, useNavigate } from 'react-router-dom'

import { Breadcrumb, MoreOptionsMenu, QueryResult, ThemeWrapper } from '../shared/components'
import HeaderTabs, { TabItem } from '../shared/components/header-tabs'
import { HomeIcon, OptionIcon, ShieldIcon } from '../shared/icons'
import { Themes } from '../shared/types'
import OrganisationHierarchy from './organisation-hierarchy/OrganisationHierarchy'

import { useGetPurchaser } from '@/modules/purchasing/hooks'
import { findActiveRoute } from '@/modules/shared/utils'

export default function AdminHeader() {
  const { purchaser, loadingState, error } = useGetPurchaser()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { id, name } = purchaser || {}

  const actionList: TabItem[] = [{ target: 'customize', label: t('general.customize', 'Customize') }]

  const isRedirect = matchPath('/admin/organisations/:organisationId', location.pathname)

  useEffect(() => {
    if (isRedirect) {
      navigate(
        generatePath('/admin/organisations/:organisationId/customize', {
          organisationId: String(id),
        })
      )
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${t('general.adminCenter', 'Admin Center')} ${name}`}</title>
      </Helmet>
      <QueryResult loading={loadingState.loading} error={error}>
        <div className="mx-auto max-w-[95rem] space-y-5 px-4">
          <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2" data-testid="invoice-breadcrumb">
            <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
            <Breadcrumb.Item href="#">{t('general.adminCenter', 'Admin Center')}</Breadcrumb.Item>
            <Breadcrumb.Item href="#">{name}</Breadcrumb.Item>
            <Breadcrumb.Item>{findActiveRoute(actionList, location.pathname)}</Breadcrumb.Item>
          </Breadcrumb>
          <section className="my-5 flex flex-col gap-y-2">
            <section
              className="flex w-full flex-col justify-between rounded-md bg-white shadow sm:flex-row"
              data-testid="admin-header"
            >
              <div className="flex items-center gap-4 p-4">
                <ThemeWrapper theme={Themes.Primary} className="flex items-center justify-center rounded-md p-2">
                  <ShieldIcon className="h-10 w-10 text-primary" />
                </ThemeWrapper>
                <div className="flex justify-between">
                  <span>
                    <p className="font-bold">{t('general.adminCenter', 'Admin Center')}</p>
                    <p className="text-sm text-gray-500">{name}</p>
                  </span>
                </div>
              </div>
              <hr className="sm:hidden" />
              <div className="flex items-center justify-between p-4 sm:justify-center">
                <p className="text-sm text-gray-500 sm:hidden">{t('general.actions')}</p>
                <div className="flex gap-x-2">
                  <MoreOptionsMenu showHelp showOpenInPPlus buttonTheme="dark">
                    <OptionIcon className="h-8 w-8" />
                  </MoreOptionsMenu>
                </div>
              </div>
            </section>
            <OrganisationHierarchy />
            <section className="flex items-center justify-between rounded-md bg-white px-4 py-3 text-sm shadow-sm">
              <p>{t('general.currentlySelectedOrganisation', 'Currently Selected Organisation')}</p>
              <div className="rounded-md bg-primary/10 px-3 py-1.5 text-xs text-primary">{name}</div>
            </section>
            <HeaderTabs actionList={actionList} />
          </section>
          <Outlet />
        </div>
      </QueryResult>
    </>
  )
}
