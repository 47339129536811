import { useTranslation } from 'react-i18next'

import { Button } from '@/modules/shared/components'
import { SaveIcon } from '@/modules/shared/icons'

interface SaveFooterProps {
  onDiscardChanges: () => void
  loading?: boolean
}

export default function SaveFooter(props: SaveFooterProps) {
  const { onDiscardChanges, loading } = props
  const { t } = useTranslation()

  return (
    <div className="fixed bottom-0 left-0 z-10 w-screen text-sm" data-testid="save-footer">
      <hr />
      <div className="flex w-full flex-col justify-between gap-x-2 gap-y-5 bg-white p-5 md:flex-row">
        <div className="flex items-center gap-x-3">
          <SaveIcon className="ms:block hidden h-9 w-9 shrink-0" />
          <p className="w-full text-center md:w-auto md:text-left">
            {t('mySuppliers.supplier.unsavedChanges', 'You have unsaved changes on this page.')}
          </p>
        </div>
        <div className="flex flex-col gap-2 md:flex-row">
          <Button
            className="flex h-11 items-center justify-center rounded-md bg-gray-200 px-5"
            type="button"
            onClick={onDiscardChanges}
            data-testid="discard"
          >
            {t('general.discardChanges', 'Discard Changes')}
          </Button>
          <Button
            type="submit"
            loading={loading}
            className="flex h-11 items-center justify-center rounded-md bg-primary px-5 text-white"
            data-testid="save-footer-button"
          >
            {t('general.saveChanges', 'Save Changes')}
          </Button>
        </div>
      </div>
    </div>
  )
}
