import dayjs from 'dayjs'

import { Dates, QueryParameter } from '../types'

export default function changeDateForQuery(
  type: QueryParameter.From | QueryParameter.To,
  date: string | null | undefined
) {
  return date
    ? String(
        dayjs(date)
          .add(type === QueryParameter.From ? -1 : 1, 'day')
          .format(Dates.ShortYear)
      )
    : ''
}
