import { useQuery } from '@apollo/client'

import {
  GetRequisitionsAwaitingApprovalCountDocument,
  Requisition,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { extractEdges } from '@/modules/shared/utils'

export default function useGetRequisitionAwaitingApprovalCount() {
  const { data } = useQuery(GetRequisitionsAwaitingApprovalCountDocument, {
    fetchPolicy: 'no-cache',
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const approvalList = extractEdges<Requisition>(data?.currentPurchaser?.requisitionsAwaitingMyApproval)

  return { count: approvalList.length }
}
