import { LazyQueryExecFunction, useMutation } from '@apollo/client'

import {
  GetPurchaseOrderLinesLinkableQuery,
  UpdateInvoiceLineItemDocument,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useUpdateInvoiceLineItem(
  invoiceId?: string,
  purchaseOrderId?: number,
  fetchLinkableLines?: LazyQueryExecFunction<
    GetPurchaseOrderLinesLinkableQuery,
    { invoiceId: string; purchaseOrderId: number }
  >
) {
  const mutation = useMutation(UpdateInvoiceLineItemDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    onCompleted: () => {
      if (fetchLinkableLines && invoiceId && purchaseOrderId) {
        fetchLinkableLines({
          variables: {
            invoiceId,
            purchaseOrderId,
          },
        })
      }
    },
  })

  return mutation
}
