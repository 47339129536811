import { graphql } from 'msw'

import { CancelInvoiceMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseCancelInvoice: CancelInvoiceMutation = {
  cancelInvoice: {
    invoice: {
      id: 7524140,
      state: 'cancelled',
      __typename: 'Invoice',
    },
    __typename: 'CancelInvoiceMutationPayload',
  },
}

export const CancelInvoice = graphql.mutation<CancelInvoiceMutation>('CancelInvoice', (_, res, ctx) => {
  return res(ctx.data(baseCancelInvoice))
})

export default baseCancelInvoice
