import { RadioGroup } from '@headlessui/react'
import { PropsWithChildren, useState } from 'react'

interface CreateTypeSelectionProps extends PropsWithChildren {
  selectedTypeURLParam: string
  title: string
  description: string
}

export default function CreateTypeSelection({
  selectedTypeURLParam,
  title,
  description,
  children,
}: CreateTypeSelectionProps) {
  const [selected, setSelected] = useState('')

  return (
    <section className="mt-8">
      <h2 className="text-sm font-semibold">{title}</h2>
      <p className="text-sm text-gray-500">{description}</p>
      <div className="mt-2">
        <RadioGroup value={selectedTypeURLParam || selected} onChange={setSelected}>
          <div className="mt-3 space-y-2">{children}</div>
        </RadioGroup>
      </div>
    </section>
  )
}
