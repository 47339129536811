import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import PurchaseOrderReceivedStatus from './PurchaseOrderReceivedStatus'

import {
  PurchaseOrder,
  PurchaseOrderReceivedStatusEnum,
  ReceivingDocument,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { ReceivingNoteStatus } from '@/modules/receiving-notes/components'
import { Modal, PPNewDetailLink } from '@/modules/shared/components'
import ReceiptsModalFooter from '@/modules/shared/components/receipts-modal-footer/ReceiptsModalFooter'
import Table from '@/modules/shared/components/table/Table'
import { NewTabIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

interface PurchaseOrderReceiptsModalProps {
  purchaseOrder: PurchaseOrder
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
}

export default function PurchaseOrderReceiptsModal({
  purchaseOrder,
  showModal,
  setShowModal,
}: PurchaseOrderReceiptsModalProps) {
  const { t } = useTranslation()
  const receipts = extractEdges<ReceivingDocument>(purchaseOrder.receivingDocuments)

  const onCloseModal = () => {
    setShowModal(false)
  }

  const purchaseOrderStatus = () => {
    switch (purchaseOrder.receivedStatus) {
      case PurchaseOrderReceivedStatusEnum.FullyReceived:
        return {
          title: t('general.isReceivedStatus', '{{ number }} is Received', {
            number: purchaseOrder.purchaseOrderNumber,
          }),
          body: t(
            'purchaseOrders.allPurchaseOrders.table.receiptsModal.receivedBody',
            'All items on this Purchase Order have been fully Received. This means that the Ordered Quantity of each Purchase Order line has been marked as Received on one or more Receiving Notes. The Receiving Notes are shown below.'
          ),
        }
      case PurchaseOrderReceivedStatusEnum.PartlyReceived:
        return {
          title: t('general.isPartlyReceivedStatus', '{{ number }} is Partly Received', {
            number: purchaseOrder.purchaseOrderNumber,
          }),
          body: t(
            'purchaseOrders.allPurchaseOrders.table.receiptsModal.partlyReceivedBody',
            'Some of the items on this Purchase Order have been Received. This means that some of the Ordered Quantity of the Purchase Order lines has been marked as Received on one or more Receiving Notes. The Receiving Notes are shown below.'
          ),
        }
      case PurchaseOrderReceivedStatusEnum.NotReceived:
        return {
          title: t('general.isNotReceivedStatus', '{{ number }} is Not Received', {
            number: purchaseOrder.purchaseOrderNumber,
          }),
          body: t(
            'purchaseOrders.allPurchaseOrders.table.receiptsModal.notReceivedBody',
            'None of the items on this Purchase Order have been Received. This means that none of the Ordered Quantity of the Purchase Order lines has been marked as Received on any Receiving Notes, or it could mean that the Receiving Notes are still in a Draft state. The Receiving Notes are shown below.'
          ),
        }
      default:
        return {
          title: t('general.unknown', 'Unknown'),
          body: t('general.unknown', 'Unknown'),
        }
    }
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal} dataTestId="receipts-modal">
      <Modal.Panel
        as="div"
        className="flex max-h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]"
      >
        <Modal.Title title={purchaseOrderStatus().title} onCloseModal={onCloseModal} />
        <Modal.Body className="space-y-4 text-sm">
          <section>
            <p className="font-bold">{t('general.receivedStatus', 'Received Status')}</p>
            <p className="text-gray-500">{purchaseOrderStatus().body}</p>
            <div className="mt-2 rounded-md border bg-gray-100 p-4">
              <span>
                {purchaseOrder.receivedStatus && (
                  <PurchaseOrderReceivedStatus receivedStatus={purchaseOrder.receivedStatus} />
                )}
              </span>
            </div>
          </section>
          <section>
            <p className="font-bold">{t('general.receivingNotes', 'Receiving Notes')}</p>
            <p className="text-gray-500">
              {t(
                'purchaseOrders.allPurchaseOrders.table.receiptsModal.receivingNotesDesc',
                'The following Receiving Notes have already been created against this Purchase Order. You can create another Receiving Note using the button below.'
              )}
            </p>
            <Table
              dataSource={receipts}
              dataTestId="receiving-notes-table"
              keyExtractor={(record) => String(record.id)}
              tableHeadStyles="border-none"
              columns={[
                {
                  title: t('general.receivingNote', 'Receiving Note'),
                  key: 'receivingNote',
                  headerCellStyles: 'pl-3 pr-4 py-4',
                  cellStyles: 'pl-3 pr-4 py-4',
                  minWidth: 8.75,
                  grow: true,
                  render(record) {
                    return (
                      <PPNewDetailLink
                        testId="receiving-note-number"
                        openNewTab
                        className="flex items-center gap-x-1 text-primary"
                        type="receiving-document"
                        secondaryId={String(purchaseOrder.id)}
                        id={String(record.id)}
                      >
                        <span>{record.receivingDocumentNumber}</span>
                        <NewTabIcon className="h-5 w-5" />
                      </PPNewDetailLink>
                    )
                  },
                },
                {
                  title: t('general.status', 'Status'),
                  key: 'status',
                  headerCellStyles: 'py-4 text-center',
                  cellStyles: 'py-4 text-center',
                  minWidth: 7.5,
                  maxWidth: 7.5,
                  render(record) {
                    return <ReceivingNoteStatus state={String(record.workflowState)} />
                  },
                },
              ]}
            />
          </section>
        </Modal.Body>
        <ReceiptsModalFooter onCloseModal={onCloseModal} purchaseOrderId={String(purchaseOrder.id)} />
      </Modal.Panel>
    </Modal>
  )
}
