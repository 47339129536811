import { graphql } from 'msw'

import {
  FlaggingMessageTypeEnum,
  UpdateInvoiceLineItemMutation,
} from '@/graphql/purchasing/generated/purchasing_graphql'

const baseUpdateInvoiceLineItem: UpdateInvoiceLineItemMutation = {
  updateInvoiceLineItem: {
    invoiceLineItem: {
      id: 24586766,
      quantity: 1,
      unitPrice: '300',
      taxPercentage: 10,
      purchaseOrderLineItemId: 60215981,
      lineTotal: 300,
      totalInvoicedQuantity: 0,
      totalReceivedQuantity: 2,
      invoicePriceVariance: 2,
      flaggingMessages: [
        {
          type: FlaggingMessageTypeEnum.InvoiceFlaggedLineBlockage,
          values: [],
        },
      ],
      invoice: {
        id: 7524140,
        lineTotal: '300',
        lineTaxTotal: '20',
        outOfBalance: false,
        flaggingMessages: [],
        __typename: 'Invoice',
      },
      purchaseOrderLineItem: {
        id: 60215981,
        quantity: 1,
        unitPrice: 300,
        taxPercentage: 10,
        product: {
          id: 71631,
          category: {
            id: 110137,
            name: 'Internal Finishing Materials',
            parent: {
              id: 109475,
              name: 'Building and Construction',
              __typename: 'Category',
            },
            __typename: 'Category',
          },
          brand: null,
          itemDescription: 'Maintenance Fee : For Plumbing',
          concatenatedSellUnit: 'each of 1',
          itemPackName: null,
          itemMeasure: 'each',
          itemSellPackName: 'each',
          itemSize: 1,
          __typename: 'Product',
        },
        __typename: 'PurchaseOrderLineItem',
      },
      __typename: 'InvoiceLineItem',
    },
    __typename: 'UpdateInvoiceLineItemMutationPayload',
  },
}

export const UpdateInvoiceLineItem = graphql.mutation<UpdateInvoiceLineItemMutation>(
  'UpdateInvoiceLineItem',
  (_, res, ctx) => {
    return res(ctx.data(baseUpdateInvoiceLineItem))
  }
)

export default baseUpdateInvoiceLineItem
