interface EmptyStateProps {
  img: string
  message: string
  title?: string
}

export default function EmptyState(props: EmptyStateProps) {
  const { img, message, title } = props
  return (
    <div className="mb-3 flex flex-col items-center justify-center">
      <img src={img} className="h-64 md:h-72" alt="" />
      <div className="text-center">
        <span className="text-xs font-bold md:text-sm">{title}</span>
        <p className="text-xs text-gray-500 md:text-sm">{message}</p>
      </div>
    </div>
  )
}
