import { useSetAtom } from 'jotai'

import { useDeleteRequisitionLine, useUpdateRequisitionLine } from '@/modules/requisitions/hooks'
import { isChangingProductQtyAtom } from '@/modules/requisitions/state'

interface UpdateRequisitionLineQuantityInput {
  qty?: number
  lineId: number | null
  productId: number | null
  cataloguedProductId: number | null
}
interface ChangeRequisitionResponse {
  onUpdateRequisitionLineQuantity: (input: UpdateRequisitionLineQuantityInput) => void
  loading: boolean
}

const useUpdateRequisitionLineQuantity = (): ChangeRequisitionResponse => {
  const [deleteRequisitionLine, { loading: loadingDelete }] = useDeleteRequisitionLine()
  const [updateRequisitionLine, { loading: loadingUpdate }] = useUpdateRequisitionLine()
  const setIsUpdatingQuantity = useSetAtom(isChangingProductQtyAtom)
  const onUpdateRequisitionLineQuantity = (input: UpdateRequisitionLineQuantityInput) => {
    const { qty, lineId, productId, cataloguedProductId } = input
    setIsUpdatingQuantity(true)
    if (qty) {
      updateRequisitionLine({
        variables: {
          input: {
            id: Number(lineId),
            productId: Number(productId),
            quantity: qty,
            cataloguedProductId: Number(cataloguedProductId),
          },
        },
        onCompleted() {
          setIsUpdatingQuantity(false)
        },
      })
    } else {
      deleteRequisitionLine({
        variables: {
          input: {
            id: Number(lineId),
          },
        },
        onCompleted() {
          setIsUpdatingQuantity(false)
        },
      })
    }
  }
  return { onUpdateRequisitionLineQuantity, loading: loadingDelete || loadingUpdate }
}

export default useUpdateRequisitionLineQuantity
