import { graphql } from 'msw'

import { GetRequisitionsDraftQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetRequisitionDraft: GetRequisitionsDraftQuery = {
  currentPurchaser: {
    id: 44926,
    requisitions: {
      edges: [
        {
          node: {
            id: 5235941,
            totalValue: 104.5,
            sourceApplication: 'ppnew',
            editableForCurrentUser: true,
            requisitionNumber: 'PR04207',
            reference: 'reference',
            expectedDeliveryDate: '2022-12-02',
            advanced: false,
            account: {
              id: 81234,
              accountName: 'Account 1',
              __typename: 'Account',
            },
            department: {
              id: 1235,
              name: 'Department 1',
              __typename: 'Purchaser',
            },
          },
          __typename: 'RequisitionEdge',
        },
        {
          node: {
            id: 31233,
            totalValue: 122.5,
            sourceApplication: 'ppnew',
            editableForCurrentUser: true,
            requisitionNumber: 'PR04241',
            reference: 'reference',
            expectedDeliveryDate: '2022-12-02',
            advanced: false,
            account: {
              id: 2234,
              accountName: 'Account 2',
              __typename: 'Account',
            },
            department: {
              id: 2235,
              name: 'Department 2',
              __typename: 'Purchaser',
            },
          },
          __typename: 'RequisitionEdge',
        },
        {
          node: {
            id: 123456,
            totalValue: 6633,
            sourceApplication: 'ppnew',
            editableForCurrentUser: true,
            requisitionNumber: 'PR01203',
            reference: 'reference',
            expectedDeliveryDate: '2022-12-02',
            advanced: false,
            account: {
              id: 2234,
              accountName: 'Account 2',
              __typename: 'Account',
            },
            department: {
              id: 2235,
              name: 'Department 2',
              __typename: 'Purchaser',
            },
          },
          __typename: 'RequisitionEdge',
        },
      ],
      pageInfo: {
        endCursor: 'MTQ',
        hasNextPage: false,
        __typename: 'PageInfo',
      },
      __typename: 'RequisitionConnection',
    },
    __typename: 'Purchaser',
  },
}

export const GetRequisitionsDraft = graphql.query<GetRequisitionsDraftQuery>('GetRequisitionsDraft', (_, res, ctx) => {
  return res(ctx.data(baseGetRequisitionDraft))
})

export default baseGetRequisitionDraft
