import classNames from 'classnames'
import { Dispatch, SetStateAction, createContext, useState } from 'react'
import { Outlet } from 'react-router-dom'

import SideNavigation from '../side-navigation/SideNavigation'
import Drawer from './Drawer'

import { Header } from '@/modules/requisitions/layouts'

export const SideBarContext = createContext<{
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
  drawerOpen: boolean
} | null>(null)

export default function AppLayout() {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [toggleSidebar, setToggleSidebar] = useState(true)

  return (
    <div>
      <Header
        setDrawerOpen={setDrawerOpen}
        setToggleSidebar={setToggleSidebar}
        toggleSidebar={toggleSidebar}
        showSidebarButtons
      />
      <SideBarContext.Provider value={{ setDrawerOpen, drawerOpen }}>
        <Drawer />
        <div
          className={classNames(
            'hidden h-full transform transition-all duration-200 ease-linear xl:fixed xl:flex xl:w-64 xl:flex-col',
            {
              '-translate-x-full': !toggleSidebar,
              'translate-x-0': toggleSidebar,
            }
          )}
          data-testid="static-side-navigation"
        >
          <div className="flex grow flex-col overflow-y-auto pl-4 hide-scrollbar">
            <div className="mt-5 flex flex-1 flex-col pt-[4.3125rem]">
              <SideNavigation />
            </div>
          </div>
        </div>
      </SideBarContext.Provider>
      <div
        className={classNames('mb-12 flex flex-1 flex-col py-[4.3125rem] transition-all duration-200 ease-linear', {
          ' xl:pl-64': toggleSidebar,
        })}
      >
        <div className="mt-5 overflow-hidden">
          <Outlet />
          <div className="h-96" />
        </div>
      </div>
    </div>
  )
}
