import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(advancedFormat)

export default function formatDate(dateType: string, date?: string | number | Date | dayjs.Dayjs | null | undefined) {
  if (date) {
    return dayjs(date).format(dateType)
  } else {
    return '--'
  }
}
