import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link, generatePath, useParams } from 'react-router-dom'

import { ThemeWrapper } from '@/modules/shared/components'
import ProductList from '@/modules/shared/components/product-list'
import { useGetAvailableProducts } from '@/modules/shared/hooks'
import { ChevronLeftIcon, ControlBarIcon } from '@/modules/shared/icons'
import { ProductListType, Themes } from '@/modules/shared/types'
import { formatProductsToProductTypes } from '@/modules/shared/utils'

export default function AddInvoiceLine() {
  const { t } = useTranslation()
  const { invoiceId } = useParams<{ invoiceId: string }>()

  const { availableProducts, onFetchMoreProducts, onFilter, loadingStates, pageInfo } = useGetAvailableProducts()

  const products = formatProductsToProductTypes(availableProducts)

  return (
    <>
      <Helmet>
        <title>{t('general.addInvoiceLine', 'Add Invoice Line')}</title>
      </Helmet>
      <header className="px-5 py-3">
        <Link
          to={generatePath('/invoices/:invoiceId', { invoiceId: String(invoiceId) })}
          className="flex h-11 w-fit items-center gap-2 rounded-md bg-gray-200 px-4 text-sm"
        >
          <ChevronLeftIcon className="h-6 w-6" />
          {t('general.returnToInvoice', 'Return to Invoice')}
        </Link>
      </header>
      <section className="mx-auto mt-5 max-w-[112.5rem] space-y-5 px-4">
        <section className="flex w-full flex-col justify-between rounded-md bg-white shadow-sm sm:flex-row">
          <div className="flex items-center gap-4 p-4">
            <ThemeWrapper theme={Themes.Primary} className="flex items-center justify-center rounded-md p-2">
              <ControlBarIcon className="h-10 w-10 text-primary" />
            </ThemeWrapper>
            <div className="flex justify-between">
              <span>
                <p className="font-bold">{t('general.searchForProducts', 'Search for Products')}</p>
                <p className="text-sm">{t('invoices.addLine.createAnInvoiceLine', 'Create an Invoice Line')}</p>
              </span>
            </div>
          </div>
        </section>
        <ProductList
          prNumber={t('general.availableProducts', 'Available Products')}
          products={products}
          onFetchMoreProduct={onFetchMoreProducts}
          searchProductLoading={
            loadingStates.loading || loadingStates.setVariablesLoading || loadingStates.refetchLoading
          }
          hasMoreProduct={!!pageInfo?.hasNextPage}
          onFilter={onFilter}
          type={ProductListType.AddInvoiceLine}
        />
      </section>
    </>
  )
}
