import { ApolloError, ServerError } from '@apollo/client'
import classNames from 'classnames'
import { Fragment, PropsWithChildren } from 'react'

import { Spinner } from '@/modules/requisitions/components'
import { GenericError } from '@/modules/shared/components'

interface QueryResultProps extends PropsWithChildren {
  loading?: boolean
  error?: ApolloError | undefined
  size?: 'md'
  spinnerClassName?: string
}

function QueryResult({ children, error, loading, size, spinnerClassName }: QueryResultProps) {
  const networkError = error?.networkError as ServerError

  if (networkError?.statusCode === 401) {
    return null
  }

  if (error) {
    return <GenericError />
  }

  if (loading) {
    return (
      <Spinner
        className={classNames(spinnerClassName, { 'mt-12 h-28 md:h-32': !size, 'my-5 h-14 md:w-16': size === 'md' })}
      />
    )
  }

  return <Fragment>{children}</Fragment>
}

export default QueryResult
