import { useMutation } from '@apollo/client'

import { OperationalApproveInvoiceDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useOperationalApproveInvoice() {
  const mutation = useMutation(OperationalApproveInvoiceDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  return mutation
}
