import { Transition } from '@headlessui/react'
import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import BestBuy from './BestBuy'
import ProductsListView from './list-view/ProductsListView'
import ProductsTileView from './tile-view/ProductsTileView'

import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Spinner } from '@/modules/requisitions/components'
import { ProductListEmptyState } from '@/modules/requisitions/components'
import { PRODUCT_VIEW } from '@/modules/requisitions/types'
import { Button } from '@/modules/shared/components'
import AdvancedSearch from '@/modules/shared/components/advanced-search/AdvancedSearch'
import SearchAndFilter, { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { useWindowSize } from '@/modules/shared/hooks'
import { ControlBarIcon } from '@/modules/shared/icons'
import SearchContainer from '@/modules/shared/search-container'
import { ProductListType, ProductType } from '@/modules/shared/types'

interface ProductListProps {
  prNumber?: string
  prReference?: string
  products: ProductType[]
  onFetchMoreProduct: () => void
  searchProductLoading?: boolean
  hasMoreProduct: boolean
  onFilter: (filter: OnFilter) => void
  type: ProductListType
  lines?: RequisitionLine[]
}

function ProductList({
  products,
  onFetchMoreProduct,
  searchProductLoading,
  hasMoreProduct,
  prNumber,
  prReference,
  onFilter,
  type,
  lines,
}: ProductListProps) {
  const { t } = useTranslation()
  const { sm, twoXL, isLargeScreen } = useWindowSize()
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)

  const [productView, setProductView] = useState<PRODUCT_VIEW>(PRODUCT_VIEW.TILE_VIEW)

  const searchFilterPlaceholder = () => {
    if (sm) {
      return t('shopPage.productList.searchPlaceholder1', 'Search by Product Name or Brand')
    } else {
      // if screen size is less than Breakpoints.SM
      return t('general.search', 'Search')
    }
  }

  return (
    <>
      <div data-testid="requisition-info" className="flex items-center justify-between">
        <div className="hidden items-center sm:flex">
          <ControlBarIcon className="h-10 w-10" />
          <p className="ml-3 text-sm">
            {prNumber} {prReference}
          </p>
        </div>
        <div className="flex gap-x-5">
          <div className="hidden md:flex">{type === ProductListType.Shop && <BestBuy />}</div>
          <div data-testid="product-view-tabs" className="hidden gap-1.5 rounded-lg bg-gray-200 px-1.5 py-1 lg:flex">
            <Button
              className={classNames('h-11 rounded-md border px-5 text-sm', {
                'pointer-events-none border-gray-300 bg-white ': productView === PRODUCT_VIEW.TILE_VIEW,
                'text-gray-500 hover:bg-gray-300': productView === PRODUCT_VIEW.LIST_VIEW,
              })}
              onClick={() => setProductView(PRODUCT_VIEW.TILE_VIEW)}
            >
              {t('general.tileView', 'Tile View')}
            </Button>
            <Button
              className={classNames('h-11 rounded-md border px-5 text-sm', {
                'pointer-events-none border-gray-300 bg-white ': productView === PRODUCT_VIEW.LIST_VIEW,
                'text-gray-500 hover:bg-gray-300': productView === PRODUCT_VIEW.TILE_VIEW,
              })}
              onClick={() => setProductView(PRODUCT_VIEW.LIST_VIEW)}
            >
              {t('general.listView', 'List View')}
            </Button>
          </div>
        </div>
      </div>
      <SearchContainer
        showAdvancedSearch={showAdvancedSearch}
        setShowAdvancedSearch={setShowAdvancedSearch}
        className="2xl:hidden"
      >
        <SearchAndFilter
          className="w-full"
          testId="product-search-input"
          placeholder={searchFilterPlaceholder()}
          ariaLabel={t('general.searchingForProducts', 'Searching for products')}
          onFilter={onFilter}
        />
      </SearchContainer>
      {showAdvancedSearch && (
        <div className="2xl:hidden">
          {!twoXL && (
            <AdvancedSearch
              showSupplier
              showCategory
              showSearch
              advancedShopping={false}
              showProductOptions={type !== ProductListType.Shop}
            />
          )}
        </div>
      )}
      <div className="flex gap-x-5">
        <div className="hidden h-fit w-full max-w-xs 2xl:block">
          {twoXL && (
            <AdvancedSearch
              showSupplier
              showCategory
              showSearch
              advancedShopping={false}
              showProductOptions={type !== ProductListType.Shop}
            />
          )}
        </div>
        <div className="flex-1">
          {searchProductLoading && <Spinner className="mt-5 h-14 md:w-16" />}
          {!searchProductLoading && (
            <InfiniteScroll
              dataLength={products.length}
              next={onFetchMoreProduct}
              hasMore={hasMoreProduct}
              loader={<Spinner className="mt-5 h-14 md:w-16" />}
            >
              <Transition
                show={productView === PRODUCT_VIEW.TILE_VIEW || !isLargeScreen}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ProductsTileView products={products} type={type} lines={lines} />
              </Transition>
              <Transition
                show={productView === PRODUCT_VIEW.LIST_VIEW && isLargeScreen}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ProductsListView products={products} type={type} />
              </Transition>
            </InfiniteScroll>
          )}
          {!searchProductLoading && products.length === 0 && (
            <ProductListEmptyState
              message={t('shopPage.productList.noProductFound', "We couldn't find any products to display.")}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ProductList
