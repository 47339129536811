import { Maybe, Product, RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'

export enum Breakpoints {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  XXL = 1536,
}

export enum Themes {
  Primary = 'primary',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export enum QueryParameter {
  Search = 'search',
  Status = 'status',
  From = 'from',
  To = 'to',
  Enabled = 'enabled',
  Categories = 'categories',
  ParentCategories = 'parent-categories',
  Suppliers = 'suppliers',
  SupplierProductCode = 'supplier-product-code',
  PurchaserProductCode = 'purchaser-product-code',
  PPlusProductCode = 'p-plus-product-code',
  MatchedInvoice = 'matched-invoice',
  CompletedReceivingNote = 'completed-receiving-note',
  PeriodYear = 'period-year',
  PeriodMonth = 'period-month',
  BestBuy = 'best-buy',
  StockLocations = 'stock-locations',
  CountStatus = 'count-status',
  HideZero = 'hide-zero',
  SelfManaged = 'self-managed',
  HasQuotes = 'has-quotes',
  Master = 'master',
  OpenHierarchy = 'open-hierarchy',
  Image = 'image',
  Catalogued = 'catalogued',
  Matched = 'matched',
}

export enum Dates {
  Long = 'Do MMMM YYYY h:mma',
  Medium = 'dddd, MMMM Do YYYY',
  Short = 'DD MMM YYYY',
  ShortOrdinal = 'Do MMM YYYY',
  ShortReverse = 'MMM Do YYYY',
  ShortYear = 'YYYY-MM-DD',
  Time = 'h:mma',
  MonthYear = 'MMMM YYYY',
}

export type FilterTabItem = {
  key: string
  filterValue: string
  target?: string
}

export type SupplierFormInputs = {
  supplierNumber?: string
  departmentId?: number | null
  accountId?: number | null
  defaultCategoryId?: number | null
}

export enum CSSVariables {
  PrimaryColor = '--primary-color',
  PrimaryColorLight = '--primary-light',
  PrimaryColorLighter = '--primary-lighter',
}

export type SlideData = {
  id: string
  imageSrc: string
}

export type StatusMapping<T extends string> = Record<
  T,
  {
    content: string
    tooltip: string
    customStyles: string
  }
>
export enum DocumentType {
  Requisition = 'Trade::Requisition',
  PurchaseOrder = 'Trade::PurchaseOrder',
  Invoice = 'Trade::Invoice',
  CreditNote = 'Trade::CreditNote',
}

export enum Document {
  Requisition = 'requisition',
  PurchaseOrder = 'purchaseOrder',
  Invoice = 'invoice',
  Stocktake = 'stocktake',
  CreditNote = 'creditNote',
}

// used to replicate the tailwindCSS breakpoints
export type BreakpointKey = 'sm' | 'md' | 'lg' | 'xl' | 'twoXL'
export type BreakpointsAsIntegers = Record<BreakpointKey, number>

export enum ReceivingNotesReceivedState {
  received = 'received',
  notReceived = 'notReceived',
}

export enum StocktakeState {
  Closed = 'closed',
  InProgress = 'in-progress',
}

export enum StocktakeCountState {
  CountComplete = 'Count Complete',
  PartCounted = 'Count In Progress',
  NotCounted = 'Count Not Started',
}

export enum Env {
  Production = 'prd',
  Staging = 'stg',
  Development = 'dev',
  UAT = 'uat',
}

export type MaybeProps<T> = { [P in keyof T]: Maybe<T[P]> }

export type AdminFormInputs = {
  id: number
  inventory: boolean
  promoBanners: boolean
  quickCreate: boolean
}

export enum ProductListType {
  Shop = 'Shop',
  BuyListBuilder = 'BuyListBuilder',
  AddInvoiceLine = 'AddInvoiceLine',
  AddCatalogProduct = 'AddCatalogProduct',
}

export type ProductType = RequisitionLine & {
  availableProduct?: Product
}
