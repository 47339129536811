import { useQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BooleanParam, DelimitedNumericArrayParam, StringParam, useQueryParam } from 'use-query-params'

import {
  GetAdvancedPossibleRequisitionLinesDocument,
  RequisitionLine,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { QueryParameter } from '@/modules/shared/types'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export const useGetAdvancedPossibleRequisitionLines = () => {
  const { requisitionId } = useParams()
  const paginationResultsPerPage = 20
  const [suppliersURLParam] = useQueryParam(QueryParameter.Suppliers, DelimitedNumericArrayParam)
  const [categoriesURLParam] = useQueryParam(QueryParameter.Categories, DelimitedNumericArrayParam)
  const [searchTermURLParam] = useQueryParam(QueryParameter.Search, StringParam)
  const [supplierSearchURLParam] = useQueryParam(QueryParameter.SupplierProductCode, StringParam)
  const [purchaserSearchURLParam] = useQueryParam(QueryParameter.PurchaserProductCode, StringParam)
  const [ppSearchURLParam] = useQueryParam(QueryParameter.PPlusProductCode, StringParam)
  const [hideZeroQtyURLParam] = useQueryParam(QueryParameter.HideZero, BooleanParam)

  const [filtersApplied, setFiltersApplied] = useState({
    term: searchTermURLParam?.trim() || '',
    supplierIds: suppliersURLParam as number[],
    categoryIds: categoriesURLParam as number[],
    supplierProductCode: supplierSearchURLParam,
    purchaserProductCode: purchaserSearchURLParam,
    productId: Number(ppSearchURLParam),
    excludeZeroQuantity: !!hideZeroQtyURLParam,
  })

  const { data, error, networkStatus, refetch } = useQuery(GetAdvancedPossibleRequisitionLinesDocument, {
    variables: {
      requisitionId: Number(requisitionId),
      first: paginationResultsPerPage,
      bestBuy: true,
      ...filtersApplied,
    },
    context: { uri: PURCHASING_GRAPHQL_API },
    notifyOnNetworkStatusChange: true,
  })
  const [searchProductLoading, setSearchProductLoading] = useState(false)
  const loadingStates = checkNetworkStatus(networkStatus)
  const { setVariablesLoading, refetchLoading } = loadingStates

  useEffect(() => {
    if (setVariablesLoading || refetchLoading) {
      setSearchProductLoading(true)
    } else if (data) {
      setSearchProductLoading(false)
    }
  }, [setVariablesLoading, refetchLoading, data])

  const actions = {
    refetch: refetch,
    onFilter: async ({
      searchValue,
      hideZero,
      categoryIds,
      supplierIds,
      supplierProductCode,
      purchaserProductCode,
      pPlusProductCode,
    }: OnFilter) => {
      const newFilter = {
        term: searchValue,
        supplierIds: supplierIds as number[],
        categoryIds: categoryIds as number[],
        supplierProductCode: supplierProductCode,
        purchaserProductCode: purchaserProductCode,
        productId: Number(pPlusProductCode),
        excludeZeroQuantity: !!hideZero,
      }
      // Only filter if the filter values are different than the current filter values
      if (!_.isEqual(newFilter, filtersApplied)) {
        await refetch(newFilter)
        setSearchProductLoading(true)
        setFiltersApplied(newFilter)
      }
    },
  }

  return {
    loadingStates,
    actions,
    error,
    searchProductLoading,
    requisition: data?.currentPurchaser?.requisition,
    advancedPossibleRequisitionLines: extractEdges<RequisitionLine>(
      data?.currentPurchaser?.requisition?.possibleRequisitionLines
    ),
    pageInfo: data?.currentPurchaser?.requisition?.possibleRequisitionLines?.pageInfo,
    paginationResultsPerPage,
  }
}
