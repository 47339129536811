import validateCurrencyCode from 'validate-currency-code'

import { useCurrentPurchaser } from '@/modules/purchasing/hooks'

export const locales = (currency: string | null | undefined) => {
  switch (currency) {
    case 'AUD':
      return 'en-AU'
    case 'GBP':
      return 'en-GB'
    case 'THB':
      return 'th-TH'
    case 'SGD':
      return 'en-SG'
    case 'FJD':
      return 'en-FJ'
    case 'EUR':
      return 'en-DE'
    case 'KHR':
      return 'km-KH'
    case 'LAK':
      return 'lo-LA'
    case 'MVR':
      return 'dv-MV'
    case 'NZD':
      return 'en-NZ'
    case 'MYR':
      return 'ms-MY'
    case 'PGK':
      return 'en-PG'
    case 'PHP':
      return 'en-PH'
    case 'VND':
      return 'vi-VN'
    case 'VUV':
      return 'bi-VU'
    case 'AED':
      return 'ar-AE'
    case 'ZAR':
      return 'en-ZA'
    case 'USD':
      return 'en-US'
    default:
      return 'en'
  }
}

export default function useMoney() {
  const { currentPurchaser } = useCurrentPurchaser()

  const validCurrency = validateCurrencyCode(String(currentPurchaser?.currency))

  const formatMoney = (amount: number | string | null | undefined = 0, quantity: number | null = 1) => {
    if (isNaN(Number(amount))) amount = 0
    return new Intl.NumberFormat(locales(currentPurchaser?.currency), {
      style: 'currency',
      currency: validCurrency ? String(currentPurchaser?.currency) : 'USD',
    }).format(Number(amount) * (quantity ?? 1))
  }
  return { formatMoney }
}
