import { PropsWithChildren } from 'react'
import { I18nProvider } from 'react-aria'

import { locales } from '../hooks/useMoney'

import { useCurrentPurchaser } from '@/modules/purchasing/hooks'

export default function ReactAriaI18nProvider(props: PropsWithChildren) {
  const { currentPurchaser } = useCurrentPurchaser()
  return <I18nProvider locale={locales(currentPurchaser?.currency)}>{props.children}</I18nProvider>
}
