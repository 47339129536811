import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useSpringCarousel } from 'react-spring-carousel'

import { SlideData } from '@/modules/shared/types'

interface CarouselProps {
  data: SlideData[]
  dataTestId?: string
}

function Carousel({ data, dataTestId }: CarouselProps) {
  const { carouselFragment, slideToItem, useListenToCustomEvent, slideToNextItem, getCurrentActiveItem } =
    useSpringCarousel({
      withLoop: true,
      items: data.map((slide) => {
        return {
          id: slide.id,
          renderItem: (
            <div
              data-testid="carousel-item"
              className="h-48 w-full cursor-grab bg-cover bg-center"
              style={{
                backgroundImage: `url(${slide.imageSrc})`,
              }}
            />
          ),
        }
      }),
    })
  const [activeSlideId, setActiveSlideId] = useState(getCurrentActiveItem().id)

  useListenToCustomEvent((event) => {
    if (event.eventName === 'onSlideStartChange') {
      setActiveSlideId(event.nextItem.id)
    }
  })

  // change to next slide on every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      slideToNextItem()
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="relative rounded-md shadow-md" data-testid={dataTestId}>
      <div className="w-full overflow-hidden rounded-md">{carouselFragment}</div>
      <div className="absolute bottom-5 right-5 flex gap-x-2">
        {data.map((slide) => {
          return (
            <button key={slide.id} onClick={() => slideToItem(slide.id)}>
              <span
                className={classNames('block h-3 w-3 rounded-full', {
                  'bg-gray-900/50': activeSlideId !== slide.id,
                  'bg-white': activeSlideId === slide.id,
                })}
              />
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default Carousel
