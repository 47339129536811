import { graphql } from 'msw'

import { UpdatePricedCataloguedProductMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseUpdatePricedCataloguedProduct: UpdatePricedCataloguedProductMutation = {
  updatePricedCataloguedProduct: {
    pricedCataloguedProduct: {
      id: 22673148,
      sellUnitPrice: 45,
      sellUnitTaxPercentage: 12,
      __typename: 'PricedCataloguedProduct',
    },
    __typename: 'UpdatePricedCataloguedProductMutationPayload',
  },
}

export const UpdatePricedCataloguedProduct = graphql.mutation<UpdatePricedCataloguedProductMutation>(
  'UpdatePricedCataloguedProduct',
  (_, res, ctx) => {
    return res(ctx.data(baseUpdatePricedCataloguedProduct))
  }
)

export default baseUpdatePricedCataloguedProduct
