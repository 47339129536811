import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'

import { GetCurrentUserDocument } from '@/graphql/access/generated/access_graphql'
import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import { CSSVariables } from '@/modules/shared/types'

type RGBValues = {
  red: number
  green: number
  blue: number
}

export default function useSession() {
  const { data, loading, error } = useQuery(GetCurrentUserDocument)
  const { currentOrganisation } = useCurrentOrganisation()
  const ppxAccess = currentOrganisation?.ppxAccess
  const setColorProperty = (name: CSSVariables, value: RGBValues) => {
    const rgbString = `${value.red}, ${value.green}, ${value.blue}`
    document.documentElement.style.setProperty(name, rgbString)
  }

  // define user information to sentry
  if (data?.currentUser) {
    Sentry.setUser({
      ...data.currentUser,
      id: data.currentUser?.id.toString(),
    })
  }

  // define theme color to global css variable
  useEffect(() => {
    if (ppxAccess) {
      setColorProperty(CSSVariables.PrimaryColor, { red: 238, green: 176, blue: 32 })
      setColorProperty(CSSVariables.PrimaryColorLight, { red: 248, green: 223, blue: 165 })
      setColorProperty(CSSVariables.PrimaryColorLighter, { red: 254, green: 247, blue: 232 })
    } else {
      setColorProperty(CSSVariables.PrimaryColor, { red: 24, green: 119, blue: 242 })
      setColorProperty(CSSVariables.PrimaryColorLight, { red: 185, green: 214, blue: 251 })
      setColorProperty(CSSVariables.PrimaryColorLighter, { red: 231, green: 241, blue: 254 })
    }
  }, [ppxAccess])

  return { currentUser: data?.currentUser, loading, error }
}
