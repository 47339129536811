import { graphql } from 'msw'

import { CreatePricedCataloguedProductMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseCreatePricedCataloguedProduct: CreatePricedCataloguedProductMutation = {
  createPricedCataloguedProduct: {
    pricedCataloguedProduct: {
      id: 22841200,
      productId: 5814401,
      __typename: 'PricedCataloguedProduct',
    },
    __typename: 'CreatePricedCataloguedProductMutationPayload',
  },
}

export const CreatePricedCataloguedProduct = graphql.mutation<CreatePricedCataloguedProductMutation>(
  'CreatePricedCataloguedProduct',
  (_, res, ctx) => {
    return res(ctx.data(baseCreatePricedCataloguedProduct))
  }
)

export default baseCreatePricedCataloguedProduct
