import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Comment, GetPurchaseOrderCommentsDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { QueryResult } from '@/modules/shared/components'
import CommentSection from '@/modules/shared/components/comment-section/CommentSection'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { ControlBarIcon } from '@/modules/shared/icons'
import { Document, DocumentType } from '@/modules/shared/types'
import { extractEdges } from '@/modules/shared/utils'

function Comments() {
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>()
  const { t } = useTranslation()

  const { data, loading, refetch } = useQuery(GetPurchaseOrderCommentsDocument, {
    variables: { purchaseOrderId: Number(purchaseOrderId) },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const publicComments = extractEdges<Comment>(data?.currentPurchaser?.purchaseOrder?.publicComments)
  const privateComments = extractEdges<Comment>(data?.currentPurchaser?.purchaseOrder?.privateComments)

  return (
    <QueryResult loading={loading}>
      <div className="flex items-center gap-x-3" data-testid="purchase-order-comments">
        <ControlBarIcon className="h-10 w-10" />
        <p className="text-sm">
          {t('comments.title', '{{ id }} Comments', {
            id: data?.currentPurchaser?.purchaseOrder?.purchaseOrderNumber,
          })}
        </p>
      </div>
      <CommentSection
        publicComments={publicComments}
        privateComments={privateComments}
        docId={Number(purchaseOrderId)}
        type={DocumentType.PurchaseOrder}
        translate={Document.PurchaseOrder}
        refetch={refetch}
      />
    </QueryResult>
  )
}

export default Comments
