import { useQuery } from '@apollo/client'
import { Trans, useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useParams } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import TableView from './table-view/SupplierPublicProductsTableView'

import EmptyProductsImg from '@/assets/images/empty-products.svg'
import {
  GetSupplierDocument,
  PricedCataloguedProduct,
  Supplier,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { Spinner } from '@/modules/requisitions/components'
import { QueryResult } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import SearchInput from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { QueryParameter } from '@/modules/shared/types'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export default function SupplierPublicProducts() {
  const { t } = useTranslation()
  const { supplierId } = useParams<{ supplierId: string }>()
  const [searchTermURLParam] = useQueryParam(QueryParameter.Search, StringParam)

  const { data, fetchMore, error, networkStatus } = useQuery(GetSupplierDocument, {
    variables: {
      supplierId: Number(supplierId),
      searchText: searchTermURLParam || '',
    },
    context: { uri: PURCHASING_GRAPHQL_API },
    notifyOnNetworkStatusChange: true,
  })

  const products = extractEdges<PricedCataloguedProduct>(data?.supplier?.publicCataloguedProducts)
  const { setVariablesLoading, loading, fetchMoreLoading } = checkNetworkStatus(networkStatus)

  const onFetchMoreProducts = () => {
    fetchMore({
      variables: {
        after: data?.supplier?.publicCataloguedProducts?.pageInfo.endCursor,
      },
    })
  }

  return (
    <div className="flex flex-col gap-y-5">
      <div className="rounded-md bg-gray-200 px-5 py-3 text-sm">
        <Trans t={t} i18nKey="supplierDirectory.products.productInfo">
          The products and prices listed here are those that the Supplier has decided to make public. The Supplier may
          offer alternate pricing or many other products that are not listed here.
        </Trans>
      </div>
      <SearchInput placeholder={t('supplierDirectory.products.searchPlaceholder', 'Search by Product Name or Brand')} />
      <QueryResult loading={loading || setVariablesLoading} error={error}>
        <InfiniteScroll
          dataLength={products.length}
          next={onFetchMoreProducts}
          hasMore={!!data?.supplier?.publicCataloguedProducts?.pageInfo.hasNextPage}
          loader={fetchMoreLoading && <Spinner className="mt-5 h-14 md:w-16" />}
        >
          <TableView products={products} supplier={data?.supplier as Supplier} />
        </InfiniteScroll>
        {!setVariablesLoading && products.length === 0 && (
          <EmptyState
            message={t('supplierDirectory.products.emptyStateMessage', 'There are no products to display.')}
            img={EmptyProductsImg}
          />
        )}
      </QueryResult>
    </div>
  )
}
