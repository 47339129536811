import { graphql } from 'msw'

import { GetAllStockLocationsQuery } from '@/graphql/inventory/generated/inventory_graphql'

const baseGetAllStockLocations: GetAllStockLocationsQuery = {
  legalHolder: {
    id: 12,
    stockLocations: {
      edges: [
        {
          node: {
            id: 2162,
            name: 'Cafe101',
            balanceValue: 25795.96,
            __typename: 'StockLocation',
          },
          __typename: 'StockLocationEdge',
        },
        {
          node: {
            id: 3749,
            name: 'Executive Lounge',
            balanceValue: 14229.5678,
            __typename: 'StockLocation',
          },
          __typename: 'StockLocationEdge',
        },
        {
          node: {
            id: 23875,
            name: 'inventory test rail5',
            balanceValue: 0,
            __typename: 'StockLocation',
          },
          __typename: 'StockLocationEdge',
        },
        {
          node: {
            id: 23887,
            name: 'Johan',
            balanceValue: 0,
            __typename: 'StockLocation',
          },
          __typename: 'StockLocationEdge',
        },
        {
          node: {
            id: 399,
            name: 'Main Beverage Store',
            balanceValue: 30456.661,
            __typename: 'StockLocation',
          },
          __typename: 'StockLocationEdge',
        },
        {
          node: {
            id: 1246,
            name: 'Main Kitchen',
            balanceValue: 11735.8523,
            __typename: 'StockLocation',
          },
          __typename: 'StockLocationEdge',
        },
        {
          node: {
            id: 816,
            name: 'Minibar',
            balanceValue: 8059.5846,
            __typename: 'StockLocation',
          },
          __typename: 'StockLocationEdge',
        },
      ],
      pageInfo: {
        endCursor: 'MTQ',
        hasNextPage: false,
        __typename: 'PageInfo',
      },
      __typename: 'StockLocationConnection',
    },
    __typename: 'Holder',
  },
}

export const GetAllStockLocations = graphql.query<GetAllStockLocationsQuery>('GetAllStockLocations', (_, res, ctx) => {
  return res(ctx.data(baseGetAllStockLocations))
})

export default baseGetAllStockLocations
