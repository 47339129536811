import { graphql } from 'msw'

import { ApproveRequisitionMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const ApproveRequisition = graphql.mutation<ApproveRequisitionMutation>(
  'ApproveRequisition',
  (req, res, ctx) => {
    return res(
      ctx.data({
        approveRequisition: {
          errors: [],
          requisition: {
            id: 5235941,
            purchaserOrganisationId: 23783,
            state: 'approved',
            nextApprovingAgentsNames: '',
            purchaseOrders: {
              edges: [
                {
                  node: {
                    id: 3929,
                    sentDate: null,
                    purchaseOrderNumber: 'PO22932',
                    totalValue: 40.23,
                    status: 'Not Sent',
                    supplier: {
                      name: 'Supplier 1',
                      id: 48289,
                      __typename: 'Supplier',
                    },
                    __typename: 'PurchaseOrder',
                  },
                },
                {
                  node: {
                    id: 3123,
                    sentDate: '2022-11-04T16:22:34+11:00',
                    purchaseOrderNumber: 'PO543555',
                    totalValue: 82.12,
                    status: 'Sent',
                    supplier: {
                      name: 'Supplier 2',
                      id: 31233,
                      __typename: 'Supplier',
                    },
                    __typename: 'PurchaseOrder',
                  },
                },
                {
                  node: {
                    id: 5342,
                    sentDate: null,
                    purchaseOrderNumber: 'PO29992',
                    totalValue: 12.11,
                    status: 'Not Sent',
                    supplier: {
                      name: 'Supplier 3',
                      id: 53331,
                      __typename: 'Supplier',
                    },
                    __typename: 'PurchaseOrder',
                  },
                },
              ],
              __typename: 'PurchaseOrderConnection',
            },
            __typename: 'Requisition',
          },
          __typename: 'ApproveRequisitionMutationPayload',
        },
      })
    )
  }
)
