import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CatAndSubCat from './form-elements/CatAndSubCat'
import SaveFooter from './form-elements/SaveFooter'

import {
  SupplierRelationship,
  UpdateSupplierRelationshipDocument,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { DeptAndAccount } from '@/modules/requisitions/pages/create/form-elements'
import { Button, Tooltip } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { QuestionMarkIcon, TrashIcon } from '@/modules/shared/icons'
import { formModifiedValues } from '@/modules/shared/utils'
import { SupplierFormInputs } from '@/modules/suppliers/types'

interface SupplierFormsProps {
  supplierRelationship: SupplierRelationship
}

export default function SupplierForms({ supplierRelationship }: SupplierFormsProps) {
  const { t } = useTranslation()
  const [remount, setRemount] = useState(0)
  const [removeDeptAccountCode, setRemoveDeptAccountCode] = useState(false)
  const [removeDefaultCategory, setRemoveDefaultCategory] = useState(false)
  const formMethods = useForm<SupplierFormInputs>({
    defaultValues: {
      accountId: supplierRelationship?.account?.id,
      defaultCategoryId: supplierRelationship?.defaultCategory?.id,
      departmentId: supplierRelationship?.department?.id,
      supplierNumber: supplierRelationship?.supplierNumber || '',
    },
  })

  // Only watch these specific inputs
  const isFieldsModified = !!Object.keys(formMethods.formState.dirtyFields).find(
    (e) => e === 'defaultCategoryId' || e === 'accountId' || e === 'supplierNumber'
  )

  const [updateSupplier, { loading: updateSupplierLoading }] = useMutation(UpdateSupplierRelationshipDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const onSubmit: SubmitHandler<SupplierFormInputs> = (data) => {
    const dirtyFieldsKeys = Object.keys(formMethods.formState.dirtyFields)

    // Only submit the modified values
    const submitData = formModifiedValues(formMethods.formState.dirtyFields, data)

    // Clicking the trash icon and hit save changes immediately, it will remove the default deparment and account code
    // If clicking the trash icon and modify the default deparment and account code form, it will update default deparment and account code instead of removing it
    if (
      removeDeptAccountCode &&
      (!dirtyFieldsKeys.includes('departmentId') || !dirtyFieldsKeys.includes('accountId'))
    ) {
      submitData.departmentId = null
      submitData.accountId = null
    }

    // Clicking the trash icon and hit save changes immediately, it will remove the default category
    // If clicking the trash icon and modify the default category form, it will update the default category instead of removing it
    if (removeDefaultCategory && !dirtyFieldsKeys.includes('defaultCategoryId')) {
      submitData.defaultCategoryId = null
    }

    if (!removeDeptAccountCode && !dirtyFieldsKeys.includes('accountId')) {
      delete submitData.accountId
      delete submitData.departmentId
    }

    updateSupplier({
      variables: {
        input: {
          id: Number(supplierRelationship.id),
          ...submitData,
        },
      },
      onCompleted(data) {
        formMethods.reset({
          supplierNumber: data.updateSupplierRelationship.supplierRelationship?.supplierNumber || '',
        })
        setRemount(remount + 1)
        setRemoveDeptAccountCode(false)
        setRemoveDefaultCategory(false)
      },
    })
  }

  const onDiscardChanges = () => {
    setRemount(remount + 1)
    formMethods.reset()
    setRemoveDeptAccountCode(false)
    setRemoveDefaultCategory(false)
  }
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} className="flex flex-col gap-y-5">
        <section>
          <p className="font-bold">{t('general.supplierNumber', 'Supplier Number')}</p>
          <p className="mb-1 text-gray-500">
            {t(
              'mySuppliers.supplier.supplierNumberDescription',
              'The Supplier Number here is optional but can help people at your organisation identify this Supplier.'
            )}
          </p>
          <input
            data-testid="supplier-number-input"
            className="mt-2 w-full rounded-md border border-gray-200 p-3 text-sm outline-primary md:w-1/2"
            defaultValue={String(supplierRelationship.supplierNumber)}
            aria-label={t('general.supplierNumberInput', 'Supplier Number Input')}
            {...formMethods.register('supplierNumber')}
          />
        </section>
        <section>
          <h2 className="text-sm font-semibold">
            {t('mySuppliers.supplier.defaultDeptAndAcc', 'Default Department and Account Code')}
          </h2>
          <p className="text-sm text-gray-500">
            {t(
              'mySuppliers.supplier.defaultDeptAndAccDescription',
              'The default Department and Account Code is optional but can be used to pre-fill the Department and Account Code fields when a Purchase Requisition is created for this Supplier by users at your organisation.'
            )}
          </p>

          <section className="mt-4 space-y-3">
            {removeDeptAccountCode ||
              ((supplierRelationship.department || supplierRelationship.account) && (
                <section
                  className="flex w-full items-center justify-between rounded-md border border-gray-300 bg-gray-200 p-3"
                  data-testid="department-account-code"
                >
                  {`${supplierRelationship.department?.name || ''}  ${
                    supplierRelationship.account?.accountName ? `> ${supplierRelationship.account?.accountName}` : ''
                  }`}
                  <div className="flex items-center gap-x-1 text-gray-500">
                    <span>{t('general.currentlySet', 'Currently Set')}</span>
                    <Tooltip
                      content={t(
                        'mySuppliers.supplier.deptAccountCurrentlySetTooltip',
                        'The Department and Account Code currently set for this Supplier'
                      )}
                    >
                      <QuestionMarkIcon className="h-4 w-4" />
                    </Tooltip>
                    <Tooltip
                      content={t(
                        'mySuppliers.supplier.removeDeptAccountCurrentlySetTooltip',
                        'Remove the currently set Department and Account Code'
                      )}
                    >
                      <Button
                        type="button"
                        data-testid="remove-dept-account"
                        className="flex items-center justify-center"
                        onClick={() => setRemoveDeptAccountCode(true)}
                      >
                        <TrashIcon className="h-7 w-7" />
                      </Button>
                    </Tooltip>
                  </div>
                </section>
              ))}
            <DeptAndAccount
              key={remount}
              showExplanation={false}
              required={false}
              title={t('createRequisition.deptAndAccount.title', 'Department and Account Code')}
              description={t(
                'createRequisition.deptAndAccount.explain',
                'If applicable, select a Department then an Account code to record this Requisition against. Your organisation may use Department and Account Codes for recording Requisition expenses.'
              )}
            />
          </section>
        </section>
        <CatAndSubCat
          relationship={supplierRelationship as SupplierRelationship}
          key={remount}
          setRemoveDefaultCategory={setRemoveDefaultCategory}
          removeDefaultCategory={removeDefaultCategory}
        />
        {(isFieldsModified || removeDeptAccountCode || removeDefaultCategory) && (
          <SaveFooter onDiscardChanges={onDiscardChanges} loading={updateSupplierLoading} />
        )}
      </form>
    </FormProvider>
  )
}
