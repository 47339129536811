import { useQuery } from '@apollo/client'

import {
  GetSupplierRelationshipDetailsDocument,
  SupplierRelationship,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useGetSupplierRelationShopDetails(supplierId: string) {
  const { data, loading, error } = useQuery(GetSupplierRelationshipDetailsDocument, {
    variables: {
      id: Number(supplierId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  return {
    supplierRelationship: data?.currentPurchaser?.supplierRelationship || ({} as SupplierRelationship),
    loading,
    error,
  }
}
