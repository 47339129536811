import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useParams } from 'react-router-dom'

import Details from '../../requisition/page-header/Details'
import CartActions from './CartActions'
import CartHeader from './CartHeader'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import { GetRequisitionDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ShopHeader } from '@/modules/requisitions/layouts'
import { QueryResult } from '@/modules/shared/components'
import HeaderTabs, { TabItem } from '@/modules/shared/components/header-tabs'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { findActiveRoute } from '@/modules/shared/utils'

function PageHeader() {
  const { t } = useTranslation()
  const { requisitionId } = useParams<{ requisitionId: string }>()
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const location = useLocation()

  const { data, loading } = useQuery(GetRequisitionDocument, {
    variables: { requisitionId: Number(requisitionId) },
    context: { uri: PURCHASING_GRAPHQL_API },
  })
  const actionList: TabItem[] = [
    { target: 'summary', label: t('general.productSummary', 'Product Summary') },
    { target: 'settings', label: t('general.settings', 'Settings') },
    {
      target: 'comments',
      label: t('general.comments', 'Comments'),
      count: Number(data?.currentPurchaser?.requisition?.commentsCount),
    },
    { target: 'attachments', label: t('general.attachments', 'Attachments') },
  ]

  const renderTitle = () => {
    const targetArray = location.pathname.split('/')
    if (targetArray[targetArray.length - 1] === 'summary') {
      return t('general.viewCartSummary', 'View Cart Summary')
    }
    return findActiveRoute(actionList, location.pathname)
  }

  return (
    <>
      <Helmet>
        <title>{`${data?.currentPurchaser?.requisition?.requisitionNumber || ''} ${renderTitle()}`}</title>
      </Helmet>
      <ShopHeader isCart />
      <QueryResult loading={loading}>
        <div className="mx-auto max-w-[95rem] space-y-5 p-4">
          <section className="flex flex-col gap-y-2">
            <CartHeader showInfo={showInfo} setShowInfo={setShowInfo} />
            {showInfo && (
              <Details requisition={data?.currentPurchaser?.requisition as Requisition} hidePurchaseOrders />
            )}
            <CartActions requisition={data?.currentPurchaser?.requisition as Requisition} />
            <HeaderTabs actionList={actionList} />
          </section>
          <Outlet context={{ readonly: false }} />
        </div>
      </QueryResult>
    </>
  )
}

export default PageHeader
