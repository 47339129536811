import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import DraftCardItem from './DraftCardItem'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'

interface TileViewProps {
  draftList: Requisition[]
}

function TileView(props: TileViewProps) {
  const { draftList } = props
  const { t } = useTranslation()

  if (draftList?.length === 0) {
    return (
      <div className="mt-5 rounded-md border py-8 text-center text-sm" data-testid="dashboard-no-drafts">
        {t('dashboard.draftRequisitions.noDrafts', "You don't have any Draft Requisitions")}
      </div>
    )
  }

  return (
    <>
      {draftList.map((item, index) => (
        <DraftCardItem key={index} item={item} />
      ))}
    </>
  )
}

export default memo(TileView)
