import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'

interface PRLineTaxValueProps {
  requisitionLine: RequisitionLine
  availableQuotes?: boolean
}

export default function PRLineTaxValue({ requisitionLine, availableQuotes = true }: PRLineTaxValueProps) {
  const { unitTax } = requisitionLine
  const { formatMoney } = useMoney()

  return (
    <Fallback condition={!!availableQuotes}>
      <strong>{formatMoney(unitTax)}</strong>
    </Fallback>
  )
}
