import { graphql } from 'msw'

import { CreateRequisitionLineMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseCreateRequisitionLine: CreateRequisitionLineMutation = {
  createRequisitionLine: {
    requisition: {
      id: 5235941,
      totalValue: 11.99,
      totalValueExcludingTax: 11.99,
      lines: [
        {
          id: 26770369,
          productId: 5814401,
          quantity: 1.0,
          __typename: 'RequisitionLine',
          cataloguedProductId: 5841814,
        },
      ],
      requisitionLinesBySuppliers: [
        {
          supplier: { id: 67087, name: 'AAPC Limited', __typename: 'Supplier' },
          total: 23.98,
          totalTax: 0,
          lines: [
            {
              id: 26770369,
              quantity: 1.0,
              unitPrice: 11.99,
              productId: 5814401,
              cataloguedProductId: 5841814,
              orderInstruction: null,
              department: null,
              account: null,
              tax: 0,
              total: 23.98,
              __typename: 'RequisitionLine',
            },
          ],
          __typename: 'RequisitionLinesBySupplier',
        },
      ],
      __typename: 'Requisition',
    },
    errors: [],
    __typename: 'CreateRequisitionLineMutationPayload',
  },
}

export const CreateRequisitionLine = graphql.mutation<CreateRequisitionLineMutation>(
  'CreateRequisitionLine',
  (_, res, ctx) => {
    return res(ctx.data(baseCreateRequisitionLine))
  }
)

export default baseCreateRequisitionLine
