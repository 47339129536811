import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import CancelRequisitionButton from '@/modules/requisitions/components/cancel-requisition-button/CancelRequisitionButton'
import { useCheckShoppingAvailable } from '@/modules/requisitions/hooks'
import { generateShopPath } from '@/modules/requisitions/utils'
import { MoreOptionsMenu, PPNewDetailLink, ThemeWrapper, Tooltip } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { CartIcon, ChevronRightIcon, InfoIcon, OptionIcon, ShoppingCartIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface DraftRequisitionRowProps {
  requisition: Requisition
  showActions?: boolean
}

export default function DraftRequisitionRow({ requisition, showActions }: DraftRequisitionRowProps) {
  const { totalValue, requisitionNumber, reference, id, advanced } = requisition || {}
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const { ShoppingNotAvailableModal, modalProps, onShowModal, onCheckShoppingAvailable } = useCheckShoppingAvailable()

  return (
    <div className="flex w-full items-center justify-between gap-x-2">
      <div className="flex w-3/4 lg:w-1/2">
        <ThemeWrapper theme={Themes.Primary} className="flex h-14 w-14 shrink-0 items-center justify-center rounded-md">
          <CartIcon className="h-10 w-10" />
        </ThemeWrapper>
        <div className="ml-4 flex flex-col justify-center">
          <p className="h-5 text-sm font-semibold sm:text-base">{formatMoney(totalValue)}</p>
          <p className="text-xs text-gray-500 sm:text-sm">{`${requisitionNumber} ${reference || ''}`}</p>
        </div>
      </div>
      {showActions && (
        <div className="flex w-1/2 items-center justify-end gap-x-2 text-sm">
          <Tooltip content={t('general.resumeShopping', 'Resume Shopping')} delay={100}>
            <Link
              data-testid="resume-button"
              to={generateShopPath(id, advanced)}
              onClick={(e) => {
                if (!onCheckShoppingAvailable(requisition)) {
                  e.preventDefault()
                  onShowModal()
                }
              }}
              className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200 text-gray-600 transition hover:brightness-95"
            >
              <ShoppingCartIcon className="h-8 w-8" />
            </Link>
          </Tooltip>
          <Tooltip content={t('general.viewRequisition', 'View Requisition')} delay={100}>
            <PPNewDetailLink
              type="requisition"
              id={String(id)}
              className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200 text-gray-600 transition hover:brightness-95"
              testId="summary-button"
            >
              <InfoIcon className="h-8 w-8" />
            </PPNewDetailLink>
          </Tooltip>
          <CancelRequisitionButton requisitionId={id} />
          <MoreOptionsMenu buttonTheme="dark" showOpenInPPlus requisitionId={id} showHelp>
            <OptionIcon className="h-8 w-8" />
          </MoreOptionsMenu>
        </div>
      )}
      <ChevronRightIcon className="h-6 w-6 shrink-0 text-gray-400 sm:hidden" />
      <ShoppingNotAvailableModal {...modalProps} />
    </div>
  )
}
