import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Catalogue, Product } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, Modal, ProductSummary } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { ENTERPRISE_SUPPLIER_CATALOGS_URL } from '@/modules/shared/constants'
import { NewTabIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

interface RelatedCataloguesModalProps {
  product: Product
  showRelatedCataloguesModal: boolean
  setShowRelatedCataloguesModal: Dispatch<SetStateAction<boolean>>
}

export default function RelatedCataloguesModal({
  showRelatedCataloguesModal,
  setShowRelatedCataloguesModal,
  product,
}: RelatedCataloguesModalProps) {
  const { t } = useTranslation()
  const { catalogues } = product
  const cataloguesList = extractEdges<Catalogue>(catalogues)
  const onCloseModal = () => setShowRelatedCataloguesModal(false)

  const renderCatalogList = ({ id, title }: Catalogue) => (
    <a
      className="flex items-center gap-x-2 text-primary"
      href={`${ENTERPRISE_SUPPLIER_CATALOGS_URL}/${id}`}
      target="_blank"
      rel="noreferrer"
    >
      <span>{title}</span>
      <NewTabIcon className="h-4 w-4" />
    </a>
  )

  return (
    <Modal showModal={showRelatedCataloguesModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        data-testid="product-details-modal"
        className="flex h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]"
      >
        <Modal.Title title={t('general.catalogs', 'Catalogs')} onCloseModal={onCloseModal} />
        <Modal.Body className="flex flex-col gap-y-4 overflow-y-scroll">
          <ProductSummary product={product} unitPrice={null} taxPercentage={null} showPriceAndTaxPercentage={false} />
          <div>
            <strong>{t('general.catalogList', 'Catalog List')}</strong>
            <p className="text-gray-700">
              {t(
                'general.catalogListDescription',
                'Catalog ListBelow are all of the Catalogs that this Product is currently added to, as well as the Unit Price and Tax Percentage in each Catalog. Click to open the Catalog.'
              )}
            </p>
          </div>
          <Table
            dataSource={cataloguesList}
            keyExtractor={(record) => String(record.id)}
            columns={[
              {
                title: t('general.catalogLabel', 'Catalog'),
                key: 'buyList',
                headerCellStyles: 'px-4 py-3',
                cellStyles: 'px-4 py-3',
                render: renderCatalogList,
              },
            ]}
          />
        </Modal.Body>
        <Modal.Footer className="flex justify-end">
          <Button className="h-11 w-full rounded-md bg-gray-200 px-8 text-sm md:w-fit" onClick={onCloseModal}>
            {t('general.close', 'Close')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
