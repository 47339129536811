import { Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'

export function formatSupplierPostalAddress(supplier: Supplier): string {
  let {
    postalAddressLine1,
    postalAddressLine2,
    postalAddressCity,
    postalAddressStateProvince,
    postalAddressPostalCode,
  } = supplier || {}

  const arr = [
    postalAddressLine1,
    postalAddressLine2,
    postalAddressCity,
    postalAddressStateProvince,
    postalAddressPostalCode,
  ] as string[]

  return arr
    .filter((e) => Boolean(e))
    .reduce((acc, curr) => `${acc} ${curr}`, '')
    .trim()
}
