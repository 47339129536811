import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import BalanceContainerRow from '../../invoice/page-header/balance-panel/BalanceContainerRow'
import CreditNoteLinesTableView from './table-view/CreditNoteLinesTableView'

import { CreditNoteLine, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useGetCreditNote } from '@/modules/invoices/hooks'
import SupplierDetailsModal from '@/modules/requisitions/pages/view-cart/summary/SupplierDetailsModal'
import { useMoney } from '@/modules/shared/hooks'
import { ControlBarIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

export default function CreditNoteSummary() {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const { creditNote } = useGetCreditNote()
  const [openSupplierModal, setOpenSupplierModal] = useState<number | null>(null)

  const creditNoteLines = extractEdges<CreditNoteLine>(creditNote?.creditNoteLines)
  const linesTotal = creditNoteLines.reduce((acc, curr) => acc + (curr.lineTotal || 0), 0)
  const linesTotalTax = creditNoteLines.reduce((acc, curr) => acc + (curr.lineTax || 0), 0)

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <ControlBarIcon className=" h-10 w-10" />
          <p className="ml-3 text-sm">
            {creditNote?.creditNoteNumber} {t('general.productSummary', 'Product Summary')}
          </p>
        </div>
      </div>
      <section className="mt-4 flex flex-col gap-y-12" data-testid="credit-note-info">
        <div className="flex flex-col gap-y-3">
          <div className="flex justify-between border bg-white px-5 py-3">
            <strong className="text-sm">{creditNote?.supplier?.name}</strong>
            <p
              onClick={() => setOpenSupplierModal(1)}
              className="cursor-pointer text-sm text-primary"
              data-testid="supplier-details-button"
            >
              {t('general.viewSupplierDetails', 'View Supplier Details')}
            </p>
          </div>
          <CreditNoteLinesTableView creditNoteLines={creditNoteLines} supplier={creditNote?.supplier as Supplier} />
          {creditNoteLines.length === 0 && (
            <div className="rounded-md border border-gray-200 py-8 text-center text-gray-600">
              {t('creditNotes.creditNote.noLines', 'There are no Lines on this Credit Note.')}
            </div>
          )}
          <div className="border bg-white">
            <div className="flex flex-col gap-3 p-3 text-sm" data-testid="credit-note-payment">
              <BalanceContainerRow
                title={t('creditNotes.creditNote.creditNoteLinesTotalExTax', 'Credit Note Lines Total (Excluding Tax)')}
                value={linesTotal - linesTotalTax}
              />
              <BalanceContainerRow
                title={t('creditNotes.creditNote.creditNoteLinesTax', 'Credit Note Lines Total Tax')}
                value={linesTotalTax}
              />
              <BalanceContainerRow
                title={t('general.deliveryFeesExTax', 'Delivery Fees (Excluding Tax)')}
                value={creditNote?.deliveryChargeExTax}
              />
              <BalanceContainerRow
                title={t('general.deliveryFeesTax', 'Delivery Fees Tax')}
                value={creditNote?.deliveryChargeTax}
              />
              {(!!creditNote?.adjustmentExTax || !!creditNote?.adjustmentTax) && (
                <>
                  <BalanceContainerRow
                    title={t('general.adjustmentsExcludingTaxBrackets', 'Adjustments (Excluding Tax)')}
                    value={creditNote.adjustmentExTax}
                  />
                  <BalanceContainerRow
                    title={t('general.adjustmentsTax', 'Adjustments Tax')}
                    value={creditNote.adjustmentTax}
                  />
                </>
              )}
            </div>
            <hr />
            <div className="flex flex-col gap-3 p-3 text-sm">
              <BalanceContainerRow
                title={t('general.creditNoteTotalExTax', 'Credit Note Total (Excluding Tax)')}
                value={(creditNote?.totalValue || 0) - (creditNote?.totalTaxValue || 0)}
              />
              <BalanceContainerRow
                title={t('general.creditNoteTotalTax', 'Credit Note Total Tax')}
                value={creditNote?.totalTaxValue}
              />
              <div className="flex items-center justify-between px-2 text-lg font-bold">
                <p>{t('general.crediNoteTotal', 'Credit Note Total')}</p>
                <p>{formatMoney(creditNote?.totalValue)}</p>
              </div>
            </div>
          </div>
          <SupplierDetailsModal
            supplier={creditNote?.supplier as Supplier}
            showModal={!!openSupplierModal}
            setShowModal={setOpenSupplierModal}
          />
        </div>
      </section>
    </>
  )
}
