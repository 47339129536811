import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { useGetRequisitionAwaitingApprovalCount } from '../../hooks'
import ListView from './approval-list/list-view'
import RequisitionsAwaitingApprovalTableView from './approval-list/table-view/RequisitionsAwaitingApprovalTableView'

import EmptyStateImg from '@/assets/images/empty-requisitions-stars.svg'
import {
  GetRequisitionsAwaitingApprovalDocument,
  PageInfo,
  Requisition,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { Breadcrumb, QueryResult, ThemeWrapper, displayBadgeCount } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import SearchAndFilter, { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { ClockIcon, HomeIcon } from '@/modules/shared/icons'
import { QueryParameter, Themes } from '@/modules/shared/types'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export default function AwaitingMyApproval() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()
  const [loadingType, setLoadingType] = useState<string | null>(null)

  const paginationResultsPerPage = 20

  const { count } = useGetRequisitionAwaitingApprovalCount()

  const { networkStatus, error, data, refetch } = useQuery(GetRequisitionsAwaitingApprovalDocument, {
    variables: {
      first: paginationResultsPerPage,
      after: null,
      filter: {},
    },
    fetchPolicy: 'cache-and-network',
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const { setVariablesLoading, loading, refetchLoading } = checkNetworkStatus(networkStatus)

  const onFilter = ({ searchValue }: OnFilter) => {
    setLoadingType('filter')
    refetch({
      filter: {
        q: [
          {
            property: 'requisitionNumber_or_reference_cont',
            value: searchValue.trim(),
          },
        ],
      },
      first: paginationResultsPerPage, // Reset the pagination to the first page
      after: null, // Reset the pagination to the first page
    })
  }

  const onPreviousPage = () => {
    setLoadingType('onPreviousPage')
    const { startCursor } = data?.currentPurchaser?.requisitionsAwaitingMyApproval?.pageInfo || {}
    refetch({
      before: startCursor,
      after: null,
      last: paginationResultsPerPage,
      first: null,
    })
  }

  const onNextPage = () => {
    setLoadingType('onNextPage')
    const { endCursor } = data?.currentPurchaser?.requisitionsAwaitingMyApproval?.pageInfo || {}
    refetch({
      after: endCursor,
      before: null,
      first: paginationResultsPerPage,
      last: null,
    })
  }

  const approvalList = extractEdges<Requisition>(data?.currentPurchaser?.requisitionsAwaitingMyApproval)

  return (
    <>
      <Helmet>
        <title>{t('general.requisitionsAwaitingMyApproval', 'Requisitions Awaiting My Approval')}</title>
      </Helmet>

      <main className="mx-auto space-y-5 px-4">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="/requisitions">{t('general.requisitions', 'Requisitions')}</Breadcrumb.Item>
          <Breadcrumb.Item>{t('general.awaitingMyApproval', 'Awaiting My Approval')}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="flex rounded-md bg-white p-4 shadow" data-testid="awaiting-my-approval-header">
          <div className="flex items-center">
            <ThemeWrapper theme={Themes.Primary} className="flex h-14 w-14 items-center justify-center rounded-md">
              <ClockIcon className="h-10 w-10 " />
            </ThemeWrapper>
            <span className="ml-3">
              <p className="font-bold">
                {t('requisition.numberOfPRsAwaiting', '{{ number }} Requisitions', {
                  number: displayBadgeCount(count, 25),
                })}
              </p>
              <p className="text-sm text-gray-500">{t('general.awaitingMyApproval', 'Awaiting My Approval')}</p>
            </span>
          </div>
        </section>
        <SearchAndFilter
          testId="awaiting-my-approval-search-input"
          placeholder={t('allRequisitions.searchPlaceholder', 'Search by PR Number or Reference')}
          ariaLabel={t('general.searchingForRequisitions', 'Searching for requisitions')}
          onFilter={onFilter}
          queryParamFilterType={QueryParameter.Status}
        />
        <QueryResult
          loading={loading || refetchLoading || (setVariablesLoading && loadingType === 'filter')}
          error={error}
        >
          <section>
            {isLargeScreen ? (
              <RequisitionsAwaitingApprovalTableView
                approvalList={approvalList}
                pageInfo={data?.currentPurchaser?.requisitionsAwaitingMyApproval?.pageInfo as PageInfo}
                paginationLoading={
                  setVariablesLoading && ['onPreviousPage', 'onNextPage'].includes(String(loadingType))
                }
                onNextPage={onNextPage}
                onPreviousPage={onPreviousPage}
              />
            ) : (
              <ListView approvalList={approvalList} />
            )}
            {approvalList.length === 0 && (
              <EmptyState
                img={EmptyStateImg}
                title={t('general.allDone', 'All Done!')}
                message={t('requisition.noPRsRemaining', 'There are no Requisitions awaiting your approval.')}
              />
            )}
          </section>
        </QueryResult>
      </main>
    </>
  )
}
