export default function HomeIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.6451 7.28001L11.6555 2.80921C11.2006 2.40127 10.611 2.17566 9.99994 2.17566C9.38887 2.17566 8.79931 2.40127 8.34434 2.80921V2.80921L3.35474 7.28001C3.09524 7.51282 2.88765 7.79765 2.74548 8.11597C2.60331 8.43429 2.52973 8.77898 2.52954 9.12761V17.0476C2.52954 17.2545 2.61172 17.4529 2.758 17.5991C2.90428 17.7454 3.10267 17.8276 3.30954 17.8276H7.98114C8.18801 17.8276 8.38641 17.7454 8.53268 17.5991C8.67896 17.4529 8.76114 17.2545 8.76114 17.0476V13.6348C8.76195 13.6014 8.77555 13.5696 8.79915 13.5459C8.82275 13.5222 8.85453 13.5085 8.88794 13.5076H11.1119C11.1453 13.5085 11.1771 13.5222 11.2007 13.5459C11.2243 13.5696 11.2379 13.6014 11.2387 13.6348V17.0452C11.2387 17.2521 11.3209 17.4505 11.4672 17.5967C11.6135 17.743 11.8119 17.8252 12.0187 17.8252H16.6903C16.8972 17.8252 17.0956 17.743 17.2419 17.5967C17.3882 17.4505 17.4703 17.2521 17.4703 17.0452V9.12721C17.4701 8.77864 17.3965 8.43404 17.2543 8.11579C17.1121 7.79754 16.9046 7.51277 16.6451 7.28001V7.28001ZM15.9103 16.2652H12.7999V13.6348C12.7994 13.1876 12.6215 12.7588 12.3053 12.4425C11.9891 12.1262 11.5604 11.9482 11.1131 11.9476H8.88794C8.44049 11.9479 8.01145 12.1258 7.69498 12.4421C7.37851 12.7584 7.20047 13.1874 7.19994 13.6348V16.2652H4.08954V9.12721C4.0893 8.99754 4.11647 8.86928 4.16928 8.75085C4.22208 8.63242 4.29932 8.52649 4.39594 8.44001L9.38554 3.97081C9.55435 3.81935 9.77315 3.73558 9.99994 3.73558C10.2267 3.73558 10.4455 3.81935 10.6143 3.97081L15.6039 8.44001C15.7004 8.52631 15.7775 8.63198 15.8303 8.75012C15.8831 8.86826 15.9104 8.99621 15.9103 9.12561V16.2652Z"
        fill="currentColor"
      />
    </svg>
  )
}
