import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import SupplierForms from './SupplierForms'
import SupplierRightDetails from './SupplierRightDetails'

import {
  GetSupplierRelationshipDocument,
  SupplierRelationship,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function SupplierDetails() {
  const { t } = useTranslation()
  const { supplierId } = useParams<{ supplierId: string }>()

  const { data, loading, error } = useQuery(GetSupplierRelationshipDocument, {
    variables: {
      id: Number(supplierId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const relationship = data?.currentPurchaser?.supplierRelationship

  return (
    <>
      <QueryResult loading={loading} error={error}>
        <div className="flex flex-col justify-between gap-10 xl:flex-row">
          <section className="flex w-full flex-col gap-y-5 text-sm xl:w-[52.5rem]">
            <section>
              <div className="my-3 flex justify-between">
                <p className="text-lg font-bold">{t('general.supplierDetails', 'Supplier Details')}</p>
              </div>
              <hr />
            </section>
            <section data-testid="supplier-status">
              <p className="font-bold">
                {relationship?.isEnabled
                  ? t('general.tradingEnabled', 'Trading Enabled')
                  : t('general.tradingDisabled', 'Trading Disabled')}
              </p>
              <p className="text-gray-500">
                {relationship?.isEnabled
                  ? t(
                      'mySuppliers.supplier.trueEnabledBody',
                      'Your trading relationship with Zeus Wholesale is currently Enabled, meaning that you can place orders with this Supplier. The Supplier can assign catalogues, products and prices to your organisation, and you can add these to your Cart when creating a Requisition. Speak with an administrator at your organisation if you wish to disable this Supplier.'
                    )
                  : t(
                      'mySuppliers.supplier.falseEnabledBody',
                      "Your trading relationship with Zeus Wholesale is currently Disabled. This means that you can't place orders with this Supplier at the moment. Speak with an administrator at your organisation or contact our friendly customer success team if you wish to have your trading relationship with this Supplier re-enabled."
                    )}
              </p>
              <Button
                className={classNames('mt-3 rounded-md px-4 py-3 text-white', {
                  'bg-success': relationship?.isEnabled,
                  'bg-error': !relationship?.isEnabled,
                })}
              >
                {relationship?.isEnabled ? t('general.enabled', 'Enabled') : t('general.disabled', 'Disabled')}
              </Button>
            </section>
            <SupplierForms supplierRelationship={relationship as SupplierRelationship} />
          </section>
          <SupplierRightDetails supplierRelationShip={relationship as SupplierRelationship} />
        </div>
      </QueryResult>
    </>
  )
}
