import { useTranslation } from 'react-i18next'
import { BooleanParam, useQueryParam } from 'use-query-params'

import Checkbox from '../../checkbox'
import BestBuy from '../../product-list/BestBuy'
import Tooltip from '../../tooltip'

import AdvancedSearchFilter from '@/modules/shared/components/advanced-search/filters-section/AdvancedSearchFilter'
import { QuestionMarkIcon } from '@/modules/shared/icons'
import { QueryParameter } from '@/modules/shared/types'

interface AdvancedSearchMoreOptionsProps {
  showProductOptions?: boolean
}

interface OptionProps {
  label: string
  tooltip: string
  checked: boolean
  onChange: (value: boolean | undefined) => void
}

export default function AdvancedSearchMoreOptions({ showProductOptions }: AdvancedSearchMoreOptionsProps) {
  const { t } = useTranslation()
  const [hasQuotesURLParam, setHasQuotesURLParam] = useQueryParam(QueryParameter.HasQuotes, BooleanParam)
  const [masterURLParam, setMasterURLParam] = useQueryParam(QueryParameter.Master, BooleanParam)

  const Option = ({ label, tooltip, checked, onChange }: OptionProps) => {
    return (
      <div
        className="flex cursor-pointer items-center"
        onClick={() => {
          if (!!checked) onChange(undefined)
          else onChange(true)
        }}
      >
        <span className="ml-1 mr-2" data-testid="best-buy-button">
          <Checkbox isSelected={!!checked} defaultSelected={!!checked} aria-label={label} />
        </span>
        <p className="text-sm">{label}</p>
        <Tooltip content={tooltip}>
          <span className="ml-1 flex items-center">
            <QuestionMarkIcon className="inline w-5" />
          </span>
        </Tooltip>
      </div>
    )
  }

  return (
    <AdvancedSearchFilter buttonTestId="more-options-section" buttonTitle={t('general.moreOptions', 'More Options')}>
      {showProductOptions ? (
        <>
          <Option
            label={t('buyLists.builder.hasQuotes', 'Only Products with Quotes')}
            tooltip={t(
              'buyLists.builder.hasQuotesTooltip',
              'Show only those products that have at least one quote from your Suppliers.'
            )}
            checked={!!hasQuotesURLParam}
            onChange={setHasQuotesURLParam}
          />
          <Option
            label={t('buyLists.builder.master', 'Only P+ Master Products')}
            tooltip={t('buyLists.builder.masterTooltip', 'Show only those Products that are P+ Master Products.')}
            checked={!!masterURLParam}
            onChange={setMasterURLParam}
          />
        </>
      ) : (
        <BestBuy reverse />
      )}
    </AdvancedSearchFilter>
  )
}
