import { graphql } from 'msw'

import { GetAllRequisitionsQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetAllRequisitions: GetAllRequisitionsQuery = {
  currentPurchaser: {
    id: 44926,
    requisitions: {
      edges: [
        {
          node: {
            account: null,
            sourceApplication: 'ppnew',
            editableForCurrentUser: true,
            approvalWorkflow: { id: 615, title: 'General Workflow', __typename: 'RequisitionApprovalWorkflow' },
            createdAt: '2022-10-26T13:15:11+11:00',
            creator: { id: 23784, fullName: 'Chris Dat Truong', __typename: 'User' },
            department: null,
            expectedDeliveryDate: '2022-11-21',
            id: 5271350,
            nextApprovingAgentsNames: '',
            originName: null,
            reference: null,
            requisitionNumber: 'PR01111',
            sendToSupplier: true,
            state: 'open',
            totalTaxValue: 0,
            totalValue: 111,
            totalValueExcludingTax: 0,
            advanced: false,
            __typename: 'Requisition',
          },
          __typename: 'RequisitionEdge',
        },
        {
          node: {
            account: null,
            sourceApplication: 'ppnew',
            editableForCurrentUser: true,
            approvalWorkflow: { id: 615, title: 'General Workflow', __typename: 'RequisitionApprovalWorkflow' },
            createdAt: '2022-10-26T13:15:11+11:00',
            creator: { id: 23784, fullName: 'Chris Dat Truong', __typename: 'User' },
            department: null,
            expectedDeliveryDate: '2022-11-22',
            id: 5271349,
            nextApprovingAgentsNames: '',
            originName: null,
            reference: null,
            requisitionNumber: 'PR02222',
            sendToSupplier: true,
            state: 'awaiting_approval',
            totalTaxValue: 0,
            totalValue: 222,
            totalValueExcludingTax: 0,
            advanced: false,
            __typename: 'Requisition',
          },
          __typename: 'RequisitionEdge',
        },
        {
          node: {
            account: null,
            sourceApplication: 'ppnew',
            editableForCurrentUser: true,
            approvalWorkflow: { id: 615, title: 'General Workflow', __typename: 'RequisitionApprovalWorkflow' },
            createdAt: '2022-10-26T13:15:11+11:00',
            creator: { id: 23784, fullName: 'Chris Dat Truong', __typename: 'User' },
            department: null,
            expectedDeliveryDate: '2022-11-23',
            id: 5271066,
            nextApprovingAgentsNames: '',
            originName: null,
            reference: null,
            requisitionNumber: 'PR03333',
            sendToSupplier: true,
            state: 'approved',
            totalTaxValue: 0,
            totalValue: 333,
            totalValueExcludingTax: 0,
            advanced: false,
            __typename: 'Requisition',
          },
          __typename: 'RequisitionEdge',
        },
        {
          node: {
            account: null,
            sourceApplication: 'ppnew',
            editableForCurrentUser: true,
            approvalWorkflow: { id: 615, title: 'General Workflow', __typename: 'RequisitionApprovalWorkflow' },
            createdAt: '2022-10-26T13:15:11+11:00',
            creator: { id: 23784, fullName: 'Chris Dat Truong', __typename: 'User' },
            department: null,
            expectedDeliveryDate: '2022-11-24',
            id: 5270780,
            nextApprovingAgentsNames: '',
            originName: null,
            reference: null,
            requisitionNumber: 'PR04444',
            sendToSupplier: true,
            state: 'declined',
            totalTaxValue: 0,
            totalValue: 444,
            totalValueExcludingTax: 0,
            advanced: false,
            __typename: 'Requisition',
          },
          __typename: 'RequisitionEdge',
        },
        {
          node: {
            account: null,
            sourceApplication: 'ppnew',
            editableForCurrentUser: true,
            approvalWorkflow: { id: 615, title: 'General Workflow', __typename: 'RequisitionApprovalWorkflow' },
            createdAt: '2022-10-26T13:15:11+11:00',
            creator: { id: 23784, fullName: 'Chris Dat Truong', __typename: 'User' },
            department: null,
            expectedDeliveryDate: '2022-11-25',
            id: 5271067,
            nextApprovingAgentsNames: '',
            originName: null,
            reference: null,
            requisitionNumber: 'PR05555',
            sendToSupplier: true,
            state: 'closed',
            totalTaxValue: 0,
            totalValue: 555,
            totalValueExcludingTax: 0,
            advanced: false,
            __typename: 'Requisition',
          },
          __typename: 'RequisitionEdge',
        },
        {
          node: {
            account: null,
            sourceApplication: 'ppnew',
            editableForCurrentUser: true,
            approvalWorkflow: { id: 615, title: 'General Workflow', __typename: 'RequisitionApprovalWorkflow' },
            createdAt: '2022-10-26T13:15:11+11:00',
            creator: { id: 23784, fullName: 'Chris Dat Truong', __typename: 'User' },
            department: null,
            expectedDeliveryDate: '2022-11-26',
            id: 5271466,
            nextApprovingAgentsNames: '',
            originName: null,
            reference: null,
            requisitionNumber: 'PR06666',
            sendToSupplier: true,
            state: 'cancelled',
            totalTaxValue: 0,
            totalValue: 666,
            totalValueExcludingTax: 0,
            advanced: false,
            __typename: 'Requisition',
          },
          __typename: 'RequisitionEdge',
        },
      ],
      pageInfo: {
        startCursor: 'MkU',
        endCursor: 'MjU',
        hasNextPage: false,
        hasPreviousPage: false,
        __typename: 'PageInfo',
      },
      __typename: 'RequisitionConnection',
    },
    __typename: 'Purchaser',
  },
}

export const GetAllRequisitions = graphql.query<GetAllRequisitionsQuery>('GetAllRequisitions', (_, res, ctx) => {
  return res(ctx.data(baseGetAllRequisitions))
})

export default baseGetAllRequisitions
