import { Navigate, generatePath, useLocation, useParams } from 'react-router-dom'

import Confirmation from '@/modules/requisitions/pages/confirmation'
import { ConfirmationVariant } from '@/modules/requisitions/types'

export default function SuccessAction() {
  const location = useLocation()
  const { requisitionId } = useParams<{ requisitionId: string }>()
  const locationState = location.state as {
    requisitionPageStatus: string
  }
  const requisitionPageStatus = locationState?.requisitionPageStatus

  if (requisitionPageStatus) return <Confirmation variant={requisitionPageStatus as ConfirmationVariant} />

  return (
    <Navigate
      to={generatePath('/requisitions/:requisitionId/summary', { requisitionId: String(requisitionId) })}
      replace
    />
  )
}
