import NumberInput from '../../number-input'

export interface JournalTaxProps {
  tax: number | string | null | undefined
  onChange: (tax: number) => void
  isDisabled?: boolean
}

export default function JournalTax({ tax, onChange, isDisabled }: JournalTaxProps) {
  return (
    <NumberInput
      defaultValue={Number(tax)}
      className="m-auto w-[4rem] rounded-md border border-gray-300 px-4 py-3 text-center text-sm"
      onChange={onChange}
      aria-label="tax-input"
      isDisabled={isDisabled}
      data-testid="tax-input"
    />
  )
}
