import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import SelfManagedProductsTableView from './table-view/SelfManagedProductsTableView'
import TileView from './tile-view'

import SelfManagedCatalogProductsEmptyStateImg from '@/assets/images/empty-self-managed-catalogs.svg'
import { useGetPricedCatalog } from '@/modules/catalogs/hooks'
import { Spinner } from '@/modules/requisitions/components'
import { QueryResult } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import SearchAndFilter from '@/modules/shared/components/search-input/SearchAndFilter'
import { useWindowSize } from '@/modules/shared/hooks'

export default function CatalogProducts() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()

  const { loadingState, error, pageInfo, onFetchMorePricedCatalogs, products } = useGetPricedCatalog()

  return (
    <section className="flex flex-col gap-y-5">
      <SearchAndFilter
        testId="self-managed-catalogs-search-input"
        placeholder={t('catalogs.searchSelfManagedCatalogPlaceholder', 'Search by Product Name')}
        ariaLabel={t('catalogs.searchSelfManagedCatalogPlaceholder', 'Search by Product Name')}
      />
      <QueryResult loading={loadingState.loading || loadingState.setVariablesLoading} error={error}>
        <InfiniteScroll
          dataLength={products.length}
          next={onFetchMorePricedCatalogs}
          hasMore={!!pageInfo?.hasNextPage}
          loader={<Spinner className="mt-5 h-14 md:w-16" />}
        >
          {isLargeScreen ? <SelfManagedProductsTableView products={products} /> : <TileView products={products} />}
        </InfiniteScroll>
        {products.length === 0 && (
          <EmptyState
            message={t('catalogs.selfManagedCatalogEmptyState', 'There are no products to display.')}
            img={SelfManagedCatalogProductsEmptyStateImg}
          />
        )}
      </QueryResult>
    </section>
  )
}
