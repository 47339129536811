import { Fallback } from '@/modules/shared/components'

export interface TextWithFallbackProps {
  title: string
  value?: string | null | number
}
export const TextWithFallback = ({ title, value }: TextWithFallbackProps) => (
  <div>
    <p className="font-bold">{title}</p>

    <Fallback as="p" condition={!!value}>
      {value}
    </Fallback>
  </div>
)
