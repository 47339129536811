import { graphql } from 'msw'

import { CreateAdvancedRequisitionLineMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseCreateAdvancedRequisitionLine: CreateAdvancedRequisitionLineMutation = {
  createRequisitionLine: {
    requisition: {
      id: 5235941,
      totalValue: 0.02,
      totalTaxValue: 0,
      lines: [
        {
          id: 26775302,
          productId: 5075897,
          cataloguedProductId: 19860317,
          quantity: 2,
          total: 0.02,
          department: null,
          account: null,
          orderInstruction: null,
          __typename: 'RequisitionLine',
          supplier: {
            id: 734,
            name: 'Simon George & Sons Pty Ltd - Cairns',
            billingAddressLine1: '548-572 Clayton Road',
            billingAddressLine2: '',
            billingAddressStateProvince: 'Victoria',
            billingAddressCity: 'Clayton',
            billingAddressCountry: 'AU',
            billingAddressPostalCode: '3168',
            telephone: '+613 8551 0111',
            email: 'ecommerce@bidvest.com.au',
            deliveryChargeConfiguration: null,
            __typename: 'Supplier',
          },
        },
      ],
      __typename: 'Requisition',
    },
    __typename: 'CreateRequisitionLineMutationPayload',
  },
}

export const CreateAdvancedRequisitionLine = graphql.mutation<CreateAdvancedRequisitionLineMutation>(
  'CreateAdvancedRequisitionLine',
  (_, res, ctx) => {
    return res(ctx.data(baseCreateAdvancedRequisitionLine))
  }
)

export default baseCreateAdvancedRequisitionLine
