import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import SupplierQuotesModal from '../../supplier-quotes-modal/SupplierQuotesModal'
import Tooltip from '../../tooltip'

import { Price, Product } from '@/graphql/purchasing/generated/purchasing_graphql'

export interface SupplierQuotesColumnProps {
  product: Product | null
  prices: Price[] | null
  currentSupplierId?: number | null // the supplier of the currently selected
  lineId?: number | null // a lineId is required to change the supplier
  image?: string | null
  refetch?: () => void
  basicShop?: boolean // displays the basic shop text
  cataloguedProductId?: number | null // only in advance mode
  quantity?: number | null // only in advance mode
}

export const SupplierQuotesButton = ({
  product,
  prices,
  currentSupplierId,
  lineId,
  image,
  cataloguedProductId,
  refetch,
  basicShop,
  quantity,
}: SupplierQuotesColumnProps) => {
  const { t } = useTranslation()
  const [showSupplierQuotesModal, setShowSupplierQuotesModal] = useState<boolean>(false)

  const numberOfQuotes = prices?.length

  const hasQuotesText = () => {
    if (cataloguedProductId || !numberOfQuotes) {
      return t('quotesButton.tooltip.advancedHasQuotes', {
        defaultValue_one: 'There is {{count}} available quote',
        defaultValue_many: 'There are {{count}} available quotes',
        defaultValue_other: 'There are no available quotes',
        count: numberOfQuotes,
      })
    }
    return t('quotesButton.tooltip.hasQuotes', {
      defaultValue_one: 'There is {{count}} Supplier quote available on this product.',
      defaultValue_many: 'There are {{count}} Supplier quotes available on this product.',
      defaultValue_other: 'There are {{count}} Supplier quotes available on this product.',
      count: numberOfQuotes,
    })
  }

  return (
    <>
      <Tooltip content={hasQuotesText()}>
        <div
          className="mx-auto flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300"
          onClick={() => setShowSupplierQuotesModal(true)}
          data-testid="supplier-quotes-button"
        >
          {numberOfQuotes || 0}
        </div>
      </Tooltip>
      <SupplierQuotesModal
        showModal={showSupplierQuotesModal}
        setShowModal={setShowSupplierQuotesModal}
        product={product}
        prices={prices}
        currentSupplierId={currentSupplierId || undefined}
        lineId={lineId || undefined}
        image={image}
        refetch={refetch}
        basicShop={basicShop}
        cataloguedProductId={cataloguedProductId}
        originalQuantity={quantity}
      />
    </>
  )
}
