import { useQuery } from '@apollo/client'
import { Dispatch, SetStateAction } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Catalogue,
  CatalogueChargingDefinition,
  GetCataloguesDocument,
  RansackDirection,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { RequisitionFormInputs } from '@/modules/requisitions/types'
import { ComboboxServer } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { extractEdges } from '@/modules/shared/utils'

interface SelectSupplierProps {
  setDefaultDeptAndAccount?: Dispatch<SetStateAction<CatalogueChargingDefinition | null>>
}

export default function SelectBuyList(props: SelectSupplierProps) {
  const { setDefaultDeptAndAccount } = props
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
    resetField,
  } = useFormContext<RequisitionFormInputs>()
  const {
    data: cataloguesData,
    networkStatus,
    refetch,
    fetchMore,
  } = useQuery(GetCataloguesDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    variables: {
      filter: {},
      after: null,
      first: 25,
      sort: [
        {
          property: 'title',
          direction: RansackDirection.Asc,
        },
      ],
    },
    notifyOnNetworkStatusChange: true,
  })

  const onSearchBuyList = (text: string) => {
    refetch({
      filter: {
        q: [
          {
            property: 'title_cont',
            value: text,
          },
        ],
      },
    })
  }

  const onFetchMoreBuyList = () => {
    fetchMore({
      variables: {
        after: cataloguesData?.currentPurchaser?.catalogues?.pageInfo.endCursor,
      },
    })
  }

  const applyNewDefaultDepartmentAndAccount = (defaultDeptAndAccount: CatalogueChargingDefinition) => {
    if (setDefaultDeptAndAccount) {
      resetField('departmentId')
      resetField('accountId')
      setDefaultDeptAndAccount(defaultDeptAndAccount)
    }
  }

  return (
    <section className="mt-8">
      <h2 className="text-sm font-semibold">{t('createRequisition.buyList.title', 'Select a Buy List')}</h2>
      <p className="text-sm text-gray-500">
        {t(
          'createRequisition.buyList.explain',
          'Please select a Buy List you wish to order products from. Buy Lists are created and managed by your organisation.'
        )}
      </p>
      <p className="text-sm text-gray-500">
        {t(
          'createRequisition.buyList.contactAdmin',
          'Please speak with an administrator at your organisation if there are changes that are required to a Buy List.'
        )}
      </p>
      <div className="mt-2">
        <Controller
          control={control}
          name="originId"
          rules={{ required: true }}
          render={({ field }) => (
            <ComboboxServer
              networkStatus={networkStatus}
              placeholder={t('createRequisition.buyList.placeholder', 'Select a Buy List...')}
              keyExtractor={(e) => String(e.id)}
              items={extractEdges<Catalogue>(cataloguesData?.currentPurchaser?.catalogues)}
              onDisplay={(e) => e.title}
              onSelected={(e) => {
                field.onChange(e.id)
                applyNewDefaultDepartmentAndAccount(
                  e.catalogueChargingDefinitions?.edges?.[0]?.node as CatalogueChargingDefinition
                )
              }}
              onInputChange={onSearchBuyList}
              onFetchMore={onFetchMoreBuyList}
              hasMore={cataloguesData?.currentPurchaser?.catalogues?.pageInfo.hasNextPage}
              hasError={Boolean(errors.originId)}
              errorMessage={t('createRequisition.buyList.required', 'You must select a Buy List.')}
            />
          )}
        />
      </div>
    </section>
  )
}
