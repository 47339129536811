import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import './index.css'
import {
  ACCESS_GRAPHQL_API,
  GOODS_GRAPHQL_API,
  INVENTORY_GRAPHQL_API,
  PURCHASING_GRAPHQL_API,
} from './modules/shared/constants'
import reportWebVitals from './reportWebVitals'

import { worker } from '@/tests/mocks/browser'

if (process.env.REACT_APP_MOCKING === 'true') {
  worker.start()
}

Sentry.init({
  environment: process.env.REACT_APP_BUILD_ENV,
  dsn: process.env.REACT_APP_DSN_VAR,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,

      // config to show request and reponse on prd
      networkDetailAllowUrls: [PURCHASING_GRAPHQL_API, GOODS_GRAPHQL_API, INVENTORY_GRAPHQL_API, ACCESS_GRAPHQL_API],
    }),
  ],
  tracesSampleRate: 1.0,

  // setup session replay rates
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
