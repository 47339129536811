import { useTranslation } from 'react-i18next'

import InvoiceLineJournalOptions from './InvoiceLineJournalOptions'

import { InvoiceLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useUpdateInvoiceLineItem } from '@/modules/invoices/hooks'
import { InvoiceStatuses } from '@/modules/invoices/types'
import Table, { ColumnDefinition } from '@/modules/shared/components/table/Table'
import {
  JournalQuantity,
  JournalTax,
  JournalUnitPrice,
  UnitPrice,
} from '@/modules/shared/components/table/table-column-items'
import JournalLinkedPO from '@/modules/shared/components/table/table-column-items/JournalLinkedPO'

interface InvoiceLinesAdvancedTableViewProps {
  state: InvoiceStatuses
  invoiceLines: InvoiceLineItem[]
  showFlagsColumn: boolean
  refetch: () => void
  columns: ColumnDefinition<InvoiceLineItem>[]
}

export default function InvoiceLinesAdvancedTableView({
  invoiceLines,
  showFlagsColumn,
  refetch,
  columns,
}: InvoiceLinesAdvancedTableViewProps) {
  const { t } = useTranslation()

  const [updateInvoiceLineItem, { loading: updateInvoiceLineItemLoading }] = useUpdateInvoiceLineItem()

  const onUpdateInvoiceLineItem = (field: string, value: number, id: number) => {
    updateInvoiceLineItem({ variables: { input: { id, [field]: value } } })
  }

  return (
    <div className="flex flex-col gap-y-3">
      {invoiceLines.map((line, i) => (
        <div key={line.id}>
          <Table
            dataSource={[line]}
            keyExtractor={(record) => String(record.id)}
            columnsHidden={!showFlagsColumn ? ['flags'] : []}
            hideHeader={i !== 0}
            columns={columns}
          />
          <div className="mt-[-1px]">
            <Table
              dataSource={[line]}
              keyExtractor={(record) => String(record.id)}
              columnsHidden={!showFlagsColumn ? ['flags'] : []}
              hideHeader
              columns={[
                {
                  title: t('invoices.invoice.lineLabels.linked', 'Linked'),
                  key: 'linked',
                  headerCellStyles: 'py-3 text-center',
                  cellStyles: 'py-3 text-center',
                  minWidth: 4.375,
                  maxWidth: 4.375,
                  render: ({ id }) => <JournalLinkedPO id={id} refetch={refetch} />,
                },
                {
                  title: t('general.product', 'Product'),
                  key: 'product',
                  headerCellStyles: 'pr-4 py-3',
                  cellStyles: 'pr-4 py-3',
                  minWidth: 13.75,
                  grow: true,
                  render: InvoiceLineJournalOptions,
                },
                {
                  title: t('general.quantity', 'Quantity'),
                  key: 'quantity',
                  headerCellStyles: 'py-3 text-center',
                  cellStyles: 'py-3 text-center',
                  minWidth: 5.1,
                  maxWidth: 5.1,
                  render: ({ quantity, id }) => (
                    <JournalQuantity
                      quantity={quantity}
                      onChange={(value: number) => onUpdateInvoiceLineItem('quantity', value, id)}
                      isDisabled={updateInvoiceLineItemLoading}
                    />
                  ),
                },
                {
                  title: t('general.unitPrice', 'Unit Price'),
                  key: 'unitPrice',
                  headerCellStyles: 'py-3 pr-2 text-right',
                  cellStyles: 'py-3 pr-2 text-right',
                  minWidth: 5.625,
                  maxWidth: 7.5,
                  render: ({ unitPrice, id }) => (
                    <JournalUnitPrice
                      unitPrice={unitPrice}
                      onChange={(value: number) => onUpdateInvoiceLineItem('invoicePrice', value, id)}
                      isDisabled={updateInvoiceLineItemLoading}
                    />
                  ),
                },
                {
                  title: t('general.tax', 'Tax'),
                  key: 'tax',
                  headerCellStyles: 'py-3 text-center',
                  cellStyles: 'py-3 text-center',
                  minWidth: 4.375,
                  maxWidth: 4.375,
                  render: ({ taxPercentage, id }) => (
                    <JournalTax
                      tax={taxPercentage}
                      onChange={(value: number) => onUpdateInvoiceLineItem('taxPercentage', value, id)}
                      isDisabled={updateInvoiceLineItemLoading}
                    />
                  ),
                },
                {
                  title: t('general.total', 'Total'),
                  key: 'total',
                  headerCellStyles: 'py-3 pr-5 text-right',
                  cellStyles: 'py-3 pr-5 text-right',
                  minWidth: 6.25,
                  maxWidth: 8.125,
                  render: ({ lineTotal }) => <UnitPrice unitPrice={lineTotal} />,
                },
              ]}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
