import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useDeclineRequisition } from '@/modules/requisitions/hooks'
import { Button } from '@/modules/shared/components'

interface DeclineFormProps {
  requisitionId: number
}

export default function DeclineForm({ requisitionId }: DeclineFormProps) {
  const { t } = useTranslation()
  const [actionComment, setActionComment] = useState('')
  const [declineRequisition, { loading }] = useDeclineRequisition()
  const onDeclineRequisition = () => {
    declineRequisition({
      variables: {
        input: {
          id: requisitionId,
          actionComment,
        },
      },
    })
  }

  return (
    <form>
      <h2 className="text-sm font-semibold">{t('general.declineReason', 'Decline Reason')}</h2>
      <p className="mt-1 text-sm">
        {t(
          'declineRequisition.form.pleaseAddReason',
          'Please add a reason for the decline to include in the decline notification email.'
        )}
      </p>
      <textarea
        required
        name="decline-reason"
        id="decline-reason"
        className="block w-full rounded-md border border-gray-300 p-3 text-sm shadow-sm focus:outline-none focus:ring-primary"
        placeholder={t('declineRequisition.form.reasonPlaceholder', 'Please add your decline reason')}
        rows={5}
        onChange={(e) => {
          setActionComment(e.target.value)
        }}
      ></textarea>
      <div>
        <Button
          type="submit"
          data-testid="action-approve"
          className="mb-2 mt-4 block h-11 w-full rounded-md bg-error px-3 text-center text-sm text-white"
          loading={loading}
          onClick={onDeclineRequisition}
        >
          {t('declineRequisition.form.declineAndSendEmail', 'Decline and Send Notification Email')}
        </Button>
        <Link
          data-testid="action-cancel"
          className="my-2 flex h-11 w-full items-center justify-center rounded-md bg-gray-200 px-3 text-center text-sm transition hover:brightness-95"
          to="/requisitions/awaiting-my-approval"
        >
          {t('general.cancelAndReturn', 'Cancel and Return')}
        </Link>
      </div>
    </form>
  )
}
