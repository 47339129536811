import classNames from 'classnames'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { useFindMatchPurchaserRoutes } from '../../../hooks'
import { SideBarContext } from '../../app-layout'
import CreatePurchaseOrderModal from '../CreatePurchaseOrderModal'
import { BuyListSideBarItem } from './BuyListSideBarItem'
import { CatalogsSideBarItem } from './CatalogsSideBarItem'
import { InventorySideBarItem } from './InventorySideBarItem'
import { InvoicesSideBarItem } from './InvoicesSideBarItem'
import { PurchaseOrdersSideBarItem } from './PurchaseOrdersSideBarItem'
import { RequisitionsSideBarItem } from './RequisitionsSideBarItem'
import { SuppliersSideBarItem } from './SuppliersSideBarItem'
import { SystemSettingsSideBarItem } from './SystemSettingsSideBarItem'

import { useSignOut } from '@/modules/access/hooks'
import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import SwitchOrganisation from '@/modules/requisitions/layouts/header/switch-organisation'
import { Button } from '@/modules/shared/components'
import { SUPPORT_LINK } from '@/modules/shared/constants'
import { ArrowsRightLeftIcon, GetHelpIcon, HomeIcon, SignOutIcon } from '@/modules/shared/icons'

export interface SideBarItem {
  openAccordionKey: number | undefined
  changeOpenAccordionKey: (key: number) => void
  onCloseDrawer: () => void
}

export default function SideNavigation() {
  const sideBar = useContext(SideBarContext)

  const { t } = useTranslation()

  const {
    requisitionRoutesMatch,
    purchaseOrdersRoutesMatch,
    invoiceRoutesMatch,
    buyListRoutesMatch,
    catalogRoutesMatch,
    supplierRoutesMatch,
    inventoryRoutesMatch,
    systemSettingRoutesMatch,
    accordionDefaultKey,
  } = useFindMatchPurchaserRoutes()

  const [signOut, { loading: actionLoading }] = useSignOut()
  const [openSwitchOrg, setOpenSwitchOrg] = useState(false)
  const [openAccordionKey, setOpenAccordionKey] = useState<number | undefined>()
  const [showCreatePurchaseOrderModal, setShowCreatePurchaseOrderModal] = useState(false)
  const { currentOrganisation } = useCurrentOrganisation()
  const { featureFlags } = currentOrganisation || {}

  useEffect(() => {
    if (accordionDefaultKey) {
      setOpenAccordionKey(accordionDefaultKey)
    }
  }, [accordionDefaultKey])

  const changeOpenAccordionKey = (key: number | undefined) => {
    if (key === openAccordionKey) {
      setOpenAccordionKey(undefined)
    } else {
      setOpenAccordionKey(key)
    }
  }

  const onCloseDrawer = () => sideBar?.setDrawerOpen && sideBar.setDrawerOpen(false)

  return (
    <>
      <nav className="flex-1 pb-1 text-sm" data-testid="side-navigation-items">
        <section className="space-y-1.5">
          <NavLink
            data-testid="home-navigation-link"
            to="/dashboard"
            onClick={() => onCloseDrawer()}
            className={({ isActive }) => {
              return classNames(
                'flex h-11 items-center gap-x-2 rounded-md  px-3  transition duration-200 hover:brightness-95',
                {
                  'bg-primary text-white': isActive,
                  'bg-gray-200': !isActive,
                }
              )
            }}
          >
            <HomeIcon className="h-7 w-7" />
            {t('general.home', 'Home')}
          </NavLink>
          <RequisitionsSideBarItem
            requisitionRoutesMatch={requisitionRoutesMatch}
            openAccordionKey={openAccordionKey}
            changeOpenAccordionKey={changeOpenAccordionKey}
            onCloseDrawer={onCloseDrawer}
          />
          <PurchaseOrdersSideBarItem
            purchaseOrdersRoutesMatch={purchaseOrdersRoutesMatch}
            openAccordionKey={openAccordionKey}
            setShowCreatePurchaseOrderModal={setShowCreatePurchaseOrderModal}
            changeOpenAccordionKey={changeOpenAccordionKey}
            onCloseDrawer={onCloseDrawer}
          />
          <InvoicesSideBarItem
            invoiceRoutesMatch={invoiceRoutesMatch}
            openAccordionKey={openAccordionKey}
            changeOpenAccordionKey={changeOpenAccordionKey}
            onCloseDrawer={onCloseDrawer}
          />
          <BuyListSideBarItem
            buyListRoutesMatch={buyListRoutesMatch}
            openAccordionKey={openAccordionKey}
            changeOpenAccordionKey={changeOpenAccordionKey}
            onCloseDrawer={onCloseDrawer}
          />
          <CatalogsSideBarItem
            catalogRoutesMatch={catalogRoutesMatch}
            openAccordionKey={openAccordionKey}
            changeOpenAccordionKey={changeOpenAccordionKey}
            onCloseDrawer={onCloseDrawer}
          />
          <SuppliersSideBarItem
            supplierRoutesMatch={supplierRoutesMatch}
            openAccordionKey={openAccordionKey}
            changeOpenAccordionKey={changeOpenAccordionKey}
            onCloseDrawer={onCloseDrawer}
          />
          {featureFlags?.inventory && (
            <InventorySideBarItem
              inventoryRoutesMatch={inventoryRoutesMatch}
              openAccordionKey={openAccordionKey}
              changeOpenAccordionKey={changeOpenAccordionKey}
              onCloseDrawer={onCloseDrawer}
            />
          )}
        </section>

        <section className="mt-7 space-y-1.5">
          <h1 className="mb-2 px-3 text-sm text-gray-500">{t('general.account', 'Account')}</h1>
          <a
            data-testid="view-support-link"
            href={SUPPORT_LINK}
            target="_blank"
            rel="noreferrer"
            className="flex h-11 cursor-pointer items-center gap-x-2 rounded-md bg-gray-200 px-3  hover:brightness-95"
          >
            <GetHelpIcon className="h-7 w-7" />
            {t('general.helpCenter', 'Help Center')}
          </a>
          <SystemSettingsSideBarItem
            systemSettingRoutesMatch={systemSettingRoutesMatch}
            openAccordionKey={openAccordionKey}
            changeOpenAccordionKey={changeOpenAccordionKey}
            onCloseDrawer={onCloseDrawer}
          />
          <Button
            data-testid="switch-org-btn"
            className="flex h-11 w-full items-center gap-x-2 rounded-md bg-gray-200 px-3 "
            onClick={() => setOpenSwitchOrg(true)}
          >
            <ArrowsRightLeftIcon className=" h-7 w-7" />
            {t('general.switchOrganisation', 'Switch Organisation')}
          </Button>
          <Button
            data-testid="signout-btn"
            loading={actionLoading}
            className="flex h-11 w-full items-center gap-x-2 rounded-md bg-gray-200 px-3 "
            onClick={() => signOut()}
          >
            <SignOutIcon className=" h-7 w-7" />
            {t('general.signOut', 'Sign Out')}
          </Button>
        </section>
      </nav>
      <SwitchOrganisation isOpen={openSwitchOrg} setIsOpen={setOpenSwitchOrg} />
      <CreatePurchaseOrderModal
        showModal={showCreatePurchaseOrderModal}
        setShowModal={setShowCreatePurchaseOrderModal}
      />
    </>
  )
}
