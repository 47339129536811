import { useTranslation } from 'react-i18next'

import { useMoney } from '../../hooks'
import PreferredSupplier from '../product-list/PreferredSupplier'
import { BestBuyItem } from '../table/table-column-items'

import NoImage from '@/assets/images/no-image.svg'
import { Price, Product } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Checkbox, Modal } from '@/modules/shared/components'

export interface SupplierQuotesModalBodyProps {
  currentSupplierId?: number | null
  product: Product | undefined | null
  prices: Price[] | null
  lineId?: number | null
  image?: string | null
  basicShop?: boolean
  cataloguedProductId?: number | null
  selectedSupplier: Price | undefined | null
  setSelectedSupplier: (price: Price) => void
}

export const SupplierQuotesModalBody = ({
  product,
  prices,
  image,
  basicShop,
  lineId,
  selectedSupplier,
  cataloguedProductId,
  setSelectedSupplier,
  currentSupplierId,
}: SupplierQuotesModalBodyProps) => {
  const {
    brand,
    itemDescription,
    itemSize,
    itemMeasure,
    itemPackName,
    concatenatedSellUnit,
    id: productId,
  } = product || {}
  const { formatMoney } = useMoney()
  const { t } = useTranslation()

  // Sort quotes by price, with null at the end, and lowest price at the beginning
  const sortedPrices = prices?.slice().sort((a, b) => {
    if (a.unitPrice === null && b.unitPrice === null) {
      return 0
    } else if (a.unitPrice === null) {
      return 1
    } else if (b.unitPrice === null) {
      return -1
    } else {
      return a.unitPrice - b.unitPrice
    }
  })

  return (
    <Modal.Body className="space-y-5 overflow-y-auto" data-testid="supplier-quotes-modal">
      <div className="flex space-x-5 rounded-md border p-3">
        <img className="h-24 w-24 flex-none border" src={image || NoImage} alt="product" />
        <div className="flex flex-col justify-center gap-4">
          <span>
            <p>
              {brand} {itemDescription}
            </p>
            <p className="text-xs text-gray-500">
              {formatProductItem({
                itemSize: itemSize,
                itemMeasure: itemMeasure,
                itemPackName: itemPackName,
                itemSellUnit: concatenatedSellUnit,
              })}
            </p>
          </span>
        </div>
      </div>
      <div className="text-sm">
        <p className="font-bold">{t('general.supplierQuotes', 'Supplier Quotes')}</p>
        <p className="text-gray-500">
          {basicShop
            ? t(
                'buyLists.products.quotesModal.basicSupplierQuotesDesc',
                'Below are the quotes available on this item from other Suppliers, based on the type of Requisition you have chosen. Quotes shown here are from alternate Suppliers who also offers this exact product. Quotes are shown here for your information, and when viewing your cart you will be able to select an alternate quote.'
              )
            : t(
                'buyLists.products.quotesModal.supplierQuotesDesc',
                'Below are the quotes we have from each of your enabled Suppliers that currently offer this product.'
              )}
        </p>
        {sortedPrices?.length ? (
          <table className="mt-2 flex flex-col divide-y rounded-md border bg-gray-100">
            <tbody>
              {sortedPrices.map(
                (
                  {
                    supplierName,
                    unitPrice,
                    taxPercentage,
                    supplierId,
                    cataloguedProductId: newCataloguedProductId,
                    preferredSupplier,
                  }: Price,
                  index
                ) => (
                  <tr
                    key={index}
                    className="flex cursor-pointer items-center justify-between gap-x-2 border-b p-3 last:border-b-0"
                  >
                    <td className="flex grow flex-row items-center justify-start gap-x-1.5 text-center">
                      {(cataloguedProductId || lineId) && (
                        <div className="w-10" data-testid="match-supplier-checkbox">
                          <Checkbox
                            isSelected={supplierId === selectedSupplier?.supplierId}
                            aria-label="match-supplier"
                            onChange={() =>
                              setSelectedSupplier({
                                unitPrice,
                                taxPercentage,
                                supplierId,
                                productId: productId,
                                supplierName,
                                cataloguedProductId: newCataloguedProductId,
                              } as Price)
                            }
                          />
                        </div>
                      )}
                      <PreferredSupplier
                        preferredSupplier={!!preferredSupplier}
                        supplierName={supplierName}
                        className="ml-1"
                      />
                      {currentSupplierId && supplierId === currentSupplierId && (
                        <div className="mx-2 rounded-md bg-gray-400 px-2 py-1 text-xs text-white">
                          {t('general.selected', 'Selected')}
                        </div>
                      )}
                    </td>
                    <td className="flex min-w-[7rem] flex-row items-center justify-between text-center">
                      {unitPrice && <BestBuyItem unitPrice={unitPrice} sortedPrices={sortedPrices} index={index} />}
                    </td>
                    <td className="min-w-[5rem]">
                      <p className="text-right font-bold">{formatMoney(unitPrice)}</p>
                      <p className="text-right text-xs">
                        {t('general.plusTax', 'Plus {{ percent }}% Tax', {
                          percent: taxPercentage,
                        })}
                      </p>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        ) : (
          <div className="mt-2 border bg-gray-100 px-3 py-6 text-center text-gray-500">
            {t('buyLists.products.quotesModal.noQuotes', 'There are no Supplier Quotes available for this product.')}
          </div>
        )}
      </div>
    </Modal.Body>
  )
}
