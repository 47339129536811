import { useLazyQuery } from '@apollo/client'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DelimitedNumericArrayParam, useQueryParam } from 'use-query-params'

import AdvancedSearchFilter from './AdvancedSearchFilter'
import FilterByCategoryModal from './FilterByCategoryModal'

import {
  CategoriesWithSubDocument,
  Category,
  CategoryEdge,
  RansackDirection,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { Chip, FilterButton } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { ChevronDownIcon } from '@/modules/shared/icons'
import { QueryParameter } from '@/modules/shared/types'
import { extractEdges } from '@/modules/shared/utils'

interface CategoryFiltersSectionProps {
  advancedShopping?: boolean
}

export default function CategoryFiltersSection({ advancedShopping }: CategoryFiltersSectionProps) {
  const { t } = useTranslation()
  const [categoriesURLParam, setCategoriesURLParam] = useQueryParam(
    QueryParameter.Categories,
    DelimitedNumericArrayParam
  )
  const [parentCategoriesURLParam, setParentCategoriesURLParam] = useQueryParam(
    QueryParameter.ParentCategories,
    DelimitedNumericArrayParam
  )
  const { md, lg, twoXL } = useWindowSize()
  const [showFilterByCategoryModal, filterByCategoryModal] = useState<boolean>(false)
  const [showCategoriesChip, setShowCategoriesChip] = useState(false)
  const [categoryChips, setCategoryChips] = useState<CategoryEdge[]>([])

  const [getCategoryChips] = useLazyQuery(CategoriesWithSubDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setShowCategoriesChip(true)
      const FilteredCategories = extractEdges<Category>(data.categories).filter(
        (cat) => Number(cat.categories?.edges?.length) > 0
      )
      let result: CategoryEdge[] = []

      // add child categories that has content to result
      FilteredCategories?.forEach((parentCat) => {
        parentCat?.categories?.edges?.forEach((childCat) => {
          if (childCat) {
            result.push(childCat)
          }
        })
      })
      setCategoryChips(result)
    },
  })

  useEffect(() => {
    if (categoriesURLParam && categoriesURLParam.length > 0) {
      getCategoryChips({
        variables: {
          first: null,
          filter: {
            q: [
              {
                property: 'id_in',
                value: parentCategoriesURLParam,
              },
            ],
          },
          filterSub: {
            q: [
              {
                property: 'id_in',
                value: categoriesURLParam,
              },
            ],
          },
          sort: [
            {
              property: 'name',
              direction: RansackDirection.Asc,
            },
          ],
        },
      })
    } else {
      setShowCategoriesChip(false)
    }
  }, [categoriesURLParam])

  return (
    <AdvancedSearchFilter
      buttonTestId="filters-section"
      buttonTitle={t('general.filterByCategory', 'Filter By Category')}
    >
      <div className="flex flex-col gap-2 md:flex-row 2xl:flex-col">
        <FilterButton
          data-testid={`filter-by-category`}
          className={classNames('flex h-11 w-full items-center justify-between rounded-md border px-4', {
            'max-w-[15.625rem]': (!advancedShopping && !twoXL && md) || (advancedShopping && lg),
          })}
          isActive={!!categoriesURLParam}
          onClick={() => filterByCategoryModal(true)}
        >
          <span className="text-sm">{t('general.category', 'Category')}</span>
          <ChevronDownIcon className="h-5 w-5" />
        </FilterButton>
      </div>

      {/* render category chips */}
      {showCategoriesChip && categoriesURLParam && (
        <div className="flex flex-wrap items-center gap-2 text-sm" data-testid="chips">
          {categoryChips.map((subCat: CategoryEdge) => (
            <Chip
              key={subCat.node?.id}
              onRemoveChip={() => {
                setCategoriesURLParam((prevParams) => {
                  const newParams = prevParams?.filter((param) => param !== subCat.node?.id) || []
                  if (newParams.length > 0) return newParams
                  return undefined
                })
                if (categoriesURLParam.length === 1) setParentCategoriesURLParam(undefined)
              }}
              label={String(subCat.node?.name)}
            />
          ))}
        </div>
      )}
      <FilterByCategoryModal showModal={showFilterByCategoryModal} setShowModal={filterByCategoryModal} />
    </AdvancedSearchFilter>
  )
}
