import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Dispatch, Fragment, PropsWithChildren, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import UserMenu from '../UserMenu'

import { User } from '@/graphql/access/generated/access_graphql'
import { Purchaser } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useSignOut } from '@/modules/access/hooks'
import { Button } from '@/modules/shared/components'
import { ArrowsRightLeftIcon, NewTabIcon, ShieldIcon, SignOutIcon } from '@/modules/shared/icons'

interface UserDetailsProps extends PropsWithChildren {
  customStyles?: string
  currentUser: User
  currentOrganisation: Purchaser
  setOpenSwitchOrg: Dispatch<SetStateAction<boolean>>
}

export default function UserDetails({
  children,
  customStyles,
  currentUser,
  currentOrganisation,
  setOpenSwitchOrg,
}: UserDetailsProps) {
  const { t } = useTranslation()

  const [signOut, { loading: actionLoading }] = useSignOut()

  return (
    <Menu as="div" className={classNames('relative inline-block text-left', customStyles || '')}>
      <Menu.Button
        className="flex h-11 items-center rounded-md bg-gray-100 px-2 py-1 transition duration-200 hover:bg-gray-200"
        data-testid="user-details-btn"
      >
        {children}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-auto origin-top-right divide-y divide-gray-100 rounded-md border bg-white shadow-lg focus:outline-none">
          <div className="py-1" data-testid="user-details">
            <Menu.Item>
              <div>
                <UserMenu />
              </div>
            </Menu.Item>
          </div>
          <div className="py-1" data-testid="user-details-actions">
            {currentUser?.isAdmin && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    className={classNames(
                      'w-full',
                      'px-4',
                      'py-2',
                      'text-left',
                      'text-sm',
                      'flex',
                      'items-center',
                      'text-gray-700',
                      'transition',
                      {
                        'bg-gray-100 text-gray-900': active,
                      }
                    )}
                    to={generatePath('/admin/organisations/:id/customize', { id: String(currentOrganisation?.id) })}
                  >
                    <ShieldIcon className="mr-2 h-5 w-5" />
                    {t('general.adminCenter', 'Admin Center')}
                  </Link>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <a
                  className={classNames(
                    'w-full',
                    'px-4',
                    'py-2',
                    'text-left',
                    'text-sm',
                    'flex',
                    'items-center',
                    'text-gray-700',
                    'transition',
                    {
                      'bg-gray-100 text-gray-900': active,
                    }
                  )}
                  href={process.env.REACT_APP_NINJA_API_HOST}
                  target="_blank"
                  rel="noreferrer"
                >
                  <NewTabIcon className="mr-2 h-5 w-5" />
                  {t('access.goToPPlusSignEnterprise', 'Go to P+ Enterprise')}
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Button
                  data-testid="user-switch-org"
                  className={classNames(
                    'w-full',
                    'px-4',
                    'py-2',
                    'text-left',
                    'text-sm',
                    'flex',
                    'items-center',
                    'text-gray-700',
                    'transition',
                    {
                      'bg-gray-100 text-gray-900': active,
                    }
                  )}
                  onClick={() => setOpenSwitchOrg(true)}
                >
                  <ArrowsRightLeftIcon className="mr-2 h-5 w-5" />
                  {t('general.title', 'Switch Organisation')}
                </Button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Button
                  loading={actionLoading}
                  className={classNames(
                    'w-full',
                    'px-4',
                    'py-2',
                    'text-left',
                    'text-sm',
                    'flex',
                    'items-center',
                    'text-gray-700',
                    'transition',
                    {
                      'bg-gray-100 text-gray-900': active,
                    }
                  )}
                  onClick={() => signOut()}
                >
                  <SignOutIcon className="mr-2 h-5 w-5" />
                  {t('general.signOut', 'Sign Out')}
                </Button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
