import { Combobox } from '@headlessui/react'
import classNames from 'classnames'

import { CheckAltIcon } from '@/modules/shared/icons'

interface ComboBoxOptionItemProps<DataType> {
  item: DataType
  testId: string | undefined
  keyExtractor: (item: DataType) => string | number
  getSelectedItem: (item: DataType) => string
  isClient?: boolean
  selectedItems?: string[]
}

export function ComboboxOptionItem<T>({
  item,
  testId,
  keyExtractor,
  getSelectedItem,
  isClient,
  selectedItems,
}: ComboBoxOptionItemProps<T>) {
  const isInSelectedItemsList = selectedItems && selectedItems.includes(String(item))

  return (
    <Combobox.Option
      data-testid={`option${testId ? `-${testId}` : ''}-${keyExtractor(item)}`}
      value={item}
      className={({ active }) =>
        classNames('relative cursor-default select-none py-2 pl-3 pr-9', {
          'bg-primary text-white': active,
          'text-gray-900': !active,
        })
      }
    >
      {({ active, selected }) => (
        <>
          <span
            className={classNames('block truncate ', {
              'font-semibold ': selected,
            })}
          >
            {getSelectedItem(item)}
          </span>

          {(isInSelectedItemsList || selected) && (
            <span
              className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', {
                'text-white': active,
                'text-primary': !active,
              })}
            >
              {isClient ? (
                <CheckAltIcon className="h-4 w-4" />
              ) : (
                <CheckAltIcon className="h-5 w-5" aria-hidden="true" />
              )}
            </span>
          )}
        </>
      )}
    </Combobox.Option>
  )
}
