import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Tooltip } from '@/modules/shared/components'
import NumberInput from '@/modules/shared/components/number-input'
import { QuestionMarkIcon } from '@/modules/shared/icons'

interface InvoiceTotalsRowProps {
  title: string
  tooltip: string
  register: string
  disabled?: boolean
}

export default function InvoiceTotalsRow({ title, tooltip, register, disabled }: InvoiceTotalsRowProps) {
  const formMethods = useFormContext()
  const [total, setTotal] = useState<number>(0)
  formMethods.watch(({ totalExTax, totalTaxValue }) => setTotal(Number(totalExTax + Number(totalTaxValue))))

  return (
    <div className="flex items-center justify-between px-4 py-2.5 text-sm">
      <p className="flex items-center gap-x-1">
        {title}
        <Tooltip content={tooltip}>
          <span>
            <QuestionMarkIcon className="h-4 w-4" />
          </span>
        </Tooltip>
      </p>
      {disabled ? (
        <div
          data-testid="invoice-total"
          className="w-40 rounded-md border border-gray-300 bg-gray-200 p-3.5 text-right"
        >
          {total.toFixed(2)}
        </div>
      ) : (
        <Controller
          control={formMethods.control}
          name={register}
          render={({ field }) => (
            <NumberInput
              className="w-40 rounded-md border border-gray-300 p-3 text-right text-sm focus:border-primary focus:outline-none focus:ring-primary"
              data-testid="invoice-total-input"
              aria-label={register}
              defaultValue={0.0}
              onChange={(e) => field.onChange(e)}
            />
          )}
        />
      )}
    </div>
  )
}
