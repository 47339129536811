import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import RelatedCataloguesModal from './RelatedCataloguesModal'

import { Catalogue, Product } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, ConfirmDialog } from '@/modules/shared/components'
import { PlusIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'
import { extractEdges } from '@/modules/shared/utils'

interface AddOrViewCatalogueProps {
  product: Product
}

export default function AddOrViewCatalogue({ product }: AddOrViewCatalogueProps) {
  const { t } = useTranslation()
  const { catalogues } = product
  const cataloguesList = extractEdges<Catalogue>(catalogues)
  const [showCataloguesModal, setShowCataloguesModal] = useState(false)
  const [showRelatedCataloguesModal, setShowRelatedCataloguesModal] = useState(false)

  if (cataloguesList.length === 0)
    return (
      <>
        <Button
          data-testid="action-add-catalogue"
          onClick={() => setShowCataloguesModal(true)}
          className="mx-auto flex h-11 w-11 items-center justify-center rounded-full bg-primary/10"
        >
          <PlusIcon className="h-7 w-7 text-primary" />
        </Button>
        <ConfirmDialog
          theme={Themes.Primary}
          isOpen={showCataloguesModal}
          setIsOpen={setShowCataloguesModal}
          title={t('general.comingSoon', 'Coming Soon!')}
          description={t(
            'supplier.myProducts.addOrRemoveCatalogue',
            'Soon you will be able to add or remove this product to and from your catalogs from here. We are still working on this feature.'
          )}
          primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
          onPrimaryBtnClick={() => setShowCataloguesModal(false)}
        />
      </>
    )

  return (
    <>
      <Button
        onClick={() => {
          setShowRelatedCataloguesModal(true)
        }}
        className="h-11 w-11 rounded-full bg-gray-200 p-1.5"
        data-testid="view-related-catalogues"
      >
        {cataloguesList.length}
      </Button>
      <RelatedCataloguesModal
        showRelatedCataloguesModal={showRelatedCataloguesModal}
        setShowRelatedCataloguesModal={setShowRelatedCataloguesModal}
        product={product}
      />
    </>
  )
}
