import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
  createHttpLink,
  from,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import createOperationNameLink from './CreateOperationNameLink'

import { Spinner } from '@/modules/requisitions/components'
import { ACCESS_GRAPHQL_API } from '@/modules/shared/constants'
import { useAlert } from '@/modules/shared/hooks'

interface ApolloProviderWrapperProps extends PropsWithChildren {}

function ApolloProviderWrapper(props: ApolloProviderWrapperProps) {
  const { children } = props

  const { t } = useTranslation()
  const { alertDialog } = useAlert()

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>()

  useEffect(() => {
    if (client) {
      const handleClearCache = () => {
        client.clearStore() // or client.resetStore() if you want to re-execute active queries
      }

      window.addEventListener('clearApolloCache', handleClearCache)

      return () => {
        window.removeEventListener('clearApolloCache', handleClearCache)
      }
    }
  }, [client])

  useEffect(() => {
    async function init() {
      const httpLink = createHttpLink({
        uri: ACCESS_GRAPHQL_API,
        credentials: 'include',
      })

      const errorLink = onError((error) => {
        const networkError = error.networkError as ServerError
        const isNotAuthorized = (error?.graphQLErrors || []).some(
          (e) => e?.extensions?.code === 401 && e?.extensions?.status === 'Unauthorized'
        )

        // Any request met with a 401 response
        if (networkError?.statusCode === 401) {
          // These operations shouldn't raise an alert dialog
          if (
            error.operation.operationName === 'GetCurrentUser' ||
            error.operation.operationName === 'GetCurrentPurchaser' ||
            error.operation.operationName === 'SignOut' ||
            error.operation.operationName === 'GetCurrentLegalEntity' ||
            error.operation.operationName === 'GetCurrentOrganisation'
          ) {
            return
          }
          return alertDialog({
            type: 'error',
            title: t('alert.unauthenicated.title', 'Please Sign In Again'),
            message: t('alert.unauthenicated.message', 'Your session has expired, please sign in again.'),
            buttonText: t('general.signIn', 'Sign In'),
            onButtonClick() {
              window.location.reload()
            },
          })
        }

        if (networkError?.statusCode >= 500) {
          return alertDialog({
            type: 'error',
            title: t('general.internalServerError', 'Internal Server Error'),
            message: t(
              'alert.internalServerError.message',
              'Sorry but there was an internal server error. Please try again later.'
            ),
            buttonText: t('general.okay', 'Okay'),
          })
        }

        if (isNotAuthorized) {
          return alertDialog({
            type: 'error',
            title: t('general.notAuthorized', 'Not Authorized'),
            message: t(
              'alert.unauthorized.message',
              'Sorry but your user account does not have the correct permissions to access this feature. Please speak with an administrator at your organisation or contact our support team for assistance.'
            ),
            buttonText: t('general.okay', 'Okay'),
          })
        }

        // All other errors
        if (!networkError) alertDialog({ type: 'error' })
      })

      const operationNameLink = createOperationNameLink(ACCESS_GRAPHQL_API, process.env.NODE_ENV)

      setClient(
        new ApolloClient({
          link: from([errorLink, operationNameLink, httpLink]),
          cache: new InMemoryCache({
            typePolicies: {
              Purchaser: {
                fields: {
                  users: relayStylePagination(),
                  deliveryAddresses: relayStylePagination(),
                  supplierRelationshipsWithCatalogue: relayStylePagination(),
                  supplierRelationships: relayStylePagination(),
                  catalogues: relayStylePagination(),
                  availableCatalogues: relayStylePagination(),
                  requisitions: relayStylePagination(),
                  requisitionsAwaitingMyApproval: relayStylePagination(),
                  purchaseOrders: relayStylePagination(),
                  invoices: relayStylePagination(),
                  pricedCatalogues: relayStylePagination(),
                  receivingDocuments: relayStylePagination(),
                  creditNotes: relayStylePagination(),
                  availableProducts: relayStylePagination(),
                },
              },
              Requisition: {
                fields: {
                  possibleRequisitionLines: relayStylePagination(),
                },
              },
              RequisitionLine: {
                keyFields: (object) => {
                  if (object.cataloguedProductId) return ['cataloguedProductId']
                  if (object.productId) return ['productId']
                  return ['id']
                },
              },
              Catalogue: {
                fields: {
                  cataloguedProducts: relayStylePagination(),
                },
              },
              Query: {
                fields: {
                  organisations: relayStylePagination(),
                  categories: relayStylePagination(['filter']),
                  suppliers: relayStylePagination(),
                },
              },
              Category: {
                fields: {
                  categories: relayStylePagination(['filter']),
                },
              },
              Holder: {
                fields: {
                  stockLocations: relayStylePagination(),
                },
              },
              StockTake: {
                fields: {
                  stockCounts: relayStylePagination(),
                },
              },
              Supplier: {
                fields: {
                  availableProducts: relayStylePagination(),
                },
              },
            },
          }),
          connectToDevTools: process.env.NODE_ENV === 'development',
        })
      )
    }

    init().catch(console.error)
  }, [])

  if (!client) return <Spinner className="mt-12 h-28 md:h-32" data-testid="spinner" />
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloProviderWrapper
