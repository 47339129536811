import { useMutation } from '@apollo/client'

import { UpdateCataloguedProductDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export const useUpdateCataloguedProduct = () => {
  const mutation = useMutation(UpdateCataloguedProductDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })
  return mutation
}
