import { memo } from 'react'

import SingleComment from './SingleComment'

import { Comment } from '@/graphql/purchasing/generated/purchasing_graphql'
import { CommentType } from '@/modules/requisitions/types'
import { UserIcon } from '@/modules/shared/icons'

export interface CommentBlockProps {
  commentType: CommentType
  privateComment: boolean
  comments: Comment[]
  commentText: string
  setText: (commentText: string) => void
  refetch: () => void
  titleText: string
  bodyText: string
  placeholderText: string
  noCommentText: string
  onCreateComment: (body: string, isPrivate: boolean) => void
  createLoading: boolean
}
const CommentBlock = ({
  refetch,
  titleText,
  bodyText,
  placeholderText,
  noCommentText,
  onCreateComment,
  createLoading,
  commentType,
  privateComment,
  comments,
  commentText,
  setText,
}: CommentBlockProps) => {
  return (
    <section className="rounded-md bg-white p-5 shadow-sm" data-testid={`${commentType}-comments-section`}>
      <p className="font-bold">{titleText}</p>
      <p className="text-sm text-gray-500">{bodyText}</p>
      <div className="mb-2 mt-5 flex w-full gap-x-3">
        <UserIcon className="flex h-9 w-9 rounded-full bg-gray-100 p-1 text-gray-500" />
        <textarea
          disabled={createLoading}
          data-testid={`${commentType}-comment-input`}
          onChange={(e) => setText(e.currentTarget.value)}
          value={commentText}
          onKeyDown={(e) => e.key === 'Enter' && onCreateComment(e.currentTarget.value, privateComment)}
          className="h-24 w-full rounded-md border border-gray-200 bg-white text-sm shadow-sm placeholder:text-gray-500"
          placeholder={placeholderText}
        ></textarea>
      </div>
      <div className="flex flex-col gap-y-3" data-testid={`${commentType}-comments`}>
        {Boolean(comments.length) ? (
          <>
            {comments.map((comment) => (
              <div key={comment.id}>
                <SingleComment comment={comment} refetch={refetch} />
              </div>
            ))}
          </>
        ) : (
          <div className="mb-6 mt-8 text-center text-sm text-gray-500">{noCommentText}</div>
        )}
      </div>
    </section>
  )
}

export default memo(CommentBlock)
