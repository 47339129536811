import { graphql } from 'msw'

import { GetCurrentSupplierProductsQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetCurrentSupplierProductsObject: GetCurrentSupplierProductsQuery = {
  currentLegalEntity: {
    id: 44926,
    __typename: 'Supplier',
    availableProducts: {
      edges: [
        {
          node: {
            id: 79030,
            productCode: '',
            image: 'https://cdn.purchaseplus.com/images/products/6e176f7c-591a-41ad-8915-800f8c783bd1.jpg',
            canonicalProductId: 79030,
            brand: 'Knorr',
            itemDescription: 'White Sauce Roux',
            itemSize: 6.0,
            itemMeasure: 'kg',
            itemPackName: 'pail',
            concatenatedSellUnit: 'each of 1',
            __typename: 'Product',
            catalogues: {
              edges: [
                { node: { id: 182601, title: 'test 4', __typename: 'Catalogue' }, __typename: 'CatalogueEdge' },
                { node: { id: 182600, title: 'test 3', __typename: 'Catalogue' }, __typename: 'CatalogueEdge' },
              ],
              __typename: 'CatalogueConnection',
            },
            category: {
              id: 110169,
              name: 'Sauces Marinades and Flavour Bases',
              __typename: 'Category',
              parent: { id: 109477, name: 'Food Preparation Ingredients', __typename: 'Category' },
            },
            owner: { id: 1, name: 'Marketboomer', __typename: 'Organisation' },
          },
          __typename: 'ProductEdge',
        },
        {
          node: {
            id: 5048913,
            productCode: '',
            image: 'https://cdn.purchaseplus.com/images/products/f1e72d4b-88a2-4b8e-ae57-3387f58a5444.jpg',
            canonicalProductId: 5048913,
            brand: 'Allsafe',
            itemDescription: 'Poster : Handwash Guide A4',
            itemSize: 1.0,
            itemMeasure: 'each',
            itemPackName: null,
            concatenatedSellUnit: 'carton of 2',
            __typename: 'Product',
            catalogues: { edges: [], __typename: 'CatalogueConnection' },
            category: {
              id: 110112,
              name: 'Charts and Posters',
              __typename: 'Category',
              parent: { id: 109473, name: 'Published Products', __typename: 'Category' },
            },
            owner: { id: 1, name: 'Marketboomer', __typename: 'Organisation' },
          },
          __typename: 'ProductEdge',
        },
        {
          node: {
            id: 270017,
            productCode: '',
            image: 'https://cdn.purchaseplus.com/images/products/63efbca4-f30e-495e-a288-7664faaf77b4.jpg',
            canonicalProductId: null,
            brand: 'Cape Mentelle - Trinders',
            itemDescription: 'Cabernet Merlot : [Australian]',
            itemSize: 750.0,
            itemMeasure: 'ml',
            itemPackName: 'bottle',
            concatenatedSellUnit: 'carton of 12',
            __typename: 'Product',
            catalogues: { edges: [], __typename: 'CatalogueConnection' },
            category: {
              id: 109606,
              name: 'Red Blend',
              __typename: 'Category',
              parent: { id: 109444, name: 'Wine Red', __typename: 'Category' },
            },
            owner: { id: 1, name: 'Marketboomer', __typename: 'Organisation' },
          },
          __typename: 'ProductEdge',
        },
      ],
      pageInfo: {
        startCursor: 'MQ',
        endCursor: 'MjA',
        hasNextPage: false,
        hasPreviousPage: false,
        __typename: 'PageInfo',
      },
      __typename: 'ProductConnection',
    },
  },
}

export const GetCurrentSupplierProducts = graphql.query<GetCurrentSupplierProductsQuery>(
  'GetCurrentSupplierProducts',
  (_, res, ctx) => {
    return res(ctx.data(baseGetCurrentSupplierProductsObject))
  }
)

export default baseGetCurrentSupplierProductsObject
