import { useMutation } from '@apollo/client'

import { FinancialApproveInvoiceDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useFinancialApproveInvoice() {
  const mutation = useMutation(FinancialApproveInvoiceDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  return mutation
}
