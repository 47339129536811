import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import MatchPOToInvoiceModal from '../page-header/MatchPOToInvoiceModal'
import BalanceContainerRow from '../page-header/balance-panel/BalanceContainerRow'
import InvoiceLinesTableView from './table-view/InvoiceLinesTableView'

import { Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useGetInvoice } from '@/modules/invoices/hooks'
import { InvoiceStatuses } from '@/modules/invoices/types'
import SupplierDetailsModal from '@/modules/requisitions/pages/view-cart/summary/SupplierDetailsModal'
import { Checkbox, QueryResult, Tooltip } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { ControlBarIcon, PlusIcon, QuestionMarkIcon } from '@/modules/shared/icons'

function InvoiceSummary() {
  const { formatMoney } = useMoney()
  const { t } = useTranslation()
  const [activeSupplierDetailsModal, setActiveSupplierDetailsModal] = useState<number | null>(null)
  const [showMatchPOModal, setShowMatchPOModal] = useState<boolean>(false)
  const [advancedView, setAdvancedView] = useState(false)
  const {
    loading,
    error,
    invoiceNumber,
    supplier,
    lineTotal,
    lineTaxTotal,
    deliveryChargeExTax,
    deliveryChargeTax,
    totalValueExTax,
    totalValue,
    totalTaxValue,
    adjustmentExTax,
    adjustmentTax,
    state,
    purchaseOrderId,
    invoiceLinesWithFlags,
    invoiceId,
    flaggedLines,
    refetch,
  } = useGetInvoice()

  const showFlagsColumn = !!flaggedLines.length && (state === InvoiceStatuses.Flagged || state === InvoiceStatuses.Open)
  const isEditable = state === InvoiceStatuses.Open || state === InvoiceStatuses.Flagged

  return (
    <>
      <QueryResult loading={loading} error={error}>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <ControlBarIcon className=" h-10 w-10" />
            <p className="ml-3 text-sm">{invoiceNumber}</p>
          </div>
          {false && // TODO: remove false when advanced invoice line view is ready
            isEditable && (
              <Checkbox reverse onChange={() => setAdvancedView(!advancedView)}>
                <div className="flex gap-x-1" data-testid="advanced-view-button">
                  {t('general.advancedView', 'Advanced View')}
                  <Tooltip
                    content={t(
                      'invoices.invoice.advancedViewTooltip',
                      'View and manage extra information about each Invoice Line such as custom Department and Account Codes, and split expensing.'
                    )}
                  >
                    <QuestionMarkIcon className="h-5 w-5" />
                  </Tooltip>
                </div>
              </Checkbox>
            )}
          {state === InvoiceStatuses.Open && (
            <Link
              to={generatePath('/invoices/:invoiceId/add-line', { invoiceId: String(invoiceId) })}
              className="flex gap-x-2 rounded-md bg-gray-200 px-4 py-3 text-sm hover:bg-gray-300"
              data-testid="add-line-button"
            >
              {t('general.addInvoiceLine', 'Add Invoice Line')}
              <PlusIcon className="h-5 w-5" />
            </Link>
          )}
        </div>
        <section className="mt-4 flex flex-col gap-y-12" data-testid="invoice-info">
          <div className="flex flex-col gap-y-3">
            <div className="flex justify-between border bg-white px-5 py-3">
              <strong className="text-sm">{supplier?.name}</strong>
              <p
                onClick={() => setActiveSupplierDetailsModal(Number(supplier?.id))}
                className="cursor-pointer text-sm text-primary"
                data-testid="supplier-details-button"
              >
                {t('general.viewSupplierDetails', 'View Supplier Details')}
              </p>
            </div>
            <div className="block text-sm lg:hidden">{/* TODO: Responsive view */}</div>
            <div className="hidden text-sm lg:block">
              <InvoiceLinesTableView
                invoiceLines={invoiceLinesWithFlags}
                state={state as InvoiceStatuses}
                supplier={supplier as Supplier}
                purchaseOrderId={purchaseOrderId}
                showFlagsColumn={showFlagsColumn}
                advancedView={advancedView && isEditable}
                refetch={refetch}
              />
              {invoiceLinesWithFlags.length === 0 && (
                <div className="rounded-md border border-gray-200 py-8 text-center text-gray-600">
                  {t('invoice.invoice.noLines', 'There are no Lines on this Invoice.')}
                </div>
              )}
            </div>
            <div className="border bg-white">
              <div className="flex flex-col gap-3 p-3 text-sm" data-testid="invoice-payment">
                <BalanceContainerRow
                  title={t('invoice.invoice.invoiceLinesTotalExTax', 'Invoice Lines Total (Excluding Tax)')}
                  value={(Number(lineTotal) || 0) - (Number(lineTaxTotal) || 0)}
                />
                <BalanceContainerRow
                  title={t('invoice.invoice.invoiceLinesTax', 'Invoice Lines Total Tax')}
                  value={lineTaxTotal}
                />
                <BalanceContainerRow
                  title={t('invoice.invoice.deliveryFeesExTax', 'Delivery Fees (Excluding Tax)')}
                  value={deliveryChargeExTax}
                />
                <BalanceContainerRow
                  title={t('general.deliveryFeesTax', 'Delivery Fees Tax')}
                  value={deliveryChargeTax}
                />
                {(!!adjustmentExTax || !!adjustmentTax) && (
                  <>
                    <BalanceContainerRow
                      title={t('general.adjustmentsExcludingTaxBrackets', 'Adjustments (Excluding Tax)')}
                      value={adjustmentExTax}
                    />
                    <BalanceContainerRow title={t('general.adjustmentsTax', 'Adjustments Tax')} value={adjustmentTax} />
                  </>
                )}
              </div>
              <hr />
              <div className="flex flex-col gap-3 p-3 text-sm" data-testid="invoice-payment">
                <BalanceContainerRow
                  title={t('invoice.invoice.totalExTax', 'Invoice Total (Excluding Tax)')}
                  value={totalValueExTax}
                />
                <BalanceContainerRow title={t('general.invoiceTotalTax', 'Invoice Total Tax')} value={totalTaxValue} />
                <div className="flex items-center justify-between px-2 text-lg font-bold" data-testid="invoice-total">
                  <p>{t('general.invoiceTotal', 'Invoice Total')}</p>
                  {formatMoney(totalValue)}
                </div>
              </div>
            </div>
            <SupplierDetailsModal
              supplier={supplier as Supplier}
              showModal={activeSupplierDetailsModal === supplier?.id}
              setShowModal={setActiveSupplierDetailsModal}
            />
            <MatchPOToInvoiceModal
              invoiceId={invoiceId}
              invoiceNumber={invoiceNumber}
              supplierId={supplier?.id}
              supplierName={supplier?.name}
              showModal={showMatchPOModal}
              setShowModal={setShowMatchPOModal}
            />
          </div>
        </section>
      </QueryResult>
    </>
  )
}

export default InvoiceSummary
