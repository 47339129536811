import i18n from 'i18next'

import { ReceivingNoteState } from '../types'

export const translationStateText = (state: string) => {
  switch (state) {
    case ReceivingNoteState.Open:
      return i18n.t('general.draft', 'Draft')
    case ReceivingNoteState.Received:
      return i18n.t('general.received', 'Received')
    case ReceivingNoteState.Cancelled:
      return i18n.t('general.cancelled', 'Cancelled')
    default:
      return ''
  }
}
