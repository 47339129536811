import { PropsWithChildren, createContext, useContext, useRef } from 'react'
import { AriaCheckboxGroupItemProps, useCheckboxGroup, useCheckboxGroupItem } from 'react-aria'
import { CheckboxGroupProps, CheckboxGroupState, useCheckboxGroupState } from 'react-stately'

import { CheckboxIcon } from '@/modules/shared/icons-special'

let CheckboxGroupContext = createContext<CheckboxGroupState>({
  value: [],
  isDisabled: false,
  isReadOnly: false,
  isSelected: function (value: string): boolean {
    throw new Error('Function not implemented.')
  },
  setValue: function (value: string[]): void {
    throw new Error('Function not implemented.')
  },
  addValue: function (value: string): void {
    throw new Error('Function not implemented.')
  },
  removeValue: function (value: string): void {
    throw new Error('Function not implemented.')
  },
  toggleValue: function (value: string): void {
    throw new Error('Function not implemented.')
  },
  validationState: 'valid',
})

function CheckboxGroup(props: CheckboxGroupProps & PropsWithChildren) {
  let { children, label, description, errorMessage, validationState } = props
  let state = useCheckboxGroupState(props)
  let { groupProps, labelProps, descriptionProps, errorMessageProps } = useCheckboxGroup(props, state)

  return (
    <div {...groupProps}>
      <span {...labelProps} data-testid="checkbox-group-label">
        {label}
      </span>
      <CheckboxGroupContext.Provider value={state}>{children}</CheckboxGroupContext.Provider>
      {description && <div {...descriptionProps}>{description}</div>}
      {errorMessage && validationState === 'invalid' && (
        <div {...errorMessageProps} className="text-error" data-testid="checkbox-group-error">
          {errorMessage}
        </div>
      )}
    </div>
  )
}

function CheckboxGroupItem(props: AriaCheckboxGroupItemProps & PropsWithChildren) {
  let { children } = props
  let state = useContext(CheckboxGroupContext)
  let ref = useRef(null)
  let { inputProps } = useCheckboxGroupItem(props, state, ref)
  let isSelected = state.isSelected(props.value)

  return (
    <label className="flex cursor-pointer items-center gap-2 text-sm">
      <input className="hidden" {...inputProps} ref={ref} />
      <CheckboxIcon isSelected={isSelected} />

      {children}
    </label>
  )
}

export { CheckboxGroup, CheckboxGroupItem }
