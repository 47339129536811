import classNames from 'classnames'
import { ComponentProps, FC, useRef } from 'react'
import { AriaTextFieldProps, useTextField } from 'react-aria'

interface InputProps extends AriaTextFieldProps {
  prefixIcon?: FC<ComponentProps<'svg'>>
  suffixIcon?: FC<ComponentProps<'svg'>>
  hasError?: boolean
  errorMessage?: string
  className?: string
}

export default function Input(props: InputProps) {
  let ref = useRef(null)
  let { inputProps, errorMessageProps } = useTextField(props, ref)
  const SuffixIcon = props.suffixIcon
  const PrefixIcon = props.prefixIcon
  return (
    <div>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 ">
          {PrefixIcon && <PrefixIcon className="h-5 w-5 text-gray-500" />}
        </div>
        <input
          {...inputProps}
          className={classNames(props.className, {
            'pl-9': Boolean(PrefixIcon),
            'pr-9': Boolean(SuffixIcon),
            'border-error focus:border-error focus:outline-none focus:ring-error': props.hasError,
            'focus:border-primary/30 focus:outline-none': !props.hasError,
            'cursor-not-allowed border-gray-300 bg-gray-200': props.isDisabled,
          })}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          {SuffixIcon && <SuffixIcon className="h-5 w-5 text-gray-500" />}
        </div>
      </div>
      {props.errorMessage && (
        <span {...errorMessageProps} className="mt-2 block text-sm text-error">
          {props.errorMessage}
        </span>
      )}
    </div>
  )
}
