import { useTranslation } from 'react-i18next'

import { DeliveryChargeConfiguration } from '@/graphql/purchasing/generated/purchasing_graphql'
import { DetailsPanelItem } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'

interface DeliveryFeeProps {
  deliveryChargeConfiguration: DeliveryChargeConfiguration
}

function DeliveryFees(props: DeliveryFeeProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const { deliveryChargeConfiguration } = props
  const { minimumOrderValueExTax, deliveryChargeExTax, deliveryChargeTaxPercentage } = deliveryChargeConfiguration || {}
  return (
    <>
      {deliveryChargeConfiguration ? (
        <section className="my-2 border" data-testid="delivery-fees">
          <DetailsPanelItem
            title={t('shopPage.cart.summary.details.0to', '{{ zero }} - {{ amount }}', {
              zero: formatMoney(0),
              amount: formatMoney(minimumOrderValueExTax),
            })}
            value={`${formatMoney(deliveryChargeExTax)} ${
              deliveryChargeTaxPercentage
                ? t('shopPage.cart.summary.details.underFeeTax', '(Plus {{ tax }}% Tax)', {
                    tax: deliveryChargeTaxPercentage,
                  })
                : ''
            }`}
          />
          <DetailsPanelItem
            title={t('shopPage.cart.summary.details.aboveFee', 'Above {{ amount }}', {
              amount: formatMoney(minimumOrderValueExTax),
            })}
            value={t('general.freeDelivery', 'Free Delivery')}
          />
        </section>
      ) : (
        <div className="mb-5 mt-2 rounded-md bg-gray-100 p-4 text-center text-sm" data-testid="supplier-details-no-fee">
          {t(
            'shopPage.cart.summary.details.noDeliveryFees',
            'The supplier has not added any delivery fee information to your trading agreement in PurchasePlus. Please contact the supplier using the details above to discuss delivery fees.'
          )}
        </div>
      )}
    </>
  )
}

export default DeliveryFees
