import { useQuery } from '@apollo/client'

import { GetDepartmentsDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useGetDepartments() {
  const query = useQuery(GetDepartmentsDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  return query
}
