import { Dispatch, SetStateAction, memo } from 'react'
import { useTranslation } from 'react-i18next'

import AddOrViewCatalogue from './AddOrViewCatalogue'
import AddOrViewProductCode from './AddOrViewProductCode'

import NoImage from '@/assets/images/no-image.svg'
import { PageInfo, Product } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, Fallback, MoreOptionsMenu } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { ProductItem } from '@/modules/shared/components/table/table-column-items'
import { CheckAltIcon, OptionIcon } from '@/modules/shared/icons'

interface MyProductsTableViewProps {
  products: Product[]
  paginationLoading: boolean
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'endCursor'>
  onNextPage: () => void
  onPreviousPage: () => void
  setShowMatchModal: Dispatch<SetStateAction<boolean>>
}

function MyProductsTableView({
  products,
  pageInfo: { hasNextPage, hasPreviousPage, endCursor },
  onNextPage,
  onPreviousPage,
  paginationLoading,
  setShowMatchModal,
}: MyProductsTableViewProps) {
  const { t } = useTranslation()

  const renderImage = ({ image }: Product) => {
    return (
      <div className="mx-auto h-[3.75rem] w-[3.75rem] cursor-pointer border border-gray-200">
        <img src={image || NoImage} alt="product" />
      </div>
    )
  }

  const renderProduct = (product: Product) => <ProductItem clickToOpenModal product={product} />

  const renderCategory = ({ category }: Product) => {
    return (
      <Fallback condition={!!category?.id}>
        <p>{category?.name}</p>
        <p className="text-xs">{category?.parent?.name}</p>
      </Fallback>
    )
  }

  const renderPPlusCode = ({ canonicalProductId }: Product) => {
    return <Fallback condition={!!canonicalProductId}>{canonicalProductId}</Fallback>
  }

  const renderSelfManaged = ({ owner }: Product) => {
    // MarketBoomer owner id is 1 accross environments: uat, staging, production
    // owner is different to 1 means it's self managed and not controlled by MarketBoomer
    const isSelfManaged = owner?.id !== 1
    return (
      <Fallback condition={!!owner?.id}>{isSelfManaged ? t('general.yes', 'Yes') : t('general.no', 'No')}</Fallback>
    )
  }

  const renderMatchedMasterProduct = ({ canonicalProductId }: Product) => {
    if (!!canonicalProductId)
      return <CheckAltIcon className="mx-auto h-10 w-10 rounded-full bg-success p-2.5 text-white" />
    return (
      <Button
        data-testid="action-match"
        onClick={() => setShowMatchModal(true)}
        className="rounded-full bg-gray-200 px-6 py-3"
      >
        {t('supplier.myProduct.action.match', 'Match')}
      </Button>
    )
  }

  const renderMoreOptions = () => {
    return (
      <MoreOptionsMenu showHelp>
        <OptionIcon className="h-11 w-11 rounded-full p-1.5 text-gray-600 outline-none hover:brightness-95" />
      </MoreOptionsMenu>
    )
  }

  return (
    <Table
      dataTestId="my-products-table-view"
      dataSource={products}
      keyExtractor={(record) => String(record.id)}
      pagination={{
        hasNextPage,
        hasPreviousPage,
        onNextPage: onNextPage,
        onPreviousPage: onPreviousPage,
        loading: paginationLoading,
        endCursor: endCursor,
      }}
      columns={[
        {
          title: t('supplier.myProduct.imageHeader', 'Image'),
          key: 'image',
          headerCellStyles: 'px-4 py-3 text-center',
          cellStyles: 'px-4 py-3 text-center',
          minWidth: 5.625,
          maxWidth: 5.625,
          render: renderImage,
        },
        {
          title: t('supplier.myProduct.productHeader', 'Product'),
          key: 'product',
          headerCellStyles: 'pl-0 pr-4 py-3',
          cellStyles: 'pl-0 pr-4 py-3',
          minWidth: 22,
          maxWidth: 22,
          render: renderProduct,
        },
        {
          title: t('supplier.myProduct.categoryHeader', 'Category'),
          key: 'category',
          headerCellStyles: 'pl-0 pr-4 py-3',
          cellStyles: 'pl-0 pr-4 py-3',
          minWidth: 15,
          grow: true,
          render: renderCategory,
        },
        {
          title: t('supplier.myProduct.pPlusCodeHeader', 'P+ Code'),
          key: 'pPlusCode',
          headerCellStyles: 'px-2 py-3 text-center',
          cellStyles: 'px-2 py-3 text-center text-sm',
          minWidth: 7.5,
          maxWidth: 8.5,
          render: renderPPlusCode,
        },
        {
          title: t('supplier.myProduct.productCodeHeader', 'Code'),
          key: 'productCode',
          headerCellStyles: 'px-2 py-3 text-center',
          cellStyles: 'px-2 py-3 text-center text-sm',
          minWidth: 7.5,
          maxWidth: 8.5,
          render: (product: Product) => <AddOrViewProductCode product={product} />,
        },
        {
          title: t('supplier.myProduct.selfManaged', 'Self-Managed'),
          key: 'selfManaged',
          headerCellStyles: 'px-2 py-3 text-center',
          cellStyles: 'px-2 py-3 text-center text-sm',
          minWidth: 7.5,
          maxWidth: 8.5,
          render: renderSelfManaged,
        },
        {
          title: t('general.catalogs', 'Catalogs'),
          key: 'catalogs',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.75,
          maxWidth: 4.75,
          render: (product: Product) => <AddOrViewCatalogue product={product} />,
        },
        {
          title: t('supplier.myProduct.matchedHeader', 'Matched'),
          key: 'matched',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'text-center py-3',
          minWidth: 6,
          maxWidth: 6,
          render: renderMatchedMasterProduct,
        },
        {
          title: t('supplier.myProduct.moreHeader', 'More'),
          key: 'more',
          headerCellStyles: ' px-4 py-3 text-center',
          cellStyles: 'px-4 py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: renderMoreOptions,
        },
      ]}
    />
  )
}

export default memo(MyProductsTableView)
