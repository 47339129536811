import { GetAccounts } from './GetAccounts'
import { GetAdvancedPossibleRequisitionLines } from './GetAdvancedPossibleRequisitionLines'
import { GetAllBuyLists } from './GetAllBuyLists'
import { GetAllCatalogs } from './GetAllCatalogs'
import { GetAllCreditNotes } from './GetAllCreditNotes'
import { GetAllInvoices } from './GetAllInvoices'
import { GetAllPurchaseOrders } from './GetAllPurchaseOrders'
import { GetAllRequisitions } from './GetAllRequisitions'
import { GetAllSuppliers } from './GetAllSuppliers'
import { GetAvailableProducts } from './GetAvailableProducts'
import { GetBuyList } from './GetBuyList'
import { GetBuyListDetails } from './GetBuyListDetails'
import { GetCategories } from './GetCategories'
import { GetCategoriesWithSub } from './GetCategoriesWithSub'
import { GetCreditNote } from './GetCreditNote'
import { GetCreditNoteComments } from './GetCreditNoteComments'
import { GetCreditNoteDetails } from './GetCreditNoteDetails'
import { GetCurrentLegalEntity } from './GetCurrentLegalEntity'
import { GetCurrentOrganisation } from './GetCurrentOrganisation'
import { GetCurrentPurchaser } from './GetCurrentPurchaser'
import { GetCurrentSupplierProducts } from './GetCurrentSupplierProducts'
import { GetDepartments } from './GetDepartments'
import { GetInvoice } from './GetInvoice'
import { GetInvoiceComments } from './GetInvoiceComments'
import { GetInvoiceLineItem } from './GetInvoiceLineItem'
import { GetInvoiceReceivingNotes } from './GetInvoiceReceivingNotes'
import { GetPOInvoices } from './GetPOInvoices'
import { GetPOReceivingNote } from './GetPOReceivingNote'
import { GetPOReceivingNotes } from './GetPOReceivingNotes'
import { GetPOSendEvents } from './GetPOSendEvents'
import { GetPossibleRequisitionLines } from './GetPossibleRequisitionLines'
import { GetPricedCatalogue } from './GetPricedCatalog'
import { GetPricedCatalogueProduct } from './GetPricedCataloguedProduct'
import { GetPurchaseOrder } from './GetPurchaseOrder'
import { GetPurchaseOrderComments } from './GetPurchaseOrderComments'
import { GetPurchaseOrderLineItem } from './GetPurchaseOrderLineItem'
import { GetPurchaseOrderLinesLinkable } from './GetPurchaseOrderLinesLinkable'
import { GetPurchaser } from './GetPurchaser'
import { GetRequisition } from './GetRequisition'
import { GetRequisitionAttachments } from './GetRequisitionAttachments'
import { GetRequisitionComments } from './GetRequisitionComments'
import { GetRequisitionInvoices } from './GetRequisitionInvoices'
import { GetRequisitionLinesBySuppliers } from './GetRequisitionLinesBySuppliers'
import { GetRequisitionPurchaseOrders } from './GetRequisitionPurchaseOrders'
import { GetRequisitionStatusActivities } from './GetRequisitionStatusActivities'
import { GetRequisitionsApproved } from './GetRequisitionsApproved'
import { GetRequisitionsAwaitingApproval } from './GetRequisitionsAwaitingApproval'
import { GetRequisitionsAwaitingApprovalCount } from './GetRequisitionsAwaitingApprovalCount'
import { GetRequisitionsDraft } from './GetRequisitionsDraft'
import { GetSubCategories } from './GetSubCategories'
import { GetSupplier } from './GetSupplier'
import { GetSupplierRelationship } from './GetSupplierRelationship'
import { GetSupplierRelationshipDetails } from './GetSupplierRelationshipDetails'
import { GetSupplierRelationships } from './GetSupplierRelationships'
import { GetSupplierRelationshipsWithCatalogue } from './GetSupplierRelationshipsWithCatalogue'
import { GetUser } from './GetUser'
import { GetAllReceivingNotes } from './getAllReceivingNotes'

const handlers = [
  GetAccounts,
  GetAllBuyLists,
  GetAllInvoices,
  GetAllPurchaseOrders,
  GetAllRequisitions,
  GetAllSuppliers,
  GetCategories,
  GetDepartments,
  GetInvoice,
  GetPricedCatalogue,
  GetAllCatalogs,
  GetPurchaseOrder,
  GetPOReceivingNote,
  GetRequisition,
  GetRequisitionComments,
  GetRequisitionLinesBySuppliers,
  GetRequisitionPurchaseOrders,
  GetRequisitionsApproved,
  GetRequisitionsAwaitingApproval,
  GetSubCategories,
  GetSupplier,
  GetSupplierRelationship,
  GetSupplierRelationships,
  GetSupplierRelationshipsWithCatalogue,
  GetCurrentPurchaser,
  GetPOReceivingNotes,
  GetPossibleRequisitionLines,
  GetRequisitionsDraft,
  GetPurchaseOrderComments,
  GetInvoiceComments,
  GetRequisitionAttachments,
  GetPurchaseOrderLineItem,
  GetPOInvoices,
  GetPOSendEvents,
  GetRequisitionInvoices,
  GetPricedCatalogueProduct,
  GetAllReceivingNotes,
  GetRequisitionStatusActivities,
  GetInvoiceLineItem,
  GetBuyList,
  GetPurchaseOrderLinesLinkable,
  GetBuyListDetails,
  GetAdvancedPossibleRequisitionLines,
  GetInvoiceReceivingNotes,
  GetCurrentOrganisation,
  GetUser,
  GetPurchaser,
  GetCurrentLegalEntity,
  GetRequisitionsAwaitingApprovalCount,
  GetAllCreditNotes,
  GetCurrentSupplierProducts,
  GetAllCatalogs,
  GetAvailableProducts,
  GetSupplierRelationshipDetails,
  GetCreditNote,
  GetCreditNoteComments,
  GetCreditNoteDetails,
  GetCategoriesWithSub,
]

export default handlers
