import { graphql } from 'msw'

import { GetCategoriesQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

export const GetCategories = graphql.query<GetCategoriesQuery>('GetCategories', (_, res, ctx) => {
  return res(
    ctx.data({
      categories: {
        edges: [
          {
            node: {
              id: 1,
              name: 'Beverage',
              __typename: 'Category',
            },
            __typename: 'CategoryEdge',
          },
          {
            node: {
              id: 2,
              name: 'Equipment and Services',
              __typename: 'Category',
            },
            __typename: 'CategoryEdge',
          },
          {
            node: {
              id: 3,
              name: 'Food',
              __typename: 'Category',
            },
            __typename: 'CategoryEdge',
          },
        ],
        pageInfo: {
          endCursor: null,
          hasNextPage: false,
          __typename: 'PageInfo',
        },
        __typename: 'CategoryConnection',
      },
    })
  )
})
