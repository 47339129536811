import { graphql } from 'msw'

import { GetSupplierQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

export const GetSupplier = graphql.query<GetSupplierQuery>('GetSupplier', (_, res, ctx) => {
  return res(
    ctx.data({
      supplier: {
        id: 168,
        name: 'Zeus Wholesale',
        billingAddressLine1: '1 Missenden',
        billingAddressLine2: 'Building 100 , Harbour bridge crescent street,',
        billingAddressCity: 'Surry Hills',
        billingAddressStateProvince: 'NSW',
        billingAddressPostalCode: '2011',
        postalAddressLine1: '1 Missenden',
        postalAddressLine2: 'Building 100 , Harbour bridge crescent street, ',
        postalAddressCity: 'Surry Hills',
        postalAddressStateProvince: 'NSW',
        postalAddressPostalCode: '2011',
        telephone: '+611300411014',
        email: 'zeuswholesale@gmail.com',
        businessNumber: '062014156',
        creditApplicationUrl: null,
        deliveryChargeConfiguration: {
          deliveryChargeExTax: 25.55,
          deliveryChargeTaxPercentage: 0,
          minimumOrderValueExTax: 85.5,
          __typename: 'DeliveryChargeConfiguration',
        },
        supplierRelationship: {
          id: 67987,
          isEnabled: true,
          __typename: 'SupplierRelationship',
        },
        publicCataloguedProducts: {
          edges: [
            {
              node: {
                id: 22675577,
                image: null,
                sellUnitPrice: 23,
                sellUnitTaxPercentage: 10,
                product: {
                  id: 2044885,
                  category: {
                    id: 109878,
                    name: 'Fruit',
                    parent: {
                      id: 109460,
                      name: 'Fruit and Vegetables',
                      __typename: 'Category',
                    },
                    __typename: 'Category',
                  },
                  brand: '',
                  itemDescription: 'Apple Red/Pink Lady',
                  concatenatedDescription: 'Apple Red/Pink Lady 1 box',
                  concatenatedSellUnit: 'each of 1',
                  itemPackName: '',
                  itemMeasure: 'box',
                  itemSellPackName: 'each',
                  itemSize: 1,
                  __typename: 'Product',
                },
                __typename: 'PricedCataloguedProduct',
              },
              __typename: 'PricedCataloguedProductEdge',
            },
            {
              node: {
                id: 22834783,
                image: 'https://cdn.purchaseplus.com/images/products/5b30a6cc-a41c-49c6-a09f-a58819ac97db.jpg',
                sellUnitPrice: 12,
                sellUnitTaxPercentage: 10,
                product: {
                  id: 11,
                  category: {
                    id: 109877,
                    name: 'Herbs and Spices',
                    parent: {
                      id: 109460,
                      name: 'Fruit and Vegetables',
                      __typename: 'Category',
                    },
                    __typename: 'Category',
                  },
                  brand: '',
                  itemDescription: 'Sage',
                  concatenatedDescription: 'Sage 1 bunch',
                  concatenatedSellUnit: 'each of 1',
                  itemPackName: '',
                  itemMeasure: 'bunch',
                  itemSellPackName: 'each',
                  itemSize: 1,
                  __typename: 'Product',
                },
                __typename: 'PricedCataloguedProduct',
              },
              __typename: 'PricedCataloguedProductEdge',
            },
          ],
          pageInfo: {
            endCursor: 'Mg',
            hasNextPage: false,
            __typename: 'PageInfo',
          },
          __typename: 'PricedCataloguedProductConnection',
        },
        __typename: 'Supplier',
      },
    })
  )
})
