import classNames from 'classnames'
import { PropsWithChildren } from 'react'

import { Themes } from '@/modules/shared/types'

interface ThemeWrapperProps extends PropsWithChildren {
  className?: string
  theme: Themes
}

function ThemeWrapper(props: ThemeWrapperProps) {
  const { children, className, theme } = props

  return (
    <div
      className={classNames(className, {
        'bg-primary/10 text-primary': theme === Themes.Primary,
        'bg-success/10 text-success': theme === Themes.Success,
        'bg-error/10 text-error': theme === Themes.Error,
        'bg-warning/10 text-warning': theme === Themes.Warning,
      })}
    >
      {children}
    </div>
  )
}

export default ThemeWrapper
