import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import EditRequisitionModal from '@/modules/requisitions/pages/view-cart/setting/EditSettingModal'
import { formatStateForLocale } from '@/modules/requisitions/utils'
import { Button, PPNewDetailLink, SimpleTileViewItem, Tooltip } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { CheckAltIcon, CloseIcon, SettingsIcon } from '@/modules/shared/icons'
import { Loading } from '@/modules/shared/icons-special'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface ApprovalItemProps {
  requisition: Requisition
  showActions?: boolean
  showRequisitionButton?: boolean
  showLargeRequisitionButton?: boolean
  showRequisitionExternalButton?: boolean
  showEditRequisitionButton?: boolean
  showLessDetail?: boolean
  isEditButtonFullWidth?: boolean
  onApprove?: (prId: number) => void
  actionLoading?: boolean
  className?: string
}

function ApprovalItem(props: ApprovalItemProps) {
  const {
    requisition,
    className,
    showActions = false,
    showRequisitionButton = false,
    showLargeRequisitionButton = true,
    showEditRequisitionButton = false,
    showLessDetail = false,
    isEditButtonFullWidth = false,
    actionLoading,
    onApprove,
  } = props
  const {
    id,
    state,
    originName,
    reference,
    requisitionNumber,
    totalValueExcludingTax,
    totalTaxValue,
    totalValue,
    expectedDeliveryDate,
    nextApprovingAgentsNames,
    sendToSupplier,
    creator,
    approvalWorkflow,
    department,
    account,
  } = requisition
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [showEditModal, setShowEditModal] = useState(false)
  const showFooter = showEditRequisitionButton || showLargeRequisitionButton || showRequisitionButton || showActions
  return (
    <div className={classNames('mb-6', 'rounded-md', 'border', 'bg-white', className)}>
      <div data-testid="approval-item" className="flex flex-col justify-between p-4 md:flex-row">
        <div>
          <span className="break-all text-base font-bold">{reference || requisitionNumber}</span>
          <p className="text-sm text-gray-500">{requisitionNumber}</p>
        </div>
        <div>
          <div className="mt-4 text-left md:mt-0 md:text-right">
            <span className="text-base font-bold">{`${formatMoney(totalValue)}`}</span>
            <p className="text-sm text-gray-500">
              {t('general.total', 'Total')} ({t('general.includingTax', 'Including Tax')})
            </p>
          </div>
        </div>
      </div>
      <hr />
      <section data-testid="requisition-details" className="p-4 text-sm">
        {!showLessDetail && (
          <SimpleTileViewItem
            className="mb-3"
            title={t('general.status', 'Status')}
            value={formatStateForLocale(state || '')}
          />
        )}
        <SimpleTileViewItem
          className="mb-3"
          title={t('general.requisitionType', 'Requisition Type')}
          value={originName}
        />
        {!showLessDetail && (
          <SimpleTileViewItem className="mb-3" title={t('general.reference', 'Reference')} value={reference} />
        )}
        <SimpleTileViewItem
          className="mb-3"
          title={t('general.requestedBy', 'Requested By')}
          value={creator?.fullName}
        />
        <SimpleTileViewItem
          className="mb-3"
          title={t('general.deliveryDate', 'Delivery Date')}
          value={formatDate(Dates.Short, expectedDeliveryDate)}
        />
        {!showLessDetail && (
          <SimpleTileViewItem
            className="mb-3"
            title={t('general.approvalWorkflow', 'Approval Workflow')}
            value={approvalWorkflow?.title}
          />
        )}
        {!showLessDetail && (
          <SimpleTileViewItem
            className="mb-3"
            title={t('general.nextApprovers', 'Next Approvers')}
            value={nextApprovingAgentsNames}
          />
        )}
        <SimpleTileViewItem className="mb-3" title={t('general.department', 'Department')} value={department?.name} />
        <SimpleTileViewItem
          className="mb-3"
          title={t('general.accountCode', 'Account Code')}
          value={account?.accountName}
        />
        <SimpleTileViewItem
          className="mb-3"
          title={t('requisition.details.sendPO', "Send PO's to Suppliers?")}
          value={`${sendToSupplier ? t('general.yes', 'Yes') : t('general.no', 'No')}`}
        />
        {!showLessDetail && (
          <SimpleTileViewItem
            className="mb-3"
            title={t('general.totalExcludingTaxBrackets', 'Total (Excluding Tax)')}
            value={`${formatMoney(totalValueExcludingTax)}`}
          />
        )}
        {!showLessDetail && (
          <SimpleTileViewItem
            className="mb-3"
            title={t('general.tax', 'Tax')}
            value={`${formatMoney(totalTaxValue)}`}
          />
        )}
        {!showLessDetail && (
          <SimpleTileViewItem
            className="mb-3"
            title={t('general.totalIncludingTaxBrackets', 'Total (Including Tax)')}
            value={`${formatMoney(totalValue)}`}
          />
        )}
      </section>

      {showFooter && <hr />}
      <div
        className={classNames('flex w-full items-center', {
          'justify-end': showEditRequisitionButton,
          'justify-between': !showEditRequisitionButton,
          'p-4': showFooter,
        })}
      >
        {showEditRequisitionButton && (
          <Button
            data-testid="edit-requisition-btn"
            className={classNames(
              'flex h-11 w-full items-center justify-center gap-x-2 rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95',
              {
                'md:w-fit': !isEditButtonFullWidth,
              }
            )}
            onClick={() => setShowEditModal(true)}
          >
            {t('general.editRequisitionSettings', 'Edit Requisition Settings')}
            <SettingsIcon className="h-6 w-6" />
          </Button>
        )}
        {showRequisitionButton && (
          <PPNewDetailLink
            type="requisition"
            id={String(id)}
            className="flex h-11 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
          >
            {t('general.viewRequisition', 'View Requisition')}
          </PPNewDetailLink>
        )}
        {showLargeRequisitionButton && (
          <PPNewDetailLink
            openNewTab
            type="requisition"
            testId="view-requisition-link-new-tab"
            id={String(id)}
            className="flex h-11 w-full items-center justify-center gap-x-2 rounded-md border p-4 text-center text-sm transition hover:brightness-95"
          >
            {t('general.viewRequisition', 'View Requisition')}
          </PPNewDetailLink>
        )}
        {showActions && (
          <div className="flex items-center gap-2">
            <Tooltip content={t('general.clickToDecline', 'Click to Decline')}>
              <Link
                data-testid="decline-button"
                to={generatePath('/requisitions/:id/decline', {
                  id: String(id),
                })}
                className="flex h-12 w-12 items-center justify-center rounded-full bg-error text-white transition hover:brightness-90"
              >
                <CloseIcon className="h-6 w-6" />
              </Link>
            </Tooltip>
            <Tooltip content={t('general.clickToApprove', 'Click to Approve')}>
              <Button
                data-testid="approve-button"
                disabled={actionLoading}
                className="flex h-12 w-12 items-center justify-center rounded-full bg-success text-white"
                onClick={() => onApprove && onApprove(id)}
              >
                {actionLoading ? (
                  <Loading className="h-6 w-6 fill-white text-gray-300" />
                ) : (
                  <CheckAltIcon className="h-5 w-5" />
                )}
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <EditRequisitionModal requisition={requisition} showModal={showEditModal} setShowModal={setShowEditModal} />
    </div>
  )
}

export default ApprovalItem
