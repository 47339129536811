import { PropsWithChildren } from 'react'

interface BadgeIconProps extends PropsWithChildren {
  isBadged: boolean
}

export const BadgeIcon = ({ children, isBadged }: BadgeIconProps) => {
  return (
    <div className="relative">
      {children}
      {isBadged && <div className="absolute right-0 top-0 inline-block h-2.5 w-2.5 rounded-full bg-error" />}
    </div>
  )
}
