export type BuyListFormInputs = {
  title: string
  departmentId: number
  accountId: number
}

export enum BuyListTypeKey {
  Requisition = 'requisition',
  Inventory = 'inventory',
}
