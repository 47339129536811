import classNames from 'classnames'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import InvoiceLineReceivedStatus from '../InvoiceLineReceivedStatus'
import LinkInvoiceModal from '../link-invoice-modal/LinkInvoiceModal'
import InvoiceLinesAdvancedTableView from './InvoiceLinesAdvancedTableView'
import InvoiceLineModal from './invoice-line-modal'

import { InvoiceLineItem, Product, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useInvoiceLineItemModalText, useReopenInvoice } from '@/modules/invoices/hooks'
import { InvoiceReceivedStatuses, InvoiceStatuses } from '@/modules/invoices/types'
import { translateFlagCode } from '@/modules/invoices/utils/translateFlagCode'
import { Button, ConfirmDialog, Fallback, Tooltip } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { ProductItem } from '@/modules/shared/components/table/table-column-items'
import { UnitPrice } from '@/modules/shared/components/table/table-column-items'
import { useMoney } from '@/modules/shared/hooks'
import { ChainIcon, FlagIcon, InfoIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface InvoiceLinesTableViewProps {
  invoiceLines: InvoiceLineItem[]
  state: InvoiceStatuses
  purchaseOrderId: number | null | undefined
  supplier: Supplier
  showFlagsColumn: boolean
  advancedView: boolean
  refetch: () => void
}

function InvoiceLinesTableView({
  invoiceLines,
  state,
  supplier,
  purchaseOrderId,
  showFlagsColumn,
  advancedView,
  refetch,
}: InvoiceLinesTableViewProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  const [showLinkInvoiceModal, setShowLinkInvoiceModal] = useState(false)
  const [showInvoiceLineModal, setShowInvoiceLineModal] = useState(false)
  const [activeInvoiceLine, setActiveInvoiceLine] = useState<number | null>(null)

  const { title, description, secondaryButton } = useInvoiceLineItemModalText(state)

  const [reopenInvoice] = useReopenInvoice()

  const onHandleLineAction = ({ invoiceId }: InvoiceLineItem) => {
    reopenInvoice({ variables: { input: { id: Number(invoiceId) } } })
  }

  const renderLinkedPO = (record: InvoiceLineItem) => {
    const { purchaseOrderLineItemId } = record
    // show if line is linked or state is open or flagged when line is unlinked but invoice is linked to a purchase order
    const allowShowInvoiceModal =
      !!purchaseOrderLineItemId ||
      (!purchaseOrderLineItemId && state === InvoiceStatuses.Open) ||
      (!purchaseOrderLineItemId && state === InvoiceStatuses.Flagged)
    return (
      <>
        <ChainIcon
          className={classNames('mx-auto h-11 w-11 cursor-pointer rounded-full p-2', {
            'bg-primary text-white': !!purchaseOrderLineItemId,
            'bg-gray-200 text-gray-500': !purchaseOrderLineItemId,
          })}
          data-testid="link-button"
          onClick={() => {
            setActiveInvoiceLine(record.id)
            setShowLinkInvoiceModal(true)
          }}
        />
        {showLinkInvoiceModal &&
          (allowShowInvoiceModal ? (
            <LinkInvoiceModal
              line={record}
              supplier={supplier}
              showModal={showLinkInvoiceModal && activeInvoiceLine === record.id}
              setShowModal={setShowLinkInvoiceModal}
              purchaseOrderId={purchaseOrderId}
            />
          ) : (
            <ConfirmDialog
              theme={Themes.Primary}
              isOpen={showLinkInvoiceModal && activeInvoiceLine === record.id}
              setIsOpen={setShowLinkInvoiceModal}
              title={title}
              description={description}
              primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
              onPrimaryBtnClick={() => setShowLinkInvoiceModal(false)}
              secondaryButtonLabel={secondaryButton}
              onSecondaryBtnClick={() => onHandleLineAction(record)}
            />
          ))}
      </>
    )
  }

  const renderFlags = ({ flaggingMessages }: InvoiceLineItem) => {
    // Allows the tooltip to display the messages with line breaks
    const flagMessageText = (
      <div className="space-y-1">
        {/* The first string in the flagging messages array is the name of the product and must be excluded */}
        {flaggingMessages?.map((message, index) => (
          <p className="text-center" key={index}>
            {translateFlagCode(message.type, message.values)}
          </p>
        ))}
      </div>
    )

    return (
      <div className="flex items-center justify-center" data-testid="invoice-line-flag">
        <Fallback condition={!!flaggingMessages?.length}>
          <Tooltip content={flagMessageText}>
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-error/10 text-error">
              <FlagIcon className="h-6 w-6" />
            </div>
          </Tooltip>
        </Fallback>
      </div>
    )
  }

  const renderReceived = ({ receivedStatus, totalInvoicedQuantity, totalReceivedQuantity }: InvoiceLineItem) => {
    return (
      <InvoiceLineReceivedStatus
        receivedStatus={receivedStatus as InvoiceReceivedStatuses}
        totalInvoicedQuantity={Number(totalInvoicedQuantity)}
        totalReceivedQuantity={Number(totalReceivedQuantity)}
        purchaseOrderId={Number(purchaseOrderId)}
      />
    )
  }

  const renderInfo = ({ invoiceId, id: lineId }: InvoiceLineItem) => {
    return (
      <div className="flex items-center justify-center">
        <Button
          data-testid="invoice-line-modal-button"
          className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200"
          onClick={() => {
            setActiveInvoiceLine(lineId)
            setShowInvoiceLineModal(true)
          }}
        >
          <InfoIcon className="h-6 w-6" />
        </Button>
        <InvoiceLineModal
          show={showInvoiceLineModal && activeInvoiceLine === lineId}
          setShow={setShowInvoiceLineModal}
          invoiceId={invoiceId}
          invoiceLineItemId={lineId}
        />
      </div>
    )
  }

  const renderQuantity = ({ quantity }: InvoiceLineItem) => {
    return (
      <p
        data-testid="invoice-line-quantity"
        className="m-auto w-fit min-w-[4rem] rounded-md border border-gray-300 bg-gray-200 px-4 py-3 text-center"
      >
        {quantity}
      </p>
    )
  }

  const renderUnitPrice = ({ unitPrice }: InvoiceLineItem) => {
    return (
      <div className="flex items-center justify-end">
        <p
          data-testid="invoice-line-price"
          className="w-fit min-w-[6rem] rounded-md border border-gray-300 bg-gray-200 py-3 pl-4 pr-2 text-right"
        >{`${formatMoney(unitPrice)}`}</p>
      </div>
    )
  }

  const renderTax = ({ taxPercentage }: InvoiceLineItem) => {
    return (
      <>
        <p
          data-testid="invoice-line-tax"
          className="m-auto w-fit min-w-[4rem] rounded-md border border-gray-300 bg-gray-200 px-4 py-3 text-center"
        >
          {`${taxPercentage}%`}
        </p>
      </>
    )
  }

  const columns = [
    {
      title: t('general.linked', 'Linked'),
      key: 'linked',
      headerCellStyles: 'py-3 text-center',
      cellStyles: 'py-3 text-center',
      minWidth: 4.375,
      maxWidth: 4.375,
      render: renderLinkedPO,
    },
    {
      title: t('general.product', 'Product'),
      key: 'product',
      headerCellStyles: 'pr-4 py-3',
      cellStyles: 'pr-4 py-3',
      minWidth: 13.75,
      grow: true,
      render: (record: InvoiceLineItem) => (
        <ProductItem product={record.product as Product} clickToOpenModal supplier={supplier} line={record} />
      ),
    },
    {
      title: t('general.flags', 'Flags'),
      key: 'flags',
      headerCellStyles: 'py-3 text-center',
      cellStyles: 'py-3 text-center',
      minWidth: 4.375,
      maxWidth: 4.375,
      render: renderFlags,
    },
    {
      title: t('general.received', 'Received'),
      key: 'received',
      headerCellStyles: 'py-3 text-center',
      cellStyles: 'py-3 text-center',
      minWidth: 8.75,
      maxWidth: 8.75,
      render: renderReceived,
    },
    {
      title: t('general.info', 'Info'),
      key: 'info',
      headerCellStyles: 'py-3 text-center',
      cellStyles: 'py-3 text-center',
      minWidth: 4.375,
      maxWidth: 4.375,
      render: renderInfo,
    },
    {
      title: t('general.quantity', 'Quantity'),
      key: 'quantity',
      headerCellStyles: 'py-3 text-center',
      cellStyles: 'py-3 text-center',
      minWidth: 5.1,
      maxWidth: 5.1,
      render: renderQuantity,
    },
    {
      title: t('general.unitPrice', 'Unit Price'),
      key: 'unitPrice',
      headerCellStyles: 'py-3 pr-2 text-right',
      cellStyles: 'py-3 pr-2 text-right',
      minWidth: 5.625,
      maxWidth: 7.5,
      render: renderUnitPrice,
    },
    {
      title: t('general.tax', 'Tax'),
      key: 'tax',
      headerCellStyles: 'py-3 text-center',
      cellStyles: 'py-3 text-center',
      minWidth: 4.375,
      maxWidth: 4.375,
      render: renderTax,
    },
    {
      title: t('general.total', 'Total'),
      key: 'total',
      headerCellStyles: 'py-3 pr-5 text-right',
      cellStyles: 'py-3 pr-5 text-right',
      minWidth: 6.25,
      maxWidth: 8.125,
      render: ({ lineTotal }: InvoiceLineItem) => <UnitPrice unitPrice={lineTotal} />,
    },
  ]

  return (
    <>
      {advancedView ? (
        <InvoiceLinesAdvancedTableView
          invoiceLines={invoiceLines}
          showFlagsColumn={showFlagsColumn}
          state={state}
          refetch={refetch}
          columns={columns}
        />
      ) : (
        <Table
          dataSource={invoiceLines}
          keyExtractor={(record) => String(record.id)}
          columnsHidden={!showFlagsColumn ? ['flags'] : []}
          columns={columns}
        />
      )}
    </>
  )
}

export default memo(InvoiceLinesTableView)
