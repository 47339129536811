import { graphql } from 'msw'

import { DeleteCommentMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const DeleteComment = graphql.mutation<DeleteCommentMutation>('DeleteComment', (req, res, ctx) => {
  return res(
    ctx.data({
      deleteComment: {
        success: 'Resource was deleted successfully',
        __typename: 'DeleteCommentMutationPayload',
      },
    })
  )
})
