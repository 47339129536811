import ReactGA from 'react-ga4'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import ReactAriaI18nProvider from './modules/shared/providers/ReactAriaI18nProvider'
import AllRoutes from './routes/all-routes'

import ApolloProviderWrapper from '@/apollo/ApolloProviderWrapper'
import i18nextConfig from '@/locales/i18nextConfig'
import AlertDialog from '@/modules/shared/components/alert-dialog/alert-dialog'
import { AlertProvider } from '@/modules/shared/providers/AlertProvider'

i18nextConfig()

if (process.env.REACT_APP_GA4_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID)
  ReactGA.send('pageview')
}

function App() {
  return (
    <AlertProvider>
      <ApolloProviderWrapper>
        <ReactAriaI18nProvider>
          <HelmetProvider>
            <BrowserRouter>
              <QueryParamProvider adapter={ReactRouter6Adapter}>
                <AllRoutes />
              </QueryParamProvider>
            </BrowserRouter>
          </HelmetProvider>
          <AlertDialog />
        </ReactAriaI18nProvider>
      </ApolloProviderWrapper>
    </AlertProvider>
  )
}

export default App
