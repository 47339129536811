import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ColumnDefinition } from '../Table'

import { Button, Checkbox, Modal, Tooltip } from '@/modules/shared/components'
import { QuestionMarkIcon } from '@/modules/shared/icons'

export interface ManageColumnPreferencesModalProps<T> {
  showModal: boolean
  setShowModal: (value: false) => void
  filteredColumns: ColumnDefinition<T>[]
  setFilteredColumns: (filteredColumns: ColumnDefinition<T>[]) => void
}

export default function ManageColumnPreferencesModal<T>({
  showModal,
  setShowModal,
  filteredColumns,
  setFilteredColumns,
}: ManageColumnPreferencesModalProps<T>) {
  const { t } = useTranslation()

  const [updatedPreferences, setUpdatedPreferences] = useState<ColumnDefinition<T>[]>(filteredColumns)

  useEffect(() => {
    if (showModal) {
      // update the checkboxes when the modal is shown, was retaining dirty state when closing and reopening without saving
      setUpdatedPreferences(filteredColumns)
    }
  }, [showModal])

  const onCloseModal = () => {
    setShowModal(false)
  }

  const handleCheckboxChange = (changeIndex: number) => {
    setUpdatedPreferences((prevPreferences) =>
      prevPreferences.map((col, index) => (index === changeIndex ? { ...col, hidden: !col.hidden } : col))
    )
  }

  const handleSave = () => {
    setFilteredColumns(updatedPreferences || filteredColumns)
    onCloseModal()
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        as="div"
        className="flex max-h-[80vh] w-full flex-col rounded-md bg-white shadow-xl transition-all md:max-w-[56.25rem]"
      >
        <Modal.Title
          title={t('general.manageColumnPreferences', 'Manage Column Preferences')}
          onCloseModal={onCloseModal}
        />
        <Modal.Body className="space-y-5 overflow-y-auto" data-testid="manage-col-modal">
          <div className="text-sm">
            <p className="font-bold">{t('general.columnPreferences', 'Column Preferences')}</p>
            <p className="text-gray-500">
              <Trans key={'manageColumnPreferencesModal.description'}>
                Here you can manage your column preferences for this table view. These preferences will be saved onto
                your account at this organisation and they will be applied to this view each time you visit it. You can
                decide which columns to show or hide, as well as which column to sort the view by. For technical reasons
                there are some columns that cannot be hidden or sorted by. If required, you can reset this view to the
                system defaults by clicking the restore button below.
              </Trans>
            </p>

            <div className="mt-2 flex w-full justify-between">
              <div className="w-max px-4 py-3 text-left text-sm text-gray-500">
                {t('general.columnName', 'Column Name')}
              </div>
              <div className="w-max px-6 py-3 text-right text-sm text-gray-500">{t('general.show', 'Show')}</div>
            </div>
            <div className="w-full rounded-md border">
              <table className="w-full divide-y">
                <tbody className="divide-y divide-gray-200">
                  {updatedPreferences?.map(
                    ({ key, title, hidden, headerTooltip, grow, alwaysVisible }: ColumnDefinition<T>, index) => (
                      <tr key={key} className="items-center bg-gray-100 p-2">
                        <td className="px-4 py-3">
                          <div className="flex grow flex-row items-center justify-start gap-x-1.5 text-center">
                            {headerTooltip ? (
                              <Tooltip content={headerTooltip}>
                                {title}
                                <QuestionMarkIcon className="h-4 w-4" />
                              </Tooltip>
                            ) : (
                              title
                            )}
                          </div>
                        </td>
                        <td className="flex justify-end px-4 py-3 text-end">
                          <div className="w-10" data-testid="show-column-checkbox">
                            <Checkbox
                              isSelected={!hidden}
                              aria-label={title}
                              onChange={() => handleCheckboxChange(index)}
                              disabled={grow || alwaysVisible}
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-end">
          <div className="flex w-full flex-col-reverse gap-2 md:w-fit md:flex-row">
            <Button
              className="h-11 w-full rounded-md bg-gray-200 px-6 text-sm md:w-fit"
              onClick={onCloseModal}
              data-testid="cancel-manage-col-modal"
            >
              {t('general.cancel', 'Cancel')}
            </Button>

            <Button
              className="h-11 w-full min-w-[9.3rem] rounded-md bg-primary px-6 text-sm text-white md:w-fit"
              onClick={handleSave}
              data-testid="submit-manage-col-modal"
            >
              {t('general.saveChanges', 'Save Changes')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
