import { Dispatch, SetStateAction } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ReceivingDocumentLineItemInput } from '@/modules/purchase-orders/types'
import { Input } from '@/modules/shared/components'

interface DeliveryVehicleTemperatureProps {
  vehicleTemperature: string
  isReadOnly: boolean
  setInputFocus: Dispatch<SetStateAction<boolean>>
}

export default function DeliveryVehicleTemperature({
  vehicleTemperature,
  setInputFocus,
  isReadOnly,
}: DeliveryVehicleTemperatureProps) {
  const { t } = useTranslation()
  const { control } = useFormContext<ReceivingDocumentLineItemInput>()
  return (
    <section>
      <strong className="text-sm">{t('general.vehicleTemperature', 'Vehicle Temperature')}</strong>
      <p className="text-sm text-gray-500">
        {t(
          'receivingNotes.advancedModal.vehicleTemperatureDesc',
          'If required, use the field below to record the temperature of the delivery vehicle at the time this product was received.'
        )}
      </p>
      <div className="mt-4">
        <Controller
          control={control}
          name="vehicleTemperature"
          defaultValue={vehicleTemperature || ''}
          render={({ field }) => (
            <Input
              isDisabled={isReadOnly}
              data-testid="vehicle-temperature-input"
              className="w-full rounded-md border border-gray-200 p-3 text-sm focus:ring-primary"
              aria-label={t('general.deliveryVehicleTemperature', 'Delivery Vehicle Temperature')}
              placeholder={t('general.deliveryVehicleTemperature', 'Delivery Vehicle Temperature')}
              value={field.value}
              onFocus={() => setInputFocus(true)}
              onChange={(e) => field.onChange(e)}
            />
          )}
        />
      </div>
    </section>
  )
}
