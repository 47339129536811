import classNames from 'classnames'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { useMoney } from '../../hooks'
import { MaybeProps } from '../../types'
import Button from '../button'
import { Modal } from '../modal'
import DeliveryFeesDetails from './DeliveryFees'
import ProductDetails from './ProductDetails'
import SupplierDetails from './SupplierDetails'

import { Product, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'

// Properties that will only be used in this modal
interface LineProps {
  taxPercentage: number
  unitPrice: number | string
  image: string
  supplierProductCode: string
  longDescription: string
  product: Product
}

export type Line = Partial<MaybeProps<LineProps>>

interface ProductModalProps<T extends Line> {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  line?: T
  supplier?: Supplier
  product?: Product
}

export default function ProductModal<T extends Line>({
  showModal,
  setShowModal,
  supplier,
  line,
  product,
}: ProductModalProps<T>) {
  const { taxPercentage, unitPrice } = line || {}
  const { brand, itemDescription } = line?.product || product || {}

  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  const onCloseModal = () => setShowModal(false)
  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        data-testid="product-details-modal"
        className="flex h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]"
      >
        <Modal.Title title={`${brand || ''} ${itemDescription || ''}`} onCloseModal={onCloseModal} />

        <Modal.Body className="overflow-y-scroll">
          <ProductDetails line={line} supplier={supplier} product={product} />
          {supplier && (
            <>
              <SupplierDetails supplier={supplier} />
              <DeliveryFeesDetails supplier={supplier} />
            </>
          )}
        </Modal.Body>
        <Modal.Footer
          className={classNames('flex flex-col gap-2 md:flex-row md:items-center', {
            'justify-between': line,
            'justify-end': !line,
          })}
        >
          {line && (
            <span>
              <p className="text-base font-bold leading-tight sm:text-lg">{formatMoney(unitPrice)}</p>
              <p className="text-xxs text-gray-500 sm:text-xs">
                {taxPercentage
                  ? t('shopPage.productList.productCard.taxPercentage', 'Plus {{ percent }}% Tax', {
                      percent: taxPercentage,
                    })
                  : t('general.taxFree', 'Tax Free')}
              </p>
            </span>
          )}
          <Button className="h-11 w-full rounded-md bg-gray-200 px-8 text-sm md:w-fit" onClick={onCloseModal}>
            {t('general.close', 'Close')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
