import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DeliveryAddress } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useGetDeliveryAddress } from '@/modules/requisitions/hooks'
import { RequisitionFormInputs } from '@/modules/requisitions/types'
import { ComboboxServer } from '@/modules/shared/components'

interface SelectAddressProps {
  defaultValue?: DeliveryAddress
  showExplanation?: boolean
  disabled?: boolean
  title: string
  description: string
}

export default function SelectAddress(props: SelectAddressProps) {
  const { defaultValue, disabled, showExplanation = true, title, description } = props
  const {
    formState: { errors },
    control,
  } = useFormContext<RequisitionFormInputs>()

  const { t } = useTranslation()
  const { deliveryAddresses, defaultDeliveryAddress, networkStatus, actions, pageInfo } = useGetDeliveryAddress()

  return (
    <section className="mt-8">
      {showExplanation && (
        <>
          <h2 className="text-sm font-semibold">{title}</h2>
          <p className="text-sm text-gray-500">{description}</p>
        </>
      )}
      <div className="mt-4">
        <Controller
          control={control}
          name="deliveryAddressId"
          rules={{ required: true }}
          render={({ field }) => (
            <ComboboxServer
              placeholder={t('createRequisition.address.placeholder', 'Select a Delivery Address...')}
              networkStatus={networkStatus}
              onDisplay={(e) => `${e.locationName} ${e.line1} ${e.line2} ${e.city} ${e.stateProvince} ${e.country}`}
              hasError={Boolean(errors.deliveryAddressId)}
              errorMessage={t('createRequisition.address.required', 'You must select a Delivery Address.')}
              keyExtractor={(e) => `${e.id}`}
              items={deliveryAddresses}
              onInputChange={(e) =>
                actions.refetch({
                  filter: {
                    q: [
                      {
                        property: 'search_text_or_locationName_or_country_cont',
                        value: e,
                      },
                    ],
                  },
                })
              }
              onSelected={(e) => field.onChange(e.id)}
              onFetchMore={() =>
                actions.fetchMore({
                  variables: {
                    after: pageInfo?.endCursor,
                  },
                })
              }
              hasMore={Boolean(pageInfo?.hasNextPage)}
              defaultValue={defaultValue || (defaultDeliveryAddress as DeliveryAddress)}
              disabled={disabled}
              testId="select-address"
            />
          )}
        />
      </div>
    </section>
  )
}
