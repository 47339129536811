import { useQuery } from '@apollo/client'

import { GetAllUsersDocument } from '@/graphql/access/generated/access_graphql'
import { User } from '@/graphql/access/generated/access_graphql'
import { PageInfo, RansackDirection, RansackMatcher } from '@/graphql/purchasing/generated/purchasing_graphql'
import { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export default function useGetAllUsers() {
  const paginationResultsPerPage = 20
  const { data, networkStatus, error, refetch, fetchMore } = useQuery(GetAllUsersDocument, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: paginationResultsPerPage,
      after: null,
      filter: {},
      sort: [
        {
          direction: RansackDirection.Asc,
          property: 'fullName',
        },
      ],
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const pageInfo = data?.currentOrganisationUnit?.users?.pageInfo || ({} as PageInfo)

  const onPreviousPage = () => {
    const { startCursor } = pageInfo
    refetch({
      before: startCursor,
      after: null,
      last: paginationResultsPerPage,
      first: null,
    })
  }

  const onNextPage = () => {
    const { endCursor } = pageInfo
    refetch({
      after: endCursor,
      before: null,
      first: paginationResultsPerPage,
      last: null,
    })
  }

  const onFetchMore = () => {
    fetchMore({
      variables: {
        after: data?.currentOrganisationUnit?.users?.pageInfo.endCursor,
        before: null,
        last: null,
        first: paginationResultsPerPage,
      },
    })
  }

  const onFilter = ({ searchValue, filterValue }: OnFilter) => {
    let filter: RansackMatcher[] = []

    if (searchValue) {
      filter.push({
        property: 'fullName_cont',
        value: searchValue.trim(),
      })
    }

    if (filterValue) {
      let value: string | boolean = false
      if (filterValue !== 'all' && filterValue === 'enabled') value = false
      if (filterValue !== 'all' && filterValue === 'disabled') value = true
      if (filterValue === 'all') value = ''
      filter.push({
        property: 'isDeleted_eq',
        value,
      })
    }

    refetch({
      filter: {
        q: [...filter],
      },
      first: paginationResultsPerPage,
      after: null,
      last: null,
    })
  }

  return {
    users: extractEdges<User>(data?.currentOrganisationUnit?.users),
    loadingStates: checkNetworkStatus(networkStatus),
    error,
    refetch,
    paginationResultsPerPage,
    pageInfo,
    onNextPage,
    onPreviousPage,
    onFetchMore,
    onFilter,
  }
}
