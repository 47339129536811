import dayjs from 'dayjs'
import { Helmet } from 'react-helmet-async'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreateInvoice } from '../../hooks'
import InvoiceTotals from './invoice-totals/InvoiceTotals'

import { CreateInvoiceFormInputs } from '@/modules/invoices/types'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks'
import { DeptAndAccount } from '@/modules/requisitions/pages/create/form-elements'
import CreateFooter from '@/modules/shared/components/create-form/CreateFooter'
import FormDate from '@/modules/shared/components/create-form/FormDate'
import FormInput from '@/modules/shared/components/create-form/FormInput'
import SelectSupplierRelationship from '@/modules/shared/components/create-form/SelectSupplierRelationship'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

export default function CreateInvoice() {
  const { t } = useTranslation()
  const { currentPurchaser } = useCurrentPurchaser()
  const formMethods = useForm<CreateInvoiceFormInputs>({
    defaultValues: {
      invoiceDate: formatDate(Dates.ShortYear, dayjs().add(1, 'days')),
      purchaserOrganisationId: currentPurchaser?.id,
      totalExTax: 0,
      totalTaxValue: 0,
    },
  })

  const [createInvoice, { loading }] = useCreateInvoice()

  const onSubmit: SubmitHandler<CreateInvoiceFormInputs> = (data) => {
    const { totalExTax, totalTaxValue } = data
    data.totalValue = Number(totalExTax) + Number(totalTaxValue)
    data.totalTaxValue = Number(data.totalTaxValue)
    delete data.totalExTax
    createInvoice({
      variables: { input: data },
    })
  }

  return (
    <>
      <Helmet>
        <title>{t('createInvoice.pageTitle', 'Create a New Invoice')}</title>
      </Helmet>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} className="mb-80">
        <div className="mx-auto my-20 w-full p-4 md:w-[50rem]">
          <h1 className="text-2xl font-semibold">{t('createInvoice.pageTitle', 'Create a New Invoice')}</h1>
          <FormProvider {...formMethods}>
            <FormInput
              title={t('general.invoiceNumber', 'Invoice Number')}
              description={t(
                'createInvoice.invoiceNumber.explain',
                'An Invoice Number is required to identify this Invoice. The Invoice number needs to be unique per Supplier.'
              )}
              placeholder={t('general.invoiceNumber', 'Invoice Number')}
              required
              requiredError={t('createInvoice.invoiceNumber.requiredError', 'You must provide an Invoice Number')}
              register="invoiceNumber"
            />
            <FormInput
              title={t('general.reference', 'Reference')}
              description={t(
                'createInvoice.reference.explain',
                'Provide a Reference to describe this Invoice, a Reference is optional.'
              )}
              placeholder={t('general.reference', 'Reference')}
              register="reference"
            />
            <FormDate
              title={t('general.invoiceDate', 'Invoice Date')}
              description={t(
                'createInvoice.invoiceDate.explain',
                'This is the date the Invoice is issued to your organisation. An Invoice Date must be provided.'
              )}
              register="invoiceDate"
            />
            <SelectSupplierRelationship
              title={t('general.selectASupplier', 'Select a Supplier')}
              description={t(
                'createInvoice.supplier.explain',
                'Please select a Supplier that the Invoice has been Issued by. A Supplier must be selected here and it cannot be changed once the Invoice has been created. If creating an Invoice for a new Supplier that has not yet been added to your Organisation, please speak with an Administrator at your Organisation to have the Supplier added.'
              )}
              placeholder={t('general.selectASupplierDots', 'Select a Supplier...')}
              register="supplierOrganisationId"
              required
              requiredError={t('createInvoice.supplier.required', 'You must select a Supplier.')}
            />
            <DeptAndAccount
              title={t('createInvoice.deptAndAcc.title', 'Department and Account Code')}
              description={t(
                'createInvoice.deptAndAcc.explain',
                'If applicable, select a Department then an Account Code to record this Invoice against. The Department and Account Code is optional, and can be added after the Invoice has been created. Your organisation may use Department and Account Codes for recording Invoice expenses.'
              )}
              required={false}
            />
            <InvoiceTotals />
          </FormProvider>
          <CreateFooter
            getHelpText={t('createInvoice.actions.help', 'Get Help creating Invoices')}
            createButtonText={t('general.createInvoice', 'Create Invoice')}
            createLoading={loading}
          />
        </div>
      </form>
    </>
  )
}
