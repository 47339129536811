import classNames from 'classnames'

import { CloseIcon } from '../../icons'
import { Themes } from '../../types'
import Button from '../button'
import Tooltip from '../tooltip'

import { Loading } from '@/modules/shared/icons-special'

interface ExtraLineProps {
  title: string
  value: string
  onLineClick?: () => void
  onRemoveLine?: () => void
  removeLineLoading?: boolean
  removeLineTooltipContent?: string
  removeActionTestId?: string
  showRemoveLineAction?: boolean
  dataTestId?: string
  isResponsive?: boolean
  theme: Themes
}

export default function ExtraLine({
  dataTestId,
  removeActionTestId,
  title,
  value,
  onLineClick,
  onRemoveLine,
  removeLineTooltipContent = '',
  removeLineLoading = false,
  showRemoveLineAction = false,
  theme = Themes.Primary,
  isResponsive = true,
}: ExtraLineProps) {
  const textColor = () => {
    switch (theme) {
      case Themes.Primary:
        return 'text-primary'
      case Themes.Warning:
        return 'text-warning'
      case Themes.Error:
        return 'text-error'
      case Themes.Success:
        return 'text-success'
      default:
        return 'text-black'
    }
  }

  return (
    <div
      data-testid={dataTestId}
      className={classNames('flex cursor-pointer items-center justify-between gap-x-4 rounded-md px-3 py-2', {
        'bg-primary/20': theme === Themes.Primary,
        'bg-warning/20': theme === Themes.Warning,
        'bg-error/20': theme === Themes.Error,
        'bg-success/20': theme === Themes.Success,
      })}
      onClick={() => onLineClick && onLineClick()}
    >
      <div
        className={classNames({
          'flex flex-col justify-center text-xs md:text-sm': isResponsive,
        })}
      >
        <p className={classNames('font-bold', textColor())}>{title}</p>
        {isResponsive && <p className={classNames('sm:hidden', textColor())}>{value}</p>}
      </div>
      <div className="flex items-center gap-x-3 text-xs md:text-sm">
        <p
          className={classNames(textColor(), {
            'hidden sm:block': isResponsive,
          })}
        >
          {value}
        </p>
        {showRemoveLineAction && onRemoveLine && (
          <Tooltip content={removeLineTooltipContent}>
            <Button
              data-testid={removeActionTestId}
              onClick={(e) => {
                e.stopPropagation()
                onRemoveLine()
              }}
              className="flex items-center"
            >
              {removeLineLoading ? (
                <Loading
                  className={classNames('h-5 w-5', {
                    'fill-primary text-primary/40': theme === Themes.Primary,
                    'fill-warning text-warning/40': theme === Themes.Warning,
                    'fill-error text-error/40': theme === Themes.Error,
                    'fill-success text-success/40': theme === Themes.Success,
                  })}
                />
              ) : (
                <CloseIcon className={classNames('h-5 w-5', textColor())} />
              )}
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  )
}
