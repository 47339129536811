import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AdvancedModal from './AdvancedModal'

import { ReceivingDocumentLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useUpdateReceivingDocumentLine } from '@/modules/receiving-notes/hooks'
import { ReceivingNoteState } from '@/modules/receiving-notes/types'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Button, Fallback, Stepper } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { ReceivingDocumentLineExtraLines } from '@/modules/shared/components/table/table-column-items'
import { useMoney } from '@/modules/shared/hooks'
import { SettingsIcon } from '@/modules/shared/icons'
import { formatNumber } from '@/modules/shared/utils'

interface TableViewProps {
  receivingNoteLineItems: ReceivingDocumentLineItem[]
  receivingDocState: string
}

export default function TableView({ receivingNoteLineItems, receivingDocState }: TableViewProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [showAdvancedModal, setShowAdvancedModal] = useState(false)
  const [activeLineId, setActiveLineId] = useState<number | null>(null)

  const [updateReceivingDocumentLine, { loading }] = useUpdateReceivingDocumentLine()

  const isReadOnly = receivingDocState === ReceivingNoteState.Received

  const columnsHidden = () => {
    if (receivingDocState === ReceivingNoteState.Received) return ['yetToReceive', 'receivingQty']
    return ['receivedQty']
  }

  const onUpdateReceivingDocumentLineQty = (input: {
    lineId: number
    productId: number
    receivingDocId: number
    qty: number
  }) => {
    setActiveLineId(input.lineId)
    updateReceivingDocumentLine({
      variables: {
        input: {
          id: input.lineId,
          productId: input.productId,
          quantity: input.qty,
          receivingDocumentId: input.receivingDocId,
        },
      },
      onCompleted() {
        setActiveLineId(null)
      },
    })
  }

  const onOpenAdvancedModal = (lineId: number) => {
    setActiveLineId(lineId)
    setShowAdvancedModal(true)
  }

  const renderItem = ({ product }: ReceivingDocumentLineItem) => {
    return (
      <>
        <p className="cursor-pointer break-words text-sm leading-5 text-primary">
          {product?.brand} {product?.itemDescription}
        </p>
        <span className="text-xs">
          {formatProductItem({
            itemSize: product?.itemSize,
            itemMeasure: product?.itemMeasure,
            itemPackName: product?.itemPackName,
            itemSellUnit: product?.concatenatedSellUnit,
          })}
        </span>
      </>
    )
  }
  const renderOrderedQty = ({ purchaseOrderLineItem }: ReceivingDocumentLineItem) => {
    return purchaseOrderLineItem?.quantity
  }
  const renderReceivedQty = ({ quantity }: ReceivingDocumentLineItem) => {
    return quantity
  }
  const renderYetToReceive = ({ purchaseOrderLineItem, receivedQuantity }: ReceivingDocumentLineItem) => {
    const yetToReceive = (purchaseOrderLineItem?.quantity || 0) - (receivedQuantity || 0)
    return (
      <Fallback condition={yetToReceive >= 0} fallbackValue="0">
        {formatNumber(yetToReceive, 3)}
      </Fallback>
    )
  }
  const renderActions = ({
    quantity,
    receivingDocument,
    id,
    product,
    receivedQuantity,
    purchaseOrderLineItem,
  }: ReceivingDocumentLineItem) => {
    // TODO: we're going to make shared components for columns in a separate ticket,
    // so this will be temporarily checked like this and will be removed anyway
    const actionLoading = loading && activeLineId === id

    const renderActions = () => {
      if ((receivedQuantity || 0) >= (purchaseOrderLineItem?.quantity || 0)) {
        return (
          <Button
            disabled={receivingDocument?.workflowState !== ReceivingNoteState.Open}
            data-testid="already-received-btn"
            className="w-[9.75rem] rounded-full bg-success py-2 text-sm text-white"
          >
            {t('general.alreadyReceived', 'Already Received')}
          </Button>
        )
      }

      if (quantity === 0) {
        return (
          <Button
            disabled={receivingDocument?.workflowState !== ReceivingNoteState.Open}
            data-testid="receive-items-btn"
            className="w-[9.75rem] rounded-full bg-gray-200 py-2 text-sm"
            loading={actionLoading}
            onClick={() =>
              onUpdateReceivingDocumentLineQty({
                qty: 1,
                lineId: id,
                productId: Number(product?.id),
                receivingDocId: Number(receivingDocument?.id),
              })
            }
          >
            {t('general.receiveItems', 'Receive Items')}
          </Button>
        )
      }

      if ((quantity || 0) > 0) {
        return (
          <Stepper
            loading={actionLoading}
            disabled={receivingDocument?.workflowState !== ReceivingNoteState.Open}
            value={quantity || 0}
            stepperAriaLabel={t('general.receiveItems', 'Receive Items')}
            onChange={(newQty) => {
              onUpdateReceivingDocumentLineQty({
                qty: newQty,
                lineId: id,
                productId: Number(product?.id),
                receivingDocId: Number(receivingDocument?.id),
              })
            }}
          />
        )
      }
    }
    return <div className="flex items-center justify-center">{renderActions()}</div>
  }
  const renderAdvanced = (record: ReceivingDocumentLineItem) => {
    return (
      <div className="flex items-center justify-center">
        <Button
          className={classNames('flex h-10 w-10 items-center justify-center rounded-full text-gray-600 ', {
            'bg-gray-200':
              !record.comment ||
              !record.overrideUnitValue ||
              !record.temperature ||
              !record.vehicleTemperature ||
              !record.expDate,
            'bg-primary/10 text-primary hover:bg-primary/20':
              record.comment ||
              record.overrideUnitValue ||
              record.temperature ||
              record.vehicleTemperature ||
              record.expDate,
          })}
          data-testid="show-advanced-modal-btn"
          onClick={() => onOpenAdvancedModal(record.id)}
        >
          <SettingsIcon className="h-6 w-6" />
        </Button>
        <AdvancedModal
          setShowModal={setShowAdvancedModal}
          showModal={showAdvancedModal && activeLineId === record.id}
          receivingDocumentLineItem={record}
        />
      </div>
    )
  }
  const renderUnitPrice = ({ unitPrice }: ReceivingDocumentLineItem) => {
    return (
      <div className="flex flex-col">
        <span className="break-words">{formatMoney(unitPrice)}</span>
        <span className="text-xs text-gray-500">{t('general.unitPrice', 'Unit Price')}</span>
      </div>
    )
  }
  const renderTotal = ({ totalValue }: ReceivingDocumentLineItem) => {
    return (
      <div className="flex flex-col">
        <strong className="break-words">{formatMoney(totalValue)}</strong>
        <span className="text-xs text-gray-500">{t('general.total', 'Total')}</span>
      </div>
    )
  }

  return (
    <Table
      dataTestId="receiving-note-line-items-table"
      dataSource={receivingNoteLineItems}
      columnsHidden={columnsHidden()}
      keyExtractor={(record) => String(record.id)}
      hasExtraLine={(record) =>
        !!record.comment ||
        !!record.overrideUnitValue ||
        !!record.vehicleTemperature ||
        !!record.temperature ||
        !!record.expDate
      }
      renderExtraLine={(record, index) => (
        <ReceivingDocumentLineExtraLines receivingDocumentLineItem={record} lineIndex={index} isReadOnly={isReadOnly} />
      )}
      columns={[
        {
          title: t('general.item', 'Item'),
          key: 'item',
          headerCellStyles: 'py-3 px-4',
          cellStyles: 'py-3 px-4',
          minWidth: 15.625,
          grow: true,
          render: renderItem,
        },
        {
          title: t('general.orderedQty', 'Ordered Qty'),
          key: 'orderedQty',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 7.5,
          maxWidth: 7.5,
          render: renderOrderedQty,
        },
        {
          title: t('general.receivedQty', 'Received Qty'),
          key: 'receivedQty',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 6.25,
          maxWidth: 6.25,
          render: renderReceivedQty,
        },
        {
          title: t('general.yetToReceive', 'Yet To Receive'),
          key: 'yetToReceive',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 6.25,
          maxWidth: 6.25,
          render: renderYetToReceive,
        },
        {
          title: t('general.receivingQuantity', 'Receiving Quantity'),
          key: 'receivingQty',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 10.625,
          maxWidth: 10.625,
          render: renderActions,
        },
        {
          title: t('general.advanced', 'Advanced'),
          key: 'advanced',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 5.625,
          maxWidth: 5.625,
          render: renderAdvanced,
        },
        {
          title: t('general.unitPrice', 'Unit Price'),
          key: 'unitPrice',
          headerCellStyles: 'py-3 pr-4 pl-1 text-right',
          cellStyles: 'py-3 pr-4 pl-1  text-right',
          minWidth: 5.625,
          maxWidth: 8.75,
          render: renderUnitPrice,
        },
        {
          title: t('general.total', 'Total'),
          key: 'total',
          headerCellStyles: 'py-3 pr-4 pl-1 text-right',
          cellStyles: 'py-3 pr-4 pl-1  text-right',
          minWidth: 5.625,
          maxWidth: 8.75,
          render: renderTotal,
        },
      ]}
    />
  )
}
