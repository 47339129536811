import { useMutation } from '@apollo/client'

import { DeleteCataloguedProductDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export const useDeleteCataloguedProduct = () => {
  const mutation = useMutation(DeleteCataloguedProductDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })
  return mutation
}
