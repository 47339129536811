import { useQuery } from '@apollo/client'

import {
  GetCurrentSupplierProductsDocument,
  PageInfo,
  Product,
  Supplier,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export default function useGetCurrentSupplierProducts() {
  const paginationResultsPerPage = 20
  const { data, error, networkStatus, refetch } = useQuery(GetCurrentSupplierProductsDocument, {
    variables: {
      first: paginationResultsPerPage,
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  const supplierEntity = data?.currentLegalEntity as Supplier
  const availableProducts = extractEdges<Product>(supplierEntity?.availableProducts)

  return {
    error,
    paginationResultsPerPage,
    pageInfo: supplierEntity?.availableProducts?.pageInfo || ({} as PageInfo),
    availableProducts,
    loadingStates: checkNetworkStatus(networkStatus),
    refetch,
  }
}
