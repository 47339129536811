import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ConfirmDialog } from '@/modules/shared/components'
import { ChevronDownIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

export default function InvoiceLineJournalOptions() {
  const { t } = useTranslation()
  const [showComingSoon, setShowComingSoon] = useState(false)

  return (
    <div className="flex gap-x-2">
      <div
        className="flex h-12 w-fit min-w-[7rem] cursor-pointer items-center justify-between rounded-md border border-gray-300 px-4"
        onClick={() => setShowComingSoon(true)}
        data-testid="type-selection"
      >
        {t('general.debit', 'Debit')}
        <ChevronDownIcon className="h-6 w-6" />
      </div>
      <div
        className="flex h-12 w-fit min-w-[15rem] cursor-pointer items-center justify-between rounded-md border border-gray-300 px-4"
        onClick={() => setShowComingSoon(true)}
        data-testid="department-selection"
      >
        {t('general.department', 'Department')}
        <ChevronDownIcon className="h-6 w-6" />
      </div>
      <div
        className="flex h-12 w-fit min-w-[15rem] cursor-pointer items-center justify-between rounded-md border border-gray-300 px-4"
        onClick={() => setShowComingSoon(true)}
        data-testid="account-selection"
      >
        {t('general.account', 'Account')}
        <ChevronDownIcon className="h-6 w-6" />
      </div>
      <ConfirmDialog
        title={t('general.comingSoon', 'Coming Soon!')}
        description={t(
          'invoices.invoice.editJournalComingSoonDescription',
          'Soon you will be able to use these options to modify this Invoice Line, as well as split Invoice Line expenses across multiple Departments and Account Codes. We are still working on this feature.'
        )}
        theme={Themes.Primary}
        isOpen={showComingSoon}
        setIsOpen={setShowComingSoon}
        primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
        onPrimaryBtnClick={() => setShowComingSoon(false)}
      />
    </div>
  )
}
