import { useQuery } from '@apollo/client'
import { Controller, useFormContext } from 'react-hook-form'

import {
  GetSupplierRelationshipsDocument,
  SupplierRelationship,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { ComboboxServer } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { extractEdges } from '@/modules/shared/utils'

interface SelectSupplierRelationshipProps {
  title: string
  description: string
  register: string
  placeholder: string
  required?: boolean
  requiredError?: string
}

export default function SelectSupplier({
  title,
  description,
  register,
  placeholder,
  required,
  requiredError,
}: SelectSupplierRelationshipProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const {
    data: suppliersData,
    networkStatus,
    refetch,
    fetchMore,
  } = useQuery(GetSupplierRelationshipsDocument, {
    variables: {
      first: 25,
      after: null,
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
  })

  const onSearchSupplierRelationship = (text: string) => {
    refetch({
      filter: { q: [{ property: 'supplierName_cont', value: text }] },
    })
  }

  return (
    <section className="mt-8">
      <h2 className="text-sm font-semibold">{title}</h2>
      <p className="text-sm text-gray-500">{description}</p>
      <div className="mt-2">
        <Controller
          control={control}
          name={register}
          rules={{ required }}
          render={({ field }) => (
            <ComboboxServer
              networkStatus={networkStatus}
              placeholder={placeholder}
              keyExtractor={(e) => String(e.id)}
              onDisplay={(e) => String(e.supplierName)}
              items={extractEdges<SupplierRelationship>(suppliersData?.currentPurchaser?.supplierRelationships)}
              onInputChange={onSearchSupplierRelationship}
              onSelected={(e) => field.onChange(e.supplier?.id)}
              hasError={Boolean(errors[register])}
              errorMessage={requiredError}
              onFetchMore={() =>
                fetchMore({
                  variables: {
                    after: suppliersData?.currentPurchaser?.supplierRelationships?.pageInfo.endCursor,
                  },
                })
              }
              hasMore={Boolean(suppliersData?.currentPurchaser?.supplierRelationships?.pageInfo.hasNextPage)}
            />
          )}
        />
      </div>
    </section>
  )
}
