import { useMutation } from '@apollo/client'

import { CancelRequisitionDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useCancelRequisition() {
  const mutation = useMutation(CancelRequisitionDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })
  return mutation
}
