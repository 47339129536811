import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ExtraLine from '../ExtraLine'

import { ReceivingDocumentLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'
import AdvancedModal from '@/modules/purchase-orders/pages/purchase-order/receiving-note/summary/table-view/AdvancedModal'
import { useUpdateReceivingDocumentLine } from '@/modules/receiving-notes/hooks'
import { useMoney } from '@/modules/shared/hooks'
import { Dates, Themes } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface ReceivingNoteLineExtraLinesProps {
  receivingDocumentLineItem: ReceivingDocumentLineItem
  lineIndex: number
  isReadOnly: boolean
}

export default function ReceivingDocumentLineExtraLines({
  receivingDocumentLineItem,
  lineIndex,
  isReadOnly,
}: ReceivingNoteLineExtraLinesProps) {
  const { comment, overrideUnitValue, temperature, vehicleTemperature, expDate, id } = receivingDocumentLineItem
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [updateReceivingDocumentLine, { loading }] = useUpdateReceivingDocumentLine()

  const [showAdvancedModal, setShowAdvancedModal] = useState(false)
  const [removeExtraLine, setRemoveExtraLine] = useState('')

  const onRemoveExtraLine = (lineId: number, extraLineType: string) => {
    setRemoveExtraLine(extraLineType)

    updateReceivingDocumentLine({
      variables: {
        input: {
          id: lineId,
          [extraLineType]: null,
        },
      },
      onCompleted() {
        setRemoveExtraLine('')
      },
    })
  }

  return (
    <>
      {comment && (
        <ExtraLine
          theme={Themes.Primary}
          dataTestId={`receiving-note-line-comment-${lineIndex}`}
          title={t('general.comment', 'Comment')}
          value={comment || ''}
          onLineClick={() => setShowAdvancedModal(true)}
          showRemoveLineAction={!isReadOnly}
          removeLineTooltipContent={t('general.removeComment', 'Remove Comment')}
          removeLineLoading={loading && removeExtraLine === 'comment'}
          onRemoveLine={() => onRemoveExtraLine(id, 'comment')}
        />
      )}
      {overrideUnitValue && (
        <ExtraLine
          theme={Themes.Primary}
          dataTestId={`receiving-note-line-overrideUnitPrice-${lineIndex}`}
          title={t('general.overrideUnitPrice', 'Override Unit Price')}
          value={formatMoney(overrideUnitValue || 0)}
          onLineClick={() => setShowAdvancedModal(true)}
          showRemoveLineAction={!isReadOnly}
          removeLineTooltipContent={t('general.removeOverrideUnitPrice', 'Remove Override Unit Price')}
          removeLineLoading={loading && removeExtraLine === 'overrideUnitValue'}
          onRemoveLine={() => onRemoveExtraLine(id, 'overrideUnitValue')}
        />
      )}
      {expDate && (
        <ExtraLine
          theme={Themes.Primary}
          dataTestId={`receiving-note-line-productExpiryDate-${lineIndex}`}
          title={t('general.productExpiryDate', 'Product Expiry Date')}
          value={formatDate(Dates.Short, expDate)}
          onLineClick={() => setShowAdvancedModal(true)}
          showRemoveLineAction={false}
          removeLineTooltipContent={t('general.removeProductExpiryDate', 'Remove Product Expiry Date')}
          removeLineLoading={loading && removeExtraLine === 'expDate'}
          onRemoveLine={() => onRemoveExtraLine(id, 'expDate')}
        />
      )}
      {vehicleTemperature && (
        <ExtraLine
          theme={Themes.Primary}
          dataTestId={`receiving-note-line-deliveryVehicleTemperature-${lineIndex}`}
          title={t('general.deliveryVehicleTemperature', 'Delivery Vehicle Temperature')}
          value={vehicleTemperature}
          onLineClick={() => setShowAdvancedModal(true)}
          showRemoveLineAction={!isReadOnly}
          removeLineTooltipContent={t(
            'general.removeDeliveryVehicleTemperature',
            'Remove Delivery Vehicle Temperature'
          )}
          removeLineLoading={loading && removeExtraLine === 'vehicleTemperature'}
          onRemoveLine={() => onRemoveExtraLine(id, 'vehicleTemperature')}
        />
      )}
      {temperature && (
        <ExtraLine
          theme={Themes.Primary}
          dataTestId={`receiving-note-line-productTemperature-${lineIndex}`}
          title={t('general.productTemperature', 'Product Temperature')}
          value={temperature}
          onLineClick={() => setShowAdvancedModal(true)}
          showRemoveLineAction={!isReadOnly}
          removeLineTooltipContent={t('general.removeProductTemperature', 'Remove Product Temperature')}
          removeLineLoading={loading && removeExtraLine === 'temperature'}
          onRemoveLine={() => onRemoveExtraLine(id, 'temperature')}
        />
      )}

      <AdvancedModal
        setShowModal={setShowAdvancedModal}
        showModal={showAdvancedModal}
        receivingDocumentLineItem={receivingDocumentLineItem}
      />
    </>
  )
}
