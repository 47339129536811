import { useMutation } from '@apollo/client'

import { ReopenInvoiceDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useReopenInvoice() {
  const mutation = useMutation(ReopenInvoiceDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  return mutation
}
