import { graphql } from 'msw'

import { GetPricedCatalogueProductQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetPricedCatalogueProductObject: GetPricedCatalogueProductQuery = {
  currentPurchaser: {
    id: 44926,
    pricedCatalogue: {
      id: 182639,
      title: 'Self Managed - sunny',
      pricedCataloguedProduct: {
        id: 22673422,
        product: {
          id: 4756296,
          category: {
            id: 109517,
            name: 'Chocolate and Confectionery',
            parent: {
              id: 109438,
              name: 'Prepared Food and Meals',
              __typename: 'Category',
            },
            __typename: 'Category',
          },
          brand: 'Akiko',
          itemDescription: 'Gummy : Sour Berry Cocktail',
          concatenatedDescription: 'Gummy : Sour Berry Cocktail 18 g pack',
          concatenatedSellUnit: 'each of 1',
          itemPackName: 'pack',
          itemMeasure: 'g',
          itemSellPackName: 'each',
          itemSize: 18,
          itemSellQuantity: 1,
          __typename: 'Product',
        },
        image: null,
        sellUnitPrice: 10,
        sellUnitTaxPercentage: 10,
        longDescription: '',
        __typename: 'PricedCataloguedProduct',
      },
      __typename: 'PricedCatalogue',
    },
    __typename: 'Purchaser',
  },
}

export const GetPricedCatalogueProduct = graphql.query<GetPricedCatalogueProductQuery>(
  'GetPricedCatalogueProduct',
  (_, res, ctx) => {
    return res(ctx.data(baseGetPricedCatalogueProductObject))
  }
)

export default baseGetPricedCatalogueProductObject
