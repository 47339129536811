import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useDeleteRequisitionAttachment } from '@/modules/requisitions/hooks'
import { Button, MenuPopover } from '@/modules/shared/components'
import { DownloadIcon, OptionIcon, TrashIcon } from '@/modules/shared/icons'

interface MoreOptionMenuProps {
  attachmentId: number
  downloadAttachmentURL: string
}

function AttachmentMoreOptionMenu(props: MoreOptionMenuProps) {
  const { downloadAttachmentURL, attachmentId } = props
  const { t } = useTranslation()
  const { requisitionId } = useParams<{ requisitionId: string }>()

  const [deleteAttachment, { loading }] = useDeleteRequisitionAttachment(attachmentId, Number(requisitionId))

  const onDeleteAttachment = () => {
    deleteAttachment({
      variables: {
        input: {
          id: attachmentId,
        },
      },
    })
  }

  const MoreOptionContent = () => (
    <div className="py-1">
      <a
        className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 transition hover:bg-gray-200 hover:text-gray-900"
        href={downloadAttachmentURL}
        target="_blank"
        rel="noreferrer"
      >
        <DownloadIcon className="mr-2 h-5 w-5" />
        {t('general.downloadAttachment', 'Download Attachment')}
      </a>
      <Button
        loading={loading}
        className="flex w-full items-center px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
        onClick={() => onDeleteAttachment()}
      >
        <TrashIcon className="mr-2 h-5 w-5" />
        {t('general.deleteAttachment', 'Delete Attachment')}
      </Button>
    </div>
  )

  return (
    <MenuPopover content={<MoreOptionContent />}>
      <OptionIcon className="h-7 w-7" data-testid="attachment-opiton-button" />
    </MenuPopover>
  )
}

export default AttachmentMoreOptionMenu
