import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { Delivery, GetPoSendEventsDocument, RansackDirection } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

interface UseGetPOSendEventsProps {
  POId?: number
}

export const useGetPOSendEvents = ({ POId }: UseGetPOSendEventsProps) => {
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>()
  const { data, loading, error, startPolling, stopPolling } = useQuery(GetPoSendEventsDocument, {
    variables: {
      purchaseOrderId: POId || Number(purchaseOrderId),
      sort: [
        {
          direction: RansackDirection.Desc,
          property: 'createdAt',
        },
      ],
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const sendEvents = (data?.currentPurchaser?.purchaseOrder?.deliveries?.nodes as Delivery[]) || []

  return { data, loading, error, sendEvents, startPolling, stopPolling }
}
