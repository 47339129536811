import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import NoImage from '@/assets/images/no-image.svg'
import { CataloguedProduct, PricedCataloguedProduct } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Button, Fallback, Modal } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { Dates } from '@/modules/shared/types'
import { extractEdges, formatDate } from '@/modules/shared/utils'

interface PriceHistoryModalProps {
  showModal: boolean
  setModalProductId: Dispatch<SetStateAction<number | null>>
  cataloguedProduct: PricedCataloguedProduct
}

export default function PriceHistoryModal(props: PriceHistoryModalProps) {
  const { showModal, setModalProductId, cataloguedProduct } = props
  const { product, sellUnitPrice, sellUnitTaxPercentage, image } = cataloguedProduct
  const { brand, itemDescription, itemMeasure, itemPackName, itemSize, concatenatedSellUnit } = product || {}
  const pricings = extractEdges<CataloguedProduct>(product?.pricings)

  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  const onCloseModal = () => setModalProductId(null)

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        data-testid="price-history-modal"
        className="flex h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[56.25rem]"
      >
        <Modal.Title title={t('general.priceHistory', 'Price History')} onCloseModal={onCloseModal} />

        <Modal.Body>
          <div className="mb-5 flex gap-x-3 rounded-md border p-4">
            <img className="h-24 w-24 flex-none border" src={image || NoImage} alt="product" />
            <div className="flex flex-col justify-center text-sm">
              <span>
                {brand} {itemDescription}
              </span>
              <span className="text-xxs text-gray-500 sm:text-xs">
                <p>
                  {formatProductItem({
                    itemMeasure: itemMeasure,
                    itemPackName: itemPackName,
                    itemSize: itemSize,
                    itemSellUnit: concatenatedSellUnit,
                  })}
                </p>
              </span>
              <span className="font-bold">{formatMoney(sellUnitPrice)}</span>
              <Fallback as="span" className="text-xs text-gray-500" condition={sellUnitTaxPercentage !== null}>
                {t('catalogs.selfManagedCatalog.priceHistoryModal.plusTax', 'Plus {{ taxPercentage }}% Tax', {
                  taxPercentage: sellUnitTaxPercentage,
                })}
              </Fallback>
            </div>
          </div>
          <p className="text-sm font-bold">{t('general.priceHistory', 'Price History')}</p>
          <p className="mb-2 text-sm text-gray-500">
            {t(
              'catalogs.selfManagedCatalog.priceHistoryModal.explain',
              'Historical changes to the Unit Price and Tax Percentage of this product in this catalog are shown below.'
            )}
          </p>
          <div className="flex flex-col divide-y rounded-md border">
            {pricings &&
              pricings.map((pricing) => (
                <div className="flex items-center justify-between gap-x-2 bg-gray-100 p-3 text-sm" key={pricing.id}>
                  <p>{formatDate(Dates.Long, pricing.updatedAt)}</p>
                  <div className="flex flex-col text-right">
                    <Fallback as="span" className="font-bold" condition={!!pricing.sellUnitPrice}>
                      {formatMoney(pricing.sellUnitPrice)}
                    </Fallback>
                    <span className="text-xs text-gray-500">
                      <Fallback
                        condition={pricing.sellUnitTaxPercentage !== null}
                      >{`Plus ${pricing.sellUnitTaxPercentage}% Tax`}</Fallback>
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-end">
          <Button className="h-11 w-full rounded-md bg-gray-200 px-8 text-sm md:w-fit" onClick={onCloseModal}>
            {t('general.close', 'Close')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
