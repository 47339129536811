import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import {
  GetAvailableProductsDocument,
  Product,
  RansackDirection,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export const useGetAvailableProducts = () => {
  const { pricedCatalogueId, invoiceId } = useParams<{ pricedCatalogueId: string; invoiceId: string }>()

  const { data, error, networkStatus, fetchMore, refetch } = useQuery(GetAvailableProductsDocument, {
    variables: {
      sortPrices: { property: 'unitPrice', direction: RansackDirection.Asc },
      invoiceId: invoiceId ? Number(invoiceId) : 0,
      catalogueId: pricedCatalogueId ? Number(pricedCatalogueId) : 0,
    },
    notifyOnNetworkStatusChange: true,
    context: { uri: PURCHASING_GRAPHQL_API },
    fetchPolicy: 'cache-and-network',
  })

  const onFetchMoreProducts = () => {
    fetchMore({
      variables: {
        after: data?.currentPurchaser?.availableProducts?.pageInfo.endCursor,
      },
    })
  }

  const onFilter = ({
    searchValue,
    categoryIds,
    supplierIds,
    supplierProductCode,
    purchaserProductCode,
    pPlusProductCode,
    hasQuotes,
    master,
  }: OnFilter) => {
    refetch({
      searchText: searchValue,
      categoryIds: categoryIds as number[],
      supplierIds: supplierIds as number[],
      supplierProductCode: supplierProductCode,
      purchaserProductCode: purchaserProductCode,
      productId: Number(pPlusProductCode),
      hasQuotes,
      master: master ? master : null,
    })
  }

  const availableProducts = extractEdges<Product>(data?.currentPurchaser?.availableProducts)

  return {
    availableProducts,
    error,
    onFetchMoreProducts,
    onFilter,
    loadingStates: checkNetworkStatus(networkStatus),
    pageInfo: data?.currentPurchaser?.availableProducts?.pageInfo,
    refetch,
  }
}
