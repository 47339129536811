import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../button'
import ConfirmDialog from '../../confirm-dialog'
import PPNewDetailLink from '../../ppnew-detail-link'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import MatchPOToInvoiceModal from '@/modules/invoices/pages/invoice/page-header/MatchPOToInvoiceModal'
import { InvoiceStatuses } from '@/modules/invoices/types'
import { MatchIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface PurchaseOrderLinkOrMatchButtonProps {
  invoice: Invoice
}

export const PurchaseOrderLinkOrMatchButton = ({ invoice }: PurchaseOrderLinkOrMatchButtonProps) => {
  const { purchaseOrder, id, invoiceNumber, supplier, state } = invoice
  const { t } = useTranslation()
  const [showMatchPOModal, setShowMatchPOModal] = useState(false)
  const [showCannotMatchPOModal, setShowCannotMatchPOModal] = useState(false)
  const [activeInvoiceId, setActiveInvoiceId] = useState<number | null>(null)
  const isMatchable = state === InvoiceStatuses.Flagged || state === InvoiceStatuses.Open
  return (
    <>
      {!!purchaseOrder ? (
        <PPNewDetailLink type="purchase-order" id={String(purchaseOrder?.id)}>
          {purchaseOrder?.purchaseOrderNumber}
        </PPNewDetailLink>
      ) : (
        <Button
          data-testid="match-po"
          className={classNames('h-9 w-9 cursor-pointer rounded-full bg-gray-200 p-1.5 text-gray-500', {
            'opacity-50': !isMatchable,
          })}
          onClick={() => {
            setActiveInvoiceId(id)
            if (isMatchable) {
              setShowMatchPOModal(true)
            } else {
              setShowCannotMatchPOModal(true)
            }
          }}
        >
          <MatchIcon className="h-6 w-6" />
        </Button>
      )}

      <MatchPOToInvoiceModal
        invoiceId={String(id)}
        invoiceNumber={invoiceNumber}
        supplierId={supplier?.id}
        supplierName={supplier?.name}
        showModal={showMatchPOModal && activeInvoiceId === id}
        setShowModal={setShowMatchPOModal}
      />
      <ConfirmDialog
        theme={Themes.Primary}
        isOpen={showCannotMatchPOModal && activeInvoiceId === id}
        setIsOpen={setShowCannotMatchPOModal}
        title={t('invoices.details.cannotMatchPO', 'Cannot Match Purchase Order')}
        description={t(
          'invoices.details.cannotMatchPODesc',
          'A Purchase Order can only be matched to an Invoice when the Invoice is in an Open or Flagged state.'
        )}
        primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
        onPrimaryBtnClick={() => setShowCannotMatchPOModal(false)}
      />
    </>
  )
}
