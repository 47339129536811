import NumberInput from '../../number-input'

export interface JournalUnitPriceProps {
  unitPrice: number | string | null | undefined
  onChange: (unitPrice: number) => void
  isDisabled?: boolean
}

export default function JournalUnitPrice({ unitPrice, onChange, isDisabled }: JournalUnitPriceProps) {
  return (
    <NumberInput
      defaultValue={Number(unitPrice)}
      className="m-auto w-[6rem] rounded-md border border-gray-300 px-4 py-3 text-right text-sm"
      onChange={onChange}
      aria-label="unit-price-input"
      isDisabled={isDisabled}
      data-testid="unit-price-input"
    />
  )
}
