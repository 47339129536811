import { graphql } from 'msw'

import { GetPoInvoicesQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetPOInvoices: GetPoInvoicesQuery = {
  currentPurchaser: {
    id: 44926,
    purchaseOrder: {
      id: 16566710,
      purchaseOrderNumber: 'PO02887',
      invoices: {
        edges: [
          {
            node: {
              id: 6289039,
              invoiceNumber: '212121',
              invoiceDate: '2020-12-18',
              state: 'reconciled',
              totalTaxValue: 0,
              totalValue: 150,
              reference: '',
              department: {
                id: 100617,
                name: 'Department 1',
                __typename: 'Purchaser',
              },
              account: {
                id: 96387,
                accountName: '0060003 - Operating Account - 3',
                code: '0060003',
                __typename: 'Account',
              },
              purchaseOrder: {
                id: 16566710,
                purchaseOrderNumber: 'PO02887',
                requisition: {
                  id: 3908577,
                  reference: '',
                  __typename: 'Requisition',
                },
                __typename: 'PurchaseOrder',
              },
              __typename: 'Invoice',
            },
            __typename: 'InvoiceEdge',
          },
        ],
        __typename: 'InvoiceConnection',
      },
      __typename: 'PurchaseOrder',
    },
    __typename: 'Purchaser',
  },
}

export const GetPOInvoices = graphql.query<GetPoInvoicesQuery>('GetPOInvoices', (_, res, ctx) => {
  return res(ctx.data(baseGetPOInvoices))
})

export default baseGetPOInvoices
