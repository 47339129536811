import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import {
  GetPricedCatalogueDocument,
  PricedCataloguedProduct,
  RansackDirection,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { QueryParameter } from '@/modules/shared/types'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export const useGetPricedCatalog = () => {
  const { pricedCatalogueId } = useParams<{ pricedCatalogueId: string }>()

  const [searchTermURLParam] = useQueryParam(QueryParameter.Search, StringParam)

  const { data, error, networkStatus, fetchMore } = useQuery(GetPricedCatalogueDocument, {
    variables: {
      pricedCatalogueId: Number(pricedCatalogueId),
      after: null,
      filter: {
        q: [
          {
            property: 'product_itemDescription_cont',
            value: searchTermURLParam || '',
          },
        ],
      },
      sort: [
        {
          property: 'product_itemDescription',
          direction: RansackDirection.Asc,
        },
      ],
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  const onFetchMorePricedCatalogs = () => {
    fetchMore({
      variables: {
        after: data?.currentPurchaser?.pricedCatalogue?.pricedCataloguedProducts?.pageInfo.endCursor,
      },
    })
  }

  const pricedCatalog = data?.currentPurchaser?.pricedCatalogue

  const products = extractEdges<PricedCataloguedProduct>(pricedCatalog?.pricedCataloguedProducts)

  const loadingState = checkNetworkStatus(networkStatus)

  return {
    pricedCatalog,
    products,
    loadingState,
    error,
    onFetchMorePricedCatalogs,
    pageInfo: data?.currentPurchaser?.pricedCatalogue?.pricedCataloguedProducts?.pageInfo,
  }
}
