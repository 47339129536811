import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PurchaseOrderModal from '../../../confirmation/requisition-purchase-orders/PurchaseOrderModal'

import { PurchaseOrder } from '@/graphql/purchasing/generated/purchasing_graphql'
import PurchaseOrderInvoicedStatus from '@/modules/purchase-orders/pages/all-purchase-order/PurchaseOrderInvoicedStatus'
import PurchaseOrderReceivedStatus from '@/modules/purchase-orders/pages/all-purchase-order/PurchaseOrderReceivedStatus'
import PurchaseOrderStatus from '@/modules/purchase-orders/pages/all-purchase-order/PurchaseOrderStatus'
import { statusText } from '@/modules/purchase-orders/utils/statusText'
import { MoreOptionsMenu, PPNewDetailLink } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { ShortDateItem, TotalTaxInclusive } from '@/modules/shared/components/table/table-column-items'
import { OptionIcon } from '@/modules/shared/icons'

interface TableViewProps {
  purchaseOrders: PurchaseOrder[]
}

function TableView(props: TableViewProps) {
  const { purchaseOrders } = props
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<number | null>(null)

  return (
    <Table
      dataSource={purchaseOrders}
      keyExtractor={(record) => String(record.id)}
      dataTestId="purchase-orders-table-view"
      columns={[
        {
          title: t('general.purchaseOrder', 'Purchase Order'),
          key: 'purchaseOrder',
          headerCellStyles: 'px-4 py-3 ',
          cellStyles: 'px-4 py-3',
          minWidth: 8.125,
          maxWidth: 8.125,
          render: (record) => (
            <PPNewDetailLink type="purchase-order" id={String(record.id)}>
              {record.purchaseOrderNumber}
            </PPNewDetailLink>
          ),
        },
        {
          title: t('general.supplier', 'Supplier'),
          key: 'supplier',
          headerCellStyles: 'px-2 py-3 ',
          cellStyles: 'px-2 py-3 ',
          minWidth: 12.5,
          grow: true,
          render: (record) => <p className="break-words">{record.supplier?.name}</p>,
        },
        {
          title: t('general.status', 'Status'),
          key: 'status',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 6.875,
          maxWidth: 6.875,
          render: (record) => (
            <>
              <span className="cursor-pointer" onClick={() => setIsOpen(record.id)} data-testid="purchase-order-send">
                <PurchaseOrderStatus status={statusText(record.status, record.sentDate)} />
              </span>
              <PurchaseOrderModal showModal={isOpen === record.id} setShowModal={setIsOpen} purchaseOrder={record} />
            </>
          ),
        },
        {
          title: t('general.received', 'Received'),
          key: 'received',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 8.75,
          maxWidth: 8.75,
          render: (record) => (
            <span>
              {record.receivedStatus && <PurchaseOrderReceivedStatus receivedStatus={record.receivedStatus} />}
            </span>
          ),
        },
        {
          title: t('general.invoiced', 'Invoiced'),
          key: 'invoiced',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 8.75,
          maxWidth: 8.75,
          render: (record) => (
            <span>{record.invoicedStatus && <PurchaseOrderInvoicedStatus purchaseOrder={record} />}</span>
          ),
        },
        {
          title: t('general.deliveryDate', 'Delivery Date'),
          key: 'deliveryDate',
          headerCellStyles: 'px-1 py-3 text-center',
          cellStyles: 'px-1 py-3 text-center',
          minWidth: 7.5,
          maxWidth: 7.5,
          render: ({ expectedDeliveryDate }) => <ShortDateItem date={expectedDeliveryDate} />,
        },
        {
          title: t('general.total', 'Total'),
          key: 'total',
          headerCellStyles: 'px-4 py-3 text-right',
          cellStyles: 'px-4 py-3 text-right',
          minWidth: 8,
          maxWidth: 9.375,
          render: ({ totalValue }) => <TotalTaxInclusive totalValue={totalValue} />,
        },
        {
          title: t('general.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: (record) => (
            <MoreOptionsMenu showPrint showOpenInPPlus showHelp showPurchaseOrderLink purchaseOrderId={record.id}>
              <OptionIcon className="h-10 w-10 text-gray-500" />
            </MoreOptionsMenu>
          ),
        },
      ]}
    />
  )
}

export default memo(TableView)
