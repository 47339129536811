import { SetStateAction } from 'jotai'
import { Dispatch, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParam } from 'use-query-params'

import Button from '../../button'
import DatePicker from '../../date-picker'
import { Modal } from '../../modal'

import { QueryParameter } from '@/modules/shared/types'

interface FilterByDateRangeModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  title: string
}

export default function FilterByDateRangeModal({ showModal, setShowModal, title }: FilterByDateRangeModalProps) {
  const { t } = useTranslation()
  const [fromDateURLParam, setFromDateURLParam] = useQueryParam(QueryParameter.From, StringParam)
  const [toDateURLParam, setToDateURLParam] = useQueryParam(QueryParameter.To, StringParam)
  const [fromDateFilter, setFromDateFilter] = useState<string>(fromDateURLParam || '')
  const [toDateFilter, setToDateFilter] = useState<string>(toDateURLParam || '')
  const onCloseModal = () => {
    setShowModal(false)
    setFromDateFilter('')
    setToDateFilter('')
  }

  const onApplyFilter = () => {
    if (!fromDateFilter && !toDateFilter) {
      return onCloseModal()
    }
    setFromDateURLParam(fromDateFilter || undefined)
    setToDateURLParam(toDateFilter || undefined)
    setShowModal(false)
  }

  useEffect(() => {
    if (showModal) {
      setFromDateFilter(fromDateURLParam || '')
      setToDateFilter(toDateURLParam || '')
    }
  }, [showModal])

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        data-tesid="filter-by-date-range"
        className="flex h-[50vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]"
      >
        <Modal.Title title={title} onCloseModal={onCloseModal} />
        <Modal.Body>
          <div className="flex flex-col gap-2">
            <DatePicker
              label={t('general.selectFromDate', 'Select From Date')}
              defaultDate={fromDateFilter}
              onSelected={setFromDateFilter}
              placeholder={t('general.fromDate', 'From Date')}
              isSelected={!!fromDateURLParam || !!fromDateFilter}
              canBeEmpty
              showRight
              classNames="w-full"
              testId="from-date-picker"
            />
            <DatePicker
              label={t('general.selectToDate', 'Select To Date')}
              defaultDate={toDateFilter}
              onSelected={setToDateFilter}
              placeholder={t('general.toDate', 'To Date')}
              isSelected={!!toDateURLParam || !!toDateFilter}
              classNames="w-full"
              canBeEmpty
              showRight
              testId="to-date-picker"
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex flex-col items-center justify-between gap-y-2 md:flex-row">
          <Button
            data-testid="supplier-filter-clear"
            className="h-11 w-full rounded-md bg-gray-200 px-4 text-sm md:w-fit"
            // disabled={!fromDateURLParam || !toDateURLParam}
            onClick={() => {
              onCloseModal()
              setFromDateURLParam(undefined)
              setToDateURLParam(undefined)
            }}
          >
            {t('general.clearFilter', 'Clear Filter')}
          </Button>
          <div className="flex w-full flex-col-reverse gap-2 md:w-fit md:flex-row">
            <Button className="h-11 w-full rounded-md bg-gray-200 px-4 text-sm md:w-fit" onClick={onCloseModal}>
              {t('general.cancel', 'Cancel')}
            </Button>
            <Button
              className="h-11 w-full rounded-md bg-primary px-4 text-sm text-white md:w-fit"
              onClick={onApplyFilter}
              data-testid="date-range-filter-confirm"
            >
              {t('general.applyFilter', 'Apply Filter')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
