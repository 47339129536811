import classNames from 'classnames'

import { useMoney } from '@/modules/shared/hooks'

interface BalanceContainerRowProps {
  title: string
  value: string | number | null | undefined
  canHover?: boolean
}

export default function BalanceContainerRow({ title, value, canHover }: BalanceContainerRowProps) {
  const { formatMoney } = useMoney()

  return (
    <div className={classNames('flex justify-between rounded-md px-2 py-1', { 'hover:bg-gray-100': canHover })}>
      <p className="text-gray-500">{title}</p>
      <p>{formatMoney(value)}</p>
    </div>
  )
}
