import { NetworkStatus, useQuery } from '@apollo/client'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Account, GetAccountsDocument, Purchaser } from '@/graphql/purchasing/generated/purchasing_graphql'
import { RequisitionFormInputs } from '@/modules/requisitions/types'
import { ComboboxClient } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useGetDepartments } from '@/modules/shared/hooks'

interface DeptAndAccountProps {
  defaultDepartmentValue?: Purchaser
  defaultAccountValue?: Account
  showExplanation?: boolean
  disabled?: boolean
  title: string
  description: string
  required?: boolean
}

export default function DeptAndAccount(props: DeptAndAccountProps) {
  const {
    defaultAccountValue,
    defaultDepartmentValue,
    showExplanation = true,
    disabled,
    title,
    description,
    required = true,
  } = props
  const { t } = useTranslation()
  const {
    resetField,
    getValues,
    control,
    formState: { errors },
  } = useFormContext<RequisitionFormInputs>()
  const [hasDefault, setHasDefault] = useState(false)
  const [resetAccount, setResetAccount] = useState(0)

  const { data: departmentsData, loading: fetchingDepartments } = useGetDepartments()
  const {
    data: accountsData,
    networkStatus,
    refetch: refetchingAccounts,
  } = useQuery(GetAccountsDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
    variables: { deparmentId: defaultDepartmentValue?.id },
  })

  const onSelectedDepartment = (e: Purchaser, field: ControllerRenderProps<RequisitionFormInputs, 'departmentId'>) => {
    field.onChange(e.id)
    if (!hasDefault) {
      resetField('accountId', { defaultValue: null })
      refetchingAccounts({ deparmentId: getValues('departmentId') })
      setResetAccount(e.id)
    } else {
      setHasDefault(false)
    }
  }

  useEffect(() => {
    if (defaultDepartmentValue) {
      refetchingAccounts({ deparmentId: defaultDepartmentValue?.id })
      setHasDefault(true)
    }
  }, [defaultDepartmentValue])

  return (
    <section className={classNames({ 'mt-8': showExplanation })}>
      {showExplanation && (
        <>
          <h2 className="text-sm font-semibold">{title}</h2>
          <p className="text-sm text-gray-500">{description}</p>
        </>
      )}
      <div className={classNames('space-y-3', { 'mt-4': showExplanation })}>
        <Controller
          control={control}
          name="departmentId"
          rules={{ required }}
          render={({ field }) => (
            <ComboboxClient
              testId="department"
              defaultValue={defaultDepartmentValue}
              loading={fetchingDepartments}
              placeholder={t('general.selectADepartmentDots', 'Select a Department...')}
              keyFilter="name"
              keyExtractor={(e) => String(e.id)}
              items={departmentsData?.currentPurchaser?.departments as Purchaser[]}
              onSelected={(e) => onSelectedDepartment(e, field)}
              hasError={Boolean(errors.departmentId)}
              disabled={disabled}
            />
          )}
        />
        <Controller
          control={control}
          name="accountId"
          rules={{ required: required || (required && !!getValues('departmentId')) }}
          render={({ field }) => (
            <ComboboxClient
              testId="account"
              defaultValue={defaultAccountValue}
              loading={networkStatus === NetworkStatus.setVariables}
              disabled={disabled || !getValues('departmentId')}
              placeholder={t('createRequisition.deptAndAccount.placeholder.account', 'Select an Account Code...')}
              keyFilter="accountName"
              keyExtractor={(e) => String(e.id)}
              items={accountsData?.purchaser?.accountsInTree as Account[]}
              onSelected={(e) => field.onChange(e.id)}
              hasError={Boolean(errors.accountId)}
              errorMessage={t(
                'createRequisition.deptAndAccount.required.account',
                'You must select a Department and an Account Code.'
              )}
              resetSelectItem={resetAccount}
            />
          )}
        />
      </div>
    </section>
  )
}
