import { Dispatch, SetStateAction } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Account, DeliveryAddress, PurchaseOrder, Purchaser } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useUpdatePurchaseOrder } from '@/modules/purchase-orders/hooks'
import { DeptAndAccount, SelectAddress, SendPurchaseOrder } from '@/modules/requisitions/pages/create/form-elements'
import { EditRequisitionModalEnum, RequisitionFormInputs } from '@/modules/requisitions/types'
import { Button, Modal } from '@/modules/shared/components'
import FormDate from '@/modules/shared/components/create-form/FormDate'

interface UpdateModalProps {
  showModal: string | null
  setShowModal: Dispatch<SetStateAction<string | null>>
  purchaseOrder: PurchaseOrder
}

export default function UpdateModal(props: UpdateModalProps) {
  const { showModal, setShowModal, purchaseOrder } = props
  const { id, expectedDeliveryDate, department, account, sendToSupplier, deliveryAddress } = purchaseOrder
  const { t } = useTranslation()
  const formMethods = useForm<RequisitionFormInputs>({
    defaultValues: {
      expectedDeliveryDate: expectedDeliveryDate,
      deliveryAddressId: deliveryAddress?.id,
      departmentId: department?.id,
      accountId: account?.id,
      sendToSupplier: Boolean(sendToSupplier),
    },
  })

  const [updatePurchaseOrder, { loading }] = useUpdatePurchaseOrder()

  const onSubmit: SubmitHandler<RequisitionFormInputs> = (data) => {
    updatePurchaseOrder({
      variables: {
        input: {
          id,
          ...data,
        },
      },
      onCompleted(data) {
        const purchaseOrder = data.updatePurchaseOrder.purchaseOrder
        formMethods.reset({
          sendToSupplier: Boolean(purchaseOrder?.sendToSupplier),
          deliveryAddressId: purchaseOrder?.deliveryAddress?.id,
          expectedDeliveryDate: String(purchaseOrder?.expectedDeliveryDate),
          departmentId: purchaseOrder?.department?.id,
          accountId: purchaseOrder?.account?.id,
        })
        setShowModal(null)
      },
    })
  }

  /**
   * Returns purchase order update modal text
   *
   * @param {string | null} showModal
   * @returns {{header: string, title: string, body: string}}
   */
  const handleUpdateModalText = (showModal: string | null) => {
    let header, title, body
    switch (showModal) {
      case 'DeptAndAcc':
        header = t(
          'purchaseOrders.purchaseOrder.details.updateModal.DeptAndAccHeader',
          'Edit Department and Account Code'
        )
        title = t('purchaseOrders.purchaseOrder.details.updateModal.DeptAndAccTitle', 'Department and Account Code')
        body = t(
          'purchaseOrders.purchaseOrder.details.updateModal.DeptAndAccBody',
          'If required, you can add or update the Department and Account Code that this Purchase Order will be recorded against. Your organisation may use Department and Account Codes for recording expenses. The Department and Account Code will be preset according to the Purchase Requisition from which the Purchase Order was created, but you can change it here if you need to, prior to the Purchase Order being marked as Sent.'
        )
        break
      case 'ExpectedDeliveryDate':
        header = t('general.editDeliveryDate', 'Edit Delivery Date')
        title = t('general.deliveryDate', 'Delivery Date')
        body = t(
          'purchaseOrders.purchaseOrder.details.updateModal.ExpectedDeliveryDateBody',
          'If required, you can update the Delivery Date here prior to the Purchase Order being marked as Sent. The Delivery Date will be preset according to the Purchase Requisition from which the Purchase Order was created, but you can change it here if you need to. The Delivery Date is the date that you expect the supplier to deliver these items and it will be visible to the Supplier and anyone at your organisation.'
        )
        break
      case 'SendToSupplier':
        header = t('purchaseOrders.purchaseOrder.details.updateModal.SendToSupplierHeader', 'Edit Send PO To Suppliers')
        title = t(
          'purchaseOrders.purchaseOrder.details.updateModal.SendToSupplierTitle',
          'Send Purchase Order to Supplier?'
        )
        body = t(
          'purchaseOrders.purchaseOrder.details.updateModal.SendToSupplierBody',
          "If required, you can update the Send PO to Supplier option here prior to the Purchase Order being marked as Sent. If you leave this option un-checked, when the Purchase Order is marked as Sent, the Purchase Order will not be electronically sent to the Supplier. There may be reasons why you don't need the Supplier to receive it, for example you may have phoned ahead and placed your order and you don't wish to send the Supplier a duplicate order."
        )
        break
      case 'DeliveryAddress':
        header = t('general.editDeliveryAddress', 'Edit Delivery Address')
        title = t('general.deliveryAddress', 'Delivery Address')
        body = t(
          'purchaseOrders.purchaseOrder.details.updateModal.DeliveryAddressBody',
          'If required, you can update the Delivery Address here prior to the Purchase Order being marked as Sent. The Delivery Address will be preset according to the Purchase Requisition from which the Purchase Order was created, but you can change it here if you need to. Select a delivery address from the list below.'
        )
        break
      default:
      // do nothing
    }
    return { header, title, body }
  }

  const { header, title, body } = handleUpdateModalText(showModal)

  return (
    <Modal showModal={Boolean(showModal)} onCloseModal={() => setShowModal(null)}>
      <Modal.Panel className="flex w-full flex-col overflow-hidden rounded-md bg-white text-sm shadow-xl transition-all md:max-w-[37.5rem]">
        <Modal.Title
          title={showModal ? header || '' : ''}
          onCloseModal={() => setShowModal(null)}
          testId="update-modal-title"
        />

        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Modal.Body className="pb-8">
              {showModal && (
                <>
                  <h2 className="mt-5 text-sm font-semibold">{title}</h2>
                  <p className="text-sm text-gray-500">{body}</p>
                </>
              )}
              {showModal === EditRequisitionModalEnum.ExpectedDeliveryDate && (
                <FormDate
                  defaultDate={expectedDeliveryDate}
                  showExplanation={false}
                  title={t('general.deliveryDate', 'Delivery Date')}
                  description={t(
                    'createRequisition.deliveryDate.explain',
                    "Please select the date you'd like to take delivery of this order. This date will be included on all Purchase Orders that are created when this Requisition is submitted and fully approved. The date will be visible to Suppliers and anyone at your organisation."
                  )}
                  register="expectedDeliveryDate"
                />
              )}
              {showModal === EditRequisitionModalEnum.DeliveryAddress && (
                <SelectAddress
                  defaultValue={deliveryAddress as DeliveryAddress}
                  showExplanation={false}
                  title={t('general.deliveryAddress', 'Delivery Address')}
                  description={t(
                    'createRequisition.address.explain',
                    'Select a delivery address from the list below. Additional delivery addresses can be added by an administrator at your organisation. Please speak with your administrator to request additional delivery addresses.'
                  )}
                />
              )}
              {showModal === EditRequisitionModalEnum.DeptAndAcc && (
                <DeptAndAccount
                  defaultAccountValue={purchaseOrder?.account as Account}
                  defaultDepartmentValue={purchaseOrder?.department as Purchaser}
                  showExplanation={false}
                  title={t('createRequisition.deptAndAccount.title', 'Department and Account Code')}
                  description={t(
                    'createRequisition.deptAndAccount.explain',
                    'If applicable, select a Department then an Account code to record this Requisition against. Your organisation may use Department and Account Codes for recording Requisition expenses.'
                  )}
                />
              )}
              {showModal === EditRequisitionModalEnum.SendToSupplier && (
                <SendPurchaseOrder
                  defaultValue={Boolean(sendToSupplier)}
                  showExplanation={false}
                  label={t('createRequisition.sendPO.label', 'Send Purchase Orders to Suppliers')}
                  title={t('createRequisition.sendPO.title', 'Send Purchase Orders to Suppliers?')}
                  description={t(
                    'createRequisition.sendPO.explain',
                    "Decide if you'd like a Purchase Order to be sent to each Supplier once the Requisition has been fully approved, and the Purchase Orders are marked as Sent. If you leave this option un-checked, Purchase Orders will still be generated for each Supplier, but they will not be electronically sent to the Supplier, even when the Purchase Order is marked as Sent. There may be reasons why you don't need the Supplier to receive it, for example you may have phoned ahead and placed your order and you don't wish to send the Supplier a duplicate order."
                  )}
                />
              )}
              {showModal !== EditRequisitionModalEnum.SendToSupplier && <div className="h-96" />}
            </Modal.Body>
            <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
              <Button
                type="button"
                className="h-11 w-full rounded-md bg-gray-200 px-8 text-sm md:w-fit"
                onClick={() => setShowModal(null)}
                data-testid="cancel-update-btn-bottom"
              >
                {t('general.cancel', 'Cancel')}
              </Button>
              <Button
                data-testid="save-changes-purchase-order-btn"
                type="submit"
                className="h-11 w-full rounded-md bg-primary px-5 text-sm text-white md:w-fit"
                loading={loading}
                disabled={!formMethods.formState.isDirty}
              >
                {t('general.saveChanges', 'Save Changes')}
              </Button>
            </Modal.Footer>
          </form>
        </FormProvider>
      </Modal.Panel>
    </Modal>
  )
}
