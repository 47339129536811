import { NetworkStatus, useQuery } from '@apollo/client'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import EmptyStateImg from '@/assets/images/empty-suppliers.svg'
import { GetInvoiceReceivingNotesDocument, ReceivingDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCreateReceivingDocument } from '@/modules/purchase-orders/hooks'
import TableView from '@/modules/purchase-orders/pages/purchase-order/receiving-notes/table-view'
import TileView from '@/modules/purchase-orders/pages/purchase-order/receiving-notes/tile-view'
import { Button, ConfirmDialog, QueryResult } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { ControlBarIcon, TruckIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'
import { extractEdges } from '@/modules/shared/utils'

export default function InvoiceReceivingNotes() {
  const { invoiceId } = useParams<{ invoiceId: string }>()
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()
  const [showNoPurchaseOrderWarning, setShowNoPurchaseOrderWarning] = useState(false)

  const { onCreateReceivingDocument, createReceivingDocumentLoading, modalProps, InventoryModal } =
    useCreateReceivingDocument()

  const { data, error, networkStatus } = useQuery(GetInvoiceReceivingNotesDocument, {
    variables: {
      invoiceId: Number(invoiceId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  const { invoiceNumber, purchaseOrderId, receivingDocuments } = data?.currentPurchaser?.invoice || {}

  const receivingDocs = extractEdges<ReceivingDocument>(receivingDocuments)

  return (
    <QueryResult
      loading={networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.refetch}
      error={error}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <ControlBarIcon className=" h-10 w-10" />
          <p className="ml-3 text-sm">
            {invoiceNumber} {t('general.receivingNotes', 'Receiving Notes')}
          </p>
        </div>
        <Button
          loading={createReceivingDocumentLoading}
          data-testid="receive-goods-btn"
          className="flex h-10 items-center gap-x-2 rounded-md bg-gray-200 px-4 text-sm"
          onClick={() =>
            purchaseOrderId ? onCreateReceivingDocument(String(purchaseOrderId)) : setShowNoPurchaseOrderWarning(true)
          }
        >
          {t('general.receiveGoods', 'Receive Goods')}
          <TruckIcon className="h-6 w-6" />
        </Button>
      </div>
      <section className="mt-4 flex flex-col gap-y-3">
        {receivingDocs.length > 0 && (
          <>
            {isLargeScreen ? (
              <div className="hidden lg:flex lg:flex-col">
                <TableView receivingDocs={receivingDocs} />
              </div>
            ) : (
              <div className="flex flex-col">
                <TileView receivingDocs={receivingDocs} />
              </div>
            )}
          </>
        )}
        {receivingDocs.length === 0 && (
          <EmptyState
            img={EmptyStateImg}
            message={t(
              'invoices.invoice.receivingNotes.noReceivingNotes',
              'There are no Goods Receiving Notes to display.'
            )}
          />
        )}
      </section>
      <InventoryModal {...modalProps} purchaseOrderId={String(purchaseOrderId)} />
      <ConfirmDialog
        isOpen={showNoPurchaseOrderWarning}
        setIsOpen={setShowNoPurchaseOrderWarning}
        title={t('invoices.invoice.receivingNotes.noPurchaseOrderWarning.title', 'No Matched Purchase Order')}
        description={t(
          'invoices.invoice.receivingNotes.noPurchaseOrderWarning.description',
          'There is no Purchase Order matched to this Invoice yet and so you cannot create a Receiving Note. To begin receiving goods, you can match a Purchase Order to the Invoice using the Find and Match button above.'
        )}
        primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
        onPrimaryBtnClick={() => setShowNoPurchaseOrderWarning(false)}
        theme={Themes.Primary}
      />
    </QueryResult>
  )
}
