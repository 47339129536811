import { graphql } from 'msw'

import { UpdateReceivingDocumentLineItemMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseUpdateReceivingDocumentLineItemMutation: UpdateReceivingDocumentLineItemMutation = {
  updateReceivingDocumentLineItem: {
    receivingDocumentLineItem: {
      id: 22273758,
      purchaseOrderLineItem: {
        id: 16566710,
        quantity: 1,
        __typename: 'PurchaseOrderLineItem',
      },
      quantity: 2,
      receivedQuantity: 0,
      comment: null,
      totalValue: 0,
      overrideUnitValue: null,
      expDate: null,
      temperature: null,
      vehicleTemperature: null,
      unitPrice: 12,
      receivingDocument: {
        id: 5854902,
        receivingTotalValue: 0,
        __typename: 'ReceivingDocument',
      },
      __typename: 'ReceivingDocumentLineItem',
    },
    __typename: 'UpdateReceivingDocumentLineItemMutationPayload',
  },
}

export const UpdateReceivingDocumentLine = graphql.mutation<UpdateReceivingDocumentLineItemMutation>(
  'UpdateReceivingDocumentLineItem',
  (_, res, ctx) => {
    return res(ctx.data(baseUpdateReceivingDocumentLineItemMutation))
  }
)

export default baseUpdateReceivingDocumentLineItemMutation
