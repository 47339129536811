import classNames from 'classnames'
import { Dispatch, SetStateAction } from 'react'

import { Button, Modal } from '@/modules/shared/components'
import { Themes } from '@/modules/shared/types'

interface Props {
  title: string
  description: string
  primaryButtonLabel: string
  secondaryButtonLabel?: string
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  onSecondaryBtnClick?: () => void
  onPrimaryBtnClick: () => void
  theme: Themes
  testId?: string
  btnPrimaryLoading?: boolean
}

export default function ConfirmDialog({
  title,
  description,
  primaryButtonLabel,
  secondaryButtonLabel,
  isOpen,
  setIsOpen,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  theme,
  testId,
  btnPrimaryLoading,
}: Props) {
  return (
    <Modal showModal={isOpen} onCloseModal={() => setIsOpen(false)}>
      <Modal.Panel className="w-full max-w-md overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
        <Modal.Title title={title} className="text-lg font-medium leading-6 text-gray-900" testId={testId} />

        <div className="mt-2">
          <p className="text-sm text-gray-500">{description}</p>
        </div>

        <div className="mt-4 flex flex-col justify-end gap-1 md:flex-row">
          {secondaryButtonLabel && (
            <Button
              type="button"
              data-testid="confirm-dialog-secondary-button"
              className="mr-2 flex h-11 w-full items-center justify-center rounded-md border bg-white px-4 text-sm font-medium text-gray-500 md:w-fit"
              onClick={() => (onSecondaryBtnClick ? onSecondaryBtnClick() : setIsOpen(false))}
            >
              {secondaryButtonLabel}
            </Button>
          )}
          <Button
            type="button"
            disabled={btnPrimaryLoading}
            data-testid="confirm-dialog-primary-button"
            className={classNames(
              'flex h-11 w-full items-center justify-center rounded-md px-4 text-sm font-medium md:w-fit',
              {
                'bg-primary text-white focus-visible:ring-primary': theme === Themes.Primary,
                'bg-error text-white focus-visible:ring-error': theme === Themes.Error,
                'cursor-progress opacity-50': btnPrimaryLoading,
              }
            )}
            onClick={onPrimaryBtnClick}
          >
            {primaryButtonLabel}
          </Button>
        </div>
      </Modal.Panel>
    </Modal>
  )
}
