import { graphql } from 'msw'

import { CreateBuyListMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseCreateBuyList: CreateBuyListMutation = {
  createCatalogue: {
    catalogue: {
      id: 182672,
      __typename: 'Catalogue',
    },
    __typename: 'CreateCatalogueMutationPayload',
  },
}

export const CreateBuyList = graphql.mutation<CreateBuyListMutation>('CreateBuyList', (_, res, ctx) => {
  return res(ctx.data(baseCreateBuyList))
})

export default baseCreateBuyList
