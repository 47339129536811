import { useTranslation } from 'react-i18next'

import { BadgeIcon } from '../../../icons-special/BadgeIcon'
import SideNavLink from '../SideNavLink'
import { SideBarItem } from './PurchaserNavigation'

import { useGetRequisitionAwaitingApprovalCount } from '@/modules/requisitions/hooks'
import { Accordion, CountBadge, displayBadgeCount } from '@/modules/shared/components'
import { CartIcon, PlusIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface RequisitionSideBarItemProps extends SideBarItem {
  requisitionRoutesMatch: boolean
}

export const RequisitionsSideBarItem = ({
  requisitionRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
}: RequisitionSideBarItemProps) => {
  const { t } = useTranslation()
  const { count } = useGetRequisitionAwaitingApprovalCount()

  return (
    <Accordion
      titlePrefixIcon={
        <BadgeIcon isBadged={!!count}>
          <CartIcon className="h-7 w-7" />
        </BadgeIcon>
      }
      title={t('general.requisitions', 'Requisitions')}
      buttonClassName="h-11 px-3"
      activeColor={requisitionRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === 0}
      handleTriggerClick={() => changeOpenAccordionKey(0)}
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="requisition-navigation"
        sideNavLinkItems={[
          {
            key: t('general.newRequisition', 'New Requisition'),
            target: '/requisitions/create',
            icon: <PlusIcon className="h-6 w-6 text-gray-500" />,
          },
          {
            key: t('general.viewAll', 'View All'),
            target: '/requisitions',
          },
          {
            key: t('general.awaitingMyApproval', 'Awaiting My Approval'),
            target: '/requisitions/awaiting-my-approval',
            icon: <CountBadge displayText={displayBadgeCount(count, 25)} />,
          },
          {
            key: t('general.myDrafts', 'My Drafts'),
            target: '/requisitions/drafts',
          },
        ]}
      />
    </Accordion>
  )
}
