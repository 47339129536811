import { useTranslation } from 'react-i18next'

import DetailsPanelItem from '../detail-panel-item/DetailsPanelItem'

import { Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatSupplierBillingAddress } from '@/modules/requisitions/utils'

export interface SupplierDetailsProps {
  supplier: Supplier
  showLess?: boolean
}

export default function SupplierDetails(props: SupplierDetailsProps) {
  const { supplier, showLess } = props
  const { t } = useTranslation()
  return (
    <section className="mt-2 border" data-testid="supplier-details">
      <DetailsPanelItem title={t('general.supplier', 'Supplier')} value={supplier.name} />
      <DetailsPanelItem
        title={t('general.headquarters', 'Headquarters')}
        value={formatSupplierBillingAddress(supplier)}
      />
      <DetailsPanelItem title={t('general.contactPhone', 'Contact Phone')} value={supplier.telephone} />
      <DetailsPanelItem title={t('general.contactEmail', 'Contact Email')} value={supplier.email} />
      {!showLess && (
        <DetailsPanelItem title={t('general.businessNumber', 'Business Number')} value={supplier.businessNumber} />
      )}
    </section>
  )
}
