import { Dispatch, ReactNode, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Modal } from '@/modules/shared/components'
import { CloseIcon } from '@/modules/shared/icons'

interface SuccessModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  icon: ReactNode
  title: string
  body: string | ReactNode
}

export default function SuccessModal({ showModal, setShowModal, icon, title, body }: SuccessModalProps) {
  const { t } = useTranslation()

  const onCloseModal = () => {
    setShowModal(false)
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal} dataTestId="success-modal">
      <Modal.Panel
        as="div"
        className="flex max-h-[80vh] w-full flex-col gap-y-2 overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]"
      >
        <Modal.Body className="flex flex-col gap-y-4 text-center">
          <div className="text-center">
            <div className="flex justify-end">
              <Button
                type="button"
                className="h-11 rounded-md bg-gray-100 px-3 focus:outline-none"
                onClick={onCloseModal}
              >
                <CloseIcon className="h-5 w-5" />
              </Button>
            </div>
            <div className="mx-auto w-max">{icon}</div>
          </div>
          <p className="text-center text-xl font-bold">{title}</p>
          <p className="px-5 text-gray-500">{body}</p>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-end">
          <Button
            type="button"
            className="h-11 w-full rounded-md bg-gray-200 px-6 text-sm md:w-fit"
            onClick={onCloseModal}
            data-testid="success-modal-close"
          >
            {t('general.okayGotIt', 'Okay, Got It')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
