import { t } from 'i18next'
import { Dispatch, SetStateAction } from 'react'
import { Link, generatePath } from 'react-router-dom'

import PurchaseOrderInvoicedStatus from './PurchaseOrderInvoicedStatus'

import {
  Invoice,
  PurchaseOrder,
  PurchaseOrderInvoicedStatusEnum,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, Modal } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { NewTabIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

interface PurchaseOrderInvoicedModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  purchaseOrder: PurchaseOrder
}

export default function PurchaseOrderInvoicedModal(props: PurchaseOrderInvoicedModalProps) {
  const { showModal, setShowModal, purchaseOrder } = props
  const { formatMoney } = useMoney()

  const onCloseModal = () => {
    setShowModal(false)
  }

  /**
   * returns a purchase order invoice modal text based on the POInvoicedStatus: PurchaseOrderInvoicedStatusEnum
   *
   * @param {PurchaseOrderInvoicedStatusEnum | null} status
   * @returns {body: string, title: string}
   */
  const modalText = (status: PurchaseOrderInvoicedStatusEnum | null): { body: string; title: string } => {
    switch (status) {
      case PurchaseOrderInvoicedStatusEnum.FullyInvoiced:
        return {
          title: t(
            'purchaseOrders.allPurchaseOrders.table.invoiceModal.fullyInvoicedTitle',
            '{{ purchaseOrderNumber }} is Invoiced',
            { purchaseOrderNumber: purchaseOrder?.purchaseOrderNumber }
          ),
          body: t(
            'purchaseOrders.allPurchaseOrders.table.invoiceModal.fullyInvoicedBody',
            'All of the items on this Purchase Order have been Invoiced. This means that the ordered quantity of each Purchase Order line has been accounted for on one or more matched Invoices. Invoices matched to this Purchase Order are listed below.'
          ),
        }
      case PurchaseOrderInvoicedStatusEnum.PartlyInvoiced:
        return {
          title: t(
            'purchaseOrders.allPurchaseOrders.table.invoiceModal.partlyInvoicedTitle',
            '{{ purchaseOrderNumber }} is Partly Invoiced',
            { purchaseOrderNumber: purchaseOrder?.purchaseOrderNumber }
          ),
          body: t(
            'purchaseOrders.allPurchaseOrders.table.invoiceModal.partlyInvoicedBody',
            'Some of the items on this Purchase Order have been Invoiced, but not all. This means that the ordered quantity of each Purchase Order line has not been accounted for on one or more matched Invoices. If there are Invoices already matched to this Purchase Order they will be listed below. You may wish to inspect the Invoices that are already matched to manually link lines, or you can match another Invoice if needed.'
          ),
        }
      default: // PurchaseOrderInvoicedStatusEnum.NotInvoiced:
        return {
          title: t(
            'purchaseOrders.allPurchaseOrders.table.invoiceModal.notInvoicedTitle',
            '{{ purchaseOrderNumber }} is Not Invoiced',
            { purchaseOrderNumber: purchaseOrder?.purchaseOrderNumber }
          ),
          body: t(
            'purchaseOrders.allPurchaseOrders.table.invoiceModal.notInvoicedBody',
            'None of the items on this Purchase Order have been Invoiced. This means that the ordered quantity of each Purchase Order line has not been accounted for on one or more matched Invoices. If there are Invoices already matched to this Purchase Order they will be listed below. You may wish to inspect the Invoices that are already matched to manually link lines, or you can match another Invoice if needed.'
          ),
        }
    }
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal} dataTestId="invoiced-modal">
      <Modal.Panel
        as="div"
        className="flex max-h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]"
      >
        <Modal.Title title={modalText(purchaseOrder?.invoicedStatus).title} onCloseModal={onCloseModal} />

        <Modal.Body>
          <section>
            <p className="font-bold">{t('general.invoicedStatus', 'Invoiced Status')}</p>
            <p className="text-gray-500">{modalText(purchaseOrder?.invoicedStatus).body}</p>
            <div className="mb-4 mt-2 rounded-md border bg-gray-100 p-3">
              <PurchaseOrderInvoicedStatus purchaseOrder={purchaseOrder} clickable={false} />
            </div>
          </section>
          <section className="flex flex-col">
            <p className="font-bold">{t('general.alreadyMatched', 'Already Matched')}</p>
            <p className="text-gray-500">
              {t(
                'purchaseOrders.allPurchaseOrders.table.invoiceModal.body',
                'The following Invoices are already matched to this Purchase Order. You may wish to inspect each Invoice to manually link Invoice Lines to Purchase Order lines.'
              )}
            </p>
            {purchaseOrder?.invoices?.edges?.length ? (
              <div className="mt-2 flex flex-col divide-y rounded-md border">
                {extractEdges<Invoice>(purchaseOrder.invoices).map((invoice) => (
                  <div className="flex justify-between p-3" key={invoice.id}>
                    <Link
                      className="flex cursor-pointer items-center gap-x-2 text-primary"
                      to={generatePath('/invoices/:id', { id: String(invoice.id) })}
                      data-testid="invoice-link"
                    >
                      {invoice.invoiceNumber}
                      <NewTabIcon className="h-5 w-5" />
                    </Link>
                    <p className="font-bold">{formatMoney(invoice.totalValue)}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p className="mt-5 border p-4 text-center">
                {t(
                  'purchaseOrders.allPurchaseOrders.table.invoiceModal.noInvoices',
                  'No Invoices have been matched to this Purchase Order yet.'
                )}
              </p>
            )}
          </section>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-end">
          <Button
            type="button"
            className="w-full rounded-md bg-gray-200 px-6 py-2.5 text-sm md:w-fit"
            onClick={onCloseModal}
            data-testid="invoices-close"
          >
            {t('general.close', 'Close')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
