import classNames from 'classnames'
import { useState } from 'react'

import InvoicesModal from '../../../../purchase-orders/pages/all-purchase-order/InvoicesModal'
import MatchInvoiceModal from '../../../../purchase-orders/pages/all-purchase-order/MatchInvoiceModal'
import { isPOMatchable } from '../../../../purchase-orders/pages/all-purchase-order/table-view'

import { PurchaseOrder } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button } from '@/modules/shared/components'
import { MatchIcon } from '@/modules/shared/icons'

interface POInvoiceProps {
  record: PurchaseOrder
}
export const POInvoice = ({ record }: POInvoiceProps) => {
  const [showInvoices, setShowInvoices] = useState<number | null>(null)
  const [showMatchInvoice, setShowMatchInvoice] = useState<number | null>(null)
  const hasInvoices = Boolean(record.invoices?.edges?.length)
  return (
    <>
      <Button
        onClick={() =>
          hasInvoices || !isPOMatchable(record) ? setShowInvoices(record.id) : setShowMatchInvoice(record.id)
        }
        className={classNames(`m-auto flex h-10 w-10 items-center justify-center rounded-full`, {
          'bg-gray-200 text-gray-500 hover:bg-gray-300': !hasInvoices,
          'bg-primary/10 text-primary hover:bg-primary/20': hasInvoices,
        })}
        data-testid="invoice-modal-button"
      >
        {hasInvoices || !isPOMatchable(record) ? record?.invoices?.edges?.length : <MatchIcon className="h-6 w-6" />}
      </Button>
      <InvoicesModal
        purchaseOrder={record}
        showModal={showInvoices === record.id}
        setShowModal={setShowInvoices}
        setShowMatchModal={setShowMatchInvoice}
      />
      <MatchInvoiceModal
        purchaseOrder={record}
        showModal={showMatchInvoice === record.id}
        setShowModal={setShowMatchInvoice}
      />
    </>
  )
}
