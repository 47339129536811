import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ThemeWrapper } from '@/modules/shared/components'
import ProductList from '@/modules/shared/components/product-list'
import { useGetAvailableProducts } from '@/modules/shared/hooks'
import { BuyListIcon, ChevronLeftIcon } from '@/modules/shared/icons'
import { ProductListType, Themes } from '@/modules/shared/types'
import { formatProductsToProductTypes } from '@/modules/shared/utils'

export default function BuyListBuilder() {
  const { t } = useTranslation()

  const { availableProducts, onFetchMoreProducts, onFilter, loadingStates, pageInfo } = useGetAvailableProducts()

  const products = formatProductsToProductTypes(availableProducts)

  return (
    <>
      <Helmet>
        <title>{t('general.buyListBuilder', 'Buy List Builder')}</title>
      </Helmet>
      <header className="px-5 py-3">
        <Link to="/buy-lists" className="flex h-11 w-fit items-center gap-2 rounded-md bg-gray-200 px-4 text-sm">
          <ChevronLeftIcon className="h-6 w-6" />
          {t('buyLists.builder.returnToBuyLists', 'Return to Buy Lists')}
        </Link>
      </header>
      <section className="mx-auto mt-5 max-w-[112.5rem] space-y-5 px-4">
        <section className="flex w-full flex-col justify-between rounded-md bg-white shadow-sm sm:flex-row">
          <div className="flex items-center gap-4 p-4">
            <ThemeWrapper theme={Themes.Primary} className="flex items-center justify-center rounded-md p-2">
              <BuyListIcon className="h-10 w-10 text-primary" />
            </ThemeWrapper>
            <div className="flex justify-between">
              <span>
                <p className="font-bold">{t('general.buyListBuilder', 'Buy List Builder')}</p>
                <p className="text-sm">{t('buyLists.builder.description', 'Add Products to your Buy Lists')}</p>
              </span>
            </div>
          </div>
        </section>
        <ProductList
          prNumber={t('general.buyListBuilder', 'Buy List Builder')}
          products={products}
          onFetchMoreProduct={onFetchMoreProducts}
          searchProductLoading={
            loadingStates.loading || loadingStates.setVariablesLoading || loadingStates.refetchLoading
          }
          hasMoreProduct={Boolean(pageInfo?.hasNextPage)}
          onFilter={onFilter}
          type={ProductListType.BuyListBuilder}
        />
      </section>
    </>
  )
}
