import { Disclosure } from '@headlessui/react'
import classNames from 'classnames'
import { Dispatch, SetStateAction, useState } from 'react'

import { Category } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Checkbox } from '@/modules/shared/components'
import { CheckboxGroup, CheckboxGroupItem } from '@/modules/shared/components/checkbox-group/checkbox-group'
import { ChevronDownIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

interface CategoriesCheckboxTreeProps {
  category: Category
  selectedCategories: number[]
  setSelectedCategories: Dispatch<SetStateAction<number[]>>
  selectedParentCategories: number[]
  setSelectedParentCategories: Dispatch<SetStateAction<number[]>>
}

function CategoriesCheckboxTree({
  category,
  selectedCategories,
  setSelectedCategories,
  selectedParentCategories,
  setSelectedParentCategories,
}: CategoriesCheckboxTreeProps) {
  const subCategories = extractEdges<Category>(category?.categories)
  let result: number[] = subCategories.map((category) => category.id).filter((id) => selectedCategories.includes(id))

  const [checkedList, setCheckedList] = useState<number[]>(result)
  const [indeterminate, setIndeterminate] = useState(
    checkedList.length > 0 && checkedList.length < subCategories.length
  )
  const [checkAll, setCheckAll] = useState(checkedList.length === subCategories.length)

  const removeDuplicates = (arr: number[]) => arr.filter((item, index) => arr.indexOf(item) === index)

  const onCheckOneChange = (list: number[], subCategoriesLength: number, categoryId: number) => {
    setCheckedList(list)
    setIndeterminate(Boolean(list.length) && list.length < subCategoriesLength)
    setCheckAll(list.length === subCategoriesLength)
    const subCategoryIds = subCategories.map((sub) => sub.id)
    const removedSameCategoryItems = selectedCategories.filter((item) => !subCategoryIds.includes(item))
    const concat = removedSameCategoryItems.concat(list)

    setSelectedCategories(removeDuplicates(concat))
    setSelectedParentCategories((prevParentCategories) => {
      if (Boolean(list.length) && list.length < subCategoriesLength)
        return removeDuplicates([...selectedParentCategories, categoryId])
      return prevParentCategories.filter((item) => item !== categoryId)
    })
  }

  const onCheckAllChange = (value: boolean, checkedList: number[], categoryId: number) => {
    setCheckedList(value ? checkedList : [])
    setIndeterminate(false)
    setCheckAll(value)

    setSelectedCategories((prevCategories) => {
      const concat = prevCategories.concat(checkedList)
      const findUnique = concat.filter((item, pos) => concat.indexOf(item) === pos)
      if (value) return findUnique
      return findUnique.filter((item) => !checkedList.includes(item))
    })
    setSelectedParentCategories((prevParentCategories) => {
      if (value) return removeDuplicates([...selectedParentCategories, categoryId])
      return prevParentCategories.filter((item) => item !== categoryId)
    })
  }

  return (
    <div className="flex flex-col gap-y-2">
      <Disclosure defaultOpen={false}>
        {({ open }) => (
          <>
            <div className="flex items-center justify-between rounded-md border bg-gray-100 p-2">
              <Checkbox
                aria-label={category.name?.toString()}
                isIndeterminate={indeterminate}
                onChange={(value) => {
                  const checkedList = subCategories.map((cat) => cat.id)
                  onCheckAllChange(value, checkedList, category.id)
                }}
                isSelected={checkAll}
              >
                {category.name}
              </Checkbox>
              <Disclosure.Button className="flex grow justify-end gap-x-2 py-1">
                <div className="flex items-center gap-x-2">
                  {checkedList.length > 0 && (
                    <span className="text-sm text-gray-500">{checkedList.length} Selected</span>
                  )}
                  <ChevronDownIcon
                    className={classNames('h-5 w-5 transition-all duration-300', {
                      'rotate-180 transform': open,
                    })}
                  />
                </div>
              </Disclosure.Button>
            </div>
            <Disclosure.Panel>
              <div className="ml-8 mt-1 rounded-md border bg-gray-100">
                {/* CheckboxGroup and CheckGroupItem needs string, we have to cast original data to match */}
                <CheckboxGroup
                  aria-label={category.name}
                  value={checkedList.map((e) => e.toString())}
                  onChange={(list) => {
                    onCheckOneChange(
                      list.map((e) => Number(e)),
                      subCategories.length,
                      category.id
                    )
                  }}
                >
                  {subCategories.map((subCategory, index) => (
                    <div
                      key={subCategory.id}
                      className={classNames('p-2 first:rounded-t-md last:rounded-b-md', { 'border-t': index > 0 })}
                    >
                      <CheckboxGroupItem
                        key={subCategory.id}
                        aria-label={subCategory.name?.toString()}
                        value={subCategory.id.toString()}
                      >
                        {subCategory.name}
                      </CheckboxGroupItem>
                    </div>
                  ))}
                </CheckboxGroup>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export default CategoriesCheckboxTree
