import { useTranslation } from 'react-i18next'

import CategoryFiltersSection from './filters-section/CategoryFiltersSection'
import DateRange from './filters-section/DateRange'
import FilterByFlag from './filters-section/FilterByFlag'
import { FilterByMatchedInvoices } from './filters-section/FilterByMatchedInvoices'
import { FilterByReceivingNotes } from './filters-section/FilterByReceivingNotes'
import FilterByStockLocation from './filters-section/FilterByStockLocation'
import PeriodRange from './filters-section/PeriodRange'
import SupplierFiltersSection from './filters-section/SupplierFiltersSection'
import AdvancedSearchMoreOptions from './more-options-section/AdvancedSearchMoreOptions'
import SearchSection from './search-section/SearchSection'

import { Document, QueryParameter } from '@/modules/shared/types'

// TODO: If this list gets too long change the config to a list of strings instead of individual props
interface AdvancedSearchProps {
  type?: Document
  showSupplier?: boolean
  showCategory?: boolean
  showSearch?: boolean
  showMoreOptions?: boolean
  advancedShopping?: boolean

  showMatchedInvoices?: boolean
  showReceivingNotes?: boolean
  showFilterByStockLocation?: boolean
  showFilterByCountStatus?: boolean
  showProductOptions?: boolean
  showFilterByImageStatus?: boolean
  showFilterByCataloguedStatus?: boolean
  showFilterByMatchedStatus?: boolean
  showFilterByMasterStatus?: boolean
}

export default function AdvancedSearch({
  type,
  showSupplier,
  showCategory,
  showSearch,
  showMoreOptions,
  advancedShopping,
  showMatchedInvoices,
  showReceivingNotes,
  showFilterByStockLocation,
  showProductOptions,
  showFilterByImageStatus,
  showFilterByCataloguedStatus,
  showFilterByMatchedStatus,
  showFilterByMasterStatus,
}: AdvancedSearchProps) {
  const { t } = useTranslation()

  const getDateRangeTitle = () => {
    switch (type) {
      case Document.Invoice:
        return t('invoices.allInvoices.searchByDate', 'Search By Invoice Date')
      case Document.Requisition:
      case Document.PurchaseOrder:
        return t('allRequisitions.filter.searchByDate', 'Search by Delivery Date')
      default:
        return null
    }
  }

  return (
    <div className="h-full divide-y rounded-md bg-white shadow-sm">
      {!!getDateRangeTitle() && (
        <div className="pt-1">
          <DateRange title={getDateRangeTitle() || ''} />
        </div>
      )}
      {type === Document.Stocktake && <PeriodRange title={t('general.searchByPeriod', 'Search By Period')} />}
      {showSupplier && <SupplierFiltersSection advancedShopping={advancedShopping} />}
      {showCategory && <CategoryFiltersSection advancedShopping={advancedShopping} />}
      {showMatchedInvoices && <FilterByMatchedInvoices />}
      {showReceivingNotes && <FilterByReceivingNotes />}
      {showSearch && <SearchSection advancedShopping={advancedShopping} />}
      {(showMoreOptions || showProductOptions) && <AdvancedSearchMoreOptions showProductOptions={showProductOptions} />}
      {showFilterByStockLocation && <FilterByStockLocation />}

      {showFilterByCataloguedStatus && (
        <FilterByFlag
          type={QueryParameter.Catalogued}
          title={t('advancedSearch.filters.filterByCatalogue', 'Filter By Catalogue')}
          options={[
            {
              label: t('general.showAll', 'Show All'),
              tooltip: t(
                'general.filter.showAllCatalogTooltip',
                'Do not apply a filter on the number of catalogs each product is in.'
              ),
              value: null,
            },
            {
              label: t('general.filter.hasCatalogue', 'Products in at least one Catalogue'),
              tooltip: t(
                'general.filter.hasCatalogueTooltip',
                'Show only products that have been added to at least one Catalog.'
              ),
              value: true,
            },
            {
              label: t('general.filter.notHaveCatalogue', 'Products not in any Catalogue'),
              tooltip: t(
                'general.filter.notHaveCatalogueTooltip',
                'Show only products that have not yet been added to any Catalogs.'
              ),
              value: false,
            },
          ]}
        />
      )}
      {showFilterByMatchedStatus && (
        <FilterByFlag
          type={QueryParameter.Matched}
          title={t('advancedSearch.filters.filterByMatched', 'Filter By Matched Status')}
          options={[
            {
              label: t('general.showAll', 'Show All'),
              tooltip: t(
                'general.filter.showAllMatchedTooltip',
                'Do not apply a filter on products based on matched status.'
              ),
              value: null,
            },
            {
              label: t('general.filter.hasMatched', 'Already Matched to a P+ Master Product'),
              tooltip: t(
                'general.filter.hasMatchedTooltip',
                'Show only products that have been matched a P+ Master Product or are themselves a P+ Master Product.'
              ),
              value: true,
            },
            {
              label: t('general.filter.notHaveMatched', 'Not Matched to a P+ Master Product'),
              tooltip: t(
                'general.filter.notHaveMatchedTooltip',
                'Show only products that are not matched a P+ Master Product.'
              ),
              value: false,
            },
          ]}
        />
      )}
      {showFilterByImageStatus && (
        <FilterByFlag
          type={QueryParameter.Image}
          title={t('advancedSearch.filters.filterByImage', 'Filter By Image')}
          options={[
            {
              label: t('general.showAll', 'Show All'),
              tooltip: t('general.showAllImageTooltip', 'Do not apply a filter on products based on image.'),
              value: null,
            },
            {
              label: t('general.filter.hasImage', 'Products with an Image'),
              tooltip: t('general.filter.hasImageTooltip', 'Show only products that have an image added.'),
              value: true,
            },
            {
              label: t('general.filter.notHaveImage', 'Products without an Image'),
              tooltip: t(
                'general.filter.notHaveImageTooltip',
                'Show only products that do not yet have an image added.'
              ),
              value: false,
            },
          ]}
        />
      )}
      {showFilterByMasterStatus && (
        <FilterByFlag
          type={QueryParameter.Master}
          title={t('advancedSearch.filters.filterByMaster', 'Filter By Self-Managed')}
          options={[
            {
              label: t('general.showAll', 'Show All'),
              tooltip: t('general.showAllMasterTooltip', 'Do not filter products based on Self-Managed status.'),
              value: null,
            },
            {
              label: t('general.filter.notHaveMaster', 'Self-Managed Products'),
              tooltip: t(
                'general.filter.notHaveMasterTooltip',
                'Show only Self-Managed products owned and managed by my Organisation.'
              ),
              value: false,
            },
            {
              label: t('general.filter.hasMaster', 'P+ Master Products'),
              tooltip: t(
                'general.filter.hasMasterTooltip',
                'Show only P+ Master products owned and managed by PurchasePlus.'
              ),
              value: true,
            },
          ]}
        />
      )}
      {/* TODO: This feature is on hold pending backend support */}
      {/* {showFilterByCountStatus && <FilterByCountStatus />} */}
    </div>
  )
}
