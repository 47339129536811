import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../button'
import Tooltip from '../../tooltip'

import { PurchaseOrder } from '@/graphql/purchasing/generated/purchasing_graphql'
import PurchaseOrderReceiptsModal from '@/modules/purchase-orders/pages/all-purchase-order/PurchaseOrderReceiptsModal'
import InventoryModal from '@/modules/purchase-orders/pages/purchase-order/receiving-notes/InventoryModal'
import { TruckIcon } from '@/modules/shared/icons'

interface PurchaseOrderReceiptsButtonProps {
  purchaseOrder: PurchaseOrder
}

export default function PurchaseOrderReceiptsButton({ purchaseOrder }: PurchaseOrderReceiptsButtonProps) {
  const { id, receivingDocuments } = purchaseOrder
  const totalReceipts = receivingDocuments?.edges?.length
  const hasReceipts = (totalReceipts || 0) > 0

  const { t } = useTranslation()

  const [openInventoryModal, setOpenInventoryModal] = useState(false)
  const [openReceiptsModal, setOpenReceiptsModal] = useState(false)

  const renderTooltip = () => {
    if (!hasReceipts) {
      return t('general.clickToCreateReceivingNote', 'Click to create a Goods Receiving Note')
    }
    return t('purchaseOrders.allPurchaseOrders.table.tooltips.receiptsCount', {
      defaultValue_one:
        '{{ count }} Receiving Note have been created against this Purchase Order. Click to view details.',
      defaultValue_many:
        '{{ count }} Receiving Notes have been created against this Purchase Order. Click to view details.',
      defaultValue_other:
        '{{ count }} Receiving Notes have been created against this Purchase Order. Click to view details.',
      count: totalReceipts,
    })
  }

  return (
    <>
      <Tooltip content={renderTooltip()}>
        <Button
          onClick={() => {
            if (hasReceipts) setOpenReceiptsModal(true)
            else setOpenInventoryModal(true)
          }}
          className={classNames(`flex h-10 w-10 items-center justify-center rounded-full`, {
            'bg-gray-200 text-gray-500 hover:bg-gray-300': !hasReceipts,
            'bg-primary/10 text-primary hover:bg-primary/20': hasReceipts,
          })}
          data-testid="receipts-modal-button"
        >
          {hasReceipts ? totalReceipts : <TruckIcon className="h-6 w-6" />}
        </Button>
      </Tooltip>
      <InventoryModal
        showModal={openInventoryModal}
        setShowModal={setOpenInventoryModal}
        purchaseOrderId={String(id)}
      />
      <PurchaseOrderReceiptsModal
        purchaseOrder={purchaseOrder}
        setShowModal={setOpenReceiptsModal}
        showModal={openReceiptsModal}
      />
    </>
  )
}
