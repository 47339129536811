import classNames from 'classnames'
import { useFormContext } from 'react-hook-form'

import { InvoiceFormInputs } from './'

interface InvoiceNumberProps {
  disabled?: boolean
  title: string
  description: string
}

export default function InvoiceNumber(props: InvoiceNumberProps) {
  const { disabled, title, description } = props
  const formMethods = useFormContext<InvoiceFormInputs>()

  return (
    <section className="mt-8">
      <h2 className="text-sm font-semibold">{title}</h2>
      <p className="text-sm text-gray-500">{description}</p>
      <div className="mt-4">
        <input
          disabled={disabled}
          data-testid="invoice-number-input"
          className={classNames('block w-full rounded-md border border-gray-300 p-3 shadow-sm sm:text-sm', {
            'border-error focus:border-error focus:outline-none focus:ring-error':
              formMethods.formState.errors.invoiceNumber,
            'focus:border-primary focus:outline-none focus:ring-primary': !formMethods.formState.errors.invoiceNumber,
            'bg-gray-200': disabled,
          })}
          type="text"
          aria-invalid={formMethods.formState.errors.invoiceNumber ? 'true' : 'false'}
          {...formMethods.register('invoiceNumber', { required: true })}
        />
      </div>
    </section>
  )
}
