import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { GetStocktakeDocument, StockCount } from '@/graphql/inventory/generated/inventory_graphql'
import { RansackDirection } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import { INVENTORY_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export const useGetStocktake = () => {
  const { currentOrganisation } = useCurrentOrganisation()
  const { stocktakeId, stockLocationId } = useParams<{ stockLocationId: string; stocktakeId: string }>()

  const { data, networkStatus, error, fetchMore } = useQuery(GetStocktakeDocument, {
    variables: {
      organisationId: Number(currentOrganisation?.id),
      stocktakeId: Number(stocktakeId),
      stockLocationId: Number(stockLocationId),
      first: 20,
      after: null,
      filter: {},
      sort: [
        {
          property: 'product_brand',
          direction: RansackDirection.Asc,
        },
      ],
    },
    context: { uri: INVENTORY_GRAPHQL_API },
    notifyOnNetworkStatusChange: true,
  })

  const { setVariablesLoading, refetchLoading, fetchMoreLoading, loading } = checkNetworkStatus(networkStatus)

  const stockCounts = extractEdges<StockCount>(data?.legalHolder?.stockLocation?.stockTake?.stockCounts)

  const onFetchMoreStockCounts = () => {
    fetchMore({
      variables: {
        after: data?.legalHolder?.stockLocation?.stockTake?.stockCounts?.pageInfo?.endCursor,
      },
    })
  }

  return {
    loading,
    error,
    fetchMoreLoading,
    setVariablesLoading,
    refetchLoading,
    stockCounts,
    stockLocation: data?.legalHolder?.stockLocation,
    onFetchMoreStockCounts,
  }
}
