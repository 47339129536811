import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath, useParams } from 'react-router-dom'

import Details from './Details'
import TableView from './table-view'
import TileView from './tile-view'

import ReceivingNoteEmptyStateImg from '@/assets/images/empty-po-lines.svg'
import {
  GetPoReceivingNoteDocument,
  PurchaseOrder,
  ReceivingDocument,
  ReceivingDocumentLineItem,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { ReceivingNoteStatus } from '@/modules/receiving-notes/components'
import { useReceivingDocumentMutations } from '@/modules/receiving-notes/hooks'
import { ReceivingNoteState } from '@/modules/receiving-notes/types'
import { Spinner } from '@/modules/requisitions/components'
import { Button, MoreOptionsMenu, QueryResult, ThemeWrapper, Tooltip } from '@/modules/shared/components'
import SearchAndFilter, { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { ENTERPRISE_GR_URL, PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useWindowSize } from '@/modules/shared/hooks'
import { useMoney } from '@/modules/shared/hooks'
import { ControlBarIcon, InfoIcon, OptionIcon, PrintIcon, TruckIcon } from '@/modules/shared/icons'
import { QueryParameter, Themes } from '@/modules/shared/types'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export default function ReceivingNoteSummary() {
  const { receivingNoteId, purchaseOrderId } = useParams<{ receivingNoteId: string; purchaseOrderId: string }>()
  const { t } = useTranslation()
  const [showInfo, setShowInfo] = useState(false)
  const { formatMoney } = useMoney()
  const { isLargeScreen } = useWindowSize()

  const {
    data: GRData,
    networkStatus,
    refetch,
    previousData: GRPreviousData,
    error,
  } = useQuery(GetPoReceivingNoteDocument, {
    variables: {
      receivingDocumentId: Number(receivingNoteId),
      purchaseOrderId: Number(purchaseOrderId),
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
  })

  const { receiveReceivingDocument, receiveReceivingDocumentLoading } = useReceivingDocumentMutations()

  const { setVariablesLoading, loading } = checkNetworkStatus(networkStatus)

  const data = GRData || GRPreviousData

  const receivingNote = data?.currentPurchaser?.purchaseOrder?.receivingDocument
  const receivingNoteLineItems = extractEdges<ReceivingDocumentLineItem>(receivingNote?.receivingDocumentLineItems)
  const receivingDocState = receivingNote?.workflowState || ''
  const stockLocation = receivingNote?.destination?.name || null

  const onFilter = ({ searchValue }: OnFilter) => {
    refetch({
      filter: {
        q: [
          {
            property: 'product_brand_or_product_itemDescription_cont',
            value: searchValue.trim(),
          },
        ],
      },
    })
  }

  return (
    <QueryResult loading={loading} error={error}>
      <div
        className={classNames('mx-auto mt-9 max-w-[95rem] space-y-5 pb-9', {
          'px-4': receivingDocState === ReceivingNoteState.Open,
        })}
      >
        <section className="flex flex-col gap-y-3">
          <section className="flex flex-col justify-center rounded-md bg-white shadow sm:flex-row sm:items-center sm:justify-between">
            <div className="flex items-center gap-4 p-4">
              <ThemeWrapper theme={Themes.Primary} className="flex items-center justify-center rounded-md p-2">
                <TruckIcon className="h-10 w-10 text-primary" />
              </ThemeWrapper>
              <span>
                <p className="font-bold">{t('general.receivingNote', 'Receiving Note')}</p>
                <p className="text-sm">{receivingNote?.receivingDocumentNumber}</p>
              </span>
            </div>
            <hr className="sm:hidden" />
            <div className="flex flex-col gap-x-2 p-0 sm:flex-row sm:items-center sm:p-4">
              <div className="flex items-center justify-between p-4 sm:justify-center sm:p-0">
                <p className="text-sm text-gray-500 sm:hidden">{t('general.actions', 'Actions')}</p>
                <div className="flex items-center gap-x-2">
                  <Tooltip
                    content={t('purchaseOrders.purchaseOrder.details.info', 'Show or hide details')}
                    showArrow={false}
                  >
                    <Button
                      data-testid="show-details-button"
                      className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                      onClick={() => setShowInfo(!showInfo)}
                    >
                      <InfoIcon className="h-8 w-8 text-gray-600" />
                    </Button>
                  </Tooltip>
                  {receivingDocState !== ReceivingNoteState.Open && (
                    <a
                      data-testid="print-button"
                      className="cursor-pointer items-center justify-center rounded-full bg-gray-200 p-1.5 text-gray-600 hover:brightness-95"
                      href={`${ENTERPRISE_GR_URL}/${receivingNoteId}/print`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PrintIcon className="h-8 w-8" />
                    </a>
                  )}
                  <MoreOptionsMenu showOpenInPPlus showHelp receivingNoteId={receivingNoteId} buttonTheme="dark">
                    <OptionIcon className="h-8 w-8" />
                  </MoreOptionsMenu>
                </div>
              </div>
            </div>
          </section>
          {receivingDocState === ReceivingNoteState.Open && (
            <section className="flex flex-col space-y-1 rounded-md bg-white p-4 shadow sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
              <p className="text-sm">
                {t('receivingNote.finaliseReceivingNote', 'You can finalise this Receiving Note')}
              </p>
              <Button
                loading={receiveReceivingDocumentLoading}
                data-testid="receive-receiving-note"
                className="flex h-10 items-center justify-between gap-x-2 rounded-md bg-primary px-4 text-sm text-white sm:justify-center"
                onClick={() =>
                  receiveReceivingDocument({
                    variables: {
                      input: { id: Number(receivingNote?.id) },
                    },
                  })
                }
              >
                {t('general.markAsReceived', 'Mark as Received')}
                <TruckIcon className="h-5 w-5" />
              </Button>
            </section>
          )}
          {showInfo && (
            <Details
              receivingNote={receivingNote as ReceivingDocument}
              purchaseOrder={data?.currentPurchaser?.purchaseOrder as PurchaseOrder}
            />
          )}
          {receivingDocState !== ReceivingNoteState.Open && (
            <section className=" flex flex-col space-y-2 rounded-md bg-white p-4 text-sm shadow md:flex-row md:items-center md:justify-between md:space-y-0">
              <p>{t('general.purchaseOrder', `Purchase Order`)}</p>
              <Link
                to={generatePath('/purchase-orders/:purchaseOrderId/summary', {
                  purchaseOrderId: String(data?.currentPurchaser?.purchaseOrder?.id),
                })}
                className="text-primary"
              >
                {data?.currentPurchaser?.purchaseOrder?.purchaseOrderNumber}
              </Link>
            </section>
          )}
          {stockLocation && (
            <section className=" flex flex-col space-y-2 rounded-md bg-white p-4 text-sm shadow md:flex-row md:items-center md:justify-between md:space-y-0">
              <p>
                {t(
                  'receivingNotes.itemsWillBeReceived',
                  `Items will be received to the {{stockLocation}} Stock Location`,
                  {
                    stockLocation,
                  }
                )}
              </p>
              <span className="w-fit rounded-full bg-gray-200 px-3 py-1 text-xs">{stockLocation}</span>
            </section>
          )}
          {receivingDocState !== ReceivingNoteState.Open && (
            <section
              className=" flex items-center justify-between rounded-md bg-white px-4 py-3 text-sm shadow"
              data-testid="receiving-doc-status"
            >
              <p>{t('general.status', 'Status')}</p>
              <ReceivingNoteStatus state={receivingDocState} />
            </section>
          )}
          <section className="my-3 hidden items-center text-sm sm:flex">
            <ControlBarIcon className=" h-10 w-10" />
            <span className="ml-3">{receivingNote?.receivingDocumentNumber}</span>
            <span className="ml-1">{t('general.productList', 'Product List')}</span>
          </section>
          <SearchAndFilter
            testId="receiving-line-items-search-input"
            placeholder={t('receivingNotes.searchPlaceholder', 'Search by Product Name or Brand')}
            ariaLabel={t('receivingNotes.searchPlaceholder', 'Search by Product Name or Brand')}
            onFilter={onFilter}
            queryParamFilterType={QueryParameter.Status}
          />
          {setVariablesLoading && <Spinner className="mt-12 h-28 md:h-32" />}
          {!setVariablesLoading && receivingNoteLineItems.length > 0 && (
            <>
              {isLargeScreen ? (
                <TableView receivingNoteLineItems={receivingNoteLineItems} receivingDocState={receivingDocState} />
              ) : (
                <TileView
                  receivingNoteLineItems={receivingNoteLineItems}
                  isReadOnly={receivingDocState === ReceivingNoteState.Received}
                />
              )}
              <section className="flex flex-col gap-3 border bg-white py-3 text-sm">
                <div className="flex justify-between px-4">
                  <span className="text-gray-500">{t('general.purchaseOrderTotal', 'Purchase Order Total')}</span>
                  <span>{formatMoney(data?.currentPurchaser?.purchaseOrder?.totalValueExcludingTax)}</span>
                </div>
                <hr />
                <div className="flex justify-between px-4">
                  <strong>{t('general.receivingTotal', 'Receiving Total')}</strong>
                  <strong>{formatMoney(receivingNote?.receivingTotalValue)}</strong>
                </div>
              </section>
            </>
          )}
          {!setVariablesLoading && receivingNoteLineItems.length === 0 && (
            <div className="mb-3 flex flex-col items-center justify-center">
              <img src={ReceivingNoteEmptyStateImg} className="h-64 md:h-72" alt="" />
              <div className="text-center">
                <p className="text-xs text-gray-500 md:text-sm">
                  {t('receivingNotes.details.receivingNotesEmptyState', 'There are no products to display.')}
                </p>
              </div>
            </div>
          )}
        </section>
      </div>
    </QueryResult>
  )
}
