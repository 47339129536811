import { useMutation } from '@apollo/client'
import { generatePath, useNavigate } from 'react-router-dom'

import { CreateBuyListDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useCreateBuyList() {
  const navigate = useNavigate()

  const [createBuyList, { loading }] = useMutation(CreateBuyListDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    onCompleted(data) {
      navigate(
        generatePath('/buy-lists/:buyListId/products', {
          buyListId: String(data?.createCatalogue?.catalogue?.id),
        })
      )
    },
  })

  return { createBuyList, loading }
}
