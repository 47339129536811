import { useTranslation } from 'react-i18next'

import { useSession } from '@/modules/access/hooks'
import { useCurrentLegalEntity } from '@/modules/purchasing/hooks'

export default function UserMenu() {
  const { t } = useTranslation()
  const { currentLegalEntity } = useCurrentLegalEntity()
  const { currentUser } = useSession()

  const renderRole = () => {
    if (currentLegalEntity?.isAPurchaser) {
      return t('general.role.purchaser', 'Purchaser')
    }
    if (currentLegalEntity?.isASupplier) {
      return t('general.role.supplier', 'Supplier')
    }
    return null
  }

  return (
    <>
      <p className="block px-4 pt-2 text-xxs">
        {t('access.signedInAs', 'Signed in as {{ legalEntity }}', {
          legalEntity: renderRole(),
        })}
      </p>
      <p className="block px-4 text-sm">{currentUser?.fullName}</p>
      <p className="block px-4 text-sm">{currentLegalEntity?.name}</p>
      <p className="block px-4 pb-2 text-sm font-semibold">{currentUser?.email}</p>
    </>
  )
}
