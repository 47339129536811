import { useLazyQuery } from '@apollo/client'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { DelimitedNumericArrayParam, useQueryParam } from 'use-query-params'

import CategoriesCheckboxTree from './CategoriesCheckboxTree'

import {
  CategoriesWithSubDocument,
  Category,
  RansackDirection,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { Spinner } from '@/modules/requisitions/components'
import { Button, Input, Modal, QueryResult } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useDebounce } from '@/modules/shared/hooks'
import { MagnifyingGlassIcon } from '@/modules/shared/icons'
import { Loading } from '@/modules/shared/icons-special'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

interface FilterByCategoryModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
}

export default function FilterByCategoryModal({ showModal, setShowModal }: FilterByCategoryModalProps) {
  const { t } = useTranslation()
  const [categoriesURLParam, setCategoriesURLParam] = useQueryParam('categories', DelimitedNumericArrayParam)
  const [parentCategoriesURLParam, setParentCategoriesURLParam] = useQueryParam(
    'parent-categories',
    DelimitedNumericArrayParam
  )

  const [searchValue, setSearchValue] = useState('')
  const [inputFocus, setInputFocus] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState<number[]>([])
  const [selectedParentCategories, setSelectedParentCategories] = useState<number[]>([])

  const debouncedQuery = useDebounce(searchValue, 500)
  const [fetchCategoriesWithSub, { data, refetch, fetchMore, networkStatus, error }] = useLazyQuery(
    CategoriesWithSubDocument,
    {
      variables: {
        first: 10,
        sort: [
          {
            property: 'name',
            direction: RansackDirection.Asc,
          },
        ],
      },
      context: {
        uri: PURCHASING_GRAPHQL_API,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  )

  const { setVariablesLoading, loading: networkLoading } = checkNetworkStatus(networkStatus)
  const loading = networkLoading || setVariablesLoading

  const categories = extractEdges<Category>(data?.categories)
  const hasNextPage = data?.categories?.pageInfo?.hasNextPage

  useEffect(() => {
    if (showModal) {
      setSearchValue('')
      // only call fetchCategoriesWithSub() when the modal is shown
      fetchCategoriesWithSub()
      setSelectedCategories((categoriesURLParam as number[]) || [])
      setSelectedParentCategories((parentCategoriesURLParam as number[]) || [])
    }
  }, [showModal])

  useEffect(() => {
    if (inputFocus) onSearchSupplier(debouncedQuery)
  }, [debouncedQuery])

  const onCloseModal = () => {
    setShowModal(false)
  }

  const onSearchSupplier = (searchText: string) => {
    refetch({ searchText })
  }

  const onFetchMoreCategories = () => {
    fetchMore({
      variables: { after: data?.categories?.pageInfo?.endCursor },
    })
  }

  const onSelectCategory = () => {
    onCloseModal()
    if (selectedCategories.length === 0) return setCategoriesURLParam(undefined)
    setCategoriesURLParam(selectedCategories)
    setParentCategoriesURLParam(selectedParentCategories)
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel className="flex h-[60vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]">
        <Modal.Title title={t('general.filterByCategory', 'Filter By Category')} onCloseModal={onCloseModal} />
        <Modal.Body className="space-y-3" id="InfiniteScroll">
          <span className="text-sm">
            <h1>
              <strong>{t('general.categoryList', 'Category List')}</strong>
            </h1>
            <p className="text-gray-500">
              {t(
                'shopPage.productList.categoryFilter.description',
                'Find and add or remove categories and sub-categories to your filter.'
              )}
            </p>
          </span>
          <Input
            className="w-full rounded-md border border-gray-300 p-3 text-sm focus:border-primary focus:outline-none focus:ring-primary"
            suffixIcon={MagnifyingGlassIcon}
            placeholder={t('general.search', 'Search')}
            onChange={(e) => setSearchValue(e.trim())}
            onFocus={() => setInputFocus(true)}
            aria-label={t('general.search', 'Search')}
          />
          {loading && (
            <div className="flex items-center justify-center">
              <Loading className="h-10 w-10 fill-gray-200 text-gray-300" />
            </div>
          )}
          {!loading && categories.length === 0 && (
            <p className="text-center text-sm text-gray-500">{t('general.noCategoryFound.', 'No Category found.')}</p>
          )}
          <QueryResult error={error}>
            {!loading && categories.length > 0 && (
              <InfiniteScroll
                dataLength={categories.length}
                next={onFetchMoreCategories}
                hasMore={!!hasNextPage}
                loader={<Spinner className="mt-5 h-10 md:w-16" />}
                scrollableTarget="InfiniteScroll"
                className="flex flex-col gap-y-3"
              >
                {categories.length > 0 &&
                  categories.map((category) => (
                    <CategoriesCheckboxTree
                      key={category.id}
                      category={category}
                      selectedCategories={selectedCategories}
                      setSelectedCategories={setSelectedCategories}
                      selectedParentCategories={selectedParentCategories}
                      setSelectedParentCategories={setSelectedParentCategories}
                    />
                  ))}
              </InfiniteScroll>
            )}
          </QueryResult>
        </Modal.Body>
        <Modal.Footer className="flex flex-col items-center justify-between gap-y-2 md:flex-row">
          <Button
            className="h-11 w-full rounded-md bg-gray-200 px-4 text-sm md:w-auto"
            data-testid="category-filter-clear"
            disabled={!Boolean(categoriesURLParam)}
            onClick={() => {
              onCloseModal()
              setCategoriesURLParam(undefined)
              setParentCategoriesURLParam(undefined)
            }}
          >
            {t('general.clearFilter', 'Clear Filter')}
          </Button>
          <div className="flex w-full flex-col-reverse gap-2 md:w-auto md:flex-row">
            <Button className="h-11 w-full rounded-md bg-gray-200 px-4 text-sm md:w-auto" onClick={onCloseModal}>
              {t('general.cancel', 'Cancel')}
            </Button>
            <Button
              className="h-11 w-full rounded-md bg-primary px-4 text-sm text-white md:w-auto"
              disabled={selectedCategories.length === 0}
              data-testid="category-filter-confirm"
              onClick={() => onSelectCategory()}
            >
              {t('general.applyFilter', 'Apply Filter')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
