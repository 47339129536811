import { Fragment, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { ReceivingDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { ReceivingNoteStatus } from '@/modules/receiving-notes/components'
import { ReceivingNoteState } from '@/modules/receiving-notes/types'
import { Fallback, PPNewDetailLink, SimpleTileViewItem } from '@/modules/shared/components'
import { MoreOptionsMenu } from '@/modules/shared/components'
import { ENTERPRISE_RECEIVING_TRANSFER } from '@/modules/shared/constants'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

export interface ReceivingNotesTileViewProps {
  receivingDocs: ReceivingDocument[]
}

function ReceivingNotesTileView({ receivingDocs }: ReceivingNotesTileViewProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  return (
    <>
      {receivingDocs.map((receivingNote) => {
        const {
          workflowState,
          id,
          purchaseOrder,
          receivingDocumentNumber,
          destination,
          receiver,
          transfer,
          receivingTotalValue,
          receivedDate,
        } = receivingNote

        return (
          <Fragment key={id}>
            <div className="rounded-md border bg-white" data-testid="receiving-notes-tile-view">
              <div className="flex justify-between p-4">
                <div>
                  <span className="break-all text-base font-bold">{receivingDocumentNumber}</span>
                  <p className="text-sm text-gray-500">{purchaseOrder?.purchaseOrderNumber}</p>
                </div>
                <div>
                  <div className="mt-0 text-right">
                    <span className="text-base font-bold">{`${formatMoney(receivingTotalValue)}`}</span>
                    <p className="text-sm text-gray-500">{t('general.receivingTotal', 'Receiving Total')}</p>
                  </div>
                </div>
              </div>
              <hr />
              <section className="space-y-3 p-4 text-sm" data-testid="receiving-note-details">
                <SimpleTileViewItem title={t('general.supplier', 'Supplier')} value={purchaseOrder?.supplier?.name} />
                <SimpleTileViewItem
                  title={t('general.status', 'Status')}
                  value={<ReceivingNoteStatus state={workflowState} />}
                />
                <SimpleTileViewItem
                  title={t('general.purchaseOrder', 'Purchase Order')}
                  value={
                    <PPNewDetailLink type="purchase-order" id={String(purchaseOrder?.id)}>
                      {purchaseOrder?.purchaseOrderNumber}
                    </PPNewDetailLink>
                  }
                />
                <SimpleTileViewItem title={t('general.receivedBy', 'Received By')} value={receiver?.fullName} />
                <SimpleTileViewItem
                  title={t('general.receivedOn', 'Received On')}
                  value={formatDate(Dates.Short, receivedDate)}
                />
                <SimpleTileViewItem title={t('general.destination', 'Destination')} value={destination?.name} />
                <SimpleTileViewItem
                  title={t('general.transfer', 'Transfer')}
                  value={
                    <Fallback condition={!!(transfer?.id && transfer?.transferNumber)}>
                      <a
                        className="text-primary"
                        href={`${ENTERPRISE_RECEIVING_TRANSFER}/${transfer?.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {transfer?.transferNumber}
                      </a>
                    </Fallback>
                  }
                />
              </section>
              <hr />
              <section className="flex items-center justify-between p-4">
                <Link
                  data-testid="view-receiving-note"
                  to={generatePath(
                    `/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/${
                      workflowState === ReceivingNoteState.Open ? 'draft' : ''
                    }`,
                    {
                      purchaseOrderId: String(purchaseOrder?.id),
                      receivingNoteId: String(id),
                    }
                  )}
                  className="flex h-11 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
                >
                  {t('general.viewReceivingNote', 'View Receiving Note')}
                </Link>

                <MoreOptionsMenu showPrint showOpenInPPlus showHelp showPurchaseOrderLink purchaseOrderId={id}>
                  <OptionIcon className="h-10 w-10 text-gray-500" />
                </MoreOptionsMenu>
              </section>
            </div>
          </Fragment>
        )
      })}
    </>
  )
}

export default memo(ReceivingNotesTileView)
