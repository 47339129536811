import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { StocktakeState } from '@/modules/shared/types'

export interface StockTakeStatusProps {
  state: StocktakeState
}

export default function StockTakeStatus({ state }: StockTakeStatusProps) {
  const { t } = useTranslation()

  const translationStateText = (state: StocktakeState) => {
    switch (state) {
      case StocktakeState.InProgress:
        return t('general.inProgress', 'In Progress')
      case StocktakeState.Closed:
        return t('general.closed', 'Closed')
      default:
        return t('general.unknown', 'Unknown')
    }
  }

  return (
    <span
      data-testid="receiving-note-status"
      className={classNames(`rounded-full px-3 py-1.5 text-xs`, {
        'bg-success text-white': state === StocktakeState.Closed,
        'bg-primary text-white': state === StocktakeState.InProgress,
      })}
    >
      {translationStateText(state)}
    </span>
  )
}
