import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { GetCreditNoteDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useGetCreditNote() {
  const { creditNoteId } = useParams<{ creditNoteId: string }>()
  const { data, loading, error } = useQuery(GetCreditNoteDocument, {
    variables: {
      creditNoteId: Number(creditNoteId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
    notifyOnNetworkStatusChange: true,
  })

  return {
    creditNote: data?.currentPurchaser?.creditNote,
    loading,
    error,
  }
}
