import { graphql } from 'msw'

import { FinancialApproveInvoiceMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseFinancialApproveInvoice: FinancialApproveInvoiceMutation = {
  financialApproveInvoice: {
    invoice: {
      id: 7524140,
      state: 'flagged',
      isFinanciallyApproved: true,
      isOperationallyApproved: false,
      __typename: 'Invoice',
    },
    __typename: 'FinancialApproveInvoiceMutationPayload',
  },
}

export const FinancialApproveInvoice = graphql.mutation<FinancialApproveInvoiceMutation>(
  'FinancialApproveInvoice',
  (_, res, ctx) => {
    return res(ctx.data(baseFinancialApproveInvoice))
  }
)

export default baseFinancialApproveInvoice
