import { graphql } from 'msw'

import { CategoriesWithSubQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetCategoriesWithSub: CategoriesWithSubQuery = {
  categories: {
    edges: [
      {
        node: {
          id: 109453,
          name: 'Anaesthesia Respiratory and Resuscitation',
          parentId: null,
          __typename: 'Category',
          categories: {
            edges: [
              {
                node: {
                  id: 109751,
                  name: 'Anaesthesia Circuits',
                  parentId: 109453,
                  __typename: 'Category',
                },
                __typename: 'CategoryEdge',
              },
              {
                node: {
                  id: 109749,
                  name: 'Anaesthesia & Respiratory Masks',
                  parentId: 109453,
                  __typename: 'Category',
                },
                __typename: 'CategoryEdge',
              },
              {
                node: {
                  id: 109750,
                  name: 'Anaesthesia Tubes and Connectors',
                  parentId: 109453,
                  __typename: 'Category',
                },
                __typename: 'CategoryEdge',
              },
            ],
            __typename: 'CategoryConnection',
          },
        },
        __typename: 'CategoryEdge',
      },
      {
        node: {
          id: 109470,
          name: 'Automotive',
          parentId: null,
          __typename: 'Category',
          categories: {
            edges: [
              {
                node: {
                  id: 110068,
                  name: 'Automotive Filters',
                  parentId: 109470,
                  __typename: 'Category',
                },
                __typename: 'CategoryEdge',
              },
              {
                node: {
                  id: 110069,
                  name: 'Automotive Fluids',
                  parentId: 109470,
                  __typename: 'Category',
                },
                __typename: 'CategoryEdge',
              },
              {
                node: {
                  id: 110073,
                  name: 'Automotive Pumps',
                  parentId: 109470,
                  __typename: 'Category',
                },
                __typename: 'CategoryEdge',
              },
              {
                node: {
                  id: 110070,
                  name: 'Automotive Tools',
                  parentId: 109470,
                  __typename: 'Category',
                },
                __typename: 'CategoryEdge',
              },
              {
                node: {
                  id: 110066,
                  name: 'Bearings',
                  parentId: 109470,
                  __typename: 'Category',
                },
                __typename: 'CategoryEdge',
              },
              {
                node: {
                  id: 110072,
                  name: 'Hoses',
                  parentId: 109470,
                  __typename: 'Category',
                },
                __typename: 'CategoryEdge',
              },
              {
                node: {
                  id: 110071,
                  name: 'Meters and Measuring Equipment',
                  parentId: 109470,
                  __typename: 'Category',
                },
                __typename: 'CategoryEdge',
              },
              {
                node: {
                  id: 110067,
                  name: 'Motor Vehicle Accessories',
                  parentId: 109470,
                  __typename: 'Category',
                },
                __typename: 'CategoryEdge',
              },
            ],
            __typename: 'CategoryConnection',
          },
        },
        __typename: 'CategoryEdge',
      },
    ],
    pageInfo: {
      endCursor: 'MTA',
      hasNextPage: false,
      __typename: 'PageInfo',
    },
    __typename: 'CategoryConnection',
  },
}

export const GetCategoriesWithSub = graphql.query<CategoriesWithSubQuery>('CategoriesWithSub', (_, res, ctx) => {
  return res(ctx.data(baseGetCategoriesWithSub))
})

export default baseGetCategoriesWithSub
