import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { OrganisationFeatureFlags } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useCurrentOrganisation, useGetPurchaser, useUpdateOrganisation } from '@/modules/purchasing/hooks'
import { QueryResult, SimpleTableItemWithTooltip } from '@/modules/shared/components'
import FormCheckBoxField from '@/modules/shared/components/create-form/FormCheckBoxField'
import { AdminFormInputs, Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'
import SaveFooter from '@/modules/suppliers/pages/supplier/supplier-details/form-elements/SaveFooter'

export default function AdminCustomize() {
  const { purchaser, loadingState, error, refetch: refetchGetPurchaser } = useGetPurchaser()
  const { t } = useTranslation()
  const { id, createdAt, parent, legalEntity, featureFlags } = purchaser || {}
  const { refetch: refetchCurrentOrganisation, currentOrganisation } = useCurrentOrganisation()
  const [updateOrganisation, { loading: updateOrganisationLoading }] = useUpdateOrganisation()
  const [remount, setRemount] = useState(0)

  const getDefaultValues = (featureFlags: OrganisationFeatureFlags) => {
    return {
      id,
      inventory: !!featureFlags?.inventory,
      promoBanners: !!featureFlags?.promoBanners,
      quickCreate: !!featureFlags?.quickCreate,
    }
  }

  const formMethods = useForm<AdminFormInputs>()

  // Set the defaults after load because featureFlags are null on load and the defaults just get set to null.
  useEffect(() => {
    if (featureFlags) {
      formMethods.reset(getDefaultValues(featureFlags as OrganisationFeatureFlags))
      setRemount(remount + 1)
    }
  }, [featureFlags, formMethods])

  const onSubmit: SubmitHandler<AdminFormInputs> = (data) => {
    const { id, inventory, promoBanners, quickCreate } = data
    updateOrganisation({
      variables: { input: { id, featureFlags: { inventory, promoBanners, quickCreate } } },
      onCompleted: (data) => {
        // only need to re-fetch the organisation if the current organisation has changed, if updating another
        //   organisation then we don't need to re-fetch the current organisation.
        if (currentOrganisation?.id === data?.updateOrganisation?.organisation?.id) {
          // Different types for updateOrganisation and currentOrganisation, need to manually refetch
          refetchCurrentOrganisation()
        } else {
          refetchGetPurchaser()
        }
        formMethods.reset(
          getDefaultValues(data?.updateOrganisation?.organisation?.featureFlags as OrganisationFeatureFlags)
        )
        setRemount(remount + 1)
      },
    })
  }

  const onDiscard = () => {
    formMethods.reset(getDefaultValues(featureFlags as OrganisationFeatureFlags))
    setRemount(remount + 1)
  }

  return (
    <QueryResult loading={loadingState.loading} error={error}>
      <div className="flex flex-col justify-between gap-10 xl:flex-row">
        <section className="flex w-full flex-col gap-y-5 text-sm xl:w-[52.5rem]">
          <section>
            <div className="my-3 flex justify-between">
              <p className="text-lg font-bold">{t('general.customize', 'Customize')}</p>
            </div>
            <hr />
          </section>
          <form onSubmit={formMethods.handleSubmit(onSubmit)} className="flex flex-col gap-y-5" key={remount}>
            <FormProvider {...formMethods}>
              <FormCheckBoxField
                title={t('general.enableQuickCreate', 'Enable Quick Create')}
                body={
                  <Trans t={t} i18nKey="adminCenter.customize.enableQuickCreateBody">
                    When this is selected, users at this Organisation who click the Start Shopping button will be met
                    with a quick create option, which offers an optional Reference field and a Stat Shopping button. The
                    Organisation must have a Default Delivery Address added, as well as a Default Department and Account
                    Code. The Purchase Requisition will be created as an All Suppliers type Requisition, and will be set
                    to Basic mode. The user will still have the option to create other types of Requisitions. This
                    setting may not be suitable for all Organisations.
                  </Trans>
                }
                text={t('general.enableQuickCreate', 'Enable Quick Create')}
                register="quickCreate"
              />
              <FormCheckBoxField
                title={t(
                  'adminCenter.customize.showPromotionalBanners',
                  'Show Promotional Banners in Basic Shopping Mode'
                )}
                body={
                  <Trans t={t} i18nKey="adminCenter.customize.showPromotionalBannersBody">
                    When this is selected, a promotional banner will appear in the top section of the Basic Shopping
                    view for all users at this Organisation. The content of these banners, including the Supplier who
                    the banner is promoting, appears the same across all of PurchasePlus and this may not be suitable
                    for some Organisations.
                  </Trans>
                }
                text={t('general.showPromotionalBanners', 'Show Promotional Banners')}
                register="promoBanners"
              />
              <div className="flex flex-col gap-y-4">
                {/* TODO: Uncomment when these fields are available for editing and remove the text from inventory */}
                {/* <FormCheckBoxField
                  title={t('general.sideNavigationCustomization', 'Side Navigation Customization')}
                  body={
                    <Trans t={t} i18nKey="adminCenter.customize.sideNavBody">
                      Use the options below to determine which modules of PurchasePlus should appear in the side menu
                      for all users at this Organisation. This technically will not prevent the user from navigating to
                      these options for example directly via the URL, but it will control the UI experience so that a
                      user would not know that these modules exist at all. This is a simple way to configure the
                      implementation for an Organisation, depending on the selected plan and module upgrades that have
                      been agreed to with the Purchaser.
                    </Trans>
                  }
                  text={t('adminCenter.customize.showHome', 'Show Home')}
                  register="todo"
                />
                <FormCheckBoxField
                  text={t('adminCenter.customize.showRequisitions', 'Show Requisitions')}
                  register="todo"
                  showText={false}
                />
                <FormCheckBoxField
                  text={t('adminCenter.customize.showPurchaseOrders', 'Show Purchase Orders')}
                  register="todo"
                  showText={false}
                />
                <FormCheckBoxField
                  text={t('adminCenter.customize.showBuyLists', 'Show Buy Lists')}
                  register="todo"
                  showText={false}
                />
                <FormCheckBoxField
                  text={t('adminCenter.customize.showRequisitions', 'Show Requisitions')}
                  register="todo"
                  showText={false}
                />{' '}
                <FormCheckBoxField
                  text={t('adminCenter.customize.catalogues', 'Show Catalogues')}
                  register="todo"
                  showText={false}
                />
                <FormCheckBoxField
                  text={t('adminCenter.customize.showSuppliers', 'Show Suppliers')}
                  register="todo"
                  showText={false}
                /> */}
                <FormCheckBoxField
                  title={t('general.sideNavigationCustomization', 'Side Navigation Customization')}
                  body={
                    <Trans t={t} i18nKey="adminCenter.customize.sideNavBody">
                      Use the options below to determine which modules of PurchasePlus should appear in the side menu
                      for all users at this Organisation. This technically will not prevent the user from navigating to
                      these options for example directly via the URL, but it will control the UI experience so that a
                      user would not know that these modules exist at all. This is a simple way to configure the
                      implementation for an Organisation, depending on the selected plan and module upgrades that have
                      been agreed to with the Purchaser.
                    </Trans>
                  }
                  text={t('general.showInventory', 'Show Inventory')}
                  register="inventory"
                  testId="inventory-toggle"
                />
              </div>
              {formMethods.formState.isDirty && (
                <SaveFooter onDiscardChanges={onDiscard} loading={updateOrganisationLoading} />
              )}
            </FormProvider>
          </form>
        </section>
        <section className="flex w-full flex-col gap-y-4 text-sm xl:w-96">
          <section className="divide-y rounded-md bg-white shadow" data-testid="organisation-details">
            <h2 className="px-5 py-4 text-base font-bold">
              {t('general.organisationDetails', 'Organisation Details')}
            </h2>
            <div className="flex flex-col gap-y-3 p-5 text-sm">
              <SimpleTableItemWithTooltip title={t('general.organisationId', 'Organisation ID')} value={String(id)} />
              <SimpleTableItemWithTooltip
                title={t('general.organisationType', 'Organisation Type')}
                value={t('general.purchaser', 'Purchaser')}
              />
              <SimpleTableItemWithTooltip
                title={t('general.parentOrganisation', 'Parent Organisation')}
                value={parent?.name}
              />
              <SimpleTableItemWithTooltip
                title={t('general.legalEntity', 'Legal Entity')}
                value={legalEntity ? t('general.yes', 'Yes') : t('general.no', 'No')}
              />
              <SimpleTableItemWithTooltip
                title={t('general.createdOn', 'Created On')}
                value={formatDate(Dates.Long, createdAt)}
              />
            </div>
          </section>
        </section>
      </div>
    </QueryResult>
  )
}
