import { useTranslation } from 'react-i18next'

import { Tooltip } from '@/modules/shared/components'
import { CheckIcon } from '@/modules/shared/icons'

interface PreferredSupplierProps {
  preferredSupplier: boolean
  supplierName: string | undefined | null
  className?: string
}

export default function PreferredSupplier({ preferredSupplier, supplierName, className }: PreferredSupplierProps) {
  const { t } = useTranslation()

  return (
    <>
      <span className={className}>{supplierName || t('general.noSuppliers', 'No Suppliers')}</span>
      {preferredSupplier && (
        <Tooltip
          content={t(
            'shopPage.productList.productCard.preferredSupplierTooltip',
            'Your organisation has tagged this Supplier as a Preferred Supplier'
          )}
        >
          <CheckIcon className="h-4 w-4" />
        </Tooltip>
      )}
    </>
  )
}
