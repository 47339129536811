import { graphql } from 'msw'

import { UpdateRequisitionLineMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseUpdateRequisitionLine: UpdateRequisitionLineMutation = {
  updateRequisitionLine: {
    requisition: {
      id: 5235941,
      totalValue: 23.98,
      totalTaxValue: 0,
      totalValueExcludingTax: 23.98,
      lines: [
        {
          id: 26770369,
          productId: 5814401,
          quantity: 2,
          __typename: 'RequisitionLine',
          cataloguedProductId: 5841814,
        },
      ],
      requisitionLinesBySuppliers: [
        {
          supplier: { id: 1, name: 'Supplier' },
          total: 23.98,
          subTotalExTax: 0,
          totalTax: 0,
          lines: [
            {
              id: 26770369,
              quantity: 1.0,
              unitPrice: 11.99,
              productId: 5814401,
              cataloguedProductId: 5841814,
              taxPercentage: 0,
              orderInstruction: 'keep it bellow 0 degree',
              department: {
                id: 106369,
                name: 'Work Test Account Pullman house keeping',
                __typename: 'Purchaser',
              },
              account: {
                id: 96448,
                accountName: '0063700 - Beverage Inventories',
                code: '0063700',
                __typename: 'Account',
              },

              tax: 0,
              total: 23.98,
              __typename: 'RequisitionLine',
            },
          ],
          __typename: 'RequisitionLinesBySupplier',
        },
      ],
      __typename: 'Requisition',
    },
    __typename: 'UpdateRequisitionLineMutationPayload',
  },
}

export const UpdateRequisitionLine = graphql.mutation<UpdateRequisitionLineMutation>(
  'UpdateRequisitionLine',
  (_, res, ctx) => {
    return res(ctx.data(baseUpdateRequisitionLine))
  }
)

export default baseUpdateRequisitionLine
