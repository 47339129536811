import { useQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BooleanParam, DelimitedNumericArrayParam, StringParam, useQueryParam } from 'use-query-params'

import { GetPossibleRequisitionLinesDocument, RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export const useGetPossibleRequisitionLines = () => {
  const { requisitionId } = useParams()
  const [suppliersURLParam] = useQueryParam('suppliers', DelimitedNumericArrayParam)
  const [categoriesURLParam] = useQueryParam('categories', DelimitedNumericArrayParam)
  const [searchTermURLParam] = useQueryParam('search', StringParam)
  const [supplierSearchURLParam] = useQueryParam('supplier-product-code', StringParam)
  const [purchaserSearchURLParam] = useQueryParam('purchaser-product-code', StringParam)
  const [ppSearchURLParam] = useQueryParam('p-plus-product-code', StringParam)
  const [bestBuyURLParam] = useQueryParam('best-buy', BooleanParam)

  const [searchProductLoading, setSearchProductLoading] = useState(false)

  const [filtersApplied, setFiltersApplied] = useState({
    term: searchTermURLParam?.trim() || '',
    supplierIds: suppliersURLParam as number[],
    categoryIds: categoriesURLParam as number[],
    supplierProductCode: supplierSearchURLParam,
    purchaserProductCode: purchaserSearchURLParam,
    productId: Number(ppSearchURLParam),
    bestBuy: !!bestBuyURLParam,
  })

  const { data, error, networkStatus, fetchMore, refetch } = useQuery(GetPossibleRequisitionLinesDocument, {
    variables: {
      first: 20,
      after: null,
      requisitionId: Number(requisitionId),
      ...filtersApplied,
    },
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
  })

  const { setVariablesLoading, refetchLoading, loading } = checkNetworkStatus(networkStatus)

  // setVariablesLoading is only true for a moment, returns to false before the data is returned. Set
  //    searchProductLoading to true until the data is returned.
  useEffect(() => {
    if (setVariablesLoading || refetchLoading) {
      setSearchProductLoading(true)
    } else if (data) {
      setSearchProductLoading(false)
    }
  }, [setVariablesLoading, refetchLoading, data])

  const onFilter = async ({
    searchValue,
    supplierIds,
    categoryIds,
    bestBuy,
    supplierProductCode,
    purchaserProductCode,
    pPlusProductCode,
  }: OnFilter) => {
    const newFilter = {
      term: searchValue,
      supplierIds: supplierIds as number[],
      categoryIds: categoryIds as number[],
      supplierProductCode: supplierProductCode,
      purchaserProductCode: purchaserProductCode,
      productId: Number(pPlusProductCode),
      bestBuy: !!bestBuy,
    }

    // Only filter if the filter values are different than the current filter values
    if (!_.isEqual(newFilter, filtersApplied)) {
      await refetch(newFilter)
      setSearchProductLoading(true)
      setFiltersApplied(newFilter)
    }
  }

  const onFetchMoreProduct = () => {
    fetchMore({
      variables: {
        after: data?.currentPurchaser?.requisition?.possibleRequisitionLines?.pageInfo.endCursor,
      },
    })
  }

  const requisition = data?.currentPurchaser?.requisition

  return {
    loading,
    searchProductLoading,
    setVariablesLoading,
    error,
    requisitionId,
    requisition,
    currentPurchaser: data?.currentPurchaser,
    possibleRequisitionLines: extractEdges<RequisitionLine>(requisition?.possibleRequisitionLines),
    onFetchMoreProduct,
    onFilter,
    hasNextPage: requisition?.possibleRequisitionLines?.pageInfo.hasNextPage,
    lines: requisition?.lines as RequisitionLine[],
  }
}
