import { graphql } from 'msw'

import { CreateInvoiceLineItemMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseCreateInvoice: CreateInvoiceLineItemMutation = {
  createInvoiceLineItem: {
    invoiceLineItem: {
      id: 24587558,
      __typename: 'InvoiceLineItem',
    },
    errors: [],
    __typename: 'CreateInvoiceLineItemMutationPayload',
  },
}

export const CreateInvoiceLineItem = graphql.mutation<CreateInvoiceLineItemMutation>(
  'CreateInvoiceLineItem',
  (_, res, ctx) => {
    return res(ctx.data(baseCreateInvoice))
  }
)

export default baseCreateInvoice
