import { graphql } from 'msw'

import { SendPurchaseOrderMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const SendPurchaseOrder = graphql.mutation<SendPurchaseOrderMutation>('SendPurchaseOrder', (_, res, ctx) => {
  return res(
    ctx.data({
      sendPurchaseOrder: {
        purchaseOrder: {
          id: 3929,
          sentDate: '2022-11-07T21:14:21+11:00',
          status: 'Sent',
          __typename: 'PurchaseOrder',
        },
        __typename: 'SendPurchaseOrderMutationPayload',
      },
      updatePurchaseOrder: {
        purchaseOrder: {
          id: 3929,
          sentDate: '2022-11-07T21:14:21+11:00',
          status: 'Sent',
          __typename: 'PurchaseOrder',
        },
        __typename: 'UpdatePurchaseOrderMutationPayload',
      },
    })
  )
})
