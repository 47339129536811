import Fallback from '../../fallback'

import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

export interface ShortDateItemProps {
  date: string | null | undefined
}

export const ShortDateItem = ({ date }: ShortDateItemProps) => {
  return <Fallback condition={!!date}>{formatDate(Dates.Short, date)}</Fallback>
}
