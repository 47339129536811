import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { useGetAllUsers } from '../../hooks'
import TableView from './table-view'
import TileView from './tile-view'

import UsersEmptyState from '@/assets/images/empty-users.svg'
import { Spinner } from '@/modules/requisitions/components'
import { Breadcrumb, FilterTabs, QueryResult } from '@/modules/shared/components'
import { TitleBar } from '@/modules/shared/components'
import SearchAndFilter from '@/modules/shared/components/search-input/SearchAndFilter'
import { useWindowSize } from '@/modules/shared/hooks'
import { HomeIcon, UserIcon } from '@/modules/shared/icons'
import { FilterTabItem, QueryParameter } from '@/modules/shared/types'

function AllUsers() {
  const { t } = useTranslation()
  const { isLargeScreen } = useWindowSize()
  const [filterURLParam] = useQueryParam(QueryParameter.Status, withDefault(StringParam, 'all'))
  const {
    users,
    loadingStates,
    onFetchMore,
    onNextPage,
    onPreviousPage,
    error,
    pageInfo,
    paginationResultsPerPage,
    onFilter,
  } = useGetAllUsers()

  const filterTabs: FilterTabItem[] = [
    {
      key: t('general.viewAll', 'View All'),
      filterValue: 'all',
    },
    {
      key: t('general.enabled', 'Enabled'),
      filterValue: 'enabled',
    },
    {
      key: t('general.disabled', 'Disabled'),
      filterValue: 'disabled',
    },
  ]

  return (
    <Fragment>
      <Helmet>
        <title>{t('general.viewAllUsers', 'View All Users')}</title>
      </Helmet>
      <main className="mx-auto space-y-5 px-4">
        <Breadcrumb className="overflow-x-auto rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="#">{t('general.systemSettings', 'System Settings')}</Breadcrumb.Item>
          <Breadcrumb.Item href="/users">{t('general.users', 'Users')}</Breadcrumb.Item>
          <Breadcrumb.Item>{filterTabs.find((e) => e.filterValue === filterURLParam)?.key}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="space-y-2">
          <TitleBar
            icon={<UserIcon className="h-10 w-10 " />}
            title={t('general.allUsers', 'All Users')}
            subtitle={t('general.viewAllUsers', 'View All Users')}
          />
          <FilterTabs tabs={filterTabs} />
        </section>
        <SearchAndFilter
          testId="users-search-input"
          placeholder={t('general.searchUsers', 'Search Users')}
          ariaLabel={t('general.searchUsers', 'Search Users')}
          queryParamFilterType={QueryParameter.Status}
          onFilter={onFilter}
        />
        <QueryResult loading={loadingStates.loading || loadingStates.refetchLoading} error={error}>
          {isLargeScreen ? (
            <TableView
              users={users.slice(0, paginationResultsPerPage)}
              pageInfo={pageInfo}
              paginationLoading={loadingStates.setVariablesLoading}
              onNextPage={onNextPage}
              onPreviousPage={onPreviousPage}
            />
          ) : (
            <InfiniteScroll
              dataLength={users.length}
              next={onFetchMore}
              hasMore={!!pageInfo.hasNextPage}
              loader={<Spinner className="mt-5 h-14 md:w-16" />}
            >
              <TileView users={users} />
            </InfiniteScroll>
          )}

          {!loadingStates.setVariablesLoading && users.length === 0 && (
            <div className="mb-3 flex flex-col items-center justify-center">
              <img src={UsersEmptyState} className="h-64 md:h-72" alt="" />
              <div className="text-center">
                <p className="text-xs text-gray-500 md:text-sm">
                  {t('systemSettings.usersEmptyState', 'There are no users that match your search.')}
                </p>
              </div>
            </div>
          )}
        </QueryResult>
      </main>
    </Fragment>
  )
}

export default AllUsers
