import { memo } from 'react'
import { UseFormRegister } from 'react-hook-form'

import { UserFormInputs } from '@/modules/access/types'

export interface UserDetailsFormFieldProps {
  title: string
  body: string
  placeholder: string
  register: UseFormRegister<UserFormInputs>
  registerString: string
}
const UserDetailsFormField = ({ title, body, placeholder, register, registerString }: UserDetailsFormFieldProps) => (
  <div className="w-full text-sm xl:w-1/2">
    <p className="font-bold">{title}</p>
    <p className="mb-2 text-gray-500">{body}</p>
    <input
      className="w-full rounded-md border border-gray-200 p-3 text-sm focus:outline-none focus:ring-primary"
      placeholder={placeholder}
      aria-label={title}
      {...register(registerString as keyof UserFormInputs)}
      data-testid={`${registerString}-input`}
    />
  </div>
)

export default memo(UserDetailsFormField)
