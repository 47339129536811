import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { InvoiceLineItem } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Accordion, ProductSummary, TableLineItem } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { NewTabIcon } from '@/modules/shared/icons'
import { extractEdges } from '@/modules/shared/utils'

interface RelatedInvoiceLineItemsProps {
  invoiceLineItem: InvoiceLineItem
}

export default function RelatedInvoiceLineItems({ invoiceLineItem }: RelatedInvoiceLineItemsProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const relatedInvoiceLineItems = extractEdges<InvoiceLineItem>(invoiceLineItem.relatedInvoiceLineItemsWithSamePo)
  const hasRelatedInvoices = relatedInvoiceLineItems.length > 0

  return (
    <section>
      <strong className="text-sm">{t('general.otherLinkedInvoiceLines', 'Other Linked Invoice Lines')}</strong>
      <p className="text-sm text-gray-500">
        {t(
          'invoices.invoice.invoiceLineModal.otherLinkedInvoiceLinesDesc',
          'If this Invoice is matched to a Purchase Order, and more than one Invoice has been matched to that Purchase Order, there may be other Invoice lines that are linked to the same Purchase Order line. If this is the case, other linked Invoice lines will appear here. This can be helpful to understand when multiple Invoices are sent by the Supplier for a single Purchase Order.'
        )}
      </p>
      {!hasRelatedInvoices && (
        <div className="mt-1 border bg-gray-100 py-4">
          <p className="text-center text-sm text-gray-500">
            {t('invoices.invoice.invoiceLineModal.noOtherLinkedInvoiceLines', 'No other Invoice Lines found.')}
          </p>
        </div>
      )}
      {hasRelatedInvoices &&
        relatedInvoiceLineItems.map((invoiceLineItem) => {
          const { invoice, id, product, unitPrice, taxPercentage, quantity, purchaseOrderLineItem } = invoiceLineItem
          return (
            <Accordion
              key={id}
              testId="accordion-invoice-line-item"
              title={invoice?.invoiceNumber || ''}
              buttonClassName="px-3 h-11 mt-4"
              titleClassName="font-bold"
            >
              <ProductSummary
                product={product}
                unitPrice={Number(unitPrice)}
                taxPercentage={taxPercentage}
                showPriceAndTaxPercentage={false}
              />
              <div className="mt-4 border" data-testid="linked-invoice-line-details">
                <TableLineItem
                  title={t('general.invoice', 'Invoice')}
                  tooltip={t(
                    'invoices.invoice.invoiceLineModal.otherLinkInvoiceTooltip',
                    'The other Invoice that includes the same linked Invoice line.'
                  )}
                  value={
                    <Link
                      className="flex items-center text-sm text-primary"
                      to={generatePath('/invoices/:id/summary', { id: String(invoice?.id) })}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {invoice?.invoiceNumber}
                      <NewTabIcon className="ml-1 h-5 w-5" />
                    </Link>
                  }
                />
                <TableLineItem
                  title={t('general.invoiceLineQty', 'Invoice Line Quantity')}
                  tooltip={t(
                    'invoices.invoice.invoiceLineModal.otherInvoiceLineQtyTooltip',
                    'The quantity shown on the Invoice line of this other Invoice.'
                  )}
                  value={quantity}
                />
                <TableLineItem
                  title={t('general.purchaseOrderLineId', 'Purchase Order Line ID')}
                  tooltip={t(
                    'invoices.invoice.invoiceLineModal.invoicePurchaseOrderLineIdTooltip',
                    'The unique identifier of the Purchase Order line that this Invoice Line is linked to.'
                  )}
                  value={purchaseOrderLineItem?.id}
                />
                <TableLineItem
                  title={t('general.invoiceUnitPrice', 'Invoice Unit Price')}
                  tooltip={t(
                    'invoices.invoice.invoiceLineModal.otherInvoiceUnitPriceTooltip',
                    'The tax exclusive Unit Price on the  Invoice line of this other Invoice.'
                  )}
                  value={formatMoney(unitPrice)}
                />
                <TableLineItem
                  title={t('general.invoiceTaxPercentageSymbol', 'Invoice Tax %')}
                  tooltip={t(
                    'invoices.invoice.invoiceLineModal.otherInvoiceTaxPercentageTooltip',
                    'The Tax Percentage on the Invoice line that will be added to the Invoice Unit Price.'
                  )}
                  value={`${taxPercentage}%`}
                />
              </div>
            </Accordion>
          )
        })}
    </section>
  )
}
