export default function formatNumber(num: number, decimalPlaces: number): string {
  const numFixed = num.toFixed(decimalPlaces)
  const numParts = numFixed.split('.')

  const integerPart = numParts[0]
  const decimalPart = numParts[1] || ''
  if (decimalPart.length < decimalPlaces) {
    return String(num)
  }
  return `${integerPart}.${decimalPart}`.replace(/\.?0+$/, '')
}
