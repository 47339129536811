import { Dispatch, SetStateAction, memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'
import { DelimitedNumericArrayParam, StringParam, useQueryParam } from 'use-query-params'

import InvoiceStatus from '../InvoiceStatus'

import { Invoice, PageInfo } from '@/graphql/purchasing/generated/purchasing_graphql'
import { InvoiceActionTypes } from '@/modules/invoices/types'
import { Fallback, MoreOptionsMenu, Tooltip } from '@/modules/shared/components'
import FilterByDateRangeModal from '@/modules/shared/components/advanced-search/filters-section/FilterByDateRangeModal'
import FilterBySupplierModal from '@/modules/shared/components/advanced-search/filters-section/FilterBySupplierModal'
import Table from '@/modules/shared/components/table/Table'
import {
  AccountCodeItem,
  InvoiceApproval,
  PurchaseOrderLinkOrMatchButton,
  ShortDateItem,
} from '@/modules/shared/components/table/table-column-items'
import { Tax } from '@/modules/shared/components/table/table-column-items'
import { TotalTaxInclusive } from '@/modules/shared/components/table/table-column-items'
import InvoiceReceiptsButton from '@/modules/shared/components/table/table-column-items/InvoiceReceiptsButton'
import { InvoiceIcon, OptionIcon } from '@/modules/shared/icons'
import { QueryParameter } from '@/modules/shared/types'

interface TableViewProps {
  invoices: Invoice[]
  paginationLoading: boolean
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'endCursor'>
  onNextPage: () => void
  onPreviousPage: () => void
  setPreventAdvancedSearchAutoOpening: Dispatch<SetStateAction<boolean>>
  awaitingMyApproval?: boolean
}

function InvoiceTableView({
  invoices,
  pageInfo: { hasNextPage, hasPreviousPage, endCursor },
  onNextPage,
  onPreviousPage,
  paginationLoading,
  setPreventAdvancedSearchAutoOpening,
  awaitingMyApproval,
}: TableViewProps) {
  const { t } = useTranslation()
  const [showSupplierFilterModal, setShowSupplierFilterModal] = useState(false)
  const [showInvoiceDateFilterModal, setShowInvoiceDateFilterModal] = useState(false)

  const [suppliersParam] = useQueryParam(QueryParameter.Suppliers, DelimitedNumericArrayParam)
  const [fromDateURLParam] = useQueryParam(QueryParameter.From, StringParam)
  const [toDateURLParam] = useQueryParam(QueryParameter.To, StringParam)

  const renderInvoice = ({ id, invoiceNumber }: Invoice) => {
    return (
      <Link
        data-testid="view-invoice-link"
        to={generatePath('/invoices/:invoiceId/summary', {
          invoiceId: String(id),
        })}
        className="text-primary"
      >
        {invoiceNumber}
      </Link>
    )
  }

  const renderPDFCopy = ({ attachmentUrl }: Invoice) => {
    return (
      <Fallback condition={!!attachmentUrl}>
        <Tooltip content={t('invoices.details.pdfCopyTooltip', 'Click to view PDF Original.')}>
          <a
            className="flex rounded-full bg-primary/10 p-2"
            href={String(attachmentUrl)}
            target="_blank"
            rel="noreferrer"
          >
            <InvoiceIcon className="h-7 w-7 text-primary" />
          </a>
        </Tooltip>
      </Fallback>
    )
  }

  const renderMoreOption = ({ id }: Invoice) => {
    return (
      <MoreOptionsMenu showHelp showOpenInPPlus showPrint showInvoiceLink invoiceId={id}>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </MoreOptionsMenu>
    )
  }

  return (
    <Table
      dataTestId="invoices-table-view"
      dataSource={invoices}
      keyExtractor={(record) => String(record.id)}
      pagination={{
        hasNextPage,
        hasPreviousPage,
        onNextPage: onNextPage,
        onPreviousPage: onPreviousPage,
        loading: paginationLoading,
        endCursor: endCursor,
      }}
      columnsHidden={awaitingMyApproval ? [] : ['operational', 'financial']}
      columns={[
        {
          title: t('general.invoice', 'Invoice'),
          key: 'invoice',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 8.125,
          maxWidth: 11.25,
          headerTooltip: t(
            'invoices.allInvoices.invoiceHeaderTooltip',
            'The Invoice Number. You can search by Invoice Number using the search field above.'
          ),
          render: renderInvoice,
        },
        {
          title: t('general.reference', 'Reference'),
          key: 'reference',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 11.25,
          maxWidth: 13.75,
          headerTooltip: t(
            'invoices.allInvoices.referenceHeaderTooltip',
            'The Reference from the Purchase Order matched to this Invoice. You can search by Reference using the search field above.'
          ),
          render: ({ reference }) => <Fallback condition={!!reference}>{reference}</Fallback>,
        },
        {
          title: t('general.purchaseOrder', 'Purchase Order'),
          key: 'purchaseOrder',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 8.125,
          maxWidth: 8.125,
          headerTooltip: t(
            'invoices.allInvoices.purchaseOrderHeaderTooltip',
            "The Purchase Order that has been matched to this Invoice, if any. You cannot sort or filter by this column yet but we're working on it!"
          ),
          render: (invoice) => <PurchaseOrderLinkOrMatchButton invoice={invoice} />,
        },
        {
          title: t('general.supplier', 'Supplier'),
          key: 'supplier',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 12.5,
          maxWidth: 15,
          headerTooltip: t(
            'invoices.allInvoices.supplierHeaderTooltip',
            'The Supplier that sent the Invoice. Click to filter by this column.'
          ),
          isHeaderActive: !!suppliersParam,
          onHeaderClick: () => {
            setShowSupplierFilterModal(true)
            setPreventAdvancedSearchAutoOpening(true)
          },
          render: ({ supplier }) => supplier?.name,
          renderHeaderFilterModal: () => (
            <FilterBySupplierModal showModal={showSupplierFilterModal} setShowModal={setShowSupplierFilterModal} />
          ),
        },
        {
          title: t('general.accountCode', 'Account Code'),
          key: 'accountCode',
          headerCellStyles: 'px-2 py-3',
          cellStyles: 'px-2 py-3',
          minWidth: 15,
          grow: true,
          headerTooltip: t(
            'invoices.allInvoices.accountCodeHeaderTooltip',
            "The Department and Account Code the Invoice will be expensed against. You cannot sort or filter by this column yet but we're working on it!"
          ),
          render: ({ department, account }) => <AccountCodeItem department={department} account={account} />,
        },
        {
          title: t('general.status', 'Status'),
          key: 'status',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 7.5,
          maxWidth: 7.5,
          headerTooltip: t(
            'invoices.allInvoices.statusHeaderTooltip',
            'The status of the Invoice. Use the tabs at the top of the page to filter by this column.'
          ),
          render: ({ state }) => state && <InvoiceStatus state={state} />,
        },
        {
          title: t('general.receipts', 'Receipts'),
          key: 'receipts',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          headerTooltip: t(
            'invoices.allInvoices.receiptsHeaderTooltip',
            'The Goods Receiving Notes that have been created against the Invoice, if any. You cannot sort or filter by this column.'
          ),
          render: (record) => (
            <div className="flex items-center justify-center">
              <InvoiceReceiptsButton invoice={record} />
            </div>
          ),
        },
        {
          title: t('general.pdfCopy', 'PDF Copy'),
          key: 'pdfCopy',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 5,
          maxWidth: 5,
          headerTooltip: t(
            'invoices.allInvoices.pdfCopyHeaderTooltip',
            "If the Invoice was created via Paperless Invoicing, a PDF copy of the original Invoice will be available here. You cannot sort or filter by this column yet but we're working on it!"
          ),
          render: renderPDFCopy,
        },
        {
          title: t('general.invoiceDate', 'Invoice Date'),
          key: 'invoiceDate',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 7.5,
          maxWidth: 7.5,
          headerTooltip: t(
            'invoices.allInvoices.invoiceDateHeaderTooltip',
            'The Invoice Date recorded on the Invoice. Click to filter by this column.'
          ),
          isHeaderActive: !!fromDateURLParam || !!toDateURLParam,
          render: ({ invoiceDate }) => <ShortDateItem date={invoiceDate} />,
          renderHeaderFilterModal: () => (
            <FilterByDateRangeModal
              setShowModal={setShowInvoiceDateFilterModal}
              showModal={showInvoiceDateFilterModal}
              title={t('general.filterByInvoiceDate', 'Filter by Invoice Date')}
            />
          ),
          onHeaderClick() {
            setShowInvoiceDateFilterModal(true)
            setPreventAdvancedSearchAutoOpening(true)
          },
        },
        {
          title: t('general.operational', 'Operational'),
          key: 'operational',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 7.5,
          maxWidth: 7.5,
          render: (invoice) => (
            <div className="flex items-center justify-center">
              <InvoiceApproval invoice={invoice} type={InvoiceActionTypes.OperationalApprove} />
            </div>
          ),
        },
        {
          title: t('general.financial', 'Financial'),
          key: 'financial',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 7.5,
          maxWidth: 7.5,
          render: (invoice) => (
            <div className="flex items-center justify-center">
              <InvoiceApproval invoice={invoice} type={InvoiceActionTypes.FinancialApprove} />
            </div>
          ),
        },
        {
          title: t('general.tax', 'Tax'),
          key: 'tax',
          headerCellStyles: 'px-1 py-3 text-right',
          cellStyles: 'px-1 py-3 text-right',
          minWidth: 5.625,
          maxWidth: 8.125,
          headerTooltip: t(
            'invoices.allInvoices.taxHeaderTooltip',
            'The Total Tax of the Invoice. You cannot sort or filter by this column.'
          ),
          render: ({ totalTaxValue }) => <Tax taxInclusiveTotal={totalTaxValue} />,
        },
        {
          title: t('general.total', 'Total'),
          key: 'total',
          headerCellStyles: 'px-1 py-3 text-right',
          cellStyles: 'px-1 py-3 text-right',
          minWidth: 6.875,
          maxWidth: 9.375,
          headerTooltip: t(
            'invoices.allInvoices.totalHeaderTooltip',
            'The Total of the Invoice including Tax. You cannot sort or filter by this column.'
          ),
          render: ({ totalValue }) => <TotalTaxInclusive totalValue={totalValue} />,
        },
        {
          title: t('general.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          headerTooltip: t('invoices.allInvoices.moreHeaderTooltip', 'More Options'),
          render: renderMoreOption,
        },
      ]}
    />
  )
}

export default memo(InvoiceTableView)
