//https://flowbite.com/docs/components/spinner/#colors
// TODO: extract into a component, not an icon
export default function Loading(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      role="status"
      {...props}
      className={`${props.className} inline animate-spin`}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 10.018C20 15.4862 15.5228 19.919 10 19.919C4.47716 19.919 0 15.4862 0 10.018C0 4.54983 4.47716 0.117004 10 0.117004C15.5228 0.117004 20 4.54983 20 10.018ZM1.81629 10.018C1.81629 14.493 5.48026 18.1207 10 18.1207C14.5197 18.1207 18.1837 14.493 18.1837 10.018C18.1837 5.543 14.5197 1.91531 10 1.91531C5.48026 1.91531 1.81629 5.543 1.81629 10.018Z"
        fill="currentColor"
      />
      <path
        d="M18.7935 7.73088C19.2786 7.60472 19.5725 7.11122 19.4016 6.64435C19.0587 5.70748 18.5742 4.82559 17.9634 4.02932C17.1691 2.99391 16.1765 2.12354 15.0425 1.46791C13.9085 0.812276 12.6551 0.384218 11.354 0.208177C10.3533 0.0727908 9.33953 0.0884942 8.34691 0.253224C7.85227 0.335313 7.56261 0.831254 7.69003 1.31155C7.81747 1.79184 8.31389 2.07361 8.81011 2.00142C9.57023 1.89081 10.3438 1.88652 11.1081 1.98993C12.1729 2.13399 13.1986 2.48431 14.1266 3.02084C15.0547 3.5574 15.867 4.26969 16.517 5.11704C16.9835 5.72518 17.36 6.39433 17.6362 7.10413C17.8166 7.5675 18.3084 7.85706 18.7935 7.73088Z"
        fill="currentFill"
      />
    </svg>
  )
}
