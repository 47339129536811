import classNames from 'classnames'
import { Link, generatePath } from 'react-router-dom'

import { PurchaseOrder } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback } from '@/modules/shared/components'

export interface SimpleTileViewItemProps {
  title: string
  value?: string | number | null | JSX.Element
  className?: string
  po?: boolean
  purchaseOrder?: PurchaseOrder | null
  yGap?: boolean
}

const SimpleTileViewItem = ({ title, value, className, po, purchaseOrder, yGap = true }: SimpleTileViewItemProps) => (
  <div className={classNames('flex flex-col justify-between md:flex-row', className, { 'gap-y-1': yGap })}>
    <strong>{title}</strong>
    {po ? (
      <Link
        data-testid="view-po-link-res"
        to={generatePath('/purchase-orders/:purchaseOrderId/summary', {
          purchaseOrderId: String(purchaseOrder?.id),
        })}
        className="text-primary"
      >
        {purchaseOrder?.purchaseOrderNumber}
      </Link>
    ) : (
      <Fallback as="p" className="pl-0 text-left md:pl-2" condition={!!value}>
        {value}
      </Fallback>
    )}
  </div>
)

export default SimpleTileViewItem
