import classNames from 'classnames'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { StockLocation } from '@/graphql/inventory/generated/inventory_graphql'
import { MoreOptionsMenu, SimpleTileViewItem } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon } from '@/modules/shared/icons'

interface TileViewProps {
  stockLocations: StockLocation[]
}

function TileView(props: TileViewProps) {
  const { stockLocations } = props
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  return (
    <>
      {stockLocations.map((stockLocation) => {
        const { id, name, balanceValue } = stockLocation
        return (
          <div key={`${id}-res`} className="mb-6 rounded-md border bg-white">
            <div className="flex flex-col p-4">
              <span className="break-all text-base font-bold">{name}</span>
              <p className="text-sm text-gray-500">{formatMoney(balanceValue)}</p>
            </div>
            <hr />
            <section data-testid="stock-location-details" className="flex flex-col gap-y-3 p-4 text-sm">
              <SimpleTileViewItem title={t('general.balance', 'Balance')} value={formatMoney(balanceValue)} />
            </section>
            <hr />
            <div className={classNames('flex w-full items-center justify-end p-4')}>
              {/* TODO: Uncomment and fix when page is added */}
              {/* <Link
                data-testid="view-stock-location-link-res"
                to={generatePath('/stock-locations/:id/products', {
                  id: id.toString(),
                })}
                className="flex h-12 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
              >
                {t('general.viewBuyList', 'View Buy List')}
              </Link> */}
              <MoreOptionsMenu showOpenInPPlus showHelp stockLocationId={id}>
                <OptionIcon className="h-10 w-10 text-gray-500" />
              </MoreOptionsMenu>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default memo(TileView)
