import { useTranslation } from 'react-i18next'

import { useMoney } from '@/modules/shared/hooks'

interface TaxProps {
  taxInclusiveTotal: number | null | undefined
}

export const Tax = ({ taxInclusiveTotal }: TaxProps) => {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  return (
    <>
      <p className="text-base font-bold">{formatMoney(taxInclusiveTotal)}</p>
      <p className="text-xs text-gray-600">{t('general.tax', 'Tax')}</p>
    </>
  )
}
