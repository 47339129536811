import { graphql } from 'msw'

import { GetCurrentLegalEntityQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetCurrentLegalEntity: GetCurrentLegalEntityQuery = {
  currentLegalEntity: {
    id: 44926,
    name: 'Test Account',
    isASupplier: false,
    isAPurchaser: true,
    currency: 'AUD',
    isInventoryEnabled: true,
    ppnewAccess: true,
    __typename: 'Purchaser',
  },
}

export const GetCurrentLegalEntity = graphql.query<GetCurrentLegalEntityQuery>(
  'GetCurrentLegalEntity',
  (_, res, ctx) => {
    return res(ctx.data(baseGetCurrentLegalEntity))
  }
)

export default baseGetCurrentLegalEntity
