import { DEFAULT_CONCATENATED_SELL_UNIT } from '../constants'

import { Maybe } from '@/graphql/purchasing/generated/purchasing_graphql'
import { titleCase } from '@/modules/shared/utils'

type Input = {
  itemSize: Maybe<number> | undefined
  itemMeasure: Maybe<string> | undefined
  itemSellUnit: Maybe<string> | undefined
  itemPackName: Maybe<string> | undefined
}

export function formatProductItem(input: Input): string {
  let { itemSize, itemMeasure, itemPackName, itemSellUnit } = input
  let result = ''

  const itemSizeString = itemSize ?? ''
  itemMeasure = itemMeasure ?? ''
  itemPackName = itemPackName ?? ''
  itemSellUnit = itemSellUnit ?? ''

  if (itemSize === 1 && itemMeasure === 'each' && itemSellUnit === DEFAULT_CONCATENATED_SELL_UNIT) {
    result = titleCase(itemSellUnit || DEFAULT_CONCATENATED_SELL_UNIT)
  } else {
    result = `${itemSizeString} ${itemMeasure} ${titleCase(itemPackName || '')} ${
      (itemSize || itemMeasure || itemPackName) && '|'
    } ${titleCase(itemSellUnit || DEFAULT_CONCATENATED_SELL_UNIT)}`
  }
  return result.replace(/\s+/g, ' ').trim()
}
