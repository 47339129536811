import { graphql } from 'msw'

import { UpdateUserMutation } from '@/graphql/access/generated/access_graphql'

const baseUpdateUser: UpdateUserMutation = {
  updateUser: {
    user: {
      id: 7831,
      fullName: 'New name',
      email: 'bau@marketboomer.com',
      telephone: '',
      staffCode: '1234',
      autoSendPurchaseOrder: true,
      enforceBuyList: false,
      createdAt: '2016-10-13T12:10:50+11:00',
      lastSignInAt: '2022-08-11T19:46:12+10:00',
      position: 'BAU',
      isDeleted: false,
      defaultOrganisationId: 1,
      defaultOrganisation: {
        id: 1,
        name: 'Marketboomer',
        __typename: 'Organisation',
      },
      __typename: 'User',
    },
    __typename: 'UpdateUserMutationPayload',
  },
}

export const UpdateUser = graphql.mutation<UpdateUserMutation>('UpdateUser', (_, res, ctx) => {
  return res(ctx.data(baseUpdateUser))
})

export default baseUpdateUser
