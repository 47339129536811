import classNames from 'classnames'
import { ReactNode } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Checkbox } from '@/modules/shared/components'

interface FormCheckBoxFieldProps {
  title?: string
  body?: string | ReactNode
  text: string
  register: string
  showText?: boolean
  testId?: string
}

const FormCheckBoxField = ({ title, body, text, register, showText = true, testId }: FormCheckBoxFieldProps) => {
  const { control } = useFormContext()

  return (
    <div>
      {showText && (
        <>
          <p className="text-sm font-bold">{title}</p>
          <p className="text-sm text-gray-500">{body}</p>
        </>
      )}
      <div className={classNames({ 'mt-4': showText })}>
        <label className="flex items-center text-sm" data-testid={testId}>
          <Controller
            control={control}
            name={register}
            render={({ field }) => (
              <Checkbox onChange={(e) => field.onChange(e)} defaultSelected={Boolean(field.value)}>
                {text}
              </Checkbox>
            )}
          />
        </label>
      </div>
    </div>
  )
}

export default FormCheckBoxField
