import { graphql } from 'msw'

import { GetAccountsQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

export const GetAccounts = graphql.query<GetAccountsQuery>('GetAccounts', (_, res, ctx) => {
  return res(
    ctx.data({
      purchaser: {
        id: 100617,
        accountsInTree: [
          {
            id: 96388,
            accountName: '0060004 - Operating Account - 4',
            __typename: 'Account',
          },
          {
            id: 96389,
            accountName: '0060005 - Operating Account - 5',
            __typename: 'Account',
          },
          {
            id: 97162,
            accountName: '9999911 - No. of Guest Arrivals',
            __typename: 'Account',
          },
        ],
        __typename: 'Purchaser',
      },
    })
  )
})
