import { graphql } from 'msw'

import {
  GetRequisitionPurchaseOrdersQuery,
  PurchaseOrderInvoicedStatusEnum,
  PurchaseOrderReceivedStatusEnum,
} from '@/graphql/purchasing/generated/purchasing_graphql'

export const GetRequisitionPurchaseOrders = graphql.query<GetRequisitionPurchaseOrdersQuery>(
  'GetRequisitionPurchaseOrders',
  (_, res, ctx) => {
    return res(
      ctx.data({
        currentPurchaser: {
          id: 44926,
          requisition: {
            id: 5235941,
            requisitionNumber: 'PR00040',
            purchaseOrders: {
              edges: [
                {
                  node: {
                    id: 16565994,
                    purchaseOrderNumber: 'PO03635',
                    requisition: {
                      id: 5270680,
                      reference: '',
                      requisitionNumber: 'PR00040',
                      __typename: 'Requisition',
                    },
                    supplier: {
                      id: 568,
                      name: 'Coca Cola Amatil P/L',
                      __typename: 'Supplier',
                    },
                    department: {
                      id: 85127,
                      name: 'Beverage',
                      __typename: 'Purchaser',
                    },
                    account: {
                      id: 96392,
                      code: '0060008',
                      __typename: 'Account',
                    },
                    status: 'Received',
                    sentDate: '2022-08-30T16:29:47+10:00',
                    receivedStatus: PurchaseOrderReceivedStatusEnum.FullyReceived,
                    invoicedStatus: PurchaseOrderInvoicedStatusEnum.NotInvoiced,
                    expectedDeliveryDate: null,
                    totalValue: 223.432,
                    __typename: 'PurchaseOrder',
                  },
                  __typename: 'PurchaseOrderEdge',
                },
              ],
              __typename: 'PurchaseOrderConnection',
            },
            __typename: 'Requisition',
          },
          __typename: 'Purchaser',
        },
      })
    )
  }
)
