import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import BalanceContainer from './BalanceContainer'
import BalanceModal from './balance-modal'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import { InvoiceStatuses } from '@/modules/invoices/types'
import { Button } from '@/modules/shared/components'
import { useMoney } from '@/modules/shared/hooks'
import { CheckAltIcon, ChevronDownIcon, CloseIcon } from '@/modules/shared/icons'

interface BalancePanelProps {
  invoice: Invoice
}

export default function BalancePanel({ invoice }: BalancePanelProps) {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const [showBalance, setShowBalance] = useState(false)
  const [showBalanceModal, setShowBalanceModal] = useState(false)

  const {
    lineTotal,
    lineTaxTotal,
    deliveryChargeExTax,
    deliveryChargeTax,
    adjustmentExTax,
    adjustmentTax,
    totalTaxValue,
    totalValue,
    outOfBalance,
    state,
  } = invoice

  const totalExTax = (totalValue || 0) - (totalTaxValue || 0)
  const showUpdateButton = state === InvoiceStatuses.Open || state === InvoiceStatuses.Flagged

  return (
    <section className="rounded-md bg-white text-sm shadow">
      <div className="flex min-h-[3.75rem] items-center justify-between px-4 py-3">
        <div className="flex items-center gap-x-2 font-bold">
          {outOfBalance ? (
            <>
              <CloseIcon className="h-8 w-8 rounded-full bg-error p-1.5 text-white" />
              {t('invoices.invoice.balance.outOfBalance', 'Invoice is Out of Balance')}
            </>
          ) : (
            <>
              <CheckAltIcon className="h-8 w-8 rounded-full bg-success p-1.5 text-white" />
              {t('invoices.invoice.balance.inBalance', 'Invoice is in Balance')}
            </>
          )}
        </div>
        <div className="flex items-center gap-x-2">
          {showUpdateButton && (
            <Button
              className="rounded-md bg-gray-200 px-4 py-2"
              onClick={() => setShowBalanceModal(true)}
              data-testid="balance-modal-button"
            >
              {t('general.updateValues', 'Update Values')}
            </Button>
          )}
          <ChevronDownIcon
            className={classNames('h-8 w-8 cursor-pointer rounded-full p-1 text-gray-500 hover:bg-gray-100', {
              'rotate-180 transform': showBalance,
            })}
            onClick={() => setShowBalance(!showBalance)}
            data-testid="show-balance-panel"
          />
          <BalanceModal invoice={invoice} showModal={showBalanceModal} setShowModal={setShowBalanceModal} />
        </div>
      </div>
      {showBalance && (
        <>
          <hr />
          <div className="flex flex-col gap-y-5 p-4">
            <BalanceContainer
              title={t('general.invoiceLines', 'Invoice Lines')}
              tooltip={t(
                'invoices.invoice.balance.invoiceLinesTooltip',
                'These values show the calculated total of all of the Invoice Lines. These values are updated automatically if a change is made to any of the Invoice Lines'
              )}
              valueExTax={(Number(lineTotal) || 0) - (Number(lineTaxTotal) || 0)}
              tax={lineTaxTotal}
            />
            <BalanceContainer
              title={t('general.deliveryFees', 'Delivery Fees')}
              tooltip={t(
                'invoices.invoice.balance.deliveryFeesTooltip',
                'These values are used to show the Delivery Fees applicable on the Invoice, if any. These values are set when the Invoice is created and you can make changes to these values by clicking the Update Totals button above.'
              )}
              valueExTax={deliveryChargeExTax}
              tax={deliveryChargeTax}
            />
            {(!!adjustmentExTax || !!adjustmentTax) && (
              <BalanceContainer
                title={t('general.adjustmentFees', 'Adjustment Fees')}
                tooltip={t(
                  'invoices.invoice.balance.adjustmentFeesTooltip',
                  'These values are used to show the Adjustment Fees applicable on the Invoice, if any. These values are set when the Invoice is created and you can make changes to these values by clicking the Update Totals button above.'
                )}
                valueExTax={adjustmentExTax}
                tax={adjustmentTax}
              />
            )}
          </div>
          <hr />
          <div className="flex flex-col p-4">
            <BalanceContainer
              title={t('general.totals', 'Totals')}
              tooltip={t(
                'invoices.invoice.balance.totalFeesTooltip',
                'These values should be equal to the sum of all of the Invoice parts above. These values are set when the Invoice is created, and they do not recalculate automatically. You can make changes to these values by clicking the Update Totals button above.'
              )}
              valueExTax={totalExTax}
              tax={totalTaxValue}
              totals
            />
            <div className="flex items-center justify-between p-2 text-lg font-bold" data-testid="invoice-total">
              <p>{t('general.invoiceTotal', 'Invoice Total')}</p>
              {formatMoney(totalValue)}
            </div>
            {outOfBalance && (
              <div className="flex items-center justify-between rounded-md bg-error/10 px-4 py-2 text-error">
                <p className="font-bold">{t('invoices.invoice.balance.errorTitle', 'Invoice Total Out of Balance')}</p>
                <p>
                  {t(
                    'invoices.invoice.balance.errorMessage',
                    'The sum of the Invoice parts does not balance with the Invoice Total'
                  )}
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </section>
  )
}
