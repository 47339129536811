import { useMutation } from '@apollo/client'
import { Dispatch, SetStateAction, useState } from 'react'

import { Invoice, UpdateInvoiceDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

interface useBalanceModalProps {
  invoice: Invoice
  setShowModal: Dispatch<SetStateAction<boolean>>
}

export default function useBalanceModalCalculations({ invoice, setShowModal }: useBalanceModalProps) {
  const {
    lineTotal,
    lineTaxTotal,
    deliveryChargeExTax,
    deliveryChargeTax,
    adjustmentExTax,
    adjustmentTax,
    totalTaxValue: totalTax,
    totalValue: total,
  } = invoice || {}

  // The values can come in different numbers of decimal places and we want it to display 2.
  // The user should be allowed to enter a number of decimal places.
  // When calculating the total, we set the decimal places back to 2 for everything
  // totalExTax is a calculated field based off of totalValue - totalTaxValue, it is then editable, when updating the invoice we submit totalTaxValue and totalValue only

  const defaultValues = {
    deliveryChargeExTax: (deliveryChargeExTax || 0).toFixed(2),
    deliveryChargeTax: (deliveryChargeTax || 0).toFixed(2),
    adjustmentExTax: (adjustmentExTax || 0).toFixed(2),
    adjustmentTax: (adjustmentTax || 0).toFixed(2),
    totalExTax: ((total || 0) - (totalTax || 0)).toFixed(2),
    totalTax: (totalTax || 0).toFixed(2),
  }

  const [balanceModalState, setBalanceModalState] = useState(defaultValues)

  const [updateInvoice, { loading }] = useMutation(UpdateInvoiceDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const sum = (...args: string[]) => {
    const total = args.reduce((acc, curr) => Number(acc) + Number(curr), 0)
    return total.toFixed(2)
  }

  const onSetState = (inputName: string, value: string) => {
    setBalanceModalState((prevState) => ({ ...prevState, [inputName]: value }))
  }

  const lineExTax = Number(lineTotal || '0') - Number(lineTaxTotal || '0')

  // Sums of row, the 4 uneditable fields in the total column
  const deliveryChargeTotal = sum(balanceModalState.deliveryChargeExTax, balanceModalState.deliveryChargeTax)
  const adjustmentTotal = sum(balanceModalState.adjustmentExTax, balanceModalState.adjustmentTax)
  const totalTotal = sum(balanceModalState.totalExTax, balanceModalState.totalTax)

  // Sums of columns, not displayed
  const totalsExTax = sum(String(lineExTax), balanceModalState.deliveryChargeExTax, balanceModalState.adjustmentExTax)
  const totalsTax = sum(lineTaxTotal || '0', balanceModalState.deliveryChargeTax, balanceModalState.adjustmentTax)
  const totalsTotal = sum(lineTotal || '0', deliveryChargeTotal, adjustmentTotal)

  // Variance totals, the differences between the sum of the columns in the top table and the values in the totals table, display in the bottom variance table
  const totalExTaxVariance = (Number(balanceModalState.totalExTax) - Number(totalsExTax)).toFixed(2)
  const totalTaxVariance = (Number(balanceModalState.totalTax) - Number(totalsTax)).toFixed(2)
  const totalVariance = (Number(totalTotal) - Number(totalsTotal)).toFixed(2)

  // Is there any variance, variance is considered being greater than 0.1 difference either way
  const totalHasVariance = Number(totalVariance) > 0.1 || Number(totalVariance) < -0.1

  // Calculating the total inputs, also formatting all inputs to have 2 decimal places again
  const onCalculateTotals = () => {
    setBalanceModalState({
      deliveryChargeExTax: Number(balanceModalState.deliveryChargeExTax).toFixed(2),
      deliveryChargeTax: Number(balanceModalState.deliveryChargeTax).toFixed(2),
      adjustmentExTax: Number(balanceModalState.adjustmentExTax).toFixed(2),
      adjustmentTax: Number(balanceModalState.adjustmentTax).toFixed(2),
      totalExTax: totalsExTax,
      totalTax: totalsTax,
    })
  }

  const onSubmitBalance = () => {
    updateInvoice({
      variables: {
        input: {
          id: invoice.id,
          deliveryChargeExTax: Number(balanceModalState.deliveryChargeExTax),
          deliveryChargeTax: Number(balanceModalState.deliveryChargeTax),
          adjustmentExTax: Number(balanceModalState.adjustmentExTax),
          adjustmentTax: Number(balanceModalState.adjustmentTax),
          totalTaxValue: Number(balanceModalState.totalTax),
          totalValue: Number(totalTotal),
        },
      },
      onCompleted: () => {
        onCloseModal(true)
      },
    })
  }

  const onCloseModal = (skipSetDefault?: boolean) => {
    !skipSetDefault && setBalanceModalState(defaultValues)
    setShowModal(false)
  }

  return {
    balanceModalState,
    onSetState,
    onSubmitBalance,
    onCloseModal,
    onCalculateTotals,
    totalExTaxVariance,
    totalTaxVariance,
    totalVariance,
    totalHasVariance,
    lineExTax,
    deliveryChargeTotal,
    adjustmentTotal,
    totalTotal,
    loading,
  }
}
