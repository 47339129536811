import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import UserStatus from '../UserStatus'

import { User } from '@/graphql/access/generated/access_graphql'
import { PageInfo } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback, MoreOptionsMenu, PPNewDetailLink } from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { OptionIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TableViewProps {
  users: User[]
  paginationLoading: boolean
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'endCursor'>
  onNextPage: () => void
  onPreviousPage: () => void
}

function TableView({
  users,
  pageInfo: { hasNextPage, hasPreviousPage, endCursor },
  onNextPage,
  onPreviousPage,
  paginationLoading,
}: TableViewProps) {
  const { t } = useTranslation()

  const renderUser = ({ fullName, id }: User) => {
    return (
      <PPNewDetailLink type="user" id={String(id)}>
        {fullName}
      </PPNewDetailLink>
    )
  }
  const renderEmailAddress = ({ email }: User) => {
    return email
  }
  const renderPhoneNumber = ({ telephone }: User) => {
    return (
      <Fallback as="p" className="break-words" condition={!!telephone}>
        {telephone}
      </Fallback>
    )
  }
  const renderLastSignIn = ({ lastSignInAt }: User) => {
    return (
      <>
        <p className="break-words">{formatDate(Dates.Short, lastSignInAt)}</p>
        <p className="break-words text-xs">{formatDate(Dates.Time, lastSignInAt)}</p>
      </>
    )
  }
  const renderStatus = ({ isDeleted }: User) => {
    return <UserStatus isDeleted={Boolean(isDeleted)} />
  }
  const renderMoreOptions = ({ id }: User) => {
    return (
      <MoreOptionsMenu showOpenInPPlus showHelp accessUserId={id}>
        <OptionIcon className="h-10 w-10 text-gray-500" />
      </MoreOptionsMenu>
    )
  }

  return (
    <Table
      dataTestId="all-users-table-view"
      dataSource={users}
      keyExtractor={(record) => String(record.id)}
      pagination={{
        hasNextPage,
        hasPreviousPage,
        onNextPage: onNextPage,
        onPreviousPage: onPreviousPage,
        loading: paginationLoading,
        endCursor: endCursor,
      }}
      columns={[
        {
          title: t('general.user', 'User'),
          key: 'user',
          headerCellStyles: 'px-4 py-3',
          cellStyles: 'px-4 py-3',
          minWidth: 11.875,
          maxWidth: 13.75,
          render: renderUser,
        },
        {
          title: t('general.emailAddress', 'Email Address'),
          key: 'emailAddress',
          headerCellStyles: 'py-3',
          cellStyles: ' py-3',
          minWidth: 12.5,
          maxWidth: 20,
          render: renderEmailAddress,
        },
        {
          title: t('general.phoneNumber', 'Phone Number'),
          key: 'phoneNumber',
          headerCellStyles: 'px-4 py-3',
          cellStyles: ' px-4 py-3',
          minWidth: 12.5,
          grow: true,
          render: renderPhoneNumber,
        },
        {
          title: t('general.lastSignIn', 'Last Sign In'),
          key: 'lastSignIn',
          headerCellStyles: 'py-3 text-center',
          cellStyles: ' py-3 text-center',
          minWidth: 8.125,
          maxWidth: 8.125,
          render: renderLastSignIn,
        },
        {
          title: t('general.status', 'Status'),
          key: 'status',
          headerCellStyles: 'py-3 text-center',
          cellStyles: ' py-3 text-center',
          minWidth: 7.5,
          maxWidth: 7.5,
          render: renderStatus,
        },
        {
          title: t('general.more', 'More'),
          key: 'more',
          headerCellStyles: 'py-3 text-center',
          cellStyles: ' py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          render: renderMoreOptions,
        },
      ]}
    />
  )
}

export default memo(TableView)
