import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Delivery } from '@/graphql/purchasing/generated/purchasing_graphql'
import { DeliveryState } from '@/modules/purchase-orders/types'
import { Fallback, MoreOptionsMenu } from '@/modules/shared/components'
import { OptionIcon } from '@/modules/shared/icons'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

interface TableViewProps {
  sendEvents: Delivery[]
}

export default function TableView({ sendEvents }: TableViewProps) {
  const { t } = useTranslation()
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>()

  const parseState = (state: DeliveryState) => {
    const stateObj: Record<DeliveryState, string> = {
      [DeliveryState.Failed]: t('general.failed', 'Failed'),
      [DeliveryState.Sent]: t('general.sent', 'Sent'),
      [DeliveryState.Resent]: t('general.resent', 'Resent'),
      [DeliveryState.QueuedForSending]: t(
        'purchaseOrders.purchaseOrder.sendEvents.queuedForSending',
        'Queued for sending'
      ),
    }
    return stateObj[state]
  }

  if (sendEvents.length === 0) return null

  return (
    <div className="overflow-x-auto" data-testid="send-events-table">
      <table className="w-full text-left text-sm">
        <thead className="whitespace-nowrap border bg-white text-xs text-gray-500">
          <tr>
            <th className="px-4 py-3 text-left">{t('general.sendDate', 'Send Date')}</th>
            <th className="px-4 py-3 text-left">{t('general.sendTo', 'Send To')}</th>
            <th className="px-4 py-3 text-center">{t('general.status', 'Status')}</th>
            <th className="px-4 py-3 text-center">{t('general.sendBy', 'Send By')}</th>
            <th className="py-3 text-center">{t('general.more', 'More')}</th>
          </tr>
        </thead>
        <tbody className="border">
          {sendEvents.map((sendEvent) => {
            const { createdAt, endpoint, state, sender, id } = sendEvent
            return (
              <tr key={id} className="border-b bg-white">
                <td className="w-16 whitespace-nowrap px-4 py-3">
                  <p>{formatDate(Dates.Short, createdAt)}</p>
                  <p className="text-xs">{formatDate(Dates.Time, createdAt)}</p>
                </td>
                <td className="min-w-[24.125rem] px-4 py-3 md:w-96">
                  <Fallback
                    as="span"
                    className={classNames('break-all', {
                      'text-error': state === DeliveryState.Failed,
                    })}
                    condition={!!endpoint}
                  >
                    {endpoint}
                  </Fallback>
                </td>
                <td
                  className={classNames('w-16 whitespace-nowrap py-3 text-center', {
                    'text-error': state === DeliveryState.Failed,
                  })}
                >
                  {parseState(state as DeliveryState)}
                </td>
                <td className="w-32 whitespace-nowrap py-3 text-center">{sender?.fullName}</td>
                <td className="w-2 whitespace-nowrap py-3 text-center">
                  <MoreOptionsMenu
                    sendEventId={id}
                    isResendable={state === DeliveryState.Sent}
                    purchaseOrderId={purchaseOrderId}
                    showOpenInPPlus
                    showHelp
                  >
                    <OptionIcon className="h-10 w-10 text-gray-500" />
                  </MoreOptionsMenu>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
