import { useLazyQuery, useQuery } from '@apollo/client'
import { SetStateAction } from 'jotai'
import { Dispatch, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Category,
  GetCategoriesDocument,
  GetSubCategoriesDocument,
  RansackDirection,
  SupplierRelationship,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { Button, ComboboxServer, Tooltip } from '@/modules/shared/components'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { QuestionMarkIcon, TrashIcon } from '@/modules/shared/icons'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'
import { SupplierFormInputs } from '@/modules/suppliers/types'

interface CatAndSubCatProps {
  relationship: SupplierRelationship
  setRemoveDefaultCategory: Dispatch<SetStateAction<boolean>>
  removeDefaultCategory: boolean
}

export default function CatAndSubCat(props: CatAndSubCatProps) {
  const { relationship, setRemoveDefaultCategory, removeDefaultCategory } = props
  const { t } = useTranslation()
  const [categoryId, setCategoryId] = useState<number | null>(null)

  const { resetField, control } = useFormContext<SupplierFormInputs>()

  const {
    data: categoriesData,
    networkStatus: categoriesNetworkStatus,
    refetch: refetchingCategories,
    fetchMore: fetchMoreCategories,
  } = useQuery(GetCategoriesDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    variables: {
      sort: [
        {
          property: 'name',
          direction: RansackDirection.Asc,
        },
      ],
      first: 10,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [
    fechSubCategories,
    {
      data: subCategoriesData,
      networkStatus: subCategoriesNetworkStatus,
      refetch: refetchingSubCategories,
      fetchMore: fetchMoreSubCategories,
    },
  ] = useLazyQuery(GetSubCategoriesDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    variables: {
      sort: [
        {
          property: 'name',
          direction: RansackDirection.Asc,
        },
      ],
      first: 10,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const categories = extractEdges<Category>(categoriesData?.categories)
  const subCategories = extractEdges<Category>((subCategoriesData?.categories?.edges || [])[0]?.node?.categories)

  const hasMoreCategories = categoriesData?.categories?.pageInfo.hasNextPage
  const hasMoreSubCategories = (subCategoriesData?.categories?.edges || [])[0]?.node?.categories?.pageInfo.hasNextPage

  const onCategorySelect = (id: number) => {
    setCategoryId(id)
    resetField('defaultCategoryId')
    fechSubCategories({
      variables: {
        parentFilter: { q: [{ property: 'id_eq', value: id }] },
      },
    })
  }

  const onFetchMoreCategories = () => {
    fetchMoreCategories({ variables: { after: categoriesData?.categories?.pageInfo.endCursor } })
  }

  const onFetchMoreSubCategories = () => {
    fetchMoreSubCategories({
      variables: {
        after: (subCategoriesData?.categories?.edges || [])[0]?.node?.categories?.pageInfo.endCursor,
      },
    })
  }

  return (
    <section>
      <h2 className="font-semibold">
        {t('mySuppliers.supplier.defaultCatAndSubCat', 'Default Category and Sub-Category')}
      </h2>
      <p className="text-gray-500">
        {t(
          'mySuppliers.supplier.defaultCatAndSubCatDescription',
          'Assigning a default Category and Sub-Category to the Supplier is optional but is used as a quick way to assign a category and sub-category to any self-managed products that are created for this Supplier. This field is also used in some reports.'
        )}
      </p>
      <div className="mt-4 space-y-3">
        {removeDefaultCategory ||
          (relationship.defaultCategory && (
            <section
              className="flex w-full items-center justify-between rounded-md border border-gray-300 bg-gray-200 p-3"
              data-testid="default-category"
            >
              {`${relationship.defaultCategory?.parent?.name} > ${relationship.defaultCategory?.name}`}
              <div className="flex items-center gap-x-1 text-gray-500">
                <span>{t('general.currentlySet', 'Currently Set')}</span>
                <Tooltip
                  content={t(
                    'mySuppliers.supplier.defaultCategoryCurrentlySetTooltip',
                    'The Default Category and Sub-Category currently set for this Supplier.'
                  )}
                >
                  <QuestionMarkIcon className="h-4 w-4" />
                </Tooltip>
                <Tooltip
                  content={t(
                    'mySuppliers.supplier.removeDefaultCategoryCurrentlySetTooltip',
                    'Remove the currently set Default Category and Sub-Category'
                  )}
                >
                  <Button
                    type="button"
                    data-testid="remove-default-category"
                    className="flex items-center justify-center"
                    onClick={() => setRemoveDefaultCategory(true)}
                  >
                    <TrashIcon className="h-7 w-7" />
                  </Button>
                </Tooltip>
              </div>
            </section>
          ))}
        <ComboboxServer
          testId="categories"
          placeholder={t('general.selectACategory', 'Select a Category')}
          onDisplay={(e) => `${e.name}`}
          onInputChange={(e) => refetchingCategories({ filter: { q: [{ property: 'name_cont', value: e }] } })}
          networkStatus={categoriesNetworkStatus}
          keyExtractor={(e) => String(e.id)}
          items={categories}
          onSelected={(e) => onCategorySelect(e.id)}
          onFetchMore={onFetchMoreCategories}
          hasMore={hasMoreCategories}
        />
        <Controller
          control={control}
          name="defaultCategoryId"
          render={({ field }) => (
            <ComboboxServer
              testId="sub-categories"
              placeholder={t('general.selectASubCategory', 'Select a Sub-Category')}
              onDisplay={(e) => `${e.name}`}
              onInputChange={(e) =>
                categoryId && refetchingSubCategories({ filter: { q: [{ property: 'name_cont', value: e }] } })
              }
              networkStatus={subCategoriesNetworkStatus}
              keyExtractor={(e) => String(e.id)}
              items={subCategories}
              loading={checkNetworkStatus(subCategoriesNetworkStatus).setVariablesLoading}
              resetSelectItem={categoryId}
              onSelected={(e) => field.onChange(e.id)}
              onFetchMore={onFetchMoreSubCategories}
              hasMore={hasMoreSubCategories}
              disabled={!categoryId}
            />
          )}
        />
      </div>
    </section>
  )
}
