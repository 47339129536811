import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ConfirmDialog from '../../confirm-dialog'
import MoreOptionsMenu from '../../more-options/MoreOptionsMenu'

import { useDeleteInvoiceLineItem } from '@/modules/invoices/hooks'
import { OptionIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

export interface JournalLinkedPOProps {
  refetch: () => void
  id: number
}

export default function JournalLinkedPO({ refetch, id }: JournalLinkedPOProps) {
  const { t } = useTranslation()
  const [showDeleteInvoiceLine, setShowDeleteInvoiceLine] = useState(false)

  const [deleteInvoiceLineItem, { loading: deleteInvoiceLineItemLoading }] = useDeleteInvoiceLineItem()

  const onDeleteInvoiceLineItem = (id: number) => {
    deleteInvoiceLineItem({ variables: { input: { id } }, onCompleted: () => refetch() })
  }

  const onShowDeleteConfirm = (id: number) => {
    setShowDeleteInvoiceLine(true)
  }

  return (
    <>
      <MoreOptionsMenu buttonTheme="dark" showDeleteInvoiceLineItem={() => onShowDeleteConfirm(id)} showRight>
        <OptionIcon className="h-8 w-8" />
      </MoreOptionsMenu>
      <ConfirmDialog
        title={t('invoices.invoice.deleteInvoiceLineTitle', 'Delete Invoice Line?')}
        description={t(
          'invoices.invoice.deleteInvoiceLineDescription',
          'Are you sure you want to delete this Invoice Line? You can’t undo this action but you can add the Invoice Line back into the Invoice again if needed.'
        )}
        theme={Themes.Error}
        isOpen={showDeleteInvoiceLine}
        setIsOpen={setShowDeleteInvoiceLine}
        primaryButtonLabel={t('general.delete', 'Delete')}
        onPrimaryBtnClick={() => onDeleteInvoiceLineItem(id)}
        secondaryButtonLabel={t('general.cancel', 'Cancel')}
        btnPrimaryLoading={deleteInvoiceLineItemLoading}
      />
    </>
  )
}
