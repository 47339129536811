import { graphql } from 'msw'

import { OperationalApproveInvoiceMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseOperationalApproveInvoice: OperationalApproveInvoiceMutation = {
  operationalApproveInvoice: {
    invoice: {
      id: 7524140,
      state: 'flagged',
      isFinanciallyApproved: false,
      isOperationallyApproved: true,
      __typename: 'Invoice',
    },
    __typename: 'OperationalApproveInvoiceMutationPayload',
  },
}

export const OperationalApproveInvoice = graphql.mutation<OperationalApproveInvoiceMutation>(
  'OperationalApproveInvoice',
  (_, res, ctx) => {
    return res(ctx.data(baseOperationalApproveInvoice))
  }
)

export default baseOperationalApproveInvoice
