import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatSupplierBillingAddress } from '@/modules/requisitions/utils'
import { formatSupplierPostalAddress } from '@/modules/requisitions/utils/formatSupplierPostalAddress'
import { QueryResult } from '@/modules/shared/components'
import { TableRowDouble, TableRowSingle } from '@/modules/shared/components/page-header-details-table'
import { useGetSupplierRelationShopDetails } from '@/modules/suppliers/hooks'

export default function Details() {
  const { supplierId } = useParams<{ supplierId: string }>()
  const { t } = useTranslation()
  const { supplierRelationship, loading, error } = useGetSupplierRelationShopDetails(String(supplierId))

  return (
    <QueryResult loading={loading} error={error} size="md">
      <section className="flex items-center justify-between rounded-md  bg-white p-4 text-sm shadow">
        <div className="w-full divide-y divide-gray-200 overflow-auto rounded-md border border-gray-300">
          <TableRowSingle
            titleText={t('general.supplierName', 'Supplier Name')}
            tooltipText={t('mySuppliers.supplier.details.supplierNameTooltip', 'The name of the Supplier.')}
            value={supplierRelationship.supplier?.name}
            testId="supplierName"
          />
          <TableRowSingle
            titleText={t('general.supplierNumber', 'Supplier Number')}
            tooltipText={t(
              'mySuppliers.supplier.details.supplierNumberTooltip',
              'The Number that your Organisation has assigned to the Supplier.'
            )}
            value={supplierRelationship.supplierNumber}
            testId="supplierNumber"
          />
          <TableRowSingle
            titleText={t('general.businessNumber', 'Business Number')}
            tooltipText={t(
              'mySuppliers.supplier.details.businessNumberTooltip',
              'The legal Business Number of the Supplier.'
            )}
            value={supplierRelationship.supplier?.businessNumber}
            testId="businessNumber"
          />
          <TableRowSingle
            titleText={t('general.relationshipStatus', 'Relationship Status')}
            tooltipText={t(
              'mySuppliers.supplier.details.relationshipStatusTooltip',
              'Indicates if both your Organisation and the Supplier have enabled trading with each other.'
            )}
            value={
              <span
                className={classNames('rounded-full px-3 py-1 text-xs text-white', {
                  'bg-success': supplierRelationship.isEnabled,
                  'bg-error': !supplierRelationship.isEnabled,
                })}
              >
                {supplierRelationship.isEnabled ? t('general.enabled', 'Enabled') : t('general.disabled', 'Disabled')}
              </span>
            }
            testId="relationshipStatus"
          />
          <TableRowSingle
            titleText={t('general.enabledByPurchaser', 'Enabled by Purchaser')}
            tooltipText={t(
              'mySuppliers.supplier.details.enabledByPurchaserTooltip',
              'Indicates if your Organisation has enabled trading with the Supplier.'
            )}
            value={
              <span
                className={classNames('rounded-full px-3 py-1 text-xs text-white', {
                  'bg-success': supplierRelationship.isEnabled,
                  'bg-error': !supplierRelationship.isEnabled,
                })}
              >
                {supplierRelationship.isEnabled ? t('general.yes', 'Yes') : t('general.no', 'No')}
              </span>
            }
            testId="enabledByPurchaser"
          />
          <TableRowSingle
            titleText={t('general.enabledBySupplier', 'Enabled by Supplier')}
            tooltipText={t(
              'mySuppliers.supplier.details.enabledBySupplierTooltip',
              'Indicates if the Supplier has enabled trading with your Organisation.'
            )}
            value={
              <span
                className={classNames('rounded-full px-3 py-1 text-xs text-white', {
                  'bg-success': supplierRelationship.isEnabledBySupplier,
                  'bg-error': !supplierRelationship.isEnabledBySupplier,
                })}
              >
                {supplierRelationship.isEnabledBySupplier ? t('general.yes', 'Yes') : t('general.no', 'No')}
              </span>
            }
            testId="enabledBySupplier"
          />
          <TableRowSingle
            titleText={t('general.selfManaged', 'Self Managed')}
            tooltipText={t(
              'mySuppliers.supplier.details.selfManagedTooltip',
              'Indicates if the Supplier manages their own profile on PurchasePlus, or if the Supplier is managed by your Organisation.'
            )}
            value={supplierRelationship.supplier?.isVerified ? t('general.no', 'No') : t('general.yes', 'Yes')}
            testId="selfManaged"
          />
          <TableRowSingle
            titleText={t('general.preferredSupplier', 'Preferred Supplier')}
            tooltipText={t(
              'mySuppliers.supplier.details.preferredSupplierTooltip',
              'As one or more Invoices are matched to the Purchase Order, the Invoices are listed here.'
            )}
            value={supplierRelationship.preferredSupplier ? t('general.yes', 'Yes') : t('general.no', 'No')}
            testId="preferredSupplier"
          />
          <TableRowDouble
            leftTitle={t('general.defaultDepartment', 'Default Department')}
            leftTooltip={t(
              'mySuppliers.supplier.details.defaultDepartmentTooltip',
              'The Department that this Purchase Order expense will be recorded The default Department that is assigned to this Supplier by your Organisation, if any. When a Single Supplier Requisition is created for this Supplier, the Requisition will be set to this Department.'
            )}
            leftTestId="defaultDepartment"
            leftValue={supplierRelationship.department?.name}
            rightTitle={t('general.defaultAccountCode', 'Default Account Code')}
            rightTooltip={t(
              'mySuppliers.supplier.details.defaultAccountCodeTooltip',
              'The default Account Code that is assigned to this Supplier by your Organisation, if any. When a Single Supplier Requisition is created for this Supplier, the Requisition will be set to this Account Code.'
            )}
            rightTestId="defaultAccountCode"
            rightValue={supplierRelationship.account?.accountName}
          />
          <TableRowSingle
            titleText={t('general.defaultCategory', 'Default Category')}
            tooltipText={t(
              'mySuppliers.supplier.details.defaultCategoryTooltip',
              'The default Category and Sub-Category that is assigned to this Supplier by your Organisation, if any. Products created on behalf of this Supplier will be assigned this Category.'
            )}
            value={
              supplierRelationship.defaultCategory &&
              `${supplierRelationship.defaultCategory?.parent?.name} > ${supplierRelationship.defaultCategory?.name}`
            }
            testId="defaultCategory"
          />
          <TableRowSingle
            titleText={t('general.supplierPhone', 'Supplier Phone')}
            tooltipText={t(
              'mySuppliers.supplier.details.supplierPhoneTooltip',
              'The contact phone number of the Supplier.'
            )}
            value={supplierRelationship.supplier?.telephone}
            testId="supplierPhone"
          />
          <TableRowSingle
            titleText={t('general.supplierEmail', 'Supplier Email')}
            tooltipText={t(
              'mySuppliers.supplier.details.supplierEmailTooltip',
              'The contact email address of the Supplier.'
            )}
            value={supplierRelationship.supplier?.email}
            testId="supplierEmail"
          />
          <TableRowSingle
            titleText={t('general.locationCode', 'Location Code')}
            tooltipText={t('mySuppliers.supplier.details.locationCodeTooltip', 'The location code of the Supplier.')}
            value={supplierRelationship.supplierLocationCode}
            testId="locationCode"
          />
          <TableRowSingle
            titleText={t('general.billingAddress', 'Billing Address')}
            tooltipText={t(
              'mySuppliers.supplier.details.billingAddressTooltip',
              'The Billing Address of the Supplier.'
            )}
            value={formatSupplierBillingAddress(supplierRelationship.supplier as Supplier)}
            testId="billingAddress"
          />
          <TableRowSingle
            titleText={t('general.postalAddress', 'Postal Address')}
            tooltipText={t('mySuppliers.supplier.details.postalAddressTooltip', 'The Postal Address of the Supplier.')}
            value={formatSupplierPostalAddress(supplierRelationship.supplier as Supplier)}
            testId="postalAddress"
          />
          <TableRowSingle
            titleText={t('general.invoicingEmail', 'Invoicing Email')}
            tooltipText={t(
              'mySuppliers.supplier.details.invoicingEmailTooltip',
              'The Paperless Invoicing Email Address assigned to this Supplier. If the Supplier sends you an Invoice to this Email Address the, Invoice will be automatically added to your Invoices.'
            )}
            value={supplierRelationship.paperlessInvoicingEmailAddress}
            testId="invoicingEmail"
          />
        </div>
      </section>
    </QueryResult>
  )
}
