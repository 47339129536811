import { graphql } from 'msw'

import { GetSubCategoriesQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetSubCatagories: GetSubCategoriesQuery = {
  categories: {
    edges: [
      {
        node: {
          id: 109453,
          name: 'Anaesthesia Respiratory and Resuscitation',
          categories: {
            edges: [
              {
                node: {
                  id: 100001,
                  name: 'Anaesthesia Respiratory Sub 1',
                  __typename: 'Category',
                },
                __typename: 'CategoryEdge',
              },
            ],
            pageInfo: {
              hasNextPage: false,
              endCursor: null,
              __typename: 'PageInfo',
            },
            __typename: 'CategoryConnection',
          },
          __typename: 'Category',
        },
        __typename: 'CategoryEdge',
      },
      {
        node: {
          id: 109470,
          name: 'Automotive',
          categories: {
            edges: [
              {
                node: {
                  id: 100002,
                  name: 'Automotive Sub 1',
                  __typename: 'Category',
                },
                __typename: 'CategoryEdge',
              },
            ],
            pageInfo: {
              hasNextPage: false,
              endCursor: null,
              __typename: 'PageInfo',
            },
            __typename: 'CategoryConnection',
          },
          __typename: 'Category',
        },
        __typename: 'CategoryEdge',
      },
      {
        node: {
          id: 109445,
          name: 'Bakery',
          categories: {
            edges: [
              {
                node: {
                  id: 100001,
                  name: 'Bakery Sub 1',
                  __typename: 'Category',
                },
                __typename: 'CategoryEdge',
              },
            ],
            pageInfo: {
              hasNextPage: false,
              endCursor: null,
              __typename: 'PageInfo',
            },
            __typename: 'CategoryConnection',
          },
          __typename: 'Category',
        },
        __typename: 'CategoryEdge',
      },
      {
        node: {
          id: 109446,
          name: 'Beer',
          categories: {
            edges: [
              {
                node: {
                  id: 100001,
                  name: 'Beer Sub 1',
                  __typename: 'Category',
                },
                __typename: 'CategoryEdge',
              },
            ],
            pageInfo: {
              hasNextPage: false,
              endCursor: null,
              __typename: 'PageInfo',
            },
            __typename: 'CategoryConnection',
          },
          __typename: 'Category',
        },
        __typename: 'CategoryEdge',
      },
    ],
    __typename: 'CategoryConnection',
  },
}

export const GetSubCategories = graphql.query<GetSubCategoriesQuery>('GetSubCategories', (_, res, ctx) => {
  return res(ctx.data(baseGetSubCatagories))
})

export default baseGetSubCatagories
