import { useFloating } from '@floating-ui/react-dom'
import { Popover, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { NewTabIcon } from '@/modules/shared/icons'
import { Portal } from '@/modules/shared/layouts'

interface SupplierOptionsProps extends PropsWithChildren {
  id: number
}

export default function SupplierOptions(props: SupplierOptionsProps) {
  const { t } = useTranslation()
  const { children, id } = props
  const {
    x,
    y,
    strategy,
    refs: { setReference, setFloating },
  } = useFloating({ placement: 'bottom-end' })

  const enterpriseSupplierUrl = `${process.env.REACT_APP_NINJA_API_HOST}/purchasing/en/supplier_relationships/${id}`

  return (
    <Popover className="relative flex items-center justify-center">
      <Popover.Button
        data-testid="supplier-options-button"
        className="items-center justify-between outline-none hover:opacity-75"
        ref={setReference}
      >
        {children}
      </Popover.Button>
      <Portal>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel
            data-testid="supplier-options-menu"
            className="z-10 rounded-md border bg-white shadow-lg"
            ref={setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <div className="py-1">
              <a
                className={classNames(
                  'w-full',
                  'px-4',
                  'py-2',
                  'text-left',
                  'text-sm',
                  'flex',
                  'items-center',
                  'text-gray-700',
                  'transition',
                  'hover:bg-gray-100 ',
                  'hover:text-gray-900'
                )}
                data-testid="view-in-enterprise"
                href={enterpriseSupplierUrl}
                target="_blank"
                rel="noreferrer"
              >
                <NewTabIcon className="mr-2 h-5 w-5" />
                {t('requisition.openInPPlusSignEnterprise', 'Open in P+ Enterprise')}
              </a>
            </div>
          </Popover.Panel>
        </Transition>
      </Portal>
    </Popover>
  )
}
