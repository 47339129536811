import { graphql } from 'msw'

import { GetInvoiceCommentsQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetInvoiceComments: GetInvoiceCommentsQuery = {
  currentPurchaser: {
    id: 44926,
    invoice: {
      id: 14798658,
      invoiceNumber: 'INGR02385',
      commentsCount: 3,
      privateComments: {
        edges: [
          {
            node: {
              id: 1985525,
              body: 'Private Comment',
              createdAt: '2023-02-02T16:45:33+11:00',
              creator: {
                id: 23783,
                fullName: 'Nathan Del Medico',
                __typename: 'User',
              },
              __typename: 'Comment',
            },
            __typename: 'CommentEdge',
          },
          {
            node: {
              id: 1985408,
              body: 'Decline Reason - test\n',
              createdAt: '2022-10-06T09:22:57+11:00',
              creator: {
                id: 18544,
                fullName: 'Sunny H Sun',
                __typename: 'User',
              },
              __typename: 'Comment',
            },
            __typename: 'CommentEdge',
          },
        ],
        __typename: 'CommentConnection',
      },
      publicComments: {
        edges: [
          {
            node: {
              id: 1985527,
              body: 'Public Comment',
              createdAt: '2023-02-02T16:47:34+11:00',
              creator: {
                id: 23790,
                fullName: 'Nathan Del Medico',
                __typename: 'User',
              },
              __typename: 'Comment',
            },
            __typename: 'CommentEdge',
          },
        ],
        __typename: 'CommentConnection',
      },
      __typename: 'Invoice',
    },
    __typename: 'Purchaser',
  },
}

export const GetInvoiceComments = graphql.query<GetInvoiceCommentsQuery>('GetInvoiceComments', (req, res, ctx) => {
  return res(ctx.data(baseGetInvoiceComments))
})

export default baseGetInvoiceComments
