import { graphql } from 'msw'

import { GetBuyListDetailsQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetBuyListDetails: GetBuyListDetailsQuery = {
  currentPurchaser: {
    id: 44926,
    catalogue: {
      id: 182690,
      title: 'Test Buy List Details',
      createdAt: '2023-04-20T10:00:04+10:00',
      owner: {
        id: 44926,
        name: 'Test Account',
        __typename: 'Purchaser',
      },
      updatedAt: '2023-04-20T10:00:04+10:00',
      user: null,
      chargingDefinitions: {
        edges: [
          {
            node: {
              id: 23714,
              department: {
                id: 207796,
                name: 'Basement',
                __typename: 'Purchaser',
              },
              account: {
                id: 96432,
                accountName: '0060200 - Petty Cash',
                __typename: 'Account',
              },
              __typename: 'CatalogueChargingDefinition',
            },
            __typename: 'CatalogueChargingDefinitionEdge',
          },
        ],
        __typename: 'CatalogueChargingDefinitionConnection',
      },

      __typename: 'Catalogue',
    },
    __typename: 'Purchaser',
  },
}

export const GetBuyListDetails = graphql.query<GetBuyListDetailsQuery>('GetBuyListDetails', (_, res, ctx) => {
  return res(ctx.data(baseGetBuyListDetails))
})

export default baseGetBuyListDetails
