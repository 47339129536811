import { graphql } from 'msw'

import { GetAllReceivingNotesQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetAllReceivingNotes: GetAllReceivingNotesQuery = {
  currentPurchaser: {
    id: 44926,
    receivingDocuments: {
      edges: [
        {
          node: {
            id: 6660098,
            receivingDocumentNumber: 'GR02672',
            receivedDate: '2023-03-14T12:53:11+11:00',
            workflowState: 'received',
            destinationId: 2162,
            receiver: {
              id: 18544,
              fullName: 'Sunny H Sun',
              __typename: 'User',
            },
            destination: {
              id: '2162',
              name: 'Cafe101',
              __typename: 'StockLocation',
            },
            transfer: {
              id: '1135814',
              transferNumber: 'TX01157',
              __typename: 'ReceivingTransfer',
            },
            receivingTotalValue: 52,
            purchaseOrder: {
              id: 16567229,
              purchaseOrderNumber: 'PO04838',
              supplier: {
                id: 121239,
                name: '100 Proof',
                __typename: 'Supplier',
              },
              __typename: 'PurchaseOrder',
            },
            __typename: 'ReceivingDocument',
          },
          __typename: 'ReceivingDocumentEdge',
        },
        {
          node: {
            id: 6660096,
            receivingDocumentNumber: 'GR02670',
            receivedDate: '2023-03-14T12:50:52+11:00',
            workflowState: 'received',
            destinationId: 2162,
            receiver: {
              id: 18544,
              fullName: 'Sunny H Sun',
              __typename: 'User',
            },
            destination: {
              id: '2162',
              name: 'Cafe101',
              __typename: 'StockLocation',
            },
            transfer: {
              id: '1135812',
              transferNumber: 'TX01155',
              __typename: 'ReceivingTransfer',
            },
            receivingTotalValue: 0.9,
            purchaseOrder: {
              id: 16567194,
              purchaseOrderNumber: 'PO04803',
              supplier: {
                id: 5426,
                name: 'Simon George & Sons Pty Ltd - Cairns',
                __typename: 'Supplier',
              },
              __typename: 'PurchaseOrder',
            },
            __typename: 'ReceivingDocument',
          },
          __typename: 'ReceivingDocumentEdge',
        },
        {
          node: {
            id: 6660094,
            receivingDocumentNumber: 'GR02668',
            receivedDate: '2023-03-14T10:24:25+11:00',
            workflowState: 'received',
            destinationId: null,
            receiver: {
              id: 18544,
              fullName: 'Sunny H Sun',
              __typename: 'User',
            },
            destination: null,
            transfer: null,
            receivingTotalValue: 5.78,
            purchaseOrder: {
              id: 16567228,
              purchaseOrderNumber: 'PO04837',
              supplier: {
                id: 734,
                name: 'Bidfood Australia Ltd - National',
                __typename: 'Supplier',
              },
              __typename: 'PurchaseOrder',
            },
            __typename: 'ReceivingDocument',
          },
          __typename: 'ReceivingDocumentEdge',
        },
        {
          node: {
            id: 6660090,
            receivingDocumentNumber: 'GR02664',
            receivedDate: '2023-03-13T14:41:21+11:00',
            workflowState: 'received',
            destinationId: null,
            receiver: {
              id: 23784,
              fullName: 'Chris Dat Truong',
              __typename: 'User',
            },
            destination: null,
            transfer: null,
            receivingTotalValue: 202.05,
            purchaseOrder: {
              id: 16567105,
              purchaseOrderNumber: 'PO04718',
              supplier: {
                id: 4,
                name: 'In2Food (Operations) Pty Ltd - All States ',
                __typename: 'Supplier',
              },
              __typename: 'PurchaseOrder',
            },
            __typename: 'ReceivingDocument',
          },
          __typename: 'ReceivingDocumentEdge',
        },
        {
          node: {
            id: 6660083,
            receivingDocumentNumber: 'GR02657',
            receivedDate: '2023-03-13T13:14:40+11:00',
            workflowState: 'received',
            destinationId: null,
            receiver: {
              id: 18544,
              fullName: 'Sunny H Sun',
              __typename: 'User',
            },
            destination: null,
            transfer: null,
            receivingTotalValue: 1.05,
            purchaseOrder: {
              id: 16567225,
              purchaseOrderNumber: 'PO04834',
              supplier: {
                id: 15065,
                name: 'Sydney Wholesale Co',
                __typename: 'Supplier',
              },
              __typename: 'PurchaseOrder',
            },
            __typename: 'ReceivingDocument',
          },
          __typename: 'ReceivingDocumentEdge',
        },
      ],
      pageInfo: {
        endCursor: 'MjU',
        hasNextPage: false,
        __typename: 'PageInfo',
      },
      __typename: 'ReceivingDocumentConnection',
    },
    __typename: 'Purchaser',
  },
}

export const GetAllReceivingNotes = graphql.query<GetAllReceivingNotesQuery>('GetAllReceivingNotes', (_, res, ctx) => {
  return res(ctx.data(baseGetAllReceivingNotes))
})

export default baseGetAllReceivingNotes
