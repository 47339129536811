import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import UpdateModal from './UpdateModal'

import { DeliveryAddress, PurchaseOrder, Requisition } from '@/graphql/purchasing/generated/purchasing_graphql'
import RequisitionStatus from '@/modules/requisitions/pages/all-requisitions/RequisitionStatus'
import { EditRequisitionModalEnum, REQUISITION_STATUS } from '@/modules/requisitions/types'
import { formatAddress } from '@/modules/requisitions/utils/formatAddress'
import { Fallback, PPNewDetailLink } from '@/modules/shared/components'
import { TableRowDouble, TableRowSingle } from '@/modules/shared/components/page-header-details-table'
import { DetailsContext } from '@/modules/shared/contexts/DetailsContext'
import { Dates } from '@/modules/shared/types'
import { extractEdges, formatDate } from '@/modules/shared/utils'

interface DetailsProps {
  requisition: Requisition
  hidePurchaseOrders?: boolean
}

function Details({ requisition, hidePurchaseOrders }: DetailsProps) {
  const {
    id,
    requisitionNumber,
    originName,
    reference,
    state,
    creator,
    approvalWorkflow,
    department,
    account,
    expectedDeliveryDate,
    deliveryAddress,
    sendToSupplier,
    purchaseOrders,
  } = requisition
  const { t } = useTranslation()
  const [modalType, setModalType] = useState<string | null>(null)
  const showUpdateButton = [
    REQUISITION_STATUS.OPEN,
    REQUISITION_STATUS.AWAITING_APPROVAL,
    REQUISITION_STATUS.DECLINED,
  ].includes(state as REQUISITION_STATUS)

  return (
    <section
      className="flex items-center justify-between rounded-md  bg-white p-4 text-sm shadow"
      data-testid="requisition-details-tab"
    >
      <DetailsContext.Provider value={{ setModalType, showUpdateButton }}>
        <div className="w-full divide-y divide-gray-200 overflow-auto rounded-md border border-gray-300">
          <TableRowDouble
            leftTitle={t('general.prNumber', 'PR Number')}
            leftTooltip={t(
              'requisition.detailsTable.prNumberTooltip',
              'The unique number used to identify this Purchase Requisition.'
            )}
            leftTestId="prNumber"
            leftValue={requisitionNumber}
            rightTitle={t('general.requisitionType', 'Requisition Type')}
            rightTooltip={t(
              'requisition.detailsTable.requisitionTypeTooltip',
              'The type of Requisition chosen by the user when this Requisition was created.'
            )}
            rightTestId="requisitionType"
            rightValue={originName}
          />
          <TableRowSingle
            titleText={t('general.reference', 'Reference')}
            tooltipText={t(
              'requisition.detailsTable.referenceTooltip',
              'The reference or description given to this Purchase Requisition. This will also appear on Purchase Orders created from the Requisition and will be visible to Suppliers.'
            )}
            testId="reference"
            value={reference}
            update={EditRequisitionModalEnum.Reference}
          />
          <TableRowSingle
            titleText={t('general.status', 'Status')}
            tooltipText={t(
              'requisition.detailsTable.statusTooltip',
              'The current Status of the Purchase Requisition. Click the status to see an explanation of each status type.'
            )}
            testId="status"
            value={<RequisitionStatus state={String(state)} requisitionId={id} />}
          />
          <TableRowSingle
            titleText={t('general.requestedBy', 'Requested By')}
            tooltipText={t(
              'requisition.detailsTable.requestedByTooltip',
              'The user at your organisation who created this Purchase Requisition.'
            )}
            testId="requestedBy"
            value={creator?.fullName}
          />
          <TableRowSingle
            titleText={t('general.approvalWorkflow', 'Approval Workflow')}
            tooltipText={t(
              'requisition.detailsTable.approvalWorkflowTooltip',
              'The workflow of individual users who will be required to approve this Purchase Requisition. The workflow is determined by the department and account code set on the Requisition.'
            )}
            testId="approvalWorkflow"
            value={approvalWorkflow?.title}
          />
          <TableRowDouble
            leftTitle={t('general.department', 'Department')}
            leftTooltip={t(
              'requisition.detailsTable.departmentTooltip',
              'The Department that this Purchase Requisition expense will be recorded against.'
            )}
            leftTestId="department"
            leftValue={department?.name}
            leftUpdate={EditRequisitionModalEnum.DeptAndAcc}
            rightTitle={t('general.accountCode', 'Account Code')}
            rightTooltip={t(
              'requisition.detailsTable.accountCodeTooltip',
              'The account code that this Purchase Requisition expense will be recorded against.'
            )}
            rightTestId="department"
            rightValue={account?.accountName}
            rightUpdate={EditRequisitionModalEnum.DeptAndAcc}
          />
          <TableRowSingle
            titleText={t('general.deliveryDate', 'Delivery Date')}
            tooltipText={t(
              'requisition.detailsTable.deliveryDateTooltip',
              'The delivery date is the date that Suppliers will be expected to make delivery. It will appear to the Suppliers on the Purchase Orders created from the Requisition.'
            )}
            testId="deliveryDate"
            value={formatDate(Dates.Medium, expectedDeliveryDate)}
            update={EditRequisitionModalEnum.ExpectedDeliveryDate}
          />
          <TableRowSingle
            titleText={t('general.deliveryAddress', 'Delivery Address')}
            tooltipText={t(
              'requisition.detailsTable.deliveryAddressTooltip',
              'The delivery address is the location that Suppliers will be expected to deliver to. It will appear to each Supplier on the Purchase Orders created from the Requisition.'
            )}
            testId="deliveryAddress"
            value={formatAddress(deliveryAddress as DeliveryAddress)}
            update={EditRequisitionModalEnum.DeliveryAddress}
          />
          <TableRowSingle
            titleText={t('requisition.detailsTable.sendPoSupplier', 'Send POs to Suppliers')}
            tooltipText={t(
              'requisition.detailsTable.sendPoSupplierTooltip',
              'Once Purchase Orders are created from the Requisition and marked as Sent, this indicates if the Purchase Order will be electronically delivered to the Supplier.'
            )}
            testId="sendPoSupplier"
            value={sendToSupplier ? t('general.yes', 'Yes') : t('general.no', 'No')}
            update={EditRequisitionModalEnum.SendToSupplier}
          />
          {!hidePurchaseOrders && (
            <TableRowSingle
              titleText={t('general.purchaseOrders', 'Purchase Orders')}
              tooltipText={t(
                'requisition.detailsTable.purchaseOrdersTooltip',
                'Once the Requisition has been approved, the Purchase Orders that are created will be listed here.'
              )}
              testId="purchaseOrders"
              value={
                <Fallback condition={!!purchaseOrders?.edges?.length}>
                  {extractEdges<PurchaseOrder>(purchaseOrders).map((purchaseOrder) => (
                    <PPNewDetailLink
                      key={purchaseOrder.id}
                      openNewTab
                      type="purchase-order"
                      id={String(purchaseOrder.id)}
                    >
                      {purchaseOrder.purchaseOrderNumber}
                    </PPNewDetailLink>
                  ))}
                </Fallback>
              }
            />
          )}
        </div>
      </DetailsContext.Provider>
      {Boolean(modalType) && (
        <UpdateModal showModal={modalType} setShowModal={setModalType} requisition={requisition} />
      )}
    </section>
  )
}

export default Details
