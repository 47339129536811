import classNames from 'classnames'

interface CheckboxIconProps extends React.ComponentProps<'svg'> {
  isSelected: boolean
  isIndeterminate?: boolean
  disabled?: boolean
}

export default function CheckboxIcon(props: CheckboxIconProps) {
  const { isSelected, isIndeterminate, disabled, ...restProps } = props

  const stroke = disabled && !isSelected ? 'gray-200' : 'white'

  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      className={classNames(
        'h-8 w-8 cursor-pointer transition duration-100 ease-in-out',
        disabled ? 'fill-gray-200' : isSelected || isIndeterminate ? 'fill-success' : 'fill-white'
      )}
      {...restProps}
    >
      <circle cx="12" cy="12" r="11" stroke="lightgray" strokeWidth="0.5"></circle>
      {isIndeterminate ? (
        <path d="M7 12H17.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" />
      ) : (
        <path d="M7 13l3 3 7-7" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      )}
    </svg>
  )
}
