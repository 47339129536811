export enum InvoiceStatuses {
  Open = 'open',
  Flagged = 'flagged',
  Reconciled = 'reconciled',
  Processing = 'processing',
  Processed = 'processed',
  Cancelled = 'cancelled',
  Parked = 'parked',
}

export type BalanceFormInputs = {
  deliveryChargeExTax: number
  deliveryChargeTax: number
  adjustmentExTax: number
  adjustmentTax: number
  totalTaxValue: number
  totalValue: number
}

export enum LinkInvoiceTableTypes {
  Invoice = 'invoice',
  PurchaseOrder = 'purchaseOrder',
}

export enum InvoiceInceptionTypes {
  Manual = 'manual',
  PDF = 'pdf',
}

export type UpdateInvoiceLineFormInputs = {
  quantity: number
  taxPercentage: number
  // unit price of an invoice line, not the price of the invoice
  invoicePrice: number
}

export enum InvoiceActionTypes {
  Reopen = 'Reopen',
  Cancel = 'Cancel',
  Halt = 'Halt',
  Resend = 'Resend',
  Park = 'Park',
  Complete = 'Complete',
  Process = 'Process',
  OperationalApprove = 'OperationalApprove',
  FinancialApprove = 'FinancialApprove',
}

export type CreateInvoiceFormInputs = {
  invoiceNumber: string
  reference: string
  invoiceDate: string
  supplierOrganisationId: number
  purchaserOrganisationId: number
  departmentId: number
  accountId: number
  totalExTax?: number
  totalTaxValue: number
  totalValue: number
}

export enum InvoiceReceivedStatuses {
  FullyReceived = 'FULLY_RECEIVED',
  PartlyReceived = 'PARTLY_RECEIVED',
  UnderReceived = 'UNDER_RECEIVED',
  NotReceived = 'NOT_RECEIVED',
}
