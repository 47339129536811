import { graphql } from 'msw'

import { CreateCataloguedProductsMutation } from '@/graphql/purchasing/generated/purchasing_graphql'

export const CreateCataloguedProducts = graphql.mutation<CreateCataloguedProductsMutation>(
  'CreateCataloguedProducts',
  (_, res, ctx) => {
    return res(
      ctx.data({
        createCataloguedProducts: {
          cataloguedProducts: [
            {
              id: 22676877,
              productId: 5814401,
              product: {
                id: 5814401,
                catalogues: {
                  edges: [
                    {
                      node: {
                        id: 182708,
                        title: 'test',
                        __typename: 'Catalogue',
                      },
                      __typename: 'CatalogueEdge',
                    },
                    {
                      node: { id: 182663, title: 'regression test', __typename: 'Catalogue' },
                      __typename: 'CatalogueEdge',
                    },
                  ],
                  __typename: 'CatalogueConnection',
                },
                __typename: 'Product',
              },
              __typename: 'CataloguedProduct',
            },
            {
              id: 22676878,
              productId: 5814401,
              product: {
                id: 5814401,
                catalogues: {
                  edges: [
                    {
                      node: {
                        id: 182708,
                        title: 'test',
                        __typename: 'Catalogue',
                      },
                      __typename: 'CatalogueEdge',
                    },
                    {
                      node: { id: 182663, title: 'regression test', __typename: 'Catalogue' },
                      __typename: 'CatalogueEdge',
                    },
                  ],
                  __typename: 'CatalogueConnection',
                },
                __typename: 'Product',
              },
              __typename: 'CataloguedProduct',
            },
          ],
          __typename: 'CreateCataloguedProductsMutationPayload',
        },
      })
    )
  }
)
