import { useTranslation } from 'react-i18next'

import SideNavLink from '../SideNavLink'
import { SideBarItem } from './PurchaserNavigation'

import { Accordion } from '@/modules/shared/components'
import { InvoiceIcon, PlusIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface InvoicesSideBarItemProps extends SideBarItem {
  invoiceRoutesMatch: boolean
}

export const InvoicesSideBarItem = ({
  invoiceRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
}: InvoicesSideBarItemProps) => {
  const { t } = useTranslation()
  return (
    <Accordion
      titlePrefixIcon={<InvoiceIcon className="h-7 w-7" />}
      title={t('general.invoices', 'Invoices')}
      activeColor={invoiceRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === 2}
      handleTriggerClick={() => changeOpenAccordionKey(2)}
      buttonClassName="h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="invoice-navigation"
        sideNavLinkItems={[
          {
            key: t('general.newInvoice', 'New Invoice'),
            target: '/invoices/create',
            icon: <PlusIcon className="h-6 w-6 text-gray-500" />,
          },
          {
            key: t('general.viewAll', 'View All'),
            target: '/invoices',
          },
          {
            key: t('general.awaitingMyApproval', 'Awaiting My Approval'),
            target: '/invoices/awaiting-my-approval',
          },
          {
            key: t('general.creditNotes', 'Credit Notes'),
            target: '/invoices/credit-notes',
          },
        ]}
      />
    </Accordion>
  )
}
