import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { PurchaseOrderReceivedStatusEnum } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Tooltip } from '@/modules/shared/components'
import { StatusMapping } from '@/modules/shared/types'

interface PurchaseOrderReceivedProps {
  receivedStatus: PurchaseOrderReceivedStatusEnum
}

export default function PurchaseOrderReceivedStatus(props: PurchaseOrderReceivedProps) {
  const { t } = useTranslation()

  const status: StatusMapping<PurchaseOrderReceivedStatusEnum> = {
    [PurchaseOrderReceivedStatusEnum.FullyReceived]: {
      content: t('general.received', 'Received'),
      tooltip: t(
        'purchaseOrders.allPurchaseOrders.table.tooltips.received',
        'All products on this Purchase Order have been received.'
      ),
      customStyles: 'bg-success text-white',
    },
    [PurchaseOrderReceivedStatusEnum.PartlyReceived]: {
      content: t('general.partlyReceived', 'Partly Received'),
      tooltip: t(
        'purchaseOrders.allPurchaseOrders.table.tooltips.partlyReceived',
        'Some products on this Purchase Order have been received.'
      ),
      customStyles: 'bg-[#EEB020] text-white',
    },
    [PurchaseOrderReceivedStatusEnum.NotReceived]: {
      content: t('general.notReceived', 'Not Received'),
      tooltip: t(
        'purchaseOrders.allPurchaseOrders.table.tooltips.notReceived',
        'No products on this Purchase Order have been received.'
      ),
      customStyles: 'bg-gray-200',
    },
  }

  const { content, tooltip, customStyles } = status[props.receivedStatus]

  return (
    <Tooltip content={tooltip} showArrow={false} delay={100}>
      <span className={classNames('rounded-full px-3 py-1.5 text-xs', customStyles)}>{content}</span>
    </Tooltip>
  )
}
