import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import CreateRequisition from '@/modules/purchasing/pages/dashboard/CreateRequisition'
import { Breadcrumb } from '@/modules/shared/components'
import { HomeIcon } from '@/modules/shared/icons'

export default function Dashboard() {
  const { t } = useTranslation()
  return (
    <>
      <Helmet>
        <title>{t('general.p+Dashboard', 'PurchasePlus Dashboard')}</title>
      </Helmet>
      <div className="mx-auto max-w-[95rem] space-y-5 px-4" data-testid="dashboard">
        <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="#">{t('general.dashboard', 'Dashboard')}</Breadcrumb.Item>
        </Breadcrumb>
        <CreateRequisition
          bannerDescription={t('dashboard.supplier.manageProducts', 'View and Manage Your Products')}
          action={{
            label: t('general.MyProducts', 'My Products'),
            path: '/my-products',
          }}
        />
      </div>
    </>
  )
}
