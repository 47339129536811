import { useMutation } from '@apollo/client'
import { produce } from 'immer'

import {
  DeleteAdvancedRequisitionLineDocument,
  GetAdvancedPossibleRequisitionLinesQuery,
  PossibleRequisitionLinesEdge,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { GET_ADVANCED_POSSIBLE_REQUISITION_LINES } from '@/graphql/purchasing/operations/queries/getAdvancedPossibleRequisitionLine'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useDeleteAdvancedRequisitionLine(requisitionId: number, cataloguedProductId: number) {
  const mutation = useMutation(DeleteAdvancedRequisitionLineDocument, {
    context: { uri: PURCHASING_GRAPHQL_API },
    update(cache) {
      // Because this delete requisition line mutation is not returning the line that we just deleted.
      // so the apollo cache does not get updated with new data automatically. Instead, we have to update the cache manually
      const queryToCache = {
        query: GET_ADVANCED_POSSIBLE_REQUISITION_LINES,
        variables: {
          requisitionId: Number(requisitionId),
          first: 5,
        },
      }
      const data = cache.readQuery(queryToCache) as GetAdvancedPossibleRequisitionLinesQuery
      const newData = produce(data, (draft) => {
        let { edges } = draft.currentPurchaser?.requisition?.possibleRequisitionLines || {}
        draft.currentPurchaser!.requisition!.possibleRequisitionLines!.edges = edges?.map((e) => {
          if (e?.node?.cataloguedProductId === cataloguedProductId) {
            // These 6 properties need to be reset
            e.node.id = null
            e.node.quantity = null
            e.node.total = 0
            e.node.department = null
            e.node.account = null
            e.node.orderInstruction = null
          }
          return e
        }) as PossibleRequisitionLinesEdge[]
      })
      cache.writeQuery({ ...queryToCache, data: newData })
    },
  })
  return mutation
}
