import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useParams } from 'react-router-dom'

import { useGetBuyList } from '../hooks/useGetBuyList'
import BuyListProductsTableView from './table-view/BuyListProductsTableView'

import EmptyProducts from '@/assets/images/empty-products.svg'
import { CataloguedProduct } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Spinner } from '@/modules/requisitions/components'
import { QueryResult } from '@/modules/shared/components'
import EmptyState from '@/modules/shared/components/empty-state'
import SearchAndFilter from '@/modules/shared/components/search-input/SearchAndFilter'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export default function BuyListProducts() {
  const { t } = useTranslation()
  const { buyListId } = useParams<{ buyListId: string }>()

  const { data, networkStatus, error, onFetchMoreProducts } = useGetBuyList(String(buyListId))

  const cataloguedProducts = extractEdges<CataloguedProduct>(data?.currentPurchaser?.catalogue?.cataloguedProducts)
  const { fetchMoreLoading, loading, setVariablesLoading } = checkNetworkStatus(networkStatus)

  return (
    <div className="space-y-5">
      <SearchAndFilter
        testId="buy-list-search-input"
        placeholder={t('buyLists.buyList.searchPlaceholder', 'Search By Product Name')}
        ariaLabel={t('buyLists.buyList.searchPlaceholder', 'Search By Product Name')}
      />
      <QueryResult loading={loading || setVariablesLoading} error={error}>
        <InfiniteScroll
          dataLength={cataloguedProducts.length}
          next={onFetchMoreProducts}
          hasMore={!!data?.currentPurchaser?.catalogue?.cataloguedProducts?.pageInfo?.hasNextPage}
          loader={fetchMoreLoading && <Spinner className="mt-5 h-14 md:w-16" />}
        >
          <BuyListProductsTableView cataloguedProducts={cataloguedProducts} />
        </InfiniteScroll>
        {cataloguedProducts.length === 0 && (
          <EmptyState
            message={t('buyLists.productsEmptyState', 'There are no products to display.')}
            img={EmptyProducts}
          />
        )}
      </QueryResult>
    </div>
  )
}
