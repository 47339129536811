import Fallback from '../../fallback'

import { Account, Purchaser } from '@/graphql/purchasing/generated/purchasing_graphql'

export interface AccountCodeItemProps {
  department?: Purchaser | null
  account?: Account | null
}

export const AccountCodeItem = ({ department, account }: AccountCodeItemProps) => {
  return (
    <Fallback condition={!!department || !!account}>
      <p>{department?.name}</p>
      <p className="text-xs">{account?.accountName}</p>
    </Fallback>
  )
}
