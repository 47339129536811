import { RequisitionLine } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useMoney } from '@/modules/shared/hooks'

interface PRLineTotalProps {
  requisitionLine: RequisitionLine
}

export default function PRLineTotal({ requisitionLine }: PRLineTotalProps) {
  const { total } = requisitionLine
  const { formatMoney } = useMoney()

  return <strong>{formatMoney(total)}</strong>
}
