import { useMutation } from '@apollo/client'
import { produce } from 'immer'

import {
  CreatePricedCataloguedProductDocument,
  GetAvailableProductsQuery,
  ProductEdge,
  RansackDirection,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import { GET_AVAILABLE_PRODUCTS } from '@/graphql/purchasing/operations/queries/getAvailableProducts'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { ProductType } from '@/modules/shared/types'

interface UseCreatePricedCataloguedProductProps {
  catalogId: number
  line: ProductType
}

export default function useCreatePricedCatalogedProduct({ catalogId, line }: UseCreatePricedCataloguedProductProps) {
  const mutation = useMutation(CreatePricedCataloguedProductDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    update: (cache) => {
      const queryToCache = {
        query: GET_AVAILABLE_PRODUCTS,
        variables: {
          catalogueId: catalogId,
          invoiceId: 0,
          sortPrices: { property: 'unitPrice', direction: RansackDirection.Asc },
        },
      }
      const availableProductsCache = cache.readQuery(queryToCache) as GetAvailableProductsQuery
      const newAvailableProductsCache = produce(availableProductsCache, (draft) => {
        let { edges } = draft.currentPurchaser?.availableProducts || {}
        draft.currentPurchaser!.availableProducts!.edges = edges?.map((e) => {
          if (e?.node?.id === line?.product?.id) {
            e!.node!.catalogued = true
          }
          return e
        }) as ProductEdge[]
      })
      cache.writeQuery({ ...queryToCache, data: newAvailableProductsCache })
    },
  })

  return mutation
}
