import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { generateShopPath } from '../utils'

import { CreateRequisitionDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export const useCreateRequisition = () => {
  const navigate = useNavigate()
  const mutation = useMutation(CreateRequisitionDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    onCompleted(data) {
      const { id, advanced } = data.createRequisition.requisition || {}
      navigate(generateShopPath(id, advanced))
    },
  })

  return mutation
}
