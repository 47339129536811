import { GetDepartmentsQuery, Purchaser } from '@/graphql/purchasing/generated/purchasing_graphql'

// Sort function for departments based on legalEntity (true at top) and then by name
function sortDepartments(a: Purchaser, b: Purchaser): number {
  if (!!a.legalEntity !== !!b.legalEntity) {
    return a.legalEntity ? -1 : 1
  }
  return (a.name || '') < (b.name || '') ? -1 : 1
}

/**
 * Takes the getDepartments query and returns a nested list of departments organised by their parent and child
 *  hierarchies.
 */
export function generateNestedOrgs(
  rawData: GetDepartmentsQuery | undefined,
  selectedId?: number | null
): Purchaser | null {
  const purchasersById: Record<number, Purchaser> = {}

  // Loop through each department in the currentPurchaser, if present, and map them to the purchasersById object
  rawData?.currentPurchaser?.departments?.forEach((purchaser) => {
    purchasersById[purchaser.id] = purchaser as Purchaser
  })

  // Define a recursive function that builds a tree structure of nested Purchasers given a root purchaserId
  function buildTree(purchaserId: number | null): Purchaser | null {
    // Return null if purchaserId is not present or if there's no Purchaser with the given ID
    if (!purchaserId || !purchasersById[purchaserId]) {
      return null
    }

    // Get the current Purchaser object using its ID
    const currentPurchaser = purchasersById[purchaserId]

    // Filter the departments based on their parent's ID matching the given purchaserId
    let children = rawData?.currentPurchaser?.departments.filter(
      (dept) => (dept.parent ? dept.parent.id : null) === purchaserId
    ) as Purchaser[]

    // Sort the children using the sortDepartments function
    children = children.sort(sortDepartments)

    // Return a new Purchaser object with the same properties as the current Purchaser,
    // but with a nested departments array containing its child Purchasers
    return {
      ...currentPurchaser,
      departments: children.map((child) => buildTree(child.id)).filter(Boolean) as Purchaser[],
    }
  }

  // If selectedId is provided, start building the tree from there.
  // Otherwise, get the parent organization from the rawData and start building the tree from its ID.
  const startId = selectedId !== undefined ? selectedId : rawData?.currentPurchaser?.id ?? null

  // Build the tree structure starting from the startId
  const topLevelParent = buildTree(startId)

  return topLevelParent
}
