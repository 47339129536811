export const PURCHASING_GRAPHQL_API = `${process.env.REACT_APP_NINJA_API_HOST}/purchasing/api/graphql`
export const GOODS_GRAPHQL_API = `${process.env.REACT_APP_NINJA_API_HOST}/goods/api/graphql`
export const INVENTORY_GRAPHQL_API = `${process.env.REACT_APP_NINJA_API_HOST}/inventory/api/graphql`
export const ACCESS_GRAPHQL_API = `${process.env.REACT_APP_NINJA_API_HOST}/access/api/graphql`
export const SUPPORT_LINK = 'https://help.purchaseplus.com'
export const SOURCE_APPLICATION = 'ppnew'

export const ENTERPRISE_CREDIT_NOTES_URL = `${process.env.REACT_APP_NINJA_API_HOST}/purchasing/en/credit_notes/`
export const ENTERPRISE_RECEIVING_TRANSFER = `${process.env.REACT_APP_NINJA_API_HOST}/inventory/en/receiving_transfers`
export const ENTERPRISE_REQUISITION_URL = `${process.env.REACT_APP_NINJA_API_HOST}/purchasing/en/requisitions`
export const ENTERPRISE_PURCHASING_CATALOGUES_URL = `${process.env.REACT_APP_NINJA_API_HOST}/purchasing/en/catalogues`
export const ENTERPRISE_PURCHASING_USERS_URL = `${process.env.REACT_APP_NINJA_API_HOST}/purchasing/en/users`
export const ENTERPRISE_SUPPLIER_RELATIONSHIP_URL = `${process.env.REACT_APP_NINJA_API_HOST}/purchasing/en/supplier_relationships`
export const ENTERPRISE_SUPPLIER_URL = `${process.env.REACT_APP_NINJA_API_HOST}/traders/en/suppliers`
export const ENTERPRISE_GR_URL = `${process.env.REACT_APP_NINJA_API_HOST}/purchasing/en/receiving_documents`
export const ENTERPRISE_PO_URL = `${process.env.REACT_APP_NINJA_API_HOST}/purchasing/en/purchase_orders`
export const ENTERPRISE_INVOICE_URL = `${process.env.REACT_APP_NINJA_API_HOST}/purchasing/en/invoices`
export const ENTERPRISE_STOCK_LOCATION_URL = `${process.env.REACT_APP_NINJA_API_HOST}/inventory/en/stock_locations`
export const ENTERPRISE_USERS_URL = `${process.env.REACT_APP_NINJA_API_HOST}/access/en/users`
export const ENTERPRISE_PRICED_CATALOGS_URL = `${process.env.REACT_APP_NINJA_API_HOST}/purchasing/en/priced_catalogues`
export const ENTERPRISE_SUPPLIER_CATALOGS_URL = `${process.env.REACT_APP_NINJA_API_HOST}/supply/en/catalogues`

export const requisitionRoutes = [{ path: '/requisitions/*' }]

export const purchaseOrderRoutes = [{ path: '/purchase-orders/*' }]

export const invoiceRoutes = [{ path: '/invoices/*' }, { path: '/credit-notes' }]

export const buyListRoutes = [{ path: '/buy-lists/*' }]

export const catalogRoutes = [{ path: '/catalogs/*' }]

export const supplierRoutes = [{ path: '/my-suppliers/*' }, { path: '/supplier-directory/*' }]

export const inventoryRoutes = [{ path: '/stock-locations/*' }, { path: '/stocktakes/*' }]

export const systemSettingRoutes = [{ path: '/users/*' }]

export const myProductsRoutes = [{ path: '/my-products/*' }]
