import i18next from 'i18next'

import { FlaggingMessageTypeEnum } from '@/graphql/purchasing/generated/purchasing_graphql'

export function translateFlagCode(flagCode: FlaggingMessageTypeEnum, values: string): string {
  switch (flagCode) {
    case FlaggingMessageTypeEnum.InvoiceFlaggedLineBlockage:
      return i18next.t(
        'invoice.flagMessage.invoiceFlaggedLineBlockage',
        'There are one or more Flagged Lines on this Invoice'
      )

    // TODO: schema changed, to be updated and implemented in different ticket
    // case FlaggingMessageTypeEnum.InvoiceLineQuantityBlockage:
    //   return i18next.t(
    //     'invoice.flagMessage.invoiceLineQuantityBlockage',
    //     "The difference between the Total Invoiced Quantity of {{ quantity }} and the {{ which_quantity }} Quantity of {{ quantity_to_match }} exceeds your Organisation's threshold of {{ threshold }}.",
    //     JSON.parse(values)
    //   )

    case FlaggingMessageTypeEnum.InvoiceLineUnitPriceBlockage:
      return i18next.t(
        'invoice.flagMessage.invoiceLineUnitPriceBlockage',
        "The difference between the Invoice Unit Price of {{ unit_price }} and the Ordered Unit Price of {{ unit_price_to_match }} exceeds your Organisation's threshold of {{ threshold }}.",
        JSON.parse(values)
      )

    case FlaggingMessageTypeEnum.InvoiceLineUnmatchedBlockage:
      return i18next.t(
        'invoice.flagMessage.invoiceLineUnmatchedBlockage',
        'This Invoice Line is not yet linked to a Purchase Order Line'
      )

    case FlaggingMessageTypeEnum.InvoiceNotReceivedBlockage:
      return i18next.t(
        'invoice.flagMessage.invoiceNotReceivedBlockage',
        'No Goods Receiving Notes have been recorded for this Invoice'
      )

    case FlaggingMessageTypeEnum.InvoiceSumLineErrorBlockage:
      return i18next.t(
        'invoice.flagMessage.invoiceSumLineErrorBlockage',
        "The sum of the differences between the {{ which_total }} Totals and the Invoiced Line Totals {{ sum_of_line_total_errors }} exceeds your Organisation's threshold of {{ threshold }}.",
        JSON.parse(values)
      )

    case FlaggingMessageTypeEnum.InvoiceTotalBlockage:
      return i18next.t(
        'invoice.flagMessage.invoiceTotalBlockage',
        "The difference between the Invoice Total of {{ total }} and the sum of the Invoice Parts exceeds your Organisation's threshold of {{ threshold }}.",
        JSON.parse(values)
      )

    case FlaggingMessageTypeEnum.InvoiceTotalMatchBlockage:
      return i18next.t(
        'invoice.flagMessage.invoiceTotalMatchBlockage',
        "The difference between the Invoice Total of {{ total }} and the Purchase Order Total of {{ po_total }} exceeds your Organisation's threshold of {{ threshold }}.",
        JSON.parse(values)
      )

    case FlaggingMessageTypeEnum.InvoiceUnmatchedBlockage:
      return i18next.t(
        'invoice.flagMessage.invoiceUnmatchedBlockage',
        'This Invoice is not yet matched to a Purchase Order'
      )

    default:
      return ''
  }
}
