import { ReactNode } from 'react'

import TableItem from './TableItem'

interface TableRowSingleProps {
  titleText: string
  tooltipText?: string
  value: string | ReactNode
  update?: string
  testId: string
}

function TableRowSingle({ titleText, tooltipText, value, update, testId }: TableRowSingleProps) {
  return (
    <>
      <div className="flex">
        <div className="flex grow items-center">
          <TableItem titleText={titleText} tooltipText={tooltipText} value={value} update={update} testId={testId} />
        </div>
      </div>
    </>
  )
}

export default TableRowSingle
