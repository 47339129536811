import { graphql } from 'msw'

import {
  PurchaseOrderLineItemReceivedStatusEnum,
  ReceiveReceivingDocumentMutation,
} from '@/graphql/purchasing/generated/purchasing_graphql'

export const ReceiveReceivingDocument = graphql.mutation<ReceiveReceivingDocumentMutation>(
  'ReceiveReceivingDocument',
  (_, res, ctx) => {
    return res(
      ctx.data({
        receiveReceivingDocument: {
          receivingDocument: {
            id: 6659890,
            purchaseOrder: {
              id: 16566710,
              purchaseOrderNumber: 'PO04331',
              __typename: 'PurchaseOrder',
              lines: {
                nodes: [
                  {
                    id: 60219623,
                    receivedStatus: PurchaseOrderLineItemReceivedStatusEnum.FullyReceived,
                    __typename: 'PurchaseOrderLineItem',
                  },
                  {
                    id: 60219624,
                    receivedStatus: PurchaseOrderLineItemReceivedStatusEnum.FullyReceived,
                    __typename: 'PurchaseOrderLineItem',
                  },
                ],
                __typename: 'PurchaseOrderLineItemConnection',
              },
            },
            receivingDocumentLineItems: {
              edges: [
                {
                  node: {
                    id: 22273758,
                    purchaseOrderLineItem: {
                      id: 16566710,
                      quantity: 1,
                      receivedQuantity: 1,
                    },
                    quantity: 1,
                    receivedQuantity: 0,
                  },
                },
                {
                  node: {
                    id: 3263701,
                    purchaseOrderLineItem: {
                      id: 16566711,
                      quantity: 1,
                      receivedQuantity: 1,
                    },
                    quantity: 1,
                    receivedQuantity: 0,
                  },
                },
              ],
            },
            workflowState: 'cancelled',
            __typename: 'ReceivingDocument',
          },
          __typename: 'ReceiveReceivingDocumentMutationPayload',
        },
      })
    )
  }
)
