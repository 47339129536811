import { useMutation } from '@apollo/client'

import { UpdateReceivingDocumentLineItemDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'

export default function useUpdateReceivingDocumentLine() {
  const mutation = useMutation(UpdateReceivingDocumentLineItemDocument, {
    context: { uri: PURCHASING_GRAPHQL_API },
  })
  return mutation
}
