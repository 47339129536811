import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import InvoiceStatus from '../../all-invoices/InvoiceStatus'

import { GetCreditNoteDetailsDocument } from '@/graphql/purchasing/generated/purchasing_graphql'
import { Fallback, PPNewDetailLink, QueryResult } from '@/modules/shared/components'
import { TableRowDouble, TableRowSingle } from '@/modules/shared/components/page-header-details-table'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useMoney } from '@/modules/shared/hooks'
import { Dates } from '@/modules/shared/types'
import { formatDate } from '@/modules/shared/utils'

export default function CreditNoteDetailsPanel() {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const { creditNoteId } = useParams<{ creditNoteId: string }>()
  const { data, loading, error } = useQuery(GetCreditNoteDetailsDocument, {
    variables: {
      creditNoteId: Number(creditNoteId),
    },
    context: { uri: PURCHASING_GRAPHQL_API },
  })

  const {
    creditNoteNumber,
    creditNoteDate,
    reference,
    workflowState,
    department,
    account,
    supplier,
    deliveryChargeExTax,
    deliveryChargeTax,
    totalValue,
    totalTaxValue,
    invoice,
  } = data?.currentPurchaser?.creditNote || {}

  return (
    <QueryResult loading={loading} error={error} size="md">
      <section
        className="flex items-center justify-between rounded-md  bg-white p-4 text-sm shadow"
        data-testid="credit-note-details-panel"
      >
        <div className="w-full divide-y divide-gray-300 overflow-auto rounded-md border border-gray-300">
          <TableRowDouble
            leftTitle={t('general.creditNoteNumber', 'Credit Note Number')}
            leftTooltip={t(
              'creditNotes.detailsTable.creditNoteNumberTooltip',
              'The number used to identify this Credit Note'
            )}
            leftTestId="creditNoteNumber"
            leftValue={creditNoteNumber}
            rightTitle={t('general.creditNoteDate', 'Credit Note Date')}
            rightTooltip={t('creditNotes.detailsTable.creditNoteDateTooltip', 'The date recorded on this Credit Note.')}
            rightTestId="dueDate"
            rightValue={formatDate(Dates.ShortReverse, creditNoteDate)}
          />
          <TableRowSingle
            titleText={t('general.reference', 'Reference')}
            tooltipText={t(
              'creditNotes.detailsTable.referenceTooltip',
              'The reference that has been added to this Credit Note.'
            )}
            testId="reference"
            value={<Fallback condition={!!reference}>{reference}</Fallback>}
          />
          <TableRowSingle
            titleText={t('general.status', 'Status')}
            tooltipText={t('creditNotes.detailsTable.statusTooltip', 'The current status of the Credit Note.')}
            testId="status"
            value={<InvoiceStatus state={workflowState || ''} />}
          />
          <TableRowDouble
            leftTitle={t('general.department', 'Department')}
            leftTooltip={t(
              'creditNotes.detailsTable.departmentTooltip',
              'The Department that this Credit Note will be recorded against.'
            )}
            leftTestId="department"
            leftValue={<Fallback condition={!!department?.name}>{department?.name}</Fallback>}
            rightTitle={t('general.accountCode', 'Account Code')}
            rightTooltip={t(
              'creditNotes.detailsTable.accountCodeTooltip',
              'The Account Code that this Credit Note will be recorded against.'
            )}
            rightTestId="accountCode"
            rightValue={<Fallback condition={!!account?.code}>{account?.code}</Fallback>}
          />
          <TableRowSingle
            titleText={t('general.supplier', 'Supplier')}
            tooltipText={t('creditNotes.detailsTable.supplierTooltip', 'The Supplier that has sent the Credit Note.')}
            testId="supplier"
            value={<Fallback condition={!!supplier?.name}>{supplier?.name}</Fallback>}
          />
          <TableRowDouble
            leftTitle={t('general.deliveryFeeExTax', 'Delivery Fee (Ex Tax)')}
            leftTooltip={t('creditNotes.detailsTable..deliveryFeeExTaxTooltip', 'The Delivery Fee excluding tax.')}
            leftTestId="deliveryFeeExTax"
            leftValue={formatMoney(deliveryChargeExTax)}
            rightTitle={t('general.deliveryFeeTax', 'Delivery Fee Tax')}
            rightTooltip={t(
              'creditNotes.detailsTable.deliveryFeeEInTaxTooltip',
              'The tax amount that will be added to the Delivery Fee.'
            )}
            rightTestId="deliveryFeeEInTax"
            rightValue={formatMoney(deliveryChargeTax)}
          />
          <TableRowDouble
            leftTitle={t('general.creditNoteTotalExTax2', 'Credit Note Total (Ex Tax)')}
            leftTooltip={t(
              'creditNotes.detailsTable.creditNoteTotalExTax2Tooltip',
              'The total of the Credit Note Total excluding tax. This should equal the tax exclusive sum of each line of the Credit Note, plus the Delivery Fee excluding tax.'
            )}
            leftTestId="creditNoteTotalExTax"
            leftValue={formatMoney((totalValue || 0) - (totalTaxValue || 0))}
            rightTitle={t('general.totalTax', 'Total Tax')}
            rightTooltip={t(
              'creditNotes.detailsTable.totalTaxTooltip',
              ' The total tax of the Credit Note. This should equal the sum of the tax on each line of the Credit Note, plus the Delivery Fee tax.'
            )}
            rightTestId="totalTax"
            rightValue={formatMoney(totalTaxValue)}
          />
          <TableRowSingle
            titleText={t('general.creditNoteTotal', 'Credit Note Total')}
            tooltipText={t(
              'creditNotes.detailsTable.invoiceTotalInTaxTooltip',
              'The tax inclusive total of the Credit Note. This value is the Credit Note Total Ex Tax plus the Credit Note Total Tax.'
            )}
            testId="creditNoteTotalInTax"
            value={formatMoney(totalValue)}
          />
          <TableRowSingle
            titleText={t('general.invoice', 'Invoice')}
            tooltipText={t(
              'creditNotes.detailsTable.invoiceTooltip',
              'The Invoice that this Credit Note belongs to, if any.'
            )}
            testId="invoice"
            value={
              <Fallback condition={!!invoice}>
                <PPNewDetailLink type="invoice" id={String(invoice?.id)} openNewTab>
                  {invoice?.invoiceNumber}
                </PPNewDetailLink>
              </Fallback>
            }
          />
        </div>
      </section>
    </QueryResult>
  )
}
