import { graphql } from 'msw'

import { GetSupplierRelationshipsWithCatalogueQuery } from '@/graphql/purchasing/generated/purchasing_graphql'

const baseGetSupplierRelationshipsWithCatalogue: GetSupplierRelationshipsWithCatalogueQuery = {
  currentPurchaser: {
    id: 44926,
    supplierRelationshipsWithCatalogue: {
      edges: [
        {
          node: {
            id: 9234,
            isEnabled: false,
            supplierName: '01 Computer System Pty Ltd',
            supplierNumber: '121233424',
            supplierId: 1000,
            __typename: 'SupplierRelationship',
          },
          __typename: 'SupplierRelationshipEdge',
        },
        {
          node: {
            id: 1235,
            isEnabled: true,
            supplierName: 'Random Company',
            supplierNumber: '532153',
            supplierId: 1001,
            __typename: 'SupplierRelationship',
          },
          __typename: 'SupplierRelationshipEdge',
        },
        {
          node: {
            id: 3211,
            isEnabled: false,
            supplierName: 'Zeus Wholesale',
            supplierNumber: '89412928',
            supplierId: 1002,
            __typename: 'SupplierRelationship',
          },
          __typename: 'SupplierRelationshipEdge',
        },
      ],
      pageInfo: {
        endCursor: 'MjU',
        hasNextPage: false,
        __typename: 'PageInfo',
      },
      __typename: 'SupplierRelationshipConnection',
    },
    __typename: 'Purchaser',
  },
}

export const GetSupplierRelationshipsWithCatalogue = graphql.query<GetSupplierRelationshipsWithCatalogueQuery>(
  'GetSupplierRelationshipsWithCatalogue',
  (_, res, ctx) => {
    return res(ctx.data(baseGetSupplierRelationshipsWithCatalogue))
  }
)

export default baseGetSupplierRelationshipsWithCatalogue
