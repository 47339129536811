import { useLazyQuery, useMutation } from '@apollo/client'
import { Dispatch, SetStateAction, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import {
  GetAllInvoicesDocument,
  Invoice,
  MatchPurchaseOrderInvoiceDocument,
  PurchaseOrder,
} from '@/graphql/purchasing/generated/purchasing_graphql'
import InvoiceStatus from '@/modules/invoices/pages/all-invoices/InvoiceStatus'
import { Spinner } from '@/modules/requisitions/components'
import {
  Button,
  Checkbox,
  ConfirmDialog,
  Fallback,
  Input,
  Modal,
  PPNewDetailLink,
  QueryResult,
} from '@/modules/shared/components'
import Table from '@/modules/shared/components/table/Table'
import { PURCHASING_GRAPHQL_API } from '@/modules/shared/constants'
import { useDebounce, useMoney } from '@/modules/shared/hooks'
import { CheckIcon, MagnifyingGlassIcon, NewTabIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

interface MatchInvoiceModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<number | null>>
  purchaseOrder: PurchaseOrder
}

function MatchInvoiceModal(props: MatchInvoiceModalProps) {
  const { showModal, setShowModal, purchaseOrder } = props
  const { t } = useTranslation()
  const { formatMoney } = useMoney()

  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const [inputFocus, setInputFocus] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState<number | null>(null)
  const [hasMatched, setHasMatched] = useState(false)
  const [showError, setShowError] = useState<boolean>(false)

  const [fetchInvoices, { data, error, refetch, networkStatus, fetchMore }] = useLazyQuery(GetAllInvoicesDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  const [matchInvoice, { loading: matchInvoiceLoading }] = useMutation(MatchPurchaseOrderInvoiceDocument, {
    context: {
      uri: PURCHASING_GRAPHQL_API,
    },
  })

  const { setVariablesLoading, loading } = checkNetworkStatus(networkStatus)

  const onMatchInvoice = () => {
    matchInvoice({
      variables: { input: { id: Number(selectedInvoice), purchaseOrderId: purchaseOrder.id } },
      onCompleted: () => setHasMatched(true),
      onError: () => setShowError(true),
    })
  }

  const onCloseModal = () => {
    setShowModal(null)
    setSelectedInvoice(null)
    window.setTimeout(() => setHasMatched(false), 1000) // wait for modal to fade out before reseting state
  }

  const invoicesFilterQ = [
    { property: 'supplier_id_eq', value: purchaseOrder?.supplier?.id },
    { property: 'state_in', value: ['open', 'flagged', 'parked', 'reconciled'] },
    { property: 'purchase_order_id_present', value: 'false' },
  ]

  useEffect(() => {
    if (inputFocus) {
      refetch({
        filter: {
          q: [...invoicesFilterQ, { property: 'invoiceNumber_cont', value: debouncedSearchValue }],
        },
      })
    }
  }, [debouncedSearchValue])

  const onFetchMoreInvoices = () => {
    fetchMore({ variables: { after: data?.currentPurchaser?.invoices?.pageInfo.endCursor } })
  }

  useEffect(() => {
    if (showModal) {
      fetchInvoices({
        variables: {
          filter: {
            q: invoicesFilterQ,
          },
        },
      })
    }
  }, [showModal])

  const invoices = extractEdges<Invoice>(data?.currentPurchaser?.invoices)

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal} dataTestId="match-invoice-modal">
      <Modal.Panel
        as="div"
        className="flex max-h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[56.25rem]"
      >
        <Modal.Title
          title={
            hasMatched
              ? t('general.successfullyMatched', 'Successfully Matched')
              : t(
                  'purchaseOrders.allPurchaseOrders.table.matchInvoiceModal.title',
                  'Match Invoice to {{ purchaseOrderNumber }}',
                  {
                    purchaseOrderNumber: purchaseOrder.purchaseOrderNumber,
                  }
                )
          }
          onCloseModal={onCloseModal}
        />

        {hasMatched ? (
          <div className="py-16 text-center" data-testid="successfully-matched">
            <CheckIcon className="m-auto mb-4 h-12 w-12 text-success" />
            <p className="text-center text-lg font-bold">{t('general.successfullyMatched', 'Successfully Matched')}</p>
            <p className="text-center text-gray-500">
              {t(
                'purchaseOrders.allPurchaseOrders.table.matchInvoiceModal.matchedBody',
                '{{ invoiceNumber }} was successfully matched to {{ purchaseOrderNumber }}',
                {
                  invoiceNumber: invoices.find((invoice) => invoice.id === selectedInvoice)?.invoiceNumber,
                  purchaseOrderNumber: purchaseOrder.purchaseOrderNumber,
                }
              )}
            </p>
          </div>
        ) : (
          <Modal.Body className="text-sm">
            <p className="font-bold">{t('general.availableInvoices', 'Available Invoices')}</p>
            <p className="text-gray-500">
              {t(
                'purchaseOrders.allPurchaseOrders.table.matchInvoiceModal.body',
                'Here you can search for an Invoices to match to this Purchase Order. Only Invoices from the Supplier Zeus Wholesale will appear here, and only those Invoices in an Open, Flagged, Parked or Reconciled state. You can match one Invoice at a time.'
              )}
            </p>
            <Input
              suffixIcon={MagnifyingGlassIcon}
              className="my-4 w-full rounded-md border border-gray-200 p-3 text-sm"
              onChange={(e) => setSearchValue(e)}
              placeholder={t(
                'purchaseOrders.allPurchaseOrders.table.matchInvoiceModal.searchPlaceholder',
                'Search by Invoice Number'
              )}
              onFocus={() => setInputFocus(true)}
              aria-label={t(
                'purchaseOrders.allPurchaseOrders.table.matchInvoiceModal.searchPlaceholder',
                'Search by Invoice Number'
              )}
              data-testid="search-invoice-input"
            />
            <QueryResult loading={loading || setVariablesLoading} error={error}>
              <InfiniteScroll
                dataLength={invoices.length}
                next={onFetchMoreInvoices}
                hasMore={!!data?.currentPurchaser?.invoices?.pageInfo.hasNextPage}
                loader={<Spinner className="mt-5 h-14 md:w-16" />}
              >
                <Table
                  dataSource={invoices}
                  keyExtractor={(record) => String(record.id)}
                  tableHeadStyles="border-none"
                  columns={[
                    {
                      title: t(
                        'purchaseOrders.allPurchaseOrders.table.matchInvoiceModal.table.invoiceNumber',
                        'Invoice Number'
                      ),
                      key: 'invoice',
                      headerCellStyles: 'pl-3 pr-4 py-3',
                      cellStyles: 'pl-3 pr-4 py-3',
                      minWidth: 8.75,
                      maxWidth: 10,
                      render(record) {
                        return (
                          <PPNewDetailLink
                            testId="invoice-number"
                            openNewTab
                            className="flex items-center gap-x-1 text-primary"
                            type="invoice"
                            id={String(record.id)}
                          >
                            <span>{record.invoiceNumber}</span>
                            <NewTabIcon className="h-5 w-5" />
                          </PPNewDetailLink>
                        )
                      },
                    },
                    {
                      title: t('general.reference', 'Reference'),
                      key: 'reference',
                      headerCellStyles: 'px-2 py-3',
                      cellStyles: 'px-2 py-3',
                      minWidth: 11.25,
                      grow: true,
                      render(record) {
                        return <Fallback condition={!!record.reference}>{record.reference}</Fallback>
                      },
                    },
                    {
                      title: t(
                        'purchaseOrders.allPurchaseOrders.table.matchInvoiceModal.table.invoiceStatus',
                        'Invoice Status'
                      ),
                      key: 'invoiceStatus',
                      headerCellStyles: 'py-3 text-center',
                      cellStyles: 'py-3 text-center',
                      minWidth: 10,
                      maxWidth: 10,
                      render(record) {
                        return <InvoiceStatus state={String(record.state)} />
                      },
                    },
                    {
                      title: t(
                        'purchaseOrders.allPurchaseOrders.table.matchInvoiceModal.table.invoiceTotal',
                        'Invoice Total'
                      ),
                      key: 'invoiceTotal',
                      headerCellStyles: 'py-3 text-right',
                      cellStyles: 'py-3 text-right font-bold',
                      minWidth: 6.875,
                      maxWidth: 7.5,
                      render(record) {
                        return formatMoney(record.totalValue)
                      },
                    },
                    {
                      title: t('general.match', 'Match'),
                      key: 'invoiceMatch',
                      headerCellStyles: 'py-3 text-center',
                      cellStyles: 'py-3 text-center',
                      minWidth: 5,
                      maxWidth: 5,
                      render(record) {
                        return (
                          <span
                            className="flex justify-center"
                            onClick={() => setSelectedInvoice(record.id)}
                            data-testid="select-invoice"
                          >
                            <Checkbox isSelected={selectedInvoice === record.id} aria-label="match" />
                          </span>
                        )
                      },
                    },
                  ]}
                />
              </InfiniteScroll>
              {!setVariablesLoading && invoices.length === 0 && (
                <div className="border bg-gray-100 p-4 text-center">
                  {t(
                    'purchaseOrders.allPurchaseOrders.table.matchInvoiceModal.noInvoices',
                    "We couldn't find any Invoices to display."
                  )}
                </div>
              )}
            </QueryResult>
            <ConfirmDialog
              title={t('general.failedToMatch', 'Failed to Match')}
              description={t(
                'purchaseOrders.allPurchaseOrders.table.matchInvoiceModal.errorDescription',
                'Sorry, something went wrong and we were unable to match the Invoice to the Purchase Order.'
              )}
              primaryButtonLabel={t('general.okay', 'Okay')}
              onPrimaryBtnClick={() => setShowError(false)}
              theme={Themes.Error}
              isOpen={showError}
              setIsOpen={setShowError}
            />
          </Modal.Body>
        )}
        <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
          <Button
            type="button"
            className="w-full rounded-md bg-gray-200 px-8 py-2.5 text-sm md:w-auto"
            onClick={onCloseModal}
            data-testid="match-invoice-close"
          >
            {t('general.close', 'Close')}
          </Button>
          {!hasMatched && (
            <Button
              type="button"
              className="w-full rounded-md bg-primary px-8 py-2.5 text-sm text-white md:w-auto"
              loading={matchInvoiceLoading}
              onClick={onMatchInvoice}
              disabled={!selectedInvoice}
              data-testid="apply-match"
            >
              {t('general.applyMatch', 'Apply Match')}
            </Button>
          )}
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}

export default memo(MatchInvoiceModal)
