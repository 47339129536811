import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import { InvoiceActionTypes, InvoiceStatuses } from '@/modules/invoices/types'
import { ConfirmDialog } from '@/modules/shared/components'
import { Themes } from '@/modules/shared/types'

interface InvoiceActionConfirmDialogProps {
  invoiceAction: InvoiceActionTypes
  showConfirmActionDialog: boolean
  setShowConfirmActionDialog: Dispatch<SetStateAction<boolean>>
  confirmActionLoading: boolean
  onConfirmInvoiceAction: () => void
  invoice: Invoice
}

type InvoiceActionConfirmDialogType = {
  title: string
  description: string
  primaryButtonLabel: string
  secondaryButtonLabel: string
}

export default function InvoiceActionConfirmDialog({
  invoiceAction,
  showConfirmActionDialog,
  setShowConfirmActionDialog,
  confirmActionLoading,
  onConfirmInvoiceAction,
  invoice,
}: InvoiceActionConfirmDialogProps) {
  const { t } = useTranslation()

  const getInvoiceActionDialogText = (): InvoiceActionConfirmDialogType => {
    switch (invoiceAction) {
      case InvoiceActionTypes.Reopen:
        return {
          title: t('general.reopenInvoice', 'Re-Open Invoice'),
          description: t(
            'invoices.invoice.action.reopen.description',
            'Please confirm below if you wish to Re-Open the Invoice. Once Re-Opened, you will be able to make changes to it and mark is as Complete.'
          ),
          primaryButtonLabel: t('general.reopenInvoice', 'Re-Open Invoice'),
          secondaryButtonLabel:
            invoice?.state === InvoiceStatuses.Cancelled
              ? t('general.keepAsCancelled', 'Keep as Cancelled')
              : t('general.keepAsReconciled', 'Keep as Reconciled'),
        }
      case InvoiceActionTypes.Cancel:
        return {
          title: t('general.cancelInvoice?', 'Cancel Invoice?'),
          description: t(
            'invoices.invoice.action.cancel.description',
            'Please confirm below if you wish to Cancel the Invoice. Once Cancelled, you will not be able to make changes to the Invoice. You will be able to Re-Open the Cancelled Invoice in the future if needed.'
          ),
          primaryButtonLabel: t('general.cancelInvoice', 'Cancel Invoice'),
          secondaryButtonLabel:
            invoice?.state === InvoiceStatuses.Open
              ? t('general.keepAsOpen', 'Keep as Open')
              : t('general.keepAsFlagged', 'Keep as Flagged'),
        }
      case InvoiceActionTypes.Halt:
        return {
          title: t('general.haltInvoice?', 'Halt Invoice?'),
          description: t(
            'invoices.invoice.action.halt.description',
            'Please confirm below if you wish to Halt the Invoice. By choosing Halt, the Invoice will be removed from the Ready for Processing pool, and will be reverted to a Reconciled state. Once in a Reconciled state, you will be able to Re-Open the Invoice if you wish to make changes to it.'
          ),
          primaryButtonLabel: t('general.haltInvoice', 'Halt Invoice'),
          secondaryButtonLabel: t('general.keepAsProcessing', 'Keep as Processing'),
        }
      case InvoiceActionTypes.Park:
        return {
          title: t('general.parkInvoice?', 'Park Invoice?'),
          description: t(
            'invoices.invoice.action.park.description',
            'Please confirm below if you wish to Park the Invoice. Once Parked, you will not be able to make changes to the Invoice. You will be able to Re-Open the Parked Invoice in the future when you wish to resume making changes to it.'
          ),
          primaryButtonLabel: t('general.parkInvoice', 'Park Invoice'),
          secondaryButtonLabel:
            invoice?.state === InvoiceStatuses.Open
              ? t('general.keepAsOpen', 'Keep as Open')
              : t('general.keepAsFlagged', 'Keep as Flagged'),
        }
      case InvoiceActionTypes.Complete:
        return {
          title: invoice?.lines?.edges?.length
            ? t('general.invoiceNotBalanced', 'Invoice Not Balanced')
            : t('invoices.invoice.action.noLines.title', 'Invoice Has Zero Lines'),
          description: invoice?.lines?.edges?.length
            ? t(
                'invoices.invoice.action.notBalanced.description',
                'The Invoice cannot be Marked as Flagged while it does not balance. The sum of the Invoice parts does not currently balance with the Invoice Total. Please visit the balance panel at the top of the Invoice to update the Invoice totals and balance the Invoice.'
              )
            : t(
                'invoices.invoice.action.noLines.description',
                'You can’t mark the Invoice as Reconciled while the Invoice has zero lines. A line will need to be added to the Invoice before you can mark it as Reconciled.'
              ),
          primaryButtonLabel: t('general.okayGotIt', 'Okay, Got It'),
          secondaryButtonLabel: '',
        }
      case InvoiceActionTypes.FinancialApprove:
      case InvoiceActionTypes.OperationalApprove:
        return {
          title: t('invoices.invoice.action.outOfBalance.title', 'Invoice is Out of Balance'),
          description: t(
            'invoices.invoice.action.outOfBalance.description',
            'The Invoice is currently Out of Balance and this needs to be resolved prior to granting approval. Use the Update Values button in the Invoice Balance panel to resolve the Out of Balance issue.'
          ),
          primaryButtonLabel: t('general.okayGotIt', 'Okay, Got It'),
          secondaryButtonLabel: '',
        }
      default:
        return { title: '', description: '', primaryButtonLabel: '', secondaryButtonLabel: '' }
    }
  }
  return (
    <ConfirmDialog
      title={getInvoiceActionDialogText().title}
      description={getInvoiceActionDialogText().description}
      primaryButtonLabel={getInvoiceActionDialogText().primaryButtonLabel}
      onPrimaryBtnClick={() =>
        [
          InvoiceActionTypes.Complete,
          InvoiceActionTypes.FinancialApprove,
          InvoiceActionTypes.OperationalApprove,
        ].includes(invoiceAction)
          ? setShowConfirmActionDialog(false)
          : onConfirmInvoiceAction()
      }
      secondaryButtonLabel={getInvoiceActionDialogText().secondaryButtonLabel}
      theme={Themes.Primary}
      isOpen={showConfirmActionDialog}
      setIsOpen={setShowConfirmActionDialog}
      btnPrimaryLoading={confirmActionLoading}
      testId={`${invoiceAction}-dialog`}
    />
  )
}
