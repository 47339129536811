import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'

import InvoiceStatus from '../../all-invoices/InvoiceStatus'
import CreditNoteDetailsPanel from './CreditNoteDetailsPanel'

import { useGetCreditNote } from '@/modules/invoices/hooks'
import { Breadcrumb, Button, MoreOptionsMenu, QueryResult, ThemeWrapper, Tooltip } from '@/modules/shared/components'
import HeaderTabs, { TabItem } from '@/modules/shared/components/header-tabs'
import { useMoney } from '@/modules/shared/hooks'
import { HomeIcon, InfoIcon, InvoiceIcon, OptionIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'
import { findActiveRoute } from '@/modules/shared/utils'

export default function CreditNotePageHeader() {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const { creditNote, loading, error } = useGetCreditNote()
  const [showInfo, setShowInfo] = useState(false)
  const location = useLocation()

  const actionList: TabItem[] = [
    { target: 'summary', label: t('general.productSummary', 'Product Summary') },
    {
      target: 'comments',
      label: t('general.comments', 'Comments'),
      count: Number(creditNote?.commentsCount),
    },
  ]

  const renderTitle = () => {
    const targetArray = location.pathname.split('/')
    if (targetArray[targetArray.length - 1] === 'summary') {
      return t('general.summary', 'Summary')
    }
    return findActiveRoute(actionList, location.pathname)
  }

  return (
    <QueryResult loading={loading} error={error}>
      <Helmet>
        <title>{`${creditNote?.creditNoteNumber || ''} ${renderTitle()}`}</title>
      </Helmet>
      <div className="mx-auto max-w-[95rem] space-y-5 px-4">
        <Breadcrumb className="rounded-md bg-gray-200 px-3 py-2" data-testid="credit-note-breadcrumb">
          <Breadcrumb.Item icon={HomeIcon} href="/dashboard"></Breadcrumb.Item>
          <Breadcrumb.Item href="/invoices/credit-notes">{t('general.creditNotes', 'Credit Notes')}</Breadcrumb.Item>
          <Breadcrumb.Item href="#">{creditNote?.creditNoteNumber}</Breadcrumb.Item>
          <Breadcrumb.Item>{findActiveRoute(actionList, location.pathname)}</Breadcrumb.Item>
        </Breadcrumb>
        <section className="my-5 flex flex-col gap-y-2">
          <section className="flex w-full flex-col justify-between rounded-md bg-white shadow sm:flex-row">
            <div className="flex items-center gap-4 p-4">
              <ThemeWrapper theme={Themes.Primary} className="flex items-center justify-center rounded-md p-2">
                <InvoiceIcon className="h-10 w-10 text-primary" />
              </ThemeWrapper>
              <div className="flex justify-between">
                <span>
                  <p className="text-xl font-bold" data-testid="cart-value">
                    {formatMoney(creditNote?.totalValue)}
                  </p>
                  <p className="text-sm">{creditNote?.creditNoteNumber}</p>
                </span>
              </div>
            </div>
            <hr className="sm:hidden" />
            <div className="flex items-center justify-between p-4 sm:justify-center">
              <p className="text-sm text-gray-500 sm:hidden">{t('general.actions')}</p>
              <div className="flex gap-x-2">
                <Tooltip content={t('general.showOrHideDetails', 'Show or hide details')} showArrow={false}>
                  <Button
                    data-testid="show-details-button"
                    className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-200"
                    onClick={() => setShowInfo(!showInfo)}
                  >
                    <InfoIcon className="h-8 w-8 text-gray-600" />
                  </Button>
                </Tooltip>
                <MoreOptionsMenu showHelp showOpenInPPlus creditNoteId={creditNote?.id} buttonTheme="dark">
                  <OptionIcon className="h-8 w-8" />
                </MoreOptionsMenu>
              </div>
            </div>
          </section>
          {showInfo && <CreditNoteDetailsPanel />}
          <section className="flex items-center justify-between rounded-md bg-white px-4 py-3 text-sm shadow">
            <p>{t('general.status', 'Status')}</p>
            {creditNote?.workflowState && <InvoiceStatus state={creditNote.workflowState} />}
          </section>
          <HeaderTabs actionList={actionList.filter((action) => !action.hiddenOnHeaderTabs)} />
        </section>
        <Outlet />
      </div>
    </QueryResult>
  )
}
