import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath, useParams } from 'react-router-dom'

import PriceHistoryModal from '../table-view/PriceHistoryModal'
import UpdateCatalogProductModal from '../table-view/UpdateCatalogProductModal'

import NoImage from '@/assets/images/no-image.svg'
import { PricedCataloguedProduct } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatProductItem } from '@/modules/requisitions/utils'
import { Button, Fallback, MoreOptionsMenu } from '@/modules/shared/components'
import { TableRowSingle } from '@/modules/shared/components/page-header-details-table'
import { useMoney } from '@/modules/shared/hooks'
import { OptionIcon } from '@/modules/shared/icons'

interface TileViewProps {
  products: PricedCataloguedProduct[]
}

export default function TileView({ products }: TileViewProps) {
  const { formatMoney } = useMoney()
  const { t } = useTranslation()
  const [priceHistoryModalId, setPriceHistoryModalId] = useState<number | null>(null)
  const [updatePriceModalId, setUpdatePriceModalId] = useState<number | null>(null)
  const { pricedCatalogueId } = useParams<{ pricedCatalogueId: string }>()

  const handleProductDetailsTableText = (detailItem: string, isTitle = true) => {
    let tooltipText, titleText
    switch (detailItem) {
      case 'category':
        titleText = t('general.category', 'Category')
        tooltipText = t(
          'catalogs.selfManagedCatalog.table.categoryTooltip',
          'The category that has been assigned to this product.'
        )
        break
      case 'subCategory':
        titleText = t('general.subCategory', 'Sub Category')
        tooltipText = t(
          'catalogs.selfManagedCatalog.table.subCategoryTooltip',
          'The sub-category that has been assigned to this product.'
        )
        break
      default:
        titleText = ''
        tooltipText = ''
    }
    return isTitle ? titleText : tooltipText
  }

  return (
    <div className="flex flex-col gap-y-6">
      {products.map((product) => {
        const { id, sellUnitPrice, sellUnitTaxPercentage, image } = product || {}
        const { itemMeasure, itemPackName, itemSize, concatenatedSellUnit, brand, itemDescription, category } =
          product.product || {}
        return (
          <div key={product.id} className="divide-y rounded-md border bg-white text-sm">
            <section className="flex gap-x-2 p-5">
              <img className="h-24 w-24 border" src={image || NoImage} alt="product" />
              <div className="flex flex-col justify-center">
                <span className="text-primary" data-testid="product-details-button">
                  {brand} {itemDescription}
                </span>
                <span className="text-xxs sm:text-xs">
                  <p>
                    {formatProductItem({
                      itemMeasure: itemMeasure,
                      itemPackName: itemPackName,
                      itemSize: itemSize,
                      itemSellUnit: concatenatedSellUnit,
                    })}
                  </p>
                </span>
                <p className="font-bold">{formatMoney(sellUnitPrice)}</p>

                <Fallback as="p" className="text-xs" condition={sellUnitTaxPercentage !== null}>
                  {t('catalogs.selfManagedCatalog.plusTax', 'Plus {{ tax }}% Tax', {
                    tax: sellUnitTaxPercentage,
                  })}
                </Fallback>
              </div>
            </section>
            <section className="p-5">
              <div className="w-full divide-y divide-gray-200 overflow-auto border border-gray-300">
                <TableRowSingle
                  titleText={handleProductDetailsTableText('category', true)}
                  tooltipText={handleProductDetailsTableText('category', false)}
                  testId="category"
                  value={category?.name}
                />
                <TableRowSingle
                  titleText={handleProductDetailsTableText('subCategory', true)}
                  tooltipText={handleProductDetailsTableText('subCategory', false)}
                  testId="subCategory"
                  value={category?.parent?.name}
                />
              </div>
            </section>
            <section className="flex items-center justify-between px-5 py-3">
              <p className="font-bold">{t('general.priceHistory', 'Price History')}</p>
              <Button
                className="rounded-full bg-gray-200 px-7 py-2.5"
                onClick={() => setPriceHistoryModalId(id)}
                data-testid="price-history-button"
              >
                {t('general.view', 'View')}
              </Button>
              <PriceHistoryModal
                cataloguedProduct={product}
                showModal={priceHistoryModalId === id}
                setModalProductId={setPriceHistoryModalId}
              />
            </section>
            <section className="flex flex-col gap-y-2 px-5 py-3">
              <div className="flex items-center justify-between">
                <p className="font-bold">{t('general.unitPrice', 'Unit Price')}</p>
                <div
                  className="w-fit min-w-[5rem] cursor-pointer rounded-md border border-gray-300 px-2 py-2.5 text-right transition duration-200 hover:bg-gray-200"
                  onClick={() => setUpdatePriceModalId(id)}
                  data-testid="update-price-button"
                >
                  {formatMoney(sellUnitPrice)}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-bold">{t('general.tax%', 'Tax %')}</p>
                <div
                  className="w-fit min-w-[5rem] cursor-pointer rounded-md border border-gray-300 px-2 py-2.5 text-right transition duration-200 hover:bg-gray-200"
                  onClick={() => setUpdatePriceModalId(id)}
                  data-testid="update-tax-button"
                >
                  <Fallback condition={sellUnitTaxPercentage !== null}>{`${sellUnitTaxPercentage}%`}</Fallback>
                  <UpdateCatalogProductModal
                    cataloguedProduct={product}
                    showModal={updatePriceModalId === id}
                    setModalProductId={setUpdatePriceModalId}
                  />
                </div>
              </div>
            </section>
            <section className="flex justify-between p-5">
              <Link
                to={generatePath('/catalogs/self-managed/:pricedCatalogId/products/:id', {
                  id: String(id),
                  pricedCatalogId: String(pricedCatalogueId),
                })}
                className="flex h-11 items-center justify-center rounded-md bg-gray-200 p-4 text-center text-sm transition hover:brightness-95"
              >
                {t('general.viewProduct', 'View Product')}
              </Link>
              <MoreOptionsMenu catalogId={pricedCatalogueId} productId={id} showProductLink>
                <OptionIcon className="h-10 w-10 text-gray-500" />
              </MoreOptionsMenu>
            </section>
          </div>
        )
      })}
    </div>
  )
}
