import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../button'
import ConfirmDialog from '../../confirm-dialog'
import Tooltip from '../../tooltip'

import { Invoice } from '@/graphql/purchasing/generated/purchasing_graphql'
import InvoiceReceiptsModal from '@/modules/invoices/pages/all-invoices/InvoiceReceiptsModal'
import InventoryModal from '@/modules/purchase-orders/pages/purchase-order/receiving-notes/InventoryModal'
import { TruckIcon } from '@/modules/shared/icons'
import { Themes } from '@/modules/shared/types'

interface InvoiceReceiptsButtonProps {
  invoice: Invoice
}

export default function InvoiceReceiptsButton({ invoice }: InvoiceReceiptsButtonProps) {
  const { receivingDocuments, purchaseOrder } = invoice
  const totalReceipts = receivingDocuments?.edges?.length
  const hasReceipts = (totalReceipts || 0) > 0

  const { t } = useTranslation()
  const [openNoMatchedPOModal, setOpenNoMatchedPOModal] = useState(false)
  const [openInventoryModal, setOpenInventoryModal] = useState(false)
  const [openReceiptsModal, setOpenReceiptsModal] = useState(false)

  const renderTooltip = () => {
    if (!hasReceipts) {
      return t('general.clickToCreateReceivingNote', 'Click to create a Goods Receiving Note')
    }
    return t('invoices.allInvoices.receiptsCount', {
      defaultValue_one: '{{ count }} Receiving Note has been created against this Invoice. Click to view details.',
      defaultValue_many: '{{ count }} Receiving Notes have been created against this Invoice. Click to view details.',
      defaultValue_other: '{{ count }} Receiving Notes have been created against this Invoice. Click to view details.',
      count: totalReceipts,
    })
  }

  return (
    <>
      <Tooltip content={renderTooltip()}>
        <Button
          onClick={() => {
            if (purchaseOrder) {
              if (hasReceipts) setOpenReceiptsModal(true)
              else setOpenInventoryModal(true)
            } else {
              setOpenNoMatchedPOModal(true)
            }
          }}
          className={classNames(`flex h-10 w-10 items-center justify-center rounded-full`, {
            'bg-gray-200 text-gray-500 hover:bg-gray-300': !hasReceipts,
            'bg-primary/10 text-primary hover:bg-primary/20': hasReceipts,
          })}
          data-testid="receipts-modal-button"
        >
          {hasReceipts ? totalReceipts : <TruckIcon className="h-6 w-6" />}
        </Button>
      </Tooltip>
      <InventoryModal
        showModal={openInventoryModal}
        setShowModal={setOpenInventoryModal}
        purchaseOrderId={String(purchaseOrder?.id)}
      />
      <InvoiceReceiptsModal invoice={invoice} showModal={openReceiptsModal} setShowModal={setOpenReceiptsModal} />
      <ConfirmDialog
        theme={Themes.Primary}
        isOpen={openNoMatchedPOModal}
        setIsOpen={setOpenNoMatchedPOModal}
        title={t('general.noMatchedPurchaseOrder', 'No Matched Purchase Order')}
        description={t(
          'invoices.details.noMatchedPurchaseOrderDesc',
          'There is no Purchase Order matched to this Invoice yet and so you cannot create a Receiving Note. To begin receiving goods, you will need to match a Purchase Order to the Invoice.'
        )}
        primaryButtonLabel={t('general.okayGotIt', 'Okay, Got It')}
        onPrimaryBtnClick={() => setOpenNoMatchedPOModal(false)}
      />
    </>
  )
}
