import { useQuery } from '@apollo/client'

import {
  GetAllStockLocationsDocument,
  RansackDirection,
  StockLocation,
} from '@/graphql/inventory/generated/inventory_graphql'
import { useCurrentOrganisation } from '@/modules/purchasing/hooks'
import { OnFilter } from '@/modules/shared/components/search-input/SearchAndFilter'
import { INVENTORY_GRAPHQL_API } from '@/modules/shared/constants'
import { checkNetworkStatus, extractEdges } from '@/modules/shared/utils'

export const useGetAllStockLocations = () => {
  const { currentOrganisation } = useCurrentOrganisation()

  const { data, networkStatus, error, refetch, fetchMore } = useQuery(GetAllStockLocationsDocument, {
    variables: {
      after: null,
      filter: {},
      first: 20,
      organisationId: Number(currentOrganisation?.id),
      sort: [
        {
          property: 'name',
          direction: RansackDirection.Asc,
        },
      ],
    },
    fetchPolicy: 'cache-and-network',
    context: { uri: INVENTORY_GRAPHQL_API },
    notifyOnNetworkStatusChange: true,
  })

  const { setVariablesLoading, refetchLoading, loading } = checkNetworkStatus(networkStatus)

  const stockLocations = extractEdges<StockLocation>(data?.legalHolder?.stockLocations)

  const onFetchMoreStockLocations = () => {
    fetchMore({
      variables: {
        after: data?.legalHolder?.stockLocations?.pageInfo.endCursor,
      },
    })
  }

  const onFilter = ({ searchValue }: OnFilter) => {
    refetch({
      filter: {
        q: [
          {
            property: 'name_cont',
            value: searchValue.trim(),
          },
        ],
      },
    })
  }

  return {
    data,
    setVariablesLoading,
    refetchLoading,
    loading,
    stockLocations,
    error,
    onFetchMoreStockLocations,
    onFilter,
    networkStatus,
    refetch,
    pageInfo: data?.legalHolder?.stockLocations?.pageInfo,
  }
}
