import classNames from 'classnames'
import { useState } from 'react'

import AddToBuyListModal from '../../AddToBuyListModal'
import ProductInformation from './ProductInformation'
import ProductPrice from './ProductPrice'
import ProductPrimaryButton from './ProductPrimaryButton'
import ProductTax from './ProductTax'

import NoImage from '@/assets/images/no-image.svg'
import { RequisitionLine, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import { formatProductItem } from '@/modules/requisitions/utils'
import { MoreOptionsMenu } from '@/modules/shared/components'
import ProductModal from '@/modules/shared/components/product-modal/ProductModal'
import { SupplierQuotesButton } from '@/modules/shared/components/table/table-column-items'
import { OptionIcon } from '@/modules/shared/icons'
import { ProductListType, ProductType } from '@/modules/shared/types'

interface ProductCardTileViewProps {
  product: ProductType
  type: ProductListType
  lines?: RequisitionLine[]
}

function ProductCardTileView({ product, type, lines }: ProductCardTileViewProps) {
  const { image, supplier, quantity, product: productInfo, availableQuotes } = product
  const { itemDescription, brand, itemSize, itemMeasure, itemPackName, concatenatedSellUnit } = productInfo || {}
  const [showProductDetailsModal, setShowProductDetailsModal] = useState(false)
  const [showAddToBuyListModal, setShowAddToBuyListModal] = useState(false)

  return (
    <div data-testid="product-detail" className="flex flex-col rounded-md border bg-white shadow-sm">
      <div
        className="flex min-h-[8.06rem] grow cursor-pointer items-center border-b border-gray-200 p-3 sm:px-5 sm:py-4"
        data-testid="tileview-click-area"
      >
        {(image || type !== ProductListType.Shop) && (
          <div
            className="mr-5 h-[4.25rem] w-[4.25rem] flex-none border border-gray-200 sm:h-24 sm:w-24"
            onClick={() => setShowProductDetailsModal(true)}
          >
            <img className="h-full w-full" src={image || NoImage} alt="product" />
          </div>
        )}
        <div
          className={classNames('overflow-hidden md:w-64', { 'flex grow': !image })}
          onClick={() => setShowProductDetailsModal(true)}
        >
          <span className="space-y-1">
            <p className="break-words text-xs text-primary sm:text-sm">
              {brand} {itemDescription}
            </p>
            <p className="text-xxs text-gray-500 sm:text-xs">
              {formatProductItem({
                itemSize: itemSize,
                itemMeasure: itemMeasure,
                itemPackName: itemPackName,
                itemSellUnit: concatenatedSellUnit,
              })}
            </p>
            <p className="flex gap-x-1.5 text-xxs sm:text-xs md:truncate">
              <ProductInformation type={type} product={product} />
            </p>
          </span>
        </div>
        {type === ProductListType.Shop && (
          <div className="mb-auto ml-auto">
            <MoreOptionsMenu
              showDetails={() => setShowProductDetailsModal(true)}
              showAddToBuyList={() => setShowAddToBuyListModal(true)}
            >
              <OptionIcon className="-mr-2 h-6 w-6 text-gray-300" data-testid="more-options-menu" />
            </MoreOptionsMenu>
          </div>
        )}
      </div>
      <div className="p-3 md:px-5 md:py-4">
        <div className="flex items-center justify-between">
          <span>
            <p className="text-base font-bold leading-tight sm:text-lg">
              <ProductPrice type={type} product={product} />
            </p>
            <p className="text-xxs text-gray-500 sm:text-xs">
              <ProductTax type={type} product={product} />
            </p>
          </span>
          <div className="flex items-center space-x-2">
            <SupplierQuotesButton
              product={productInfo}
              prices={availableQuotes}
              currentSupplierId={supplier?.id}
              image={image}
              basicShop
              quantity={quantity}
            />
            <ProductPrimaryButton type={type} product={product} lines={lines} />
          </div>
        </div>
      </div>
      <ProductModal
        line={product}
        showModal={showProductDetailsModal}
        setShowModal={setShowProductDetailsModal}
        supplier={product?.supplier as Supplier}
      />
      <AddToBuyListModal
        requisitionLine={product}
        showModal={showAddToBuyListModal}
        setShowModal={setShowAddToBuyListModal}
      />
    </div>
  )
}

export default ProductCardTileView
