import Carousel from '@/modules/shared/components/carousel'
import { SlideData } from '@/modules/shared/types'

const slideData: SlideData[] = [
  {
    id: 'slider-1',
    imageSrc: 'https://uploads-ssl.webflow.com/624237936b1994694aca059d/62845895ccc4f754d7ab4692_image_1-p-1600.jpeg',
  },
  {
    id: 'slider-2',
    imageSrc: 'https://uploads-ssl.webflow.com/624237936b1994694aca059d/628458d73fd27ddb9b01cee3_image_2-p-1600.jpeg',
  },
]

export default function ShopCarousel() {
  return <Carousel data={slideData} dataTestId="shop-carousel" />
}
